/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, Input } from "@angular/core";

import { OrgBillingInvoice } from "models/org_billing.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";

@Component({
  selector: "settings-billing-tab-invoices",
  templateUrl: "./tab-invoices.component.html",
  styleUrls: ["./tab-invoices.component.scss"],
})
export class SettingsBillingTabInvoicesComponent {
  @Input() superOrg: SuperOrg = null;
  @Input() orgBillingInvoices: OrgBillingInvoice[] = null;

  constructor(private superOrgDao: SuperOrgDao) {}

  public getInvoiceURL(invoice: OrgBillingInvoice): string {
    return this.superOrgDao.getInvoiceURL(this.superOrg.id, invoice.id);
  }
}
