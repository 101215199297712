import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";

import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsIndex, AnalyticsQuery } from "models/analytics.filters.type";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { UUID } from "models/survey.dao.types";
import { ShareReportType, Survey } from "models/survey.model";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { arrayToSet } from "utils/array";
import { deepCopy } from "utils/object";

@Component({
  selector: "survey-stats-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
})
export class FiltersStatsSurveyComponent implements OnInit, OnDestroy {
  @Input()
  public org: Org = null;
  @Input()
  public survey: Survey = null;
  @Input()
  public reporting: string = null;
  @Input()
  public canExport = true;
  @Input()
  public canShare = true;
  @Input()
  public canFilterByDate = true;
  @Input()
  public type: AnalyticsIndex;
  @Input()
  public registryEntriesIdentityProperty: RegistryEntry[] = [];
  @Input()
  public registryEntriesGroup: RegistryEntry[] = [];
  @Input()
  public registryEntriesEvent: RegistryEntry[] = [];
  @Input()
  public reportType: ShareReportType = null;
  @Input()
  public languages: LanguageWithEmoji[] = null;

  private filtersObs$: any = null;
  public lastFilters: AnalyticsQuery;
  public defaultFilters: AnalyticsQuery;

  public userGroupsOptions: NzSelectOptionInterface[] = [];
  public selectedUserGroups: string[] = null;

  constructor(
    private analyticsFilterService: AnalyticsFilterService,
    private analyticsDao: AnalyticsDao,
    private trackersService: TrackersService,
    public featureFlaggingService: FeatureFlaggingService,
    public uiService: UIService,
    public permissionsService: PermissionsService,
  ) {}

  ngOnInit() {
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQuery) => {
        this.lastFilters = this.addCustomParamsForCSVExport(deepCopy(filters));
        this.refreshUserGroupsOptions();
      });

    this.defaultFilters = this.addCustomParamsForCSVExport(
      this.getInitialFilters(),
    );
  }

  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  refreshUserGroupsOptions() {
    if (this.lastFilters.type === "respondent") {
      this.selectedUserGroups = arrayToSet(
        this.lastFilters.filters.flatMap((filter) => {
          if (
            filter.type === "respondent" &&
            filter.key === "segment" &&
            filter.operator === "in"
          ) {
            return filter.values;
          }
          return [];
        }),
      );
    }

    this.userGroupsOptions = this.registryEntriesGroup.map((userGroup) => ({
      value: userGroup.id,
      label: getFormattedUserGroupNameOrID(userGroup),
    }));
  }

  selectedUserGroupsChange($event: string[]) {
    this.analyticsFilterService.setSelectedUserGroups($event);
  }

  private addCustomParamsForCSVExport(filters: AnalyticsQuery): AnalyticsQuery {
    filters = deepCopy(filters); // probably not needed, but just in case...

    if (filters.type === "response") {
      filters.having_answer_only = true;
    }

    return filters;
  }

  public onExportWithFiltersClick() {
    // export event is sent into Segment from server side
    this.track("Export with filters", this.lastFilters);
  }
  public onExportAllClick() {
    // export event is sent into Segment from server side
    this.track("Export all", this.defaultFilters);
  }
  private track(eventName: TrackingEventName, filters: AnalyticsQuery) {
    // export event is sent into Segment from server side
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withAnalyticsFilters(filters.filters, filters.type)
      .build();
  }

  private getInitialFilters() {
    return this.analyticsFilterService.getInitialFilters(
      this.type,
      UUID(this.org.id),
      "AND",
      this.survey ? [UUID(this.survey.id)] : [],
      this.survey?.created_at || this.org?.created_at,
    );
  }

  public getCsvExportURL(withFilters: boolean): string {
    withFilters = withFilters && !!this.lastFilters;

    return this.analyticsDao.export(
      withFilters ? this.lastFilters : this.defaultFilters,
    );
  }

  public hideAnonymousOnChange(value: boolean) {
    this.analyticsFilterService.setIdentifiedOnly(value);

    this.trackersService
      .newEventTrackingBuilder("Respondent anonymous switched")
      .withOrg(this.org)
      .withProps({
        identified_only: value,
      })
      .build();
  }
}
