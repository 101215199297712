import { Component, Input } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";

@Component({
  selector: "rule-scroll-reach-element",
  templateUrl: "./rule-scroll-reach-element.component.html",
  styleUrls: ["./rule-scroll-reach-element.component.scss"],
})
export class TargetingRuleScrollReachElementComponent {
  @Input()
  public rule: SurveyTargetingRule = null;

  constructor() {}

  public checkValidQuerySelector(r: SurveyTargetingRule): boolean {
    try {
      document.querySelector(r.value.v_s);
      return true;
    } catch (e) {
      return false;
    }
  }
}
