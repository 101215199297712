import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  NzSelectComponent,
  NzSelectOptionInterface,
} from "ng-zorro-antd/select";

@Component({
  selector: "tag-input",
  template: `<div class="add-tags-container">
    <nz-select
      #selectTags
      class="add-tags"
      [nzPlaceHolder]="placeHolder"
      nzMode="tags"
      [ngModel]="ngModel"
      [nzOptions]="options"
      (nzOnSearch)="onSearch($event)"
      (nzBlur)="onBlur()"
      [nzTokenSeparators]="tokenSeparators"
      [nzSize]="size"
      [nzDropdownStyle]="{ display: activateDropdown ? 'block' : 'none' }"
      (ngModelChange)="emit($event)"
    >
    </nz-select>
  </div>`,
  styles: [
    `
      :host {
        .add-tags-container {
          display: flex;
          gap: 8px;
          align-items: flex-end;
        }

        .add-tags {
          min-width: max(90px, 100%);
        }
      }
    `,
  ],
})
export class TagInputComponent implements AfterViewInit, OnChanges {
  @Input() public tokenSeparators: string[] = [";", ",", " "];
  @Input() public placeHolder: string;
  @Input() public size: "default" | "small" | "large" = "default";
  @Input() public activateDropdown = true;
  @Input() public options: NzSelectOptionInterface[] = [];
  @Input() public ngModel: any[] = [];
  @Input() public autoFocus: boolean = false;

  @Output() public ngModelChange = new EventEmitter<any[]>();

  @ViewChild("selectTags") selectComponent: NzSelectComponent;

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.selectComponent?.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.options !== undefined &&
      changes.options?.currentValue !== this.options
    ) {
      this.options = changes.options.currentValue;
    }
  }

  // onAdd() {
  //   this.addTagsEmitter.emit(this.tagsModel);
  //   this.tagsModel = [];
  // }

  /**
   * HACK: until this PR get merged: https://github.com/NG-ZORRO/ng-zorro-antd/pull/5464
   * See: https://github.com/NG-ZORRO/ng-zorro-antd/issues/5460
   *
   * I made a quick hack by storing last value. Then, on click outside, I add the value.
   */
  private lastInput = "";

  public onSearch(event) {
    this.lastInput = event.trim();
  }

  public onBlur() {
    const select = document.querySelector(".add-tags-container") as HTMLElement;
    if (this.lastInput !== "") {
      // dirty fix to make button not move when adding a tag that is not "confirmed"
      select.style.width = select.offsetWidth + "px";
      select.style.height = select.offsetHeight + "px";

      this.ngModel = (this.ngModel ?? []).concat(this.lastInput as any);

      this.emit(this.ngModel);
    }
    setTimeout(() => {
      select.style.width = "auto";
      select.style.height = "auto";
    }, 500);
  }

  public emit(event) {
    this.ngModelChange.emit(event.concat());
  }
}
