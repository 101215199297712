import { Component, Input, OnInit } from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";
import { ChannelType } from "models/channel.model";
import { ClipboardService } from "ngx-clipboard";
import { UIService } from "services/ui.service";

@Component({
  selector: "survey-share-targeting-manual",
  templateUrl: "./manual.component.html",
  styleUrls: ["./manual.component.scss"],
})
export class ManualTargetingShareSurveyComponent implements OnInit {
  @Input() public surveyId: string = null;
  @Input() public channelType: ChannelType = null;

  public code: string;

  constructor(
    private clipboardService: ClipboardService,
    private notificationHelper: NotificationHelper,
    public uiService: UIService,
  ) {}

  ngOnInit(): void {
    this.code = this.getCode(true);
  }

  public getDocURL() {
    switch (this.channelType) {
      case "widget":
        return "https://developers.screeb.app/sdk-js/install";
      case "android":
        return "https://developers.screeb.app/sdk-android/install";
      case "ios":
        return "https://developers.screeb.app/sdk-ios/install";
      default:
        throw new Error("unexpected channel type");
    }
  }

  public getCode(multiple: boolean) {
    switch (this.channelType) {
      case "widget":
        return this.getCodeJS(multiple);
      case "android":
        return this.getCodeAndroid(multiple);
      case "ios":
        return this.getCodeIOS(multiple);
      default:
        throw new Error("unexpected channel type");
    }
  }

  private getCodeJS(multiple: boolean) {
    return `$screeb('survey.start', '${this.surveyId}'${
      multiple ? "" : ", { allow_multiple_responses: false }"
    });`;
  }

  private getCodeAndroid(multiple: boolean) {
    return `Screeb.startSurvey(
    surveyId = "${this.surveyId}"${
      multiple ? "" : ",\n    allowMultipleResponses = false"
    }
)`;
  }

  private getCodeIOS(multiple: boolean) {
    return `Screeb.startSurvey(
    surveyId: “${this.surveyId}"${
      multiple ? "" : ",\n    allowMultipleResponses: false"
    }
)`;
  }

  recurrencyChange(multiple: boolean) {
    this.code = this.getCode(multiple);
  }

  clipboardCopy(code: string) {
    this.clipboardService.copy(code);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );
  }
}
