import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { NotificationHelper } from "helpers/notification.helper";
import { SurveyDao } from "models/survey.dao";
import { Survey } from "models/survey.model";

@Injectable()
export class SurveyResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private surveyDao: SurveyDao,
    private notificationHelper: NotificationHelper,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Survey> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.surveyDao.getById(
          route.params["org_id"],
          route.params["survey_id"],
          route.queryParams["scenario_id"] || null,
        );
      })
      .catch((err: HttpErrorResponse) => {
        // this.notificationHelper.trigger("Failed to fetch survey", null, 'error');
        console.error(err.error);
        throw err;
      });
  }
}
