<h1>
  {{ uiService.isMessagePage ? "Create a new message" : "Create a new survey" }}
</h1>

<upgrade-modal
  [centeredModal]="true"
  [withOverlay]="true"
  [orgId]="org.id"
  (closeClick)="isUpgradeModalVisible = false"
  class="upgrade-modal"
  *ngIf="isUpgradeModalVisible"
></upgrade-modal>

<div *ngIf="loading" class="center">
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>

<ng-container *ngIf="!loading">
  <header>
    <span
      >Start from one of these templates, or create your own
      {{ uiService.isMessagePage ? "messsage" : "survey" }} from scratch</span
    >

    <div>
      <nz-select
        nzSize="large"
        [ngModel]="templateLanguage"
        (ngModelChange)="onLanguageChange($event)"
      >
        <nz-option nzValue="en" nzLabel="🇬🇧 English"></nz-option>
        <nz-option nzValue="fr" nzLabel="🇫🇷 French"></nz-option>
      </nz-select>

      <nz-input-group
        nzSize="large"
        [nzPrefix]="prefixIcon"
        [nzSuffix]="inputClearButton"
      >
        <input
          type="text"
          nz-input
          placeholder="Search a template"
          [(ngModel)]="keywords"
          (ngModelChange)="onSearchChange()"
        />
      </nz-input-group>
      <ng-template #prefixIcon>
        <i nz-icon nzType="search"></i>
      </ng-template>
      <ng-template #inputClearButton>
        <i
          nz-icon
          class="ant-input-clear-icon"
          nzTheme="fill"
          nzType="close-circle"
          *ngIf="keywords"
          (click)="keywords = ''; onSearchChange()"
        ></i>
      </ng-template>
    </div>
  </header>

  <div *ngIf="!searching">
    <ul class="survey-template-groups">
      <li>
        <h2>Popular templates</h2>
        <ul class="survey-templates">
          <!-- from scratch -->
          <li class="from-scratch">
            <card
              [title]="
                templateLanguage === 'fr'
                  ? 'Partir de zéro'
                  : 'Create from Scratch'
              "
              [content]="[
                templateLanguage === 'fr'
                  ? uiService.isMessagePage
                    ? 'Créez votre propre message et questionnez vos utilisateurs.'
                    : 'Créez votre propre sondage et questionnez vos utilisateurs.'
                  : uiService.isMessagePage
                    ? 'Create your own messages and chain them for your needs.'
                    : 'Create your own survey from scratch and ask tailored questions to your users.'
              ]"
              headerIcon="plus"
              (cardClick)="onCreate()"
            >
            </card>
          </li>

          <!-- from ai -->
          <li class="from-ai" *ngIf="!uiService.isMessagePage">
            <card
              [title]="
                templateLanguage === 'fr'
                  ? 'Créer avec l\'IA'
                  : 'Create with AI'
              "
              [content]="[
                templateLanguage === 'fr'
                  ? uiService.isMessagePage
                    ? 'Expliquez ce que vous voulez demander et notre IA créera un message.'
                    : 'Expliquez ce que vous voulez demander et notre IA créera un sondage.'
                  : uiService.isMessagePage
                    ? 'Use AI to generate a flow of messages. Edit as you like.'
                    : 'Explain what you want to ask and our AI will create a survey.'
              ]"
              headerIcon="lightning"
              headerIconSize="sm"
              (cardClick)="onCreate(null, null, null, 0, 'success', true)"
            >
            </card>
          </li>

          <!-- from popular surveys -->
          <ng-container
            *ngFor="let tpl of popularSurveys; let surveyId = index"
          >
            <li *ngIf="tpl.language === templateLanguage">
              <ng-container
                [ngTemplateOutlet]="templateCard"
                [ngTemplateOutletContext]="{ template: tpl }"
              >
              </ng-container>
            </li>
          </ng-container>
        </ul>
      </li>

      <!-- loop over each template categories -->
      <li
        *ngFor="
          let group of uiService.isMessagePage
            ? settingsService.messageTemplateGroups
            : settingsService.surveyTemplateGroups;
          let groupId = index
        "
      >
        <h2>{{ group.title[templateLanguage] }}</h2>
        <ng-container
          [ngTemplateOutlet]="templateCards"
          [ngTemplateOutletContext]="{ templates: group.surveys }"
        >
        </ng-container>
      </li>
    </ul>
  </div>

  <!-- loop over search results -->
  <div *ngIf="searching">
    <ul class="survey-template-groups">
      <li>
        <h2>Results</h2>
        <nz-empty
          *ngIf="filteredTemplates.length === 0"
          nzNotFoundContent="No template matching"
        ></nz-empty>
        <ng-container
          *ngIf="filteredTemplates.length > 0"
          [ngTemplateOutlet]="templateCards"
          [ngTemplateOutletContext]="{ templates: filteredTemplates }"
        ></ng-container>
      </li>
    </ul>
  </div>

  <ng-template #templateCards let-templates="templates">
    <ul class="survey-templates">
      <ng-container *ngFor="let tpl of templates">
        <li *ngIf="tpl.language === templateLanguage">
          <ng-container
            [ngTemplateOutlet]="templateCard"
            [ngTemplateOutletContext]="{ template: tpl }"
          >
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </ng-template>

  <ng-template let-template="template" #templateCard>
    <card
      [ngClass]="{
        'inspired-by-customers': template.is_inspired_by_customers
      }"
      [title]="template.name"
      [content]="template.description"
      [headerCss]="{
        'background-image': getCssBackground(template.background)
      }"
      [actionLeft]="actionLeft"
      (cardClick)="onDuplicate(template)"
    >
    </card>
    <ng-template #actionLeft>
      <a
        class="preview"
        nz-button
        nzType="link"
        rel="noopener"
        (click)="$event.stopPropagation()"
        href="http://preview.screeb.app/templates.html?sid={{
          template.survey_id
        }}&vid={{ sessionService.session.id }}&platform={{
          configService.config.platform
        }}"
        target="_blank"
        >Preview</a
      >
    </ng-template>
  </ng-template>
</ng-container>
