import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ChannelType } from "models/channel.model";
import { SurveyDistribution } from "models/survey-distribution.model";
import { Survey } from "models/survey.model";
import { UIService } from "services/ui.service";

@Component({
  selector: "page-survey-share-redirect",
  template: "",
  templateUrl: "./share-redirect.component.html",
  styleUrls: ["./share-redirect.component.scss"],
})
export class ShareRedirectSurveyPageComponent implements OnInit {
  private obs: any = null;

  private orgId: string = null;
  private surveyId: string = null;
  public survey: Survey = null;
  private distributions: SurveyDistribution[] = [];

  public loaded = false;
  public isEditMessage = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public uiService: UIService,
  ) {}

  public getLink(channelType: ChannelType) {
    const distribution = this.distributions.find(
      ({ type }) => type === channelType,
    );

    if (this.isEditMessage) {
      return `/org/${this.orgId}/message/${this.surveyId}/edit/${distribution?.channel_id}/${distribution?.type}`;
    }
    return `/org/${this.orgId}/${this.uiService.surveyTypePath}/${this.surveyId}/share/${distribution?.channel_id}/${distribution?.type}`;
  }

  public ngOnInit() {
    this.obs = this.route.data.subscribe((data) => {
      this.survey = data["survey"];
    });
    this.orgId = this.route.snapshot.params["org_id"];
    this.surveyId = this.route.snapshot.params["survey_id"];
    this.distributions = this.route.snapshot.data["distributions"];
    this.isEditMessage = this.router.isActive(
      `/org/${this.orgId}/message/${this.surveyId}/edit`,
      false,
    );
    const channel = this.route.snapshot.data["channel"];

    const noDistributionsEditedSoFar = this.distributions.every(
      (distribution) => {
        if (
          Number(distribution.updated_at) !== Number(distribution.created_at)
        ) {
          return false;
        }

        return distribution.targeting_rules?.every(
          (targeting_rule) =>
            Number(targeting_rule.created_at) ===
            Number(distribution.created_at),
        );
      },
    );

    if (noDistributionsEditedSoFar) {
      this.loaded = true;
      return;
    }

    let channelId = channel?.id;
    let channelType = channel?.type;

    if (!channel && !!this.distributions) {
      // search for enabled survey
      // @TODO: if many distrib are enabled, we should definitely redirect to web channel first
      let distrib = this.distributions.find(
        (d: SurveyDistribution) => d.enabled,
      );

      // if no survey is enabled, redirect to last edited distribution

      if (!distrib) {
        const sortedDistribs = [...this.distributions].sort(
          (a, b) => Number(b.updated_at) - Number(a.updated_at),
        );
        distrib = sortedDistribs[0];
      }

      // fallback on first channel
      if (!distrib) distrib = this.distributions[0];

      channelId = distrib?.channel_id;
      channelType = distrib?.type;
    }

    if (this.isEditMessage) {
      this.router.navigate([
        "org",
        this.orgId,
        "message",
        this.surveyId,
        "edit",
        channelId,
        channelType,
      ]);
    } else {
      this.router.navigate([
        "org",
        this.orgId,
        this.uiService.surveyTypePath,
        this.surveyId,
        "share",
        channelId,
        channelType,
      ]);
    }
  }
}
