<h1>Install Screeb</h1>

<div class="installation-guide">
  <onboarding-installation-guide
    [channels]="channels"
    [org]="org"
    [isOnboarding]="false"
    [guideType]="'screeb'"
  ></onboarding-installation-guide>

  <a
    href="https://developers.screeb.app"
    target="_blank"
    rel="noopener"
    class="installation-guide__doc"
  >
    💡&nbsp;<span>Having an integration</span
    >&nbsp;<strong>question</strong>?&nbsp;<span
      >Read our Developer doc here!</span
    >
  </a>
</div>
