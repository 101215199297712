<ul nz-menu>
  <li
    id="menu-settings-default-settings-design"
    *ngIf="'org.update' | hasSuperPermission"
    nz-menu-item
    nzMatchRouter
  >
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/settings/customize"
      routerLinkActive="active"
    >
      Default settings & design
    </a>
  </li>
  <li
    id="menu-settings-survey-capping"
    *ngIf="'targeting-rule.create' | hasPermission"
    nz-menu-item
    nzMatchRouter
  >
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/settings/survey-capping"
      routerLinkActive="active"
    >
      Survey capping
      <screeb-icon
        *ngIf="!('survey_capping' | hasEntitlement)"
        size="sm"
        icon="upgrade"
      />
    </a>
  </li>
  <li id="menu-settings-install-screeb" nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/settings/install"
      routerLinkActive="active"
    >
      Install Screeb
    </a>
  </li>
  <!-- <li id="menu-settings-sdk-changelogs" nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/settings/sdk-changelogs"
      routerLinkActive="active"
    >
      SDK changelogs
    </a>
  </li> -->
  <li id="menu-settings-team-members" nz-menu-item nzMatchRouter>
    <a
      routerLink="/overview/{{ uiService.currentSuperOrgId }}/users"
      [queryParams]="{ workspace_id: uiService.currentOrgId }"
      routerLinkActive="active"
    >
      Team members
      <screeb-icon
        *ngIf="!('team_membership' | hasEntitlement)"
        size="sm"
        icon="upgrade"
      />
    </a>
  </li>
  <li
    id="menu-settings-data-governance"
    nz-menu-item
    nzMatchRouter
    *ngIf="
      ('org.update' | hasSuperPermission) &&
      ('isDataGovernancePageEnabled' | hasFeatureFlag)
    "
  >
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/settings/data-governance"
      routerLinkActive="active"
    >
      Data governance
    </a>
  </li>
  <li id="menu-settings-user-identity-properties" nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{
        uiService.currentOrgId
      }}/settings/user-identity-properties"
      routerLinkActive="active"
    >
      User properties
    </a>
  </li>
  <li id="menu-settings-user-events" nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/settings/user-events"
      routerLinkActive="active"
    >
      User events
    </a>
  </li>
  <li id="menu-settings-mobile-app-data" nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/settings/mobile-screens"
      routerLinkActive="active"
    >
      Mobile app data
    </a>
  </li>
  <li
    id="menu-settings-billing"
    *ngIf="'billing.list' | hasSuperPermission"
    nz-menu-item
    nzMatchRouter
  >
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/settings/billing"
      routerLinkActive="active"
    >
      Billing
    </a>
  </li>
  <li
    id="menu-settings-language-preferences"
    *ngIf="'org.update' | hasSuperPermission"
    nz-menu-item
    nzMatchRouter
  >
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/settings/language"
      routerLinkActive="active"
    >
      Language & Translation
      <screeb-icon
        *ngIf="!('multilingual_survey' | hasEntitlement)"
        size="sm"
        icon="upgrade"
      />
    </a>
  </li>
  <li
    id="menu-settings-automatic-tagging"
    *ngIf="'tagging.list' | hasPermission"
    nz-menu-item
    nzMatchRouter
  >
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/settings/tagging"
      routerLinkActive="active"
    >
      Automatic Tagging
    </a>
  </li>
</ul>
