<div
  nz-col
  [nzSpan]="24"
  class="targeting-embed"
  *ngIf="interaction === 'in_page'"
>
  <h3>Embed the survey in</h3>
  <div class="targeting-embed-input-container">
    <div>
      <span>Element</span>
    </div>
    <input
      type="text"
      nz-input
      placeholder="Type element name for example: #elementId, .elementClass, input#login..."
      [ngModel]="selectorCSS"
      (ngModelChange)="selectorCSSChange.emit($event)"
    />
  </div>
</div>
