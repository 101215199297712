<ul class="stats-list iAppear" *ngIf="userRecords.length">
  <li
    *ngFor="let record of userRecords; let i = index"
    class="stat-item clickable"
  >
    <div class="stat-item-header" (click)="onRecordClicked(record)">
      <square-icon icon="cursor-click"></square-icon>
      <div class="stat-item-data">
        <div class="stat-item-title">
          <span>Record {{ userRecords.length - i }}</span>
          <tag-registry-entry-source
            [sources]="[record.source]"
            size="small"
          ></tag-registry-entry-source>
        </div>
        <div
          class="stat-item-value"
          *ngIf="record.session_start"
          [title]="record.session_start | toLocaleString"
        >
          {{
            record.session_start | dfnsFormatDistanceToNow: { addSuffix: true }
          }}
          /
          {{
            duration(record.snapshot_duration_seconds)
              | dfnsFormatDuration: { format: ["hours", "minutes"] }
          }}
        </div>
      </div>
    </div>
  </li>
</ul>
<nz-empty *ngIf="!userRecords.length" [nzNotFoundContent]="contentTpl">
  <ng-template #contentTpl>
    <div>No records</div>
    <br />
    <!-- <a target="_blank"
      href="https://help.screeb.app/en/articles/5227983-how-to-track-user-events-in-screeb-to-display-your-surveys">
      <i nz-icon nzType="info-circle"></i>
      <span> How to add user events in Screeb</span>
    </a> -->

    <a routerLink="/overview/{{ uiService.currentSuperOrgId }}/billing">
      <i nz-icon nzType="info-circle"></i>
      <span> Your current plan does not support session recording</span>
    </a>
  </ng-template>
</nz-empty>
