<div>
  <h1>Visitor Events</h1>

  <header>
    <p>
      Visitor events will allow you create more accurate survey targeting rules
      and will improve response analysis.
      <br />
      Learn how to track visitor events from SDKs
      <a
        href="https://help.screeb.app/en/articles/5227983-how-to-track-user-events-in-screeb"
        target="_blank"
        rel="noopener noreferrer"
        title="How to track user events in Screeb"
      >
        <u>here</u>.
      </a>
    </p>
  </header>

  <section id="results">
    <div class="global-actions" *ngIf="updateOrDeleteAllowed">
      <ul class="actions left">
        <li>
          <nz-input-group [nzPrefix]="prefixIcon" [nzSuffix]="inputClearButton">
            <input
              type="text"
              nz-input
              placeholder="Search"
              [(ngModel)]="keyword"
              (ngModelChange)="filterRegistryEntries()"
              [ngModelOptions]="{ standalone: true }"
            />
          </nz-input-group>
          <ng-template #prefixIcon><i nz-icon nzType="search"></i></ng-template>
          <ng-template #inputClearButton>
            <i
              nz-icon
              class="ant-input-clear-icon"
              nzTheme="fill"
              nzType="close-circle"
              *ngIf="keyword"
              (click)="keyword = ''; filterRegistryEntries()"
            ></i>
          </ng-template>
        </li>
        <li>
          <button
            nz-button
            nzType="default"
            (click)="selectAll()"
            [disabled]="!isAllSelected()"
          >
            Select all
          </button>
        </li>
        <li>
          <button
            nz-button
            nzType="default"
            (click)="unselectAll()"
            [disabled]="!isAllUnselected()"
          >
            Unselect all
          </button>
        </li>
      </ul>
      <ul class="actions right">
        <li>
          <button
            *ngIf="updateAllowed"
            nz-button
            nz-dropdown
            [nzDropdownMenu]="menuAction1"
            [disabled]="!hasSelected()"
          >
            Actions
            <span nz-icon nzType="down"></span>
          </button>
        </li>
      </ul>
    </div>

    <nz-table
      #table
      [nzData]="filteredRegistryEntries"
      [nzHideOnSinglePage]="true"
      [nzPageSize]="100"
      (nzCurrentPageDataChange)="onPageChange($event)"
    >
      <thead>
        <tr>
          <th
            *ngFor="let column of listOfColumns"
            [nzWidth]="column.nzWidth"
            [nzShowSort]="!!column.sortFn"
            [(nzSortOrder)]="column.sortOrder"
            [nzSortFn]="column.sortFn"
            [nzSortDirections]="column.sortDirections"
            [nzFilterMultiple]="column.filterMultiple"
            [nzFilters]="column.listOfFilter"
            [nzFilterFn]="column.filterFn"
          >
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let registryEntry of table.data"
          [attr.data-event-id]="registryEntry.id"
        >
          <td>
            <big-checkbox2
              *ngIf="updateOrDeleteAllowed"
              [checked]="checkedRegistryEntryIds.has(registryEntry.id)"
              [disabled]="!registryEntry.removable"
              (checkedChange)="
                checkedRegistryEntryIds.has(registryEntry.id)
                  ? checkedRegistryEntryIds.delete(registryEntry.id)
                  : checkedRegistryEntryIds.add(registryEntry.id)
              "
            ></big-checkbox2>
          </td>
          <td class="uppercase">
            {{ registryEntry.title }}
          </td>
          <!-- <td>{{ registryEntryEventTypeFormatted[registryEntry.type].title }}</td> -->
          <!-- <td>{{ registryEntry.count }}</td> -->
          <td>
            <nz-tag
              nzColor="#F3F4F9"
              *ngIf="registryEntry.location !== 'screeb'"
            >
              <span class="device-tag"
                >Location: {{ registryEntry.location }}</span
              >
            </nz-tag>
            <tag-registry-entry-source
              [sources]="registryEntry.sources"
            ></tag-registry-entry-source>
          </td>
          <td>
            {{
              registryEntry.created_at
                | dfnsFormatDistanceToNow: { addSuffix: true }
            }}
          </td>
          <td>
            {{
              registryEntry.updated_at
                | dfnsFormatDistanceToNow: { addSuffix: true }
            }}
          </td>
          <td>
            <button
              nz-button
              nz-tooltip="Favorite"
              nzType="text"
              nzShape="circle"
              (click)="favorite([registryEntry.id], !registryEntry.favorite)"
              *ngIf="updateAllowed"
            >
              <i nz-icon nzType="star" *ngIf="!registryEntry.favorite"></i>
              <i
                nz-icon
                nzType="star"
                nzTheme="fill"
                *ngIf="registryEntry.favorite"
              ></i>
            </button>
            <button
              nz-button
              nz-tooltip="Show/Hide"
              nzType="text"
              nzShape="circle"
              (click)="showHide([registryEntry.id], registryEntry.hidden)"
              *ngIf="updateAllowed"
            >
              <i nz-icon nzType="eye" *ngIf="!registryEntry.hidden"></i>
              <i
                nz-icon
                nzType="eye-invisible"
                *ngIf="registryEntry.hidden"
              ></i>
            </button>
            <button
              nz-button
              nz-tooltip="Remove"
              nzType="text"
              nzShape="circle"
              *ngIf="registryEntry.removable && deleteAllowed"
              (click)="confirmThenRemoveEntries([registryEntry.id])"
            >
              <i nz-icon nzType="close"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>

    <div class="global-actions" *ngIf="updateOrDeleteAllowed">
      <ul class="actions left">
        <li>
          <button
            nz-button
            nzType="default"
            (click)="selectAll()"
            [disabled]="!isAllSelected()"
          >
            Select all
          </button>
        </li>
        <li>
          <button
            nz-button
            nzType="default"
            (click)="unselectAll()"
            [disabled]="!isAllUnselected()"
          >
            Unselect all
          </button>
        </li>
      </ul>
      <ul class="actions right">
        <li>
          <button
            *ngIf="updateAllowed"
            nz-button
            nz-dropdown
            [nzDropdownMenu]="menuAction2"
            [disabled]="!hasSelected()"
          >
            Actions
            <span nz-icon nzType="down"></span>
          </button>
        </li>
      </ul>
    </div>
  </section>
</div>

<nz-dropdown-menu #menuAction1="nzDropdownMenu">
  <ng-container [ngTemplateOutlet]="actionMenu"></ng-container>
</nz-dropdown-menu>

<nz-dropdown-menu #menuAction2="nzDropdownMenu">
  <ng-container [ngTemplateOutlet]="actionMenu"></ng-container>
</nz-dropdown-menu>

<ng-template #actionMenu>
  <ul nz-menu>
    <li
      nz-menu-item
      (click)="favoriteSelectedEntries(true)"
      *ngIf="updateAllowed"
    >
      Favorite
    </li>
    <li
      nz-menu-item
      (click)="favoriteSelectedEntries(false)"
      *ngIf="updateAllowed"
    >
      Unfavorite
    </li>
    <li
      nz-menu-item
      (click)="showHideSelectedEntries(true)"
      *ngIf="updateAllowed"
    >
      Visible
    </li>
    <li
      nz-menu-item
      (click)="showHideSelectedEntries(false)"
      *ngIf="updateAllowed"
    >
      Hidden
    </li>
    <li
      nz-menu-item
      (click)="confirmThenRemoveSelectedEntries()"
      nzDanger
      *ngIf="deleteAllowed"
    >
      Remove
    </li>
  </ul>
</ng-template>
