import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { TrackersService } from "services/trackers.service";
import { RoutingService } from "services/routing.service";
import { IndicatorStatsSurveyPageComponent } from "../indicator.component";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { IndustriesScores } from "resolvers/asset-industries-scores";
import { AnalyticsResponse } from "models/analytics.model";
import {
  BarClickEvent,
  RepartitionIndicatorValues,
} from "../components/repartition-indicator/repartition-indicator.component";
import { ChartDataset } from "chart.js";
import { TrendIndicatorLegend } from "../components/trend-indicator/trend-indicator.config";
import {
  bucketToDatedAggregation,
  getDateAnswerValueBuckets,
  getIndustryAverageScore,
} from "../indicator.utils";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import {
  bucketToCesAggregation,
  CesAggregation,
  cesEmojis,
  cesPerDateToChartDataset,
  computeCesPeriod,
  computeCesRepartition,
  computeCesVariationBewteenPeriod,
} from "./ces.aggregation";
import { EntitlementService } from "services/entitlement.service";

import { Release } from "models/release.model";
import { Account } from "models/account.model";
import { startOfMonth } from "date-fns";
import { UIService } from "services/ui.service";

@Component({
  selector: "page-survey-stats-indicators-ces",
  templateUrl: "./ces.component.html",
  styleUrls: ["./ces.component.scss"],
})
export class CESIndicatorStatsSurveyPageComponent
  extends IndicatorStatsSurveyPageComponent
  implements OnInit, OnDestroy
{
  public title = "Analyze - Customer Effort Score";
  public name = "Analyze customer effort score";

  public cesPerDatePreviousPeriod: CesAggregation[] = null;
  public cesPerDateCurrentPeriod: CesAggregation[] = null;
  public cesPreviousPeriod: CesAggregation = null;
  public cesCurrentPeriod: CesAggregation = null;
  public cesVariation: CesAggregation = null;
  public cesRepartition: RepartitionIndicatorValues = [];

  public industryAverageCes = 50;

  public trendChartDataset: ChartDataset[] = [];

  public releases: Release[] = [];
  public orgAccounts: Account[] = [];
  public trendChartLegend: TrendIndicatorLegend = [
    {
      label: "CES",
      value: "CES",
      checked: true,
      color: "#5E21F1",
      hoverColor: "#5E21F1",
      format: "number",
    },
    {
      label: "High effort",
      value: "High effort",
      checked: true,
      color: "#F11672",
      hoverColor: "#F11672",
    },
    {
      label: "Medium effort",
      value: "Medium effort",
      checked: true,
      color: "#FFC046",
      hoverColor: "#FFC046",
    },
    {
      label: "Small effort",
      value: "Small effort",
      checked: true,
      color: "#1ED5A4",
      hoverColor: "#1ED5A4",
    },
  ];

  constructor(
    router: Router,
    route: ActivatedRoute,
    routingService: RoutingService,
    analyticsDao: AnalyticsDao,
    analyticsFilterService: AnalyticsFilterService,
    trackersService: TrackersService,
    public uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
  ) {
    super(
      router,
      route,
      routingService,
      analyticsDao,
      analyticsFilterService,
      trackersService,
      uiService,
    );
  }

  ngOnInit(): void {
    this.obs = this.route.data.subscribe((data) => {
      this.releases = data.releases;
      this.orgAccounts = data.orgAccounts;

      this.industryAverageCes = getIndustryAverageScore(
        data.industriesScores as IndustriesScores,
        "ces",
        data.org.industry,
      );
    });

    this.init();
    this.resetFilters(startOfMonth(new Date()));
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  async getResponseCallback(
    previousPeriod: AnalyticsResponse,
    currentPeriod: AnalyticsResponse,
  ) {
    this.cesPerDatePreviousPeriod = bucketToDatedAggregation(
      previousPeriod,
      (bucket) =>
        bucketToCesAggregation(
          bucket,
          getDateAnswerValueBuckets,
          this.survey.scenario,
        ),
    );
    this.cesPerDateCurrentPeriod = bucketToDatedAggregation(
      currentPeriod,
      (bucket) =>
        bucketToCesAggregation(
          bucket,
          getDateAnswerValueBuckets,
          this.survey.scenario,
        ),
    );

    this.trendChartDataset = cesPerDateToChartDataset(
      this.cesPerDateCurrentPeriod,
    );
    this.cesPreviousPeriod = computeCesPeriod(this.cesPerDatePreviousPeriod);
    this.cesCurrentPeriod = computeCesPeriod(this.cesPerDateCurrentPeriod);

    this.cesVariation = computeCesVariationBewteenPeriod(
      this.cesPreviousPeriod,
      this.cesCurrentPeriod,
    );

    this.cesRepartition = computeCesRepartition(
      currentPeriod,
      this.survey.scenario,
    );
  }

  public repartitionProgressClicked(
    group: "smallEffort" | "mediumEffort" | "highEffort",
  ) {
    let scoresLabels: number[] = [];

    switch (group) {
      case "smallEffort":
        scoresLabels = [6, 7];
        break;
      case "mediumEffort":
        scoresLabels = [4, 5];
        break;
      case "highEffort":
        scoresLabels = [1, 2, 3];
        break;
    }

    this.setFiltersToSpecificScores(scoresLabels, "ces");
  }

  public repartitionChartBarClick($event: BarClickEvent) {
    this.setFiltersToSpecificScores(
      [cesEmojis.findIndex((emoji) => emoji === $event.value.label) + 1],
      "ces",
    );
  }
}
