<section class="header-section" *ngIf="scope !== 'org'">
  <div class="design-switch label">
    <span>
      I want a specific design for this
      {{
        scope === "distribution"
          ? "channel"
          : typeSelected === "survey"
            ? "survey"
            : "message"
      }}
      <p>This will override the default design</p>
    </span>
    <nz-switch
      ngDefaultControl
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="isSpecificDesignActivated()"
      (ngModelChange)="syncTab(); specificDesignSwitchChange.emit($event)"
    >
    </nz-switch>
  </div>
</section>

<section
  class="design-content"
  *ngIf="tagSettings && typeSelected === 'survey' && interaction !== 'in_page'"
>
  <p class="label" *ngIf="scope === 'org'">Default format</p>
  <p class="label" *ngIf="scope !== 'org'">Format</p>
  <p class="label-desc">Choose the format of your survey</p>
  <nz-radio-group
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [(ngModel)]="tagSettings.format"
    (ngModelChange)="formatChange($event)"
  >
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col nzXs="24" nzXl="12">
        <big-radio
          id="survey-tag-settings-design-format-conversationnal"
          nzValue="conversationnal"
          label="Conversational"
          badgeText="Best response rate"
          [content]="formatConversationalBigRadio"
        >
          <ng-template #formatConversationalBigRadio>
            <span class="format-radio-label">
              Perfect format to engage your users in an interactive and chatty
              way.
              <br />
              Its response rate is usually between
              <strong>40%</strong> and <strong>60%</strong>.
            </span>
          </ng-template>
        </big-radio>
      </div>

      <div nz-col nzXs="24" nzXl="12">
        <big-radio
          id="survey-tag-settings-design-format-cards"
          nzValue="cards"
          label="Card"
          [content]="formatCardBigRadio"
        >
          <ng-template #formatCardBigRadio>
            <span class="format-radio-label">
              More traditional format, and perfect for all kind of survey
              purposes.
              <br />
              Response rate usually between <strong>20%</strong> and
              <strong>40%</strong>.
            </span>
          </ng-template>
        </big-radio>
      </div>
    </div>
  </nz-radio-group>
</section>

<section class="design-info" *ngIf="tagSettings">
  <div>
    <p class="label">Graphical Settings</p>
    <p class="label-desc" *ngIf="!(scope !== 'org' && tagSettings?.advanced)">
      This is where you define how your
      {{ typeSelected === "survey" ? "surveys" : "messages" }} will look like,
      check the preview to check the result of the selected options.
    </p>
    <div
      class="label-desc no-flex"
      *ngIf="scope !== 'org' && tagSettings?.advanced"
    >
      You've set custom graphical settings for all your surveys.<br />
      If you want to change colours, fonts, etc, you have to change your global
      settings
      <a [routerLink]="['/', 'org', org.id, 'settings']">here</a>.<br />
      These changes will be applied to all your surveys.
    </div>
  </div>
  <div
    *ngIf="
      scope === 'org' && ('advanced_survey_customization' | hasEntitlement)
    "
  >
    <div class="design-switch label">
      <span>
        Advanced customisation
        <p>Unlock more customisation options</p>
      </span>
      <nz-switch
        ngDefaultControl
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="tagSettings.advanced"
        (ngModelChange)="advancedCustomisationChanged($event)"
      >
      </nz-switch>
    </div>
  </div>
</section>

<nz-tabset
  *ngIf="tagSettings && typeSelected === 'survey'"
  [nzSelectedIndex]="tabSelectedIndex"
  [nzAnimated]="false"
  (nzSelectedIndexChange)="changeTab($event)"
>
  <nz-tab nzTitle="Conversational" *ngIf="interaction !== 'in_page'">
    <section nz-row [nzGutter]="[32, 16]">
      <!-- Conversational General -->
      <div nz-col nzSpan="24">
        <div class="label">General</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">
              Pop sound &nbsp;<utils-badge text="New"></utils-badge>
            </div>
            <div class="play-sound-wrapper">
              <nz-select
                class="select-100"
                [nzOptions]="popSoundsOptions"
                nzSize="large"
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.conversational_pop_sound"
              >
              </nz-select>

              <button
                nz-button
                [disabled]="
                  tagSettings.conversational_pop_sound === 'none' ||
                  !canPlayAudio
                "
                class="play-pop-sound"
                (click)="playSound(tagSettings.conversational_pop_sound)"
              >
                <screeb-icon *ngIf="!soundPlaying" size="md" icon="play" />
                <screeb-icon *ngIf="soundPlaying" size="md" icon="pause" />
              </button>
            </div>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Overall size</div>
            <nz-select
              class="select-100"
              [nzOptions]="sizeOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_size"
            >
            </nz-select>
          </div>

          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">Position</div>
            <nz-select
              class="select-100"
              [nzOptions]="conversationalPlacementOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_position"
            >
            </nz-select>
          </div>
          <div
            nz-col
            nzSpan="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Buttons</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.conversational_display_close_reduce =
                    !tagSettings.conversational_display_close_reduce
                "
                >Display close and reduce buttons</span
              >
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.conversational_display_close_reduce"
              ></nz-switch>
            </div>
          </div>
        </section>
      </div>

      <div
        nz-col
        nzSpan="24"
        *ngIf="(scope !== 'org' && !tagSettings.advanced) || scope === 'org'"
      >
        <hr />
        <p class="label">Colors</p>
        <section nz-row [nzGutter]="[24, 8]">
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              (scope !== 'org' && !tagSettings.advanced) || scope === 'org'
            "
          >
            <div class="label-desc">Primary color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidPrimaryColor(
                  tagSettings.conversational_color_primary
                )
              }"
              (colorChange)="conversationalPrimaryColorChange($event)"
              [(color)]="tagSettings.conversational_color_primary"
            ></color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              (scope !== 'org' && !tagSettings.advanced) || scope === 'org'
            "
          >
            <div class="label-desc">Primary text color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidPrimaryTextColor(
                  tagSettings.conversational_color_primary_text
                )
              }"
              [(color)]="tagSettings.conversational_color_primary_text"
            >
            </color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Secondary color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidSecondaryColor(
                  tagSettings.conversational_color_secondary
                )
              }"
              (colorChange)="conversationalSecondaryColorChange($event)"
              [(color)]="tagSettings.conversational_color_secondary"
            ></color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Secondary text color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidSecondaryTextColor(
                  tagSettings.conversational_color_secondary_text
                )
              }"
              [(color)]="tagSettings.conversational_color_secondary_text"
            >
            </color-picker>
          </div>
        </section>
      </div>

      <div nz-col nzSpan="24" *ngIf="tagSettings.advanced && scope === 'org'">
        <hr />
        <p class="label">Font</p>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Family</div>
            <font-select
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_font_family"
            >
            </font-select>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Size</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_font_size"
              [nzMin]="1"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidFontSize(tagSettings.conversational_font_size)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Line height</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_font_line_height"
              [nzMin]="1"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidFontLineHeight(
                  tagSettings.conversational_font_line_height
                )
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>
        </section>
      </div>

      <!-- Conversational Border -->
      <div nz-col nzSpan="24" *ngIf="tagSettings.advanced && scope === 'org'">
        <hr />
        <p class="label">Border</p>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">Radius</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_border_radius"
              [nzMin]="0"
              [nzMax]="100"
              [nzStep]="1"
              [nzStatus]="
                isValidBorderRadius(tagSettings.conversational_border_radius)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div nz-col nzSpan="24" nzXl="12">
            <div class="label-desc">Border line</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.conversational_border_line =
                    !tagSettings.conversational_border_line
                "
                >Customize border line</span
              >
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.conversational_border_line"
              ></nz-switch>
            </div>
          </div>

          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.conversational_border_line"
          >
            <div class="label-desc">Line width</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_border_line_width"
              [nzMin]="1"
              [nzMax]="20"
              [nzStep]="1"
              [nzStatus]="
                isValidBorderLineWidth(
                  tagSettings.conversational_border_line_width
                )
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.conversational_border_line"
          >
            <div class="label-desc">Line color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidBorderLineColor(
                  tagSettings.conversational_border_line_color
                )
              }"
              [alpha]="true"
              [(color)]="tagSettings.conversational_border_line_color"
            ></color-picker>
          </div>
        </section>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzSpan="24" nzMd="12">
            <div class="label-desc">Border shadow</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.conversational_border_shadow =
                    !tagSettings.conversational_border_shadow
                "
                >Customize border shadow</span
              >
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.conversational_border_shadow"
              ></nz-switch>
            </div>
          </div>

          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.conversational_border_shadow"
          >
            <div class="label-desc">Shadow color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidBorderShadowColor(
                  tagSettings.conversational_border_shadow_color
                )
              }"
              [alpha]="true"
              [color]="tagSettings.conversational_border_shadow_color"
              (colorChange)="onBorderShadowColorChange($event)"
            ></color-picker>
          </div>
        </section>
      </div>

      <!-- Conversational Overlay -->
      <div nz-col nzSpan="24" *ngIf="tagSettings.advanced && scope === 'org'">
        <hr />
        <div class="label">Overlay</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Visibility</div>
            <nz-select
              class="select-100"
              [nzOptions]="overlayVisibilityOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_overlay_visibility"
            >
            </nz-select>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidOverlayColor(
                  tagSettings.conversational_overlay_color
                )
              }"
              [alpha]="true"
              [(color)]="tagSettings.conversational_overlay_color"
            ></color-picker>
          </div>
        </section>
      </div>

      <div nz-col nzSpan="24">
        <hr />
        <div class="label">Display name, avatar and title</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">From</div>
            <nz-select
              class="select-100"
              [nzOptions]="identityTypeOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_identity_type"
            >
            </nz-select>
          </div>
          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.conversational_identity_type === 'fixed'"
          >
            <div class="label-desc">Avatar</div>
            <file-image-picker
              [canDelete]="false"
              [maxSize]="maxAvatarSize"
              [loading]="loadingAvatar"
              [resize]="{ minWidth: 512, minHeight: 512 }"
              [imageUrl]="tagSettings.conversational_identity_fixed_avatar"
              (imageFileChange)="
                imageAvatarFileChange($event, 'survey', 'conversationnal')
              "
            >
            </file-image-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.conversational_identity_type === 'fixed'"
          >
            <div class="label-desc">Name</div>
            <input
              type="text"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_identity_fixed_name"
              nzSize="large"
              nz-input
              [minLength]="1"
              [maxLength]="56"
              placeholder="Sophia"
              [ngClass]="{
                invalid: !isValidName(
                  tagSettings.conversational_identity_fixed_name
                )
              }"
            />
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.conversational_identity_type === 'fixed'"
          >
            <div class="label-desc">Title or job</div>
            <input
              type="text"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_identity_fixed_title"
              nzSize="large"
              nz-input
              [minLength]="3"
              [maxLength]="128"
              placeholder="Founder"
              [ngClass]="{
                invalid: !isValidTitle(
                  tagSettings.conversational_identity_fixed_title
                )
              }"
            />
          </div>

          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Avatar format</div>
            <nz-select
              class="select-100"
              [nzOptions]="avatarFormatOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_identity_avatar_format"
            >
            </nz-select>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          ></div>
        </section>
      </div>
    </section>
  </nz-tab>

  <nz-tab nzTitle="Cards" *ngIf="interaction !== 'in_page'">
    <section nz-row [nzGutter]="[32, 16]">
      <!-- Cards General -->
      <div nz-col nzSpan="24">
        <div class="label">General</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">
              Pop sound &nbsp;<utils-badge text="New"></utils-badge>
            </div>
            <div class="play-sound-wrapper">
              <nz-select
                class="select-100"
                [nzOptions]="popSoundsOptions"
                nzSize="large"
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.cards_pop_sound"
              >
              </nz-select>

              <button
                nz-button
                [disabled]="
                  tagSettings.cards_pop_sound === 'none' || !canPlayAudio
                "
                class="play-pop-sound"
                (click)="playSound(tagSettings.cards_pop_sound)"
              >
                <screeb-icon *ngIf="!soundPlaying" size="md" icon="play" />
                <screeb-icon *ngIf="soundPlaying" size="md" icon="pause" />
              </button>
            </div>
          </div>
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">Position</div>
            <nz-select
              class="select-100"
              [nzOptions]="cardsPlacementOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_position"
            >
            </nz-select>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Overall size</div>
            <nz-select
              class="select-100"
              [nzOptions]="sizeOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_size"
            >
            </nz-select>
          </div>
          <div
            nz-col
            nzSpan="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Buttons</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.cards_display_reduce_button =
                    !tagSettings.cards_display_reduce_button
                "
                >Display reduce button</span
              >
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.cards_display_reduce_button"
              ></nz-switch>
            </div>
          </div>
        </section>
      </div>

      <!-- Cards Colors -->
      <div
        nz-col
        nzSpan="24"
        *ngIf="(scope !== 'org' && !tagSettings.advanced) || scope === 'org'"
      >
        <hr />
        <div class="label">Colors</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              (scope !== 'org' && !tagSettings.advanced) || scope === 'org'
            "
          >
            <div class="label-desc">Primary color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidPrimaryColor(tagSettings.cards_color_primary)
              }"
              (colorChange)="cardsPrimaryColorChange($event)"
              [(color)]="tagSettings.cards_color_primary"
            ></color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              (scope !== 'org' && !tagSettings.advanced) || scope === 'org'
            "
          >
            <div class="label-desc">Primary text color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidPrimaryTextColor(
                  tagSettings.cards_color_primary_text
                )
              }"
              [(color)]="tagSettings.cards_color_primary_text"
            >
            </color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Secondary color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidSecondaryColor(
                  tagSettings.cards_color_secondary
                )
              }"
              (colorChange)="cardsSecondaryColorChange($event)"
              [(color)]="tagSettings.cards_color_secondary"
            ></color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Secondary text color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidSecondaryTextColor(
                  tagSettings.cards_color_secondary_text
                )
              }"
              [(color)]="tagSettings.cards_color_secondary_text"
            >
            </color-picker>
          </div>
        </section>
      </div>

      <!-- Cards Font -->
      <div nz-col nzSpan="24" *ngIf="tagSettings.advanced && scope === 'org'">
        <hr />
        <div class="label">Font</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Family</div>
            <font-select
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_font_family"
            >
            </font-select>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Size</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_font_size"
              [nzMin]="1"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidFontSize(tagSettings.cards_font_size)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Line height</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_font_line_height"
              [nzMin]="1"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidFontLineHeight(tagSettings.cards_font_line_height)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>
        </section>
      </div>

      <!-- Cards Border -->
      <div nz-col nzSpan="24" *ngIf="tagSettings.advanced && scope === 'org'">
        <hr />
        <p class="label">Border</p>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">Border radius</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_border_radius"
              [nzMin]="0"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidBorderRadius(tagSettings.cards_border_radius)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div nz-col nzSpan="24" nzXl="12">
            <div class="label-desc">Border line</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.cards_border_line = !tagSettings.cards_border_line
                "
                >Customize Border line</span
              >
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.cards_border_line"
              ></nz-switch>
            </div>
          </div>

          <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.cards_border_line">
            <div class="label-desc">Border line width</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_border_line_width"
              [nzMin]="1"
              [nzMax]="20"
              [nzStep]="1"
              [nzStatus]="
                isValidBorderLineWidth(tagSettings.cards_border_line_width)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.cards_border_line">
            <div class="label-desc">Border line color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidBorderLineColor(
                  tagSettings.cards_border_line_color
                )
              }"
              [alpha]="true"
              [(color)]="tagSettings.cards_border_line_color"
            ></color-picker>
          </div>
        </section>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzSpan="24" nzMd="12">
            <div class="label-desc">Border shadow</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.cards_border_shadow =
                    !tagSettings.cards_border_shadow
                "
                >Customize border shadow</span
              >
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.cards_border_shadow"
              ></nz-switch>
            </div>
          </div>

          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.cards_border_shadow"
          >
            <div class="label-desc">Border shadow color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidBorderShadowColor(
                  tagSettings.cards_border_shadow_color
                )
              }"
              [alpha]="true"
              [(color)]="tagSettings.cards_border_shadow_color"
            ></color-picker>
          </div>
        </section>
      </div>

      <!-- Cards Overlay -->
      <div
        hidden
        nz-col
        nzSpan="24"
        *ngIf="tagSettings.advanced && scope === 'org'"
      >
        <hr />
        <div class="label">Overlay</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Visibility</div>
            <nz-select
              class="select-100"
              [nzOptions]="overlayVisibilityOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_overlay_visibility"
            >
            </nz-select>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="abel-desc">Color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidOverlayColor(tagSettings.cards_overlay_color)
              }"
              [alpha]="true"
              [(color)]="tagSettings.cards_overlay_color"
            ></color-picker>
          </div>
        </section>
      </div>

      <!-- Cards Display name, avatar and title -->
      <div nz-col nzSpan="24">
        <hr />
        <div class="label">Display name, avatar and title</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">From</div>
            <nz-select
              class="select-100"
              [nzOptions]="identityTypeOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_identity_type"
            >
            </nz-select>
          </div>
          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.cards_identity_type === 'fixed'"
          >
            <div class="label-desc">Avatar</div>
            <file-image-picker
              [canDelete]="false"
              [maxSize]="maxAvatarSize"
              [loading]="loadingAvatar"
              [resize]="{ minWidth: 512, minHeight: 512 }"
              [imageUrl]="tagSettings.cards_identity_fixed_avatar"
              (imageFileChange)="
                imageAvatarFileChange($event, 'survey', 'cards')
              "
            >
            </file-image-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.cards_identity_type === 'fixed'"
          >
            <div class="label-desc">Name</div>
            <input
              type="text"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_identity_fixed_name"
              nzSize="large"
              nz-input
              [minLength]="1"
              [maxLength]="56"
              placeholder="Sophia"
              [ngClass]="{
                invalid: !isValidName(tagSettings.cards_identity_fixed_name)
              }"
            />
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.cards_identity_type === 'fixed'"
          >
            <div class="label-desc">Title or job</div>
            <input
              type="text"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_identity_fixed_title"
              nzSize="large"
              nz-input
              [minLength]="3"
              [maxLength]="128"
              placeholder="Founder"
              [ngClass]="{
                invalid: !isValidTitle(tagSettings.cards_identity_fixed_title)
              }"
            />
          </div>

          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Avatar format</div>
            <nz-select
              class="select-100"
              [nzOptions]="avatarFormatOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_identity_avatar_format"
            >
            </nz-select>
          </div>
        </section>
      </div>
    </section>
  </nz-tab>

  <nz-tab
    nzTitle="Link sharing"
    *ngIf="
      (scope !== 'distribution' || surveyDistribution.type === 'hosted-page') &&
      interaction !== 'in_page'
    "
  >
    <section>
      <div nz-row [nzGutter]="[32, 16]" nzAlign="bottom">
        <div nz-col nzXs="24" nzXl="12">
          <div class="label">Type of background</div>
          <div class="label-desc" *ngIf="scope === 'org'">
            Set the default background for all your surveys share via a link.
          </div>
          <div class="label-desc" *ngIf="scope !== 'org'">
            Set a background for all your surveys share via a link.
          </div>
          <nz-select
            class="select-100"
            [nzOptions]="backgroundTypeOptions"
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.page_background_type"
            (ngModelChange)="onLinkSharingBackgroundTypeChange($event)"
          >
          </nz-select>
        </div>

        <div
          nz-col
          nzAlign="bottom"
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.page_background_type === 'color'"
        >
          <div class="label">&nbsp;</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidBackgroundColor(
                tagSettings.page_background_color
              )
            }"
            [(color)]="tagSettings.page_background_color"
          >
          </color-picker>
        </div>

        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.page_background_type === 'image'"
        >
          <div class="label">&nbsp;</div>
          <file-image-picker
            [canDelete]="false"
            [maxSize]="maxBackgroundSize"
            [loading]="loadingBackground"
            [imageUrl]="tagSettings.page_background_image_url"
            (imageFileChange)="imageBackgroundFileChange($event)"
          >
          </file-image-picker>
        </div>
      </div>
    </section>
  </nz-tab>

  <nz-tab
    nzTitle="In-Page"
    *ngIf="
      (interaction === 'in_page' || scope === 'org') &&
      featureFlaggingService.isInteractionEnabled()
    "
  >
    <section nz-row [nzGutter]="[32, 16]">
      <!-- InPage General -->
      <div nz-col nzSpan="24">
        <div class="label">General</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">
              Pop sound &nbsp;<utils-badge text="New"></utils-badge>
            </div>
            <div class="play-sound-wrapper">
              <nz-select
                class="select-100"
                [nzOptions]="popSoundsOptions"
                nzSize="large"
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.in_page_pop_sound"
              >
              </nz-select>

              <button
                nz-button
                [disabled]="
                  tagSettings.in_page_pop_sound === 'none' || !canPlayAudio
                "
                class="play-pop-sound"
                (click)="playSound(tagSettings.in_page_pop_sound)"
              >
                <screeb-icon *ngIf="!soundPlaying" size="md" icon="play" />
                <screeb-icon *ngIf="soundPlaying" size="md" icon="pause" />
              </button>
            </div>
          </div>
          <!-- <div nz-col nzXs="24" nzXl="12" *ngIf="interaction !== 'in_page'"> -->
          <!--   <div class="label-desc">Position</div> -->
          <!--   <nz-select -->
          <!--     class="select-100" -->
          <!--     [nzOptions]="inPagePlacementOptions" -->
          <!--     nzSize="large" -->
          <!--     ngDefaultControl -->
          <!--     [ngModelOptions]="{ standalone: true }" -->
          <!--     [(ngModel)]="tagSettings.in_page_position" -->
          <!--   > -->
          <!--   </nz-select> -->
          <!-- </div> -->
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Overall size</div>
            <nz-select
              class="select-100"
              [nzOptions]="sizeOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.in_page_size"
            >
            </nz-select>
          </div>
        </section>
      </div>

      <!-- InPage Colors -->
      <div
        nz-col
        nzSpan="24"
        *ngIf="(scope !== 'org' && !tagSettings.advanced) || scope === 'org'"
      >
        <hr />
        <div class="label">Colors</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              (scope !== 'org' && !tagSettings.advanced) || scope === 'org'
            "
          >
            <div class="label-desc">Primary color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidPrimaryColor(tagSettings.in_page_color_primary)
              }"
              (colorChange)="inPagePrimaryColorChange($event)"
              [(color)]="tagSettings.in_page_color_primary"
            ></color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              (scope !== 'org' && !tagSettings.advanced) || scope === 'org'
            "
          >
            <div class="label-desc">Primary text color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidPrimaryTextColor(
                  tagSettings.in_page_color_primary_text
                )
              }"
              [(color)]="tagSettings.in_page_color_primary_text"
            >
            </color-picker>
          </div>
          <!-- <div -->
          <!--   nz-col -->
          <!--   nzXs="24" -->
          <!--   nzXl="12" -->
          <!--   *ngIf="tagSettings.advanced && scope === 'org'" -->
          <!-- > -->
          <!--   <div class="label-desc">Secondary color</div> -->
          <!--   <color-picker -->
          <!--     [ngClass]="{ -->
          <!--       invalid: !isValidSecondaryColor( -->
          <!--         tagSettings.in_page_color_secondary -->
          <!--       ) -->
          <!--     }" -->
          <!--     (colorChange)="inPageSecondaryColorChange($event)" -->
          <!--     [(color)]="tagSettings.in_page_color_secondary" -->
          <!--   ></color-picker> -->
          <!-- </div> -->
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings.advanced && scope === 'org'"
          >
            <div class="label-desc">Secondary text color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidSecondaryTextColor(
                  tagSettings.in_page_color_secondary_text
                )
              }"
              [(color)]="tagSettings.in_page_color_secondary_text"
            >
            </color-picker>
          </div>
        </section>
      </div>

      <!-- InPage Font -->
      <div nz-col nzSpan="24" *ngIf="tagSettings.advanced && scope === 'org'">
        <hr />
        <div class="label">Font</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Family</div>
            <font-select
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.in_page_font_family"
            >
            </font-select>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Size</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.in_page_font_size"
              [nzMin]="1"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidFontSize(tagSettings.in_page_font_size)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Line height</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.in_page_font_line_height"
              [nzMin]="1"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidFontLineHeight(tagSettings.in_page_font_line_height)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>
        </section>
      </div>

      <!-- In-page Overlay -->
      <div
        hidden
        nz-col
        nzSpan="24"
        *ngIf="tagSettings.advanced && scope === 'org'"
      >
        <hr />
        <div class="label">Overlay</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Visibility</div>
            <nz-select
              class="select-100"
              [nzOptions]="overlayVisibilityOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.in_page_overlay_visibility"
            >
            </nz-select>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="abel-desc">Color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidOverlayColor(tagSettings.in_page_overlay_color)
              }"
              [alpha]="true"
              [(color)]="tagSettings.in_page_overlay_color"
            ></color-picker>
          </div>
        </section>
      </div>
    </section>
  </nz-tab>
</nz-tabset>

<ng-container *ngIf="tagSettings && typeSelected === 'message'">
  <p class="label">General</p>
  <section nz-row [nzGutter]="[32, 16]">
    <div nz-col nzXs="24" nzXl="12">
      <div class="label-desc">
        Pop sound &nbsp;<utils-badge text="New"></utils-badge>
      </div>
      <div class="play-sound-wrapper">
        <nz-select
          class="select-100"
          [nzOptions]="popSoundsOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.messages_pop_sound"
        >
        </nz-select>

        <button
          nz-button
          [disabled]="
            tagSettings.messages_pop_sound === 'none' || !canPlayAudio
          "
          class="play-pop-sound"
          (click)="playSound(tagSettings.messages_pop_sound)"
        >
          <screeb-icon *ngIf="!soundPlaying" size="md" icon="play" />
          <screeb-icon *ngIf="soundPlaying" size="md" icon="pause" />
        </button>
      </div>
    </div>
    <div
      nz-col
      nzXs="24"
      nzXl="12"
      *ngIf="tagSettings.advanced && scope === 'org'"
    >
      <div class="label-desc">Overall size</div>
      <nz-select
        class="select-100"
        [nzOptions]="sizeOptions"
        nzSize="large"
        ngDefaultControl
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="tagSettings.messages_size"
      >
      </nz-select>
    </div>
    <div nz-col nzSpan="24" *ngIf="tagSettings.advanced && scope === 'org'">
      <hr />
      <div class="label">Border</div>
      <section nz-row [nzGutter]="[24, 8]">
        <div nz-col nzXs="24" nzXl="12">
          <div class="label-desc">Border radius</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_border_radius"
            [nzMin]="0"
            [nzMax]="100"
            [nzStep]="1"
            [nzStatus]="
              isValidBorderRadius(tagSettings.messages_border_radius)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>

        <div nz-col nzSpan="24" nzXl="12">
          <div class="label-desc">Border line</div>
          <div class="label-switch">
            <span
              (click)="
                tagSettings.messages_border_line =
                  !tagSettings.messages_border_line
              "
              >Customize border line</span
            >
            <nz-switch
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.messages_border_line"
            ></nz-switch>
          </div>
        </div>

        <div
          nz-col
          nzXs="24"
          nzMd="12"
          *ngIf="tagSettings.messages_border_line"
        >
          <div class="label-desc">Border line width</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_border_line_width"
            [nzMin]="1"
            [nzMax]="20"
            [nzStep]="1"
            [nzStatus]="
              isValidBorderLineWidth(tagSettings.messages_border_line_width)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>

        <div
          nz-col
          nzXs="24"
          nzMd="12"
          *ngIf="tagSettings.messages_border_line"
        >
          <div class="label-desc">Border line color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidBorderLineColor(
                tagSettings.messages_border_line_color
              )
            }"
            [alpha]="true"
            [(color)]="tagSettings.messages_border_line_color"
          ></color-picker>
        </div>
      </section>
      <section nz-row [nzGutter]="[24, 8]">
        <div nz-col nzSpan="24" nzMd="12">
          <div class="label-desc">Border shadow</div>
          <div class="label-switch">
            <span
              (click)="
                tagSettings.messages_border_shadow =
                  !tagSettings.messages_border_shadow
              "
              >Customize border shadow</span
            >
            <nz-switch
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.messages_border_shadow"
            ></nz-switch>
          </div>
        </div>

        <div
          nz-col
          nzXs="24"
          nzMd="12"
          *ngIf="tagSettings.messages_border_shadow"
        >
          <div class="label-desc">Border shadow color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidBorderShadowColor(
                tagSettings.messages_border_shadow_color
              )
            }"
            [alpha]="true"
            [(color)]="tagSettings.messages_border_shadow_color"
          ></color-picker>
        </div>
      </section>
    </div>
    <div nz-col nzSpan="24" *ngIf="tagSettings.advanced && scope === 'org'">
      <hr />
      <div class="label">Overlay</div>
      <section nz-row [nzGutter]="[24, 8]">
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Visibility</div>
          <nz-select
            class="select-100"
            [nzOptions]="messagesOverlayVisibilityOptions"
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_overlay_visibility"
          >
          </nz-select>
        </div>

        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidOverlayColor(tagSettings.messages_overlay_color)
            }"
            [alpha]="true"
            [(color)]="tagSettings.messages_overlay_color"
          ></color-picker>
        </div>
      </section>
    </div>
    <div nz-col nzSpan="24">
      <hr />
      <div class="label">Display name, avatar and title</div>
      <section nz-row [nzGutter]="[24, 8]">
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">From</div>
          <nz-select
            class="select-100"
            [nzOptions]="identityTypeOptions"
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_identity_type"
          >
          </nz-select>
        </div>
        <div
          nz-col
          nzXs="24"
          nzMd="12"
          *ngIf="tagSettings.messages_identity_type === 'fixed'"
        >
          <div class="label-desc">Avatar</div>
          <file-image-picker
            [canDelete]="false"
            [maxSize]="maxAvatarSize"
            [loading]="loadingAvatar"
            [resize]="{ minWidth: 512, minHeight: 512 }"
            [imageUrl]="tagSettings.messages_identity_fixed_avatar"
            (imageFileChange)="imageAvatarFileChange($event, 'message')"
          >
          </file-image-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.messages_identity_type === 'fixed'"
        >
          <div class="label-desc">Displayed name</div>
          <input
            type="text"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_identity_fixed_name"
            nzSize="large"
            nz-input
            [minLength]="1"
            [maxLength]="56"
            placeholder="Sophia"
            [ngClass]="{
              invalid: !isValidName(tagSettings.messages_identity_fixed_name)
            }"
          />
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.messages_identity_type === 'fixed'"
        >
          <div class="label-desc">Displayed title or job</div>
          <input
            type="text"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_identity_fixed_title"
            nzSize="large"
            nz-input
            [minLength]="3"
            [maxLength]="128"
            placeholder="Founder"
            [ngClass]="{
              invalid: !isValidTitle(tagSettings.messages_identity_fixed_title)
            }"
          />
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.advanced && scope === 'org'"
        >
          <div class="label-desc">Avatar format</div>
          <nz-select
            class="select-100"
            [nzOptions]="avatarFormatOptions"
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_identity_avatar_format"
          >
          </nz-select>
        </div>
      </section>
    </div>
  </section>

  <section nz-row [nzGutter]="[32, 16]">
    <div
      nz-col
      nzSpan="24"
      *ngIf="(scope !== 'org' && !tagSettings.advanced) || scope === 'org'"
    >
      <hr />
      <div class="label">Colors</div>
      <section nz-row [nzGutter]="[24, 8]">
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="(scope !== 'org' && !tagSettings.advanced) || scope === 'org'"
        >
          <div class="label-desc">Primary color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidPrimaryColor(tagSettings.messages_color_primary)
            }"
            (colorChange)="messagesPrimaryColorChange($event)"
            [(color)]="tagSettings.messages_color_primary"
          ></color-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="(scope !== 'org' && !tagSettings.advanced) || scope === 'org'"
        >
          <div class="label-desc">Primary text color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidPrimaryTextColor(
                tagSettings.messages_color_primary_text
              )
            }"
            [(color)]="tagSettings.messages_color_primary_text"
          >
          </color-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.advanced && scope === 'org'"
        >
          <div class="label-desc">Secondary color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidSecondaryColor(
                tagSettings.messages_color_secondary
              )
            }"
            (colorChange)="messagesSecondaryColorChange($event)"
            [(color)]="tagSettings.messages_color_secondary"
          ></color-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.advanced && scope === 'org'"
        >
          <div class="label-desc">Secondary text color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidSecondaryTextColor(
                tagSettings.messages_color_secondary_text
              )
            }"
            [(color)]="tagSettings.messages_color_secondary_text"
          >
          </color-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.advanced && scope === 'org'"
        >
          <div class="label-desc">Tertiary color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidSecondaryColor(
                tagSettings.messages_color_tertiary
              )
            }"
            (colorChange)="messagesTertiaryColorChange($event)"
            [(color)]="tagSettings.messages_color_tertiary"
          ></color-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.advanced && scope === 'org'"
        >
          <div class="label-desc">Tertiary text color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidTertiaryTextColor(
                tagSettings.messages_color_tertiary_text
              )
            }"
            [(color)]="tagSettings.messages_color_tertiary_text"
          >
          </color-picker>
        </div>
      </section>
    </div>

    <div nz-col nzSpan="24" *ngIf="tagSettings.advanced && scope === 'org'">
      <hr />
      <div class="label">Font</div>
      <section nz-row [nzGutter]="[24, 8]">
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Family for titles</div>
          <font-select
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_title_font_family"
          >
          </font-select>
        </div>
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Family for texts</div>
          <font-select
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_font_family"
          >
          </font-select>
        </div>

        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Size for titles</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_title_font_size"
            [nzMin]="1"
            [nzMax]="40"
            [nzStep]="1"
            [nzStatus]="
              isValidFontSize(tagSettings.messages_title_font_size)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Size for texts</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_font_size"
            [nzMin]="1"
            [nzMax]="40"
            [nzStep]="1"
            [nzStatus]="
              isValidFontSize(tagSettings.messages_font_size)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>

        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Weight for titles</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_title_font_weight"
            [nzMin]="100"
            [nzMax]="900"
            [nzStep]="100"
            [nzStatus]="
              isValidFontWeight(tagSettings.messages_title_font_weight)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Line height for texts</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_font_line_height"
            [nzMin]="1"
            [nzMax]="40"
            [nzStep]="1"
            [nzStatus]="
              isValidFontLineHeight(tagSettings.messages_font_line_height)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>
      </section>
    </div>
  </section>
</ng-container>
