/* eslint-disable @angular-eslint/no-output-on-prefix */

import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { autoTips } from "helpers/form-errors.helper";
import { NotificationHelper } from "helpers/notification.helper";
import { HookType, REGEXP_VALIDATION_WEBHOOK } from "models/hook.model";
import { IntegrationDao } from "models/integration.dao";
import { IntegrationSettingsCobbaiItem } from "models/integrations.model";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "integration-settings-cobbai-edit",
  templateUrl: "./cobbai-settings-edit.component.html",
  styleUrls: ["./cobbai-settings-edit.component.scss"],
})
export class IntegrationSettingsCobbaiEditComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public survey: Survey = null;
  @Input() public hook: IntegrationSettingsCobbaiItem = null;

  @Output() public onSave = new EventEmitter<IntegrationSettingsCobbaiItem>();
  @Output() public onCancel = new EventEmitter<unknown>();
  @Output() public onRemove = new EventEmitter<unknown>();

  private initialURL: string = null;
  public validateForm: FormGroup = null;
  public autoTips = autoTips;
  public replaying: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private integrationDao: IntegrationDao,
    private notificationHelper: NotificationHelper,
    private trackersService: TrackersService,
  ) {}

  ngOnInit() {
    // backup initial value, in order to trigger a sync on value change
    this.initialURL = this.hook?.path;

    this.validateForm = this.formBuilder.group({
      version: [this.hook.version, [Validators.required]],
      name: [this.hook.name, [Validators.required]],
      path: [
        this.hook.path,
        [
          Validators.required,
          Validators.pattern(REGEXP_VALIDATION_WEBHOOK),
          // Validators.pattern(REGEXP_VALIDATION_WEBHOOK_COBBAI),  // commented because cobbai team cannot test easily in dev and staging env
        ],
      ],
    });

    this.validate();
  }

  private validate() {}

  public save() {
    this.validate();

    if (!this.validateForm.valid) return;

    const item = {
      id: this.hook.id,
      version: this.validateForm.value["version"],
      name: this.validateForm.value["name"],
      path: this.validateForm.value["path"],
    } as IntegrationSettingsCobbaiItem;

    this.onSave.emit(item);

    // if (this.initialURL !== this.validateForm.value["path"]) {
    //   this.triggerHookReplay();
    // }
  }

  public cancel() {
    this.onCancel.emit();
  }

  public remove() {
    this.onRemove.emit();
  }

  public triggerHookReplay() {
    const hook: HookType = "response.ended";
    const params = {
      cobbai_endpoint: this.validateForm.value["path"],
      cobbai_version: this.validateForm.value["version"],
    };

    this.replaying = true;

    this.integrationDao
      .triggerHookReplay(
        this.org.id,
        "cobbai",
        hook,
        this.survey?.id ? [this.survey.id] : [],
        null,
        params,
      )
      .then(() => {
        this.notificationHelper.trigger(
          "A synchronisation of your Cobbai project has been ordered!",
          null,
          "success",
        );

        this.trackersService
          .newEventTrackingBuilder("Integration Cobbai sync requested")
          .withOrg(this.org)
          .withSurvey(this.survey)
          .withProps(params)
          .build();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);

        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to synchronize to Cobbai.",
          null,
          "error",
        );
      })
      .finally(() => {
        this.replaying = false;
      });
  }
}
