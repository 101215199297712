/* eslint-disable @angular-eslint/no-output-on-prefix */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { autoTips } from "helpers/form-errors.helper";
import { NotificationHelper } from "helpers/notification.helper";
import {
  hookIntegrationsSpec,
  HookType,
  REGEXP_VALIDATION_WEBHOOK,
  REGEXP_VALIDATION_WEBHOOK_ZAPIER,
} from "models/hook.model";
import { IntegrationDao } from "models/integration.dao";
import { IntegrationSettingsZapierItem } from "models/integrations.model";

@Component({
  selector: "integration-settings-zapier-edit",
  templateUrl: "./zapier-settings-edit.component.html",
  styleUrls: ["./zapier-settings-edit.component.scss"],
})
export class IntegrationSettingsZapierEditComponent implements OnInit {
  @Input() public orgId: string = null;
  @Input() public surveyId: string = null;
  @Input() public hook: IntegrationSettingsZapierItem = null;

  @Output() public onSave = new EventEmitter<IntegrationSettingsZapierItem>();
  @Output() public onCancel = new EventEmitter<unknown>();
  @Output() public onRemove = new EventEmitter<unknown>();

  public validateForm: FormGroup = null;
  public autoTips = autoTips;
  public hooksSpec = hookIntegrationsSpec.zapier.hooksSpec;

  constructor(
    private formBuilder: FormBuilder,
    private integrationDao: IntegrationDao,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit() {
    this.validateForm = this.formBuilder.group({
      version: [this.hook.version, [Validators.required]],
      name: [this.hook.name, [Validators.required]],
      path: [
        this.hook.path,
        [
          Validators.required,
          Validators.pattern(REGEXP_VALIDATION_WEBHOOK),
          Validators.pattern(REGEXP_VALIDATION_WEBHOOK_ZAPIER),
        ],
      ],
      hook: [this.hook.hook, [Validators.required]],
    });

    this.validate();
  }

  private validate() {}

  public save() {
    this.validate();

    if (!this.validateForm.valid) return;

    const item = {
      id: this.hook.id,
      version: this.validateForm.value["version"],
      name: this.validateForm.value["name"],
      path: this.validateForm.value["path"],
      hook: this.validateForm.value["hook"],
    } as IntegrationSettingsZapierItem;

    this.onSave.emit(item);
  }

  public cancel() {
    this.onCancel.emit();
  }

  public remove() {
    this.onRemove.emit();
  }

  public sendHookSample(hookType: HookType) {
    this.integrationDao
      .triggerHookSample(
        this.orgId,
        "zapier",
        hookType,
        this.validateForm.value["path"],
        this.validateForm.value["version"],
      )
      .then(() => {
        this.notificationHelper.trigger(
          "A sample has been sent to your webhook!",
          null,
          "success",
        );
      })
      .catch((err) => {
        console.error(err);

        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to send sample to webhook.",
          null,
          "error",
        );
      });
  }
}
