<section class="content">
  <div class="header-group">
    <h1>Benchmark</h1>
  </div>
</section>

<section class="benchmark-content-section" *ngIf="ready">
  <benchmark-summary
    [orgId]="org.id"
    type="RESPONSE_RATE"
    title="Response Rate"
    [companyName]="org.name"
    [companyScore]="org.stats?.total_survey_response_started_rate"
    [competitorScore]="industryStats.total_survey_response_started_rate"
    [topPerformerScore]="topPerformers.RESPONSE_RATE"
    [withObservation]="false"
  ></benchmark-summary>
  <benchmark-summary
    [orgId]="org.id"
    type="RESPONSE_COMPLETION_RATE"
    title="Completion Rate"
    [companyName]="org.name"
    [companyScore]="org.stats?.total_survey_response_fully_completed_rate"
    [competitorScore]="industryStats.total_survey_response_fully_completed_rate"
    [topPerformerScore]="topPerformers.RESPONSE_COMPLETION_RATE"
    [withObservation]="false"
  ></benchmark-summary>
  <benchmark-summary
    title="Conversion Rate"
    [withObservation]="false"
    [soon]="true"
  ></benchmark-summary>

  <benchmark-summary
    [orgId]="org.id"
    type="NPS"
    title="NPS"
    [companyName]="org.name"
    [companyScore]="org.stats?.total_nps_score"
    [competitorScore]="industryStats.total_nps_score"
    [topPerformerScore]="topPerformers.NPS"
  ></benchmark-summary>
  <benchmark-summary
    [orgId]="org.id"
    type="CSAT"
    title="Csat"
    [companyName]="org.name"
    [companyScore]="org.stats?.total_csat_score"
    [competitorScore]="industryStats.total_csat_score"
    [topPerformerScore]="topPerformers.CSAT"
  ></benchmark-summary>
  <benchmark-summary
    [orgId]="org.id"
    type="CES"
    title="CES"
    [companyName]="org.name"
    [companyScore]="org.stats?.total_ces_score"
    [competitorScore]="industryStats.total_ces_score"
    [topPerformerScore]="topPerformers.CES"
  ></benchmark-summary>
</section>

<section class="tutorials">
  <div class="metrics-analyze">
    <h2>How to Analyze your metrics</h2>
    <div class="metrics-analyze-content" *ngIf="analytic">
      <markdown ngPreserveWhitespaces [data]="analytic"></markdown>
    </div>
    <nz-card-loading *ngIf="!analytic"> </nz-card-loading>
  </div>
  <div class="links-101">
    <h2>Learn how to improve your performances to beat the competition</h2>
    <card-101-link
      title="Net Promoter Score: understanding and measuring your NPS with Screeb"
      color="#FFF6EA"
      url="https://screeb.app/blog/net-promoter-score-understanding-and-measuring-your-nps-with-screeb"
    ></card-101-link>
    <card-101-link
      title="Customer Effort Score: understanding and measuring your CES with Screeb"
      color="#E4F8F2"
      url="https://screeb.app/blog/customer-effort-score-understanding-and-measuring-your-ces-with-screeb"
    ></card-101-link>
    <card-101-link
      title="How to get a great response rate for your in-app surveys: the complete guide"
      color="#F5F1FF"
      url="https://screeb.app/blog/how-to-get-a-great-response-rate-for-your-in-app-surveys-the-complete-guide"
    ></card-101-link>
  </div>
</section>
