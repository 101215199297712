import { Component, Input } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";
import { Channel } from "models/channel.model";

@Component({
  selector: "rule-device",
  templateUrl: "./rule-device.component.html",
  styleUrls: ["./rule-device.component.scss"],
})
export class TargetingRuleDeviceComponent {
  @Input()
  public rule: SurveyTargetingRule = null;
  @Input()
  public channel: Channel = null;

  constructor() {}

  public onDeviceChange(
    rule: SurveyTargetingRule,
    device: string,
    checked: boolean,
  ) {
    if (checked) {
      rule.value.v_s_arr?.push(device);
    } else {
      rule.value.v_s_arr = rule.value.v_s_arr?.filter((d) => d !== device);
    }
  }
}
