import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "parent-layout",
  template: `<app-maintenance *ngIf="!appLoaded"></app-maintenance>
    <router-outlet *ngIf="appLoaded"></router-outlet>`,
  styleUrls: ["./parent-layout.component.scss", "./datepicker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
class ParentLayoutComponent implements OnInit, OnDestroy {
  private obs: any = null;

  constructor(private route: ActivatedRoute) {}

  public appLoaded: boolean = true;
  private forceMaintenance: boolean = false;

  ngOnInit() {
    this.obs = this.route.data.subscribe(({ _init_app }) => {
      if (this.forceMaintenance === true) {
        this.appLoaded = false;
      } else if (_init_app === true) {
        this.appLoaded = true;
      }
    });
  }

  ngOnDestroy() {
    this.obs?.unsubscribe();
  }
}

export { ParentLayoutComponent };
