<div class="menu-block" [ngClass]="{ 'menu-collapsed': isMenuCollapsed }">
  <!-- header of sidebar -->
  <div class="sidebar-logo">
    <a routerLink="/" routerLinkActive="active" *ngIf="!isMenuCollapsed">
      <img alt="Screeb" src="/assets/logo-white.png" />
    </a>

    <span
      class="sidebar-trigger reduce"
      *ngIf="!isMenuCollapsed"
      (click)="onClickMenuCollapse()"
    >
      <!--<i class="trigger" nz-icon [nzType]="'double-left'" nzTheme="outline"></i>-->
      <img alt="Menu" class="trigger" src="/assets/icons/layout/collapse.svg" />
    </span>
    <span
      class="sidebar-trigger grow-up"
      *ngIf="isMenuCollapsed"
      (click)="onClickMenuCollapse()"
    >
      <!--<i class="trigger" nz-icon [nzType]="'double-right'" nzTheme="outline"></i>-->
      <img alt="Menu" class="trigger" src="/assets/icons/layout/collapse.svg" />
    </span>
  </div>

  <!-- super org picker -->
  <ng-container
    *ngIf="uiService.superOrgs?.length && uiService.superOrgs.length > 1"
  >
    <ul
      class="org-picker {{ isSuperOrgSidebar ? 'super-org-sidebar' : '' }} {{
        superOrgPickerOpened ? 'is-active' : ''
      }}"
      nz-menu
      [routerLink]="
        uiService.superOrgs.length === 1
          ? '/overview/' + uiService.currentSuperOrgId + '/home'
          : null
      "
      nzMode="inline"
      [nzInlineCollapsed]="isMenuCollapsed"
    >
      <ng-template #orgTitle>
        <div class="org-picker-selected-wrapper">
          <screeb-icon size="md" icon="overview"></screeb-icon>
          <div class="org-picker-selected" *ngIf="!isMenuCollapsed">
            <span>Organization</span>
            <span>
              {{ uiService.currentSuperOrg?.name ?? "My organization" }}
            </span>
          </div>
        </div>
      </ng-template>
      <li
        nz-submenu
        [(nzOpen)]="superOrgPickerOpened"
        [nzTitle]="orgTitle"
        [nzDisabled]="uiService.superOrgs.length === 1"
      >
        <div class="org-picker-search" *ngIf="uiService.superOrgs.length > 9">
          <input
            type="text"
            placeholder="Search"
            [ngModel]="superOrgPickerSearch"
            (ngModelChange)="onSuperOrgSearchChange($event)"
          />
          <screeb-icon
            *ngIf="!superOrgPickerSearch.length"
            size="sm"
            icon="search"
          ></screeb-icon>
          <screeb-icon
            *ngIf="superOrgPickerSearch.length"
            class="clear-search"
            size="sm"
            icon="cross-circle"
            (click)="superOrgPickerSearch = ''"
          ></screeb-icon>
        </div>
        <ul *ngIf="superOrgPickerOpened">
          <li
            *ngIf="uiService.superOrgs.length > 9 && filteredOrgs?.length === 0"
            nz-menu-item
          >
            <div class="no-results">No orgs matching your search</div>
          </li>
          <li
            *ngFor="
              let org of uiService.superOrgs.length > 9
                ? filteredOrgs
                : uiService.superOrgs
            "
            nz-menu-item
          >
            <a
              [routerLink]="
                ('super-org.update' | hasSuperPermission)
                  ? '/overview/' + org.id
                  : getFirstWorkspaceURL(org.id)
              "
              routerLinkActive="active"
              (click)="superOrgPickerOpened = false"
            >
              {{ org.name }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </ng-container>

  <ul
    *ngIf="isSuperOrgSidebar && uiService.currentSuperOrg"
    nz-menu
    class="menu-actions"
    nzMode="inline"
    [nzInlineCollapsed]="isMenuCollapsed"
  >
    <li
      id="menu-overview"
      class="main-item"
      nz-menu-item
      nzTooltipPlacement="right"
      nz-tooltip
      nzMatchRouter
    >
      <a
        routerLink="/overview/{{ uiService.currentSuperOrg.id }}/home"
        routerLinkActive="active"
      >
        <screeb-icon size="md" icon="overview"></screeb-icon>
        <span *ngIf="!isMenuCollapsed">Overview</span>
      </a>
    </li>
  </ul>

  <!-- org picker -->
  <ng-container *ngIf="uiService.orgWorkspaces?.length">
    <ul
      class="org-picker {{ isSuperOrgSidebar ? 'super-org-sidebar' : '' }} {{
        isSuperOrgSidebar &&
        (orgPickerOpened ||
          uiService.currentPageRouteUrl?.indexOf(
            '/overview/' + uiService.currentSuperOrgId + '/workspaces'
          ) === 0)
          ? 'is-active'
          : ''
      }}"
      nz-menu
      nzMode="inline"
      [routerLink]="
        uiService.orgWorkspaces.length === 1 &&
        !('super-org.update' | hasSuperPermission)
          ? '/org/' + uiService.orgWorkspaces[0].id
          : null
      "
      [nzInlineCollapsed]="isMenuCollapsed"
    >
      <ng-template #workspaceTitle>
        <div class="org-picker-selected-wrapper">
          <screeb-icon size="md" icon="desktop"></screeb-icon>
          <div class="org-picker-selected" *ngIf="!isMenuCollapsed">
            <span
              class="{{
                !uiService.currentOrg || isSuperOrgSidebar ? 'main-title' : ''
              }}"
              >Workspace</span
            >
            <span *ngIf="uiService.currentOrg && !isSuperOrgSidebar">
              {{ uiService.currentOrg?.name }}
            </span>
          </div>
          <div *ngIf="!isMenuCollapsed" class="spacer"></div>
        </div>
      </ng-template>
      <li
        nz-submenu
        [(nzOpen)]="orgPickerOpened"
        [nzTitle]="workspaceTitle"
        [nzDisabled]="
          uiService.orgWorkspaces.length === 1 &&
          !('super-org.update' | hasSuperPermission)
        "
      >
        <div
          class="org-picker-search"
          *ngIf="uiService.orgWorkspaces.length > 9"
        >
          <input
            type="text"
            placeholder="Search"
            [ngModel]="orgPickerSearch"
            (ngModelChange)="onOrgSearchChange($event)"
          />
          <screeb-icon
            *ngIf="!orgPickerSearch.length"
            size="sm"
            icon="search"
          ></screeb-icon>
          <screeb-icon
            *ngIf="orgPickerSearch.length"
            class="clear-search"
            size="sm"
            icon="cross-circle"
            (click)="orgPickerSearch = ''"
          ></screeb-icon>
        </div>
        <ul *ngIf="orgPickerOpened">
          <li
            *ngIf="
              uiService.orgWorkspaces.length > 9 &&
              filteredWorkspaces?.length === 0
            "
            nz-menu-item
          >
            <div class="no-results">No workspaces matching your search</div>
          </li>
          <li
            nz-menu-item
            *ngIf="
              !isSuperOrgSidebar &&
              uiService.currentSuperOrg &&
              ('super-org.update' | hasSuperPermission)
            "
          >
            <a
              routerLink="/overview/{{ uiService.currentSuperOrg.id }}"
              (click)="orgPickerOpened = false"
            >
              <span>
                <screeb-icon size="md" icon="overview"></screeb-icon>
                Organization Overview
              </span>
            </a>
          </li>
          <li
            *ngFor="
              let org of uiService.orgWorkspaces.length > 9
                ? filteredWorkspaces
                : uiService.orgWorkspaces
            "
            nz-menu-item
          >
            <a routerLinkActive="active" (click)="onOrgSelected(org)">
              <span
                >{{ org.name }}
                {{
                  org.id === uiService.currentOrg?.id ? "(current)" : ""
                }}</span
              >
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </ng-container>

  <!-- Menu Bar -->
  <ng-container *ngIf="uiService.currentOrg && !isSuperOrgSidebar">
    <ul
      nz-menu
      class="menu-actions"
      nzMode="inline"
      [nzInlineCollapsed]="isMenuCollapsed"
    >
      <li
        id="menu-home"
        class="main-item"
        nz-menu-item
        [nzTooltipTitle]="isMenuCollapsed ? 'Home' : undefined"
        nzTooltipPlacement="right"
        nz-tooltip
        nzMatchRouter
      >
        <a
          routerLink="/org/{{ uiService.currentOrg?.id }}/home"
          routerLinkActive="active"
        >
          <screeb-icon size="md" icon="home"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Home</span>
        </a>
      </li>

      <ng-template #analyticsTitle>
        <a
          routerLink="/org/{{ uiService.currentOrg?.id }}/people/respondent"
          routerLinkActive="active"
          class="sub-menu-title"
        >
          <screeb-icon size="md" icon="analytics"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Analytics</span>
        </a>
      </ng-template>

      <li
        id="menu-analytics"
        class="main-item"
        nz-submenu
        [nzTitle]="analyticsTitle"
        [nzOpen]="true"
      >
        <ul>
          <li
            id="menu-sessions-recordings"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="
              isMenuCollapsed ? 'Sessions recordings' : undefined
            "
            nzTooltipPlacement="right"
            nz-tooltip
            *ngIf="
              ('session_recording' | hasEntitlement) &&
              ('isSessionRecordingEnabled' | hasFeatureFlag)
            "
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/people/sessions"
              routerLinkActive="active"
            >
              <span>Sessions recordings</span>
              <utils-badge
                text="Beta"
                backgroundColor="var(--screeb-color-yellow-500)"
                foregroundColor="var(--screeb-color-black)"
              ></utils-badge>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            id="menu-user-profiles"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'User Profiles' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/people/respondent"
              routerLinkActive="active"
            >
              <span>User Profiles</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            id="menu-user-segments"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'User Segments' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/people/segment"
              routerLinkActive="active"
            >
              <span>User Segments</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            id="menu-funnels"
            nz-menu-item
            nzMatchRouter
            [nzTooltipTitle]="isMenuCollapsed ? 'Funnels' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/funnel/list"
              routerLinkActive="active"
            >
              <span>Funnels</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
        </ul>
      </li>

      <ng-template #studiesTitle>
        <a
          routerLink="/org/{{ uiService.currentOrg?.id }}/survey/list"
          routerLinkActive="active"
          class="sub-menu-title"
        >
          <screeb-icon size="md" icon="studies"></screeb-icon>
          <!-- <img src="/assets/icons/users/respondent.png" /> -->
          <span *ngIf="!isMenuCollapsed">Studies</span>
        </a>
      </ng-template>
      <li
        id="menu-studies"
        class="main-item"
        nz-submenu
        [nzTitle]="studiesTitle"
        [nzOpen]="true"
      >
        <ul>
          <li
            id="menu-surveys"
            nz-menu-item
            [nzTooltipTitle]="isMenuCollapsed ? 'Surveys' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/survey/list"
              routerLinkActive="active"
            >
              <!-- <img src="/assets/icons/layout/surveys.svg" /> -->
              <span>Surveys</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            *ngIf="'isContextualMessagesEnabled' | hasFeatureFlag"
            id="menu-messages"
            nz-menu-item
            [nzTooltipTitle]="isMenuCollapsed ? 'Messages' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/message/list"
              routerLinkActive="active"
            >
              <span>Messages</span>
              <utils-badge
                text="Beta"
                backgroundColor="var(--screeb-color-yellow-500)"
                foregroundColor="var(--screeb-color-black)"
              ></utils-badge>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            id="menu-user-recruitment"
            nz-menu-item
            [nzTooltipTitle]="isMenuCollapsed ? 'User Recruitment' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/user-recruitment"
              routerLinkActive="active"
            >
              <!-- <img src="/assets/icons/layout/surveys.svg" /> -->
              <span>User Recruitment</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            *ngIf="'survey.create' | hasPermission"
            id="menu-surveys-templates"
            nz-menu-item
            [nzTooltipTitle]="isMenuCollapsed ? 'Templates' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/survey/create"
              routerLinkActive="active"
            >
              <!-- <img src="/assets/icons/layout/surveys.svg" /> -->
              <span>Templates</span>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
          <li
            id="menu-benchmark"
            nz-menu-item
            [nzTooltipTitle]="isMenuCollapsed ? 'Benchmark' : undefined"
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <a
              routerLink="/org/{{ uiService.currentOrg?.id }}/benchmark"
              routerLinkActive="active"
            >
              <!-- <img src="/assets/icons/layout/surveys.svg" /> -->
              <span>Benchmark</span>
              <utils-badge text="New"></utils-badge>
            </a>
            <div class="selected-item-indicator"></div>
          </li>
        </ul>
      </li>

      <li
        id="menu-releases"
        class="main-item"
        nz-menu-item
        nzMatchRouter
        [nzTooltipTitle]="isMenuCollapsed ? 'Releases' : undefined"
        nzTooltipPlacement="right"
        nz-tooltip
      >
        <a
          routerLink="/org/{{ uiService.currentOrg?.id }}/releases"
          routerLinkActive="active"
        >
          <screeb-icon size="md" icon="releases"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Releases</span>
        </a>
      </li>

      <li
        *ngIf="'integration.create' | hasPermission"
        id="menu-integrations"
        class="main-item"
        nz-menu-item
        nzMatchRouter
        [nzTooltipTitle]="isMenuCollapsed ? 'Integrations' : undefined"
        nzTooltipPlacement="right"
        nz-tooltip
      >
        <a
          routerLink="/org/{{ uiService.currentOrg?.id }}/integrations"
          routerLinkActive="active"
        >
          <screeb-icon
            size="md"
            icon="integrations"
            color="var(--screeb-color-white)"
          ></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Integrations</span>
        </a>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="uiService.currentSuperOrg && isSuperOrgSidebar">
    <ul
      nz-menu
      class="menu-actions"
      nzMode="inline"
      [nzInlineCollapsed]="isMenuCollapsed"
    >
      <li
        id="menu-teammates"
        class="main-item"
        nz-menu-item
        [nzTooltipTitle]="isMenuCollapsed ? 'Teammates' : undefined"
        nzTooltipPlacement="right"
        nz-tooltip
        nzMatchRouter
      >
        <a
          routerLink="/overview/{{ uiService.currentSuperOrg.id }}/users"
          routerLinkActive="active"
        >
          <screeb-icon size="md" icon="community"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Teammates</span>
        </a>
      </li>
      <li
        id="menu-billing"
        class="main-item"
        *ngIf="'billing.list' | hasSuperPermission"
        nz-menu-item
        [nzTooltipTitle]="isMenuCollapsed ? 'Billing' : undefined"
        nzTooltipPlacement="right"
        nz-tooltip
        nzMatchRouter
      >
        <a
          routerLink="/overview/{{ uiService.currentSuperOrg.id }}/billing"
          routerLinkActive="active"
        >
          <screeb-icon size="md" icon="nps"></screeb-icon>
          <span *ngIf="!isMenuCollapsed">Billing</span>
        </a>
      </li>
    </ul>
  </ng-container>
</div>

<!-- bottom menu -->
<div class="menu-block" [ngClass]="{ 'menu-collapsed': isMenuCollapsed }">
  <div
    class="schedule-demo"
    *ngIf="
      uiService.currentOrg &&
      !isMenuCollapsed &&
      (('isTrialing' | hasEntitlement) || !('hasSubscribed' | hasEntitlement))
    "
  >
    <button nz-button nzType="default" (click)="scheduleDemo()">
      Schedule a Demo
    </button>
  </div>

  <div class="bottom-menu">
    <button
      class="bottom-menu-button"
      nz-popover
      [nzPopoverPlacement]="isMenuCollapsed ? 'topLeft' : 'topRight'"
      nzPopoverTrigger="click"
      nzPopoverOverlayClassName="bottom-menu-overlay"
      [nzPopoverContent]="bottomMenuTemplate"
      [(nzPopoverVisible)]="bottomMenuPopoverVisible"
    >
      <org-account-avatar
        [noTooltip]="true"
        [account]="sessionService.session"
        size="small"
      ></org-account-avatar>
      <div class="user-fullname" *ngIf="!isMenuCollapsed">
        {{ sessionService.session.fullname }}
      </div>
      <screeb-icon
        size="sm"
        icon="select"
        *ngIf="!isMenuCollapsed"
      ></screeb-icon>
    </button>
  </div>
  <ng-template #bottomMenuTemplate>
    <ul>
      <li
        *ngIf="
          uiService.currentOrg &&
          uiService.currentSuperOrg &&
          uiService.currentSuperOrg.usedMTUPercent !== null
        "
      >
        <a
          href="javascript:void(0)"
          [routerLink]="
            ('billing.list' | hasSuperPermission)
              ? '/overview/' + uiService.currentSuperOrg?.id + '/billing'
              : null
          "
          (click)="closeBottomMenu()"
        >
          <screeb-icon
            *ngIf="uiService.currentSuperOrg.usedMTUPercent > 80"
            size="sm"
            icon="warning"
          ></screeb-icon>
          <screeb-icon
            *ngIf="uiService.currentSuperOrg.usedMTUPercent <= 80"
            size="sm"
            icon="info-circle"
          ></screeb-icon>
          <span>MTU Usage</span>
          <div class="spacer"></div>
          <span class="used-mtu">
            {{ uiService.currentSuperOrg.usedMTUPercent }}%
          </span>
        </a>
        <nz-progress
          [nzPercent]="uiService.currentSuperOrg.usedMTUPercent"
          nzSize="small"
          [nzShowInfo]="false"
          [nzStrokeColor]="
            uiService.currentSuperOrg.usedMTUPercent <= 80
              ? 'var(--screeb-color-primary)'
              : 'var(--screeb-color-error)'
          "
          [nzStatus]="
            uiService.currentSuperOrg.usedMTUPercent > 80
              ? 'exception'
              : 'normal'
          "
        ></nz-progress>
      </li>
      <li id="menu-settings" nzMatchRouter>
        <!-- the workspace queryParams is used by uiService for finding current workspace -->
        <a
          routerLink="/settings/account"
          [queryParams]="{ workspace: uiService.currentOrg?.id }"
          routerLinkActive="active"
          (click)="closeBottomMenu()"
        >
          <screeb-icon size="sm" icon="user"></screeb-icon>
          <span>Account Settings</span>
        </a>
      </li>
      <li
        *ngIf="uiService.currentOrg && !isSuperOrgSidebar"
        id="menu-settings"
        nzMatchRouter
      >
        <a
          routerLink="/org/{{ uiService.currentOrg?.id }}/settings"
          routerLinkActive="active"
          (click)="closeBottomMenu()"
        >
          <screeb-icon size="sm" icon="widget-settings"></screeb-icon>
          <span>Workspace Settings</span>
        </a>
      </li>
      <li
        id="menu-help"
        nz-popover
        nzPopoverPlacement="right"
        nzPopoverOverlayClassName="bottom-menu-overlay bottom-menu-overlay--secondary"
        [nzPopoverOverlayStyle]="{ marginLeft: '12px' }"
        [nzPopoverContent]="bottomMenuHelpTemplate"
      >
        <hr />
        <a href="javascript:void(0)">
          <screeb-icon size="sm" icon="question-mark"></screeb-icon>
          <span>Help & Docs</span>
          <screeb-icon size="sm" icon="righ-arrow"></screeb-icon>
        </a>
      </li>
      <li
        *ngIf="'isThemeSwitchEnabled' | hasFeatureFlag"
        id="menu-theme"
        nzMatchRouter
      >
        <a href="javascript:void(0)" (click)="toggleTheme()">
          <screeb-icon size="sm" icon="theme"></screeb-icon>
          <span>Toggle theme</span>
        </a>
      </li>
      <li id="menu-logout" nzMatchRouter (click)="closeBottomMenu()">
        <hr />
        <a (click)="logout()">
          <screeb-icon size="sm" icon="log-out"></screeb-icon>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </ng-template>
  <ng-template #bottomMenuHelpTemplate>
    <ul>
      <li id="menu-feedback" nzMatchRouter>
        <a
          id="feedback"
          href="mailto:simon@screeb.app"
          (click)="onFeedback(); closeBottomMenu()"
          target="_blank"
        >
          <screeb-icon size="sm" icon="share"></screeb-icon>
          <span>Share feedback</span>
        </a>
      </li>
      <li id="menu-talk-to-human" nzMatchRouter (click)="closeBottomMenu()">
        <intercom>
          <a id="intercom-help-i-need-somebody-help">
            <screeb-icon size="sm" icon="talk-to-a-human"></screeb-icon>
            <span>Talk to a human</span>
            <!-- Human assistance -->
          </a>
        </intercom>
      </li>
      <li id="menu-help-center" nzMatchRouter (click)="closeBottomMenu()">
        <a rel="noopener" href="https://help.screeb.app" target="_blank">
          <screeb-icon size="sm" icon="question-mark"></screeb-icon>
          <span>Help Center</span>
        </a>
      </li>
      <li id="menu-changelog" (click)="closeBottomMenu()">
        <a rel="noopener" href="https://changelog.screeb.app" target="_blank">
          <screeb-icon size="sm" icon="notification"></screeb-icon>
          <span>Changelog</span>
        </a>
      </li>
      <li
        id="menu-developer-documentation"
        nzMatchRouter
        (click)="closeBottomMenu()"
      >
        <a rel="noopener" href="https://developers.screeb.app" target="_blank">
          <screeb-icon size="sm" icon="dev-documentation"></screeb-icon>
          <span>Developer doc.</span>
        </a>
      </li>
      <li id="menu-status-page" nzMatchRouter (click)="closeBottomMenu()">
        <a
          rel="noopener"
          href="{{ configService.config.statusEndpoint }}"
          target="_blank"
        >
          <screeb-icon size="sm" icon="analyze"></screeb-icon>
          <span>Status page</span>
        </a>
      </li>
    </ul>
  </ng-template>
</div>
