<ng-container *ngIf="hook">
  <form
    nz-form
    [nzAutoTips]="autoTips"
    [formGroup]="validateForm"
    (ngSubmit)="save()"
  >
    <nz-form-item>
      <nz-form-label>Name</nz-form-label>
      <nz-form-control>
        <input
          type="text"
          nz-input
          nzHasFeedback
          formControlName="name"
          placeholder="My Cobbai Project"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>URL</nz-form-label>
      <nz-form-control [nzErrorTip]="errorTpl">
        <nz-input-group nzAddOnBefore="POST">
          <input
            type="url"
            nz-input
            nzHasFeedback
            formControlName="path"
            placeholder="https://xxxxxxxxx.cobbai.com/xxxxxxxxx/connectors/listener_screeb/synchronize?import_id=xxxxxxxxx&token=xxxxxxxxx"
          />
          <ng-template #errorTpl let-control>
            <span class="field-error" *ngIf="control.hasError('pattern')">
              Malformed URL
            </span>
          </ng-template>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <div class="actions">
      <a
        nz-button
        nzType="primary"
        [disabled]="!validateForm.valid"
        [nzLoading]="replaying"
        nz-dropdown
        (click)="triggerHookReplay()"
        >Re-Send All Historical Data</a
      >

      <span class="push"></span>
      <button
        nz-button
        nzType="primary"
        (click)="save()"
        [disabled]="!validateForm.valid"
      >
        {{ !!survey ? "Ok" : "Save" }}
      </button>
      <button nz-button nzType="default" (click)="cancel()">Cancel</button>
      <button nz-button nzType="dashed" nzDanger (click)="remove()">
        Remove
      </button>
    </div>
  </form>
</ng-container>
