import { Injectable, TemplateRef } from "@angular/core";
import { ENV } from "environment";
import { NotificationHelper } from "helpers/notification.helper";
import { NzNotificationRef } from "ng-zorro-antd/notification";

const UPDATE_AVAILABLE_CHECK_INTERVAL = 30000;

@Injectable()
class UpgradeAdminService {
  public upgradeAvailable = false;

  private notificationClosed = false;
  private template: TemplateRef<object> = null;

  private notificationRef: NzNotificationRef;

  constructor(private notificationHelper: NotificationHelper) {}

  async checkAvailableUpgrade() {
    const response = await fetch("/version.json");
    const version: { latest: string } | undefined = await response.json();

    this.upgradeAvailable =
      version &&
      version.latest &&
      ENV["VERSION"] &&
      version.latest !== ENV["VERSION"];

    this.updateNotification();
  }

  public updateNotification() {
    if (this.upgradeAvailable && this.template && !this.notificationClosed) {
      this.notificationRef = this.notificationHelper.template(
        this.template,
        24 * 60 * 60,
        "upgrade",
      );

      this.notificationRef.onClose.subscribe(() => {
        this.notificationHelper.close("upgrade");
        this.notificationClosed = true;
        this.notificationRef.onClose.unsubscribe();
      });
    }
  }

  public initialize() {
    this.checkAvailableUpgrade();
    setInterval(
      this.checkAvailableUpgrade.bind(this),
      UPDATE_AVAILABLE_CHECK_INTERVAL,
    );
  }

  public setTemplate(template: TemplateRef<object>) {
    this.template = template;
    this.updateNotification();
  }

  public upgradeApp() {
    // @ts-ignore-next-line
    window.location.reload(true);
  }
}

export { UpgradeAdminService };
