<ng-container *ngIf="!('video_question' | hasEntitlement)">
  <div class="no-access-container">
    <img
      class="feature-logo"
      alt="Audio Video Logo"
      src="/assets/illustrations/question-video.png"
    />

    <p>
      You don’t have access to this option (yet).
      <br />
      To ask Video Questions to your users, you must activate this option on
      your account.
    </p>

    <button
      nz-button
      nzSize="large"
      nzType="primary"
      title="Activate the Video Questions Option Now"
      (click)="upgradeClick()"
    >
      <span>Activate the Video Questions Option Now</span>
    </button>
  </div>
</ng-container>

<ng-container *ngIf="'video_question' | hasEntitlement">
  <label for="message-{{ uniqHash }}-file" class="lateral-panel-subtitle">
    <div class="left">
      {{ nextQuestionPickerEnabled ? "Video" : "Question" }}

      <span
        class="help"
        nz-popover
        [nzPopoverContent]="videoHelpTemplate"
        nzPopoverPlacement="leftTop"
      >
        <i nz-icon nzType="question-circle" nzTheme="twotone"></i>
      </span>
    </div>

    <div class="right">
      <language-select
        class="language-select"
        name="defaultLanguage"
        ngDefaultControl
        [(ngModel)]="language"
        [languages]="builderStore.availableLanguages"
        [defaultLanguage]="builderStore.survey.scenario.default_language"
        (ngModelChange)="languageChange.emit($event)"
        (click)="$event.preventDefault(); $event.stopPropagation()"
      >
      </language-select>
    </div>

    <ng-template #variablesHelpTemplate>
      <span>
        If you use this option,it will add a dark overlay<br />on your video for
        the text to be readable.
      </span>
    </ng-template>
    <ng-template #videoHelpTemplate>
      <div>
        <p>
          Video questions will be cropped to fit the survey format.<br />
          Don't hesitate to preview the survey to make sure your video fit the
          selected format.
        </p>
        <p>Make your videos more engaging by:</p>
        <ul>
          <li>
            <a
              href="https://help.screeb.app/en/articles/8516650-how-to-use-audio-video-feature"
              target="_blank"
              rel="noopener"
            >
              <i nz-icon nzType="link"></i>
              Everything about video format
            </a>
          </li>
        </ul>
      </div>
    </ng-template>
  </label>

  <div class="upload-container" *ngIf="!video.url">
    <div class="flex">
      <div class="upload-box">
        <span class="upload-title">Upload a Video</span>
        <div class="control-icon">
          <screeb-icon size="md" icon="move-up" />
        </div>
        <input
          #VideoInput
          class="upload-input"
          type="file"
          [multiple]="false"
          accept="video/mp4,video/x-m4v,video/*"
          (change)="onVideoSubmit($event.target.files[0])"
        />
      </div>
      <div class="upload-box" (click)="setRecordModalOpened(true)">
        <span class="upload-title">Record a Video</span>
        <div class="control-icon">
          <screeb-icon size="md" icon="microphone" />
        </div>
      </div>
    </div>
  </div>
  <div
    class="upload-container"
    *ngIf="video.url && uploadState.state === 'PENDING'"
  >
    <div class="video-thumbnail">
      <video #videoThumbnail>
        <source [src]="video.url" type="video/mp4" />
        Your Browser doesn't support this video format
      </video>
      <div class="controls-overlay">
        <div class="control-icon gray" (click)="onVideoUploadCancel()">
          <screeb-icon size="md" icon="delete" />
        </div>
        <div class="control-icon" (click)="setPreviewModalOpened(true)">
          <screeb-icon size="md" icon="play" />
        </div>
      </div>
    </div>
  </div>
  <div class="progress-bar" *ngIf="uploadState.state === 'IN_PROGRESS'">
    <nz-progress [nzPercent]="uploadState.progress" />
    <screeb-icon
      class="cancel-btn"
      size="sm"
      icon="cross-circle"
      (click)="onVideoUploadCancel()"
    />
  </div>

  <label
    for="message-{{ uniqHash }}-input"
    class="lateral-panel-subtitle no-flex"
  >
    <div class="left">
      Overlay Question (Optional)
      <span
        class="help"
        nz-popover
        [nzPopoverContent]="variablesHelpTemplate"
        nzPopoverPlacement="leftTop"
      >
        <i nz-icon nzType="question-circle" nzTheme="twotone"></i>
      </span>
    </div>

    <textarea
      name="message-{{ uniqHash }}-input"
      id="message-{{ uniqHash }}-input"
      class="overlay-input"
      [ngClass]="{ invalid: overlayErrors.length > 0 }"
      [placeholder]="getMessagePlaceholder()"
      [(ngModel)]="overlay"
      (ngModelChange)="validateData()"
    ></textarea>

    <div
      *ngIf="getLength(overlay) > 0"
      [ngClass]="{
        'max-length-error': !getLength(overlay) || getLength(overlay) > 100
      }"
    >
      {{ getLength(overlay) }}/100
    </div>
    <utils-form-error [errors]="overlayErrors"></utils-form-error>

    <ng-container *ngIf="nextQuestionPickerEnabled">
      <label class="lateral-panel-subtitle">Next question</label>
      <lateral-panel-step-picker
        [routing]="node.node.routing"
        [chainableNodeIds]="node.chainableNodeIds"
      ></lateral-panel-step-picker>
    </ng-container>
  </label>
  <record-video-modal
    [isOpen]="recordModalOpened"
    (modalOpenChange)="setRecordModalOpened($event)"
    (videoRecorded)="onVideoRecorded($event)"
  />
  <preview-video-modal
    [videoUrl]="video.url"
    [isOpen]="previewModalOpened"
    (modalOpenChange)="setPreviewModalOpened($event)"
  />
</ng-container>
