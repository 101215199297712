<div
  class="survey-status-container"
  [style]="{
    borderColor: testMode
      ? '#FFF6EA'
      : running
        ? !isInStartEndDates
          ? '#F3F4F9'
          : 'var(--screeb-color-success-outline)'
        : '#F3F4F9'
  }"
  [nz-tooltip]="testMode ? testModeTooltip : null"
  *ngIf="channelsType.length > 0"
>
  <div
    class="status-icon"
    [style]="{
      backgroundColor: testMode
        ? '#FFF6EA'
        : running
          ? !isInStartEndDates
            ? '#F3F4F9'
            : 'var(--screeb-color-success-outline)'
          : '#F3F4F9'
    }"
  >
    <i *ngIf="loading" nz-icon nzType="loading"></i>
    <img
      *ngIf="!loading && (!running || (running && !isInStartEndDates))"
      alt="Pause"
      nz-icon
      src="/assets/icons/sm/pause.svg"
    />
    <img
      *ngIf="!loading && running && isInStartEndDates"
      alt="Live"
      nz-icon
      src="/assets/icons/sm/lightning.svg"
    />
  </div>
  <div class="channel-list">
    <span>{{ getFormattedChannelList() }}</span>
  </div>
</div>

<ng-template #testModeTooltip>
  <div class="test-mode-tooltip">
    <span>⚠️ Test mode activated on this channel</span>
  </div>
</ng-template>
