import {
  BillingSettings,
  notFreeSubscriptionOptions,
  OrgBillingCoupon,
  PlanBillingSettings,
  PlanType,
  scalePlanDefaultMTU,
  subscriptionOptions,
  subscriptionOptionsAddon,
} from "models/org_billing.model";

export const defaultScaleSubscriptionOptions: notFreeSubscriptionOptions = {
  billingCycle: "year",
  planType: "scale",
  addons: {
    whiteLabel: false,
    additionalWorkspaces: 0,
    responseTranslation: false,
    audioVideoSurvey: false,
  },
  mtu: scalePlanDefaultMTU,
};

export type lateralPanelMode =
  | "address"
  | "payment_method"
  | "change_billing_cycle"
  | "downgrade"
  | "activate_addon"
  | "subscription"
  | "confirm_subscription";

export const YearlyDiscount = 0.2;

export function couponToString(coupon: OrgBillingCoupon): string {
  if (coupon.type === "amount") {
    return "€" + coupon.amount_off + " off";
  } else if (coupon.type === "percentage") {
    return "" + coupon.percent_off + "% off";
  }

  throw Error("unexpected coupon type");
}

export function mtuToScalePlanId(
  billingSettings: BillingSettings,
  mtu: number,
): PlanBillingSettings {
  return billingSettings.plans.scale.products.find((p) => p.mtu === mtu);
}

export function subscriptionOptionsToAddonArray(
  subscriptionOptions: subscriptionOptions,
) {
  if (subscriptionOptions.planType === "free") {
    return [];
  }

  const addons: subscriptionOptionsAddon[] = [];

  if (subscriptionOptions.addons.whiteLabel) {
    addons.push({
      addon_key: "white-label",
      quantity: 1,
    });
  }

  if (subscriptionOptions.addons.additionalWorkspaces > 0) {
    addons.push({
      addon_key: "additional-workspaces",
      quantity: subscriptionOptions.addons.additionalWorkspaces,
    });
  }

  if (subscriptionOptions.addons.responseTranslation) {
    addons.push({
      addon_key: "response-translation",
      quantity: 1,
    });
  }

  if (subscriptionOptions.addons.audioVideoSurvey) {
    addons.push({
      addon_key: "audio-video-survey",
      quantity: 1,
    });
  }

  return addons;
}

export function planIdToPlanType(
  planId: string,
  billingSettings: BillingSettings,
): PlanType {
  if (!planId) {
    return "free";
  }

  const scaleProduct = billingSettings.plans.scale.products.find(
    (p) => p.product === planId,
  );
  if (scaleProduct) {
    return "scale";
  }

  if (billingSettings.plans.scale.old_products.includes(planId)) {
    return "scale";
  }

  return "custom";
}
