<section>
  <div *ngIf="integration">
    <integration-settings-oauth
      [org]="org"
      [integration]="integration"
      [confirmMessage]=""
    >
    </integration-settings-oauth>
  </div>
</section>
