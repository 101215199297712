<label class="lateral-panel-subtitle">Calendar</label>
<p class="lateral-panel-tip">
  You must choose the embed/iframe link.<br />Supported providers:
</p>
<p>Calendly, Google Calendar, HubSpot, Cal.com, SavvyCal.</p>
<input
  type="text"
  class="panel-input"
  placeholder="e.g. https://calendly.com/screeb/discoverycall"
  [ngClass]="{ invalid: linkErrors?.length ?? 0 > 0 }"
  [ngModel]="getLabelLink()"
  (ngModelChange)="setLabelLink($event); validateData()"
/>
<utils-form-error [errors]="getError()"></utils-form-error>

<lateral-panel-step-picker
  [routing]="node.node.routing"
  [chainableNodeIds]="node.chainableNodeIds"
  [actions]="[cta.calendar]"
></lateral-panel-step-picker>
