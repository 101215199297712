<div *ngIf="rule">
  <nz-select
    nzMode="multiple"
    nzShowSearch
    nzHasFeedback
    nzPlaceHolder="Select user events"
    [ngClass]="{ invalid: !isValid() }"
    [(ngModel)]="rule.value.v_s_arr"
    [disabled]="!!rule.sequence_id"
  >
    <nz-option-group
      *ngFor="let source of sortedRegistryEntrySources"
      [nzLabel]="registryEntrySourceFormatted[source].title"
    >
      <nz-option
        *ngFor="let registryEntry of getEventNamesBySource(source)"
        [nzValue]="registryEntry.id"
        [nzLabel]="(registryEntry.favorite ? '⭐️ ' : '') + registryEntry.slug"
      ></nz-option>
      <nz-option
        *ngIf="
          getEventNamesBySource(source).length === 0 &&
          registryEntrySourceFormatted[source].targetingCategoryVisibleOnEmpty
        "
        [nzValue]="'None'"
        [nzLabel]="'None'"
        nzDisabled
      ></nz-option>
    </nz-option-group>
  </nz-select>
</div>
