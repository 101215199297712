<ng-container *ngIf="!node.isPreset">
  <label class="lateral-panel-subtitle multi-select-title">Multi select</label>
  <p class="lateral-panel-tip">Allowing users to select multiple options</p>
  <nz-switch
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="cta.multiple"
    (ngModelChange)="setIsMultiple($event)"
  >
  </nz-switch>
</ng-container>

<label class="lateral-panel-subtitle">Options</label>
<ng-container *ngFor="let button of cta.choices; let ctaIndex = index">
  <div class="panel-button-line">
    <button
      *ngIf="button.payload.emoji"
      #btnElement
      class="emoji-button"
      [disabled]="node.isPreset"
      (click)="openEmojiPicker(button, btnElement)"
    >
      <span *ngIf="button.payload.emoji">
        <sanitized-message-component
          [content]="button.payload.emoji"
          [options]="{
            styling: true,
            emojiTranscoder: true
          }"
        ></sanitized-message-component>
      </span>
      <span *ngIf="!button.payload.emoji" class="no-emoji"> no emoji </span>
    </button>
    <button
      *ngIf="!button.payload.emoji"
      #btnElement
      class="emoji-button-no-emoji"
      title="Open emoji picker"
      [disabled]="node.isPreset"
      (click)="openEmojiPicker(button, btnElement)"
    >
      <i nz-icon nzType="plus"></i>
    </button>
    <input
      type="text"
      class="panel-input"
      placeholder="Your answer"
      [ngClass]="{ invalid: (textBtnErrors[ctaIndex]?.length ?? 0) > 0 }"
      [ngModel]="getLabelText(ctaIndex)"
      (ngModelChange)="setLabelText(ctaIndex, $event); validateData()"
    />
    <lateral-panel-step-picker
      *ngIf="!cta.multiple"
      [routing]="node.node.routing"
      [chainableNodeIds]="node.chainableNodeIds"
      [actions]="[button]"
    ></lateral-panel-step-picker>
    <button
      class="remove-button"
      title="Remove option"
      *ngIf="!node.isPreset && cta.choices.length > 1"
      (click)="removeOption(ctaIndex)"
    >
      <i nz-icon nzType="close-circle"></i>
    </button>
  </div>
  <utils-form-error [errors]="textBtnErrors[ctaIndex]"></utils-form-error>
</ng-container>

<button
  *ngIf="!node.isPreset && cta.choices.length < 10"
  nz-button
  nzType="default"
  nzSize="large"
  (click)="addOption()"
>
  + Add an option
</button>

<ng-container *ngIf="cta.multiple">
  <label class="lateral-panel-subtitle">Next step</label>
  <lateral-panel-step-picker
    [routing]="node.node.routing"
    [chainableNodeIds]="node.chainableNodeIds"
    [actions]="cta.choices"
  ></lateral-panel-step-picker>
</ng-container>

<ng-container>
  <label class="lateral-panel-subtitle skip-to-step-title">Skip to step</label>
  <p class="lateral-panel-tip">Allowing users to skip to a next step</p>

  <lateral-panel-step-picker
    [routing]="node.node.routing"
    [chainableNodeIds]="node.chainableNodeIds"
    [actions]="[skipAction]"
    [isSkipAction]="true"
  ></lateral-panel-step-picker>
</ng-container>

<ng-container *ngIf="!node.isPreset">
  <label class="lateral-panel-subtitle randomize-title">Randomize</label>
  <p class="lateral-panel-tip">Display options in a randomized order</p>
  <nz-switch
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="cta.randomize"
    (ngModelChange)="setIsRandomize($event)"
  >
  </nz-switch>
</ng-container>

<ng-container>
  <label class="lateral-panel-subtitle">Responsive</label>
  <p class="lateral-panel-tip">Display options in reverse order on mobile</p>
  <nz-switch
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="cta.responsive"
    (ngModelChange)="setIsResponsive($event)"
  >
  </nz-switch>
</ng-container>
