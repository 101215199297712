<div *ngIf="rule">
  <nz-select
    nzMode="tags"
    class="rule-type-app-version-values"
    [ngClass]="{ invalid: rule.value.v_s_arr.length === 0 }"
    [(ngModel)]="rule.value.v_s_arr"
    nzPlaceHolder="Value"
    [nzDropdownMatchSelectWidth]="false"
    nzDropdownClassName="filters-select-value"
  >
    <nz-option
      *ngFor="let clientVersion of channelClientVersions"
      [nzLabel]="clientVersion.app_version"
      [nzValue]="clientVersion.app_version"
    ></nz-option>
  </nz-select>
</div>
