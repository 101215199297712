<div class="loader" *ngIf="loadingDropdown">
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>

<ng-container *ngIf="!loadingDropdown">
  <h3>{{ title }}</h3>

  <div class="bool-de-noel">
    Match
    <nz-select
      [ngModel]="filtersOperator"
      (ngModelChange)="onFilterOperatorChange($event)"
      [nzOptions]="matchingOperatorOptions"
      [nzDropdownMatchSelectWidth]="false"
    ></nz-select>
    the following conditions
  </div>

  <div class="filters">
    <form (ngSubmit)="save.emit()">
      <table>
        <tr *ngFor="let f of filters; let i = index">
          <td class="filter-key">
            <nz-select
              [ngClass]="{ 'form-error': !filtersValidation[i]['key'] }"
              name="analytics-filters-{{ i }}-key"
              [compareWith]="compareFilterKeys"
              nzShowSearch
              nzSize="large"
              nzPlaceHolder="Field"
              nzDropdownClassName="filters-select-key"
              [nzDropdownMatchSelectWidth]="false"
              [nzCustomTemplate]="selectDefaultTemplate"
              [ngModel]="{ type: f.type, key: f.key }"
              (ngModelChange)="onFilterKeyChange(f, $event)"
            >
              <ng-container *ngFor="let option of keysOptions">
                <!-- duplicated for options having no group -->
                <nz-option
                  *ngIf="option.groupLabel === undefined"
                  nzCustomContent
                  [nzValue]="option.value"
                  [nzLabel]="option.label"
                >
                  <sanitized-message-component
                    [content]="option.label"
                    [options]="{
                      styling: true,
                      emojiTranscoder: true
                    }"
                  ></sanitized-message-component>
                </nz-option>
              </ng-container>

              <nz-option-group
                [nzLabel]="group"
                *ngFor="let group of filterGroupOrder"
              >
                <!-- duplicated for options having a group -->
                <ng-container *ngFor="let option of keysOptions">
                  <nz-option
                    *ngIf="option.groupLabel === group"
                    nzCustomContent
                    [nzValue]="option.value"
                    [nzLabel]="option.label"
                  >
                    <sanitized-message-component
                      [content]="option.label"
                      [options]="{
                        styling: true,
                        emojiTranscoder: true
                      }"
                    ></sanitized-message-component>
                  </nz-option>
                </ng-container>
              </nz-option-group>
            </nz-select>
          </td>
          <td class="filter-operator">
            <nz-select
              [ngClass]="{
                'form-error': !filtersValidation[i]['operator']
              }"
              name="analytics-filters-{{ i }}-operator"
              [(ngModel)]="f.operator"
              (ngModelChange)="onOperatorChange(f)"
              nzSize="large"
              nzPlaceHolder="Operator"
              [nzDropdownMatchSelectWidth]="false"
              nzDropdownClassName="filters-select-operator"
              [nzCustomTemplate]="selectDefaultTemplate"
            >
              <nz-option
                *ngFor="let option of getOperatorOptions(f)"
                nzCustomContent
                [nzValue]="option.value"
                [nzLabel]="option.label"
              >
                <sanitized-message-component
                  [content]="option.label"
                  [options]="{
                    styling: true,
                    emojiTranscoder: true
                  }"
                ></sanitized-message-component>
              </nz-option>
            </nz-select>
          </td>
          <td class="filter-value">
            <ng-container [ngSwitch]="getValueComponent(f)">
              <!-- when filter is of type: response + hidden fields + text responses (not preset) -->
              <nz-input-group
                *ngSwitchCase="'text'"
                [nzSuffix]="getValueSuffix(f)"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                nzSize="large"
              >
                <input
                  type="text"
                  nz-input
                  placeholder="Value"
                  name="analytics-filters-{{ i }}-value"
                  [(ngModel)]="f.value"
                  (ngModelChange)="onFieldChange()"
                />
              </nz-input-group>

              <!-- when filter is of type: user event -->
              <nz-input-group
                *ngSwitchCase="'number'"
                [nzSuffix]="getValueSuffix(f)"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                nzSize="large"
              >
                <input
                  type="number"
                  nz-input
                  placeholder="Value"
                  name="analytics-filters-{{ i }}-value"
                  [(ngModel)]="f.value"
                  (ngModelChange)="onFieldChange()"
                  [min]="getValueNumberMin(f)"
                />
              </nz-input-group>

              <!-- when filter is of type: user event -->
              <nz-switch
                *ngSwitchCase="'boolean'"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                nzSize="large"
                name="analytics-filters-{{ i }}-value"
                [(ngModel)]="f.value"
                (ngModelChange)="onFieldChange()"
                nzSize="large"
                [nzCheckedChildren]="checkedTemplate"
                [nzUnCheckedChildren]="unCheckedTemplate"
              ></nz-switch>
              <ng-template #checkedTemplate
                ><i nz-icon nzType="check"></i
              ></ng-template>
              <ng-template #unCheckedTemplate
                ><i nz-icon nzType="close"></i
              ></ng-template>

              <nz-date-picker
                *ngSwitchCase="'time'"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                nzSize="large"
                name="analytics-filters-{{ i }}-value"
                [(ngModel)]="f.value"
                (ngModelChange)="onFieldChange()"
              >
              </nz-date-picker>

              <!-- when filter is of type: answer but not preset ("in") -->

              <nz-select
                *ngSwitchCase="'preset_select'"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                name="analytics-filters-{{ i }}-value"
                [(ngModel)]="f.action_correlation_ids"
                (ngModelChange)="onFieldChange()"
                nzSize="large"
                nzPlaceHolder="Value"
                nzMode="tags"
                [nzShowArrow]="true"
                nzSuffixIcon="caret-down"
                [nzDropdownMatchSelectWidth]="false"
                nzDropdownClassName="filters-select-value"
                [nzCustomTemplate]="selectDefaultTemplate"
              >
                <nz-option
                  *ngFor="let option of getValuePresetOptions(f)"
                  nzCustomContent
                  [nzValue]="getValue(f, option.value)"
                  [nzLabel]="option.label"
                >
                  <sanitized-message-component
                    [content]="option.label"
                    [options]="{
                      styling: true,
                      emojiTranscoder: true
                    }"
                  ></sanitized-message-component>
                </nz-option>
              </nz-select>

              <!-- when filter is of type: respondent and in/not_in -->
              <tag-input
                *ngSwitchCase="'tag_input'"
                placeHolder="Value"
                size="large"
                [(ngModel)]="f.values"
                (ngModelChange)="onFieldChange()"
              >
              </tag-input>

              <!-- when filter is of type: answer but not preset ("greater than" and "less than") -->
              <nz-select
                *ngSwitchCase="'preset_compare'"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                name="analytics-filters-{{ i }}-value"
                [(ngModel)]="f.value"
                (ngModelChange)="onPresetCompareChange(f); onFieldChange()"
                nzSize="large"
                nzPlaceHolder="Value"
                [nzDropdownMatchSelectWidth]="false"
                nzDropdownClassName="filters-select-value"
                [nzCustomTemplate]="selectDefaultTemplate"
              >
                <nz-option
                  *ngFor="let option of getValuePresetOptions(f)"
                  nzCustomContent
                  [nzValue]="option.value"
                  [nzLabel]="option.label"
                >
                  <sanitized-message-component
                    [content]="option.label"
                    [options]="{
                      styling: true,
                      emojiTranscoder: true
                    }"
                  ></sanitized-message-component>
                </nz-option>
              </nz-select>

              <!-- when filter is of type: answer but not preset ("greater than" and "less than") -->
              <nz-select
                *ngSwitchCase="'channel_select'"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                name="analytics-filters-{{ i }}-value"
                [(ngModel)]="f.values"
                (ngModelChange)="onFieldChange()"
                nzSize="large"
                nzMode="tags"
                nzPlaceHolder="Channels"
                [nzDropdownMatchSelectWidth]="false"
                nzDropdownClassName="filters-select-value"
                [nzCustomTemplate]="selectDefaultTemplate"
              >
                <nz-option
                  *ngFor="let option of channelsOptions"
                  nzCustomContent
                  [nzValue]="option.value"
                  [nzLabel]="option.label"
                >
                  <sanitized-message-component
                    [content]="option.label"
                    [options]="{
                      styling: true,
                      emojiTranscoder: true
                    }"
                  ></sanitized-message-component>
                </nz-option>
              </nz-select>

              <nz-select
                *ngSwitchCase="'segment_select'"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                name="analytics-filters-{{ i }}-value"
                [(ngModel)]="f.values"
                (ngModelChange)="onFieldChange()"
                nzSize="large"
                nzMode="multiple"
                nzPlaceHolder="Segments"
                [nzDropdownMatchSelectWidth]="false"
                nzDropdownClassName="filters-select-value"
                [nzCustomTemplate]="selectDefaultTemplate"
              >
                <nz-option
                  *ngFor="let option of segmentsOptions"
                  nzCustomContent
                  [nzValue]="option.value"
                  [nzLabel]="option.label"
                >
                  <sanitized-message-component
                    [content]="option.label"
                    [options]="{
                      styling: true,
                      emojiTranscoder: true
                    }"
                  ></sanitized-message-component>
                </nz-option>
              </nz-select>

              <!-- when filter is of type: answer but not preset ("greater than" and "less than") -->

              <!-- this should be displayed in red when invalid -->
              <language-select
                *ngSwitchCase="'language_select'"
                name="analytics-filters-{{ i }}-value"
                ngDefaultControl
                nzSize="large"
                nzMode="multiple"
                placeholder="Languages"
                [languages]="availableLanguages"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                [(ngModel)]="f.values"
                (ngModelChange)="f.values = $event; onFieldChange()"
                [maxWidth]="true"
              >
              </language-select>

              <nz-select
                *ngSwitchCase="'emotions_select'"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                name="analytics-filters-{{ i }}-value"
                [(ngModel)]="f.values"
                (ngModelChange)="onFieldChange()"
                nzSize="large"
                nzMode="tags"
                nzPlaceHolder="Emotions"
                [nzDropdownMatchSelectWidth]="false"
                nzDropdownClassName="filters-select-value"
                [nzCustomTemplate]="selectDefaultTemplate"
              >
                <nz-option
                  *ngFor="let option of availableEmotions"
                  nzCustomContent
                  [nzValue]="option.value"
                  [nzLabel]="option.label"
                >
                  <sanitized-message-component
                    [content]="option.label"
                    [options]="{
                      styling: true,
                      emojiTranscoder: true
                    }"
                  ></sanitized-message-component>
                </nz-option>
              </nz-select>

              <nz-select
                *ngSwitchCase="'tag_select'"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                name="analytics-filters-{{ i }}-value"
                [(ngModel)]="f.values"
                (ngModelChange)="onFieldChange()"
                nzSize="large"
                nzMode="tags"
                nzPlaceHolder="Tags"
                [nzDropdownMatchSelectWidth]="false"
                nzDropdownClassName="filters-select-value"
                [nzCustomTemplate]="selectDefaultTemplate"
              >
                <nz-option
                  *ngFor="let option of availableTags"
                  nzCustomContent
                  [nzValue]="option.value"
                  [nzLabel]="option.label"
                >
                  <sanitized-message-component
                    [content]="option.label"
                    [options]="{
                      styling: true,
                      emojiTranscoder: true
                    }"
                  ></sanitized-message-component>
                </nz-option>
              </nz-select>

              <nz-select
                *ngSwitchCase="'types_select'"
                [ngClass]="{ 'form-error': !filtersValidation[i]['value'] }"
                name="analytics-filters-{{ i }}-value"
                [(ngModel)]="f.values"
                (ngModelChange)="onFieldChange()"
                nzSize="large"
                nzMode="tags"
                nzPlaceHolder="Format"
                [nzDropdownMatchSelectWidth]="false"
                nzDropdownClassName="filters-select-value"
                [nzCustomTemplate]="selectDefaultTemplate"
              >
                <nz-option
                  *ngFor="let option of availableFormats"
                  nzCustomContent
                  [nzValue]="option.value"
                  [nzLabel]="option.label"
                >
                  <sanitized-message-component
                    [content]="option.label"
                    [options]="{
                      styling: true,
                      emojiTranscoder: true
                    }"
                  ></sanitized-message-component>
                </nz-option>
              </nz-select>
            </ng-container>

            <!-- <nz-select [(ngModel)]="f.value" [nzDropdownMatchSelectWidth]="false" nzSize="large" nzDropdownClassName="filters-select-value"></nz-select> -->
          </td>
          <td class="filter-actions">
            <i
              nz-icon
              nzType="delete"
              nzTheme="outline"
              (click)="onFilterRemoved(i)"
            ></i>
          </td>
          <td class="add-filter-container">
            <button
              *ngIf="i === filters.length - 1 || filters.length === 0"
              nz-button
              nzType="default"
              nzShape="circle"
              (click)="onFilterAdded()"
            >
              <i nz-icon nzType="plus" nzTheme="outline"></i>
            </button>
          </td>
        </tr>
      </table>
      <div class="add-filter-button-container">
        <button
          *ngIf="filters.length === 0"
          nz-button
          nzType="default"
          nzShape="circle"
          (click)="onFilterAdded()"
        >
          <i nz-icon nzType="plus" nzTheme="outline"></i>
        </button>
      </div>
    </form>
  </div>

  <div class="actions" *ngIf="showUpdateButton">
    <button
      nz-button
      class="ant-btn-secondary"
      nzType="default"
      nzSize="large"
      [disabled]="!filtersAreValid"
      (click)="onSave()"
    >
      Update filters
    </button>
  </div>
</ng-container>

<ng-template #selectDefaultTemplate let-selected>
  <sanitized-message-component
    [content]="selected.nzLabel"
    [options]="{
      styling: true,
      emojiTranscoder: true
    }"
  ></sanitized-message-component>
</ng-template>
