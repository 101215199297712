import { Component, Input } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";

@Component({
  selector: "rule-session-duration",
  templateUrl: "./rule-session-duration.component.html",
  styleUrls: ["./rule-session-duration.component.scss"],
})
export class TargetingRuleSessionDurationComponent {
  @Input()
  public rule: SurveyTargetingRule = null;

  constructor() {}

  public checkValidValue(r: SurveyTargetingRule): boolean {
    if (r.value.v_n === null || r.value.v_n === undefined) return false;
    return true;
  }
}
