import { Component, EventEmitter, Input, Output } from "@angular/core";

import { intervalToDuration } from "date-fns";

import { RegistryEntrySourceFormatted } from "models/registry.model";
import { UserRecord } from "models/user-record.model";
import { UIService } from "services/ui.service";
import { User } from "models/user.model";
import { Router } from "@angular/router";

@Component({
  selector: "user-records",
  templateUrl: "./user-records.component.html",
  styleUrls: ["./user-records.component.scss"],
})
export class UserRecordsComponent {
  @Input() public user: User = null;
  @Input() public userRecords: UserRecord[] = [];

  @Output() public recordClicked: EventEmitter<object> = new EventEmitter();

  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;

  constructor(
    public uiService: UIService,
    private router: Router,
  ) {}

  public onRecordClicked(record: UserRecord) {
    this.recordClicked.emit();
    this.router.navigate([
      "/org",
      this.user.org_id,
      "people",
      "sessions",
      record.id,
    ]);
  }

  public duration(seconds: number) {
    return intervalToDuration({ start: 0, end: seconds * 1000 });
  }
}
