import { Injectable } from "@angular/core";
import { ScreebApiHelper } from "services/auth.service";
import { ConfigService } from "services/config.service";

@Injectable()
class EmailDao {
  constructor(
    private screebApiHelper: ScreebApiHelper,
    private configService: ConfigService,
  ) {}

  public async getTemplate(
    orgId: string,
    surveyId: string,
  ): Promise<{ html: string }> {
    return this.screebApiHelper
      .get<{
        html: string;
      }>(`/org/${orgId}/survey/${surveyId}/scenario/email/template`)
      .toPromise();
  }
}

export { EmailDao };
