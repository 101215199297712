<div>
  <div class="loading-container {{ loading ? 'is-loading' : '' }}">
    <h3
      *ngIf="!hideTitle"
      class="radar-title"
      [style]="isHomepage ? 'font-weight: bold;font-size: 20px' : ''"
    >
      Responses Emotions Analysis <utils-badge text="New"></utils-badge>
    </h3>

    <nz-spin size="medium" *ngIf="loading"></nz-spin>
    <div>
      <canvas
        style="position: relative; width: auto"
        #trendChart="base-chart"
        baseChart
        [data]="data"
        [options]="options"
        [type]="'radar'"
        [legend]="false"
      >
      </canvas>
    </div>
    <nz-checkbox-group
      [(ngModel)]="reversedLegend"
      (ngModelChange)="onTrendLegendChange($event)"
    >
    </nz-checkbox-group>
  </div>
</div>
