import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { UserDao } from "models/user.dao";
import { Response } from "models/response.model";

@Injectable()
export class UserResponsesByUserIdResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private userDao: UserDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Response[]> {
    return this.applicationLoadService
      .loader()
      .then(() =>
        this.userDao.getUserResponses(
          route.params["org_id"],
          route.params["respondent_id"],
          200,
        ),
      )
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
