import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AnalyticsFilter,
  AnalyticsFilterOperator,
  AnalyticsFilterResponseAnswer,
  AnalyticsFilterResponseHiddenField,
  AnalyticsFilterUser,
  AnalyticsFilterUserEvent,
  AnalyticsFilters,
  AnalyticsFiltersOperator,
  AnalyticsQuery,
} from "models/analytics.filters.type";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";
import {
  AnalyticsFilterService,
  analyticsQueryOperatorSettings,
} from "services/analytics-filters.service";
import { valueComponent } from "../filter-criteria.component";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { Channel, ChannelTypeFormatted } from "models/channel.model";
import { UIService } from "services/ui.service";
import { ChannelDao } from "models/channel.dao";
import { RegistryEntry } from "models/registry.model";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import { deepCopy } from "utils/object";
import { GraphNode } from "components/builder/flow";
import { Survey } from "models/survey.model";

const settingToOption = (filterType: AnalyticsFilter["type"]) => (s: any) => {
  const label = typeof s.label === "string" ? s.label : s.label(filterType);
  return { value: s.value, label };
};

const selectOperators = (
  filterType: AnalyticsFilter["type"],
  operators: AnalyticsFilterOperator["operator"][],
) =>
  operators
    .map((o) => analyticsQueryOperatorSettings[o])
    .map(settingToOption(filterType));

const operators = {
  response: {
    respondent_aliases: selectOperators("response", ["eq", "contains"]),
    respondent_segments: selectOperators("respondent", ["in", "not_in"]),
    raw: selectOperators("response", ["contains"]),
    channel_id: selectOperators("respondent", ["in"]),
    multiple_choice: selectOperators("respondent", ["in", "not_null"]),
    language: selectOperators("response", ["in"]),
    emotions: selectOperators("response", ["in", "not_in"]),
    answer: {
      tags: selectOperators("response", ["in", "not_in"]),
      types: selectOperators("response.answer", ["in", "not_in"]),
      string: selectOperators("response.answer", [
        "eq",
        "not_null",
        "contains",
      ]),
      numeric: selectOperators("response.answer", [
        "eq",
        "not_null",
        "gt",
        "lt",
      ]),
      // @TODO: other types
    },
    answerPreset: selectOperators("response.answer", [
      "in",
      "not_null",
      "preset.gt",
      "preset.lt",
    ]),
    hiddenField: selectOperators("response.hidden_field", [
      "eq",
      "not_null",
      "contains",
      "gt",
      "lt",
      "before",
      "after",
    ]),
  },

  respondent: {
    created_at: selectOperators("respondent", ["before", "after"]),
    last_activity_at: selectOperators("respondent", ["before", "after"]),
    aliases: selectOperators("respondent", ["in", "not_in", "contains"]),
    segment: selectOperators("respondent", ["in", "not_in"]),
    event: selectOperators("respondent.event", [
      "eq",
      "not_null",
      "null",
      "gt",
      "lt",
    ]), // @TODO: add first_after, first_before, last_after, last_before
    property: {
      string: selectOperators("respondent.property", [
        "eq",
        "not_null",
        "contains",
      ]),
      numeric: selectOperators("respondent.property", [
        "eq",
        "not_null",
        "gt",
        "lt",
      ]),
      boolean: selectOperators("respondent.property", ["eq", "not_null"]),
      time: selectOperators("respondent.property", ["before", "after"]),
    },
  },
};

const valueComponents = {
  response: {
    respondent_aliases: "text" as valueComponent,
    respondent_segments: "segment_select" as valueComponent,
    raw: "text" as valueComponent,
    channel_id: "channel_select" as valueComponent,
    language: "language_select" as valueComponent,
    emotions: "emotions_select" as valueComponent,
    answer: {
      tags: "tag_select" as valueComponent,
      types: "types_select" as valueComponent,
      string: "text" as valueComponent,
      number: "number" as valueComponent,
      boolean: "boolean" as valueComponent,
      time: "time" as valueComponent,
      // @TODO: other types
    },
    multipleChoice: (filter: AnalyticsFilterResponseAnswer): valueComponent => {
      switch (filter.operator) {
        case "not_null":
          return "none";
        case "in":
          return "preset_select";
      }
    },
    answerPreset: (filter: AnalyticsFilterResponseAnswer): valueComponent => {
      switch (filter.operator) {
        case "not_null":
          return "none";
        case "in":
          return "preset_select";
        case "preset.lt":
        case "preset.gt":
          return "preset_compare";
      }
    },
    hiddenField: (
      filter: AnalyticsFilterResponseHiddenField,
    ): valueComponent => {
      switch (filter.operator) {
        case "not_null":
          return "none";
        case "eq":
        case "contains":
          return "text";
        case "gt":
        case "lt":
          return "number";
        case "before":
        case "after":
          return "time";
      }
    },
  },

  respondent: {
    aliases: (filter: AnalyticsFilterUser): valueComponent => {
      if (filter.operator === "in" || filter.operator === "not_in") {
        return "tag_input";
      }
      return "text";
    },
    created_at: "time" as valueComponent,
    last_activity_at: "time" as valueComponent,
    segment: "segment_select" as valueComponent,
    event: (filter: AnalyticsFilterUserEvent): valueComponent => {
      // @TODO: add first_after, first_before, last_after, last_before
      switch (filter.operator) {
        case "not_null":
          return "none";
        case "eq":
        case "gt":
        case "lt":
          return "number";
      }
    },
    property: {
      string: "text" as valueComponent,
      numeric: "number" as valueComponent,
      boolean: "boolean" as valueComponent,
      time: "time" as valueComponent,
    },
  },
};
export enum FilterGroup {
  Question = "Question",
  HiddenField = "Hidden field",
  UserProperty = "User property",
  UserEvent = "User event",
}
const filterGroupOrder = [
  FilterGroup.Question,
  FilterGroup.HiddenField,
  FilterGroup.UserProperty,
  FilterGroup.UserEvent,
];

@Component({
  selector: "survey-filter-criteria-filters",
  templateUrl: "./filter-criteria-filters.component.html",
  styleUrls: ["./filter-criteria-filters.component.scss"],
})
export class FilterCriteriaFiltersComponent implements OnInit {
  @Input() visibleDropdown: boolean = false;
  @Input() loadingDropdown: boolean = false;
  @Input() showUpdateButton: boolean = true;

  @Input() filtersOperator: AnalyticsFiltersOperator = null;
  @Output() filtersOperatorChange: EventEmitter<AnalyticsFiltersOperator> =
    new EventEmitter();
  @Input() matchingOperatorOptions: NzSelectOptionInterface[] = [
    { label: "All", value: "AND" },
    { label: "One of", value: "OR" },
  ];
  @Input() shouldTakeLastFilters: boolean = true;
  @Input() lastFilters: AnalyticsQuery;
  @Output() lastFiltersChange: EventEmitter<AnalyticsQuery> =
    new EventEmitter();
  @Input() filters: AnalyticsFilters = null;
  @Output() filtersChange: EventEmitter<AnalyticsFilters> = new EventEmitter();
  @Input() nodesByKey: object = null;

  protected filtersValidation: object[] = null;
  @Input() keysOptions: NzSelectOptionInterface[] = [];
  @Output() keysOptionsChange: EventEmitter<NzSelectOptionInterface[]> =
    new EventEmitter();
  public filterGroupOrder = filterGroupOrder;
  protected operators = operators;
  protected valueComponents = valueComponents;

  public channelsOptions: NzSelectOptionInterface[];
  public segmentsOptions: NzSelectOptionInterface[];
  @Input() availableLanguages: LanguageWithEmoji[];
  public availableEmotions: NzSelectOptionInterface[];
  @Input() availableTags: NzSelectOptionInterface[] = [];
  public availableFormats: NzSelectOptionInterface[] = [];
  public filtersAreValid: boolean = null;
  @Output() filtersAreValidChange: EventEmitter<boolean> = new EventEmitter();

  @Input() title: string = "";

  @Output() save: EventEmitter<boolean> = new EventEmitter();

  @Output() filterKeyChange: EventEmitter<{ f: AnalyticsFilters; event: any }> =
    new EventEmitter();

  @Output() operatorChange: EventEmitter<AnalyticsFilters> = new EventEmitter();

  @Output() filterAdded: EventEmitter<Channel[]> = new EventEmitter();

  @Input() operatorOptions: NzSelectOptionInterface[] = [];

  public channels: Channel[] = [];
  @Input() registryEntriesGroup: RegistryEntry[] = [];
  @Input() registryEntriesIdentityProperty: RegistryEntry[] = [];
  @Input() registryEntriesEvent: RegistryEntry[] = [];

  @Input() survey: Survey = null;

  public compareFilterKeys(a: AnalyticsFilter, b: AnalyticsFilter): boolean {
    return (
      !!a &&
      !!b &&
      !!a.type && // && !!b.type
      !!a.key && // && !!b.key
      a.type === b.type &&
      a.key === b.key
    );
  }

  public onFilterKeyChange(f: AnalyticsFilters, event: any) {
    this.filterKeyChange.emit({ f: f, event: event });

    setTimeout(() => {
      this.triggerValidation();
    }, 100);
  }

  public onFieldChange() {
    this.triggerValidation();
  }

  // inherited
  @Input() getOperatorOptions: (
    f: AnalyticsFilter,
  ) => NzSelectOptionInterface[] = () => [];

  // inherited
  @Input() getValueComponent: (v: AnalyticsFilter) => valueComponent = () =>
    "none";

  // inherited
  @Output() getAvailableFields: EventEmitter<void> = new EventEmitter();
  // @Input() public getAvailableFields: () => void = () => {};

  @Input() getValuePresetOptions: (
    f: AnalyticsFilter,
  ) => NzSelectOptionInterface[] = (_: AnalyticsFilter) => [];
  @Input() public getLabel: (f: AnalyticsFilter, key: string) => string = (
    _: AnalyticsFilter,
    __: string,
  ) => "";

  constructor(
    private uiService: UIService,
    private channelDao: ChannelDao,
    private analyticsFilterService: AnalyticsFilterService,
  ) {}

  ngOnInit(): void {
    this.initPanel();
  }

  public onFilterAdded() {
    this.filterAdded.emit(this.channels);
    this.triggerValidation();
  }

  public onOperatorChange(f) {
    this.operatorChange.emit(f);
    this.triggerValidation();
  }

  public onFilterRemoved(index: number) {
    if (index < 0 || index > this.filters.length - 1) {
      throw new Error("Filter not found");
    }

    this.filters.splice(index, 1);
    this.filtersChange.emit(this.filters);
    this.triggerValidation();
  }

  public onSave() {
    if (!this.filtersAreValid) {
      return;
    }
    this.triggerValidation();
    this.save.emit();
  }

  public getValueSuffix(filter: AnalyticsFilter): string | null {
    if (filter.type === "respondent.event" && filter.operator !== "not_null") {
      return "times";
    }
    return null;
  }

  public getValueNumberMin(filter: AnalyticsFilter): number | null {
    if (
      filter.type === "respondent.event" &&
      ["gt", "lt"].includes(filter.operator)
    ) {
      return 0;
    } else if (
      filter.type === "respondent.event" &&
      ["eq"].includes(filter.operator)
    ) {
      return 1;
    }
    return null;
  }

  public onPresetCompareChange(_: AnalyticsFilter) {}

  public fetchChannels(): Promise<Channel[]> {
    return this.channelDao.getAllByOrgId(this.uiService.currentOrgId);
  }

  private resetAvailableFields() {
    this.getAvailableFields.emit();
  }

  protected async initPanel() {
    if (this.filters) this.triggerValidation();

    this.channels = await this.fetchChannels();

    this.lastFilters = this.analyticsFilterService.get();
    this.lastFiltersChange.emit(this.lastFilters);
    if (!this.shouldTakeLastFilters) {
      this.lastFilters.filters = [];
    }

    if (!this.filters) {
      this.filters = deepCopy(this.lastFilters.filters) ?? [];
    }
    this.triggerValidation();
    this.filtersChange.emit(this.filters);
    this.filtersOperator = this.lastFilters.filters_bool;
    this.filtersOperatorChange.emit(this.filtersOperator);

    if (this.filters.length === 0) {
      // this.onFilterAdded();

      this.filterAdded.emit(this.channels);
      this.triggerValidation();
    }
    this.resetAvailableFields();

    setTimeout(() => {
      this.triggerValidation();
    }, 0);

    this.loadingDropdown = false;

    this.channelsOptions = this.channels.map(
      (c: Channel): NzSelectOptionInterface => {
        return {
          value: c.id,
          label: ChannelTypeFormatted[c.type].title,
        };
      },
    );

    this.segmentsOptions = this.registryEntriesGroup.map((userGroup) => ({
      value: userGroup.id,
      label: getFormattedUserGroupNameOrID(userGroup),
    }));

    this.availableEmotions = [
      {
        value: "joy",
        label: "Joy",
      },
      {
        value: "sadness",
        label: "Sadness",
      },
      {
        value: "anger",
        label: "Anger",
      },
      {
        value: "fear",
        label: "Fear",
      },
    ];

    this.availableFormats = [
      {
        value: "text",
        label: "Text",
      },
      {
        value: "audio",
        label: "Audio",
      },
      {
        value: "video",
        label: "Video",
      },
    ];
  }

  public triggerValidation() {
    this.filtersValidation = this.filters.map((f) =>
      this.isFilterValid(f as AnalyticsFilter),
    );
    this.filtersAreValid = !this.filtersValidation.find(
      (f: any) => f.all_valid === false,
    );
    this.filtersAreValidChange.emit(this.filtersAreValid);
  }
  private isFilterValid(filter: AnalyticsFilter) {
    if (!filter) {
      return {
        all_valid: false,
      };
    }

    const opSettings = analyticsQueryOperatorSettings[filter.operator];

    const key =
      !!filter.key &&
      filter.key.length > 0 &&
      !!this.keysOptions.find(
        (o) =>
          o.value.type === "response.hidden_field" ||
          o.value.key === filter.key,
      );
    const operator =
      !!filter.operator && filter.operator.length > 0 && !!opSettings;

    const value =
      opSettings?.filterCanHaveValue === false ||
      ((filter as any)?.value?.toString().length ?? 0) > 0;
    // Boolean((filter as any).value) && (filter as any).value?.toString().length > 0
    const values =
      opSettings?.filterCanHaveValues === false ||
      (Array.isArray((filter as any)?.values) &&
        ((filter as any)?.values?.length ?? 0) > 0) ||
      ((filter as any)?.action_correlation_ids?.length ?? 0) > 0;
    // Boolean((filter as any).values) && (filter as any).values.length > 0

    return {
      all_valid: key && operator && value && values,
      key,
      operator,
      value,
      values,
    };
  }

  public getValue(
    filter: AnalyticsFilter,
    label: string | number,
  ): string | number {
    if (filter.type !== "response.answer") {
      return "";
    }

    const node: GraphNode = this.nodesByKey[filter.key];
    if (!node) {
      return "";
    }

    const cta = node.node.question?.call_to_action;

    if (!cta) {
      return "";
    }

    switch (cta.type) {
      case "multiple_choice":
      case "pmf":
        for (const choice of cta.choices) {
          if (choice.type === "button" && choice.correlation_id === label) {
            return choice.correlation_id;
          }
        }
        return "";
      case "scoring":
      case "ces":
      case "csat":
      case "nps":
        if (filter.operator === "in") {
          for (const choice of cta.scores) {
            if (choice.type === "scoring" && choice.payload.value === label) {
              return choice.correlation_id;
            }
          }
          return "";
        }

        return label;
      case "none":
      default:
        return "";
    }
  }

  public onFilterOperatorChange(operator: AnalyticsFiltersOperator) {
    this.filtersOperator = operator;
    this.filtersOperatorChange.emit(operator);
  }
}
