import {
  CTAType,
  Routing,
  ScenarioNode,
  SurveyScenario,
  SwitchCase,
  UUID,
} from "models/survey.dao.types";
import { arrayExcludeElements, arrayToSet } from "utils/array";
import { incrementString } from "utils/string";

export type GraphNode = {
  id: UUID;
  correlationId: UUID;
  node: ScenarioNode;

  index: number;
  name: string;

  previousNodeIds: UUID[];
  nextNodeIds: UUID[];
  skipToNodeId?: UUID;
  chainableNodeIds: UUID[];

  questionType: CTAType | null;
  hasCTA: boolean;
  isNPS: boolean;
  isCSAT: boolean;
  isCES: boolean;
  isLink: boolean;
  isPMF: boolean;
  isPreset: boolean;
  isOpenEndedQuestion: boolean;
  description: string | null;
};

export function graphNodeCompare(a: GraphNode, b: GraphNode): number {
  return a.index - b.index;
}

export class ScenarioGraphBuilder {
  private nodesById: Map<UUID, GraphNode> = new Map();

  constructor(private scenario: SurveyScenario) {}

  public getNodeGraph(): GraphNode[][] {
    if (!this.scenario.node_entrypoint_id) return [];

    // store messages by k/v (key = id, value = message)
    this.nodesById = this.scenario.nodes.reduce(
      (acc: Map<UUID, GraphNode>, node: ScenarioNode) => {
        acc.set(node.id, {
          id: node.id,
          correlationId: node.correlation_id,
          node: node,

          index: 0,
          name: "",

          previousNodeIds: [],
          nextNodeIds: [],
          chainableNodeIds: [],

          questionType: null,
          hasCTA: false,
          isNPS: false,
          isCSAT: false,
          isCES: false,
          isPMF: false,
          isPreset: false,
          isOpenEndedQuestion: false,
          description: null,
        } as GraphNode);
        return acc;
      },
      new Map(),
    );

    this.linkQuestionsToEachOther(this.scenario.node_entrypoint_id);
    const nodePaths = this.listAllPaths([], this.scenario.node_entrypoint_id);
    this.listChainableNodes(nodePaths);
    const columns = this.groupNodesByColumn(nodePaths);

    this.setNodesNameAndIndex(columns);
    this.setQuestionDetails();

    return columns;
  }

  // @TODO: i'm pretty sure infinite recursive loop is not possible here
  private linkQuestionsToEachOther(nodeId: UUID) {
    if (!nodeId) return;

    let node = this.nodesById.get(nodeId);
    if (!node) return;

    node = this.linkQuestionsToNextNode(node);
    node = this.linkQuestionToSkipToNode(node);
  }

  private linkQuestionsToNextNode(node: GraphNode): GraphNode {
    // prevent infinite loop
    if (node.nextNodeIds.length > 0) return node;

    // search for next nodes over routing of a node
    node.nextNodeIds = this.routingToNextNodeIds(node.node.routing);
    // remove duplicated nextNodeIds
    node.nextNodeIds = arrayToSet(node.nextNodeIds);
    // here, we ensure nextQuestion won't point to itself
    node.nextNodeIds = node.nextNodeIds.filter(
      (nid: UUID) => !!nid && node.id !== nid,
    );

    // Append current node to previousNodeIds field of next node.
    // If there is no infinite recursive loop, i'm pretty sure there
    // is no way to push twice the same previous node id here.
    const nextNodes = node.nextNodeIds.map((nid: UUID) =>
      this.nodesById.get(nid),
    );
    nextNodes.forEach((n: GraphNode) => n.previousNodeIds.push(node.id));

    // loop recursively to each next nodes
    node.nextNodeIds.forEach((nid: UUID) => this.linkQuestionsToEachOther(nid));
    return node;
  }

  private linkQuestionToSkipToNode(node: GraphNode): GraphNode {
    // prevent infinite loop
    if (node.skipToNodeId) return node;

    // search for skip to node over routing of a node
    node.skipToNodeId = this.routingToSkipToNodeId(node.node.routing);
    // here, we ensure nextQuestion won't point to itself
    if (node.skipToNodeId === node.id) node.skipToNodeId = null;
    if (!node.skipToNodeId) return node;

    // Append current node to previousNodeIds field of skip to node.
    // If there is no infinite recursive loop, i'm pretty sure there
    // is no way to push twice the same previous node id here.
    const skipToNode = this.nodesById.get(node.skipToNodeId);
    if (skipToNode) skipToNode.previousNodeIds.push(node.id);

    // loop recursively to each next nodes
    this.linkQuestionsToEachOther(node.skipToNodeId);
    return node;
  }

  private routingToNextNodeIds(routing: Routing): UUID[] {
    const nodeIds = [];

    if (routing.default_effect.type === "next_node")
      nodeIds.push(routing.default_effect.next_node_id);

    routing.cases.forEach((c: SwitchCase) => {
      if (c.effect.type === "next_node") nodeIds.push(c.effect.next_node_id);
    });

    return nodeIds;
  }

  private routingToSkipToNodeId(routing: Routing): UUID | null {
    if (routing.default_effect.type === "skip_to_node")
      return routing.default_effect.skip_to_node_id;

    let nodeId = null;
    routing.cases.forEach((c: SwitchCase) => {
      if (c.effect.type === "skip_to_node") nodeId = c.effect.skip_to_node_id;
    });

    return nodeId;
  }

  // Here, we assume that there is no way to pass twice by the same point.
  // Recursive loop.
  // We send the current path over recursive loop, because we need to check if
  // next node is already part of current path.
  private listAllPaths(currentPath: UUID[], nodeId: UUID): UUID[][] {
    // check if node exists
    if (!nodeId) return [];
    const node = this.nodesById.get(nodeId);
    if (!node) return [];

    // termination condition
    if (currentPath.includes(nodeId)) return [currentPath.slice()];

    // add current node to path
    currentPath = currentPath.slice().concat(nodeId);

    // this returns an array of possible path, starting with nextNode
    const pathsPerNode: UUID[][][] = node.nextNodeIds.map((nid: UUID) =>
      this.listAllPaths(currentPath, nid),
    );

    // Include skipToNodeId in pathsPerNode
    if (node.skipToNodeId) {
      pathsPerNode.push(this.listAllPaths(currentPath, node.skipToNodeId));
    }

    if (pathsPerNode.length === 0) return [currentPath];

    // we merge each path from next nodes
    // and append the current nodes id just in begining of each path
    return [].concat(...pathsPerNode);
  }

  // fills the chainableNodeIds field from nodes objects
  // list the nodes that could be added as a "next step" in a CTA
  private listChainableNodes(paths: UUID[][]) {
    const allNodeIds = [];
    const previousNodeIdsByNodeId = {};
    for (const nodeId of this.nodesById.keys()) {
      allNodeIds.push(nodeId);
      previousNodeIdsByNodeId[nodeId] = [nodeId]; // includes itself
    }

    for (const path of paths) {
      for (let i = 1; i < path.length; i++) {
        // from second nodeId
        const nodeId = path[i];
        const previousNodeIds = path.slice(0, i);

        // here, we may add many time the same ids, but who cares ?
        previousNodeIdsByNodeId[nodeId].push(...previousNodeIds);
      }
    }

    for (const node of this.nodesById.values()) {
      node.chainableNodeIds = arrayExcludeElements(
        allNodeIds,
        previousNodeIdsByNodeId[node.id],
      );
    }
  }

  // nodes are placed into the rightmost columns
  // then we loop over paths from right to left, in order to fill columns
  private groupNodesByColumn(paths: UUID[][]): GraphNode[][] {
    if (paths.length === 0) return [];

    paths.sort((a: UUID[], b: UUID[]) => b.length - a.length);

    const nbrColumns = paths[0].length;
    if (!nbrColumns) return [];

    const output: GraphNode[][] = new Array(nbrColumns).fill(42).map(() => []);
    const placedNodeIds = [];

    for (let column = nbrColumns - 1; column >= 0; column--) {
      for (const path of paths) {
        // paths are ordered by length, then we don't need to go
        // into next iteration of this loop
        if (path.length < column + 1) break;

        // does not insert twice the same node in columns
        const nodeId = path[column];
        if (!nodeId || placedNodeIds.includes(nodeId)) continue;

        const node = this.nodesById.get(nodeId);
        if (!node) continue;

        output[column].push(node);
        placedNodeIds.push(nodeId);
      }
    }

    return output;
  }

  private setNodesNameAndIndex(columns: GraphNode[][]) {
    let i_str = "";
    let i_nbr = 0;
    for (const col of columns) {
      for (const n of col) {
        n.name = incrementString(i_str);
        n.index = i_nbr++;
        i_str = n.name;
      }
    }
  }

  private setQuestionDetails() {
    for (const node of this.nodesById.values()) {
      const question = node.node.question;
      if (!question) continue;

      const t = question.call_to_action.type;
      node.questionType = t;
      node.hasCTA =
        t !== "none" && !["appstore_rating", "calendar"].includes(t);
      node.isNPS = t === "nps";
      node.isCES = t === "ces";
      node.isLink = t === "link";
      node.isCSAT = t === "csat";
      node.isPMF = false;
      node.isPreset = node.isNPS || node.isCES || node.isCSAT || node.isPMF;
      node.isOpenEndedQuestion = t === "input" || t === "range";
      node.description = question.description;
    }
  }
}
