<div
  class="billing-plan"
  [style]="{
    'background-color': displayMode === 'trial' ? '#FFF6EA' : '#FFFFFF'
  }"
>
  <div class="plan-header">
    <h2 *ngIf="displayMode === 'normal'">
      {{ planTypeInfos[planType].name }}
    </h2>
    <h2 *ngIf="displayMode === 'entitlement'">Current Subscription Summary</h2>
    <h2 *ngIf="displayMode === 'service'">Your Services</h2>
    <h2 *ngIf="displayMode === 'trial'">Your Free Trial</h2>

    <nz-tag
      *ngIf="displayMode === 'normal' && isCurrentPlanType"
      nzColor="#F7F7F8"
    >
      Current
    </nz-tag>
    <nz-tag
      *ngIf="displayMode === 'normal' && !isCurrentPlanType"
      nzColor="#5E21F126"
    >
      Recommended
    </nz-tag>

    <nz-tag
      *ngIf="displayMode === 'service' && planType === 'custom'"
      nzColor="#5E21F126"
    >
      ✨Only for Custom Plan
    </nz-tag>
    <a
      *ngIf="displayMode === 'entitlement' && orgBillingInvoices.length > 0"
      (click)="goToInvoices.emit()"
    >
      See Invoices
    </a>
  </div>

  <ng-container *ngIf="displayMode === 'normal'">
    <ng-container *ngIf="isCurrentPlanType">
      <h1
        *ngIf="
          !freeTrial &&
          (currentPlanType === 'free' ||
            (orgBillingSubscription.amount ?? 0 > 0))
        "
      >
        <!-- we hide when a customer is in scale/custom plan but billing is made on pennylane -->
        {{ orgBillingSubscription.amount ?? 0 | price }}
        /
        {{ orgBilling.billing_cycle_duration ?? "month" }}
        <span class="coupon" *ngIf="!!orgBillingSubscription.discount">
          ({{ couponToString(orgBillingSubscription.discount) }})
        </span>
      </h1>
      <h1 *ngIf="freeTrial">Free Trial</h1>
      <p class="plan-intro">In your plan, you have...</p>
      <div
        class="plan-feature"
        *ngFor="let feature of planTypeInfos[planType]?.currentFeatures || []"
      >
        {{ typeOf(feature) === "string" ? feature : feature(orgBilling) }}
      </div>
    </ng-container>
    <ng-container *ngIf="!isCurrentPlanType">
      <h1 *ngIf="planTypeInfos[planType]?.startPrice">
        From {{ planTypeInfos[planType].startPrice | price }}/month
      </h1>
      <p class="plan-intro">✨By upgrading, you’ll gain...</p>
      <div
        class="plan-feature"
        *ngFor="let feature of planTypeInfos[planType]?.features || []"
      >
        {{ typeOf(feature) === "string" ? feature : feature(orgBilling) }}
      </div>
    </ng-container>

    <ng-container *ngIf="!isCurrentPlanType">
      <div class="trusted-by" *ngIf="planType === 'scale'">
        Trusted by
        <div class="trusted-by-logo-container">
          <img src="/assets/external/doctolib.png" alt="doctolib" />
          <img src="/assets/external/pennylane.png" alt="pennylane" />
          <img src="/assets/external/smallable.png" alt="smallable" />
          <img src="/assets/external/leocare.png" alt="leocare" />
          <img src="/assets/external/nespresso.png" alt="nespresso" />
          <img src="/assets/external/sosh.png" alt="sosh" />
        </div>
      </div>
      <ng-container *ngIf="planTypeInfos[planType].upgradeCTA">
        <button
          class="upgrade-btn"
          title="Upgrade now"
          nz-button
          nzType="primary"
          nzSize="large"
          (click)="upgrade()"
        >
          <span>{{ planTypeInfos[planType].upgradeCTA }}</span>
        </button>
        <a (click)="comparePlan()" class="compare-plan-btn">
          Compare our plans
        </a>
      </ng-container>
    </ng-container>

    <button
      *ngIf="
        isCurrentPlanType &&
        planType === 'scale' &&
        !freeTrial &&
        !orgBillingSubscription?.cancel_at
      "
      class="downgrade-btn"
      title="Downgrade now"
      nz-button
      nzType="primary"
      nzSize="large"
      (click)="downgrade()"
    >
      <span>Downgrade to Free Plan</span>
    </button>
  </ng-container>

  <ng-container *ngIf="displayMode === 'entitlement'">
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display: planType !== 'free',
          data: {
            title: 'Billing Cycle',
            value: cycleDurationToLabel[orgBilling.billing_cycle_duration],
            icon: 'hourglass'
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display:
            planType !== 'free' &&
            !freeTrial &&
            !!orgBillingSubscription?.current_period_end &&
            !orgBillingSubscription?.cancel_at,
          data: {
            title: 'Next Renewal',
            value: getFormattedDate(
              orgBillingSubscription?.current_period_end ?? 0
            ),
            icon: 'bye'
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display:
            planType !== 'free' &&
            !freeTrial &&
            !!orgBillingSubscription?.cancel_at,
          data: {
            title: 'Cancel date',
            value: getFormattedDate(orgBillingSubscription?.cancel_at ?? 0),
            icon: 'bye'
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display: planType !== 'free' && hasPaymentMethod(),
          data: {
            title: 'Payment Method',
            value: getPaymentMethodLabel(),
            icon: 'bye'
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display: planType !== 'free' && freeTrial,
          data: {
            title: 'Monthly Total at the End of The Free Trial',
            price: orgBillingSubscription.amount ?? 0,
            icon: 'union'
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          data: {
            display: planType !== 'free' && !freeTrial,
            title: 'Monthly Total',
            price: orgBillingSubscription.amount ?? 0,
            icon: 'union'
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display: true,
          data: {
            title: 'Number of MTUs',
            value:
              orgBilling.expected_metadata.mtu_scheme === 'limited'
                ? formatHighNumber(orgBilling.expected_metadata.max_mtu)
                : 'Unlimited',
            icon: 'union'
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display: true,
          data: {
            title: 'Surveys',
            value:
              orgBilling.expected_metadata.survey_scheme === 'limited'
                ? orgBilling.expected_metadata.max_surveys
                : 'Unlimited',
            icon: 'union',
            addon: false
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display: true,
          data: {
            title: 'Funnels',
            value:
              orgBilling.expected_metadata.funnel_scheme === 'limited'
                ? orgBilling.expected_metadata.max_funnels
                : 'Unlimited',
            icon: 'union',
            addon: false
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display: true,
          data: {
            title: 'Workspaces',
            value:
              orgBilling.expected_metadata.max_workspaces === -1
                ? 'Unlimited'
                : orgBilling.expected_metadata.max_workspaces,
            icon: 'union',
            addon:
              orgBilling.expected_metadata.max_workspaces >
              scalePlanDefaultWorkspaces
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display: orgBilling.expected_metadata.advanced_survey_customization,
          data: {
            title: 'Advanced Survey Customization',
            value: orgBilling.expected_metadata.advanced_survey_customization
              ? 'Active'
              : 'Inactive',
            icon: 'union',
            addon: true
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display: orgBilling.expected_metadata.white_label,
          data: {
            title: 'Hide \'Powered by Screeb\'',
            value: orgBilling.expected_metadata.white_label
              ? 'Active'
              : 'Inactive',
            icon: 'union',
            addon: true
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display: orgBilling.expected_metadata.response_translation,
          data: {
            title: 'Response Translation',
            value: orgBilling.expected_metadata.response_translation
              ? 'Active'
              : 'Inactive',
            icon: 'union',
            addon: true
          }
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        planSummaryElement;
        context: {
          display: orgBilling.expected_metadata.video_question,
          data: {
            title: 'Survey Audio / Video',
            value: orgBilling.expected_metadata.video_question
              ? 'Active'
              : 'Inactive',
            icon: 'union',
            addon: true
          }
        }
      "
    ></ng-container>
    <button
      *ngIf="
        orgBilling.billing_cycle_duration === 'month' &&
        !freeTrial &&
        planType === 'scale'
      "
      class="upgrade-btn"
      title="Upgrade now"
      nz-button
      nzType="primary"
      nzSize="large"
      [nzBlock]="true"
      (click)="upgradeAnnualBilling()"
    >
      <span>💳 Save 20% by Opting for Annual Billing Now!</span>
    </button>
    <button
      *ngIf="freeTrial"
      class="upgrade-btn"
      title="Upgrade now"
      nz-button
      nzType="primary"
      nzSize="large"
      (click)="upgrade()"
    >
      <span>✨ Subscribe Now and Keep Using Screeb!</span>
    </button>
  </ng-container>

  <ng-container *ngIf="displayMode === 'service'">
    <div class="services">
      <intercom class="service">
        <div class="fake-avatar">
          <img
            src="/assets/people/salome-cousseau.jpeg"
            alt="Salomé Cousseau"
          />
          <div class="activity-dot"></div>
        </div>
        <div class="content">
          Book a Meeting with Salomé, your dedicated Customer Success Manager
        </div>
      </intercom>
      <intercom class="service">
        <div class="fake-avatar">
          <img src="/assets/people/simon-robic-2.jpeg" alt="Simon Robic" />
          <div class="activity-dot"></div>
        </div>
        <div class="content">
          Book a Meeting with Simon, our Chief Product Officer, to talk about
          our product and our roadmap
        </div>
      </intercom>
      <intercom class="service">
        <div class="icon">👋</div>
        <div class="content">Request a training for your team members</div>
      </intercom>
      <intercom class="service">
        <div class="icon">💬</div>
        <div class="content">
          Send a livechat message to our support and get a response in under 5
          minutes
        </div>
      </intercom>
    </div>
  </ng-container>

  <ng-container *ngIf="displayMode === 'trial'">
    <div class="services">
      <div class="service free-trial">
        <span class="trial-status">
          {{ remainingTrialDays }} day{{
            remainingTrialDays > 1 ? "s" : ""
          }}
          left of your {{ getFormattedFreeTrialDuration() }} free trial
        </span>
        <nz-slider
          disabled
          [nzMin]="0"
          [nzMax]="getFreeTrialTotalDuration()"
          [nzDots]="false"
          [nzTooltipVisible]="'never'"
          [nzTooltipPlacement]="'bottom'"
          [ngModel]="selectedTrialStep"
        >
        </nz-slider>
      </div>
      <div class="service free-trial">
        During your free trial, you have a complete access to the Scale Plan!
      </div>
      <intercom class="service free-trial">
        <span>
          <screeb-icon size="sm" icon="survey" />
          Any question about your free trial? Chat with us!
        </span>
      </intercom>
      <div class="service free-trial">
        <p>
          ⚠️ If you don’t subscribe at the end of your free trial, you’ll be
          downgraded to a Free Account.
          <a (click)="comparePlan()">Compare our plans</a>.
        </p>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #planSummaryElement let-data="data" let-display="display">
  <div class="current-plan-summary" *ngIf="display">
    <div class="icon">
      <screeb-icon size="md" [icon]="data.icon" />
    </div>
    <div class="content">
      <div class="title">
        <p class="key">
          {{ data.title }}
          <i
            *ngIf="!!data.tooltip"
            nz-icon
            nzType="question-circle"
            nzTheme="outline"
            nz-tooltip
            [nzTooltipTitle]="data.tooltip"
          ></i>
        </p>
        <nz-tag *ngIf="data.addon" nzColor="#E4F8F2">🎟️ Add On</nz-tag>
      </div>
      <p *ngIf="!!data.value" class="value">{{ data.value }}</p>
      <p *ngIf="data.price !== undefined" class="value">
        {{ data.price | price }}
      </p>
    </div>
  </div>
</ng-template>
