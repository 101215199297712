<tag-settings-with-preview
  class="survey-settings"
  [additionalSettingsRef]="surveySettings"
  [(tagSettings)]="tagSettings"
  [defaultTagSettings]="org.settings"
  [org]="org"
  [survey]="survey"
  [orgIntegrations]="integrations"
  [surveyIntegrations]="survey.integrations"
  [translationEnabled]="translationEnabled"
  scope="survey"
  (specificDesignSwitchChange)="specificDesignSwitchChange($event)"
  (specificTranslationEnabledSwitchChange)="
    specifictranslationEnabledSwitchChange($event)
  "
  [interactionType]="interaction"
  (interactionTypeChange)="interactionTypeChange($event)"
  [tabSelectedIndex]="tabSelectedIndex"
>
</tag-settings-with-preview>

<ng-template #surveySettings>
  <div>
    <h2 class="title"><screeb-icon size="sm" icon="info-circle" /> Settings</h2>
    <div class="description">
      Just for you and your colleagues! These information will not be displayed
      to your users.
    </div>

    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label">
          {{ uiService.isMessagePage ? "Message" : "Survey" }} name
        </div>
        <input
          type="text"
          [(ngModel)]="survey.title"
          nzSize="large"
          nz-input
          placeholder="{{
            uiService.isMessagePage ? 'Message' : 'Survey'
          }} name"
          [ngClass]="{ invalid: !isValidSurveyName() }"
        />
      </div>
      <div nz-col nzXs="24" nzMd="12">
        <div class="label">Tags</div>
        <nz-select
          class="survey-tags-select"
          [(ngModel)]="survey.tags"
          nzSize="large"
          nzMode="tags"
          nzPlaceHolder="Choose or add tags"
        >
          <nz-option
            *ngFor="let tag of surveyTags"
            [nzLabel]="tag"
            [nzValue]="tag"
          ></nz-option>
        </nz-select>
      </div>
    </section>
  </div>
</ng-template>

<ng-template #pageActions>
  <div class="toolbar">
    <nz-button-group
      *ngIf="'survey.update' | hasPermission"
      nzSize="large"
      class="actions-save"
    >
      <button
        *ngIf="!getNbrResponse()"
        title="Save and next"
        nz-button
        class="ant-btn-secondary"
        nzSize="large"
        nzShape="round"
        [nzLoading]="saving"
        [disabled]="saveButtonDisabled()"
        (click)="actionOnSave()"
      >
        <screeb-icon size="sm" icon="arrow-right"></screeb-icon>
        <span>Save and next</span>
      </button>
      <button
        *ngIf="getNbrResponse()"
        title="Save"
        nz-button
        class="ant-btn-secondary"
        nzSize="large"
        nzShape="round"
        [nzLoading]="saving"
        [disabled]="saveButtonDisabled()"
        (click)="actionOnSave()"
      >
        <screeb-icon size="sm" icon="save"></screeb-icon>
        <span>Save</span>
      </button>
    </nz-button-group>
  </div>
</ng-template>
