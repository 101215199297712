import { Injectable } from "@angular/core";
import {
  OrgAccountPermission,
  WorkspaceAccountPermission,
} from "models/account-permissions";
import { UIService } from "./ui.service";

@Injectable()
class PermissionsService {
  constructor(private uiService: UIService) {}

  isAllowed(key: WorkspaceAccountPermission): boolean {
    return this.uiService.currentOrg?.permissions?.includes(key) ?? false;
  }

  isSuperAllowed(key: OrgAccountPermission): boolean {
    return this.uiService.currentSuperOrg?.permissions?.includes(key) ?? false;
  }
}

export { PermissionsService };
