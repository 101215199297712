import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";

import { Org } from "models/org.model";

import {
  NzTableQueryParams,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { UIService } from "services/ui.service";
import { Debounce } from "utils/debounce";
import { UserRecord } from "models/user-record.model";
import { intervalToDuration } from "date-fns";
import { AnalyticsResponseItemUser } from "models/analytics.model";
import { UUID } from "models/survey.dao.types";
import { AnalyticsQueryUsers } from "models/analytics.filters.type";
import { AnalyticsDao } from "models/analytics.dao";
import { RegistryEntry } from "models/registry.model";
import { UserRecordDao } from "models/user-record.dao";

interface ColumnItem {
  name: string;
  sortKey?: string;
  width?: string;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null | true;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "user-record-list-page",
  templateUrl: "./user-record-list.component.html",
  styleUrls: ["./user-record-list.component.scss"],
})
export class UserRecordListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Sessions recordings";
  public name = "Sessions recordings";

  private obs: any = null;
  public org: Org;
  public registryEntriesGroup: RegistryEntry[] = [];

  public keyword = "";
  public searching = false;
  public loading = true;
  public initialFetch = true;
  public isUsersLoading = false;
  public error: Error;
  public pageSize = 10;
  public pageIndex = 1;
  public total = 0;
  public maxOffset = 0;
  public sessions: UserRecord[] = [];
  public users: Record<string, AnalyticsResponseItemUser> = {};

  listOfColumns: ColumnItem[] = [
    {
      name: "",
      width: "60px",
      align: "center",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: true,
    },
    {
      name: "Highlights",
      width: "300px",
      align: "left",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: true,
    },
    {
      name: "User",
      width: "200px",
      align: "center",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: false,
    },
    {
      name: "Duration",
      width: "150px",
      sortKey: "session.snapshot_duration_seconds",
      align: "center",
      sortOrder: "descend",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Date and device",
      width: "150px",
      sortKey: "session.session_start",
      align: "center",
      sortOrder: "descend",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public analyticsFilterService: AnalyticsFilterService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public uiService: UIService,
    private userRecordDao: UserRecordDao,
    private analyticsDao: AnalyticsDao,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.initialFetch = false;
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public trackingCTAVisible(): boolean {
    if (this.org.stats.total_respondents > 0) return false;
    if (this.sessions.length > 0) return false;
    return true;
  }

  private getSessions() {
    this.loading = true;
    this.error = null;

    return this.userRecordDao
      .getByWorkspaceID(this.org.id)
      .then((records) => {
        records.sort(
          (a, b) => Number(b.session_start) - Number(a.session_start),
        );

        // hide records shorter than 5 seconds
        records = records.filter((r) => r.snapshot_duration_seconds > 5);

        this.sessions = records;
        this.getUsersByIds(records.map((r) => r.respondent_id));
      })
      .catch((error) => {
        // this.errorRecords = error;
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private async getUsersByIds(ids: string[]) {
    // Filters ids already fetched
    const idsToFetch = Array.from(new Set(ids)).filter((id) => !this.users[id]);
    if (idsToFetch.length === 0) {
      return;
    }
    this.isUsersLoading = true;
    const query: AnalyticsQueryUsers = {
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      filters_bool: "OR",
      type: "respondent",
      filters: idsToFetch.map((id) => ({
        type: "respondent",
        key: "aliases",
        operator: "eq",
        value: id,
      })),
      range: {
        start: this.org.created_at,
        end: new Date(),
        field: "created_at",
      },
      identified_only: false,
      size: idsToFetch.length,
    };
    const response = await this.analyticsDao.search(query);
    for (const user of response.hits.respondents ?? []) {
      this.users[user.id] = user;
    }
    this.isUsersLoading = false;
  }

  public onQueryParamsChange(params: NzTableQueryParams) {
    let { pageIndex } = params;
    const { pageSize } = params;

    if (pageSize !== this.pageSize) {
      pageIndex = 1;
    }

    this.pageSize = pageSize;
    this.pageIndex = pageIndex;

    this.getSessions();
  }

  /**
   * Search
   */
  @Debounce(400)
  public onSearchChange(keyword: string) {
    this.onSearchChangeImmediate(keyword);
  }

  // without debounce
  public onSearchChangeImmediate(keyword: string) {
    this.analyticsFilterService.setKeyword(keyword.trim());
    this.searching = true;
    this.getSessions();
  }

  public duration(seconds: number) {
    return intervalToDuration({ start: 0, end: seconds * 1000 });
  }
}
