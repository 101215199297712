import { RegistryEntrySource } from "models/registry.model";
import { Serializable } from "models/serializable.model";
import { SurveyScenario } from "models/survey.dao.types";
import { Survey } from "models/survey.model";

export type ResponseCompletion =
  | "not_started"
  | "partially_completed"
  | "fully_completed";

export type ResponseEndReason =
  | "completed"
  | "closed"
  | "expired"
  | "interrupted"
  | "deleted";

export type ResponseDeletionReason =
  | "preview"
  | "retention_policy"
  | "manual_response_deletion"
  | "manual_survey_deletion"
  | "manual_respondent_deletion";

export class Response extends Serializable {
  constructor(
    public id?: string,
    public respondent_id?: string,
    public survey_id?: string,
    public scenario_id?: string,
    public channel_id?: string,
    public source?: RegistryEntrySource,
    public context?: object,
    public hidden_fields?: object,
    public hidden_fields2?: object,
    public properties_snapshot?: object,
    public emotions?: object,
    public group_ids?: string[],
    public locale?: string | null, // optional
    public completion?: ResponseCompletion,
    public active?: boolean,
    public preview?: boolean,
    public imported?: boolean,
    public last_answer_at?: Date | null,
    public end_reason?: ResponseEndReason | null,
    public ended_at?: Date | null,

    public created_at?: Date,
    public updated_at?: Date,
    public expired_at?: Date | null,
    public deleted_at?: Date | null,
    public deletion_reason?: ResponseDeletionReason | null,
    public deletion_grace_period_end_at?: Date | null, // we don't expect to see this value in frontend, since response would have been deleted

    // dynamic
    public survey?: Survey,
    public scenario?: SurveyScenario,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    this.source = json["source"] as RegistryEntrySource;
    this.completion = json["completion"] as ResponseCompletion;
    this.end_reason = json["end_reason"] as ResponseEndReason;
    this.deletion_reason = json["deletion_reason"] as ResponseDeletionReason;

    if (json["survey"]) {
      this.survey = new Survey().fromJson(json["survey"]);
    }

    if (json["scenario"]) {
      this.scenario = json["scenario"] as SurveyScenario;
    }

    return this;
  }
}
