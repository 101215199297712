<button
  nz-button
  nzType="default"
  nzSize="large"
  nzTrigger="click"
  [nzVisible]="visibleDropdown"
  (nzVisibleChange)="switchPanelVisibility($event)"
  nz-dropdown
  [nzDropdownMenu]="dropdown"
  nzPlacement="bottomRight"
>
  <i nz-icon nzType="filter" nzTheme="outline"></i>
  {{ title }}
</button>

<nz-dropdown-menu #dropdown="nzDropdownMenu">
  <div class="analysis-segment-dropdown" *ngIf="visibleDropdown">
    <survey-filter-criteria-filters
      [visibleDropdown]="visibleDropdown"
      [loadingDropdown]="loadingDropdown"
      [(filtersOperator)]="filtersOperator"
      [(filters)]="filters"
      [(lastFilters)]="lastFilters"
      [(keysOptions)]="keysOptions"
      [availableLanguages]="availableLanguages"
      [availableTags]="availableTags"
      [title]="title"
      [getOperatorOptions]="getOperatorOptions"
      [getValueComponent]="getValueComponent"
      [getValuePresetOptions]="getValuePresetOptions"
      [getLabel]="getLabel"
      [nodesByKey]="nodesByKey"
      [survey]="survey"
      (getAvailableFields)="getAvailableFields()"
      (save)="onSave()"
      (filterKeyChange)="onFilterKeyChange($event.f, $event.event)"
      (operatorChange)="onOperatorChange($event)"
      (filterAdded)="onFilterAdded($event)"
      [registryEntriesGroup]="registryEntriesGroup"
      [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
      [registryEntriesEvent]="registryEntriesEvent"
    ></survey-filter-criteria-filters>
  </div>
</nz-dropdown-menu>

<ng-template #selectDefaultTemplate let-selected>
  <sanitized-message-component
    [content]="selected.nzLabel"
    [options]="{
      styling: true,
      emojiTranscoder: true
    }"
  ></sanitized-message-component>
</ng-template>
