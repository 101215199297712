import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import {
  ScenarioQuestionTypeMessage,
  availableQuestionTypeMessagePlacement,
  questionTypeMessagePlacementLabels,
} from "models/survey.dao.types";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";

@Component({
  selector: "lateral-panel-edit-message",
  templateUrl: "./edit-message.component.html",
  styleUrls: ["./edit-message.component.scss"],
})
export class LateralPanelEditMessageComponent implements OnInit {
  @Input() node: GraphNode = null;

  @Output() errorChange = new EventEmitter<boolean>();
  @Output() openTagEditorArIndex = new EventEmitter<number>();

  public availableQuestionTypeMessagePlacement =
    availableQuestionTypeMessagePlacement;
  public questionTypeMessagePlacementLabels =
    questionTypeMessagePlacementLabels;

  public hasSelectorError = false;

  constructor(
    public builderStore: BuilderStore,
    public entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    this.validateData();
  }

  public get question(): any {
    return this.node.node.question as ScenarioQuestionTypeMessage;
  }

  validateSelector(selector: string): boolean {
    if (!selector) {
      return false;
    }

    try {
      document.querySelector(selector);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Data validation
   */
  public validateData() {
    if (this.node.node.question.type === "banner") {
      if (this.node.node.question.position === "fixed") {
        this.node.node.question.size = "full";
      } else if ((this.node.node.question as any).size === "full") {
        this.node.node.question.size = "medium";
      }
    } else if (
      this.node.node.question.type === "beacon" ||
      this.node.node.question.type === "popover"
    ) {
      this.hasSelectorError = !this.validateSelector(
        this.node.node.question.selector,
      );
      this.errorChange.emit(this.hasSelectorError);
    }
    this.errorChange.emit(false);
  }

  public onOpenPreviewClick() {
    this.openTagEditorArIndex.emit(this.node.index);
  }
}
