/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OrgBilling } from "models/org_billing.model";

@Component({
  selector: "billing-subscription-paused-subscription-alert",
  template: `
    <!-- <nz-alert
      *ngIf="orgBilling.billing_status === 'paused' || true"
      nzShowIcon
      nzType="error"
      nzMessage="Subscription paused"
      [nzDescription]="descriptionTemplate"
    ></nz-alert>
    <ng-template #descriptionTemplate>
      Your subscription has been paused. Please check
      <a (click)="onTabChange.emit('payment')">payment methods</a> or
      <a (click)="onTabChange.emit('invoices')">unpaid invoices</a>.
      <br />
      In case of issue, please
      <a href="mailto:michael@screeb.app" (click)="onContactUs($event)"
        >contact us</a
      >.
    </ng-template> -->

    <div
      *ngIf="orgBilling.billing_status === 'paused'"
      class="payment-issue-banner"
      [style]="{ 'background-color': '#FFEEF1' }"
    >
      <span class="payment-issue-title">
        Your subscription has been paused. Please check your
        <a (click)="onTabChange.emit('payment')">payment methods</a> or
        <a (click)="onTabChange.emit('invoices')">unpaid invoices</a>.
      </span>

      <intercom-button title="Talk to us!" />
    </div>
  `,
  styles: [
    `
      /* :host {
        display: block;
        max-width: 1000px;
        margin: auto;
      } */

      .payment-issue-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        /* border-radius: 16px; */
        width: 100%;
        min-height: 72px;
        padding: 16px 90px;
        animation: shadeAppear 200ms;
        margin-bottom: 32px;
      }

      .payment-issue-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        color: #303140;
      }

      @keyframes shadeAppear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `,
  ],
})
export class SettingsBillingSubscriptionPausedSubscriptionAlertComponent {
  @Input() orgBilling: OrgBilling;

  @Output() onTabChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}
}
