import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ActivationStart,
  ChildActivationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";

@Component({
  selector: "app-root",
  template: `
    <router-outlet></router-outlet>
    <div *ngIf="isPageLoading" id="page-loader">
      <img src="/assets/loader-screeb-small.gif" title="Loader" alt="Loader" />
    </div>
  `,
  styles: `
    #page-loader {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 701;
      width: 100%;
      height: 100%;
      background: var(--screeb-color-background);

      img {
        position: relative;
        top: calc(50% - 38px);
        left: calc(50% - 75px);
        width: 150px;
        height: 76px;
      }
    }
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  private obs = null;
  public isPageLoading: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.obs = this.router.events.subscribe((e) => this.onRouterEvent(e));
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }

  private onRouterEvent(event) {
    switch (true) {
      case event instanceof NavigationStart:
        // we does not set pageLoading to true here,
        // because NavigationStart is triggered before CanDeactivate
        //   => on bot editor page, if the state is destroy before navigation cancellation
        //      the component will be destroyed and ngOnInit called once again
        break;
      case event instanceof ChildActivationStart:
      case event instanceof ActivationStart:
        this.isPageLoading = true;
        break;
      case event instanceof NavigationEnd:
        this.isPageLoading = false;
        break;
      case event instanceof NavigationCancel:
        this.isPageLoading = false;
        break;
      case event instanceof NavigationError:
        this.isPageLoading = false;
        break;
      default:
        break;
    }
  }
}
