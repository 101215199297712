import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";

import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";
import { ApplicationLoadService } from "services/app.service";
import { UIService } from "services/ui.service";

@Injectable()
export class WorkspacesResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private orgDao: OrgDao,
    private uiService: UIService,
  ) {}

  resolve(): Promise<Org[]> {
    return this.applicationLoadService
      .loader()
      .then((isAuth) => {
        if (!isAuth) {
          return [];
        }
        return this.orgDao.getAll().then((orgs: Org[]) => {
          this.uiService.orgs = orgs;
          return orgs;
        });
      })
      .catch((err: HttpErrorResponse) => {
        // this.notificationHelper.trigger("Failed to fetch orgs", null, 'error');
        console.error(err.error);
        throw err;
      });
  }
}
