<nz-modal
  [(nzVisible)]="isOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="null"
  [nzClosable]="false"
  (nzOnCancel)="handleClose()"
  nzWidth="757px"
  nzHeight="633px"
  nzClassName="record-video-modal"
>
  <ng-template #modalTitle>
    <div class="modal-header">
      <h1>
        <sanitized-message-component
          content="🎥"
          [options]="{ emojiTranscoder: true }"
        >
        </sanitized-message-component>
        Record a new video question
      </h1>
      <p>Your video will be automatically cropped to fit our survey formats</p>
    </div>
  </ng-template>

  <ng-template #modalContent>
    <div class="modal-content">
      <ng-container *ngIf="mode === 'record'">
        <div class="video-container">
          <div class="loading-overlay" *ngIf="!isLoaded">
            <div class="loading-container">
              <span
                nz-icon
                nzType="loading"
                class="circle-loader"
                nzTheme="outline"
              ></span>
            </div>
          </div>
          <div class="countdown" *ngIf="isRecording && countDown > 0">
            {{ countDown }}
          </div>
          <video
            [class]="{
              loading: !isLoaded
            }"
            [muted]="true"
            [mediaStream]="videoRecordConfig"
            (streamReady)="onMediaReady()"
            (initError)="onVideoStreamError($event)"
            (videoRecorded)="onVideoRecorded($event)"
          ></video>
          <div
            class="ratio-border left"
            [style]="{
              width: aspectRatioData.margin + 'px'
            }"
          ></div>
          <div
            class="ratio-border right"
            [style]="{
              width: aspectRatioData.margin + 'px'
            }"
          ></div>
        </div>
        <div class="actions-container">
          <div
            class="device-select-group align-left"
            *ngIf="!isRecording"
          ></div>
          <div
            *ngIf="isLoaded && !isRecording"
            class="action-btn record-btn"
            (click)="startRecording()"
          ></div>
          <div
            *ngIf="isLoaded && isRecording"
            class="action-btn record-btn recording"
            (click)="stopRecording()"
          >
            <div class="icon"></div>
          </div>
          <div class="device-select-group" *ngIf="!isRecording">
            <div class="device-select">
              <sanitized-message-component
                content="🎥"
                [options]="{ emojiTranscoder: true }"
              >
              </sanitized-message-component>
              <nz-select
                nzPlaceHolder="Camera Device"
                name="camera-device"
                [nzShowArrow]="true"
                nzSuffixIcon="caret-down"
                [ngModel]="selectedDeviceVideo"
                (ngModelChange)="handleDeviceVideoChange($event, true)"
              >
                <nz-option
                  *ngFor="let device of mediaDeviceVideo"
                  [nzLabel]="formatDeviceLabel(device.label)"
                  [nzValue]="device.deviceId"
                ></nz-option>
              </nz-select>
            </div>
            <div class="device-select">
              <sanitized-message-component
                content="🎙"
                [options]="{ emojiTranscoder: true }"
              >
              </sanitized-message-component>
              <nz-select
                nzPlaceHolder="Audio Device"
                name="audio-device"
                [nzShowArrow]="true"
                nzSuffixIcon="caret-down"
                [ngModel]="selectedDeviceAudio"
                (ngModelChange)="handleDeviceAudioChange($event, true)"
              >
                <nz-option
                  *ngFor="let device of mediaDeviceAudio"
                  [nzLabel]="formatDeviceLabel(device.label)"
                  [nzValue]="device.deviceId"
                ></nz-option>
              </nz-select>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="mode === 'preview'">
        <div class="video-container" *ngIf="record !== null">
          <video autoplay loop [src]="recordURL"></video>
          <div
            class="ratio-border left"
            [style]="{
              width: aspectRatioData.margin + 'px'
            }"
          ></div>
          <div
            class="ratio-border right"
            [style]="{
              width: aspectRatioData.margin + 'px'
            }"
          ></div>
        </div>
        <div class="actions-container">
          <div class="flex">
            <div class="action-btn retry-btn" (click)="retryRecording()">
              <screeb-icon size="sm" icon="undo" />
            </div>
            <div class="action-btn next-btn" (click)="confirmRecording()">
              <screeb-icon size="sm" icon="arrow-right" />
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</nz-modal>
