import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";

import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { ChannelDao } from "models/channel.dao";
import { SeoService } from "services/seo.service";
import { ChannelType, channelTypes } from "models/channel.model";
import { PageComponentInterface } from "components/PageComponentInterface";
import { RoutingService } from "services/routing.service";

function channelTypeValidator(control: FormControl) {
  if (control.value) {
    const matches = channelTypes.includes(control.value as ChannelType);
    return matches ? null : { invalidType: true };
  }
  return null;
}

@Component({
  selector: "page-channel-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class CreateChannelPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "New channel";
  public name = "New channel";

  private obs: any = null;
  public org: Org = null;

  public loading: boolean = false;
  public errors: any = null;

  public validateForm: FormGroup = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private notificationHelper: NotificationHelper,
    private seoService: SeoService,
    private channelDao: ChannelDao,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
    });

    this.validateForm = this.formBuilder.group({
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(128),
        ],
      ],
      type: [null, [Validators.required, channelTypeValidator]],
    });
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }

  public onSubmit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (!this.validateForm.valid) return;

    this.loading = true;
    this.errors = null;

    const value = this.validateForm.value;
    this.channelDao
      .create(this.org.id, value.type, value.name)
      .then(() => {
        this.errors = null;
        this.router.navigate([`/org/${this.org.id}/survey`]);
      })
      .catch((err: HttpErrorResponse) => {
        // this.notificationHelper.trigger(err?.error?.message?? "Failed to create channel", null, 'error');
        this.errors = err.error;
        console.error(err.error);
      })
      .then(() => {
        this.loading = false;
      });
  }
}
