<div nz-row class="page-container">
  <div nz-col [nzXs]="24" [nzMd]="12" class="column-container">
    <div class="inner-left">
      <div class="welcome-header">
        <img src="/assets/logo-purple-500.svg" alt="Screeb logo" />
        <ng-container *ngIf="org.logo">
          <span>x</span>
          <img [src]="org.logo" [alt]="org.name" />
        </ng-container>
      </div>
      <h1 class="main-title">
        <span>Nice to meet you </span>
        <p *ngIf="!askForFirstAndLastName" class="emphasis">
          {{ localAccount.firstname }}👋
        </p>
        <p *ngIf="askForFirstAndLastName" class="emphasis">👋</p>
      </h1>
      <p class="main-description">We need a little more info about you</p>
      <div class="main-description emphasis">
        <span
          >to taylor your experience with Screeb at
          <span class="company-name">{{ org.name }}</span></span
        >
      </div>
      <form
        nz-form
        [nzAutoTips]="autoTips"
        [formGroup]="validateForm"
        class="profile-form"
        (ngSubmit)="onSubmit()"
      >
        <ng-container *ngIf="askForFirstAndLastName">
          <nz-form-item>
            <nz-form-label nzFor="firstname">Your First Name</nz-form-label>
            <nz-form-control>
              <nz-input-group>
                <input
                  class="firstname-input"
                  type="text"
                  id="firstname"
                  nz-input
                  nzHasFeedback
                  formControlName="firstname"
                  placeholder="Frida"
                  [(ngModel)]="localAccount.firstname"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="lastname">Your Last Name</nz-form-label>
            <nz-form-control>
              <nz-input-group>
                <input
                  class="lastname-input"
                  type="text"
                  id="lastname"
                  nz-input
                  nzHasFeedback
                  formControlName="lastname"
                  placeholder="Kahlo"
                  [(ngModel)]="localAccount.lastname"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
        <nz-form-item>
          <nz-form-label nzFor="profile_picture"
            >Your Profile Picture</nz-form-label
          >
          <nz-form-control>
            <nz-input-group>
              <input
                class="profile-picture-upload-input"
                type="file"
                id="profile_picture"
                nzHasFeedback
                formControlName="profile_picture"
                (change)="onFileSubmit($event.target.files[0])"
              />
              <span *ngIf="!!profilePictureFileName">{{
                profilePictureFileName
              }}</span>
              <screeb-icon size="md" icon="move-up" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="job_title"
            >Your Role at&nbsp;<span class="company-name">{{ org.name }}</span
            >*</nz-form-label
          >
          <nz-form-control>
            <nz-select
              id="job_title"
              nzHasFeedback
              formControlName="job_title"
              nzPlaceHolder="Product Manager"
              [(ngModel)]="localAccount.flags.job_title"
            >
              <nz-option
                *ngFor="let job of accountJobTitles"
                [nzValue]="job"
                [nzLabel]="accountJobTitlesToLabel[job]"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="tel">Your Phone Number</nz-form-label>
          <nz-form-control>
            <nz-input-group>
              <input
                id="phone_number"
                type="phone_number"
                nz-input
                nzHasFeedback
                formControlName="phone_number"
                placeholder="+1 548 351 954"
                [(ngModel)]="localAccount.phone_number"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="!isInvited">
          <nz-form-label nzFor="industry">Industry*</nz-form-label>
          <nz-form-control>
            <nz-select
              id="industry"
              nzHasFeedback
              formControlName="industry"
              nzPlaceHolder="SaaS B2B, Finance.."
              [nzOptions]="industries"
            >
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <div class="form-submit-error" *ngIf="!!errors?.message">
          <utils-form-error [errors]="[errors?.message]"></utils-form-error>
        </div>

        <div class="actions">
          <button
            nz-button
            class="big-button"
            nzType="primary"
            nzSize="large"
            [nzLoading]="loading"
          >
            <span>Continue</span>
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.4241 5.38278C10.4719 5.26756 10.4988 5.14146 10.5 5.00923C10.5 5.00616 10.5 5.00308 10.5 5M10.4241 5.38278C10.3753 5.50073 10.303 5.61123 10.2071 5.70711L6.20711 9.70711C5.81658 10.0976 5.18342 10.0976 4.79289 9.70711C4.40237 9.31658 4.40237 8.68342 4.79289 8.29289L7.08579 6L1.5 6C0.947715 6 0.5 5.55229 0.5 5C0.5 4.44772 0.947715 4 1.5 4L7.08579 4L4.79289 1.70711C4.40237 1.31658 4.40237 0.683418 4.79289 0.292893C5.18342 -0.0976308 5.81658 -0.0976309 6.20711 0.292893L10.2071 4.29289C10.4 4.48581 10.4976 4.73793 10.5 4.99077"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </form>
    </div>
  </div>

  <div nz-col [nzXs]="24" [nzMd]="12" class="column-container column-right">
    <div class="preview-right">
      <onboarding-desktop-preview
        [firstName]="localAccount.firstname"
        [profilePicture]="getProfileOrCompanyPictureUrl()"
      />
      <onboarding-mobile-preview
        [authorName]="localAccount.fullname"
        [authorJob]="accountJobTitlesToLabel[localAccount.flags.job_title]"
        [authorProfilePicture]="getProfileOrCompanyPictureUrl()"
      />
    </div>
  </div>
</div>
