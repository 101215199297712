<div
  class="BigCard"
  [ngClass]="{ ReadOnly: builderStore.readOnly }"
  [ngStyle]="{
    'padding-bottom': hasMissingTranslation() ? '32px' : ''
  }"
  (click)="onClick.emit($event)"
>
  <div class="BigCardInner">
    <card-type [questionType]="type" [letter]="letter"></card-type>
    <div class="BigCardLabel">
      <sanitized-message-component
        *ngIf="labelType === 'text' || !labelType"
        [content]="getTranslatedTextLabel()"
        [options]="{
          links: true,
          styling: true,
          emojiTranscoder: true,
          CR: true
        }"
      ></sanitized-message-component>
      <div class="video-thumbnail" *ngIf="labelType === 'video'">
        <video
          class="video"
          [poster]="getTranslatedVideoThumbnailLabel()"
          autoplay
          #videoThumbnail
        >
          <source [src]="getTranslatedVideoThumbnailLabel()" type="video/mp4" />
          Your Browser doesn't support this video format
        </video>
      </div>
      <sanitized-message-component
        *ngIf="subLabel?.length"
        class="sub-label"
        [content]="subLabel"
        [options]="{
          links: true,
          styling: true,
          emojiTranscoder: true,
          CR: true
        }"
      ></sanitized-message-component>
      <span class="has-error" *ngIf="hasMissingTranslation()">
        <span> Missing translation</span>
        <i nz-icon nzType="warning"></i>
      </span>
      <span class="has-error" *ngIf="!hasMissingTranslation() && haveError">
        <i nz-icon nzType="warning"></i>
      </span>
    </div>
  </div>
  <div class="BigCardSelector" *ngIf="type === 'beacon' || type === 'popover'">
    <screeb-icon size="sm" icon="pin"></screeb-icon>
    {{ selector?.length ? selector : "Place element" }}
    <i *ngIf="!selector?.length" nz-icon nzType="warning"></i>
  </div>
  <div class="ActionDotContainer">
    <action-dot
      *ngIf="hasActionDot && !builderStore.readOnly"
      [displayPlainState]="false"
      (onClick)="actionDotClick($event)"
    ></action-dot>
  </div>
  <div
    class="PathPercentContainer"
    [class]="{ 'have-skip': haveSkip }"
    *ngIf="
      haveNext &&
      builderStore.readOnly &&
      type === 'input' &&
      builderStore.showPathCount
    "
  >
    {{ formatNumber(pathCount ?? 0, "percent") }}
  </div>
  <div
    class="SkipPathPercentContainer"
    [class]="{ 'have-next': haveNext }"
    *ngIf="haveSkip && builderStore.readOnly && builderStore.showPathCount"
  >
    {{ formatNumber(skipPathCount ?? 0, "percent") }}
  </div>
</div>
