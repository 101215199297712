import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { UserRecord } from "./user-record.model";

@Injectable()
export class UserRecordDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getByWorkspaceID(workspaceID: string): Promise<UserRecord[]> {
    return this.screebApiHelper
      .get<object>(`/org/${workspaceID}/record`)
      .toPromise()
      .then((data: object[]): UserRecord[] => {
        return data.map((p: object) => new UserRecord().fromJson(p));
      });
  }

  public getByUserID(
    workspaceID: string,
    userId: string,
  ): Promise<UserRecord[]> {
    return this.screebApiHelper
      .get<object>(`/org/${workspaceID}/user/${userId}/record`)
      .toPromise()
      .then((data: Record<string, any>): UserRecord[] => {
        return data.records.map((p: object) => new UserRecord().fromJson(p));
      });
  }

  public getById(workspaceID: string, recordId: string): Promise<UserRecord> {
    return this.screebApiHelper
      .get<object>(`/org/${workspaceID}/record/${recordId}`)
      .toPromise()
      .then((data: object): UserRecord => {
        return new UserRecord().fromJson(data);
      });
  }
}
