<section>
  <div
    class="settings"
    *ngIf="
      integration?.settings?.webhook?.items &&
      (integration.settings.webhook.items.length > 0 || !hideListOnEmpty)
    "
  >
    <nz-table
      #table
      [nzData]="integration.settings.webhook.items"
      [nzHideOnSinglePage]="true"
      [nzPageSize]="100"
    >
      <thead>
        <tr>
          <th>Webhooks</th>
          <th nzWidth="50px"></th>
          <th nzWidth="50px"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of table.data; let i = index">
          <tr
            class="item-opened-separator"
            [ngClass]="{
              visible:
                !!item.expanded || (i > 0 && !!table.data[i - 1]?.expanded)
            }"
          >
            <td colspan="3"></td>
          </tr>
          <tr
            (click)="expandItem(i)"
            class="item-header"
            [ngClass]="{ opened: isItemExpanded(i) }"
          >
            <td>
              {{ item.name }}
            </td>
            <td>
              <!-- hide when item is not defined, to prevent saving an empty item -->
              <nz-tag *ngIf="item.id" nzColor="#00C898"
                ><span class="tag">v{{ item.version }}</span></nz-tag
              >
            </td>
            <td>
              <!-- hide when item is not defined, to prevent saving an empty item -->
              <nz-switch
                *ngIf="item.id"
                [ngModel]="item.enabled"
                (ngModelChange)="onItemStatusChange(i)"
                (click)="$event.stopPropagation()"
                [nzCheckedChildren]="checkedTemplate"
                [nzUnCheckedChildren]="unCheckedTemplate"
              ></nz-switch>
            </td>
          </tr>
          <tr
            *ngIf="isItemExpanded(i)"
            class="item-body"
            [ngClass]="{ opened: isItemExpanded(i) }"
          >
            <td colspan="3">
              <integration-settings-webhook-edit
                [org]="org"
                [survey]="survey"
                [hook]="item"
                (onSave)="onItemSaved(i, $event)"
                (onCancel)="onItemCanceled()"
                (onRemove)="onItemRemoved(i)"
              >
              </integration-settings-webhook-edit>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>

  <div class="left actions">
    <button nz-button nzSize="large" nzType="primary" (click)="onItemAdd()">
      Add a new webhook
    </button>
  </div>
</section>

<p class="doc">
  Check our technical documentation
  <a href="https://developers.screeb.app/" target="_blank">here</a>.
</p>

<section class="signature" *ngIf="scope === 'org'">
  <h3>Signature secret (optional)</h3>
  <text-snippet-copy
    [reset]="true"
    [copyText]="integration.token"
    (onReset)="onReset()"
    (onCopy)="onCopy()"
  >
  </text-snippet-copy>
</section>

<ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
<ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
