import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ColorPickerControl } from "@iplab/ngx-color-picker";

const padColor = (color: string, alpha: boolean) =>
  alpha && color.length === 7 ? color + "FF" : color;

@Component({
  selector: "color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
})
export class ColorPickerComponent implements OnInit {
  @Input()
  public color = "#5E21F1";
  @Input()
  public alpha = false;

  @Output()
  public colorChange: EventEmitter<string> = new EventEmitter();

  public chromeControl = new ColorPickerControl().hidePresets();

  ngOnInit(): void {
    if (!this.alpha) {
      this.chromeControl.hideAlphaChannel();
    }
  }

  public onBlur() {
    this.color = padColor(this.color, this.alpha);
    this.colorChange.emit(this.color);
  }

  public onColorChange(color: string) {
    this.colorChange.emit(padColor(color, this.alpha));
  }
}
