import { Serializable } from "models/serializable.model";
import { ChannelType } from "./channel.model";
import { SurveyTargetingRule } from "./survey-targeting-rule.model";
import { TagSettings } from "./tag-settings.types";

export class SurveyDistribution extends Serializable {
  constructor(
    public id?: string,
    public survey_id?: string,
    public channel_id?: string,

    public type?: ChannelType,
    public enabled?: boolean,
    public settings?: TagSettings,
    public test_mode?: boolean,
    public selector_css?: string,

    public created_at?: Date,
    public updated_at?: Date,

    public targeting_rules?: SurveyTargetingRule[],
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["settings"] && !Object.keys(json["settings"]).length) {
      this.settings = null;
    }

    if (json["targeting_rules"])
      this.targeting_rules = json["targeting_rules"].map((r) =>
        new SurveyTargetingRule().fromJson(r),
      );

    if (json["selectors_css"] && json["selectors_css"].length > 0) {
      this.selector_css = json["selectors_css"][0];
    }

    return this;
  }
}
