<div
  #builderLayout
  *ngIf="!showPickStartUrl"
  id="builder-layout"
  [ngClass]="isLoading ? 'loading' : ''"
  (window:resize)="onResize()"
>
  <!-- new question popin -->
  <card-menu-popin
    *ngIf="!!cardMenuPopinOrigin"
    [origin]="cardMenuPopinOrigin"
    [surveyType]="builderStore.survey?.type ?? 'survey'"
    [isNodeLeaf]="cardMenuPopinIsNodeLeaf"
    [isNodeRoot]="cardMenuPopinIsNodeRoot"
    [format]="builderStore.getSurveyFormat()"
    (onClickOutside)="closeMenu()"
    (onNewNode)="addNode($event)"
    (onGenerateSurvey)="generateSurvey()"
  >
  </card-menu-popin>

  <!-- "Flow starts" when there is no question (no column) -->
  <checkpoint
    *ngIf="columnsWithCards.length === 0"
    [isStarting]="true"
    [openOnInit]="openOnInit"
    [messageURL]="messageURL"
    (messageURLChange)="editMessageURL()"
    [defaultLanguageWarning]="defaultLanguageWarning"
    (onClick)="onActionDotClick($event)"
    (defaultLanguageChange)="defaultLanguageChange.emit($event)"
  >
  </checkpoint>

  <!-- columns -->
  <ng-container
    *ngFor="
      let column of columnsWithCards;
      index as columnIndex;
      count as columnsCount
    "
  >
    <ng-container *ngIf="isFirstColumnMarginReady || columnIndex === 0">
      <column
        [columnIndex]="columnIndex"
        [marginTop]="firstColumnMargin"
        [hasMargin]="columnIndex > 0"
      >
        <!-- "Flow starts" when there is at least 1 question -->
        <checkpoint
          *ngIf="columnIndex === 0"
          [isStarting]="true"
          [messageURL]="messageURL"
          (messageURLChange)="editMessageURL()"
          [defaultLanguageWarning]="defaultLanguageWarning"
          (onClick)="onActionDotClick($event)"
          (defaultLanguageChange)="defaultLanguageChange.emit($event)"
        ></checkpoint>

        <!-- cards of current column -->
        <ng-container
          *ngFor="
            let card of column.cards;
            index as cardIndex;
            count as cardsCount
          "
        >
          <ng-container [ngSwitch]="card.component">
            <ng-container *ngSwitchCase="CardContainerType.BigCard">
              <big-card
                [type]="card.type"
                [letter]="card.letter"
                [labelType]="card.node.node.question.messages?.[0]?.type"
                [label]="card.label"
                [question]="card.node.node.question"
                [subLabel]="card.subLabel"
                [multiple]="card.multiple"
                [pathCount]="card.pathCountPercent"
                [skipPathCount]="card.skipPathCountPercent"
                [haveNext]="!!card.targetNodeId"
                [haveSkip]="!!card.skipTargetNodeId"
                [haveError]="!!errorsByNodeIds[card.node.node.id]"
                (onActionDotClick)="
                  onActionDotClick($event, card.node, card.action, card.actions)
                "
                (mouseenter)="onCardMouseEnter(card)"
                (mouseleave)="onCardMouseLeave(card)"
                (onClick)="editNode(card.node)"
                (onSizeUpdate)="
                  updateCardsBoundingClientRect(
                    columnIndex,
                    cardIndex,
                    $event,
                    card
                  )
                "
              >
              </big-card>
            </ng-container>
            <ng-container *ngSwitchCase="CardContainerType.SmallCard">
              <small-card
                [emoji]="card.emoji"
                [label]="card.label"
                [multiple]="card.multiple"
                [pathCount]="card.pathCountPercent"
                [skipPathCount]="card.skipPathCountPercent"
                [haveNext]="!!card.targetNodeId || card.multiple"
                [haveSkip]="!!card.skipTargetNodeId"
                [haveError]="!!errorsByNodeIds[card.node.node.id]"
                (onActionDotClick)="
                  onActionDotClick($event, card.node, card.action, card.actions)
                "
                (mouseenter)="onCardMouseEnter(card)"
                (mouseleave)="onCardMouseLeave(card)"
                (onClick)="editNode(card.node)"
                (onSizeUpdate)="
                  updateCardsBoundingClientRect(
                    columnIndex,
                    cardIndex,
                    $event,
                    card
                  )
                "
              >
              </small-card>
            </ng-container>
            <ng-container *ngSwitchCase="CardContainerType.InvisibleCard">
              <!-- invisible card contains link when lines jump a column -->
              <invisible-card
                [isEven]="card.columnIndex % 2 === 0"
                [isSkipLine]="!!card.skipTargetNodeId"
                [hovered]="isInvisibleCardHovered(card)"
                (onSizeUpdate)="
                  updateCardsBoundingClientRect(
                    columnIndex,
                    cardIndex,
                    $event,
                    card
                  )
                "
              >
              </invisible-card>
            </ng-container>
          </ng-container>
        </ng-container>

        <!-- "Flow ends" -->
        <!-- <checkpoint *ngIf="columnIndex === columnsCount -1" [isStarting]="false" (onClick)="onActionDotClick($event)">
        </checkpoint> -->
      </column>
    </ng-container>
  </ng-container>

  <!-- lines 😱 -->
  <div class="lines--wrapper">
    <ng-container *ngFor="let line of lines">
      <div
        [class]="isLineHovered(line) ? 'line line--hovered' : 'line'"
        [style]="line.style"
      ></div>
    </ng-container>
  </div>

  <!-- preview sidebar -->
  <survey-preview
    *ngIf="isPreviewOpened && builderStore.survey.type === 'survey'"
    class="disable-drag-and-drop"
    [channelId]="channelIdPreview"
    [surveyId]="builderStore.survey.id"
    [lang]="builderStore.currentLanguage"
    (onClose)="onPreviewClosed()"
  >
  </survey-preview>

  <!-- edit sidebar -->
  <lateral-panel
    *ngIf="isLateralPanelOpen"
    class="disable-drag-and-drop"
    [nodeId]="currentSelectedNodeId"
    (goToPreviousNode)="goToPreviousNode()"
    (goToNextNode)="goToNextNode()"
    (onClose)="closeLateralPanel()"
    (onSave)="onLateralPanelSave.emit($event)"
    (onDelete)="onNodeRemoved.emit($event)"
    (onError)="onError($event)"
    (openTagEditorArIndex)="onOpenTagEditorAtIndex($event)"
  >
  </lateral-panel>
</div>

<div class="message-preview-container">
  <div
    class="message-web-url-container"
    *ngIf="messageDistribution?.type === 'widget' && showPickStartUrl"
  >
    <h1>On which page do you want the first message to be displayed?</h1>

    <div class="is-flex">
      <input
        type="text"
        placeholder="Enter website url"
        [ngClass]="{
          invalid: false
        }"
        [(ngModel)]="messageURL"
        (keyup.enter)="onMessageURLChosen()"
      />
      <button
        nz-button
        nzType="primary"
        nzSize="large"
        [disabled]="!messageURL?.length"
        (click)="onMessageURLChosen()"
      >
        Choose
      </button>
    </div>
    <p class="url-tip">You will manage who see it in the next step.</p>

    <ng-container *ngIf="currentURLs?.length">
      <div class="recently-used-pages">
        <h4>Your recently used pages</h4>
        <div
          class="recently-used-page"
          *ngFor="let url of currentURLs"
          (click)="onMessageURLChosen(url)"
        >
          <div class="url" [title]="url">{{ url }}</div>
          <screeb-icon size="sm" icon="bottom-arrow"></screeb-icon>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    class="message-web-url-container"
    *ngIf="
      messageDistribution?.type === 'ios' ||
      messageDistribution?.type === 'android'
    "
  >
    <h1>Scan the QR code with a phone having the app installed 📱</h1>
    <img [src]="qrCode" alt="QR code" class="qr-code" />

    <p>
      Using a simulator? You can also copy the command below and paste it your
      terminal:
    </p>

    <div class="installation-code-container">
      <code class="installation-code" [highlight]="mobileQrCode"></code>
      <button
        class="copy-button"
        nz-button
        nzSize="medium"
        nzType="primary"
        (click)="clipboardCopy(mobileQrCode)"
      >
        Copy
      </button>
    </div>
  </div>
</div>

<!-- fab -->
<div class="actions fab disable-drag-and-drop" *ngIf="!!channelIdPreview">
  <button
    nz-button
    [nzLoading]="isSaving"
    nzType="primary"
    nzSize="large"
    [disabled]="
      !columnsWithCards.length && builderStore.survey.type !== 'message'
    "
    (click)="onPreviewRequested()"
  >
    <i nz-icon nzType="caret-right"></i>
    Preview
  </button>
</div>

<!-- @TODO: show this again -->
<a
  *ngIf="!builderStore.readOnly"
  target="_blank"
  class="how-to-button disable-drag-and-drop"
  rel="noopener"
  href="https://screeb.app/how-to-get-a-great-response-rate-for-your-in-app-surveys-the-complete-guide/"
  hidden
>
  💡&nbsp;<span>How to design your survey to</span>&nbsp;<strong
    >get more responses?</strong
  >&nbsp;<span>Read our guide here!</span>
</a>

<div
  class="progress-bar"
  *ngIf="!isLateralPanelOpen && uploadState.state === 'IN_PROGRESS'"
>
  <nz-progress class="upload-progress" [nzPercent]="uploadState.progress" />
  <screeb-icon
    class="cancel-btn"
    size="sm"
    icon="cross-circle"
    (click)="onVideoUploadCancel()"
  />
</div>
