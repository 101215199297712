import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotificationHelper } from "helpers/notification.helper";

import {
  Integration,
  integrationSpecs,
  IntegrationSpec,
  IntegrationType,
} from "models/integrations.model";
import { Org } from "models/org.model";
import { OAuth2Service } from "services/oauth2.service";

@Component({
  selector: "integration-settings-oauth",
  templateUrl: "./integration-settings-oauth.component.html",
  styleUrls: ["./integration-settings-oauth.component.scss"],
})
export class IntegrationSettingsOAuthComponent implements OnInit {
  @Input() org: Org = null;
  @Input() integration: Integration = null;
  @Input() confirmMessage: string = null;

  public integrationSlug: IntegrationType = null;
  public integrationSpec: IntegrationSpec = null;
  public authorizeURL = null;

  constructor(
    private route: ActivatedRoute,
    private oauth2Service: OAuth2Service,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit() {
    this.integrationSlug = this.integration?.type;
    this.integrationSpec = integrationSpecs[this.integrationSlug];
    this.getAuthorizeURL();
  }

  public getAuthorizeURL() {
    this.oauth2Service
      .getAuthorizeURL(this.org.id, this.integrationSlug)
      .then((url) => (this.authorizeURL = url));
  }

  public disconnect() {
    this.oauth2Service
      .revoke(this.org.id, this.integrationSlug)
      .then(() => {
        this.integration.auth_ok = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Could not revoke access to integration",
          null,
          "error",
        );
      });
  }
}
