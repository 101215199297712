import { Injectable } from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";
import { Survey } from "models/survey.model";
import {
  TagEditorCommunicationEvent,
  TagEditorCommunicationMessage,
} from "models/tag-editor-communication.types";
import { BehaviorSubject } from "rxjs";
import { BuilderStore } from "stores/builder.store";

@Injectable()
class TagEditorService {
  private token: string;
  private currentWindow: Window;

  public surveyUpdated = new BehaviorSubject<void>(null);

  public get isOpen() {
    return this.currentWindow && !this.currentWindow.closed;
  }

  constructor(
    private notificationHelper: NotificationHelper,
    private builderStore: BuilderStore,
  ) {
    window.addEventListener("message", this.onWindowMessage);
  }

  open(
    token: string,
    parameters: { url: string; addAtIndex?: number } = { url: "" },
  ) {
    if (!parameters.url) {
      this.notificationHelper.trigger("Unable to open preview", null, "error");
      return;
    }

    const title = new URL("screeb:editor/tab");

    Object.entries(parameters).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        title.searchParams.append(key, value.toString());
      }
    });

    this.token = token;

    if (this.currentWindow && !this.currentWindow.closed) {
      this.currentWindow.focus();
    } else {
      this.currentWindow = window.open(parameters.url, title.toString());
    }
  }

  close() {
    this.currentWindow?.close();
    this.token = undefined;
  }

  private sendMessage(
    target: MessageEventSource,
    event: TagEditorCommunicationEvent,
  ) {
    target.postMessage(
      {
        screeb: {
          to: "core",
          from: "admin",
          event,
        },
      },
      { targetOrigin: "*" },
    );
  }

  private handleWindowMessageAdminQueryToken(
    event: TagEditorCommunicationMessage<TagEditorCommunicationEvent>,
  ) {
    if (this.token) {
      this.sendMessage(event.source, {
        type: "admin.query.token.result",
        token: this.token,
      });
    }
  }

  // handlers

  private onWindowMessage = (
    event: TagEditorCommunicationMessage<TagEditorCommunicationEvent>,
  ) => {
    if (event.origin && event.data.screeb) {
      switch (event.data.screeb.event.type) {
        case "admin.query.token":
          this.handleWindowMessageAdminQueryToken(event);
          break;

        case "admin.query.survey.get":
          this.handleWindowMessageAdminQuerySurveyGet(event);
          break;

        case "admin.query.survey.update":
          this.handleWindowMessageAdminQuerySurveyUpdate(event);
          break;
      }
    }
  };

  private handleWindowMessageAdminQuerySurveyGet(
    event: TagEditorCommunicationMessage<TagEditorCommunicationEvent>,
  ) {
    this.sendMessage(event.source, {
      type: "admin.query.survey.get.result",
      survey: {
        ...this.builderStore.survey,
        settings: this.builderStore.getSurveySettings(),
      } as Survey,
      availableLanguages: this.builderStore.availableLanguages.map(
        ({ value }) => value,
      ),
    });
  }

  private handleWindowMessageAdminQuerySurveyUpdate(
    event: TagEditorCommunicationMessage<TagEditorCommunicationEvent>,
  ) {
    if (event.data.screeb.event.type === "admin.query.survey.update") {
      this.builderStore.setSurveyScenario(
        event.data.screeb.event.survey.scenario,
      );
      this.surveyUpdated.next();
    }
    this.sendMessage(event.source, {
      type: "admin.query.survey.update.result",
    });
  }
}

export { TagEditorService };
