<section class="stats">
  <trend-chart
    [title]="chartMTUTitle"
    [value]="superOrg.stats.current_month_respondents"
    [total]="
      orgBilling.expected_metadata.mtu_scheme === 'limited'
        ? orgBilling.expected_metadata.max_mtu
        : -1
    "
    [data]="data"
    [dataKey]="'released_at'"
    [format]="'day'"
    [isDataLineCumulating]="true"
    [subHeader]="chartSubHeaderMTU"
  >
  </trend-chart>
  <!--  <trend-chart
    title="Monthly Tracked Events"
    [total]="superOrg.stats.current_month_events_track"
    [data]="data"
    [dataKey]="'released_at'"
    [format]="'day'"
    [isDataLineCumulating]="true"
    [subHeader]="chartSubHeaderEvents"
  >
  </trend-chart>-->
  <trend-chart
    title="Monthly Recorded Sessions"
    [value]="0"
    [total]="
      orgBilling.expected_metadata.mtu_scheme === 'limited'
        ? orgBilling.expected_metadata.max_mtu
        : -1
    "
    [data]="data"
    [dataKey]="'released_at'"
    [format]="'month'"
    [isTotalAverage]="true"
    [subHeader]="chartSubHeaderSessionRecording"
    [soon]="true"
  ></trend-chart>
  <trend-chart
    title="Monthly Translated Responses"
    [value]="0"
    [total]="orgBilling.expected_metadata.response_translation ? 1 : 0"
    [data]="data"
    [dataKey]="'released_at'"
    [format]="'month'"
    [isTotalAverage]="true"
    [subHeader]="chartSubHeaderResponseTranslation"
    [soon]="true"
  ></trend-chart>
</section>

<billing-subscription-paused-subscription-alert
  [orgBilling]="orgBilling"
  (onTabChange)="goToTab.emit($event)"
>
</billing-subscription-paused-subscription-alert>

<section class="content" nz-row [nzGutter]="[24, 24]">
  <billing-plan
    nz-col
    nzSpan="8"
    nzXl="8"
    nzMd="12"
    nzXs="24"
    [superOrg]="superOrg"
    [orgBilling]="orgBilling"
    [orgBillingSubscription]="orgBillingSubscription"
    [orgBillingPaymentMethods]="orgBillingPaymentMethods"
    [orgBillingInvoices]="orgBillingInvoices"
    [planType]="currentPlanType"
    displayMode="normal"
    (onUpgrade)="upgradePlan($event)"
    (onDowngrade)="downgradePlan()"
    (onUpgradeAnnualBilling)="upgradeAnnualBilling()"
  />
  <billing-plan
    nz-col
    nzSpan="8"
    nzXl="8"
    nzMd="12"
    nzXs="24"
    [superOrg]="superOrg"
    [orgBilling]="orgBilling"
    [orgBillingSubscription]="orgBillingSubscription"
    [orgBillingPaymentMethods]="orgBillingPaymentMethods"
    [orgBillingInvoices]="orgBillingInvoices"
    [planType]="currentPlanType"
    displayMode="entitlement"
    (onUpgrade)="upgradePlan($event)"
    (onDowngrade)="downgradePlan()"
    (onUpgradeAnnualBilling)="upgradeAnnualBilling()"
    (goToInvoices)="goToTab.emit('invoices')"
  />
  <billing-plan
    *ngIf="orgBilling.billing_status !== 'trial'"
    nz-col
    nzSpan="8"
    nzXl="8"
    nzMd="24"
    nzXs="24"
    [superOrg]="superOrg"
    [orgBilling]="orgBilling"
    [orgBillingSubscription]="orgBillingSubscription"
    [orgBillingPaymentMethods]="orgBillingPaymentMethods"
    [planType]="recommendedPlanType"
    [displayMode]="!!recommendedPlanType ? 'normal' : 'service'"
    (onUpgrade)="upgradePlan($event)"
    (onDowngrade)="downgradePlan()"
    (onUpgradeAnnualBilling)="upgradeAnnualBilling()"
    (onComparePlan)="onComparePlan()"
  />
  <billing-plan
    *ngIf="orgBilling.billing_status === 'trial'"
    nz-col
    nzSpan="8"
    nzXl="8"
    nzMd="24"
    nzXs="24"
    [superOrg]="superOrg"
    [orgBilling]="orgBilling"
    [orgBillingSubscription]="orgBillingSubscription"
    [orgBillingPaymentMethods]="orgBillingPaymentMethods"
    [planType]="recommendedPlanType"
    displayMode="trial"
    (onUpgrade)="upgradePlan($event)"
    (onDowngrade)="downgradePlan()"
    (onUpgradeAnnualBilling)="upgradeAnnualBilling()"
    (onComparePlan)="onComparePlan()"
  />
</section>

<section class="addons">
  <h4>
    🎟️ Add Ons recommended for your usage
    <span class="free-plan-info" *ngIf="currentPlanType === 'free'"
      >(Upgrade to a Scale Plan Needed)</span
    >
  </h4>
  <div class="addons">
    <billing-addon-upgrade
      *ngIf="
        currentSubscriptionOptions.planType === 'free' ||
        !currentSubscriptionOptions.addons.whiteLabel
      "
      addonKey="white-label"
      [planMTU]="orgBilling.expected_metadata.max_mtu"
      (click)="activateAddon('white-label')"
    />
    <billing-addon-upgrade
      addonKey="additional-workspaces"
      [planMTU]="orgBilling.expected_metadata.max_mtu"
      (click)="activateAddon('additional-workspaces')"
    />
    <billing-addon-upgrade
      *ngIf="
        currentSubscriptionOptions.planType === 'free' ||
        !currentSubscriptionOptions.addons.responseTranslation
      "
      addonKey="response-translation"
      [planMTU]="orgBilling.expected_metadata.max_mtu"
      (click)="activateAddon('response-translation')"
    />
    <billing-addon-upgrade
      *ngIf="
        currentSubscriptionOptions.planType === 'free' ||
        !currentSubscriptionOptions.addons.audioVideoSurvey
      "
      addonKey="audio-video-survey"
      [planMTU]="orgBilling.expected_metadata.max_mtu"
      (click)="activateAddon('audio-video-survey')"
    />
    <billing-addon-upgrade
      addonKey="additional-mtu"
      (click)="activateAdditionalMTU()"
    />
  </div>
</section>

<compare-plan-modal
  [isOpen]="comparePlanOpened"
  (modalOpenChange)="onComparePlanModalToggle($event)"
/>

<ng-template #chartMTUTitle>
  <div class="chart-title">
    <span>Monthly Tracked Users&nbsp;</span>
    <span
      nz-popover
      [nzPopoverContent]="variablesHelpTemplate"
      nzPopoverPlacement="rightTop"
    >
      <i nz-icon nzType="info-circle" nzTheme="outline"></i>
    </span>
  </div>
</ng-template>

<ng-template #variablesHelpTemplate>
  <p style="max-width: 500px; word-break: break-word">
    Monthly Tracked Users are the number of unique users who triggered at least
    one event in your app in the last month. To estimate your number of MTUs,
    add your Monthly Active Users to the number of anonymous visitors you get
    every month.<br />For SaaS or mobile apps, MTUs are usually the number of
    users who logged in during the month. If most of your users are not logged
    in, then MTU is similar to Monthly Unique Sessions.
  </p>
</ng-template>

<ng-template #chartSubHeaderMTU>
  <div class="chart-sub-header">
    <span>Since 1st of the Month</span>
  </div>
</ng-template>

<!--<ng-template #chartSubHeaderEvents>
  <div class="chart-sub-header">
    <span>Since 1st of the Month</span>
    <span class="right">Fair use</span>
  </div>
</ng-template>-->

<ng-template #chartSubHeaderSessionRecording>
  <div class="chart-sub-header">
    <span>Since 1st of the Month</span>
  </div>
</ng-template>

<ng-template #chartSubHeaderResponseTranslation>
  <div class="chart-sub-header">
    <span>Since 1st of the Month</span>
  </div>
</ng-template>
