/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, OnInit, Input } from "@angular/core";
import { ChartDataset, ChartOptions } from "chart.js";

@Component({
  selector: "mini-trend-indicator",
  template: `
    <canvas
      [ngClass]="{ 'no-data': !hasData() && !loading, loading }"
      style="position: relative; width: auto"
      #trendChart="base-chart"
      baseChart
      type="line"
      [datasets]="datasets"
      [options]="options"
      [legend]="false"
      width="350"
      height="100"
    >
    </canvas>
    <div
      class="no-data-label"
      *ngIf="!hasData() && !loading"
      style="color: {{ color }}"
    >
      Not enough data
    </div>
    <div class="loading-indicator" *ngIf="loading">
      <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        position: relative;
      }

      :host .no-data,
      :host .loading {
        visibility: hidden;
      }

      :host .no-data-label {
        border: 1px dashed rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        color: var(--screeb-color-white);
      }

      :host .loading-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      ::ng-deep :host .ant-spin-dot-item {
        background-color: var(--screeb-color-white);
      }
    `,
  ],
})
export class MiniTrendIndicatorComponent implements OnInit {
  @Input() public min: number;
  @Input() public max: number;
  @Input() public datasets: ChartDataset[] = [];
  @Input() public loading = false;
  @Input() public color: string = null;

  public options: ChartOptions;

  ngOnInit(): void {
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: 2,
      },
      elements: {
        point: {
          radius: 0,
          borderColor: "transparent",
          borderWidth: 0,

          hoverRadius: 0,
          hoverBorderColor: "transparent",
          hoverBorderWidth: 0,
        },
        line: {
          borderColor: "white",
          borderWidth: 2,
          cubicInterpolationMode: "monotone",
          spanGaps: true,
          fill: this.color === "#5e21f1" ? "end" : "start",
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              return;
            }
            return getGradient(ctx, chartArea, this.color);
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawTicks: false,
          },
          border: {
            display: false,
          },
          type: "time",
          time: {
            unit: "day",
            isoWeekday: true,
            round: "day",
          },
          ticks: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          position: "left",
          grid: {
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    };
    this.options.scales.y.min = this.min;
    this.options.scales.y.max = this.max;
    this.options.elements.line.borderColor = this.color ?? "white";
  }

  hasData() {
    return (this.datasets[0]?.data?.length ?? 0) > 1;
  }
}

function getGradient(ctx, chartArea, color = null) {
  let width, height, gradient;

  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);

    if (color === "#5e21f1") {
      gradient.addColorStop(0, "rgba(226, 214, 255, 0)");
      gradient.addColorStop(1, "rgba(94, 33, 241, 0.2)");
    } else if (color === "#15a179") {
      gradient.addColorStop(0, "rgba(21, 161, 121, 0.6)");
      gradient.addColorStop(1, "rgba(171, 243, 224, 0)");
    } else {
      gradient.addColorStop(0, "rgba(255, 255, 255, 0.2)");
      gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
    }
  }

  return gradient;
}
