<builder-layout
  *ngIf="!isGenerating && hasData"
  class="builder-layout"
  [draggableZone]
  [columnsWithCards]="columnsWithCards"
  [channelIdPreview]=""
></builder-layout>

<div class="loading-container" *ngIf="isGenerating">
  <div class="loading">
    <img src="/assets/logo-purple-500.svg" alt="Screeb logo" />

    <h2>Generating your survey!</h2>
    <h5>{{ loadingMessage }}</h5>
  </div>
</div>

<div class="ctas">
  <button
    *ngIf="!isGenerating && hasData"
    nz-button
    class="big-button another-one"
    nzSize="large"
    (click)="regenerate()"
  >
    <span>Another one!&nbsp;</span>
    <screeb-icon size="sm" icon="lightning" />
  </button>

  <button
    *ngIf="!isGenerating && hasData"
    nz-button
    class="cta-next big-button"
    nzType="primary"
    nzSize="large"
    (click)="onNextClick()"
  >
    <span>Next&nbsp;&nbsp;&nbsp;</span>
    <screeb-icon size="sm" icon="arrow-right" />
  </button>
</div>
