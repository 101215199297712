import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";

import {
  AnalyticsResponse,
  AnalyticsResponseItemUser,
  getFormattedUserNameOrID,
} from "models/analytics.model";
import { Org } from "models/org.model";
import { AnalyticsQueryUsers } from "models/analytics.filters.type";
import { AnalyticsDao } from "models/analytics.dao";

import { UUID } from "models/survey.dao.types";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import { Debounce } from "utils/debounce";
import { getUserIcon } from "models/user.model";
import {
  RegistryEntry,
  RegistryEntrySourceFormatted,
  sortRegistryEntries,
} from "models/registry.model";
import { RegistryDao } from "models/registry.dao";

@Component({
  selector: "user-segment-select",
  templateUrl: "./user-segment-select.component.html",
  styleUrls: ["./user-segment-select.component.scss"],
})
export class UserSegmentSelectComponent implements OnInit, OnChanges {
  @Input() public org: Org;
  @Input() public userIds: string[] = [];
  @Input() public showList: boolean = false;
  @Input() public isPreview: boolean = false;
  @Output() public userIdsChange = new EventEmitter<string[]>();

  public isLoading: boolean = false;
  public isUsersLoading: boolean = false;
  public hasError: boolean = false;
  public currentKeyword: string = "";
  private isInitialRendering: boolean = true;

  public userGroupsOptions: RegistryEntry[] = [];
  public filteredUserGroupsOptions: RegistryEntry[] = [];
  public usersOptions: AnalyticsResponseItemUser[] = [];
  public filteredUsersOptions: AnalyticsResponseItemUser[] = [];

  public computedUserGroups: RegistryEntry[] = [];
  public computedUsers: AnalyticsResponseItemUser[] = [];

  public getFormattedUserNameOrID = getFormattedUserNameOrID;
  public getUserIcon = getUserIcon;
  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;

  constructor(
    private analyticsDao: AnalyticsDao,
    private registryDao: RegistryDao,
  ) {}

  ngOnInit(): void {
    this.getInitialData()
      .then(() => this.fetchBindedUsers())
      .finally(() => {
        this.isInitialRendering = false;
      });
  }

  ngOnChanges(): void {
    if (!this.isInitialRendering) {
      this.fetchBindedUsers();
    }
  }

  /**
   * This function fetches users that are not already loaded and adds them to
   * a list of computed users.
   */
  public async fetchBindedUsers(): Promise<void> {
    if (!this.userIds?.length) {
      this.computedUsers = [];
      this.computedUserGroups = [];
      return;
    }

    this.isUsersLoading = true;

    // Compute segments
    this.computedUserGroups = this.userGroupsOptions.filter((userGroup) =>
      this.userIds.includes(userGroup.id),
    );

    // Compute already loaded users
    this.computedUsers = this.usersOptions.filter((user) =>
      this.userIds.includes(user.id),
    );

    // If all users are already loaded, then we don't need to fetch them again
    if (
      this.computedUsers.length + this.computedUserGroups.length ===
      this.userIds.length
    ) {
      this.isUsersLoading = false;
      return;
    }

    // Only fetch the users that are not already loaded
    const ids = this.userIds.filter(
      (id) => !this.computedUsers.map((user) => user.id).includes(id),
    );

    // If there are no users to fetch, then we don't need to fetch them
    if (!ids.length) {
      this.isUsersLoading = false;
      return;
    }

    // If not, then we need to fetch them
    return this.analyticsDao
      .search({
        type: "respondent",
        org_id: UUID(this.org.id),
        survey_ids: ["*"],
        identified_only: false,
        filters_bool: "OR",
        filters: ids.map((id) => ({
          type: "respondent",
          key: "aliases",
          operator: "eq",
          value: id,
        })),
        range: {
          start: this.org.created_at,
          end: new Date(),
          field: "created_at",
        },
        sort: { field: "respondent.last_activity_at", order: "desc" },
        size: ids.length,
      } as AnalyticsQueryUsers)
      .then((res) => {
        // Add the fetched users to the list of computed users and remove duplicates
        this.computedUsers = this.computedUsers.concat(
          res.hits.respondents || [],
        );
      })
      .finally(() => {
        this.isUsersLoading = false;
      });
  }

  /**
   * Return the group name by its ID.
   * @param {string} id - the ID of the group.
   * @returns {string} - a string representing the name of the group.
   */
  public getGroupNameById(id: string): string {
    const group = this.userGroupsOptions.find((segment) => segment.id === id);
    if (!group) {
      return id;
    }

    return getFormattedUserGroupNameOrID(group);
  }

  /**
   * The function filters segments and users by the given keyword.
   * ID can also be used to filter segments.
   *
   * @param {string} keyword - The keyword to filter segments and users by.
   */
  @Debounce(250)
  public async onSearchUsers(keyword: string): Promise<void> {
    this.currentKeyword = keyword;
    if (!keyword?.trim().length) {
      this.filteredUsersOptions = this.usersOptions;
      this.filteredUserGroupsOptions = this.userGroupsOptions;
      return;
    }

    // Filter segments using his ID or name
    this.filteredUserGroupsOptions = this.userGroupsOptions.filter(
      (segment) => {
        return (
          segment.properties?.["name"]
            ?.toLowerCase()
            .includes(keyword.toLowerCase()) ||
          segment.title.toLowerCase().includes(keyword.toLowerCase()) ||
          segment.id.toLowerCase() === keyword.toLowerCase()
        );
      },
    );

    // Filter users
    this.isLoading = true;
    this.getUsers(1, keyword)
      .then((users) => {
        this.filteredUsersOptions = users;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * Remove the given user from the list of selected users.
   * @param {string} id - The ID of the user to remove.
   */
  public onRemove(id: string): void {
    this.userIdsChange.emit(this.userIds.filter((userId) => userId !== id));
  }

  /**
   * This function retrieves initial data for a page, including user groups
   * and users, and sets loading and error states.
   */
  public getInitialData(): Promise<void> {
    this.isLoading = true;

    return Promise.all([
      this.registryDao.getGroups(this.org.id),
      this.getUsers(1),
    ])
      .then(([registryEntriesGroup, users]) => {
        registryEntriesGroup.groups =
          registryEntriesGroup.groups.sort(sortRegistryEntries);

        this.userGroupsOptions = registryEntriesGroup.groups;
        this.filteredUserGroupsOptions = registryEntriesGroup.groups;
        this.usersOptions = this.usersOptions.concat(users);
        this.filteredUsersOptions = users;
        this.hasError = false;
      })
      .catch((err) => {
        console.error(err);
        this.hasError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * Get users from analytics API
   *
   * @param {number} [pageIndex=1] - The `pageIndex` parameter is an optional
   * @param {string} [keyword] - The `keyword` parameter is an optional
   */
  private async getUsers(pageIndex: number = 1, keyword?: string) {
    const query = {
      type: "respondent",
      org_id: UUID(this.org.id),
      survey_ids: [],
      identified_only: false,
      filters_bool: "AND",
      filters: [],
      keyword,
      range: {
        start: this.org.created_at,
        end: new Date(),
        field: "created_at",
      },
      sort: { field: "respondent.last_activity_at", order: "desc" },
      size: 25,
    } as AnalyticsQueryUsers;
    query.offset = (pageIndex - 1) * query.size;
    return this.analyticsDao.search(query).then((res: AnalyticsResponse) => {
      return res?.hits?.respondents || [];
    });
  }
}
