import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AuthService, SessionService } from "services/auth.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "steps-component",
  templateUrl: "./steps.component.html",
  styleUrls: ["./steps.component.scss"],
})
export class StepsComponent {
  @Input()
  public steps: string[] = [];

  @Input()
  public currentStep: number = 0;

  @Input()
  public validatePastSteps: boolean = false;

  @Output()
  public stepClicked: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    public authService: AuthService,
    public sessionService: SessionService,
    public uiService: UIService,
  ) {}

  public onStepClick(idx: number) {
    this.stepClicked.emit(idx);
  }
}
