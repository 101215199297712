import { Component, Input, OnInit } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";

@Component({
  selector: "rule-country",
  templateUrl: "./rule-country.component.html",
  styleUrls: ["./rule-country.component.scss"],
})
export class TargetingRuleCountryComponent implements OnInit {
  @Input() public rule: SurveyTargetingRule = null;
  @Input() public countries: string[][] = [];

  public mainCountries: string[][] = [];

  constructor() {}

  ngOnInit() {
    this.mainCountries = this.countries.filter((country: string[]) => {
      return ["US", "GB", "FR", "ES", "DE", "CN"].includes(country[0]);
    });
  }
}
