<section nz-row [nzGutter]="[24, 8]">
  <div nz-col nzXs="24" nzMd="12" id="settings-follow-mode">
    <div class="label">
      Follow mode
      <nz-switch
        [(ngModel)]="tagSettings.follow_mode"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
      ></nz-switch>
      <ng-template #checkedTemplate
        ><span nz-icon nzType="check"></span
      ></ng-template>
      <ng-template #unCheckedTemplate
        ><span nz-icon nzType="close"></span
      ></ng-template>
    </div>
    <p>
      To increase response rates, you can use our follow mode which continue to
      display the surveys once they've been displayed to your users until they
      respond or close them, even if they leave the context you were targeting.
    </p>
  </div>
  <div nz-col nzXs="24" nzMd="12" id="settings-skip-anonymous">
    <div class="label">
      Ignore anonymous users
      <nz-switch
        [(ngModel)]="tagSettings.skip_anonymous"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
      ></nz-switch>
      <ng-template #checkedTemplate
        ><span nz-icon nzType="check"></span
      ></ng-template>
      <ng-template #unCheckedTemplate
        ><span nz-icon nzType="close"></span
      ></ng-template>
    </div>
    <p>
      If you want to exclude anonymous users for this workspace, you can enable
      this setting, and we will not track anything from them, as well as not
      being counted in your MTU.
    </p>
  </div>
  <div nz-col nzXs="24" nzMd="12">
    <div class="label">Close response after</div>
    <nz-select
      class="position"
      [nzOptions]="responseExpirationOptions"
      nzSize="large"
      [(ngModel)]="tagSettings.response_expiration_second"
    >
    </nz-select>
  </div>
</section>
