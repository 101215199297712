<ng-container *ngFor="let tag of getTags()">
  <nz-tag [style.color]="textColor" [nzColor]="color">{{ tag }}</nz-tag>
</ng-container>
<nz-tag
  *ngIf="getRemainingTags().length"
  class="rest-tags"
  nz-tooltip
  [nzTooltipTitle]="getRemainingTags().join(', ')"
>
  +{{ getRemainingTags().length }}
</nz-tag>
