import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";

import { SessionService } from "./auth.service";
import { EventService } from "./event.service";

import { ENV } from "environment";
import { ScreebConfig } from "./config.service";

export function startLogging(config: ScreebConfig) {
  if (!config.sentryDsn) {
    return;
  }

  Sentry.init({
    dsn: config.sentryDsn,
    release: `admin@${ENV["VERSION"]}`,
    environment: ENV["ENV"],

    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        tracePropagationTargets: [config.selfEndpoint],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),

      new Sentry.Replay({
        networkDetailAllowUrls: [config.apiEndpoint],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 100%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

export function handleError(config: ScreebConfig, error: Error) {
  if (!config?.sentryDsn) {
    return;
  }

  Sentry.createErrorHandler({
    showDialog: false,
  }).handleError(error);
}

@Injectable()
export class LoggerService {
  constructor(
    private sessionService: SessionService,
    private eventService: EventService,
  ) {}

  public load() {
    this.refreshAccount();

    this.eventService.subscribeAll(() => {
      this.refreshAccount();
    });
  }

  private refreshAccount() {
    const session = this.sessionService.session;

    if (this.sessionService.isAuth() && !!session) {
      Sentry.setUser({
        email: session.email,
        id: session.id,
        firstname: session.firstname,
        lastname: session.lastname,
      });
    } else {
      Sentry.setUser(null);
    }
  }
}
