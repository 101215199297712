<ul class="actions" *ngIf="!!lastFilters">
  <li class="action" *ngIf="canExport">
    <filters-csv-export
      [org]="org"
      [survey]="survey"
      [type]="type"
    ></filters-csv-export>
  </li>
  <li class="action" *ngIf="canShare && ('integration.create' | hasPermission)">
    <share-reporting [reportType]="reportType" class="share"></share-reporting>
  </li>
  <li class="action" *ngIf="canFilterByDate">
    <survey-stats-filter-date
      [org]="org"
      [survey]="survey"
      [reporting]="reporting"
    ></survey-stats-filter-date>
  </li>
  <li class="action" *ngIf="lastFilters.type === 'respondent'">
    <nz-select
      class="my-segments"
      nzPlaceHolder="My segments"
      [nzOptions]="userGroupsOptions"
      nzSize="large"
      nzMode="mutiple"
      [nzDropdownMatchSelectWidth]="false"
      [ngModel]="selectedUserGroups"
      (ngModelChange)="selectedUserGroupsChange($event)"
    >
    </nz-select>
  </li>
  <li class="action anonymous" *ngIf="lastFilters.type === 'respondent'">
    <label
      nz-checkbox
      [ngModel]="lastFilters.identified_only"
      (ngModelChange)="hideAnonymousOnChange($event)"
      [ngClass]="{ checked: lastFilters.identified_only }"
      nzSize="large"
      >Hide anonymous users</label
    >
  </li>
  <li class="action">
    <nz-badge [nzCount]="lastFilters?.filters.length">
      <survey-stats-filter-criteria-response
        *ngIf="type === 'response'"
        [survey]="survey"
        [org]="org"
        [languages]="languages"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesGroup]="registryEntriesGroup"
        [registryEntriesEvent]="registryEntriesEvent"
      >
      </survey-stats-filter-criteria-response>
      <survey-stats-filter-criteria-user
        *ngIf="type === 'respondent'"
        [survey]="survey"
        [org]="org"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesGroup]="registryEntriesGroup"
        [registryEntriesEvent]="registryEntriesEvent"
      >
      </survey-stats-filter-criteria-user>
    </nz-badge>
  </li>
</ul>
