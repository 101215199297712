import { Component, Input, OnInit } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";
import { onOrRuleChange } from "components/surveys/pages/share/targeting/advanced/components/utils/rule-change";

const REGEXP_VALIDATION_URL =
  /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}(:\d*)?\b([-a-zA-Z0-9@:%_\+.~#?&//=!]*)$/g;

@Component({
  selector: "rule-url",
  templateUrl: "./rule-url.component.html",
  styleUrls: ["./rule-url.component.scss"],
})
export class TargetingRuleUrlComponent implements OnInit {
  @Input()
  public rule: SurveyTargetingRule = null;

  public onOrRuleChange = onOrRuleChange;

  constructor() {}

  ngOnInit() {
    const last = this.rule.value.v_s_arr.length - 1;
    if (this.rule.value.v_s_arr[last].length > 0) {
      this.rule.value.v_s_arr.push("");
    }
  }

  public trackByFn(index: any) {
    return index;
  }

  public checkValidUrlRule(r: SurveyTargetingRule, index: number): boolean {
    if (index > 0 && r.value.v_s_arr[index].trim().length === 0) return true;

    if (["equal", "not equal"].includes(r.operator)) {
      return !!r.value.v_s_arr[index]?.match(REGEXP_VALIDATION_URL);
    }

    return true;
  }
}
