<label class="lateral-panel-subtitle">Options</label>

<ng-container *ngFor="let button of cta.scores; let ctaIndex = index">
  <div class="panel-button-line">
    <button
      #btnElement
      title="Open emoji picker"
      class="emoji-button"
      [disabled]="node.isPreset"
      (click)="!node.isPreset && openEmojiPicker(button, btnElement)"
    >
      <sanitized-message-component
        [content]="button.payload.emoji"
        [options]="{
          styling: true,
          emojiTranscoder: true
        }"
      ></sanitized-message-component>
    </button>
    <lateral-panel-step-picker
      [routing]="node.node.routing"
      [chainableNodeIds]="node.chainableNodeIds"
      [actions]="[button]"
    ></lateral-panel-step-picker>

    <button
      title="Remove option"
      *ngIf="!node.isPreset && cta.scores.length > 1"
      class="remove-button"
      (click)="removeOption(ctaIndex)"
    >
      <i nz-icon nzType="close-circle"></i>
    </button>
  </div>
</ng-container>

<button
  *ngIf="!node.isPreset && cta.scores.length < 11"
  nz-button
  nzType="default"
  nzSize="large"
  (click)="addOption()"
>
  + Add an option
</button>

<ng-container>
  <label class="lateral-panel-subtitle skip-to-step-title">Skip to step</label>
  <p class="lateral-panel-tip">Allowing users to skip to a next step</p>

  <lateral-panel-step-picker
    [routing]="node.node.routing"
    [chainableNodeIds]="node.chainableNodeIds"
    [isSkipAction]="true"
    [actions]="[skipAction]"
  ></lateral-panel-step-picker>
</ng-container>

<ng-container>
  <label class="lateral-panel-subtitle">Responsive</label>
  <p class="lateral-panel-tip">Display options in reverse order on mobile</p>
  <nz-switch
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="cta.responsive"
    (ngModelChange)="setIsResponsive($event)"
  >
  </nz-switch>
</ng-container>
