<div
  [ngClass]="{
    'is-locked': !('multilingual_survey' | hasEntitlement)
  }"
>
  <h1>Language</h1>

  <section>
    <h3>Select the language you want for your surveys</h3>
    <language-select
      class="language-select"
      name="defaultLanguage"
      ngDefaultControl
      nzSize="large"
      nzMode="multiple"
      [ngModel]="survey_languages"
      (ngModelChange)="modelChange($event)"
      [languages]="languages"
    ></language-select>
    <div class="error" *ngIf="isInvalidSurveyLanguage()">
      You must choose at least one language and no more than 20.
    </div>
  </section>
  <language-translation-option
    *ngIf="'response_translation' | hasEntitlement"
    [translationEnabled]="org.translation_enabled"
    [translationLanguage]="org.translation_language"
    [availableTranslationLanguages]="availableTranslationLanguages"
    (translationLanguageChange)="translationLanguageChange($event)"
    (translationEnabledChange)="translationEnabledChange($event)"
    (translationValidChange)="translationValid = $event"
  ></language-translation-option>

  <section
    *ngIf="('response_translation' | hasEntitlement) && translationEnabled"
  >
    <span class="total-translations">Total Translation Languages</span>
    <h3 class="translation-description">
      You have
      {{
        org.computed_translations.length
          | pluralSingular: "translation language" : "translation languages"
      }}
      activated. This is the sum of the main translation language you selected
      and the other translation languages selected by individual Screeb users in
      your organization.<br />
      It means that each response you receive will be translated
      {{ org.computed_translations.length | pluralSingular: "time" : "times" }}.
    </h3>
    <div class="language-list">
      <div
        class="lang-option"
        *ngFor="let lang of computedTranslations"
        nzCustomContent
      >
        <sanitized-message-component
          class="lang-option-icon"
          [content]="lang.emoji"
          [options]="{ emojiTranscoder: true }"
        ></sanitized-message-component>
        <span class="lang-option-label">{{ lang.label }}</span>
      </div>
    </div>
  </section>

  <footer>
    <button
      (click)="save()"
      [nzLoading]="loading"
      nz-button
      nzType="primary"
      nzSize="large"
      [disabled]="isInvalid() || !needsSave"
    >
      Save
    </button>
  </footer>
</div>

<upgrade-modal
  [centeredModal]="true"
  [withOverlay]="true"
  [canCancel]="false"
  position="absolute"
  class="upgrade-modal"
  upgradeType="multilingual_survey"
  *ngIf="!('multilingual_survey' | hasEntitlement)"
  [orgId]="org.id"
></upgrade-modal>
