/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import {
  newRoutingEffectToEnd,
  routingSetCase,
} from "components/builder/routing";
import { decode } from "html-entities";
import {
  ActionButton,
  ActionSkip,
  CTAMultipleChoice,
  CTAMultipleChoiceAny,
  I18nTextLabel,
  SurveyLanguages,
  SwitchCase,
  UUID,
  getI18nTextLabelTranslation,
  newCTASkip,
  setI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";
import { uuidv4 } from "utils/uuid";
import {
  htmlSanitizer,
  stripHtml,
} from "../../Cards/sanitized-message/sanitizer";
import { LateralPanelEmojiPickerConfig } from "../LateralPanel.component";

const RANDOM_EMOJI = ["💪", "🥰", "😎", "🥳", "🤯", "😝", "👏", "🤘", "👌"];

@Component({
  selector: "lateral-panel-edit-button",
  templateUrl: "./edit-button.component.html",
  styleUrls: ["./edit-button.component.scss"],
})
export class LateralPanelEditButtonComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() public language: SurveyLanguages = "en";

  @Output() onEmojiPickerOpen =
    new EventEmitter<LateralPanelEmojiPickerConfig>();
  @Output() onOptionAdded = new EventEmitter();
  @Output() errorChange = new EventEmitter<boolean>();

  public textBtnErrors: string[][] = [];

  constructor(
    public builderStore: BuilderStore,
    public entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    if (
      !["multiple_choice", "pmf"].includes(
        this.node.node.question.call_to_action.type,
      )
    ) {
      throw Error("unexpected action type for multiple choices");
    }

    this.validateData();
  }

  public get cta(): CTAMultipleChoiceAny {
    const cta = this.node.node.question.call_to_action;
    if (!["multiple_choice", "pmf"].includes(cta.type)) {
      throw Error("unexpected action type for multiple choices");
    }
    return cta as CTAMultipleChoiceAny;
  }

  public get skipAction(): ActionSkip {
    return (this.node.node.question.skip_action ??= newCTASkip());
  }

  public setIsMultiple(multiple: boolean) {
    this.cta.multiple = multiple;
    this.validateData();

    // When multiple is selected, we want to copy the first case effect to all cases
    // Except for skip cases
    if (multiple && this.node.node.routing.cases?.length) {
      // @TODO: instead of first case, we should probably look for the nextNodeId having the most branching.
      // Picking the default effect as fallback is not a bad idea neither.
      const firstCaseEffect =
        this.node.node.routing.cases.find((c) => c.effect.type === "next_node")
          ?.effect ?? newRoutingEffectToEnd();

      // Replacing effects of current routing cases is not enough, since some routing cases might be missing.
      // For a safe update, we remove all routing cases of type "end" or "next_node", but not "skip_to_node".
      this.node.node.routing.cases = this.node.node.routing.cases.filter(
        (c) => {
          // explicitely removing cases that are not skip_to_node (more future-proof)
          return !(c.effect.type === "next_node" || c.effect.type === "end");
        },
      );
      this.node.node.routing.cases.push(
        // ...then we re-create all cases
        ...this.cta.choices.map((choice) => {
          return {
            action_id: choice.id,
            effect: firstCaseEffect,
          } as SwitchCase;
        }),
      );
    }
  }

  public setIsRandomize(randomize: boolean) {
    this.cta.randomize = randomize;
    this.cta.responsive = false;
    this.validateData();
  }

  public setIsResponsive(responsive: boolean) {
    this.cta.responsive = responsive;
    this.cta.randomize = false;
    this.validateData();
  }

  public getLabelText(index: number): string {
    const cta = this.node.node.question.call_to_action as CTAMultipleChoice;
    return decode(
      getI18nTextLabelTranslation(
        cta.choices[index].payload.label,
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelText(index: number, text: string) {
    const cta = this.node.node.question.call_to_action as CTAMultipleChoice;
    setI18nTextLabelTranslation(
      cta.choices[index].payload.label,
      text,
      this.language,
    );
  }

  /**
   * Options
   */
  public addOption() {
    if (this.node.isPreset) {
      throw Error("cannot add multiple choices in preset");
    }

    // default values
    const emoji = this.getRandomEmoji();
    const label = {} as I18nTextLabel;
    setI18nTextLabelTranslation(label, "Your answer", this.language);

    const newChoice = {
      id: uuidv4() as UUID,
      correlation_id: uuidv4() as UUID,
      type: "button",
      payload: {
        emoji,
        label,
      },
    } as ActionButton;
    this.cta.choices.push(newChoice);

    // When multiple is selected, we want to copy the first case effect to all cases
    if (this.cta.multiple && this.node.node.routing.cases?.length) {
      // @TODO: instead of first case, we should probably look for the nextNodeId having the most branching.
      // Picking the default effect as fallback is not a bad idea neither.
      const firstCaseEffect =
        this.node.node.routing.cases.find((c) => c.effect.type === "next_node")
          ?.effect ?? newRoutingEffectToEnd();

      if (firstCaseEffect.type === "next_node") {
        // warning: no case is created when firstCaseEffect lead to end of survey
        // it might cause error later if we apply a single routing rule to every cases
        // (eg: set multiple to true)
        routingSetCase(
          this.node.node.routing,
          newChoice.id,
          firstCaseEffect.type === "next_node"
            ? firstCaseEffect.next_node_id
            : null,
          firstCaseEffect.type,
        );
      }
    }

    this.onOptionAdded.emit();
    this.validateData();
  }

  public removeOption(index: number) {
    if (this.node.isPreset) {
      throw Error("cannot add multiple choices in preset");
    }

    if (this.cta.choices.length <= 1) {
      return;
    }
    this.cta.choices.splice(index, 1);

    this.validateData();
  }

  private getRandomEmoji(): string {
    return RANDOM_EMOJI[Math.floor(Math.random() * RANDOM_EMOJI.length)];
  }

  /**
   * Emoji picker
   */
  public openEmojiPicker(
    buttonWithEmoji: ActionButton,
    btnElement: HTMLElement,
  ) {
    const buttonRect = btnElement.getBoundingClientRect();
    this.onEmojiPickerOpen.emit({
      action: buttonWithEmoji,
      positionTop: buttonRect.top + window.pageYOffset - 20,
      positionRight:
        window.innerWidth - buttonRect.left + window.pageXOffset + 10,
      canBeNull: true,
    });
  }

  /**
   * Data validation
   */
  public validateData() {
    if (this.node.isPreset) {
      throw Error("cannot add multiple choices in preset");
    }

    let error = false;
    this.textBtnErrors = this.cta.choices.map(
      (btn: ActionButton, index: number) => {
        const text = this.getLabelText(index)?.trim() || "";

        if (
          this.getLength(text) < 1 &&
          this.language === this.builderStore.survey.scenario.default_language
        ) {
          error = true;
          return ["Text is missing."];
        }
        if (this.getLength(text) > 70) {
          error = true;
          return ["Max 70 characters permitted."];
        }
        return [];
      },
    );

    if (error) {
      this.errorChange.emit(true);
    } else {
      this.errorChange.emit(false);
    }
  }

  getLength(text: string) {
    return stripHtml(
      htmlSanitizer(text, {
        styling: true,
        CR: false,
        nativeCR: false,
        links: false,
      }),
    ).length;
  }
}
