<header>
  <h1>Product Market Fit</h1>
</header>

<survey-stats-empty-dashboard
  *ngIf="'survey.update' | hasPermission"
  imgPath="/assets/survey-templates/product-market-fit.png"
  title="Product Market Fit is coming to Screeb!"
  description1="Product Market Fit is a great and simple way to measure if your product satisfies a strong market demand. Use it to identify improvements to make to your experience!"
  description2="Our PMF report will be live in a few weeks. Stay tuned!"
  primaryButtonTxt="Add question to this survey"
  (primaryButtonCTA)="soonCTA(true)"
  secondaryButtonTxt="Start from a survey template"
  (secondaryButtonCTA)="soonCTA(false)"
>
</survey-stats-empty-dashboard>
