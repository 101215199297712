import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import {
  ChannelClientScreen,
  ChannelClientVersion,
} from "models/channel-screen.model";
import { Channel, ChannelType } from "./channel.model";

@Injectable()
export class ChannelDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getAllByOrgId(orgId: string): Promise<Channel[]> {
    return this.screebApiHelper
      .get<Channel[]>(`/org/${orgId}/channel`)
      .toPromise()
      .then((data: object[]) => {
        return data.map((c: object) => new Channel().fromJson(c));
      });
  }
  public getById(orgId: string, id: string): Promise<Channel> {
    return this.screebApiHelper
      .get<Channel[]>(`/org/${orgId}/channel/${id}`)
      .toPromise()
      .then((data: object) => {
        return new Channel().fromJson(data);
      });
  }

  public getChannelClientScreensByChannelId(
    orgId: string,
    id: string,
  ): Promise<ChannelClientScreen[]> {
    return this.screebApiHelper
      .get<ChannelClientScreen[]>(`/org/${orgId}/channel/${id}/client/screen`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data;
        }
        throw Error("Unexpected channel screens properties");
      });
  }

  public getChannelClientScreenVersionsByChannelId(
    orgId: string,
    id: string,
  ): Promise<ChannelClientVersion[]> {
    return this.screebApiHelper
      .get<ChannelClientVersion[]>(`/org/${orgId}/channel/${id}/client/version`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data;
        }
        throw Error("Unexpected channel screens properties");
      });
  }

  public updateChannelClientScreenHidden(
    orgId: string,
    channelId: string,
    ids: string[],
    hidden: boolean,
  ): Promise<any> {
    return this.screebApiHelper
      .put(
        `/org/${orgId}/channel/${channelId}/client/screen/${ids.join(",")}`,
        { hidden },
      )
      .toPromise();
  }

  public create(
    orgId: string,
    type: ChannelType,
    name: string,
  ): Promise<Channel> {
    const body = { type, name };
    return this.screebApiHelper
      .post<Channel[]>(`/org/${orgId}/channel`, body)
      .toPromise()
      .then((data: object) => {
        return new Channel().fromJson(data);
      });
  }
}
