import { Serializable } from "./serializable.model";
import { RegistryEntrySource } from "./registry.model";

export class UserRecord extends Serializable {
  constructor(
    public id?: string,

    public org_id?: string,
    public respondent_id?: string,
    public session_id?: string,
    public source?: RegistryEntrySource,
    public session_start?: Date,
    public session_end?: Date,

    public snapshot_start?: Date,
    public snapshot_end?: Date,
    public snapshot_duration_seconds?: number,
    public snapshot_size_bytes?: number,

    public created_at?: Date,
    public updated_at?: Date,

    // dynamic
    public snapshots?: UserRecordSnapshot[],
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    this.source = json["source"] as RegistryEntrySource;

    if (json["snapshots"]) {
      this.snapshots = json["snapshots"].map((s) =>
        new UserRecordSnapshot().fromJson(s),
      );
    }

    return this;
  }
}

export class UserRecordSnapshot extends Serializable {
  constructor(
    public id?: string,
    public record_id?: string,
    public type?: number,
    public data?: any,
    public size_bytes?: number,
    public timestamp?: Date,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}
