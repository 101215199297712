<div class="card-menu-popin-backdrop" (click)="onClickOutside.emit()"></div>

<!-- https://material.angular.io/cdk/overlay/examples -->
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="!!origin"
  [cdkConnectedOverlayOffsetY]="isNodeRoot ? 14 : 0"
>
  <div class="card-menu-popin-container">
    <div class="card-menu-popin">
      <div class="card-menu-popin__left">
        <div class="card-menu-popin-items">
          <label class="card-menu-popin-title">{{
            surveyType === "survey" ? "Add a question" : "Add a message"
          }}</label>
          <button
            *ngFor="let ctaType of ctaTypes"
            [disabled]="ctaType.disabled"
            class="card-menu-popin-item"
            [class]="{ focused: ctaType.type === focusedType?.type }"
            [style.background]="ctaType.backgroundColor"
            (mouseenter)="onFocusType(ctaType)"
            (click)="onNewNode.emit(ctaType.type)"
          >
            <div class="card-menu-popin-item__icon">
              <card-type-icons
                [questionType]="ctaType.type"
                [style.display]="'flex'"
              ></card-type-icons>
            </div>

            <div class="card-menu-popin-item-label">
              <div class="card-menu-popin-item-label__title">
                <span>{{ nodeTypesSettings[ctaType.type].title }}</span>
                <div *ngIf="ctaType.new" class="flag">New</div>
                <div *ngIf="ctaType.beta" class="flag">Beta</div>
              </div>
              <div class="disabledInfo" *ngIf="ctaType.disabled">
                Only at the end of a
                {{ surveyType === "survey" ? "survey" : "message" }}.
              </div>
            </div>
          </button>
        </div>
      </div>
      <div
        *ngIf="focusedType"
        class="card-menu-popin__right"
        [style.background]="focusedType.backgroundColor"
      >
        <p>{{ nodeTypesSettings[focusedType.type].description }}</p>
        <img
          alt="{{ nodeTypesSettings[focusedType.type].title }}"
          src="{{
            surveyType === 'survey'
              ? nodeTypesSettings[focusedType.type].asset[format]
              : nodeTypesSettings[focusedType.type].asset
          }}"
        />
      </div>
    </div>
    <button
      *ngIf="isNodeRoot"
      class="ai-button"
      title="Create with AI"
      nz-button
      nzType="default"
      nzSize="large"
      (click)="onGenerateSurvey.emit()"
    >
      <screeb-icon class="icon" size="sm" icon="lightning"></screeb-icon>
      Create with AI
    </button>
  </div>
</ng-template>
