<p
  class="italic"
  *ngIf="
    !!surveyCappingDisplay?.value?.v_n && !surveyCappingResponse?.value?.v_n
  "
>
  {{ levelToText() }} will receive up to
  {{ surveyCappingDisplay.value.v_n }} displays per
  {{
    surveyCappingTimePeriodTypesAndLabels[surveyCappingDisplay.value.v_n_p].per
  }}
  {{ surveyCappingType === "per-survey" ? "until paused." : "." }}
</p>
<p
  class="italic"
  *ngIf="
    !!surveyCappingResponse?.value?.v_n && !surveyCappingDisplay?.value?.v_n
  "
>
  {{ levelToText() }} will receive up to
  {{ surveyCappingResponse.value.v_n }} responses per
  {{
    surveyCappingTimePeriodTypesAndLabels[surveyCappingResponse.value.v_n_p].per
  }}
  {{ surveyCappingType === "per-survey" ? "until paused." : "." }}
</p>
<p
  class="italic"
  *ngIf="
    !!surveyCappingDisplay?.value?.v_n && !!surveyCappingResponse?.value?.v_n
  "
>
  {{ levelToText() }} will receive up to
  {{ surveyCappingDisplay.value.v_n }} displays and
  {{ surveyCappingResponse.value.v_n }} responses per
  {{
    surveyCappingTimePeriodTypesAndLabels[surveyCappingResponse.value.v_n_p].per
  }}
  {{ surveyCappingType === "per-survey" ? "until paused." : "." }}
</p>
