import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { NotificationHelper } from "helpers/notification.helper";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import { SurveyDistribution } from "models/survey-distribution.model";

@Injectable()
export class SurveyDistributionBySurveyAndChannelResolver
  implements Resolve<any>
{
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private surveyDistributionDao: SurveyDistributionDao,
    private notificationHelper: NotificationHelper,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<SurveyDistribution> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.surveyDistributionDao
          .getAllBySurveyIdAndChannelId(
            route.params["org_id"],
            route.params["survey_id"],
            route.params["channel_id"],
          )
          .then((distribs: SurveyDistribution[]) => {
            if (distribs?.length === 1) return distribs[0];
            throw new HttpErrorResponse({
              status: 500,
              statusText: "Internal error",
            });
          });
      })
      .catch((err: HttpErrorResponse) => {
        // this.notificationHelper.trigger("Failed to fetch channels", null, 'error');
        console.error(err.error);
        throw err;
      });
  }
}
