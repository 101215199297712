import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OrgAnswerTags } from "models/org.model";

@Component({
  selector: "page-settings-tagging-edit",
  templateUrl: "./tagging-edit.component.html",
  styleUrls: ["./tagging-edit.component.scss"],
})
export class SettingsTaggingEditComponent {
  @Input() public groupTag: OrgAnswerTags;

  @Input() public allOrgTags: string[] = [];

  @Output() public removeGroupTag = new EventEmitter();

  @Output() public changeTag = new EventEmitter<boolean>();

  onEventRemove() {
    this.removeGroupTag.emit(this.groupTag);
  }

  onEventChange() {
    this.changeTag.emit(false);
  }
}
