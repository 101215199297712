<header *ngIf="!loadings.firstLoading">
  <div class="header-top-line">
    <!-- <ai-helper class="ai-helper" mode="home" [orgId]="org.id"></ai-helper> -->
    <div class="hello-message">
      <org-account-avatar
        [account]="sessionService.session"
        size="small"
        [ctaWhenNoPicture]="true"
      ></org-account-avatar>
      <div class="hello-message-text">
        <span>
          {{ helloMessage }}
        </span>
        <span class="hello-message-name">
          {{ sessionService.session?.firstname }}
        </span>
        <span>!</span>
      </div>
    </div>

    <org-account-avatar-list
      [accounts]="orgAccounts"
      [maxDisplayed]="8"
      theme="dark"
      size="medium"
    ></org-account-avatar-list>
  </div>

  <div
    class="gridAppear"
    nz-row
    nzAlign="middle"
    [nzGutter]="[
      { xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 },
      { xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 }
    ]"
  >
    <div
      nz-col
      class="fullsize-indicator quote-of-the-day-container"
      nzXs="24"
      nzXl="8"
    >
      <h3>Product Quote of the Day</h3>
      <div class="iAppear quote-of-the-day">
        &#x201C;&nbsp;{{ quoteOfTheDay[0] }}&nbsp;&#x201D;
      </div>
      <div class="quote-of-the-day-author">{{ quoteOfTheDay[1] }}</div>
    </div>
    <div nz-col nzXs="24" nzXl="16">
      <div
        nz-row
        nzAlign="middle"
        [nzGutter]="[
          { xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 },
          { xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 }
        ]"
      >
        <div nz-col class="fullsize-indicator" nzXs="24" nzXl="12">
          <div class="nps">
            <div
              class="iAppear"
              [ngClass]="{ 'not-available': !npsAggregation?.total }"
            >
              <div class="nps-header">
                <h3>NPS</h3>
                <survey-stats-indicators-single-indicator
                  [value]="npsAggregation?.score ?? 0"
                  [diffPercent]="
                    npsAggregation?.total ? npsAggregationDiff : undefined
                  "
                  [loading]="loadings.getNps"
                  theme="dark"
                  color="var(--screeb-color-purple-700)"
                  size="small"
                >
                </survey-stats-indicators-single-indicator>
              </div>
              <!-- var color don't work because its in a canvas -->
              <mini-trend-indicator
                [datasets]="npsDatasets"
                [loading]="loadings.getNps"
                color="#5e21f1"
              ></mini-trend-indicator>
              <div
                *ngIf="
                  npsAggregation &&
                  npsAggregation.total &&
                  industryAverageNps !== null &&
                  industryAverageNps !== undefined
                "
                class="row-2 industry-average iAppear"
              >
                <span class="industry-average-diff">
                  {{ formattedDiffIndustryAverageNps }}
                </span>
                <span>compared to your industry</span>
              </div>
              <div
                class="not-available-empty-screen nps-empty-screen"
                *ngIf="!npsAggregation?.total"
              >
                <div>
                  Start Tracking your NPS Now and Measure your Users
                  Satisfaction in Real Time
                </div>
                <a
                  routerLink="/org/{{ org?.id }}/survey/create"
                  [queryParams]="{
                    duplicateById: '486d54b5-4675-4fcd-95ab-cacb42989cb6'
                  }"
                >
                  Set up NPS&nbsp;
                  <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div nz-col class="fullsize-indicator" nzXs="24" nzXl="12">
          <div class="monthly-active-users">
            <div
              class="iAppear"
              [ngClass]="{ 'not-available': !orgHasRespondents }"
            >
              <div class="monthly-active-users-header">
                <h3>Monthly Active Users & Activity Trend</h3>
                <survey-stats-indicators-single-indicator
                  [value]="monthlyActiveRespondents"
                  [diffPercent]="monthlyActiveRespondentsDiff"
                  [loading]="loadings.getMonthlyActiveRespondents"
                  theme="dark"
                  color="var(--screeb-color-green-700)"
                  size="small"
                >
                </survey-stats-indicators-single-indicator>
              </div>
              <mini-trend-indicator
                [datasets]="activityDatasets"
                [loading]="loadings.getMonthlyActiveRespondents"
                color="#15a179"
              ></mini-trend-indicator>
              <div
                class="not-available-empty-screen monthly-active-users-empty-screen"
                *ngIf="!orgHasRespondents"
              >
                <div>
                  Install Screeb to Analyse your Product Usage in Real Time
                </div>

                <a routerLink="/org/{{ org?.id }}/settings/install">
                  Install Screeb&nbsp;
                  <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<ng-container *ngIf="!loadings.firstLoading">
  <error-message
    *ngIf="error"
    message="An error occured when fetching data."
    [details]="error.message"
    (reload)="reload()"
  ></error-message>

  <div class="gridAppear" *ngIf="!error">
    <section nz-col class="overview-content">
      <div nz-col nzXs="24">
        <section nz-row [nzGutter]="[0, 32]">
          <div nz-col nzXs="24" nzXl="24">
            <div class="indicator-with-border">
              <h2 class="users-title">
                <div>Users</div>
                <a routerLink="/org/{{ org?.id }}/people/respondent">
                  View all users&nbsp;
                  <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                </a>
              </h2>
              <div
                class="user"
                [ngClass]="{ 'not-available': !orgHasRespondents }"
              >
                <div
                  class="iAppear"
                  [ngClass]="{ 'not-available': !orgHasRespondents }"
                >
                  <survey-stats-indicators-single-indicator
                    title="Current Active Users"
                    boldTitle="true"
                    size="medium"
                    [value]="currentActiveRespondents"
                    [loading]="loadings.getCurrentActiveRespondents"
                    [thousandsSuffixes]="true"
                    [maximumFractionDigits]="1"
                    link="/org/{{ org?.id }}/people/respondent"
                    titleLink="View"
                    boldLink="true"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
                <div
                  class="iAppear"
                  [ngClass]="{ 'not-available': !orgHasRespondents }"
                >
                  <survey-stats-indicators-single-indicator
                    title="New Users This Month"
                    boldTitle="true"
                    size="medium"
                    [value]="newRespondents"
                    [diffPercent]="newRespondentsDiff"
                    [loading]="loadings.getNewRespondents"
                    [thousandsSuffixes]="true"
                    [maximumFractionDigits]="1"
                    link="/org/{{ org?.id }}/survey/create"
                    titleLink="Ask a question"
                    boldLink="true"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
                <a
                  class="empty-screen-link-dark"
                  routerLink="/org/{{ org?.id }}/settings/install"
                  *ngIf="!orgHasRespondents"
                >
                  Install Screeb to see who's using your product&nbsp;
                  <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                </a>
                <div
                  class="iAppear"
                  [ngClass]="{ 'not-available': !orgHasRespondents }"
                >
                  <survey-stats-indicators-single-indicator
                    title="Daily Active Users"
                    boldTitle="true"
                    size="medium"
                    [value]="dailyActiveRespondents"
                    [diffPercent]="dailyActiveRespondentsDiff"
                    [loading]="loadings.getDailyActiveRespondents"
                    [thousandsSuffixes]="true"
                    [maximumFractionDigits]="1"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
                <div
                  class="iAppear"
                  [ngClass]="{ 'not-available': !orgHasRespondents }"
                >
                  <survey-stats-indicators-single-indicator
                    title="Weekly Active Users"
                    boldTitle="true"
                    size="medium"
                    [value]="weeklyActiveRespondents"
                    [diffPercent]="weeklyActiveRespondentsDiff"
                    [loading]="loadings.getWeeklyActiveRespondents"
                    [thousandsSuffixes]="true"
                    [maximumFractionDigits]="1"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
              </div>
            </div>
          </div>
          <div nz-col nzXs="24" nzXl="24">
            <div
              nz-row
              [nzGutter]="[
                32,
                { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64 }
              ]"
            >
              <div nz-col nzXs="24" nzXl="14">
                <div class="indicator-with-border">
                  <h2 class="most-active-user-title">
                    <div>Top 5 Most Active Users</div>
                    <button
                      nz-button
                      nzSize="large"
                      [nzType]="'primary'"
                      *ngIf="'survey.create' | hasPermission"
                      routerLink="/org/{{ org.id }}/survey/create"
                      [queryParams]="{
                        respondentIds: mostActiveRespondentsIds
                      }"
                    >
                      + Ask a question
                    </button>
                  </h2>

                  <div class="respondents-list">
                    <nz-spin
                      nzSimple
                      nzSize="medium"
                      *ngIf="
                        loadings.getSpecificRespondentsMostActive &&
                        initialFetch
                      "
                    ></nz-spin>
                    <user-simple-list
                      *ngIf="
                        !loadings.getSpecificRespondentsMostActive ||
                        !initialFetch
                      "
                      [users]="mostActiveRespondents"
                      userNameSize="large"
                      [shouldShowArrow]="true"
                      [registryEntriesGroup]="registryEntriesGroup"
                      [shouldShowPresence]="true"
                      iconSize="small"
                      style="
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                      "
                    >
                    </user-simple-list>
                  </div>
                </div>
              </div>
              <div nz-col nzXs="24" nzXl="10">
                <div class="indicator-with-border">
                  <survey-stats-indicators-radar-indicator
                    [labels]="[
                      '😡 Anger',
                      '&nbsp;😄 Joy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
                      '😱 Fear',
                      '😢 Sadness'
                    ]"
                    [legend]="emotionsChartLegend"
                    [datasets]="emotionsChartDatasets"
                    [isHomepage]="true"
                    [loading]="loadings.getEmotions"
                  />
                </div>
              </div>
            </div>
          </div>
          <div nz-col nzXs="24">
            <section nz-row nzJustify="space-between" [nzGutter]="[32, 16]">
              <div
                nz-col
                nzXs="24"
                nzXl="12"
                class="fullsize-indicator iAppear"
              >
                <div class="indicator-with-border">
                  <h2 class="last-surveys-title">
                    <div>Your Last In-App Surveys</div>
                    <button
                      *ngIf="'survey.create' | hasPermission"
                      routerLink="/org/{{ org.id }}/survey/create"
                      nz-button
                      nzSize="large"
                      [nzType]="'primary'"
                    >
                      + Create new survey
                    </button>
                  </h2>
                  <div>
                    <nz-empty
                      class="survey-empty-screen"
                      *ngIf="!surveys.length"
                      [nzNotFoundContent]="contentTpl"
                      nzNotFoundImage="/assets/empty-screen/empty-screen-surveys.png"
                    >
                      <ng-template #contentTpl>
                        <div>
                          Ask a first question to your Users now and get
                          qualitative insights about your product!
                        </div>
                        <a routerLink="/org/{{ org?.id }}/survey/create">
                          Create my First In-App Survey&nbsp;
                          <span
                            nz-icon
                            nzType="arrow-right"
                            nzTheme="outline"
                          ></span>
                        </a>
                      </ng-template>
                    </nz-empty>

                    <table
                      class="surveys-response-rate"
                      *ngIf="lastInAppSurveys.length"
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th style="min-width: 120px; width: 120px">
                            Response rate
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let survey of lastInAppSurveys">
                          <td>
                            <a
                              routerLink="/org/{{ org.id }}/survey/{{
                                survey.id
                              }}/stats"
                            >
                              {{ survey.title }}
                            </a>
                          </td>
                          <td>
                            <a
                              nzTooltipTitle="Compared to the average response rate in your industry"
                              nz-tooltip
                              class="surveys-response-rate-indicators"
                              routerLink="/org/{{ org.id }}/survey/{{
                                survey.id
                              }}/stats"
                            >
                              <span
                                >{{
                                  (survey.stats.response_rate * 100).toFixed()
                                }}%
                              </span>
                              <indicator-variation
                                [variation]="
                                  (
                                    survey.stats.response_rate * 100 -
                                    industryAverageResponseRate
                                  ).toFixed()
                                "
                                sign="force"
                                format="points"
                              ></indicator-variation>
                              <svg
                                width="14"
                                height="10"
                                viewBox="0 0 14 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M13.9241 4.61722C13.8753 4.49927 13.803 4.38877 13.7071 4.29289L9.70711 0.292893C9.31658 -0.0976311 8.68342 -0.097631 8.29289 0.292893C7.90237 0.683418 7.90237 1.31658 8.29289 1.70711L10.5858 4L1 4C0.447715 4 0 4.44772 0 5C0 5.55229 0.447715 6 1 6L10.5858 6L8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.70711 9.70711L13.7071 5.70711C13.9 5.51419 13.9976 5.26207 14 5.00923M13.9241 4.61722C13.9719 4.73244 13.9988 4.85854 14 4.99077L13.9241 4.61722Z"
                                  fill="#727387"
                                />
                              </svg>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <a
                      *ngIf="
                        surveys.length &&
                        surveys.length > lastInAppSurveys.length
                      "
                      class="see-all-surveys-link"
                      routerLink="/org/{{ org.id }}/survey/list"
                    >
                      See all your surveys&nbsp;
                      <span
                        nz-icon
                        nzType="arrow-right"
                        nzTheme="outline"
                      ></span>
                    </a>
                  </div>
                </div>
                <div class="recommended-readings">
                  <h2>Recommended Readings</h2>
                  <section>
                    <div nz-col nzXs="24">
                      <card-101-link
                        title="How to create an Event Tracking Plan for your SaaS"
                        minuteRead="4"
                        url="https://screeb.app/how-to-create-an-event-tracking-plan-for-your-saas/"
                        color="var(--screeb-color-green-200)"
                      ></card-101-link>
                    </div>
                    <div nz-col nzXs="24">
                      <card-101-link
                        title="How To Get A Great Response Rate For Your In-App Surveys - The Complete Guide"
                        minuteRead="6"
                        url="https://screeb.app/how-to-get-a-great-response-rate-for-your-in-app-surveys-the-complete-guide/"
                        color="var(--screeb-color-purple-200)"
                      ></card-101-link>
                    </div>
                  </section>
                </div>
              </div>
              <div
                class="fullsize-indicator iAppear"
                nz-col
                nzXs="24"
                nzXl="12"
              >
                <div class="indicator-with-border">
                  <h2>User repartition</h2>
                  <div
                    class="fullsize-indicator iAppear user-repartition"
                    [ngClass]="{ 'not-available': !orgHasResponses }"
                  >
                    <donut-indicator
                      title="Device used"
                      [data]="respondentDeviceData"
                      [loading]="loadings.getAnswersPlatformRepartition"
                    ></donut-indicator>
                    <div
                      class="not-available-empty-screen donut-empty-screen"
                      *ngIf="!orgHasRespondents"
                    >
                      <div>
                        Install Screeb and get actionnable insights about your
                        Product Users
                      </div>
                      <a routerLink="/org/{{ org?.id }}/settings/install">
                        Install Screeb&nbsp;
                        <span
                          nz-icon
                          nzType="arrow-right"
                          nzTheme="outline"
                        ></span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="fullsize-indicator iAppear"
                    [ngClass]="{ 'not-available': !orgHasRespondents }"
                  >
                    <donut-indicator
                      title="User identification"
                      [data]="respondentIdentificationData"
                      [loading]="
                        loadings.getRespondentIdentificationRepartition
                      "
                    ></donut-indicator>
                    <div
                      class="not-available-empty-screen donut-empty-screen"
                      *ngIf="!orgHasRespondents"
                      [style]="{ marginTop: '-12px' }"
                    >
                      <div>
                        Install Screeb and get actionnable insights about your
                        Product Users
                      </div>
                      <a routerLink="/org/{{ org?.id }}/settings/install">
                        Install Screeb&nbsp;
                        <span
                          nz-icon
                          nzType="arrow-right"
                          nzTheme="outline"
                        ></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </section>

    <section
      nz-row
      [nzGutter]="[{ xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64 }, 16]"
      class="overview-content"
    >
      <div hidden nz-col class="fullsize-indicator" nzXs="24" nzXl="16">
        <h2>Live User Map</h2>
        map
      </div>
    </section>
  </div>
</ng-container>
