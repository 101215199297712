import { htmlSanitizer } from "components/builder/components/Cards/sanitized-message/sanitizer";
import { uuidv4 } from "utils/uuid";
import {
  IntegrationSettingsWebhook,
  IntegrationSettingsZapier,
} from "./integrations.model";
import { SurveyStats } from "./survey.model";
import { TagSettingsFormat } from "./tag-settings.types";

declare const tag: unique symbol;

export type UUID = string & { readonly [tag]: "UUID" };

export const UUID = (uuid: string): UUID => uuid as UUID;

export type SurveyLanguages = string;
export type TranslationLanguage = string;

export type SupportedLanguages = SurveyLanguages | TranslationLanguage;

export type I18nFileDataThumbnails = {
  thumbnail_webp_url?: string;
  thumbnail_gif_url?: string;
  thumbnail_jpg_url?: string;
};

export type I18FileData = {
  video_id?: string;
  url?: string;
  overlay?: string;
  thumbnail_urls?: I18nFileDataThumbnails;
};

export type I18nTextLabel = {
  [key in SurveyLanguages]: string;
};

export type I18nVideoLabel = {
  [key in SurveyLanguages]: I18FileData;
};

export type I18nLabel = I18nTextLabel | I18nVideoLabel;

export function sanitize18nLabelTranslation(
  label: I18nTextLabel,
  allowLinks = true,
): I18nTextLabel {
  return Object.fromEntries(
    Object.entries(label).map(
      ([lang, label]) =>
        [
          lang,
          htmlSanitizer(label, {
            styling: true,
            CR: false,
            nativeCR: true,
            links: allowLinks,
          }),
        ] as const,
    ),
  );
}

export function sanitize18nFileTranslation(
  label: I18nVideoLabel,
  allowLinks = true,
): I18nVideoLabel {
  return Object.fromEntries(
    Object.entries(label).map(
      ([lang, label]) =>
        [
          lang,
          {
            ...label,
            overlay: htmlSanitizer(label.overlay, {
              styling: true,
              CR: false,
              nativeCR: true,
              links: allowLinks,
            }),
          },
        ] as const,
    ),
  );
}

export function getI18nVideoLabelTranslation(
  label: I18nVideoLabel,
  currentLanguage: SurveyLanguages,
  defaultLanguage: SurveyLanguages,
): I18FileData | null {
  return label[currentLanguage] || label[defaultLanguage];
}

export function setI18nVideoLabelTranslation(
  label: I18nVideoLabel,
  fileData: I18FileData,
  currentLanguage: string,
) {
  label[currentLanguage] = fileData;
}

export function getI18nTextLabelTranslation(
  label: I18nTextLabel,
  currentLanguage: SurveyLanguages,
  defaultLanguage: SurveyLanguages,
): string | null {
  return label[currentLanguage] || label[defaultLanguage];
}

export function setI18nTextLabelTranslation(
  label: I18nTextLabel,
  text: string,
  currentLanguage: string,
) {
  label[currentLanguage] = text.trim();
}

export function trimI18nTextLabels(label: I18nTextLabel): I18nTextLabel {
  for (const k in label) {
    label[k] = label[k].trim();
  }
  return label;
}

/* - - - - -*/
/* Messages */
/* - - - - -*/

type AbstractMessage = {
  id: UUID;
};

export type MessageText = AbstractMessage & {
  type: "text";
  text: I18nTextLabel;
};

export type MessageVideo = AbstractMessage & {
  type: "video";
  video: I18nVideoLabel;
};

export type ScenarioQuestionMessage = MessageText | MessageVideo;
export type ScenarioQuestionType = ScenarioQuestionMessage["type"];

/* - - - - */
/* Actions */
/* - - - - */

type AbstractAction = {
  id: UUID;
  correlation_id: UUID;
};

export type ActionSkip = AbstractAction & {
  type: "skip";
};

export type ActionButton = AbstractAction & {
  type: "button";
  payload: {
    label: I18nTextLabel;
    url?: I18nTextLabel;
    emoji?: string;
  };
};

export type ActionScoring = AbstractAction & {
  type: "scoring";
  payload: {
    emoji: string;
    value: number;
  };
};

export type ActionTextInput = AbstractAction & {
  type: "text_input";
  payload: {
    validation: "email" | "text";
  };
};

export type ActionCalendar = AbstractAction & {
  type: "calendar";
  payload: {
    url: I18nTextLabel;
  };
};

export type ActionNumberInput = AbstractAction & {
  type: "number_input";
  payload: {
    min: number;
    max: number;
    emoji: string;
    validation: "min_max";
  };
};

// not supported for now
// export type ActionNumberInput = AbstractAction & {
//     type: 'number_input';
//     payload: {
//         min: number;
//         max: number;
//         step: number;
//     };
// }

export type CTAAction =
  | ActionButton
  | ActionScoring
  | ActionTextInput
  | ActionNumberInput;

/* - - - - - - - - */
/* Call to actions */
/* - - - - - - - - */

export type CTAType =
  | "multiple_choice"
  | "pmf"
  | "scoring"
  | "nps"
  | "ces"
  | "csat"
  | "input"
  | "appstore_rating"
  | "range"
  | "link"
  | "calendar"
  | "none";

export const availableCTATypes: CTAType[] = [
  "none", // text
  "input",
  "multiple_choice",
  // 'pmf',
  "scoring",
  "nps",
  "ces",
  "csat",
  "range",
  "link",
  "calendar",
  "appstore_rating",
];

export type CTAInput = {
  type: "input";
  input: ActionTextInput; // | ActionNumberInput;
};

export type CTAMultipleChoice = {
  type: "multiple_choice";
  multiple: boolean;
  randomize: boolean;
  responsive: boolean;
  choices: ActionButton[];
};
export type CTAPMF = {
  type: "pmf";
  multiple: boolean;
  randomize: boolean;
  responsive: boolean;
  choices: ActionButton[];
};

export type CTAScoring = {
  type: "scoring";
  responsive: boolean;
  scores: ActionScoring[];
};
export type CTANPS = {
  type: "nps";
  responsive: boolean;
  scores: ActionScoring[];
};
export type CTACES = {
  type: "ces";
  responsive: boolean;
  scores: ActionScoring[];
};
export type CTACSat = {
  type: "csat";
  responsive: boolean;
  scores: ActionScoring[];
};

export type CTAAppStoreRating = {
  type: "appstore_rating";
};

export type CTALink = {
  type: "link";
  randomize: boolean;
  responsive: boolean;
  choices: ActionButton[];
};

export type CTACalendar = {
  type: "calendar";
  calendar: ActionCalendar;
};

export type CTARange = {
  type: "range";
  range: ActionNumberInput;
};

export type CTANone = {
  type: "none";
};

export type CTAScoringAny = CTAScoring | CTANPS | CTACES | CTACSat;
export type CTAMultipleChoiceAny = CTAMultipleChoice | CTAPMF;

export type CallToAction =
  | CTAScoringAny
  | CTAMultipleChoiceAny
  | CTAInput
  | CTAAppStoreRating
  | CTALink
  | CTACalendar
  | CTARange
  | CTANone;

/**
 * Common types
 */

export type ScenarioQuestionTypeMessagePlacement =
  | "top-left"
  | "top-center"
  | "top-right"
  | "center-left"
  | "center-right"
  | "bottom-left"
  | "bottom-center"
  | "bottom-right";

/**
 * Blocks
 */

export type ScenarioQuestionBlockAvatar = {
  type: "avatar";
};

// @TODO
// export type ScenarioQuestionBlockVisualImage = {
//   type: "visual-image";
//   image: I18nVideoLabel;
// };

export type ScenarioQuestionBlockVisualVideo = {
  type: "visual-video";
  video: I18nVideoLabel;
};

export type ScenarioQuestionBlockVisual =
  // @TODO
  // | ScenarioQuestionBlockVisualImage
  ScenarioQuestionBlockVisualVideo;

export type ScenarioQuestionBlockTitle = {
  type: "title";
  text: I18nTextLabel;
};

export type ScenarioQuestionBlockText = {
  type: "text";
  text: I18nTextLabel;
};

export type ScenarioQuestionBlockLink = {
  type: "link";
  text: I18nTextLabel;
  url: string;
  target: "in-page" | "new-tab";
};

export type ScenarioQuestionBlock =
  | ScenarioQuestionBlockAvatar
  | ScenarioQuestionBlockVisual
  | ScenarioQuestionBlockTitle
  | ScenarioQuestionBlockLink
  | ScenarioQuestionBlockText;

export type ScenarioQuestionBlockType = ScenarioQuestionBlock["type"];

/* - - - - - */
/* Questions */
/* - - - - - */

export type GenericScenarioQuestion = {
  description: string;
  call_to_action: CallToAction;
  skip_action: ActionSkip;
};

// surveys

export type ScenarioQuestionTypeSurvey = GenericScenarioQuestion & {
  type: "survey";
  messages: ScenarioQuestionMessage[];
};

// in-app messages

export const availableQuestionTypeMessage: ScenarioQuestionTypeMessage["type"][] =
  ["banner", "beacon", "popover", "modal", "sidebar"];

export const availableQuestionTypeMessagePlacement: ScenarioQuestionTypeMessagePlacement[] =
  [
    "top-left",
    "top-center",
    "top-right",
    "center-left",
    "center-right",
    "bottom-left",
    "bottom-center",
    "bottom-right",
  ];

export const questionTypeMessagePlacementLabels: {
  [key in ScenarioQuestionTypeMessagePlacement]: string;
} = {
  "top-left": "Top left",
  "top-center": "Top center",
  "top-right": "Top right",
  "center-left": "Center left",
  "center-right": "Center right",
  "bottom-left": "Bottom left",
  "bottom-center": "Bottom center",
  "bottom-right": "Bottom right",
};

export type GenericScenarioQuestionTypeMessage = GenericScenarioQuestion & {
  blocks: ScenarioQuestionBlock[];
  url: string;
};

export type GenericScenarioQuestionTypeMessageAttached =
  GenericScenarioQuestionTypeMessage & {
    selector: string;
    placement: ScenarioQuestionTypeMessagePlacement;
  };

export type ScenarioQuestionTypeMessageBannerFixed = {
  size: "full";
  position: "fixed";
  placement: "top" | "bottom";
};

export type ScenarioQuestionTypeMessageBannerFloating = {
  size: "small" | "medium" | "large";
  position: "floating";
  placement: ScenarioQuestionTypeMessagePlacement;
};

export type ScenarioQuestionTypeMessageBanner =
  GenericScenarioQuestionTypeMessage & {
    type: "banner";
  } & (
      | ScenarioQuestionTypeMessageBannerFixed
      | ScenarioQuestionTypeMessageBannerFloating
    );

export type ScenarioQuestionTypeMessageBeacon =
  GenericScenarioQuestionTypeMessageAttached & {
    type: "beacon";
  };

export type ScenarioQuestionTypeMessagePopover =
  GenericScenarioQuestionTypeMessageAttached & {
    type: "popover";
  };

export type ScenarioQuestionTypeMessageModal =
  GenericScenarioQuestionTypeMessage & {
    type: "modal";
  };

export type ScenarioQuestionTypeMessageSidebar =
  GenericScenarioQuestionTypeMessage & {
    type: "sidebar";
    placement: "left" | "right";
  };

export type ScenarioQuestionTypeMessage =
  | ScenarioQuestionTypeMessageBanner
  | ScenarioQuestionTypeMessageBeacon
  | ScenarioQuestionTypeMessagePopover
  | ScenarioQuestionTypeMessageModal
  | ScenarioQuestionTypeMessageSidebar;

export type ScenarioQuestion =
  | ScenarioQuestionTypeMessage
  | ScenarioQuestionTypeSurvey;

export function getActionsFromQuestion(
  question: ScenarioQuestion,
): CTAAction[] {
  const actions = [];

  if (
    ["multiple_choice", "pmf", "link"].includes(question.call_to_action.type)
  ) {
    actions.push(...(question.call_to_action as CTAMultipleChoice).choices);
  } else if (
    ["scoring", "nps", "ces", "csat"].includes(question.call_to_action.type)
  ) {
    actions.push(...(question.call_to_action as CTAScoring).scores);
  } else if (question.call_to_action.type === "input") {
    actions.push(question.call_to_action.input);
  } else if (question.call_to_action.type === "range") {
    actions.push(question.call_to_action.range);
  }

  return actions;
}

/* - - - - */
/* Routing */
/* - - - - */

export type RoutingEffectNextNode = {
  type: "next_node";
  next_node_id: UUID;
};

// skip_to_node is a copy of next_node but for skip action.
// This is pointless and should be removed because it adds complexity for no gain.
export type RoutingEffectSkipToNode = {
  type: "skip_to_node";
  skip_to_node_id: UUID;
};

export type RoutingEffectSurveyEnd = {
  type: "end";
};

export type RoutingEffect =
  | RoutingEffectNextNode
  | RoutingEffectSkipToNode
  | RoutingEffectSurveyEnd;

export type SwitchCase = {
  action_id: UUID;
  effect: RoutingEffect;
};

export type Routing = {
  type: "switch";
  cases: SwitchCase[];
  default_effect: RoutingEffect;
};

/* - - - - - - - - - - */
/* Nodes integrations  */
/* - - - - - - - - - - */

export type ScenarioNodeIntegrations = {
  webhook?: IntegrationSettingsWebhook;
  zapier?: IntegrationSettingsZapier;
};

/* - - - - - - - - */
/* Nodes, scenario */
/* - - - - - - - - */

export type ScenarioNode = {
  id: UUID;
  correlation_id: UUID;
  scenario_id?: UUID;
  question?: ScenarioQuestion;
  routing: Routing;
  integrations: ScenarioNodeIntegrations;
};

export type SurveyScenario = {
  id: string;
  author_id: UUID;
  version: number;
  default_language: SurveyLanguages;
  node_entrypoint_id: UUID;
  nodes: ScenarioNode[];
  created_at: Date;
};

export function getScenarioNodeByCorrelationId(
  scenario: SurveyScenario,
  correlationId,
): ScenarioNode | null {
  return scenario.nodes.find((node) => node.correlation_id === correlationId);
}

// To detect NPS template, we expect:
//    - 1 question of type NPS
//    - 3 questions of type input
//    - 3 questions of type none (text)
//    - must start with NPS question
export function surveyScenarioIsNPSTemplate(scenario: SurveyScenario): boolean {
  if (scenario.nodes.length !== 7) {
    return false;
  }

  const countNodesPerQuestionType = scenario.nodes.reduce(
    (agg: object, curr: ScenarioNode): object => {
      const questionType = curr?.question?.call_to_action?.type;
      if (!questionType) return agg;

      if (!agg[questionType]) agg[questionType] = 0;

      agg[questionType]++;
      return agg;
    },
    {},
  );

  // check each question type
  if (
    countNodesPerQuestionType["nps"] !== 1 ||
    countNodesPerQuestionType["input"] !== 3 ||
    countNodesPerQuestionType["none"] !== 3
  ) {
    return false;
  }

  // entrypoint is of type NPS
  const firstNode = scenario.nodes.find(
    (node: ScenarioNode) => node.id === scenario.node_entrypoint_id,
  );
  if (!firstNode || firstNode?.question?.call_to_action?.type !== "nps") {
    return false;
  }

  return true;
}

export type SurveyScenarioNodeTypeIcon =
  | "text"
  | "scoring"
  | "multiple_choices"
  | "input"
  | "nps"
  | "csat"
  | "ces"
  | "appstore_rating"
  | "link"
  | "calendar"
  | "range";

export type SurveyScenarioNodeTypeSettings = {
  type: CTAType;
  is_open_ended: boolean | null; // null when not applicable (for example when there is no CTA)
  title: string;
  description: string;
  colors: string[];
  icon: SurveyScenarioNodeTypeIcon;
  new: boolean;
  beta: boolean;
  asset: {
    [key in TagSettingsFormat]: string;
  };
};

export const surveyNodeTypesSettings = {
  ["none"]: {
    type: "none",
    is_open_ended: null,
    title: "Text",
    description:
      "Display custom text in your survey to provide context, instructions, or information to your users.",
    colors: ["rgba(255, 192, 70, 0.2)", "#FFC046"],
    icon: "text",
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/text.gif",
      cards: "/assets/illustrations/surveys/card/text.gif",
    },
  } as SurveyScenarioNodeTypeSettings,
  ["multiple_choice"]: {
    type: "multiple_choice",
    is_open_ended: false,
    title: "Multiple choices",
    description:
      "Offer users several options to pick from. Allow single or multiple selections.",
    colors: ["--screeb-color-green-alpha", "--screeb-color-green-700"],
    icon: "multiple_choices",
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/multiple-choice.gif",
      cards: "/assets/illustrations/surveys/card/multiple-choice.gif",
    },
  } as SurveyScenarioNodeTypeSettings,
  ["pmf"]: {
    type: "pmf",
    is_open_ended: false,
    title: "PMF",
    description:
      "Offer users several options to pick from. Allow single or multiple selections.",
    colors: ["--screeb-color-green-alpha", "--screeb-color-green-700"],
    icon: "multiple_choices",
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/multiple-choice.gif",
      cards: "/assets/illustrations/surveys/card/multiple-choice.gif",
    },
  } as SurveyScenarioNodeTypeSettings,
  ["input"]: {
    type: "input",
    is_open_ended: true,
    title: "Open question",
    description:
      "Let your users freely express their thoughts. Also made to collect emails. Analyze responses with AI.",
    colors: ["--screeb-color-blue-alpha", "--screeb-color-blue-700"],
    icon: "input",
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/open-question.gif",
      cards: "/assets/illustrations/surveys/card/open-question.gif",
    },
  } as SurveyScenarioNodeTypeSettings,
  ["range"]: {
    type: "range",
    is_open_ended: false,
    title: "Range rating",
    description:
      "Allow users to slide and select a value on a continuous scale to capture nuanced feedback.",
    colors: ["--screeb-color-red-200", "--screeb-color-red-500"],
    icon: "range",
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/range.gif",
      cards: "/assets/illustrations/surveys/card/range.gif",
    },
  } as SurveyScenarioNodeTypeSettings,
  ["scoring"]: {
    type: "scoring",
    is_open_ended: false,
    title: "Rating",
    description:
      "Gather scores from users to measure satisfaction or performance.",
    colors: ["--screeb-color-purple-alpha", "#5E21F1"],
    icon: "scoring",
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/rating.gif",
      cards: "/assets/illustrations/surveys/card/rating.gif",
    },
  } as SurveyScenarioNodeTypeSettings,
  ["nps"]: {
    type: "nps",
    is_open_ended: false,
    title: "NPS",
    description:
      "Rate users loyalty on a scale of 0-10. A dedicated report is available for this question.",
    colors: ["--screeb-color-red-200", "--screeb-color-red-500"],
    icon: "nps",
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/nps.gif",
      cards: "/assets/illustrations/surveys/card/nps.gif",
    },
  } as SurveyScenarioNodeTypeSettings,
  ["ces"]: {
    type: "ces",
    is_open_ended: false,
    title: "CES",
    description:
      "Evaluate effort level on a scale of 1-7. A dedicated report is available for this question.",
    colors: ["--screeb-color-yellow-200", "--screeb-color-yellow-500"],
    icon: "ces",
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/ces.gif",
      cards: "/assets/illustrations/surveys/card/ces.gif",
    },
  } as SurveyScenarioNodeTypeSettings,
  ["csat"]: {
    type: "csat",
    is_open_ended: false,
    title: "CSAT",
    description:
      "Use emojis to gauge user sentiment from very unhappy to very happy. Dedicated report available.",
    colors: ["--screeb-color-purple-200", "#5E21F1"],
    icon: "csat",
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/csat.gif",
      cards: "/assets/illustrations/surveys/card/csat.gif",
    },
  } as SurveyScenarioNodeTypeSettings,
  ["appstore_rating"]: {
    type: "appstore_rating",
    is_open_ended: false,
    title: "App Store Rating",
    description:
      "Encourage users to rate and review on Apple or Google app store to boost your app's visibility.",
    colors: ["--screeb-color-green-200", "#086554"],
    icon: "appstore_rating",
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/appstore-rating.gif",
      cards: "/assets/illustrations/surveys/card/appstore-rating.gif",
    },
  } as SurveyScenarioNodeTypeSettings,
  ["link"]: {
    type: "link",
    is_open_ended: false,
    title: "Link",
    description:
      "Insert links to guide users to internal or external pages seamlessly within your survey.",
    colors: ["rgba(0, 148, 255, 0.15)", "#0054B6"],
    icon: "link",
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/link.gif",
      cards: "/assets/illustrations/surveys/card/link.gif",
    },
  } as SurveyScenarioNodeTypeSettings,
  ["calendar"]: {
    type: "calendar",
    is_open_ended: false,
    title: "Calendar",
    description:
      "Let users book a meeting with you or your team. Integrates with Calendly, Hubspot, and Google Calendar.",
    colors: ["rgba(94, 33, 241, 0.15)", "#5e21f1"],
    icon: "calendar",
    new: true,
    asset: {
      conversationnal: "/assets/illustrations/surveys/conv/calendar.png",
      cards: "/assets/illustrations/surveys/card/calendar.png",
    },
  } as SurveyScenarioNodeTypeSettings,
};

export type MessageScenarioNodeTypeIcon =
  | "banner"
  | "beacon"
  | "popover"
  | "modal"
  | "sidebar";

export type MessageScenarioNodeTypeSettings = {
  type: ScenarioQuestionTypeMessage["type"];
  is_open_ended: boolean | null; // null when not applicable (for example when there is no CTA)
  title: string;
  description: string;
  colors: string[];
  icon: MessageScenarioNodeTypeIcon;
  new: boolean;
  beta: boolean;
  asset: string;
};

export const messageNodeTypesSettings = {
  ["banner"]: {
    type: "banner",
    is_open_ended: null,
    title: "Banner",
    description:
      "Banners are the best option to alert users or give general tips.",
    colors: ["#E4F8F2", "#1ED5A4"],
    icon: "banner",
    asset: "/assets/illustrations/surveys/message/banner.gif",
  } as MessageScenarioNodeTypeSettings,
  ["modal"]: {
    type: "modal",
    is_open_ended: null,
    title: "Modal",
    description:
      "Modals are really useful to display important info to your users.",
    colors: ["#EBF4FF", "#0094FF"],
    icon: "modal",
    asset: "/assets/illustrations/surveys/message/modal.gif",
  } as MessageScenarioNodeTypeSettings,
  ["beacon"]: {
    type: "beacon",
    is_open_ended: null,
    title: "Beacon",
    description:
      "Beacons are the perfect starting point for product tours and tips!",
    colors: ["#F5F1FF", "#5E21F1"],
    icon: "beacon",
    asset: "/assets/illustrations/surveys/message/beacon.gif",
  } as MessageScenarioNodeTypeSettings,
  ["popover"]: {
    type: "popover",
    is_open_ended: null,
    title: "Popover",
    description:
      "Popover allow you to give specific info on a specific element.",
    colors: ["#FFF6EA", "#FFB546"],
    icon: "popover",
    asset: "/assets/illustrations/surveys/message/popover.gif",
  } as MessageScenarioNodeTypeSettings,
  ["sidebar"]: {
    type: "sidebar",
    is_open_ended: null,
    title: "Sidebar",
    description:
      "Sidebars are perfect to display contextual info to your users.",
    colors: ["#FFEEF1", "#F11672"],
    icon: "sidebar",
    asset: "/assets/illustrations/surveys/message/sidebar.gif",
  } as MessageScenarioNodeTypeSettings,
};

export const presetsDefaultValue = {
  nps: [
    { value: 0, emoji: "0️⃣" },
    { value: 1, emoji: "1️⃣" },
    { value: 2, emoji: "2️⃣" },
    { value: 3, emoji: "3️⃣" },
    { value: 4, emoji: "4️⃣" },
    { value: 5, emoji: "5️⃣" },
    { value: 6, emoji: "6️⃣" },
    { value: 7, emoji: "7️⃣" },
    { value: 8, emoji: "8️⃣" },
    { value: 9, emoji: "9️⃣" },
    { value: 10, emoji: "🔟" },
  ],
  ces: [
    { value: 1, emoji: "1️⃣" },
    { value: 2, emoji: "2️⃣" },
    { value: 3, emoji: "3️⃣" },
    { value: 4, emoji: "4️⃣" },
    { value: 5, emoji: "5️⃣" },
    { value: 6, emoji: "6️⃣" },
    { value: 7, emoji: "7️⃣" },
  ],
  csat: [
    { value: 1, emoji: "😡" },
    { value: 2, emoji: "😒" },
    { value: 3, emoji: "😐" },
    { value: 4, emoji: "😄" },
    { value: 5, emoji: "😍" },
  ],
};

/* - - - -*/
/* Survey */
/* - - - -*/

export type APISurvey = {
  id: UUID;
  scenario: SurveyScenario;
  stats: SurveyStats;

  // legacy
  org_id?: UUID;
  created_at?: string;
  updated_at?: string;
  title?: string;
};

/* - - - - - - -*/
/* New question */

/* - - - - - - -*/

export function newTextLabel(
  txt: string,
  defaultLanguage: string,
): I18nTextLabel {
  const label = {};
  setI18nTextLabelTranslation(label, txt, defaultLanguage);
  return label;
}

export function newVideoLabel(defaultLanguage: string): I18nVideoLabel {
  const label = {};
  setI18nVideoLabelTranslation(label, {}, defaultLanguage);
  return label;
}

export function newQuestionCTA(
  ctaType: CTAType,
  defaultLanguage: string,
): CallToAction {
  switch (ctaType) {
    case "none":
      return newCTAText();
    case "input":
      return newCTAInput();
    case "multiple_choice":
      return newCTAMultipleChoices(defaultLanguage);
    case "scoring":
      return newCTAScoring();
    case "nps":
      return newCTANPS();
    case "ces":
      return newCTACES();
    case "csat":
      return newCTACSat();
    case "appstore_rating":
      return newAppStoreRating();
    case "link":
      return newLink(defaultLanguage);
    case "calendar":
      return newCalendar();
    case "range":
      return newRange();
    default:
      throw Error("unable to create a question of this type: " + ctaType);
  }
}

export function newQuestionMessages(
  ctaType: CTAType,
  defaultLanguage: string,
): ScenarioQuestionMessage[] {
  switch (ctaType) {
    case "none":
    case "input":
    case "multiple_choice":
    case "scoring":
    case "nps":
    case "ces":
    case "csat":
    case "range":
    case "link":
    case "calendar":
      return [
        {
          type: "text",
          id: uuidv4() as UUID,
          text: newTextLabel("Your question", defaultLanguage),
        },
      ];
    case "appstore_rating":
      return [];
    default:
      throw Error("unable to create a question of this type: " + ctaType);
  }
}

export function newQuestionBlocks(
  ctaType: CTAType,
  defaultLanguage: string,
): ScenarioQuestionBlock[] {
  switch (ctaType) {
    case "none":
    case "input":
    case "multiple_choice":
    case "scoring":
    case "nps":
    case "ces":
    case "csat":
    case "range":
    case "link":
    case "calendar":
      return [
        {
          type: "text",
          text: newTextLabel("Your message", defaultLanguage),
        } as ScenarioQuestionBlockText,
      ];
    case "appstore_rating":
      return [];
    default:
      throw Error("unable to create a question of this type: " + ctaType);
  }
}

export function newQuestionDescription(ctaType: CTAType): string {
  switch (ctaType) {
    case "none":
    case "input":
    case "multiple_choice":
    case "scoring":
    case "nps":
    case "ces":
    case "csat":
    case "range":
      return "Your question";
    case "appstore_rating":
      return "Open an App Store Rating dialog";
    case "link":
      return "Your link";
    case "calendar":
      return "Your calendar";
    default:
      throw Error("unable to create a question of this type: " + ctaType);
  }
}

export function newSurveyQuestion(
  ctaType: CTAType,
  defaultLanguage: string,
): ScenarioQuestion {
  const cta = newQuestionCTA(ctaType, defaultLanguage);
  const messages = newQuestionMessages(ctaType, defaultLanguage);
  const description = newQuestionDescription(ctaType);

  return {
    type: "survey",
    messages,
    call_to_action: cta,
    skip_action: ctaType !== "none" ? newCTASkip() : undefined,
    description,
  };
}

export function newMessageQuestion(
  type: ScenarioQuestionTypeMessage["type"],
  defaultLanguage: string,
  url: string,
): ScenarioQuestion {
  const ctaType = "none";
  const cta = newQuestionCTA(ctaType, defaultLanguage);
  const blocks = newQuestionBlocks(ctaType, defaultLanguage);

  const base = {
    type,
    blocks,
    call_to_action: cta,
    skip_action: ctaType !== "none" ? newCTASkip() : undefined,
    description: "Your message",
    url,
  };

  switch (type) {
    case "modal":
      return base as ScenarioQuestionTypeMessageModal;
    case "banner":
      return {
        ...base,
        size: "full",
        placement: "top",
        position: "fixed",
      } as ScenarioQuestionTypeMessageBanner;
    case "beacon":
      return {
        ...base,
        selector: undefined,
        placement: "center-right",
      } as ScenarioQuestionTypeMessageBeacon;
    case "popover":
      return {
        ...base,
        placement: "center-right",
        selector: undefined,
      } as ScenarioQuestionTypeMessagePopover;
    case "sidebar":
      return {
        ...base,
        placement: "right",
      } as ScenarioQuestionTypeMessageSidebar;
    default:
      throw Error("unable to create a question of this type: " + type);
  }
}

export function newCTAText(): CallToAction {
  return { type: "none" };
}

export function newCTASkip(): ActionSkip {
  return {
    id: uuidv4() as UUID,
    correlation_id: uuidv4() as UUID,
    type: "skip",
  };
}

export function newCTAInput(): CTAInput {
  return {
    type: "input",
    input: {
      id: uuidv4() as UUID,
      correlation_id: uuidv4() as UUID,
      type: "text_input",
      payload: { validation: "text" },
    },
  };
}

export function newCTAMultipleChoices(
  defaultLanguage: string,
): CTAMultipleChoice {
  return {
    type: "multiple_choice",
    multiple: false,
    randomize: false,
    responsive: false,
    choices: [
      {
        id: uuidv4() as UUID,
        correlation_id: uuidv4() as UUID,
        type: "button",
        payload: {
          emoji: "😝",
          label: newTextLabel("Your answer 1", defaultLanguage),
        },
      },
      {
        id: uuidv4() as UUID,
        correlation_id: uuidv4() as UUID,
        type: "button",
        payload: {
          emoji: "🥰",
          label: newTextLabel("Your answer 2", defaultLanguage),
        },
      },
    ],
  };
}

export function newCTAScoring(): CTAScoring {
  return {
    type: "scoring",
    responsive: true,
    scores: [
      {
        id: uuidv4() as UUID,
        correlation_id: uuidv4() as UUID,
        type: "scoring",
        payload: { emoji: "😝", value: 0 },
      },
      {
        id: uuidv4() as UUID,
        correlation_id: uuidv4() as UUID,
        type: "scoring",
        payload: { emoji: "🥰", value: 1 },
      },
    ],
  };
}

export function newCTANPS(): CTANPS {
  return {
    type: "nps",
    responsive: true,
    scores: presetsDefaultValue.nps.map((v): ActionScoring => {
      return {
        id: uuidv4() as UUID,
        correlation_id: uuidv4() as UUID,
        type: "scoring",
        payload: { emoji: v.emoji, value: v.value },
      };
    }),
  };
}

export function newCTACES(): CTACES {
  return {
    type: "ces",
    responsive: true,
    scores: presetsDefaultValue.ces.map((v): ActionScoring => {
      return {
        id: uuidv4() as UUID,
        correlation_id: uuidv4() as UUID,
        type: "scoring",
        payload: { emoji: v.emoji, value: v.value },
      };
    }),
  };
}

export function newCTACSat(): CTACSat {
  return {
    type: "csat",
    responsive: true,
    scores: presetsDefaultValue.csat.map((v): ActionScoring => {
      return {
        id: uuidv4() as UUID,
        correlation_id: uuidv4() as UUID,
        type: "scoring",
        payload: { emoji: v.emoji, value: v.value },
      };
    }),
  };
}

export function newAppStoreRating(): CTAAppStoreRating {
  return {
    type: "appstore_rating",
  };
}

export function newLink(defaultLanguage: string): CTALink {
  return {
    type: "link",
    randomize: false,
    responsive: false,
    choices: [
      {
        id: uuidv4() as UUID,
        correlation_id: uuidv4() as UUID,
        type: "button",
        payload: {
          emoji: "🔗",
          url: newTextLabel("", defaultLanguage),
          label: newTextLabel("Your label", defaultLanguage),
        },
      },
    ],
  };
}

export function newCalendar(): CTACalendar {
  return {
    type: "calendar",
    calendar: {
      id: uuidv4() as UUID,
      correlation_id: uuidv4() as UUID,
      type: "calendar",
      payload: {
        url: newTextLabel("", "en"),
      },
    },
  };
}

export function newRange(): CTARange {
  return {
    type: "range",
    range: {
      type: "number_input",
      id: UUID(uuidv4()),
      correlation_id: UUID(uuidv4()),
      payload: {
        min: 0,
        max: 100,
        emoji: "💖",
        validation: "min_max",
      },
    },
  };
}
