<header>
  <div class="header-top-line">
    <div class="hello-message">
      <org-account-avatar
        [account]="sessionService.session"
        size="small"
        [ctaWhenNoPicture]="true"
      ></org-account-avatar>
      <div class="hello-message-text">
        <span>
          {{ helloMessage }}
        </span>
        <span class="hello-message-name">
          {{ sessionService.session?.firstname }}
        </span>
        <span>!</span>
      </div>
    </div>
    <org-account-avatar-list
      [accounts]="superOrgAccounts"
      [maxDisplayed]="8"
      theme="dark"
      size="medium"
    ></org-account-avatar-list>
  </div>

  <div
    class="gridAppear"
    nz-row
    nzAlign="middle"
    [nzGutter]="[
      { xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 },
      { xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 }
    ]"
  >
    <div
      nz-col
      class="fullsize-indicator quote-of-the-day-container"
      nzXs="24"
      nzXl="8"
    >
      <h3>Product Quote of the Day</h3>
      <div class="iAppear quote-of-the-day">
        &#x201C;&nbsp;{{ quoteOfTheDay[0] }}&nbsp;&#x201D;
      </div>
      <div class="quote-of-the-day-author">{{ quoteOfTheDay[1] }}</div>
    </div>
    <div nz-col nzXs="24" nzXl="16">
      <div
        nz-row
        nzAlign="middle"
        [nzGutter]="[
          { xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 },
          { xs: 16, sm: 16, md: 24, lg: 24, xl: 24, xxl: 24 }
        ]"
      >
        <div nz-col class="fullsize-indicator" nzXs="24" nzXl="12">
          <div class="nps">
            <div
              class="iAppear"
              [ngClass]="{ 'not-available': !npsAggregation?.total }"
            >
              <div class="nps-header">
                <h3>Global NPS</h3>
                <survey-stats-indicators-single-indicator
                  [value]="npsAggregation?.score ?? 0"
                  [diffPercent]="
                    npsAggregation?.total ? npsAggregationDiff : undefined
                  "
                  [loading]="loadings.getNps"
                  theme="dark"
                  color="var(--screeb-color-purple-700)"
                  size="small"
                >
                </survey-stats-indicators-single-indicator>
              </div>
              <mini-trend-indicator
                [datasets]="npsDatasets"
                [loading]="loadings.getNps"
                color="#5e21f1"
              ></mini-trend-indicator>
              <div
                *ngIf="
                  npsAggregation &&
                  npsAggregation.total &&
                  industryAverageNps !== null &&
                  industryAverageNps !== undefined
                "
                class="row-2 industry-average iAppear"
              >
                <span class="industry-average-diff">
                  {{ formattedDiffIndustryAverageNps }}
                </span>
                <span>compared to your industry</span>
              </div>
              <div
                class="not-available-empty-screen nps-empty-screen"
                *ngIf="!npsAggregation?.total"
              >
                <div>
                  Start Tracking your NPS Now and Measure your Users
                  Satisfaction in Real Time
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nz-col class="fullsize-indicator" nzXs="24" nzXl="12">
          <div class="monthly-active-users">
            <div
              class="iAppear"
              [ngClass]="{ 'not-available': !superOrgHasRespondents }"
            >
              <div class="monthly-active-users-header">
                <h3>Global Monthly Active Users & Activity Trend</h3>
                <survey-stats-indicators-single-indicator
                  [value]="monthlyActiveRespondents"
                  [diffPercent]="monthlyActiveRespondentsDiff"
                  [loading]="loadings.getMonthlyActiveRespondents"
                  theme="dark"
                  color="var(--screeb-color-green-700)"
                  size="small"
                >
                </survey-stats-indicators-single-indicator>
              </div>
              <mini-trend-indicator
                [datasets]="activityDatasets"
                [loading]="
                  loadings.getMonthlyActiveRespondents ||
                  loadings.getActivityTrendEvents
                "
                color="#15a179"
              ></mini-trend-indicator>
              <div
                class="not-available-empty-screen monthly-active-users-empty-screen"
                *ngIf="!superOrgHasRespondents"
              >
                <div>
                  Install Screeb to Analyse your Product Usage in Real Time
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<ng-container>
  <error-message
    *ngIf="error"
    message="An error occured when fetching data."
    [details]="error.message"
    (reload)="reload()"
  ></error-message>

  <div class="gridAppear" *ngIf="!error">
    <section nz-col class="overview-content">
      <div nz-col nzXs="24">
        <section nz-row [nzGutter]="[0, 32]">
          <div nz-col nzXs="24" nzXl="24">
            <div class="indicator-with-border">
              <div
                class="user"
                [ngClass]="{ 'not-available': !superOrgHasRespondents }"
              >
                <div
                  class="iAppear"
                  [ngClass]="{ 'not-available': !superOrgHasRespondents }"
                >
                  <survey-stats-indicators-single-indicator
                    title="Current Active Users"
                    boldTitle="true"
                    size="medium"
                    [value]="currentActiveRespondents"
                    [loading]="loadings.getCurrentActiveRespondents"
                    [thousandsSuffixes]="true"
                    [maximumFractionDigits]="1"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
                <div
                  class="iAppear"
                  [ngClass]="{ 'not-available': !superOrgHasRespondents }"
                >
                  <survey-stats-indicators-single-indicator
                    title="New Users This Month"
                    boldTitle="true"
                    size="medium"
                    [value]="newRespondents"
                    [diffPercent]="newRespondentsDiff"
                    [loading]="loadings.getNewRespondents"
                    [thousandsSuffixes]="true"
                    [maximumFractionDigits]="1"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
                <div
                  class="iAppear"
                  [ngClass]="{ 'not-available': !superOrgHasRespondents }"
                >
                  <survey-stats-indicators-single-indicator
                    title="Daily Active Users"
                    boldTitle="true"
                    size="medium"
                    [value]="dailyActiveRespondents"
                    [diffPercent]="dailyActiveRespondentsDiff"
                    [loading]="loadings.getDailyActiveRespondents"
                    [thousandsSuffixes]="true"
                    [maximumFractionDigits]="1"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
                <div
                  class="iAppear"
                  [ngClass]="{ 'not-available': !superOrgHasRespondents }"
                >
                  <survey-stats-indicators-single-indicator
                    title="Weekly Active Users"
                    boldTitle="true"
                    size="medium"
                    [value]="weeklyActiveRespondents"
                    [diffPercent]="weeklyActiveRespondentsDiff"
                    [loading]="loadings.getWeeklyActiveRespondents"
                    [thousandsSuffixes]="true"
                    [maximumFractionDigits]="1"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section class="content iAppear d100">
      <h2>Your Workspaces</h2>
      <div class="filters-bar">
        <div class="left">
          <div class="tags-filter">
            <nz-select
              nzShowSearch
              nzAllowClear
              nzSuffixIcon="caret-down"
              nzPlaceHolder="All Tags"
              nzSize="large"
              nzMode="multiple"
              [ngModel]="filteredTags"
              [nzShowArrow]="true"
              (ngModelChange)="onTagsFilterChange($event)"
            >
              <ng-container *ngFor="let tag of workspacesTags">
                <nz-option [nzLabel]="tag" [nzValue]="tag"></nz-option>
              </ng-container>
            </nz-select>
          </div>
          <div class="search-bar">
            <form nz-form (ngSubmit)="onSearchChangeImmediate(keyword)">
              <nz-input-group
                nzSize="large"
                [nzPrefix]="prefixIcon"
                [nzSuffix]="inputClearButton"
              >
                <input
                  type="text"
                  nz-input
                  placeholder="Search a workspace"
                  [ngModel]="keyword"
                  (ngModelChange)="onSearchChange($event)"
                  [ngModelOptions]="{ standalone: true }"
                />
              </nz-input-group>
              <ng-template #prefixIcon
                ><i nz-icon nzType="search"></i
              ></ng-template>
              <ng-template #inputClearButton>
                <i
                  nz-icon
                  class="ant-input-clear-icon"
                  nzTheme="fill"
                  nzType="close-circle"
                  *ngIf="keyword"
                  (click)="onSearchChangeImmediate('')"
                ></i>
              </ng-template>
            </form>
          </div>
        </div>
        <div class="right">
          <button
            routerLink="/overview/{{ superOrg?.id }}/workspaces"
            nz-button
            nzGhost
            *ngIf="
              ('org.update' | hasSuperPermission) &&
              ('super-org.update' | hasSuperPermission)
            "
            nzType="primary"
            nzSize="large"
          >
            <i nz-icon nzType="user" nzTheme="outline"></i>
            MTU Allocation
          </button>
          <button
            *ngIf="'org.create' | hasSuperPermission"
            title="Create a new Workspace"
            nz-button
            nzType="primary"
            nzSize="large"
            [nzTooltipTitle]="
              !canCreateMoreWorkspaces
                ? 'You have reached the maximum number of workspaces for your plan.'
                : null
            "
            nz-tooltip
            [disabled]="!canCreateMoreWorkspaces"
            (click)="onAdd()"
          >
            <i nz-icon nzType="plus"></i>
            <span>Create a new Workspace</span>
          </button>
        </div>
      </div>

      <div class="workspace-table-group">
        <nz-table
          class="workspace-table"
          #table
          [nzData]="filteredWorkspaces"
          nzTableLayout="fixed"
          [nzPageSize]="100"
          [nzShowSizeChanger]="true"
          [nzFrontPagination]="true"
          [nzHideOnSinglePage]="true"
          [nzPageSizeOptions]="[10, 25, 100]"
          [nzScroll]="{ x: '1100px' }"
        >
          <thead>
            <tr>
              <th
                *ngFor="let column of displayedColumns"
                [(nzSortOrder)]="column.sortOrder"
                [nzSortFn]="column.sortFn"
                [nzSortDirections]="column.sortDirections"
                [nzFilterMultiple]="column.filterMultiple"
                [nzColumnKey]="column.sortKey"
                [nzWidth]="column.width"
                [nzAlign]="column.align"
              >
                {{ column.name }}
              </th>
              <th [nzWidth]="'140px'" nzRight>
                <div
                  class="columns-settings"
                  nz-popover
                  [(nzPopoverVisible)]="columnPickerVisible"
                  (nzPopoverVisibleChange)="onColumnPicker($event)"
                  nzPopoverTrigger="click"
                  [nzPopoverContent]="columnPickerContentTemplate"
                >
                  <screeb-icon size="md" icon="settings"></screeb-icon>
                </div>
                <ng-template #columnPickerContentTemplate>
                  <div *ngFor="let column of allColumns">
                    <label
                      nz-checkbox
                      [ngModel]="displayedColumnsKeys.includes(column.key)"
                      (ngModelChange)="onColumnToggle(column.key, $event)"
                    >
                      {{ column.name }}
                    </label>
                  </div>
                </ng-template>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let workspace of table.data">
              <!--
                HACK: using (click), we cannot right-click on a row and select "open in a new table".
                So we added a "<a>" html tag between tbody and tr.
              -->

              <tr>
                <td
                  *ngFor="let column of displayedColumns"
                  [nzAlign]="column.align ?? 'left'"
                >
                  <ng-container *ngIf="column.key === 'name'">
                    <a
                      class="hack"
                      class="bold-text"
                      nzEllipsis="true"
                      routerLink="/org/{{ workspace.id }}/home"
                    >
                      {{ workspace.name }}
                      <div
                        *ngIf="workspace.tags?.length"
                        class="workspace-tags"
                      >
                        <nz-tag *ngFor="let tag of workspace.tags.slice(0, 3)">
                          {{ tag }}
                        </nz-tag>
                        <nz-tag *ngIf="workspace.tags.length > 3">
                          +{{ workspace.tags.length - 3 }}
                        </nz-tag>
                      </div>
                    </a>
                  </ng-container>
                  <ng-container *ngIf="column.key === 'mtu'">
                    <nz-progress
                      [nzPercent]="
                        superOrg.entitlements.mtu_mode !== 'auto'
                          ? (workspace.stats.current_month_respondents /
                              workspace.entitlements.max_mtu) *
                            100
                          : (workspace.stats.current_month_respondents /
                              superOrg.entitlements.max_mtu) *
                            100
                      "
                      [nzStatus]="
                        (workspace.stats.current_month_respondents /
                          workspace.entitlements.max_mtu) *
                          100 >=
                        100
                          ? 'exception'
                          : null
                      "
                      [nzShowInfo]="false"
                    ></nz-progress>
                    <p class="slider-mtu">
                      {{ workspace.sliderMin }}/{{ workspace.sliderMax }}
                    </p>
                  </ng-container>
                  <ng-container *ngIf="column.key === 'active_surveys'">
                    <span
                      *ngIf="
                        currentActiveSurveyByWorkspace[workspace.id] ||
                        currentActiveSurveyByWorkspace[workspace.id] === 0
                      "
                    >
                      {{ currentActiveSurveyByWorkspace[workspace.id] }}
                    </span>
                    <nz-spin
                      nzSimple
                      nzSize="small"
                      *ngIf="
                        !currentActiveSurveyByWorkspace[workspace.id] &&
                        currentActiveSurveyByWorkspace[workspace.id] !== 0
                      "
                    ></nz-spin>
                  </ng-container>
                  <ng-container *ngIf="column.key === 'survey_display'">
                    {{ workspace.stats.total_survey_responses }}
                  </ng-container>
                  <ng-container *ngIf="column.key === 'survey_response'">
                    {{ workspace.stats.total_survey_responses_started }}
                  </ng-container>
                  <ng-container *ngIf="column.key === 'survey_respondent'">
                    {{ workspace.stats.total_respondents }}
                  </ng-container>
                  <ng-container *ngIf="column.key === 'workspace_users'">
                    {{ workspace.userCount }}
                  </ng-container>
                  <ng-container *ngIf="column.key === 'avg_response_rate'">
                    <span
                      *ngIf="
                        responseSurveyRatebyWorkspace[workspace.id] ||
                        responseSurveyRatebyWorkspace[workspace.id] === 0
                      "
                    >
                      {{
                        (
                          responseSurveyRatebyWorkspace[workspace.id] * 100
                        ).toFixed()
                      }}%
                    </span>
                    <nz-spin
                      nzSimple
                      nzSize="small"
                      *ngIf="
                        !responseSurveyRatebyWorkspace[workspace.id] &&
                        responseSurveyRatebyWorkspace[workspace.id] !== 0
                      "
                    ></nz-spin>
                  </ng-container>
                </td>
                <td nzAlign="right" class="actions" nzRight>
                  <a
                    routerLink="/org/{{ workspace.id }}/home"
                    title="Workspace"
                    nz-button
                    nzSize="large"
                    nzType="default"
                    nzShape="round"
                  >
                    <img
                      alt="Stats"
                      nz-icon
                      src="/assets/icons/homepage/graph.svg"
                    />
                  </a>
                  <button
                    title="Workspace settings"
                    nz-button
                    *ngIf="'org.update' | hasSuperPermission"
                    nzSize="large"
                    nzType="primary"
                    nzShape="round"
                    (click)="onEditWorkspace(workspace)"
                  >
                    <img
                      alt="Settings"
                      nz-icon
                      src="/assets/icons/homepage/pen.svg"
                    />
                  </button>

                  <!-- workspace action dropdown-->
                  <button
                    *ngIf="'org.delete' | hasSuperPermission"
                    title="More"
                    nz-button
                    class="button-create"
                    nzSize="large"
                    nzType="text"
                    nzShape="round"
                    (click)="$event.preventDefault(); $event.stopPropagation()"
                  >
                    <i
                      nz-icon
                      [nzType]="'more'"
                      class="workspace-actions"
                      [ngClass]="{
                        opened: currentWorkspaceActionDropDown === workspace.id
                      }"
                      nz-dropdown
                      nzTrigger="click"
                      nzPlacement="bottomRight"
                      [nzDropdownMenu]="workspaceActionsDropdown"
                      (nzVisibleChange)="
                        setCurrentWorkspaceActionDropDown(workspace.id, $event)
                      "
                    ></i>
                  </button>

                  <nz-dropdown-menu #workspaceActionsDropdown="nzDropdownMenu">
                    <ul nz-menu class="sidebar-workspace-dropdown-actions-list">
                      <style>
                        /* because overlay is displayed out of component */
                        .sidebar-workspace-dropdown-actions-list li.disabled {
                          background: transparent;
                          cursor: default;
                        }

                        .sidebar-workspace-dropdown-actions-list a {
                          padding: 0;
                          padding-left: 10px;
                          text-align: left;
                        }

                        .sidebar-workspace-dropdown-actions-list button {
                          margin-top: -5px;
                          margin-bottom: -5px;
                          padding: 0;
                        }

                        .sidebar-workspace-dropdown-actions-list
                          button
                          i.green {
                          color: green;
                        }

                        .sidebar-workspace-dropdown-actions-list
                          button
                          i.yellow {
                          color: #ffc046;
                        }
                      </style>
                      <li nz-menu-item (click)="onDelete(workspace)">
                        <button
                          nz-button
                          nzType="text"
                          [nzLoading]="
                            getWorkspaceActionLoaderStatus(
                              workspace.id,
                              'deleting'
                            )
                          "
                        >
                          Delete
                        </button>
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </section>
  </div>
</ng-container>
<workspace-create-modal
  [isOpen]="modalOpened"
  (modalOpenChange)="onClose($event)"
  [workspaces]="workspaces"
  [workspace]="editWorkspace"
  [superOrg]="superOrg"
/>
