import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { ChartDataset } from "chart.js/dist/types";
import {
  ReleaseTrendTimeInterval,
  ReleasesMarker,
  XAxisPoint,
} from "components/surveys/pages/stats/indicators/components/trend-indicator/release-overlay/release-overlay.types";
import { TrendIndicatorTheme } from "components/surveys/pages/stats/indicators/components/trend-indicator/trend-indicator.config";
import {
  add,
  differenceInDays,
  endOfDay,
  isWithinInterval,
  startOfDay,
} from "date-fns";
import { Release, getReleasedBy } from "models/release.model";
import { Account } from "models/account.model";
import { BaseChartDirective } from "ng2-charts";

const ReleaseOverlayOffset: { [key in TrendIndicatorTheme]: number } = {
  overall: 16,
  ces: 37,
  csat: 37,
  segment: 37,
  nps: 37,
};

@Component({
  selector: "release-chart-overlay",
  templateUrl: "./release-overlay.component.html",
  styleUrls: ["./release-overlay.component.scss"],
})
export class ReleaseChartOverlayComponent implements OnChanges {
  @Input()
  public releases: Release[] = [];
  @Input()
  public orgAccounts: Account[] = [];
  @Input()
  public orgId: string = "";

  @Input()
  public trendChart: BaseChartDirective = null;

  // Forced to pass dataset as input as it is
  // the only thing modified when changing trendChart time interval
  @Input()
  public datasets: ChartDataset[] = [];
  @Input()
  public options: object = null;
  @Input()
  public trendTheme: TrendIndicatorTheme = "overall";

  public groupedReleases: ReleasesMarker[] = [];
  public points: XAxisPoint[] = [];
  private trendTimeInterval: ReleaseTrendTimeInterval = {};

  public infoboxContent: Release[] = [];

  constructor(public router: Router) {}

  ngOnChanges({ options, datasets }: SimpleChanges) {
    if (datasets && datasets.previousValue !== datasets.currentValue) {
      setTimeout(() => {
        if (!!this.trendChart && !!this.trendChart.chart) {
          this.setupReleaseOverlay(true);
        }
      });
    }
    if (options && options.previousValue !== options.currentValue) {
      // Need to add onResize after the options has been configured to keep trendChart Element scope
      options.currentValue.onResize = (chart, { width, height }) => {
        chart.resize(width, height);
        setTimeout(() => {
          if (!!this.trendChart && !!this.trendChart.chart) {
            this.setupReleaseOverlay(false);
          }
        });
      };
    }
  }

  getReleasedBy(authorId?: string) {
    return getReleasedBy(this.orgAccounts, authorId);
  }

  getAuthorProfilePicture(accountId: string): Account {
    return this.orgAccounts.find((u) => u.id === accountId);
  }

  public onMarkerMouseEnter(left: number, markerIdx: number) {
    const infoBox = document.getElementById("release-infobox");
    const markerReleases = this.groupedReleases[markerIdx];

    if (infoBox === undefined || markerReleases === undefined) return;

    const right = Math.max(...this.points.map((o) => o["x"]));

    if (left < 150) {
      left = 150;
    } else if (left > right - 150) {
      left = right - 150;
    }

    infoBox.style.left = `${left}px`;
    infoBox.style.visibility = "visible";

    this.infoboxContent = markerReleases.releases;
  }

  private getTimePeriodDst(date: Date): number {
    // Find the closest point by day of year
    const closestPoint = this.points.reduce(
      (prev, curr) => {
        const currDiff = differenceInDays(curr.$context.parsed.x, date);
        const prevDiff = differenceInDays(prev.$context.parsed.x, date);

        if (prevDiff > 0 && currDiff <= 0) return curr;

        return currDiff <= 0 && currDiff > prevDiff ? curr : prev;
      },
      this.points[this.points.length - 1],
    );

    // Get idx of the closest point
    const pointIdx = this.points.findIndex(
      (point) => point.x === closestPoint.x,
    );

    const dayDiff = Math.abs(
      differenceInDays(closestPoint.$context.parsed.x, date),
    );

    if (dayDiff === 0) {
      return closestPoint.x;
    }

    const twoPointsDst =
      pointIdx < this.points.length - 1
        ? closestPoint.x - this.points[pointIdx + 1].x
        : this.points[pointIdx - 1].x - closestPoint.x;

    return (
      closestPoint.x +
      dayDiff * (twoPointsDst / this.trendTimeInterval.dayInterval)
    );
  }

  private groupReleases() {
    this.groupedReleases = [];

    const now = endOfDay(
      new Date(this.trendTimeInterval.max.$context.parsed.x),
    );
    const start = new Date(this.trendTimeInterval.min.$context.parsed.x);

    if (now.getTime() < start.getTime()) {
      return;
    }

    const releasesToDisplay = this.releases.filter((release) =>
      isWithinInterval(release.released_at, {
        start: start,
        end: now,
      }),
    );

    let pointDate = start;
    for (let i = 0; pointDate.getTime() <= now.getTime(); i++) {
      const limitDate = add(pointDate, {
        days: this.trendTimeInterval.dayInterval,
      });

      const releases = releasesToDisplay.filter((release) => {
        return isWithinInterval(release.released_at, {
          start: pointDate,
          end: limitDate,
        });
      });

      if (releases.length > 0) {
        const averageReleaseDay = startOfDay(
          Math.round(
            releases.reduce(
              (prev, curr) => prev + curr.released_at.getTime(),
              0,
            ) / releases.length,
          ),
        );
        this.groupedReleases.push({
          dateRef: averageReleaseDay,
          left: this.getTimePeriodDst(averageReleaseDay),
          releases: releases,
        });
      }

      pointDate = add(start, {
        days: (i + 1) * this.trendTimeInterval.dayInterval,
      });
    }
  }

  private onResize() {
    this.groupedReleases.forEach((point) => {
      point.left = this.getTimePeriodDst(point.dateRef);
    });
  }

  private setupReleaseOverlay(isRefresh = false) {
    const elementHeight = 30;

    const overlay = document.getElementById("release-overlay");
    const infoBox = document.getElementById("release-infobox");

    const data = this.trendChart.chart.getSortedVisibleDatasetMetas();
    const xAxis = data.find((d) => d.iAxisID === "x");

    if (overlay === undefined || xAxis === undefined || infoBox === undefined)
      return;

    overlay.style.width = `${xAxis.iScale.width + xAxis.iScale.paddingRight}px`;
    overlay.style.height = `${elementHeight}px`;
    overlay.style.top = `${
      xAxis.vScale.bottom + ReleaseOverlayOffset[this.trendTheme]
    }px`;

    infoBox.style.visibility = "hidden";
    infoBox.style.top = `${
      xAxis.vScale.bottom + ReleaseOverlayOffset[this.trendTheme] - 150
    }px`;

    infoBox.onmouseenter = () => (infoBox.style.visibility = "");
    infoBox.onmouseleave = () => (infoBox.style.visibility = "hidden");

    if (xAxis.data.length <= 1) {
      return;
    }

    // X-axis points are reversed
    this.trendTimeInterval = {
      min: xAxis.data[xAxis.data.length - 1] as unknown as XAxisPoint,
      max: xAxis.data[0] as unknown as XAxisPoint,
      dayInterval: 0,
    };

    this.points = xAxis.data as unknown as XAxisPoint[];

    // Get the total difference in Day between the two points
    this.trendTimeInterval.dayInterval = differenceInDays(
      startOfDay(this.points[0].$context.parsed.x),
      startOfDay(this.points[1].$context.parsed.x),
    );

    if (isRefresh) {
      this.groupReleases();
    } else {
      this.onResize();
    }
  }

  public onReleaseReadMore(releaseId: string) {
    this.router.navigate(["org", this.orgId, `releases`], {
      fragment: releaseId,
    });
  }
}
