import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FunnelReportAggregation } from "components/funnels/pages/report/funnel-report.aggregation";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { SequenceFunnel, SequenceFunnelStep } from "models/sequence.types";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { TrackersService } from "services/trackers.service";
import { ONE_DAY_SECONDS } from "../drop-if-select/drop-if-select-popover.component";

@Component({
  selector: "funnel-grid",
  template: `
    <nz-table
      #columnTable
      [nzData]="funnel.funnel.steps"
      [nzFrontPagination]="false"
      [nzScroll]="{ x: '1150px', y: 'calc(100vh - 136px - 64px)' }"
      [ngClass]="{ 'edit-mode': editMode }"
    >
      <thead>
        <tr>
          <th nzLeft nzWidth="400px">
            <span>Funnel</span>
          </th>
          <th nzWidth="400px">
            <img
              class="step-type-icon"
              src="/assets/icons/funnels/funnel-analysis-success.svg"
              alt="Conversion"
            />
            <span>Conversion</span>
          </th>
          <th nzWidth="400px">
            <img
              class="step-type-icon"
              src="/assets/icons/funnels/funnel-analysis-drop.svg"
              alt="Drop"
            />
            <span>Drop</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!loading">
          <tr *ngFor="let step of columnTable.data; let stepIndex = index">
            <td nzLeft *ngIf="editMode">
              <div class="step-event-name">
                <button
                  title="More"
                  nz-button
                  class="button-create"
                  nzType="text"
                  nzSize="large"
                  nz-dropdown
                  nzTrigger="click"
                  nzPlacement="bottomLeft"
                  [nzDropdownMenu]="stepActionsDropdown"
                >
                  <i nz-icon nzType="more"></i>
                </button>
                <nz-dropdown-menu #stepActionsDropdown="nzDropdownMenu">
                  <ul nz-menu class="sidebar-steps-dropdown-actions-list">
                    <style>
                      .sidebar-steps-dropdown-actions-list button {
                        margin-top: -5px;
                        margin-bottom: -5px;
                        padding: 0;
                      }
                    </style>
                    <li nz-menu-item *ngIf="stepIndex">
                      <span nz-icon nzType="arrow-up" nzTheme="outline"></span>
                      &nbsp;
                      <button
                        nz-button
                        nzType="text"
                        (click)="moveUpStep(stepIndex)"
                      >
                        Move up
                      </button>
                    </li>
                    <li
                      nz-menu-item
                      *ngIf="stepIndex < columnTable.data.length - 1"
                    >
                      <span
                        nz-icon
                        nzType="arrow-down"
                        nzTheme="outline"
                      ></span>
                      &nbsp;
                      <button
                        nz-button
                        nzType="text"
                        (click)="moveDownStep(stepIndex)"
                      >
                        Move down
                      </button>
                    </li>
                    <li nz-menu-item *ngIf="columnTable.data.length > 2">
                      <span nz-icon nzType="delete" nzTheme="outline"></span>
                      &nbsp;
                      <button
                        nz-button
                        nzType="text"
                        (click)="deleteStep(stepIndex)"
                      >
                        Remove
                      </button>
                    </li>
                  </ul>
                </nz-dropdown-menu>
                <div>{{ registryEntriesEventById[step.event_id]?.slug }}</div>
              </div>
              <drop-if-select
                *ngIf="
                  step.drop_condition.type === 'delay' &&
                  stepIndex !== columnTable.data.length - 1
                "
                [readOnly]="!editMode"
                [(value)]="step.drop_condition.delay"
                (valueChange)="onDropIfConditionDelayChange($event)"
              ></drop-if-select>
            </td>
            <td nzLeft class="repartition-cell" *ngIf="!editMode">
              <funnel-repartition
                *ngIf="values[stepIndex]"
                [eventName]="registryEntriesEventById[step.event_id]?.slug"
                [dropCondition]="
                  stepIndex !== columnTable.data.length - 1
                    ? step.drop_condition
                    : undefined
                "
                [total]="values[stepIndex].total"
                [currentValue]="values[stepIndex].currentValue"
                [nextValue]="values[stepIndex].nextValue"
              ></funnel-repartition>
            </td>
            <td class="success-cell">
              <funnel-grid-survey-cell
                [firstStep]="!stepIndex"
                [editMode]="editMode"
                [orgId]="funnel.org_id"
                [surveyId]="step.survey_on_success"
                [surveyTitle]="surveysById[step.survey_on_success]?.title"
                [surveyResponseCount]="
                  surveysById[step.survey_on_success]?.stats.response_total -
                  surveysById[step.survey_on_success]?.stats
                    .response_completion_not_started
                "
                [sequenceId]="funnel.id"
                [sequenceStepIndex]="stepIndex"
                sequenceStepType="success"
                [userPercent]="
                  editMode || !values[stepIndex - 1]
                    ? 100
                    : (values[stepIndex - 1].nextValue * 100) /
                      values[stepIndex - 1].currentValue
                "
                [userNextCount]="
                  editMode
                    ? 0
                    : values[stepIndex - 1]?.currentValue ??
                      values[stepIndex].currentValue
                "
                [userCount]="editMode ? 0 : values[stepIndex].currentValue"
                (displaySurveyClick)="displaySurveyClick.emit($event)"
              ></funnel-grid-survey-cell>
              <funnel-grid-sum-cell
                *ngIf="!editMode && stepIndex === columnTable.data.length - 1"
                sequenceStepType="success"
                [firstEventName]="
                  registryEntriesEventById[funnel.funnel.steps[0].event_id]
                    ?.slug
                "
                [lastEventName]="
                  registryEntriesEventById[
                    funnel.funnel.steps[funnel.funnel.steps.length - 1].event_id
                  ]?.slug
                "
                [totalCount]="values[0]?.currentValue ?? 0"
                [remainingCount]="
                  values[funnel.funnel.steps.length - 1]?.currentValue ?? 0
                "
              ></funnel-grid-sum-cell>
            </td>
            <td class="drop-cell">
              <funnel-grid-survey-cell
                *ngIf="stepIndex !== columnTable.data.length - 1"
                type="drop"
                [editMode]="editMode"
                [orgId]="funnel.org_id"
                [surveyId]="step.survey_on_drop"
                [surveyTitle]="surveysById[step.survey_on_drop]?.title"
                [surveyResponseCount]="
                  surveysById[step.survey_on_drop]?.stats.response_total -
                  surveysById[step.survey_on_drop]?.stats
                    .response_completion_not_started
                "
                [sequenceId]="funnel.id"
                [sequenceStepIndex]="stepIndex"
                sequenceStepType="drop"
                [userPercent]="
                  editMode
                    ? 0
                    : ((values[stepIndex].currentValue -
                        values[stepIndex].nextValue) *
                        100) /
                      values[stepIndex].currentValue
                "
                [userNextCount]="editMode ? 0 : values[stepIndex]?.currentValue"
                [userCount]="
                  editMode
                    ? 0
                    : values[stepIndex].currentValue -
                      values[stepIndex].nextValue
                "
                (displaySurveyClick)="displaySurveyClick.emit($event)"
              ></funnel-grid-survey-cell>
              <funnel-grid-sum-cell
                *ngIf="!editMode && stepIndex === columnTable.data.length - 1"
                sequenceStepType="drop"
                [firstEventName]="
                  registryEntriesEventById[funnel.funnel.steps[0].event_id]
                    ?.slug
                "
                [lastEventName]="
                  registryEntriesEventById[
                    funnel.funnel.steps[funnel.funnel.steps.length - 1].event_id
                  ]?.slug
                "
                [totalCount]="values[0]?.currentValue ?? 0"
                [remainingCount]="
                  (values[0]?.currentValue ?? 0) -
                  (values[funnel.funnel.steps.length - 1]?.currentValue ?? 0)
                "
              ></funnel-grid-sum-cell>
            </td>
          </tr>
          <tr *ngIf="editMode">
            <td class="add-event-cell">
              <funnel-add-event
                [registryEntriesEvent]="getAvailableEvents()"
                (submitEvents)="addEvents($event)"
              ></funnel-add-event>
            </td>
            <td></td>
            <td></td>
          </tr>
        </ng-container>
        <tr *ngIf="loading">
          <td colspan="3">
            <div class="loading-screen">
              <img
                src="/assets/illustrations/analytics-opportunities.png"
                alt="Loading"
              />
              <determinate-progress
                [percentage]="loadingPercentage"
              ></determinate-progress>
              <h2>
                We are processing your data for you, this will take a few
                moment.
              </h2>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  `,
  styles: [
    `
      :host {
        height: 100%;
      }

      :host ::ng-deep nz-table thead th {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
        line-height: 150%;
        color: var(--screeb-color-body-text-secondary);
        background: var(--screeb-color-input-background);
        padding: 16px 12px;
        border: none;
      }
      :host ::ng-deep nz-table thead th span {
        transform: translateY(2px);
        display: inline-block;
      }
      :host ::ng-deep nz-table thead th:before {
        display: none;
      }
      :host ::ng-deep nz-table tbody tr td {
        border-bottom: none;
        background: transparent;
        padding: 22px 12px 26px 28px;
        vertical-align: top;
        pointer-events: none;
      }
      :host ::ng-deep nz-table tbody tr td * {
        pointer-events: auto;
      }
      :host ::ng-deep nz-table.edit-mode tbody tr td {
        vertical-align: middle;
      }
      :host ::ng-deep nz-table tbody tr td:first-child .step-event-name {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        display: flex;
        align-items: center;
      }
      :host ::ng-deep nz-table tbody tr td:first-child .step-event-name button {
        padding: 0 8px;
        margin-left: -12px;
        height: 26px;
      }
      :host ::ng-deep nz-table tbody tr:hover td {
        background: transparent !important;
      }
      :host ::ng-deep table tbody .ant-table-row td:first-child {
        position: relative;
      }
      :host ::ng-deep table tbody tr:nth-child(2) td:first-child:before {
        z-index: -1;
        display: block;
        width: 400px;
        height: 1030px;
        position: absolute;
        left: 0;
        top: 0;
        content: " ";
        background-color: var(--screeb-color-background) !important;
      }
      :host
        .edit-mode
        ::ng-deep
        table
        tbody
        tr:nth-child(2)
        td:first-child:before {
        background: url("/assets/empty-screen/empty-screen-funnels-create.svg");
        background-repeat: no-repeat;
        background-position: 0 22px;
        background-color: var(--screeb-color-background) !important;
      }
      :host ::ng-deep .ant-table-body {
        min-height: calc(100vh - 136px - 64px);
      }

      :host determinate-progress {
        margin-bottom: 1rem;
        max-width: 500px;
      }

      :host .step-type-icon {
        margin-right: 8px;
      }

      :host .grid-cell {
        width: 100%;
        height: 176px;
        border: 3px solid var(--screeb-color-border);
        border-radius: 8px;
        max-width: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      :host .edit-mode .grid-cell {
        height: 124px;
      }

      :host .repartition-cell {
        padding: 0 12px 0 0;
      }

      :host .drop-cell funnel-grid-survey-cell {
        transform: translateY(256px);
      }

      :host .edit-mode .drop-cell funnel-grid-survey-cell {
        transform: translateY(168px);
      }

      :host tbody td {
        padding-top: 0;
      }

      :host .edit-mode tbody td {
        padding-top: 22px;
        padding-bottom: 22px;
      }

      :host tbody tr:nth-child(2) td {
        padding-top: 24px;
      }

      :host tbody tr:nth-child(2) .repartition-cell {
        padding-top: 24px;
      }

      :host tbody tr:nth-child(2) .repartition-cell > * {
        border-top-right-radius: 8px;
        overflow: hidden;
      }

      :host tbody tr:last-child .repartition-cell > * {
        border-bottom-right-radius: 8px;
        overflow: hidden;
        height: 500px;
      }

      :host .loading-screen {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 60px;
      }

      :host .loading-screen indeterminate-progress {
        max-width: 500px;
        margin-top: 8px;
        margin-bottom: 32px;
      }

      :host .add-event-cell {
        z-index: 2;
        height: 170px;
      }

      :host .success-cell funnel-grid-sum-cell {
        margin-top: 80px;
      }

      :host .drop-cell funnel-grid-sum-cell {
        margin-top: calc(176px + 80px);
      }
    `,
  ],
})
export class FunnelGridComponent implements OnInit {
  @Input() public surveysById: Record<UUID, Survey> = {};
  @Input() public funnel: SequenceFunnel;
  @Input() public registryEntriesEvent: RegistryEntry[] = [];
  @Input() public loading = false;
  @Input() public loadingPercentage = 0;
  @Input() public editMode = false;
  @Input() public values: FunnelReportAggregation = [];
  @Input() public org: Org;

  @Output() public funnelChange = new EventEmitter<SequenceFunnel>();
  @Output() public displaySurveyClick = new EventEmitter<string>();

  public registryEntriesEventById: Record<UUID, RegistryEntry> = {};

  constructor(private trackersService: TrackersService) {}

  ngOnInit() {
    this.registryEntriesEventById = this.registryEntriesEvent.reduce(
      (events, event) => ({ ...events, [event.id]: event }),
      {},
    );
  }

  onDropIfConditionDelayChange(delay: number) {
    this.trackersService
      .newEventTrackingBuilder("Funnel step drop-if delay set")
      .withOrg(this.org)
      .withSequence(this.funnel)
      .withProps({ delay })
      .build();
  }

  swapSteps(stepIndexA: number, stepIndexB: number) {
    const newSteps = [...this.funnel.funnel.steps];

    [newSteps[stepIndexA], newSteps[stepIndexB]] = [
      newSteps[stepIndexB],
      newSteps[stepIndexA],
    ];

    this.funnel.funnel.steps = newSteps;
  }

  moveUpStep(stepIndex: number) {
    this.swapSteps(stepIndex, stepIndex - 1);
  }

  moveDownStep(stepIndex: number) {
    this.swapSteps(stepIndex, stepIndex + 1);
  }

  deleteStep(stepIndex: number) {
    const newSteps = [...this.funnel.funnel.steps];

    newSteps.splice(stepIndex, 1);

    this.funnel.funnel.steps = newSteps;
  }

  addEvents(eventsIds: UUID[]) {
    this.funnel.funnel.steps = [
      ...this.funnel.funnel.steps,
      ...eventsIds.map(
        (event_id): SequenceFunnelStep => ({
          event_id,
          drop_condition: { type: "delay", delay: ONE_DAY_SECONDS },
        }),
      ),
    ];
  }

  getAvailableEvents() {
    return this.registryEntriesEvent.filter(
      ({ id }) =>
        !this.funnel.funnel.steps.some(({ event_id }) => event_id === id),
    );
  }
}
