/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { NotificationHelper } from "helpers/notification.helper";
import {
  allKPIs,
  allResponsesKPIs,
  cesKPIs,
  contentAnalysisKPIs,
  csatKPIs,
  KPI,
  KPIKey,
  npsKPIs,
  surveyAnalyticsKPIs,
  usersKPIs,
} from "models/analytics.kpis";
import { IntegrationDao } from "models/integration.dao";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import { SurveyDao } from "models/survey.dao";
import {
  esFiltersToSurveyResponseFilterGroup,
  IntegrationSettingsReport,
  IntegrationSettingsReports,
  ShareRepeatInterval,
  ShareReportType,
  Survey,
  surveyResponseFilterGroupToEsFilters,
} from "models/survey.model";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { TrackersService } from "services/trackers.service";
import { searchStringInString } from "utils/string";
import { RegistryEntry } from "models/registry.model";
import {
  AnalyticsFilter,
  AnalyticsFilterForResponse,
  AnalyticsFilters,
  AnalyticsFiltersOperator,
  AnalyticsQuery,
} from "models/analytics.filters.type";
import { Channel } from "models/channel.model";
import { ChannelDao } from "models/channel.dao";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import {
  operators,
  valueComponents,
} from "../filters/filter-criteria/filter-criteria.component";
import { GraphNode } from "components/builder/flow";
import { AnalyticsDao } from "models/analytics.dao";
import {
  fetchTagsKeys,
  getAvailableFields,
  getOperatorOptions,
  getValueComponent,
  getValuePresetOptions,
} from "../filters/filter-criteria/filter-criteria-response";

const repeatOptions: NzSelectOptionInterface[] = [
  { value: "no-repeat", label: "Don't repeat" },
  { value: "day", label: "Daily" },
  { value: "week", label: "Weekly" },
  { value: "month", label: "Monthly" },
];

@Component({
  selector: "share-lateral-panel",
  templateUrl: "./share-lateral-panel.component.html",
  styleUrls: [
    "./share-lateral-panel.component.scss",
    "../../../../builder/components/LateralPanel/common.scss",
  ],
})
export class ShareLateralPanelComponent implements OnInit, OnDestroy {
  @Input() reportType: ShareReportType = null;

  @Output() onClose = new EventEmitter<Event>();

  public org?: Org;
  public survey?: Survey;
  public integrations?: Integration[];

  public repeatOptions = repeatOptions;
  public allKPIs: readonly KPI[] = [];
  public filteredKPIs: readonly KPI[] = allKPIs;

  public shareLoading = false;
  public shareError = false;

  public selectedKpis: KPIKey[] = [];
  public repeatInterval: ShareRepeatInterval = "no-repeat";

  public slackEnabled = false;
  public slackChannel: string = null;
  public isSlackValid: boolean = null;

  public notionEnabled = false;
  public notionDatabaseId: string = null;
  public notionVersion: string = null;
  public isNotionValid: boolean = null;

  public atlassianEnabled = false;
  public atlassianCloudId: string = null;
  public atlassianProjectId: string = null;
  public isAtlassianValid: boolean = null;

  public emailEnabled = false;
  public emailList: string[] = null;
  public isEmailValid: boolean = null;

  public integrationSettingsReport: IntegrationSettingsReport = null;

  public registryEntriesIdentityProperty: RegistryEntry[] = [];
  public registryEntriesGroup: RegistryEntry[] = [];
  public registryEntriesEvent: RegistryEntry[] = [];

  private obs: any;

  // Filters
  public shouldShowFilters = false;
  public channels: Channel[] = null;
  public filters: AnalyticsFilters = null;
  public filtersOperator: AnalyticsFiltersOperator = null;
  public loadingDropdown = true;
  public lastFilters: AnalyticsQuery;
  public keysOptions: NzSelectOptionInterface[] = [];
  public availableLanguages: LanguageWithEmoji[];
  public availableTags: NzSelectOptionInterface[] = [];
  protected operators = operators;
  public nodesByKey: object = {};
  public nodes: GraphNode[] = [];
  protected valueComponents = valueComponents;
  public hiddenFields: string[] = null;
  public languages: LanguageWithEmoji[] = [];

  public matchingOperatorOptions: NzSelectOptionInterface[] = [
    { label: "All", value: "AND" },
    { label: "One of", value: "OR" },
  ];

  public filtersAreValid: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private surveyDao: SurveyDao,
    private integrationDao: IntegrationDao,
    private trackersService: TrackersService,
    private notificationHelper: NotificationHelper,
    private featureFlaggingService: FeatureFlaggingService,
    protected channelDao: ChannelDao,
    protected analyticsDao: AnalyticsDao,
  ) {}

  ngOnInit(): void {
    this.allKPIs = this.getKPIsFromType();

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
      this.survey = data["survey"];

      this.integrations = data["integrations"];

      // this.languages = data.languages_and_countries.surveyLanguagesWithEmojis;

      // this.registryEntriesEvent = data["registryEntriesEvent"];
      // this.registryEntriesGroup = data["registryEntriesGroup"].groups;
      // this.registryEntriesIdentityProperty =
      //   data["registryEntriesIdentityProperty"];

      this.integrationSettingsReport =
        this.survey.integrations?.reports?.[this.reportType];

      this.atlassianCloudId = this.integrations.find(
        ({ type }) => type === "atlassian",
      )?.settings.atlassian.cloud_id;

      if (this.integrationSettingsReport) {
        // if we have a report with filters then all-responses-raw-content should be transformed to filtered-responses
        if (
          this.integrationSettingsReport.filters &&
          this.integrationSettingsReport?.kpis.includes(
            "all-responses-raw-content",
          )
        ) {
          this.selectedKpis = ["filtered-responses"];
        } else {
          this.selectedKpis = this.integrationSettingsReport?.kpis;
        }

        this.shouldShowFiltersPanel();

        this.filters = surveyResponseFilterGroupToEsFilters(
          this.integrationSettingsReport.filters,
        );

        this.slackEnabled = Boolean(
          this.integrationSettingsReport.distributions.slack,
        );
        this.slackChannel =
          this.integrationSettingsReport.distributions.slack?.channel;

        this.notionEnabled = Boolean(
          this.integrationSettingsReport.distributions.notion,
        );
        this.notionDatabaseId =
          this.integrationSettingsReport.distributions.notion?.database_id;
        this.notionVersion =
          this.integrationSettingsReport.distributions.notion?.version;

        this.atlassianProjectId =
          this.integrationSettingsReport.distributions.atlassian?.project_id;

        this.atlassianEnabled = Boolean(
          this.integrationSettingsReport.distributions.atlassian,
        );

        this.emailEnabled = Boolean(
          this.integrationSettingsReport.distributions.email,
        );
        this.emailList =
          this.integrationSettingsReport.distributions.email?.emails;

        this.repeatInterval = this.integrationSettingsReport.repeatInterval;
      }
    });

    this.initPanel();
  }

  getKPIsFromType(): readonly KPI[] {
    switch (this.reportType) {
      case "users":
        return usersKPIs;
      case "survey-analytics":
        return surveyAnalyticsKPIs;
      case "all-responses":
        return allResponsesKPIs;
      case "content-analysis":
        return contentAnalysisKPIs;
      case "nps":
        return npsKPIs;
      case "csat":
        return csatKPIs;
      case "ces":
        return cesKPIs;
    }
  }

  ngOnDestroy(): void {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public onKpisChange($event: KPIKey[]) {
    this.selectedKpis = $event;
    this.shouldShowFiltersPanel();
  }

  public isKpisValid() {
    return this.selectedKpis.length >= 1 && this.selectedKpis.length <= 10;
  }

  public isRepeatValid() {
    return Boolean(this.repeatInterval);
  }

  public isValid(): boolean {
    const distributionsCount =
      Number(this.slackEnabled) +
      Number(this.notionEnabled) +
      Number(this.atlassianEnabled) +
      Number(this.emailEnabled);

    return (
      this.isKpisValid() &&
      this.isRepeatValid() &&
      (!this.isSlackIntegrationAvailableHere() ||
        this.isSlackValid ||
        !this.slackEnabled) &&
      (!this.isNotionIntegrationAvailableHere() ||
        this.isNotionValid ||
        !this.notionEnabled) &&
      (!this.isAtlassianIntegrationAvailableHere() ||
        this.isAtlassianValid ||
        !this.atlassianEnabled) &&
      (!this.isEmailIntegrationAvailableHere() ||
        this.isEmailValid ||
        !this.atlassianEnabled) &&
      distributionsCount !== 0 &&
      (this.filtersAreValid || !this.filters || this.filters.length === 0)
    );
  }

  private buildIntegrationSettingsReportObject(): IntegrationSettingsReports {
    const convertedFilters = esFiltersToSurveyResponseFilterGroup(
      this.filters,
      this.survey,
      this.filtersOperator,
    );

    // we transform filtered-responses to all-responses-raw-content if it is present
    // this is because the backend expects all-responses-raw-content
    // but we want to show filtered-responses in the UI
    const reportKpis = this.selectedKpis.map((kpi) => {
      if (kpi === "filtered-responses") {
        return "all-responses-raw-content";
      }
      return kpi;
    });

    const reportSettings: IntegrationSettingsReport = {
      kpis: reportKpis,
      repeatInterval: this.hasRepeatInterval()
        ? this.repeatInterval
        : "no-repeat",
      distributions: {
        slack: this.slackEnabled ? { channel: this.slackChannel } : undefined,
        notion: this.notionEnabled
          ? { database_id: this.notionDatabaseId, version: this.notionVersion }
          : undefined,
        atlassian: this.atlassianEnabled
          ? {
              cloud_id: this.atlassianCloudId,
              project_id: this.atlassianProjectId,
            }
          : undefined,
        email: this.emailEnabled ? { emails: this.emailList } : undefined,
      },
      filters: this.shouldShowFilters ? convertedFilters : null,
    };

    return {
      ...this.survey.integrations?.reports,
      [this.reportType]: reportSettings,
    };
  }

  public share() {
    if (this.isValid()) {
      this.shareLoading = true;
      this.shareError = false;

      const reports = this.buildIntegrationSettingsReportObject();

      this.surveyDao
        .updateIntegrations(this.org.id, this.survey.id, this.reportType, {
          ...(this.survey?.integrations ?? {}),
          reports,
        })
        .then(() => {
          const previousReportConfig =
            this.survey.integrations.reports?.[this.reportType];

          this.survey.integrations.reports = reports;

          this.onClose.emit();
          this.notificationHelper.trigger(
            "Sharing preferences have been saved.",
            null,
            "success",
          );

          // resync notions page if it is a new page
          if (
            reports[this.reportType]?.distributions?.notion?.database_id &&
            previousReportConfig?.distributions?.notion?.database_id !==
              reports[this.reportType]?.distributions?.notion?.database_id
          ) {
            this.triggerNotionReplay(
              reports[this.reportType]?.distributions?.notion?.database_id,
              reports[this.reportType]?.distributions?.notion?.version,
            );
          }
        })
        .catch((err) => {
          console.error(err);
          this.shareError = true;
          this.notificationHelper.trigger(
            "An error occured when saving sharing preferences.",
            null,
            "error",
          );
        })
        .finally(() => {
          this.shareLoading = false;
        });
    }
  }

  public shareIsDisabled() {
    return !this.isValid();
  }

  public remove() {
    const reports = this.buildIntegrationSettingsReportObject();

    delete reports[this.reportType];

    this.surveyDao
      .updateIntegrations(this.org.id, this.survey.id, this.reportType, {
        ...(this.survey?.integrations ?? {}),
        reports,
      })
      .then(() => {
        this.survey.integrations.reports = reports;

        this.onClose.emit();
        this.notificationHelper.trigger(
          "Sharing preferences have been removed.",
          null,
          "success",
        );
      })
      .catch(() => {
        this.shareError = true;
        this.notificationHelper.trigger(
          "An error occured when removing sharing preferences.",
          null,
          "error",
        );
      });
  }

  public kpiSearchChanged(searchString: string) {
    this.filteredKPIs = allKPIs.filter(
      ({ key, label }) =>
        searchStringInString(key, searchString) ||
        searchStringInString(label, searchString),
    );
  }

  public getShareButtonLabel() {
    if (!this.hasRepeatInterval()) {
      return "Save";
    }

    if (this.repeatInterval === "no-repeat") {
      return "Share once";
    }

    return "Set recurring share";
  }

  public getShareTipsLabel(): string | undefined {
    switch (this.repeatInterval) {
      case "day":
        return "Next notification will be shared tomorrow, and then every day.";
      case "week":
        return "Next notifications will be shared next Monday, and then every week.";
      case "month":
        return "Next notification will be shared early next month, and then every month.";
      case "no-repeat":
        return undefined;
    }
  }

  hasRepeatInterval() {
    return (
      this.selectedKpis.length &&
      this.selectedKpis.every(
        (kpiKey) => allKPIs.find((kpi) => kpiKey === kpi.key).hasRepeat,
      )
    );
  }

  hasAllResponsesRawContent() {
    return this.selectedKpis.find(
      (kpiKey) => kpiKey === "all-responses-raw-content",
    );
  }

  public isSlackIntegrationAvailableHere(): boolean {
    return true;
  }
  public isAtlassianIntegrationAvailableHere(): boolean {
    return this.reportType === "all-responses";
  }
  public isNotionIntegrationAvailableHere(): boolean {
    return this.reportType === "all-responses";
  }
  public isEmailIntegrationAvailableHere(): boolean {
    return true;
  }

  private triggerNotionReplay(notionDatabaseId: string, notionVersion: string) {
    const params = {
      notion_database_id: notionDatabaseId,
      notion_version: notionVersion,
    };

    this.integrationDao
      .triggerHookReplay(
        this.org.id,
        "notion",
        "response.ended",
        [this.survey.id],
        null,
        params,
      )
      .then(() => {
        this.notificationHelper.trigger(
          "A synchronisation of your Notion page has been ordered!",
          null,
          "success",
        );

        this.trackersService
          .newEventTrackingBuilder("Integration Notion database sync requested")
          .withOrg(this.org)
          .withSurvey(this.survey)
          .withProps(params)
          .build();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);

        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to synchronize your Notion page.",
          null,
          "error",
        );
      });
  }

  // Filters
  protected async initPanel() {
    await Promise.all([
      // fetchHiddenFieldKeys(),
      // fetchLanguageKeys(),
      fetchTagsKeys(this.org, this.analyticsDao).then((tags) => {
        this.availableTags = tags;
      }),
    ]);
  }

  private shouldShowFiltersPanel() {
    const kpiObject = this.selectedKpis
      .map((kpi) => {
        // Get corresponding kpi
        return allKPIs.find((k) => k.key === kpi);
      })
      .filter((kpi) => kpi.hasFilters);
    if (kpiObject.length > 0) {
      this.shouldShowFilters = true;
    } else {
      this.shouldShowFilters = false;
    }
  }

  protected getDefaultFilter(_channels): AnalyticsFilter {
    return {
      type: "response",
      key: "raw",
      operator: "contains",
      value: "",
    };
  }
  public onFilterAdded(channels: Channel[]) {
    this.filters.push(
      this.getDefaultFilter(channels) as never, // wtf
    );
  }

  public getOperatorOptions(f: AnalyticsFilter) {
    return getOperatorOptions(
      f,
      this.nodesByKey,
      this.registryEntriesIdentityProperty,
      true,
    );
  }

  public getValueComponent(f: AnalyticsFilterForResponse) {
    return getValueComponent(
      f,
      this.nodesByKey,
      this.registryEntriesIdentityProperty,
    );
  }

  protected getAvailableFields() {
    const { nodes, nodesByKey, keysOptions } = getAvailableFields(
      this.survey,
      this.selectedKpis,
      this.hiddenFields,
    );
    this.nodes = nodes;
    this.nodesByKey = nodesByKey;
    this.keysOptions = keysOptions;
  }

  public onFilterKeyChange(f: AnalyticsFilter, { type, key }) {
    f.type = type;
    f.key = key;

    const availbleOperators = getOperatorOptions(
      f,
      this.nodesByKey,
      this.registryEntriesIdentityProperty,
    ).map((opt) => opt.value);
    if (!availbleOperators.includes(f.operator)) {
      f.operator = availbleOperators[0];
    }
    this.onOperatorChange(f);
  }
  public onOperatorChange(f: AnalyticsFilter) {
    this.setDefaultValue(f);
  }
  public setDefaultValue(f: AnalyticsFilter) {
    (f as any).value = null;
    (f as any).values = null;
    (f as any).action_correlation_ids = null;

    const valueComponent = getValueComponent(
      f as AnalyticsFilterForResponse,
      this.nodesByKey,
      this.registryEntriesIdentityProperty,
    );

    if (valueComponent === "boolean") {
      (f as any).value = true;
    }
  }

  public getValuePresetOptions(f: AnalyticsFilter) {
    return getValuePresetOptions(f, this.survey, this.nodesByKey);
  }
}
