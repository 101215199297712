import { SurveyTargetingRule } from "models/survey-targeting-rule.model";

export function onOrRuleChange(
  rule: SurveyTargetingRule,
  content: string | number,
  index: number,
  isValueNumeric: boolean,
) {
  if (isValueNumeric) {
    rule.value.v_n_arr[index] = content as number;

    // remove field when empty
    if (
      rule.value.v_n_arr[index] === null ||
      rule.value.v_n_arr[index] === undefined
    )
      rule.value.v_n_arr.splice(index, 1);
  } else {
    rule.value.v_s_arr[index] = content as string;

    // remove field when empty
    if (rule.value.v_s_arr[index].trim().length === 0)
      rule.value.v_s_arr.splice(index, 1);
  }

  onOrRuleChangeEnsureEmptyEndingField(rule, isValueNumeric);
}

export function onOrRuleChangeEnsureEmptyEndingField(
  rule: SurveyTargetingRule,
  isValueNumeric: boolean,
) {
  // ensure a field is empty at the end
  if (isValueNumeric) {
    if (
      rule.value.v_n_arr.length > 0 &&
      rule.value.v_n_arr[rule.value.v_n_arr.length - 1] !== null &&
      rule.value.v_n_arr[rule.value.v_n_arr.length - 1] !== undefined
    )
      rule.value.v_n_arr.push(null);
  } else {
    if (
      rule.value.v_s_arr.length > 0 &&
      rule.value.v_s_arr[rule.value.v_s_arr.length - 1].trim().length > 0
    )
      rule.value.v_s_arr.push("");
  }
}

export function getNumericValueWithLimits(
  value: number | null,
  min: number,
  max: number,
): number {
  if (value === null || value === undefined) {
    return null;
  }

  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  }

  return value;
}
