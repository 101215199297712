import { Component, Input } from "@angular/core";
import { formatNumber } from "utils/number";

@Component({
  selector: "funnel-grid-sum-cell",
  template: `
    <div
      class="funnel-grid-sum-cell"
      [ngClass]="{
        success: sequenceStepType === 'success',
        drop: sequenceStepType === 'drop'
      }"
    >
      <survey-stats-indicators-single-indicator
        [title]="
          sequenceStepType === 'success'
            ? 'Total Conversion Rate'
            : 'Total Drop-Off Rate'
        "
        [value]="totalCount > 0 ? (remainingCount * 100) / totalCount : 0"
        format="percent"
      >
      </survey-stats-indicators-single-indicator>
      <div>
        ({{ formatNumber(remainingCount, "number") }} of
        {{ formatNumber(totalCount, "number") }})
      </div>
      <div>
        From <span class="bold">{{ firstEventName }}</span> to
        <span class="bold">{{ lastEventName }}</span>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        max-width: 500px;

        font-family: "Rubik";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
      }

      :host .funnel-grid-sum-cell {
        width: 100%;
        height: 176px;
        border: 3px solid var(--screeb-color-border);
        border-radius: 8px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      :host .drop {
        border-color: var(--screeb-color-error-outline);
      }

      :host .success {
        border-color: var(--screeb-color-success-outline);
      }

      :host .bold,
      :host ::ng-deep survey-stats-indicators-single-indicator .title {
        font-weight: 500 !important;
      }

      :host ::ng-deep survey-stats-indicators-single-indicator .value {
        font-size: 38px !important;
        line-height: 52px !important;
      }

      :host ::ng-deep survey-stats-indicators-single-indicator {
        min-height: auto;
      }
    `,
  ],
})
export class FunnelGridSumCellComponent {
  @Input() public sequenceStepType: "drop" | "success";
  @Input() public firstEventName: string;
  @Input() public lastEventName: string;
  @Input() public remainingCount: number;
  @Input() public totalCount: number;

  public formatNumber = formatNumber;
}
