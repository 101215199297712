import { Component, Input } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";
import { Survey } from "models/survey.model";

@Component({
  selector: "rule-user-answered-survey",
  templateUrl: "./rule-user-answered-survey.component.html",
  styleUrls: ["./rule-user-answered-survey.component.scss"],
})
export class TargetingRuleUserAnsweredSurveyComponent {
  @Input() public rule: SurveyTargetingRule = null;
  @Input() public surveys: Survey[] = [];

  constructor() {}
}
