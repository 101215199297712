import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";
import { WorkspaceAccountRole } from "./account-permissions";
import { Account } from "./account.model";
import {
  AnalyticsResponseItemResponse,
  getFormatedUserFromResponse,
} from "./analytics.model";
import { AskResult } from "./ask_result.model";
import { Org, OrgAnswerTags, OrgDataGovernance } from "./org.model";

import { Release } from "./release.model";
import { SurveyLanguages, TranslationLanguage } from "./survey.dao.types";

@Injectable()
class OrgDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getAll(): Promise<Org[]> {
    return this.screebApiHelper
      .get<Org[]>("/org")
      .toPromise()
      .then((data: object[]) => {
        return data.map((c: object) => new Org().fromJson(c));
      });
  }
  public getById(id: string): Promise<Org> {
    return this.screebApiHelper
      .get<Org>(`/org/${id}`)
      .toPromise()
      .then((data: object) => {
        return new Org().fromJson(data);
      });
  }

  /**
   * Settings
   */
  public getAllTargetingRulesByOrgId(
    orgId: string,
  ): Promise<SurveyTargetingRule[]> {
    return this.screebApiHelper
      .get<SurveyTargetingRule[]>(`/org/${orgId}/targeting-rules`)
      .toPromise()
      .then((data: object[]) => {
        return data.map((c: object) => new SurveyTargetingRule().fromJson(c));
      });
  }

  public updateTargetingRules(
    orgId: string,
    targeting_rules: SurveyTargetingRule[],
  ) {
    return this.screebApiHelper
      .put<SurveyTargetingRule[]>(`/org/${orgId}/targeting-rules`, {
        targeting_rules,
      })
      .toPromise()
      .then((data) => {
        return {
          targeting_rules: (data ?? []).map((rule) => {
            return new SurveyTargetingRule().fromJson(rule);
          }),
        };
      });
  }

  public updateDataGovernance(
    orgId: string,
    dataGovernance: OrgDataGovernance,
  ) {
    const body = {
      // retention
      retention_policy_identity_days:
        dataGovernance.retention_policy_identity_days,
      retention_policy_group_days: dataGovernance.retention_policy_group_days,
      retention_policy_event_days: dataGovernance.retention_policy_event_days,
      retention_policy_response_days:
        dataGovernance.retention_policy_response_days,

      // features
      tracking_capability_cohort_enabled:
        dataGovernance.tracking_capability_cohort_enabled,
      tracking_capability_page_enabled:
        dataGovernance.tracking_capability_page_enabled,
      tracking_capability_screen_enabled:
        dataGovernance.tracking_capability_screen_enabled,
      tracking_capability_device_enabled:
        dataGovernance.tracking_capability_device_enabled,
      tracking_capability_ip_enabled:
        dataGovernance.tracking_capability_ip_enabled,
      tracking_capability_geoip_enabled:
        dataGovernance.tracking_capability_geoip_enabled,
      tracking_capability_click_enabled:
        dataGovernance.tracking_capability_click_enabled,
      tracking_capability_tap_enabled:
        dataGovernance.tracking_capability_tap_enabled,
      tracking_capability_copy_enabled:
        dataGovernance.tracking_capability_copy_enabled,
      tracking_capability_paste_enabled:
        dataGovernance.tracking_capability_paste_enabled,
      tracking_capability_highlight_enabled:
        dataGovernance.tracking_capability_highlight_enabled,
      tracking_capability_zoom_enabled:
        dataGovernance.tracking_capability_zoom_enabled,
      tracking_capability_rage_click_enabled:
        dataGovernance.tracking_capability_rage_click_enabled,
      tracking_capability_dead_click_enabled:
        dataGovernance.tracking_capability_dead_click_enabled,
      tracking_capability_thrashing_click_enabled:
        dataGovernance.tracking_capability_thrashing_click_enabled,
    };
    return this.screebApiHelper
      .patch<Org>(`/org/${orgId}`, { data_governance: body })
      .toPromise();
  }

  // public setFlags(orgID: string, flags: object): Promise<Org> {
  //   const body = {
  //     flags,
  //   };
  //   return this.screebApiHelper
  //     .patch<Org>(`/org/${orgID}`, body)
  //     .toPromise()
  //     .then((data: object) => {
  //       return new Org().fromJson(data);
  //     });
  // }

  public getAllSurveyTagsByOrgId(orgId: string): Promise<string[]> {
    return this.screebApiHelper
      .get<string[]>(`/org/${orgId}/survey_tags`)
      .toPromise()
      .then((tags) => tags.sort());
  }

  public updateOrg(
    orgId: string,
    name: string,
    size: string,
    industry: string,
  ): Promise<Org> {
    return this.screebApiHelper
      .patch<Org>(`/org/${orgId}`, {
        name,
        size,
        industry,
      })
      .toPromise()
      .then((data) => {
        return new Org().fromJson(data);
      });
  }

  public updateAvailableLanguages(
    orgId: string,
    survey_languages: SurveyLanguages[],
    translation_enabled: boolean,
    translation_language: TranslationLanguage,
  ): Promise<Org> {
    return this.screebApiHelper
      .patch<Org>(`/org/${orgId}`, {
        survey_languages,
        translation_enabled,
        translation_language,
      })
      .toPromise()
      .then((data) => {
        return new Org().fromJson(data);
      });
  }

  /**
   * Team management
   */
  public getAllOrgAccounts(orgId: string): Promise<Account[]> {
    return this.screebApiHelper
      .get<object[]>(`/org/${orgId}/account`)
      .toPromise()
      .then((data) => data.map((account) => new Account().fromJson(account)));
  }

  public inviteOrgAccounts(
    orgId: string,
    email: string,
    role: WorkspaceAccountRole,
  ): Promise<any> {
    return this.screebApiHelper
      .post(`/org/${orgId}/account`, { email, role })
      .toPromise();
  }

  public deleteOrgAccounts(orgId: string, accountId: string): Promise<any> {
    return this.screebApiHelper
      .delete(`/org/${orgId}/account/${accountId}`)
      .toPromise();
  }

  public updateAccount(
    orgId: string,
    accountId: string,
    account: { role: WorkspaceAccountRole },
  ): Promise<any> {
    return this.screebApiHelper
      .patch(`/org/${orgId}/account/${accountId}`, account)
      .toPromise();
  }

  /**
   * API
   */
  public newApiToken(orgId: string): Promise<object> {
    return this.screebApiHelper
      .post<object>(`/org/${orgId}/reset-token`, {})
      .toPromise()
      .then((data) => {
        return { api_token: data["api_token"] };
      });
  }

  public ask(orgId: string, query: string): Promise<AskResult[]> {
    return this.screebApiHelper
      .get<object>(`/org/${orgId}/ask?query=${query}`)
      .toPromise()
      .then((data) => {
        const hits = data["hits"];
        const values = Object.values(hits)
          .flat()
          .filter((f) => f);
        values.sort((a, b) => {
          return b["score"] - a["score"];
        });

        return values
          .map((v: any) => {
            switch (v["doc_type"]) {
              case "release":
                const release = new Release().fromJson(v);
                return new AskResult(
                  release.id,
                  release.name,
                  v["doc_type"],
                  `/org/${orgId}/releases`,
                  v["doc_type"],
                  "releases",
                  release,
                );
              case "response":
                const response = AnalyticsResponseItemResponse.fromJson(v);
                const answers = response.answers.filter(
                  (a) => a.cta_type === "input" && a.v_s?.trim() !== "",
                );
                if (answers.length === 0) {
                  return null;
                }
                return new AskResult(
                  response.id,
                  answers.map((a) => a.v_s).join(", "),
                  `${v["doc_type"]} by ${getFormatedUserFromResponse(
                    response,
                  )}`,
                  `/org/${orgId}/survey/${response.survey_id}/stats/all-responses`,
                  v["doc_type"],
                  "arrow-right",
                  response,
                );
              default:
                return null;
            }
          })
          .filter((f) => f);
      });
  }

  public updateOrgAnswerTags(
    orgId: string,
    answer_tags: OrgAnswerTags[],
  ): Promise<{ status: string }> {
    return this.screebApiHelper
      .put<{ status: string }>(`/org/${orgId}/automatic_tags`, {
        fields: answer_tags || [],
      })
      .toPromise();
  }

  public updateOrgSmartAnswerTags(
    orgId: string,
    smart_answer_tags: string[],
  ): Promise<{ status: string }> {
    return this.screebApiHelper
      .put<{ status: string }>(`/org/${orgId}/smart_tags`, {
        fields: smart_answer_tags || [],
      })
      .toPromise();
  }

  public getIndustryObservation(
    id: string,
    type: string,
    company_score: number,
    competitor_score: number,
    top_performer_score: number,
  ): Promise<string> {
    const body = {
      type,
      company_score,
      competitor_score,
      top_performer_score,
    };

    return this.screebApiHelper
      .post<string>(`/org/${id}/stats/industry/observation`, body)
      .toPromise()
      .then((data: string) => {
        return data;
      });
  }

  public getIndustryAnalyze(id: string): Promise<string> {
    return this.screebApiHelper
      .post<string>(`/org/${id}/stats/industry/analyze`, {})
      .toPromise()
      .then((data: string) => {
        return data;
      });
  }
}

export { OrgDao };
