import { Component, Input } from "@angular/core";
import {
  AnalyticsResponseItemUser,
  getFormattedUserNameOrID,
} from "models/analytics.model";
import { RegistryEntry } from "models/registry.model";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import { getUserIcon } from "models/user.model";
import { UIService } from "services/ui.service";

@Component({
  selector: "user-simple-list",
  template: `
    <a
      class="user-item"
      *ngFor="let user of users"
      routerLink="/org/{{ user.org_id }}/people/respondent/{{ user.id }}"
    >
      <div class="user-icon" [ngClass]="iconSize">
        <img
          nz-icon
          [src]="getUserIcon(user.id, user.getAvatarURL())"
          alt="{{ getFormattedUserNameOrID(user) }}"
        />
        <div *ngIf="shouldShowPresence && user.isActive" class="user-presence">
          <div class="user-presence-dot"></div>
        </div>
      </div>
      <div class="bold-text">
        <span [ngClass]="userNameSize">
          {{ getFormattedUserNameOrID(user) }}</span
        >
        <ng-container *ngIf="user.is_anonymous">
          <br />
          <nz-tag
            nz-popover
            nzPopoverTitle="Anonymous users"
            [nzPopoverContent]="anonymousHelp"
            >Anonymous
          </nz-tag>

          <ng-template #anonymousHelp>
            This user has not been identified in Javascript tag or mobile SDKs.
            <br />
            <a rel="noopener" href="https://help.screeb.app" target="_blank">
              <i nz-icon nzType="link"></i>
              See our help section.
            </a>
          </ng-template>
        </ng-container>
        <div class="user-groups">
          <nz-tag *ngFor="let userGroupName of getUserGroupsName(user)">
            {{ userGroupName }}
          </nz-tag>
        </div>
      </div>
      <svg
        *ngIf="shouldShowArrow"
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.9241 4.61722C13.8753 4.49927 13.803 4.38877 13.7071 4.29289L9.70711 0.292893C9.31658 -0.0976311 8.68342 -0.097631 8.29289 0.292893C7.90237 0.683418 7.90237 1.31658 8.29289 1.70711L10.5858 4L1 4C0.447715 4 0 4.44772 0 5C0 5.55229 0.447715 6 1 6L10.5858 6L8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.70711 9.70711L13.7071 5.70711C13.9 5.51419 13.9976 5.26207 14 5.00923M13.9241 4.61722C13.9719 4.73244 13.9988 4.85854 14 4.99077L13.9241 4.61722Z"
          fill="#727387"
        />
      </svg>
    </a>
    <nz-empty
      *ngIf="!users?.length && showEmptyState"
      [nzNotFoundContent]="contentTpl"
      nzNotFoundImage="/assets/empty-screen/empty-screen-users.png"
    >
      <ng-template #contentTpl>
        <div>Install Screeb to see who's using your product</div>
        <a routerLink="/org/{{ uiService.currentOrgId }}/settings/install">
          Install Screeb&nbsp;
          <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
        </a>
      </ng-template>
    </nz-empty>
  `,
  styles: [
    `
      :host .user-item {
        display: flex;
        align-items: center;
      }

      :host .user-item + .user-item {
        padding-top: 8px;
        margin-top: 8px;
      }

      :host .user-item nz-tag {
        border-radius: 8px;
        margin-right: 4px;
        margin-bottom: 4px;
      }

      :host .user-groups {
        max-height: 66px;
        overflow: hidden;
      }

      :host .user-groups > nz-tag {
        border-radius: 8px;
        margin-right: 4px;
        margin-bottom: 4px;
        min-height: 30px;
      }

      :host .bold-text {
        flex: 1;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: var(--screeb-color-body-text);
      }

      :host .small {
        font-size: 14px;
      }

      :host .large {
        font-size: 16px;
      }

      :host .user-icon.medium {
        height: 60px;
        position: relative;
      }

      :host .user-icon.small {
        height: 48px;
        position: relative;
      }

      :host .user-icon.medium > img {
        width: 60px;
        height: 60px;
        margin-right: 12px;
        border-radius: 60px;
      }

      :host .user-icon.small > img {
        width: 48px;
        height: 48px;
        margin-right: 12px;
        border-radius: 48px;
      }

      :host .user-icon .user-presence {
        top: 38px;
        left: 2px;
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: var(--screeb-color-white);
      }

      :host .user-icon .user-presence .user-presence-dot {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: var(--screeb-color-green-500);
      }

      :host nz-empty {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: var(--screeb-color-body-text);
      }

      :host nz-empty a {
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      :host ::ng-deep nz-empty img {
        width: auto !important;
        height: auto !important;
      }
    `,
  ],
})
export class UserSimpleListComponent {
  @Input() public users: AnalyticsResponseItemUser[] = [];
  @Input() public shouldShowArrow = false;
  @Input() public registryEntriesGroup: RegistryEntry[] = [];
  @Input() public userNameSize: "small" | "large" = "small";
  @Input() public iconSize: "small" | "medium" = "medium";
  @Input() public shouldShowPresence = false;
  @Input() public showEmptyState = true;

  constructor(public uiService: UIService) {}

  public getFormattedUserNameOrID = getFormattedUserNameOrID;
  public getUserIcon = getUserIcon;

  public getUserGroupsName(user: AnalyticsResponseItemUser): string[] {
    if (this.registryEntriesGroup.length === 0) {
      return [];
    }
    return user?.group_ids?.reduce((accum, groupId) => {
      const groupName = this.registryEntriesGroup.find(
        (group) => group.id === groupId,
      );
      if (groupName) {
        accum.push(getFormattedUserGroupNameOrID(groupName));
      }
      return accum;
    }, []);
  }
}
