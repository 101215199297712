import { Component, Input, OnInit } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";
import { ChannelClientScreen } from "models/channel-screen.model";
import {
  onOrRuleChange,
  onOrRuleChangeEnsureEmptyEndingField,
} from "../utils/rule-change";

@Component({
  selector: "rule-screen",
  templateUrl: "./rule-screen.component.html",
  styleUrls: ["./rule-screen.component.scss"],
})
export class TargetingRuleScreenComponent implements OnInit {
  @Input() public rule: SurveyTargetingRule = null;
  @Input() public channelClientScreens: ChannelClientScreen[] = [];

  public onOrRuleChange = onOrRuleChange;

  constructor() {}

  ngOnInit() {
    onOrRuleChangeEnsureEmptyEndingField(this.rule, false);
  }

  public trackByFn(index: any) {
    return index;
  }

  public checkValidValue(r: SurveyTargetingRule, index: number): boolean {
    if (index === 0 && r.value.v_s_arr[index].trim().length === 0) {
      return false;
    }

    return true;
  }
}
