/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { PlanType } from "models/org_billing.model";
import {
  PlanTypeInfo,
  planTypeInfos,
} from "../../components/billing-plan/billing-plan.data";
import { TrackersService } from "services/trackers.service";
import { NotificationHelper } from "helpers/notification.helper";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";

@Component({
  selector: "billing-lateral-panel-downgrade",
  template: `
    <div>
      <p class="subtitle">By downgrading to a Free Account, you’ll loose...</p>

      <div class="plan-feature" *ngFor="let feature of plan.features">
        {{ feature }}
      </div>

      <intercom-button
        title="Any question about downgrading and the impact on your account? Chat with us!"
        textSize="14px"
      />

      <!-- <p class="subtitle">
        Before downgrading, could you respond to these quick questions? 👇
      </p> -->
    </div>

    <footer class="panel-button-container">
      <button
        class="cancel-btn"
        nz-button
        nzType="default"
        nzSize="large"
        (click)="onClose.emit($event)"
      >
        Cancel
      </button>

      <button
        nz-button
        nzType="primary"
        nzSize="large"
        [nzLoading]="validationLoading"
        (click)="downgrade()"
      >
        Downgrade Now
      </button>
    </footer>
  `,
  styles: [
    `
      .subtitle {
        margin-top: 28px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #303140;
      }

      .plan-feature {
        width: 100%;
        height: 45px;

        border-radius: 16px;
        background-color: #f7f7f8;
        padding: 12px;
        margin-top: 8px;

        display: flex;
        align-items: center;

        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #303140;
      }

      intercom-button {
        margin-top: 40px;
        max-width: 100%;
      }

      ::ng-deep #screeb-dot-app {
        right: -45px !important;
        animation: right;
      }
    `,
  ],
  styleUrls: ["../footer.component.scss"],
})
export class SettingsBillingLateralPanelDowngradeComponent
  implements OnInit, OnDestroy
{
  @Input() superOrg: SuperOrg = null;
  @Input() currentPlanType: PlanType;

  @Output() onSubscriptionUpdate = new EventEmitter<unknown>();
  @Output() onClose = new EventEmitter<boolean>();

  public plan: PlanTypeInfo;

  public validationLoading: boolean = false;

  constructor(
    private notificationHelper: NotificationHelper,
    private superOrgDao: SuperOrgDao,
    private trackersService: TrackersService,
  ) {}

  ngOnInit(): void {
    this.initModeDowngrade();
    this.trackersService.screebSurveyStart(
      "e261bc93-e958-4561-8bb2-9e30de137d78",
    );
  }

  ngOnDestroy(): void {
    this.trackersService.screebSurveyClose();
  }

  private initModeDowngrade() {
    this.plan = planTypeInfos[this.currentPlanType];
  }

  public async downgrade() {
    this.validationLoading = true;

    this.superOrgDao
      .updateSubscription(this.superOrg.id, null, null, null, null)
      .then(async () => {
        // this.trackersService.linkedinTrackConversion(10979394);
        this.trackersService.gtmTrackConversion("churn");
      })
      .then(() => {
        this.onSubscriptionUpdate.emit();
      })
      .catch(() => {
        this.notificationHelper.trigger(
          "An error occured when selecting plan.",
          null,
          "error",
        );
        this.validationLoading = false;
      });
  }
}
