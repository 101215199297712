import { Component, Input, OnChanges, OnInit } from "@angular/core";

import { TagSettings, TagSettingsPosition } from "models/tag-settings.types";
import { SettingsService } from "services/settings.service";
import { isDarkColor } from "utils/color";

@Component({
  selector: "tag-settings-preview",
  templateUrl: "./tag-settings-preview.component.html",
  styleUrls: ["./tag-settings-preview.component.scss"],
})
export class TagSettingsPreviewComponent implements OnInit, OnChanges {
  @Input() public tagSettings: TagSettings = null;
  @Input() public forceMobile = false;
  @Input() public showMobileOption = true;
  @Input() public format: "cards" | "in_page" | "conversational" | "link" =
    "conversational";
  @Input() public scope: "org" | "survey" | "distribution" = "org";

  @Input() public mode: "mobile" | "desktop" = "desktop";

  public verticalAlign: "flex-end" | "center" = "flex-end";
  public horizontalAlign: "flex-end" | "center" | "flex-start" = "flex-start";

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    if (this.forceMobile) {
      this.mode = "mobile";
    }
  }

  ngOnChanges(): void {
    if (!this.showMobileOption) {
      this.mode = "desktop";
    }
  }

  public getAffixTarget(): HTMLElement {
    return document.querySelector("#affix-anchor-target");
  }

  public getPosition(): TagSettingsPosition {
    switch (this.format) {
      case "cards":
        return this.tagSettings.cards_position;
      case "conversational":
        return this.tagSettings.conversational_position;
      default:
        return "bottom-left";
    }
  }

  public getVerticalAlign() {
    if (this.format === "link" && this.tagSettings.format !== "cards") {
      return "center";
    }

    if (this.mode === "mobile") {
      return "flex-end";
    }

    if (this.format === "in_page") {
      return "center";
    }

    switch (this.getPosition()) {
      case "bottom-left":
      case "bottom-center":
      case "bottom-right":
        return "flex-end";
      default:
        return "center";
    }
  }

  public getHorizontalAlign(noCenter = false) {
    if (this.format === "link") {
      if (noCenter) {
        return "flex-start";
      }
      return "center";
    }

    if (this.mode === "mobile") {
      if (noCenter) {
        return "flex-start";
      }
      return "center";
    }

    if (this.format === "in_page") {
      return "center";
    }

    switch (this.getPosition()) {
      case "bottom-left":
      case "center-left":
        return "flex-start";
      case "center-center":
      case "bottom-center":
        if (noCenter) {
          return "flex-start";
        }
        return "center";
      case "bottom-right":
      case "center-right":
        return "flex-end";
    }
  }

  public getBackgroundImage(): string {
    if (this.format === "link" && this.mode === "desktop") {
      return this.getBackgroundImageUrl();
    }

    if (this.mode === "mobile") {
      return "/assets/preview/preview-background-mobile.svg";
    }

    if (this.format === "in_page") {
      return "/assets/preview/preview-background-in-page-up.png";
    }

    if (this.mode === "desktop") {
      return "/assets/preview/preview-background.svg";
    }

    return "";
  }

  public getInnerBackgroundImage(): string {
    if (this.format === "link" && this.mode === "mobile") {
      return this.getBackgroundImageUrl();
    }

    return "";
  }

  public getBackgroundImageUrl() {
    return this.tagSettings.page_background_type === "image"
      ? this.tagSettings.page_background_image_url
      : "";
  }

  public getInnerBackgroundColor() {
    if (this.format === "link" && this.mode === "mobile") {
      const style = window.getComputedStyle(document.body);
      return this.tagSettings.page_background_type === "color"
        ? this.tagSettings.page_background_color
        : style.getPropertyValue("--screeb-color-background-secondary");
    }

    if (this.format === "in_page") {
      const style = window.getComputedStyle(document.body);
      return style.getPropertyValue("--screeb-color-grey-200");
    }

    return "transparent";
  }

  public getBackgroundColor() {
    const style = window.getComputedStyle(document.body);

    if (this.format === "in_page") {
      return style.getPropertyValue("--screeb-color-grey-200");
    }

    return this.tagSettings.page_background_type === "color" &&
      this.mode === "desktop"
      ? this.tagSettings.page_background_color
      : style.getPropertyValue("--screeb-color-background-secondary");
  }

  public shouldInvertWhiteLabel() {
    return isDarkColor(this.tagSettings.cards_color_primary);
  }

  public getFormat() {
    if (this.format === "link") {
      return this.tagSettings.format;
    }

    return this.format;
  }

  getCssVars() {
    return {
      "--cards-border-line-color": this.tagSettings.cards_border_line_color,
      "--cards-border-line-width":
        this.tagSettings.cards_border_line_width + "px",
      "--cards-border-line-style": this.tagSettings.cards_border_line
        ? "solid"
        : "none",
      "--cards-border-line": this.tagSettings.cards_border_line,
      "--cards-border-radius": this.tagSettings.cards_border_radius + "px",
      "--cards-border-shadow-color": this.tagSettings.cards_border_shadow
        ? this.tagSettings.cards_border_shadow_color
        : "transparent",
      "--cards-border-shadow": this.tagSettings.cards_border_shadow,
      "--cards-color-primary-text": this.tagSettings.cards_color_primary_text,
      "--cards-color-primary": this.tagSettings.cards_color_primary,
      "--cards-color-secondary-text":
        this.tagSettings.cards_color_secondary_text,
      "--cards-color-secondary": this.tagSettings.cards_color_secondary,
      "--cards-display-reduce-button":
        this.tagSettings.cards_display_reduce_button,
      "--cards-font-family": this.tagSettings.cards_font_family,
      "--cards-font-line-height":
        this.tagSettings.cards_font_line_height + "px",
      "--cards-font-size": this.tagSettings.cards_font_size + "px",
      "--cards-identity-avatar-format":
        this.tagSettings.cards_identity_avatar_format,
      "--cards-identity-fixed-avatar": `url('${this.tagSettings["cards_identity_fixed_avatar"]}')`,
      "--cards-identity-fixed-name":
        this.tagSettings["cards_identity_fixed_name"],
      "--cards-identity-fixed-title":
        this.tagSettings["cards_identity_fixed_title"],
      "--cards-identity-type": this.tagSettings.cards_identity_type,
      "--cards-identity-avatar-border-radius":
        {
          round: 100,
          square: 2,
          rounded_square: 8,
        }[this.tagSettings.cards_identity_avatar_format] + "px",
      "--cards-overlay-color": this.tagSettings.cards_overlay_color,
      "--cards-overlay-visibility": this.tagSettings.cards_overlay_visibility,
      "--cards-position": this.tagSettings.cards_position,
      "--cards-size": this.tagSettings.cards_size - 15 + "%",

      "--conversational-border-line-color":
        this.tagSettings.conversational_border_line_color,
      "--conversational-border-line-width":
        this.tagSettings.conversational_border_line_width + "px",
      "--conversational-border-line-style": this.tagSettings
        .conversational_border_line
        ? "solid"
        : "none",
      "--conversational-border-radius":
        this.tagSettings.conversational_border_radius + "px",
      "--conversational-border-shadow-color": this.tagSettings
        .conversational_border_shadow
        ? this.tagSettings.conversational_border_shadow_color
        : "transparent",
      "--conversational-border-shadow":
        this.tagSettings.conversational_border_shadow,
      "--conversational-color-primary-text":
        this.tagSettings.conversational_color_primary_text,
      "--conversational-color-primary":
        this.tagSettings.conversational_color_primary,
      "--conversational-color-secondary-text":
        this.tagSettings.conversational_color_secondary_text,
      "--conversational-color-secondary":
        this.tagSettings.conversational_color_secondary,
      "--conversational-display-close-reduce":
        this.tagSettings.conversational_display_close_reduce,
      "--conversational-font-family":
        this.tagSettings.conversational_font_family,
      "--conversational-font-line-height":
        this.tagSettings.conversational_font_line_height + "px",
      "--conversational-font-size":
        this.tagSettings.conversational_font_size + "px",
      "--conversational-font-line-height-intro":
        Math.round(this.tagSettings.conversational_font_line_height / 1.2) +
        "px",
      "--conversational-font-size-intro":
        Math.round(this.tagSettings.conversational_font_size / 1.15) + "px",
      "--conversational-identity-avatar-border-radius":
        {
          round: 100,
          square: 2,
          rounded_square: 8,
        }[this.tagSettings.conversational_identity_avatar_format] + "px",
      "--conversational-identity-fixed-avatar": `url('${this.tagSettings["conversational_identity_fixed_avatar"]}'`,
      "--conversational-identity-fixed-name":
        this.tagSettings["conversational_identity_fixed_name"],
      "--conversational-identity-fixed-title":
        this.tagSettings["conversational_identity_fixed_title"],
      "--conversational-identity-type":
        this.tagSettings.conversational_identity_type,
      "--conversational-overlay-color":
        this.tagSettings.conversational_overlay_color,
      "--conversational-overlay-visibility":
        this.tagSettings.conversational_overlay_visibility,
      "--conversational-position": this.tagSettings.conversational_position,
      "--conversational-size": this.tagSettings.conversational_size + "%",

      "--in-page-border-line-color": this.tagSettings.in_page_border_line_color,
      "--in-page-border-line-width":
        this.tagSettings.in_page_border_line_width + "px",
      "--in-page-border-line-style": this.tagSettings.in_page_border_line
        ? "solid"
        : "none",
      "--in-page-border-line": this.tagSettings.in_page_border_line,
      "--in-page-border-radius": this.tagSettings.in_page_border_radius + "px",
      "--in-page-border-shadow-color": this.tagSettings.in_page_border_shadow
        ? this.tagSettings.in_page_border_shadow_color
        : "transparent",
      "--in-page-border-shadow": this.tagSettings.in_page_border_shadow,
      "--in-page-color-primary-text":
        this.tagSettings.in_page_color_primary_text,
      "--in-page-color-primary": this.tagSettings.in_page_color_primary,
      "--in-page-color-secondary-text":
        this.tagSettings.in_page_color_secondary_text,
      "--in-page-color-secondary": this.tagSettings.in_page_color_secondary,
      "--in-page-display-reduce-button":
        this.tagSettings.in_page_display_reduce_button,
      "--in-page-font-family": this.tagSettings.in_page_font_family,
      "--in-page-font-line-height":
        this.tagSettings.in_page_font_line_height + "px",
      "--in-page-font-size": this.tagSettings.in_page_font_size + "px",
      "--in-page-identity-avatar-format":
        this.tagSettings.in_page_identity_avatar_format,
      "--in-page-identity-fixed-avatar": `url('${this.tagSettings["in_page_identity_fixed_avatar"]}')`,
      "--in-page-identity-fixed-name":
        this.tagSettings["in_page_identity_fixed_name"],
      "--in-page-identity-fixed-title":
        this.tagSettings["in_page_identity_fixed_title"],
      "--in-page-identity-type": this.tagSettings.in_page_identity_type,
      "--in-page-identity-avatar-border-radius":
        {
          round: 100,
          square: 2,
          rounded_square: 8,
        }[this.tagSettings.in_page_identity_avatar_format] + "px",
      "--in-page-overlay-color": this.tagSettings.in_page_overlay_color,
      "--in-page-overlay-visibility":
        this.tagSettings.in_page_overlay_visibility,
      "--in-page-position": this.tagSettings.in_page_position,
      "--in-page-size": this.tagSettings.in_page_size - 15 + "%",
    };
  }
}
