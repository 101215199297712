<div class="share-redirect-container" *ngIf="loaded">
  <h1>
    Where do you want to
    {{ uiService.isMessagePage ? "see this message" : "share this survey" }}?
  </h1>

  <div class="share-cards">
    <div class="share-card" [routerLink]="getLink('widget')">
      <div class="share-card-illustration" [style.background]="'#EBF4FF'">
        <img
          alt="Web"
          width="280"
          src="/assets/illustrations/channels/web.png"
          class="padding"
        />
      </div>
      <div class="share-card-details">
        <div class="share-card-details-title">Web App</div>
        <div class="share-card-details-description">For SaaS and websites</div>
        <div class="spacer"></div>
        <a nz-button nzType="primary" [routerLink]="getLink('widget')"
          >Choose</a
        >
      </div>
    </div>
    <div
      class="share-card"
      [routerLink]="uiService.isMessagePage ? null : getLink('ios')"
      *ngIf="this.survey.interaction !== 'in_page'"
    >
      <div class="share-card-illustration" [style.background]="'#F5F1FF'">
        <img
          alt="iOS"
          width="280"
          src="/assets/illustrations/channels/ios.png"
          class="padding"
        />
      </div>
      <div class="share-card-details">
        <div class="share-card-details-title">iOS App</div>
        <div class="share-card-details-description">For iPhones and iPad</div>
        <div class="spacer"></div>
        <a
          nz-button
          nzType="primary"
          [routerLink]="getLink('ios')"
          [disabled]="
            uiService.isMessagePage &&
            !('isContextualMessagesMobileEnabled' | hasFeatureFlag)
          "
          >{{
            uiService.isMessagePage &&
            !("isContextualMessagesMobileEnabled" | hasFeatureFlag)
              ? "Coming soon"
              : "Choose"
          }}</a
        >
      </div>
    </div>
    <div
      class="share-card"
      [routerLink]="uiService.isMessagePage ? null : getLink('android')"
      *ngIf="this.survey.interaction !== 'in_page'"
    >
      <div class="share-card-illustration" [style.background]="'#E4F8F2'">
        <img
          width="280"
          alt="Android"
          src="/assets/illustrations/channels/android.png"
        />
      </div>
      <div class="share-card-details">
        <div class="share-card-details-title">Android App</div>
        <div class="share-card-details-description">
          For smartphones and tablets
        </div>
        <div class="spacer"></div>
        <a
          nz-button
          nzType="primary"
          [routerLink]="getLink('android')"
          [disabled]="uiService.isMessagePage"
          >{{ uiService.isMessagePage ? "Coming soon" : "Choose" }}</a
        >
      </div>
    </div>
  </div>

  <a
    *ngIf="!uiService.isMessagePage && this.survey.interaction !== 'in_page'"
    nz-button
    nzType="dashed"
    class="hosted-page"
    [routerLink]="getLink('hosted-page')"
  >
    <div class="hosted-page-title">
      <i nz-icon nzType="link"></i> Direct link
    </div>
    <div class="hosted-page-description">
      To share via email or social media
    </div>
  </a>
</div>
