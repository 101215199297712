<div *ngIf="rule">
  <div class="rule-type-session-duration">
    <nz-input-group
      [nzPrefix]="'After:'"
      [nzSuffix]="'seconds spent on the app'"
      [ngClass]="{ invalid: !checkValidValue(rule) }"
    >
      <rule-input-number
        [(value)]="rule.value.v_n"
        [step]="1"
        [minValue]="1"
        [maxValue]="86400"
        [inputGroupMode]="true"
        [placeholder]="rule.getValuePlaceholder()"
      ></rule-input-number>
    </nz-input-group>

    <span
      class="help"
      nz-popover
      [nzPopoverContent]="integrationsHelpTemplate"
      nzPopoverPlacement="rightTop"
    >
      <i nz-icon nzType="question-circle" nzTheme="twotone"></i>
    </span>

    <ng-template #integrationsHelpTemplate>
      Session is reset after 30 minute of inactivity
    </ng-template>
  </div>
</div>
