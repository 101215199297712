import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { Integration, IntegrationType } from "models/integrations.model";
import { HookType } from "./hook.model";

export type IntegrationSlackChannel = {
  id: string;
  name: string;
};

export type IntegrationNotionPage = {
  id: string;
  name: string;
  url: string;
  emoji?: string;
};

export type IntegrationNotionDatabase = {
  id: string;
  name: string;
  url: string;
  version: string;
  emoji?: string;
};

export type IntegrationAtlassianProject = {
  id: string;
  name: string;
};

export type HookReplayParams =
  // notion
  | { notion_database_id: string; notion_version: string }
  // atlassian
  | { atlassian_cloud_id: string; atlassian_project_id: string }
  // webhook
  | { webhook_endpoint: string; webhook_version: string }
  // stitch
  | { stitch_endpoint: string; stitch_version: string }
  // cobbai
  | { cobbai_endpoint: string; cobbai_version: string };

@Injectable()
class IntegrationDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getIntegrations(orgId: string): Promise<Integration[]> {
    return this.screebApiHelper
      .get(`/org/${orgId}/integration`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data.map<Integration>((integration: Integration) => {
            return new Integration().fromJson(integration);
          });
        } else {
          throw Error("Unexpected integrations");
        }
      });
  }

  public getIntegration(
    orgId: string,
    integrationSlug: IntegrationType,
  ): Promise<Integration> {
    return this.screebApiHelper
      .get(`/org/${orgId}/integration/${integrationSlug}`)
      .toPromise()
      .then((data: object) => {
        return new Integration().fromJson(data);
      });
  }

  public updateIntegration(
    orgId: string,
    integrationSlug: IntegrationType,
    token: string | null,
    settings: object,
  ): Promise<Integration> {
    const body = {
      token,
      settings,
    };
    return this.screebApiHelper
      .put(`/org/${orgId}/integration/${integrationSlug}`, body)
      .toPromise()
      .then((data: object) => {
        return new Integration().fromJson(data);
      });
  }

  public resetIntegrationToken(
    orgId: string,
    integrationSlug: IntegrationType,
  ): Promise<Integration> {
    return this.screebApiHelper
      .post(`/org/${orgId}/integration/${integrationSlug}/auth/reset-token`, {})
      .toPromise()
      .then((data: object) => {
        return new Integration().fromJson(data);
      });
  }

  public removeIntegration(
    orgId: string,
    integrationSlug: IntegrationType,
  ): Promise<any> {
    return this.screebApiHelper
      .delete(`/org/${orgId}/integration/${integrationSlug}`)
      .toPromise();
  }

  public forwardResponse(
    orgId: string,
    body: object,
    integrationSlug: IntegrationType,
  ): Promise<any> {
    return this.screebApiHelper
      .post(
        `/org/${orgId}/integration/${integrationSlug}/forward-response`,
        body,
      )
      .toPromise();
  }

  public triggerHookSample(
    orgId: string,
    integrationSlug: IntegrationType,
    hookType: HookType,
    webhookEndpoint: string,
    webhookVersion?: string,
  ): Promise<any> {
    const body = {
      endpoint: webhookEndpoint,
      version: webhookVersion,
    };

    return this.screebApiHelper
      .post(
        `/org/${orgId}/integration/${integrationSlug}/hook/${hookType}/sample`,
        body,
      )
      .toPromise();
  }

  // @TODO: we don't support question-level hooks for now
  public triggerHookReplay(
    orgId: string,
    integrationSlug: IntegrationType,
    hookType: HookType,
    surveyIds: string[],
    nodeCorrelationId: string | null,
    params: HookReplayParams,
  ): Promise<any> {
    const body = {
      survey_ids: surveyIds,
      node_correlation_id: nodeCorrelationId,

      from: new Date(2020, 1, 1),
      to: new Date(),

      ...params,
    };

    return this.screebApiHelper
      .post(
        `/org/${orgId}/integration/${integrationSlug}/hook/${hookType}/replay`,
        body,
      )
      .toPromise();
  }

  public slackListChannels(
    orgId: string,
  ): Promise<{ channels: IntegrationSlackChannel[] }> {
    return this.screebApiHelper
      .get<{
        channels: IntegrationSlackChannel[];
      }>(`/org/${orgId}/integration/slack/channel`)
      .toPromise();
  }

  public notionListPages(
    orgId: string,
    query: string,
  ): Promise<{ pages: IntegrationNotionPage[] }> {
    return this.screebApiHelper
      .get<{
        pages: IntegrationNotionPage[];
      }>(`/org/${orgId}/integration/notion/page?query=` + query)
      .toPromise();
  }

  public notionGetDatabase(
    orgId: string,
    databaseId: string,
  ): Promise<IntegrationNotionDatabase> {
    return this.screebApiHelper
      .get<IntegrationNotionDatabase>(
        `/org/${orgId}/integration/notion/database/${databaseId}`,
      )
      .toPromise();
  }

  public notionCreateDatabase(
    orgId: string,
    pageId: string,
    surveyId: string,
    name: string,
    version: string,
  ): Promise<IntegrationNotionDatabase> {
    const body = {
      parent_page_id: pageId,
      survey_id: surveyId,
      name,
      version,
    };

    return this.screebApiHelper
      .post<IntegrationNotionDatabase>(
        `/org/${orgId}/integration/notion/database`,
        body,
      )
      .toPromise();
  }

  public atlassianListProjects(
    orgId: string,
  ): Promise<{ projects: IntegrationAtlassianProject[] }> {
    return this.screebApiHelper
      .get<{
        projects: IntegrationAtlassianProject[];
      }>(`/org/${orgId}/integration/atlassian/project`)
      .toPromise();
  }

  public atlassianGetProject(
    orgId: string,
    projectId: string,
  ): Promise<IntegrationAtlassianProject> {
    return this.screebApiHelper
      .get<IntegrationAtlassianProject>(
        `/org/${orgId}/integration/atlassian/project/${projectId}`,
      )
      .toPromise();
  }

  public atlassianSyncProject(
    orgId: string,
    projectId: string,
    surveyId: string,
  ): Promise<IntegrationAtlassianProject> {
    const body = {
      project_id: projectId,
      survey_id: surveyId,
    };

    return this.screebApiHelper
      .post<IntegrationAtlassianProject>(
        `/org/${orgId}/integration/atlassian/project`,
        body,
      )
      .toPromise();
  }
}

export { IntegrationDao };
