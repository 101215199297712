<header class="iAppear">
  <h1>Net Promoter Score</h1>

  <div class="analytics-filters" *ngIf="!!nodes.length">
    <survey-stats-filters
      reportType="nps"
      [canExport]="false"
      [org]="org"
      [survey]="survey"
      [reporting]="'Net promoter Score'"
      [languages]="languages"
      [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
      [registryEntriesGroup]="registryEntriesGroup"
      [registryEntriesEvent]="registryEntriesEvent"
      type="response"
    >
    </survey-stats-filters>
  </div>
</header>

<div class="iAppear d100">
  <survey-stats-empty-dashboard
    *ngIf="nodes.length === 0"
    imgPath="/assets/survey-templates/nps.png"
    title="You don’t collect NPS in this {{
      uiService.isMessagePage ? 'message' : 'survey'
    }} yet"
    description1="Net Promoter Score is a great and simple way to understand your customers’level of loyality. The NPS system seeks to measure not just customer satisfaction, but it gauges whether customers like your company so much that they'd tell their friends about it."
    primaryButtonTxt="Add NPS to this {{
      uiService.isMessagePage ? 'message' : 'survey'
    }}"
    (primaryButtonCTA)="redirectBuilder()"
    secondaryButtonTxt="Preview this {{
      uiService.isMessagePage ? 'message' : 'survey'
    }}"
    (secondaryButtonCTA)="redirectBuilder()"
  >
  </survey-stats-empty-dashboard>
</div>

<ng-container *ngIf="!!nodes.length">
  <div class="loader" *ngIf="loading && initialFetch">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>

  <error-message
    *ngIf="error"
    message="An error occured when fetching data."
    [details]="error.message"
    (reload)="analyticsFilterService.triggerChange()"
  ></error-message>

  <div class="gridAppear" *ngIf="(!loading || !initialFetch) && !error">
    <nz-empty
      *ngIf="!npsCurrentPeriod || npsCurrentPeriod.total === 0"
      [nzNotFoundContent]="'No answer during this interval'"
    ></nz-empty>

    <ng-container *ngIf="npsCurrentPeriod && npsCurrentPeriod.total > 0">
      <section
        nz-row
        [nzGutter]="[32, 16]"
        class="top-indicators"
        *ngIf="!!npsCurrentPeriod"
      >
        <div nz-col nzXs="24" nzXl="12" nzXXl="6">
          <survey-stats-indicators-single-indicator
            class="indicator-with-border iAppear"
            title="Score"
            [value]="npsCurrentPeriod.score"
            [diffPercent]="npsVariation?.score"
            imageSrc="/assets/indicators/NPS-indicator-score.png"
          >
          </survey-stats-indicators-single-indicator>
        </div>
        <div nz-col nzXs="24" nzXl="12" nzXXl="6">
          <survey-stats-indicators-single-indicator
            class="indicator-with-border"
            title="Promoters"
            [value]="npsCurrentPeriod.promoters"
            [diffPercent]="npsVariation?.promoters"
            imageSrc="/assets/indicators/NPS-indicator-promoters.png"
          ></survey-stats-indicators-single-indicator>
        </div>
        <div nz-col nzXs="24" nzXl="12" nzXXl="6">
          <survey-stats-indicators-single-indicator
            class="indicator-with-border"
            title="Passives"
            [value]="npsCurrentPeriod.passives"
            [diffPercent]="npsVariation?.passives"
            imageSrc="/assets/indicators/NPS-indicator-passive.png"
          ></survey-stats-indicators-single-indicator>
        </div>
        <div nz-col nzXs="24" nzXl="12" nzXXl="6">
          <survey-stats-indicators-single-indicator
            class="indicator-with-border"
            title="Detractors"
            [value]="npsCurrentPeriod.detractors"
            [diffPercent]="npsVariation?.detractors"
            imageSrc="/assets/indicators/NPS-indicator-detractors.png"
          ></survey-stats-indicators-single-indicator>
        </div>
      </section>

      <section
        nz-row
        [nzGutter]="[32, 16]"
        class="middle-indicators"
        *ngIf="!!npsCurrentPeriod"
      >
        <div nz-col class="fullsize-indicator" nzSpan="24" nzXl="10">
          <h2>You’re right on track!</h2>
          <survey-stats-indicators-vu-meter-indicator
            [value]="npsCurrentPeriod.score"
            [ideal]="industryAverageNps"
            [min]="-100"
            [max]="100"
            [mediumRangeStart]="-33.33333"
            [highRangeStart]="33.33333"
            class="indicator-with-border industry-diff-score"
          >
          </survey-stats-indicators-vu-meter-indicator>
        </div>
        <div nz-col nzFlex="1" class="fullsize-indicator">
          <h2>Results repartition</h2>
          <div class="indicator-with-border">
            <survey-stats-indicators-repartition-indicator
              [values]="npsRepartition"
              emojiSet="nps"
              (barClick)="repartitionChartBarClick($event)"
            >
            </survey-stats-indicators-repartition-indicator>
          </div>
        </div>
      </section>

      <section nz-row [nzGutter]="[32, 16]" *ngIf="!!npsPerDateCurrentPeriod">
        <div nz-col nzSpan="24">
          <h2>Trend</h2>

          <div
            class="indicator-with-border"
            *ngIf="
              !npsPerDateCurrentPeriod || npsPerDateCurrentPeriod.length <= 1
            "
          >
            <nz-empty
              [nzNotFoundContent]="
                'Please select a longer period to display trend'
              "
            ></nz-empty>
          </div>
          <div
            class="indicator-with-border trend-indicator"
            *ngIf="
              npsPerDateCurrentPeriod && npsPerDateCurrentPeriod.length > 1
            "
          >
            <survey-stats-indicators-trend-indicator
              theme="nps"
              [datasets]="trendChartDataset"
              [legend]="trendChartLegend"
              [releases]="releases"
              [orgAccounts]="orgAccounts"
              [orgId]="org.id"
            >
            </survey-stats-indicators-trend-indicator>
          </div>
        </div>
        <!--<div nz-col nzSpan="7">
          <h2 class="live-results-title">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.4739 4.31522C12.5186 3.77863 11.8 3.56018 11.5385 4.03088L6.82552 12.5143C6.45523 13.1808 6.9372 14 7.69968 14H12.0002L11.5264 19.6847C11.4817 20.2213 12.2003 20.4397 12.4618 19.969L17.1748 11.4856C17.5451 10.8191 17.0631 9.99995 16.3007 9.99995H12.0002L12.4739 4.31522Z"
                fill="#727387" />
            </svg>
            <span>Live results</span>
          </h2>

          <div class="indicator-with-border" *ngIf="!recentResults.length">
            <nz-empty [nzNotFoundContent]="'No recent results'"></nz-empty>
          </div>
          <div class="indicator-with-border" *ngIf="recentResults.length">
            <survey-stats-indicators-live-results-indicator></survey-stats-indicators-live-results-indicator>
          </div>
        </div>-->
      </section>
    </ng-container>
  </div>

  <section *ngIf="!loading" nz-row [nzGutter]="[32, 16]">
    <div nz-col nzXs="24" nzLg="12" nzXXl="12">
      <card-101-link
        title="How to use NPS as a startup"
        minuteRead="6"
        url="https://screeb.app/how-to-get-a-great-response-rate-for-your-in-app-surveys-the-complete-guide/"
        color="var(--screeb-color-success-outline)"
      ></card-101-link>
    </div>
    <div nz-col nzXs="24" nzLg="12" nzXXl="12">
      <card-101-link
        title="NPS: Why You Should Measure It Every Quarter (And How To Do It With Screeb)"
        minuteRead="3"
        url="https://help.screeb.app/en/articles/5385254-how-to-use-segments-to-identify-insightful-feedback"
        color="var(--screeb-color-primary-outline)"
      ></card-101-link>
    </div>
  </section>
</ng-container>
