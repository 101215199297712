/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "expandable-box",
  template: `
    <div class="expandable-box" [ngClass]="{ opened }">
      <div class="expandable-box-title" (click)="onExpandClick()">
        <div class="expandable-box-title-content">
          <ng-content select="[title]"></ng-content>
        </div>
        <screeb-icon size="sm" icon="bottom-arrow"></screeb-icon>
      </div>
      <div *ngIf="opened" class="expandable-box-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      :host .expandable-box {
        background: var(--screeb-color-background);
        border: 7px solid var(--screeb-color-background-secondary);
        border-radius: 16px;
        padding: 24px;
      }
      :host .expandable-box-title {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      :host .expandable-box-title-content {
        flex: 1;
      }

      :host screeb-icon {
        color: var(--screeb-color-body-text-secondary);
        cursor: pointer;
        transition: transform 150ms;
        margin-left: 16px;
      }

      :host .opened screeb-icon {
        transform: rotate(180deg);
      }

      :host .expandable-box-content {
        margin-top: 16px;
        transform-origin: 50% 0%;
        animation: expandableContentAppear 150ms;
      }

      @keyframes expandableContentAppear {
        from {
          opacity: 0;
          margin-top: 0;
          margin-bottom: -16px;
        }
        to {
          opacity: 1;
          margin-top: 16px;
          margin-bottom: 0;
        }
      }
    `,
  ],
})
export class ExpandableBoxComponent {
  @Input() public opened = false;

  @Output() public openedChange: EventEmitter<boolean> = new EventEmitter();

  onExpandClick() {
    this.opened = !this.opened;
    this.openedChange.emit(this.opened);
  }
}
