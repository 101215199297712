import { Injectable } from "@angular/core";
import { intervalToDuration, startOfDay } from "date-fns";
import { IntegrationType } from "models/integrations.model";
import { UIService } from "services/ui.service";

type BooleanFeatureKey =
  | "team_membership"
  | "survey_capping"
  | "device_tracking"
  | "multilingual_survey"
  | "advanced_survey_customization"
  | "analytics_content_analysis"
  | "analytics_segment"
  | "session_recording"
  | "video_question"
  | "audio_answer"
  | "video_answer"
  | "response_translation"
  // integration of type "forward"
  | "integration_harvestr"
  | "integration_productboard"
  | "integration_intercom"
  | "integration_zendesk"
  | "integration_trello"
  | "integration_cycle"
  | "integration_monday"
  | "integration_asana"
  // integration of type "cdp"
  | "integration_amplitude_source"
  | "integration_segment_source"
  | "integration_rudderstack_source"
  | "integration_typeform"
  // integration of type "hook"
  | "integration_stitch"
  | "integration_webhook"
  | "integration_zapier"
  | "integration_cobbai"
  | "integration_notion"
  | "integration_atlassian"
  | "integration_email"
  | "integration_amplitude_destination"
  | "integration_segment_destination"
  | "integration_rudderstack_destination"
  // integration of type "reporting"
  | "integration_slack";

type TieredFeatureKey =
  | "max_survey"
  | "max_workspace"
  | "max_funnels"
  // "max_mtu"
  | "max_segments";

export type FeatureKey = BooleanFeatureKey | TieredFeatureKey;

const slugToEntitlement: { [key in IntegrationType]: FeatureKey } = {
  // forward
  harvestr: "integration_harvestr",
  productboard: "integration_productboard",
  intercom: "integration_intercom",
  zendesk: "integration_zendesk",
  trello: "integration_trello",
  cycle: "integration_cycle",
  monday: "integration_monday",
  asana: "integration_asana",

  // cdp
  "amplitude-source": "integration_amplitude_source",
  "segment-source": "integration_segment_source",
  "rudderstack-source": "integration_rudderstack_source",
  typeform: "integration_typeform",

  // hook
  stitch: "integration_stitch",
  webhook: "integration_webhook",
  zapier: "integration_zapier",
  cobbai: "integration_cobbai",
  notion: "integration_notion",
  atlassian: "integration_atlassian",
  email: "integration_email",
  "amplitude-destination": "integration_amplitude_destination",
  "segment-destination": "integration_segment_destination",
  "rudderstack-destination": "integration_rudderstack_destination",

  // reporting
  slack: "integration_slack",
};

type EntitlementLabel = {
  illustration: string;
  text: string;
};

export const entitlementToIllustration: {
  [key in FeatureKey]?: EntitlementLabel;
} = {
  team_membership: {
    illustration: "/upgrade/team-membership.png",
    text: "You only have access to one seat on our free plan! Upgrade now to invite all your colleagues to use Screeb with you 🚀 Collaborate on product analytics, in-app surveys and build better products together!",
  },
  max_segments: {
    illustration: "/upgrade/list-of-segments.png",
    text: "You’re using Screeb for free 🤗 In our free plan, you can only create one segment. Upgrade now to create as many segments as you want and unlock all our features!",
  },
  survey_capping: {
    illustration: "/upgrade/capping.png",
    text: "Survey Capping is a premium feature only available for our paid accounts! Upgrade now to display surveys in multiple languages and unlock the rest of our features!",
  },
  max_survey: {
    illustration: "/upgrade/list-of-surveys.png",
    text: "You’re using Screeb for free 🤗 In our free plan, you can only create one survey. Upgrade now to create as many surveys as you want and unlock all our features!",
  },
  max_funnels: {
    illustration: "/upgrade/list-of-funnels.png",
    text: "You’re using Screeb for free 🤗 In our free plan, you can only create one Funnel Report. Upgrade now to create as many Funnel Reports as you want and unlock all our features!",
  },
  multilingual_survey: {
    illustration: "/upgrade/language-preferences.png",
    text: "This is a premium feature only available for our paid accounts! Upgrade now to display surveys in multiple languages and unlock the rest of our features!",
  },
};

@Injectable()
export class EntitlementService {
  constructor(private uiService: UIService) {}

  getMTUUsagePercent() {
    // TODO(alexis): later we'll want to check on workspace level
    const mtu =
      this.uiService.currentSuperOrg?.stats.current_month_respondents ?? 0;
    const max =
      this.uiService.currentSuperOrg?.entitlements.max_mtu ?? Infinity;
    return Math.min(100, (mtu * 100) / max);
  }

  isMTUExceeded() {
    return this.uiService.currentSuperOrg?.mtuExceeded();
  }

  isMTUAlmostReached() {
    return this.getMTUUsagePercent() >= 90;
  }

  isTrialing() {
    if (this.uiService.currentSuperOrg?.flags?.billing_status !== "trial") {
      return false;
    }

    const freeTrialEndDate =
      this.uiService.currentSuperOrg?.flags?.last_trial_end_date;

    if (!freeTrialEndDate) {
      return false;
    }

    return freeTrialEndDate >= new Date();
  }

  isFreePlan() {
    return !this.hasSubscribed() && !this.isTrialing();
  }

  hasSubscribed() {
    return (
      this.uiService.currentSuperOrg?.flags?.billing_status !== "no_billing"
    );
  }

  isHavingPaymentIssue() {
    return this.uiService.currentSuperOrg?.flags?.billing_status === "paused";
  }

  getRemainingFreeTrialDays(): number {
    if (!this.uiService.currentSuperOrg?.flags?.last_trial_end_date) {
      return 0;
    }

    if (
      this.uiService.currentSuperOrg?.flags?.last_trial_end_date < new Date()
    ) {
      return 0;
    }

    return (
      intervalToDuration({
        start: startOfDay(new Date()),
        end: startOfDay(
          this.uiService.currentSuperOrg?.flags?.last_trial_end_date ??
            new Date(),
        ),
      }).days || 0
    );
  }

  isFreeTrialEnded() {
    const freeTrialEndDate =
      this.uiService.currentSuperOrg?.flags?.last_trial_end_date;

    if (!freeTrialEndDate) {
      return false;
    }

    return freeTrialEndDate < new Date();
  }

  timeSinceFreeTrialEnded() {
    if (!this.isFreeTrialEnded()) {
      return null;
    }

    return (
      new Date().getTime() -
      this.uiService.currentSuperOrg?.flags?.last_trial_end_date.getTime()
    );
  }

  isQuotaExceeded(key: TieredFeatureKey, value: number) {
    // TODO(alexis): later we'll want to check on workspace level
    const entitlements = this.uiService.currentSuperOrg?.entitlements;

    if (!entitlements) {
      return false;
    }

    switch (key) {
      case "max_survey":
        return (
          entitlements.survey_scheme === "limited" &&
          value >= entitlements.max_surveys
        );
      case "max_workspace":
        return (
          entitlements.max_workspaces !== -1 &&
          value >= entitlements.max_workspaces
        );
      case "max_funnels":
        return (
          entitlements.funnel_scheme === "limited" &&
          value >= entitlements.max_funnels
        );
      // case "max_mtu":
      //   return false; // entitlements.max_mtu < value;
      case "max_segments":
        return false; // entitlements.max_segments < value;
      default:
        return false;
    }
  }

  isAvailable(key: FeatureKey): boolean {
    // TODO(alexis): later we'll want to check on workspace level
    const entitlements = this.uiService.currentSuperOrg?.entitlements;

    if (!entitlements) {
      return false;
    }

    switch (key) {
      case "team_membership":
        return entitlements.team_membership_enabled;
      case "analytics_content_analysis":
        return entitlements.content_analysis_enabled;
      case "analytics_segment":
        return entitlements.segment_analysis_enabled;
      case "device_tracking":
        return entitlements.device_tracking_enabled;
      case "multilingual_survey":
        return entitlements.multilingual_survey_enabled;
      case "advanced_survey_customization":
        return entitlements.advanced_survey_customization_enabled;
      case "session_recording":
        return entitlements.session_recording_enabled;
      case "survey_capping":
        return entitlements.capping_enabled;
      case "video_question":
        return entitlements.video_question_enabled;
      case "audio_answer":
        return entitlements.audio_answer_enabled;
      case "video_answer":
        return entitlements.video_answer_enabled;
      case "response_translation":
        return entitlements.response_translation_enabled;
      // forward
      case "integration_harvestr":
        return Boolean(entitlements.integration_harvestr_enabled);
      case "integration_productboard":
        return Boolean(entitlements.integration_productboard_enabled);
      case "integration_intercom":
        return Boolean(entitlements.integration_intercom_enabled);
      case "integration_zendesk":
        return Boolean(entitlements.integration_zendesk_enabled);
      case "integration_trello":
        return Boolean(entitlements.integration_trello_enabled);
      case "integration_cycle":
        return Boolean(entitlements.integration_cycle_enabled);
      case "integration_monday":
        return Boolean(entitlements.integration_monday_enabled);
      case "integration_asana":
        return Boolean(entitlements.integration_asana_enabled);

      // cdp
      case "integration_amplitude_source":
        return Boolean(entitlements.integration_amplitude_source_enabled);
      case "integration_segment_source":
        return Boolean(entitlements.integration_segment_source_enabled);
      case "integration_rudderstack_source":
        return Boolean(entitlements.integration_rudderstack_source_enabled);
      case "integration_typeform":
        return Boolean(entitlements.integration_typeform_enabled);

      // hook
      case "integration_notion":
        return Boolean(entitlements.integration_notion_enabled);
      case "integration_atlassian":
        return Boolean(entitlements.integration_atlassian_enabled);
      case "integration_zapier":
        return Boolean(entitlements.integration_zapier_enabled);
      case "integration_stitch":
        return Boolean(entitlements.integration_stitch_enabled);
      case "integration_webhook":
        return Boolean(entitlements.integration_webhook_enabled);
      case "integration_cobbai":
        return Boolean(entitlements.integration_cobbai_enabled);
      case "integration_email":
        return Boolean(entitlements.integration_email_enabled);
      case "integration_amplitude_destination":
        return Boolean(entitlements.integration_amplitude_destination_enabled);
      case "integration_segment_destination":
        return Boolean(entitlements.integration_segment_destination_enabled);
      case "integration_rudderstack_destination":
        return Boolean(
          entitlements.integration_rudderstack_destination_enabled,
        );

      // reporting
      case "integration_slack":
        return Boolean(entitlements.integration_slack_enabled);
      default:
        return false;
    }
  }

  isIntegrationAvailable(type: IntegrationType): boolean {
    return this.isAvailable(slugToEntitlement[type]);
  }
}
