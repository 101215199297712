<div class="preview-panel">
  <div class="preview-panel-inner">
    <header class="preview-header">
      <div class="left">
        <h2 class="title">Preview</h2>
      </div>

      <div class="right">
        <button nz-button nzType="default" nzSize="large" (click)="onReload()">
          <i nz-icon nzType="reload"></i>
        </button>
        <button
          nz-button
          nzType="default"
          nzSize="large"
          (click)="onClose.emit($event)"
        >
          <i nz-icon nzType="close"></i>
        </button>
      </div>
    </header>

    <div class="preview-body" *ngIf="!!iframePath">
      <div class="mode">
        <nz-radio-group [(ngModel)]="mode">
          <label class="mode-button" nz-radio nzValue="desktop">
            <i nz-icon nzType="desktop" nzTheme="outline"></i>
          </label>
          <label class="mode-button" nz-radio nzValue="mobile">
            <i nz-icon nzType="mobile" nzTheme="outline"></i>
          </label>
        </nz-radio-group>
      </div>
      <!-- <div class="preview-body-content"> -->

      <iframe
        #iframePreview
        *ngIf="mode === 'desktop'"
        [src]="iframePath | sanitizeUrl"
        title="Screeb preview"
        id="screeb-iframe-container"
        allowFullscreen="false"
        scrolling="no"
        frameBorder="0"
        tabIndex="-1"
        aria-hidden="true"
      >
      </iframe>
      <div *ngIf="mode === 'mobile'" class="mobile-view">
        <div class="mobile-view-wrapper">
          <iframe
            #iframePreview
            [src]="iframePath + '&force_mobile=true' | sanitizeUrl"
            title="Screeb preview"
            id="screeb-iframe-container"
            allowFullscreen="false"
            scrolling="no"
            frameBorder="0"
            width="302"
            tabIndex="-1"
            aria-hidden="true"
          >
          </iframe>
        </div>
      </div>

      <!-- </div> -->
    </div>
  </div>
</div>

<div class="preview-panel-backdrop" (click)="onClose.emit($event)"></div>
