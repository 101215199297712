import { Channel, ChannelType } from "models/channel.model";
import { ScreebConfig } from "services/config.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { TrackingEventName } from "services/trackers.events";

export type GuideType = "screeb" | "segment" | "gtm";

type CodeInstruction = {
  instruction: string;
  code?: string;
};

type CodeOption = {
  icon?: string;
  color?: string;
  title?: string;
  steps: {
    trackerEvent?: TrackingEventName;
    title: string;
    required?: boolean;
    estimatedTime: number;
    instructions: CodeInstruction[];
    noHighlight?: boolean;
  }[];
};

type CodeBlock = {
  title: string;
  showNext?: boolean;
  description?: string;
  options?: CodeOption[];
  option?: CodeOption;
}[];

const screebJSTagCode = (config: ScreebConfig, channelId?: string) => {
  return `<!-- Screeb tag -->
<script type="text/javascript">
  (function (s,c,r,ee,b) {${
    config.platform?.length &&
    config.platform !== "global" &&
    config.platform !== "local"
      ? `
    s['ScreebConfig']=${JSON.stringify({ platform: config.platform })};`
      : ""
  }
    s['ScreebObject']=r;s[r]=s[r]||function(){var d=arguments;return new Promise(function(a,b){(s[r].q=s[r].q||[]).push({v:1,args:d,ok:a,ko:b})})};
    b=c.createElement('script');b.type='text/javascript';
    b.id=r;b.src=ee;b.async=1;c.getElementsByTagName("head")[0].appendChild(b);
  }(window,document,'$screeb','https://t.screeb.app/tag.js'));

  $screeb('init', '${channelId ?? "Channel ID"}');
</script>
<!-- End of Screeb tag -->`;
};

const getChannelByType = (channels: Channel[], type: ChannelType) =>
  channels.find((channel) => channel.type === type);

export const getGTMCodeBlocks = (
  config: ScreebConfig,
  channels: Channel[],
): CodeBlock => {
  const widgetChannelId = getChannelByType(channels, "widget")?.id;
  return [
    {
      title: "Google Tag Manager",
      showNext: true,
      option: {
        steps: [
          {
            title: "Install Screeb Tag",
            instructions: [
              {
                instruction: `
<ul>
  <li> - Navigate to the "Tags" section in your Google Tag Manager container</li>
  <li> - Click on “Add New Tag”</li>
  <li> - Name your new tag “Screeb” and click “Choose a tag …”</li>
  <li> - Navigate to the “Community Tags” by clicking on the banner above the tag list</li>
  <li> - Scroll to “Screeb” and click “Add to Workspace”</li>
  <li> - Add the “Website ID” below</li>
  <li> - Set the trigger for the Screeb snippet to “All Pages – Page View”</li>
  <li> - Publish your updated Workspace</li>
</ul>`,
                code: widgetChannelId,
              },
            ],
            estimatedTime: 1,
          },
          {
            title: "Identify your users",
            required: false,
            estimatedTime: 1,
            instructions: [
              {
                instruction: `
<ul>
  <li> - Navigate to the "Variables" section in your Google Tag Manager container</li>
  <li> - Create a few variables such as "name", "email", "user_id"...</li>
  <li> - Once user data was defined with variables, navigate to the "Triggers" section</li>
  <li> - Edit the Screeb Tag configuration and fullfil the "User ID" field with {{user_id}} (or your own variable name).</li>
  <li> - You can send additional user properties from your User-Defined Variables to Screeb.</li>
  <li> - Publish your updated Workspace</li>
</ul>`,
              },
            ],
            noHighlight: true,
          },
        ],
      },
    },
  ];
};

export const getSegmentActionCodeBlocks = (channels: Channel[]): CodeBlock => {
  const widgetChannelId = getChannelByType(channels, "widget")?.id;
  return [
    {
      title: "Segment Action",
      showNext: true,
      option: {
        steps: [
          {
            title: "",
            instructions: [
              {
                instruction: `First, go to the Segment destination catalog, and add "Screeb (action)" as a Destination. When asked for the Website ID, use this id below: <br/><br/>`,
                code: widgetChannelId,
              },
            ],
            estimatedTime: 1,
          },
        ],
      },
    },
  ];
};

export const getScreebCodeBlocks = (
  featureFlaggingService: FeatureFlaggingService,
  config: ScreebConfig,
  channels: Channel[],
  displayTagManagers: boolean,
): CodeBlock => {
  const iosChannelId = getChannelByType(channels, "ios")?.id;
  const androidChannelId = getChannelByType(channels, "android")?.id;
  const widgetChannelId = getChannelByType(channels, "widget")?.id;

  return [
    {
      title: "Web App",
      options: [
        {
          title: "JS snippet",
          icon: "snippet-logo-uncolor",
          color: "#5E21F1",
          steps: [
            {
              trackerEvent: "Javascript Tag Copied",
              title: "Install the main Screeb tag",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This small snippet of code will track basic browsing behaviour and is used to actually display the surveys. Put it before the &lt/body&gt tag in your code.",
                  code: screebJSTagCode(config, widgetChannelId),
                },
              ],
            },
            {
              trackerEvent: "Javascript Tag Copied",
              title: "Identify your users",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you display surveys to a specific group of users based on their properties and analyse responses with greater precision. It’s optional but highly recommended. ",
                  code: `<!-- Screeb tag -->
<script type="text/javascript">
  // Assign current session to a visitor identifier,
  // such as your internal id, an email address...
  $screeb('identity', '<unique-visitor-id>');

  // Set visitor properties
  // Those are examples, you can track any property
  $screeb('identity.properties', {
    // firstname: '<user-firstname>',
    // lastname: '<user-lastname>',
    // plan: '<user-plan>',
    // last_seen_at: new Date(),
    // authenticated: true,
  });
</script>
<!-- End of Screeb tag -->`,
                },
              ],
            },
            {
              trackerEvent: "Javascript Track Snippet Copied",
              title: "Track users events",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you display surveys to a specific group of users based on their actions and get advanced features like Funnel Reports. It’s optional but highly recommended.",
                  code: `<script type="text/javascript">
  $screeb('event.track', 'Name of the Event');
</script>`,
                },
              ],
            },
            {
              trackerEvent: "Javascript Group Assignation Snippet Copied",
              title: "Add users to a segment",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you add users to a segment from your code. Segments are used in advanced reports and you can display surveys to specific segments.",
                  code: `<script type="text/javascript">
  $screeb('identity.group.assign', 'company', 'Apple');
  $screeb('identity.group.assign', 'plan', 'Growth');
  $screeb('identity.group.assign', null, 'cohort-42');   // default group type
</script>`,
                },
              ],
            },
          ],
        },
        {
          title: "JS module",
          icon: "js-logo-uncolor",
          color: "#f0dc4f",
          steps: [
            {
              title: "Add dependency",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This library is published in the NPM registry and can be installed using any compatible package manager.",
                  code: `npm install @screeb/sdk-browser --save

# For Yarn, use the command below.
yarn add @screeb/sdk-browser`,
                },
              ],
              noHighlight: true,
            },
            {
              title: "Initialize Screeb (with anonymous users)",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This small snippet of code will track basic browsing behaviour and is used to actually display the surveys. <br/>Add this code at the startup of your app:",
                  code: `import * as Screeb from "@screeb/sdk-browser";

Screeb.load();
Screeb.init('${widgetChannelId}');`,
                },
              ],
              noHighlight: false,
            },
            {
              title: "Initialize Screeb (with identified users)",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you display surveys to a specific group of users based on their properties and analyse responses with greater precision. It's optional but highly recommended. <br/>Add this code at the startup of your app:",
                  code: `import * as Screeb from "@screeb/sdk-browser";

Screeb.load();
Screeb.init(
  '${widgetChannelId}',
  // Assign current session to a visitor identifier,
  // such as your internal id, an email address...
  '<unique-visitor-id>',
  // Set visitor properties
  // Those are examples, you can track any property
  {
    // firstname: '<user-firstname>',
    // lastname: '<user-lastname>',
    // plan: '<user-plan>',
    // last_seen_at: new Date(),
    // authenticated: true,
  }
);`,
                },
              ],
              noHighlight: false,
            },
            {
              title: "Track users events",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you display surveys to a specific group of users based on their actions and get advanced features like Funnel Reports. It's optional but highly recommended. <br/>Add this code in your app:",
                  code: `import * as Screeb from "@screeb/sdk-browser";

Screeb.eventTrack("Name of the Event");`,
                },
              ],
              noHighlight: false,
            },
            {
              title: "Add users to a segment",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you add users to a segment from your code. Segments are used in advanced reports and you can display surveys to specific segments. <br/>Add this code in your app:",
                  code: `import * as Screeb from "@screeb/sdk-browser";

Screeb.identityGroupAssign("company", "Apple");
Screeb.identityGroupAssign("plan", "Growth");
Screeb.identityGroupAssign(null, "cohort-42"); // default group type`,
                },
              ],
              noHighlight: false,
            },
          ],
        },
        {
          title: "React",
          icon: "react-logo-uncolor",
          color: "#00d8ff",
          steps: [
            {
              title: "Add dependency",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This library is published in the NPM registry and can be installed using any compatible package manager.",
                  code: `npm install @screeb/sdk-react --save

# For Yarn, use the command below.
yarn add @screeb/sdk-react`,
                },
              ],
              noHighlight: true,
            },
            {
              title: "Initialize Screeb (with anonymous users)",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This small snippet of code will track basic browsing behaviour and is used to actually display the surveys. <br/>Add this code at the top of your app:",
                  code: `import * as React from 'react';

import { ScreebProvider } from '@screeb/sdk-react';

const App = () => (
  <ScreebProvider autoInit websiteId="${widgetChannelId}">
    <HomePage />
  </ScreebProvider>
);`,
                },
              ],
              noHighlight: false,
            },
            {
              title: "Initialize Screeb (with identified users)",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you display surveys to a specific group of users based on their properties and analyse responses with greater precision. It's optional but highly recommended. <br/>Add this code at the top of your app:",
                  code: `import * as React from 'react';

import { ScreebProvider } from '@screeb/sdk-react';

// Assign current session to a visitor identifier,
// such as your internal id, an email address...
const userId = '<unique-visitor-id>';

// Set visitor properties
// Those are examples, you can track any property
const userProperties = {
  // firstname: '<user-firstname>',
  // lastname: '<user-lastname>',
  // plan: '<user-plan>',
  // last_seen_at: new Date(),
  // authenticated: true,
};

const App = () => (
  <ScreebProvider
    autoInit
    websiteId="${widgetChannelId}"
    userId={userId}
    userProperties={userProperties}
  >
    <HomePage />
  </ScreebProvider>
);`,
                },
              ],
              noHighlight: false,
            },
            {
              title: "Track users events",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you display surveys to a specific group of users based on their actions and get advanced features like Funnel Reports. It's optional but highly recommended. <br/>Add this code in your app:",
                  code: `import * as React from 'react';

import { useScreeb } from '@screeb/sdk-react';

const HomePage = () => {
  const { eventTrack } = useScreeb();

  const onButtonClicked = React.useCallback(
    () => eventTrack("Name of the Event");
  );

  return (
    <button onClick={onButtonClicked}>
      Track event!
    </button>
  );
};`,
                },
              ],
              noHighlight: false,
            },
            {
              title: "Add users to a segment",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you add users to a segment from your code. Segments are used in advanced reports and you can display surveys to specific segments. <br/>Add this code in your app:",
                  code: `import { useScreeb } from '@screeb/sdk-react';

// Anywhere in your app:
React.useEffect(() => {
  identityGroupAssign("company", "Apple");
  identityGroupAssign("plan", "Growth");
  identityGroupAssign(null, "cohort-42"); // default group type
}, [identityGroupAssign]);`,
                },
              ],
              noHighlight: false,
            },
          ],
        },
        {
          title: "Angular",
          icon: "angular-logo-uncolor",
          color: "#DD0031",
          steps: [
            {
              title: "Add dependency",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This library is published in the NPM registry and can be installed using any compatible package manager.",
                  code: `npm install @screeb/sdk-angular --save

# For Yarn, use the command below.
yarn add @screeb/sdk-angular`,
                },
              ],
              noHighlight: true,
            },
            {
              title: "Initialize Screeb (with anonymous users)",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This small snippet of code will track basic browsing behaviour and is used to actually display the surveys. <br/>Add this code in your app bootstrap and/or app module:",
                  code: `import { ScreebModule } from "@screeb/sdk-angular";

@NgModule({
  imports: [
    // ...
    ScreebModule.forRoot({
      autoInit: true,
      websiteId: "${widgetChannelId}",
    })
    // ...
  ]
})
export class AppModule {}`,
                },
              ],
              noHighlight: false,
            },
            {
              title: "Initialize Screeb (with identified users)",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you display surveys to a specific group of users based on their properties and analyse responses with greater precision. It's optional but highly recommended. <br/>Add this code at the top of your app:",
                  code: `import { ScreebModule } from "@screeb/sdk-angular";

@NgModule({
  imports: [
    // ...
    ScreebModule.forRoot({
      autoInit: true,
      websiteId: "${widgetChannelId}",
      // Assign current session to a visitor identifier,
      // such as your internal id, an email address...
      userId: "<unique-visitor-id>",
      // Set visitor properties
      // Those are examples, you can track any property
      userProperties: {
        // firstname: '<user-firstname>',
        // lastname: '<user-lastname>',
        // plan: '<user-plan>',
        // last_seen_at: new Date(),
        // authenticated: true,
      }
    })
    // ...
  ]
})
export class AppModule {}`,
                },
              ],
              noHighlight: false,
            },
            {
              title: "Track users events",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you display surveys to a specific group of users based on their actions and get advanced features like Funnel Reports. It's optional but highly recommended. <br/>Add this code in any module of your app:",
                  code: `import { Component, OnInit } from '@angular/core';
import { ScreebModule } from "@screeb/sdk-angular";

@Component({
  selector: 'app',
  template: \`...\`
})
export class AppComponent implements OnInit {
  constructor(
    public screeb: Screeb
  ){}

  ngOnInit() {
    this.screeb.eventTrack("Name of the Event");
  }
}`,
                },
              ],
              noHighlight: false,
            },
            {
              title: "Add users to a segment",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "This step will let you add users to a segment from your code. Segments are used in advanced reports and you can display surveys to specific segments. <br/>Add this code in any module of your app:",
                  code: `import { Component, OnInit } from '@angular/core';
import { ScreebModule } from "@screeb/sdk-angular";

@Component({
  selector: 'app',
  template: \`...\`
})
export class AppComponent implements OnInit {
  constructor(
    public screeb: Screeb
  ){}

  ngOnInit() {
    this.screeb.identityGroupAssign("company", "Apple");
    this.screeb.identityGroupAssign("plan", "Growth");
    this.screeb.identityGroupAssign(null, "cohort-42"); // default group type
  }
}`,
                },
              ],
              noHighlight: false,
            },
          ],
        },
        !displayTagManagers
          ? null
          : {
              title: "Segment Action",
              icon: "segment-logo-uncolor",
              color: "#52bd94",
              steps: [
                {
                  title: "Add Screeb destination",
                  required: true,
                  estimatedTime: 1,
                  instructions: [
                    {
                      instruction: `
<ul>
<li> - Go to your <a href="https://app.segment.com/" target="_blank">Segment workspace</a></li>
<li> - Navigate to <strong>Catalog > Destinations</strong></li>
<li> - Search for <strong>Screeb (Actions)</strong> and click it</li>
<li> - Click on <strong>Add destination</strong></li>
</ul>`,
                    },
                  ],
                  noHighlight: true,
                },
                {
                  title: "Configure Screeb destination",
                  required: true,
                  estimatedTime: 1,
                  instructions: [
                    {
                      instruction: `
<ul>
  <li> - Go to your <strong>Screeb (Actions)</strong> destination settings</li>
  <li> - In <strong>Website ID</strong> field, input the ID below</li>
  <li> - Enable destination</li>
  <li> - Save changes</li>
</ul>`,
                      code: widgetChannelId,
                    },
                  ],
                  noHighlight: true,
                },
              ],
            },
        !displayTagManagers
          ? null
          : {
              title: "GTM",
              icon: "gtm-logo-uncolor",
              color: "#4CBDEF",
              steps: [
                {
                  title: "Install Screeb Tag",
                  required: true,
                  estimatedTime: 1,
                  instructions: [
                    {
                      instruction: `
<ul>
  <li> - Navigate to the "Tags" section in your Google Tag Manager container</li>
  <li> - Click on “Add New Tag”</li>
  <li> - Name your new tag “Screeb” and click “Choose a tag …”</li>
  <li> - Navigate to the “Community Tags” by clicking on the banner above the tag list</li>
  <li> - Scroll to “Screeb” and click “Add to Workspace”</li>
  <li> - Add the “Website ID” below</li>
  <li> - Set the trigger for the Screeb snippet to “All Pages – Page View”</li>
  <li> - Publish your updated Workspace</li>
</ul>`,
                      code: widgetChannelId,
                    },
                  ],
                  noHighlight: true,
                },
                {
                  title: "Identify your users",
                  required: false,
                  estimatedTime: 1,
                  instructions: [
                    {
                      instruction: `
<ul>
  <li> - Navigate to the "Variables" section in your Google Tag Manager container</li>
  <li> - Create a few variables such as "name", "email", "user_id"...</li>
  <li> - Once user data was defined with variables, navigate to the "Triggers" section</li>
  <li> - Edit the Screeb Tag configuration and fullfil the "User ID" field with {{user_id}} (or your own variable name).</li>
  <li> - You can send additional user properties from your User-Defined Variables to Screeb.</li>
  <li> - Publish your updated Workspace</li>
</ul>`,
                    },
                  ],
                  noHighlight: true,
                },
              ],
            },
      ],
    },
    {
      title: "Mobile App",
      options: [
        {
          title: "Native iOS",
          icon: "ios-logo-uncolor",
          color: "#000000",
          steps: [
            ...(config.platform?.length &&
            config.platform !== "global" &&
            config.platform !== "local"
              ? [
                  {
                    title: "Add platform to Info.plist",
                    required: true,
                    estimatedTime: 1,
                    instructions: [
                      {
                        instruction: "Add this key to your Info.plist file:",
                        code: `<key>ScreebPlatform</key>
<string>${config.platform}</string>`,
                      },
                    ],
                    noHighlight: true,
                  },
                ]
              : []),
            {
              title: "Add dependency",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction: "First, add this dependency to your Podfile:",
                  code: `pod "Screeb", "2.x.x"`,
                },
              ],
              noHighlight: true,
            },
            {
              trackerEvent: "IOS Tag Copied",
              title: "Install the main Screeb tag",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "<strong>Paste this code</strong> into the main controller of your app.",
                  code: `@UIApplicationMain
class AppDelegate: UIResponder, UIApplicationDelegate {
    // [..]
    func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
        Screeb.initSdk(
            context: nil,
            channelId: "${iosChannelId}",
            // identity: "<unique-visitor-id>",
            // visitorProperty: [
            //   "firstname": AnyEncodable("<user-firstname>"),
            //   "lastname": AnyEncodable("<user-lastname>"),
            //   "plan": AnyEncodable("<user-plan>"),
            //   "age": AnyEncodable(30),
            //   "last_seen_at": AnyEncodable(Date()),
            //   "authenticated": AnyEncodable(true)
            // ]
        )
        return true
    }
    // [..]
}`,
                },
              ],
            },
            ...(featureFlaggingService.isContextualMessagesMobileEnabled()
              ? [
                  {
                    title: "Add configuration for In-App Messaging",
                    required: false,
                    estimatedTime: 5,
                    instructions: [
                      {
                        instruction:
                          "<strong>Paste this code</strong> into your Info.plist file.",
                        code: `<key>CFBundleURLTypes</key>
<array>
  <dict>
    <key>CFBundleTypeRole</key>
    <string>Viewer</string>
    <key>CFBundleURLName</key>
    <string>screeb</string>
    <key>CFBundleURLSchemes</key>
    <array>
      <string>screeb-${iosChannelId}</string>
    </array>
  </dict>
</array>`,
                      },
                      {
                        instruction: "If using AppDelegate, add this code:",
                        code: `@UIApplicationMain
class AppDelegate: UIResponder, UIApplicationDelegate {
    func application(_ app: UIApplication, open url: URL, options: [UIApplication.OpenURLOptionsKey: Any]) -> Bool {
      Screeb.handleDeepLink(url: url)
      return true
    }`,
                      },
                      {
                        instruction:
                          "Or if using SceneDelegate, add this code:",
                        code: `class SceneDelegate: UIResponder, UIWindowSceneDelegate {
  func scene(_ scene: UIScene, openURLContexts URLContexts: Set<UIOpenURLContext>) {
      Screeb.handleDeepLink(url: URLContexts.first?.url)
  }`,
                      },
                    ],
                    noHighlight: true,
                  },
                ]
              : []),
            {
              trackerEvent: "Channel Id Copied",
              title: "Custom install",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction: `If you prefer to use our <a href="https://developers.screeb.app/sdk-ios/install" target="_blank">Developer Documentation</a>, here is the Channel ID you have to use:`,
                  code: iosChannelId,
                },
              ],
              noHighlight: true,
            },
          ],
        },
        {
          title: "Native Android",
          icon: "android-logo-uncolor",
          color: "#3DDC84",
          steps: [
            ...(config.platform?.length &&
            config.platform !== "global" &&
            config.platform !== "local"
              ? [
                  {
                    title: "Add platform to AndroidManifest.xml",
                    required: true,
                    estimatedTime: 1,
                    instructions: [
                      {
                        instruction:
                          "Add this meta-data to your AndroidManifest.xml file:",
                        code: `<meta-data
  android:name="app.screeb.sdk.PLATFORM"
  android:value="${config.platform}" />`,
                      },
                    ],
                    noHighlight: true,
                  },
                ]
              : []),
            {
              title: "Add dependency",
              required: true,
              instructions: [
                {
                  instruction:
                    "First, add this dependency in your app build.gradle:",
                  code: `implementation 'app.screeb.sdk:survey:2.x.x'`,
                },
              ],
              estimatedTime: 1,
              noHighlight: true,
            },
            {
              trackerEvent: "Android Tag Copied",
              title: "Install the main Screeb tag",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "<strong>Paste this code</strong> into the main controller of your app.",
                  code: `import app.screeb.sdk.Screeb
// Init Screeb Android SDK
Screeb.initSdk(this,
  "${androidChannelId}",
  // "<unique-user-id>",
  // VisitorProperties().apply {
  //     this["firstname"] = "<user-firstname>"
  //     this["lastname"] = "<user-lastname>"
  //     this["plan"] = "<user-plan>"
  //     this["age"] = 42
  //     this["logged_at"] = Date()
  //     this["authenticated"] = true
  // },
)`,
                },
              ],
            },
            {
              trackerEvent: "Channel Id Copied",
              title: "Custom install",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction: `If you prefer to use our <a href="https://developers.screeb.app/sdk-android/install" target="_blank">Developer Documentation</a>, here is the Channel ID you have to use:`,
                  code: androidChannelId,
                },
              ],
              noHighlight: true,
            },
          ],
        },
        {
          title: "Flutter",
          icon: "flutter-logo-uncolor",
          color: "#61DAFB",
          steps: [
            ...(config.platform?.length &&
            config.platform !== "global" &&
            config.platform !== "local"
              ? [
                  {
                    title: "Add platform to both platforms",
                    required: true,
                    estimatedTime: 1,
                    instructions: [
                      {
                        instruction: "Add this key to your Info.plist file:",
                        code: `<key>ScreebPlatform</key>
<string>${config.platform}</string>`,
                      },
                      {
                        instruction:
                          "Add this meta-data to your AndroidManifest.xml file:",
                        code: `<meta-data
  android:name="app.screeb.sdk.PLATFORM"
  android:value="${config.platform}" />`,
                      },
                    ],
                    noHighlight: true,
                  },
                ]
              : []),
            {
              title: "Add package",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction: "First, add the Screeb package to your app.",
                  code: "flutter pub add plugin_screeb",
                },
              ],
              noHighlight: true,
            },
            {
              trackerEvent: "Flutter Tag Copied",
              title: "Install the main Screeb tag",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "<strong>Paste this code</strong> into the main controller of your app.",
                  code: `PluginScreeb.initSdk(
    "${androidChannelId}",
    "${iosChannelId}",
    // "<user-identity>",
    // <String, dynamic>{
    //   'firstname': '<user-firstname>',
    //   'lastname': '<user-lastname>',
    //   'plan': '<user-plan>',
    //   'age': 30,
    //   'last_seen_at': DateTime.now(),
    //   'authenticated': true
    // }
);`,
                },
              ],
            },
            {
              trackerEvent: "Channel Id Copied",
              title: "Custom install",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction: `If you prefer to use our <a href="https://developers.screeb.app/sdk-flutter/install" target="_blank">Developer Documentation</a>, here is the Channel ID you have to use:<br/>For Android:`,
                  code: `${androidChannelId}`,
                },
                {
                  instruction: `For iOS:`,
                  code: `${iosChannelId}`,
                },
              ],
              noHighlight: true,
            },
          ],
        },
        {
          title: "ReactNative",
          icon: "react-native-logo-uncolor",
          color: "#01579B",
          steps: [
            ...(config.platform?.length &&
            config.platform !== "global" &&
            config.platform !== "local"
              ? [
                  {
                    title: "Add platform to both platforms",
                    required: true,
                    estimatedTime: 1,
                    instructions: [
                      {
                        instruction: "Add this key to your Info.plist file:",
                        code: `<key>ScreebPlatform</key>
<string>${config.platform}</string>`,
                      },
                      {
                        instruction:
                          "Add this meta-data to your AndroidManifest.xml file:",
                        code: `<meta-data
  android:name="app.screeb.sdk.PLATFORM"
  android:value="${config.platform}" />`,
                      },
                    ],
                    noHighlight: true,
                  },
                ]
              : []),
            {
              title: "Add package",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction: "First, add the Screeb package to your app.",
                  code: "npm install @screeb/react-native",
                },
              ],
              noHighlight: true,
            },
            {
              trackerEvent: "ReactNative Tag Copied",
              title: "Install the main Screeb tag",
              required: true,
              estimatedTime: 1,
              instructions: [
                {
                  instruction:
                    "<strong>Paste this code</strong> into the main controller of your app.",
                  code: `import { initSdk, trackScreen, trackEvent, setProperties, setIdentity } from "@screeb/react-native";

React.useEffect(() => {
   initSdk(
      "${androidChannelId}",
      "${iosChannelId}",
      // "<user-identity>",
      // {
      //     firstname: "<user-firstname>",
      //     lastname: "<user-lastname>",
      //     plan: "<user-plan>",
      //     age: 30,
      //     last_seen_at: new Date(),
      //     authenticated: true
      // }
   );
}, []);`,
                },
              ],
            },
            {
              trackerEvent: "Channel Id Copied",
              title: "Custom install",
              required: false,
              estimatedTime: 1,
              instructions: [
                {
                  instruction: `If you prefer to use our <a href="https://developers.screeb.app/sdk-react-native/install" target="_blank">Developer Documentation</a>, here is the Channel ID you have to use:<br/>For Android:`,
                  code: `${androidChannelId}`,
                },
                {
                  instruction: `For iOS:`,
                  code: `${iosChannelId}`,
                },
              ],
              noHighlight: true,
            },
          ],
        },
      ],
    },
  ];
};
