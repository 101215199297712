import { Component, Input } from "@angular/core";
import { Account } from "models/account.model";
import { UIService } from "services/ui.service";

@Component({
  selector: "org-account-avatar-list",
  template: `
    <div [class]="'org-account-avatar-list ' + theme + ' ' + size">
      <org-account-avatar
        [class]="{ inactive: !account.isActive }"
        *ngFor="let account of getAccounts()"
        [account]="account"
        [size]="size"
      ></org-account-avatar>
      <div
        class="avatar-more"
        *ngIf="accounts.length > maxDisplayed"
        [style.background-image]="
          'url(' + accounts[maxDisplayed].profile_picture + ')'
        "
      >
        <div
          class="avatar-more-label"
          nz-tooltip
          [nzTooltipTitle]="getTooltip()"
        >
          +{{ accounts.length - maxDisplayed + 1 }}
        </div>
      </div>
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/settings/team-members"
        class="avatar-add"
        *ngIf="accounts.length < 3"
        nz-tooltip
        nzTooltipTitle="Invite team members"
      >
        +
      </a>
    </div>
  `,
  styles: [
    `
      .org-account-avatar-list {
        display: flex;
        justify-content: center;
      }
      .org-account-avatar-list org-account-avatar.inactive {
        opacity: 0.66;
      }
      .org-account-avatar-list org-account-avatar {
        mask: radial-gradient(circle at 40px, transparent 18px, black 18px);
      }
      .org-account-avatar-list org-account-avatar + org-account-avatar {
        margin-left: -8px;
      }
      .medium.org-account-avatar-list org-account-avatar {
        mask: radial-gradient(circle at 52px, transparent 22px, black 22px);
      }
      .org-account-avatar-list org-account-avatar:last-child {
        mask: none;
      }
      .org-account-avatar-list .avatar-more,
      .org-account-avatar-list .avatar-add {
        position: relative;
        margin-left: -8px;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .org-account-avatar-list .avatar-add {
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--screeb-color-success-active);
        content: " ";
        border-radius: 32px;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-white);
        cursor: pointer;
      }
      .dark.org-account-avatar-list .avatar-add {
        background: var(--screeb-color-background);
        color: var(--screeb-color-body-text);
      }
      .medium.org-account-avatar-list .avatar-add {
        border-radius: 40px;
        font-size: 20px;
        width: 40px;
        height: 40px;
      }
      .medium.org-account-avatar-list .avatar-more {
        border-radius: 40px;
        width: 40px;
        height: 40px;
      }
      .org-account-avatar-list .avatar-add:hover {
        opacity: 0.8;
      }
      .org-account-avatar-list .avatar-more-label {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
        content: " ";
        border-radius: 32px;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: var(--screeb-color-white);
      }
      .medium.org-account-avatar-list .avatar-more-label {
        font-size: 20px;
      }
    `,
  ],
})
export class OrgAccountAvatarListComponent {
  @Input() public accounts: Account[] = null;
  @Input() public maxDisplayed = 6;
  @Input() public theme: "dark" | "light" = "light";
  @Input() public size: "small" | "medium" = "small";

  constructor(public uiService: UIService) {}

  getAccounts() {
    const accounts = this.accounts.sort(
      (a, b) => Number(b.isActive) - Number(a.isActive),
    );

    if (accounts.length > this.maxDisplayed) {
      return accounts.slice(0, this.maxDisplayed - 1);
    }

    return accounts;
  }

  getTooltip() {
    return this.accounts
      .slice(this.maxDisplayed - 1)
      .map(({ fullname }) => fullname)
      .join(", ");
  }
}
