import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { ApplicationLoadService } from "services/app.service";
import { NotificationHelper } from "helpers/notification.helper";
import { HttpErrorResponse } from "@angular/common/http";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";
import { OrgDao } from "models/org.dao";

@Injectable()
export class OrgTargetingRulesResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private orgDao: OrgDao,
    private notificationHelper: NotificationHelper,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<SurveyTargetingRule[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.orgDao.getAllTargetingRulesByOrgId(route.params["org_id"]);
      })
      .catch((err: HttpErrorResponse) => {
        // this.notificationHelper.trigger("Failed to fetch channels", null, 'error');
        console.error(err.error);
        throw err;
      });
  }
}
