import { Pipe, PipeTransform } from "@angular/core";
import { ResponseCompletion } from "models/response.model";

@Pipe({ name: "responseCompletion" })
export class ResponseCompletionPipe implements PipeTransform {
  constructor() {}

  transform(responseCompletion: ResponseCompletion) {
    switch (responseCompletion) {
      case "not_started":
        return "Displayed";
      case "partially_completed":
        return "Partially responded";
      case "fully_completed":
        return "Fully responded";
      default:
        "Unknown";
    }
  }
}
