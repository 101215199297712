<div>
  <header>
    <h1>Data governance</h1>
  </header>

  <section class="org-settings retention">
    <h2>Data retention</h2>

    <div class="banner-warning">
      <span>⚠️ This is a destructive operation</span>
    </div>

    <div nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-retention-selector
          what="User properties"
          [(value)]="org.data_governance.retention_policy_identity_days"
        ></settings-data-governance-retention-selector>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-retention-selector
          what="User segments"
          [(value)]="org.data_governance.retention_policy_group_days"
        ></settings-data-governance-retention-selector>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-retention-selector
          what="User events"
          [(value)]="org.data_governance.retention_policy_event_days"
        ></settings-data-governance-retention-selector>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-retention-selector
          what="User responses"
          [(value)]="org.data_governance.retention_policy_response_days"
        ></settings-data-governance-retention-selector>
      </div>
    </div>
  </section>

  <section class="org-settings retention">
    <h2>Registry limits</h2>
    <div nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-registry-limits
          what="Contextual properties"
          [current]="0"
          [max]="org.data_governance.registry_limit_context_property"
        ></settings-data-governance-registry-limits>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-registry-limits
          what="User properties"
          [current]="0"
          [max]="org.data_governance.registry_limit_identity_property"
        ></settings-data-governance-registry-limits>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-registry-limits
          what="Segment types"
          [current]="0"
          [max]="org.data_governance.registry_limit_group_type"
        ></settings-data-governance-registry-limits>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-registry-limits
          what="Segment properties"
          [current]="0"
          [max]="org.data_governance.registry_limit_group_type_property"
        ></settings-data-governance-registry-limits>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-registry-limits
          what="Segments"
          [current]="0"
          [max]="org.data_governance.registry_limit_group"
        ></settings-data-governance-registry-limits>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-registry-limits
          what="Track events"
          [current]="0"
          [max]="org.data_governance.registry_limit_track"
        ></settings-data-governance-registry-limits>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-registry-limits
          what="Track event properties"
          [current]="0"
          [max]="org.data_governance.registry_limit_track_property"
        ></settings-data-governance-registry-limits>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-registry-limits
          what="Screen events"
          [current]="0"
          [max]="org.data_governance.registry_limit_screen"
        ></settings-data-governance-registry-limits>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-registry-limits
          what="Screen event properties"
          [current]="0"
          [max]="org.data_governance.registry_limit_screen_property"
        ></settings-data-governance-registry-limits>
      </div>
    </div>
  </section>

  <section class="org-settings tracking-capabilities">
    <h2>Tracking capabilities</h2>

    <div class="label">User tagging</div>
    <div nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Cohort tracking"
          [(value)]="org.data_governance.tracking_capability_cohort_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
    </div>

    <div class="label">Navigation</div>
    <div nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Page load"
          [(value)]="org.data_governance.tracking_capability_page_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Screen load"
          [(value)]="org.data_governance.tracking_capability_screen_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
    </div>

    <div class="label">Context</div>
    <div nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Device description (user agent, browser, device OS...)"
          [(value)]="org.data_governance.tracking_capability_device_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="IP capture"
          [(value)]="org.data_governance.tracking_capability_ip_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="GeoIP (IP will be used but not stored)"
          [(value)]="org.data_governance.tracking_capability_geoip_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
    </div>

    <div class="label">Clicks / Taps</div>
    <div nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Clicks"
          [(value)]="org.data_governance.tracking_capability_click_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Taps"
          [(value)]="org.data_governance.tracking_capability_tap_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Copy"
          [(value)]="org.data_governance.tracking_capability_copy_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Paste"
          [(value)]="org.data_governance.tracking_capability_paste_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Highlight"
          [(value)]="org.data_governance.tracking_capability_highlight_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Zoom"
          [(value)]="org.data_governance.tracking_capability_zoom_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Rage click"
          [(value)]="org.data_governance.tracking_capability_rage_click_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Dead click"
          [(value)]="org.data_governance.tracking_capability_dead_click_enabled"
        ></settings-data-governance-tracking-capability>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <settings-data-governance-tracking-capability
          what="Trashing click"
          [(value)]="
            org.data_governance.tracking_capability_thrashing_click_enabled
          "
        ></settings-data-governance-tracking-capability>
      </div>
    </div>
  </section>

  <footer>
    <button
      nz-button
      [nzLoading]="loading"
      (click)="save()"
      nzType="primary"
      nzSize="large"
    >
      Save
    </button>
    <utils-form-error [errors]="[errors?.message]"></utils-form-error>
  </footer>
</div>
