<button
  nz-button
  nzType="default"
  [nzSize]="!isSmall ? 'large' : 'small'"
  (click)="showModal()"
>
  <i *ngIf="!isSmall" nz-icon nzType="export" nzTheme="outline"></i>
  <i *ngIf="isSmall" nz-icon nzType="plus" nzTheme="outline"></i>
  <ng-container *ngIf="!isSmall">Add to segment</ng-container>
</button>

<upgrade-modal
  [orgId]="org.id"
  [centeredModal]="true"
  [withOverlay]="true"
  (closeClick)="isUpgradeModalVisible = false"
  *ngIf="isUpgradeModalVisible"
></upgrade-modal>

<nz-modal
  nzTitle="Add to segment(s)"
  [(nzVisible)]="isModalVisible"
  [nzOkLoading]="loading"
  [nzOkDisabled]="!isValid()"
  nzOkType="primary"
  nzOkText="Add to segment"
  (nzOnOk)="handleOk()"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <nz-select
      #segmentNameElement
      class="select-segment"
      nzPlaceHolder="Select existing segment or create new one"
      nzSize="large"
      nzMode="tags"
      [nzOptions]="userGroups"
      [nzDropdownMatchSelectWidth]="false"
      [(ngModel)]="selectedUserGroups"
      (ngModelChange)="onModelChange($event)"
    >
    </nz-select>

    <p style="margin-top: 12px">
      Add this user to a segment to analyse its behaviour and share in-app
      surveys along its journey.
    </p>

    <p class="error" *ngIf="error">{{ error }}</p>
  </ng-container>
</nz-modal>
