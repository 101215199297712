<big-checkbox
  class="big-checkbox"
  label="Via Notion"
  [content]="notionBigCheckbox"
  [(checked)]="enabled"
  (checkedChange)="onEnabledChange($event)"
  [disabled]="!quotaOk"
  iconUrl="/assets/icons/integrations/notion-icon.svg"
  name="notionEnabled"
  ngDefaultControl
  collapseWhenNotChecked
>
  <ng-template #notionBigCheckbox>
    <div class="notion-tip" *ngIf="integrationNotionStatus === 'not_installed'">
      <span>No Notion integration installed!<br />Go to </span>
      <a routerLink="/org/{{ org.id }}/integrations"> integrations </a>
      <span>to install one.</span>
    </div>

    <div
      class="notion-tip"
      *ngIf="
        integrationNotionStatus === 'error' ||
        integrationNotionStatus === 'not_authed'
      "
    >
      <p class="error">Something went wrong with Notion integration!</p>
      <p>
        Go to your
        <a routerLink="/org/{{ org.id }}/integrations/notion">
          Notion integration
        </a>
        to check your connection.
      </p>
    </div>

    <div
      *ngIf="
        integrationNotionStatus === 'loading' ||
        integrationNotionStatus === 'ok'
      "
    >
      <!-- fetching db -->
      <div class="loader" *ngIf="fetchingDatabase">
        <nz-spin nzSimple [nzSize]="'medium'"></nz-spin>
      </div>

      <!-- failed to fetch selected db -->
      <div *ngIf="notionDatabaseId && databaseError && !fetchingDatabase">
        <span class="error">Something went wrong with Notion database!</span>
        <div class="right">
          <button
            nz-button
            nzType="default"
            nzSize="small"
            nzDanger
            (click)="remove()"
          >
            <i nz-icon nzType="delete"></i>
            Remove
          </button>
        </div>
      </div>

      <!-- db selected -->
      <div
        *ngIf="notionDatabaseId && !databaseError && !fetchingDatabase"
        class="notion-version-{{ notionVersion }}"
      >
        <label>
          <span>Share to Notion database &nbsp;</span>
          <!-- <nz-tag nzColor="#00C898">
            <span class="tag">v{{ notionVersion }}</span>
          </nz-tag> -->
        </label>
        <nz-input-group
          class="notion-database"
          nzSearch
          [nzAddOnAfter]="suffixIconButton"
        >
          <a [href]="database?.url" target="_blank">
            <input
              type="text"
              nz-input
              [value]="database?.name"
              [disabled]="true"
            />
          </a>
        </nz-input-group>
        <ng-template #suffixIconButton>
          <button
            nz-button
            nzType="primary"
            nzDanger
            nzSearch
            (click)="remove()"
          >
            <i nz-icon nzType="delete"></i>
          </button>
        </ng-template>
        <div
          class="right"
          *ngIf="'isNotionReplayButtonVisible' | hasFeatureFlag"
        >
          <button
            class="sync"
            nz-button
            nzType="primary"
            [nzLoading]="replaying"
            (click)="triggerReplay()"
          >
            Sync
          </button>
        </div>
      </div>

      <!-- select a page for creating db -->
      <div *ngIf="!notionDatabaseId">
        <nz-select
          nzShowSearch
          nzServerSearch
          nzPlaceHolder="Select a parent page"
          [nzLoading]="integrationNotionStatus === 'loading'"
          [nzOptions]="pages"
          [(ngModel)]="page"
          (nzOnSearch)="onSearch($event)"
          nzSize="large"
          class="notion-pages-select"
        >
        </nz-select>
        <div class="right">
          <button
            class="create-btn"
            nz-button
            nzType="primary"
            [nzLoading]="creating"
            [disabled]="!page"
            (click)="createDatabase()"
          >
            Create database into this page
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</big-checkbox>
