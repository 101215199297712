<h3 class="title">{{ title }}</h3>

<div class="company-summary">
  <div class="soon-cover" *ngIf="soon">
    <div class="soon-text">Soon</div>
  </div>
  <div class="company-content">
    <div class="industry-score">
      <span>{{ companyName }}</span>
      <span class="score"
        >{{ companyScore.toFixed(1) }}{{ isPercentage ? " %" : "" }}</span
      >
    </div>
    <div
      class="compare-badge"
      [style]="{
        backgroundColor: diffColor
      }"
    >
      {{ scoreDiff }}% than your industry
    </div>
  </div>
  <div class="industry-content">
    <div class="industry-score">
      <span>Competitors</span>
      <span class="score"
        >{{ competitorScore.toFixed(1) }}{{ isPercentage ? " %" : "" }}</span
      >
    </div>
    <div class="separator"></div>
    <div class="industry-score">
      <span>Top Performer</span>
      <span class="score"
        >{{ topPerformerScore.toFixed(1) }}{{ isPercentage ? " %" : "" }}</span
      >
    </div>
  </div>
  <div class="observations" *ngIf="withObservation">
    <h3>Observation</h3>
    <span *ngIf="observation">{{ observation }} </span>
    <nz-card-loading *ngIf="!observation"></nz-card-loading>
  </div>
</div>
