import { ChartOptions } from "chart.js";
import { format, parse } from "date-fns";
import { formatNumber } from "utils/number";

export type TrendIndicatorLegend = {
  label: string;
  value: string;
  checked: boolean;
  hidden?: boolean;
  color: string;
  hoverColor?: string;
  format?: "percent" | "number";
}[];

export type TrendIndicatorTheme =
  | "csat"
  | "nps"
  | "ces"
  | "overall"
  | "segment";

export const TREND_GRAPH_MAX_BUCKETS = 30;

export const trendChartOptions = (
  legend: TrendIndicatorLegend,
  theme: TrendIndicatorTheme,
  maxTicks: number,
): ChartOptions => ({
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    point: {
      radius: theme === "nps" ? 0 : 6,
      borderColor: "white",
      borderWidth: theme === "nps" ? 0 : 4,

      hoverRadius: theme === "nps" ? 0 : 8,
      hoverBorderColor: theme === "nps" ? "transparent" : "white",
      hoverBorderWidth: theme === "nps" ? 0 : 2,
    },
    line: {
      borderColor: "white",
      borderWidth: 2,
      cubicInterpolationMode: "monotone",
      spanGaps: true,
    },
  },
  scales: {
    x: {
      grid: {
        display: true,
        drawOnChartArea:
          theme === "csat" ||
          theme === "ces" ||
          theme === "overall" ||
          theme === "segment",
        drawTicks: false,
        color: "#CECED7",
        z: theme === "nps" ? 1 : -1,
      },
      border: {
        display: true,
        dash: [3],
        dashOffset: 3,
      },
      type: "time",
      time: {
        unit: "day",
        isoWeekday: true,
        round: "day",
        tooltipFormat: "MMM d yyyy",
        displayFormats: {
          day: "MMM d",
        },
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: maxTicks,
        padding: 10,
      },
    },
    main: {
      beginAtZero: true,
      max: theme === "nps" ? 100 : undefined,
      position: "left",
      grid: {
        display: true,
        drawOnChartArea: false,
        drawTicks: false,
        color: "#CECED7",
        z: theme === "nps" ? 1 : -1,
      },
      border: {
        display: false,
      },
      ticks: {
        maxTicksLimit: 5,
        padding: 10,
        callback: theme === "nps" ? (tick) => `${tick}%` : (tick) => tick,
      },
      stacked: theme === "nps",
    },
    secondary: {
      beginAtZero: true,
      display: theme === "overall" || theme === "nps" || theme === "ces",
      position: "right",
      max: theme === "ces" ? 7 : 100,
      min: theme === "nps" ? -100 : 0,
      grid: {
        display: true,
        drawOnChartArea: false,
        drawTicks: false,
        color: "#CECED7",
      },
      border: {
        display: true,
        dash: [3],
        dashOffset: 3,
      },
      ticks: {
        maxTicksLimit: 3,
        padding: 10,
        callback:
          theme === "overall"
            ? function (tick) {
                return tick.toString() + "%";
              }
            : (a) => a,
      },
    },
    tertiary: {
      beginAtZero: true,
      display: theme === "overall",
      position: "right",
      grid: {
        display: true,
        drawOnChartArea: true,
        drawTicks: false,
        color: "#CECED7",
      },
      border: {
        display: true,
        dash: [3],
        dashOffset: 3,
      },
      ticks: {
        display: false,
        maxTicksLimit: 1,
      },
    },
  },

  plugins: {
    tooltip: {
      mode: "index",
      axis: "x",
      intersect: false,
      position: "average",

      titleMarginBottom: 0,
      bodySpacing: 0,
      padding: {
        // @ts-ignore
        x: 160,
        y: 0,
      },
      caretPadding: 20,
      backgroundColor: "transparent",
      callbacks: {
        title: () => "",
        label: () => "",
      },
      animation: { duration: 0 },
      external(context) {
        const tooltipElement = document.body.querySelector(
          "#tooltip",
        ) as HTMLDivElement;
        const indicatorsContainer = tooltipElement.querySelector(
          ".tooltip-indicators",
        ) as HTMLDivElement;
        const title = tooltipElement.querySelector(
          ".tooltip-title",
        ) as HTMLDivElement;

        tooltipElement.style.left = context.tooltip.x + 40 + "px";
        tooltipElement.style.top = "50%";
        tooltipElement.style.marginTop = "-120px";

        indicatorsContainer.innerHTML = ""; // reset

        const createIndicator = (
          label: string = "N/A",
          value: string = "N/A",
          color: string = "black",
          format: "percent" | "number" = "percent",
        ) => {
          const element = document.createElement("div");

          element.classList.add("tooltip-indicator");
          element.style.display = "flex";
          element.style.flexDirection = "row";
          element.style.justifyContent = "space-between";
          element.style.width = "100%";
          element.style.marginTop = "18px";

          const styles = {
            "tooltip-indicator-label": `
              font-family: Rubik;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 150%;
              color: #727387;
            `,
            "tooltip-indicator-dot": `
              display: inline-block;
              width: 8px;
              height: 8px;
              background-color: red;
              border-radius: 8px;
              margin-right: 6px;
            `,
            "tooltip-indicator-value": `
              font-family: Rubik;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
            `,
          };

          element.innerHTML = `
            <div style="${styles["tooltip-indicator-label"]}">
              <span style="${
                styles["tooltip-indicator-dot"]
              }; background: ${color}"></span>
              ${label}
            </div>
            <div style="${styles["tooltip-indicator-value"]}">
              ${formatNumber(Number(value), format, 1)}
            </div>
          `;

          return element;
        };

        if (context.tooltip.dataPoints) {
          [...context.tooltip.dataPoints]
            .reverse()
            .forEach(({ formattedValue, datasetIndex }) => {
              if (!legend[datasetIndex].hidden) {
                indicatorsContainer.appendChild(
                  createIndicator(
                    legend[datasetIndex].label,
                    formattedValue.replace(/[\s]/gi, "").replace(/[\,]/gi, "."),
                    legend[datasetIndex].hoverColor,
                    legend[datasetIndex].format,
                  ),
                );
              }
            });

          title.innerText = format(
            parse(
              context.tooltip.dataPoints[0].label,
              "MMM d yyyy",
              new Date(),
            ),
            "PPP",
          );

          tooltipElement.style.display = "block";
        } else {
          tooltipElement.style.display = "none";
        }
      },
    },
  },
  hover: {
    mode: "index",
    intersect: false,
  },
  animation: {
    duration: 0,
    onProgress: ({ chart: { ctx, chartArea, tooltip } }) => {
      const x = tooltip?.caretX;

      if (x) {
        ctx.save();

        ctx.beginPath();
        ctx.moveTo(x, chartArea.top);
        ctx.lineTo(x, chartArea.bottom);
        ctx.lineCap = "round";
        ctx.lineWidth = 8;
        ctx.strokeStyle = window
          .getComputedStyle(document.body)
          .getPropertyValue("--screeb-color-purple-alpha");
        ctx.stroke();

        ctx.restore();
      }
    },
  },
});
