import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { LayoutBackHeaderService } from "components/layouts/back/header/header.service";
import { Post101 } from "components/utils/card-101-link/card-101-link.model";
import { NotificationHelper } from "helpers/notification.helper";
import {
  ChannelClientScreen,
  ChannelClientVersion,
} from "models/channel-screen.model";
import { Channel } from "models/channel.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import { SurveyDistribution } from "models/survey-distribution.model";
import { surveyCappingTimePeriodTypesAndLabels } from "models/survey-targeting-rule-capping.model";
import {
  SurveyTargetingRule,
  SurveyTargetingRuleOperator,
  SurveyTargetingRuleType,
  targetingRulesTypesAndLabels,
} from "models/survey-targeting-rule.model";
import { Survey } from "models/survey.model";
import { EntitlementService } from "services/entitlement.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { SettingsService } from "services/settings.service";
import { UIService } from "services/ui.service";
import { isTodayBetweenDates } from "utils/date";

type EditMode = "manual" | "wizard" | "advanced" | "push-notification";

type linkMode = "link" | "email";

@Component({
  selector: "page-survey-share",
  templateUrl: "./share.component.html",
  styleUrls: ["./share.component.scss"],
})
export class ShareSurveyPageComponent
  implements PageComponentInterface, OnInit, AfterViewInit, OnDestroy
{
  public title = "Share - Diffusion";
  public name = "Survey Share Diffusion";

  private obs: any = null;

  public org: Org = null;
  public survey: Survey = null;
  public channel: Channel = null;
  public surveyDistribution: SurveyDistribution = null;
  public targetingRules: SurveyTargetingRule[] = [];
  public registryEntriesIdentityProperty?: RegistryEntry[];
  public registryEntriesEvent?: RegistryEntry[];
  public registryEntriesGroup: RegistryEntry[];
  public channelClientScreens?: ChannelClientScreen[];
  public channelClientVersions?: ChannelClientVersion[];
  public surveyCappingDisplay?: SurveyTargetingRule;
  public surveyCappingResponse?: SurveyTargetingRule;
  public active: boolean = null;
  public languages: string[][] = [];
  public countries: string[][] = [];
  public surveys: Survey[] = [];

  public saving: boolean = false;
  public enabling: boolean = false;
  private targetingRulesBackup: SurveyTargetingRule[] = []; // enable or disable 'save' button
  private selectorCSSBackup: string = ""; // enable or disable 'save' button

  public editMode: EditMode | null = null;

  public linkMode: linkMode = "link";

  public surveyCappingTimePeriodTypesAndLabels =
    surveyCappingTimePeriodTypesAndLabels;
  public overrideSurveyCappingSwitch = false;
  public testModeRule: SurveyTargetingRule = null;
  public recurrenceRule: SurveyTargetingRule = null;

  public posts101: Post101[] = [
    // {
    //   color: "var(--screeb-color-primary-outline)",
    //   title:
    //     "How In-App Surveys Can Help You <b>Reduce Funnel Drop-Off Rate</b>",
    //   url: "https://screeb.app/how-in-app-surveys-can-help-you-reduce-funnel-drop-off-rate/",
    // },
    {
      color: "var(--screeb-color-info-outline)",
      title: "My survey is not displayed, what can I do?",
      url: "https://help.screeb.app/en/articles/6007748-my-survey-is-not-displayed-to-my-users-help-me",
    },
    {
      color: "var(--screeb-color-info-outline)",
      title:
        "NPS: Why <b>You Should Measure It Every Quarter</b> (And How To Do It With Screeb)",
      url: "https://screeb.app/nps-why-you-should-measure-it-every-quarter-and-how-to-do-it-with-screeb/",
    },
    {
      color: "var(--screeb-color-warning-outline)",
      title:
        "How to <b>use your users’ events</b> to choose when to display your surveys",
      url: "https://help.screeb.app/en/articles/5252769-how-to-use-your-users-events-to-choose-when-to-display-your-surveys",
    },
    {
      color: "var(--screeb-color-info-outline)",
      title:
        "How to <b>use your users’ properties</b> to choose when to display surveys",
      url: "https://help.screeb.app/en/articles/5040116-how-to-use-your-users-properties-to-choose-when-to-display-surveys",
    },
  ];

  @ViewChild("pageActions")
  private pageActionsRef: TemplateRef<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private notificationHelper: NotificationHelper,
    private surveyDistributionDao: SurveyDistributionDao,
    private layoutBackHeaderService: LayoutBackHeaderService,
    private settingsService: SettingsService,
    public uiService: UIService,
    public entitlementService: EntitlementService,
    public permissionsService: PermissionsService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
      this.survey = data["survey"];
      this.surveys = data["surveys"];
      this.channel = data["channel"];
      this.surveyDistribution = data["survey_distribution"];
      this.registryEntriesIdentityProperty =
        data["registryEntriesIdentityProperty"];
      this.registryEntriesEvent = data["registryEntriesEvent"];
      this.registryEntriesGroup = data["registryEntriesGroup"].groups;
      this.channelClientScreens = data["channelClientScreens"];

      if (this.channelClientScreens) {
        this.channelClientScreens = this.channelClientScreens.filter(
          (screen) => !screen.hidden,
        );
      }

      this.channelClientVersions = data["channelClientAppVersions"];
      this.targetingRules = this.surveyDistribution.targeting_rules
        .filter((rule) => !!rule["survey_distribution_id"])
        .sort((a, b) => Number(b.created_at) - Number(a.created_at));
      this.active = this.surveyDistribution.enabled;

      // Remove visitor property rules without key => invalid rule
      this.targetingRules.forEach((rule, index) => {
        rule.initRuleValues();
        if (rule.type !== "visitor_property") {
          return;
        } else if (!rule.value.key_id) {
          this.targetingRules.splice(index, 1);
        }
      });
      this.targetingRulesBackup = JSON.parse(
        JSON.stringify(this.targetingRules),
      );
      this.selectorCSSBackup = this.surveyDistribution.selector_css;

      this.languages = data["languages_and_countries"]["surveyLanguages"];
      this.countries = data["languages_and_countries"]["countries"];

      if (this.isWizardAvailable()) {
        this.editMode = "wizard";
      } else {
        this.editMode = "advanced";
      }

      this.createTestModeRuleIfNotExist();

      this.overrideSurveyCappingSwitch =
        this.targetingRules.filter((rule) =>
          ["capping_survey_display", "capping_survey_response"].includes(
            rule.type,
          ),
        ).length > 0;

      this.surveyCappingDisplay = this.findRuleByType("capping_survey_display");
      this.surveyCappingResponse = this.findRuleByType(
        "capping_survey_response",
      );

      this.isRecurrenceRuleExisting();
    });
  }

  ngAfterViewInit() {
    // not available at ngOnInit time
    setTimeout(() => {
      this.layoutBackHeaderService.surveyPageActionsRef = this.pageActionsRef;
    });
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
    this.layoutBackHeaderService.surveyPageActionsRef = null;
  }

  private getCleanedTargetingRules(): SurveyTargetingRule[] {
    // remove empty line in Array rules that are not saved to avoid unwanted diff between last change and actual change
    return this.targetingRules
      .map((rule) => {
        const isCappingRule = [
          "capping_survey_display",
          "capping_survey_response",
        ].includes(rule.type);
        if (rule.value.v_s_arr !== undefined && rule.value.v_s_arr !== null) {
          const tmpRule = JSON.parse(JSON.stringify(rule));
          const last = tmpRule.value.v_s_arr.length - 1;
          if (
            tmpRule.value.v_s_arr[last] !== undefined &&
            tmpRule.value.v_s_arr[last].length === 0
          ) {
            tmpRule.value.v_s_arr.pop();
          }
          return tmpRule;
        } else if (
          rule.value.v_n_arr !== undefined &&
          rule.value.v_n_arr !== null &&
          !isCappingRule
        ) {
          const tmpRule = JSON.parse(JSON.stringify(rule));
          const last = tmpRule.value.v_n_arr.length - 1;
          if (
            tmpRule.value.v_n_arr[last] !== undefined &&
            tmpRule.value.v_n_arr[last] === null
          ) {
            tmpRule.value.v_n_arr.pop();
          }
          return tmpRule;
        }
        return rule;
      })
      .filter((rule: SurveyTargetingRule) => {
        if (
          ["capping_survey_display", "capping_survey_response"].includes(
            rule.type,
          )
        ) {
          return (
            rule.value.v_n_p !== null &&
            rule.value.v_n_p !== undefined &&
            rule.value.v_n !== null &&
            rule.value.v_n !== undefined
          );
        }

        return !!rule.type;
      });
  }

  public onTargetingRulesChange(rules: SurveyTargetingRule[]) {
    this.targetingRules = Array.from(rules);

    this.isRecurrenceRuleExisting();
  }

  public hasUnsavedChanges(): boolean {
    // remove empty line in Array rules that are not saved to avoid unwanted diff between last change and actual change
    const targetingRules = this.getCleanedTargetingRules();

    // @TODO: better with deepEqual

    return (
      JSON.stringify(targetingRules) !==
        JSON.stringify(this.targetingRulesBackup) ||
      this.surveyDistribution.selector_css !== this.selectorCSSBackup
    );
  }

  public canLaunch() {
    if (
      this.survey.interaction === "in_page" &&
      (this.surveyDistribution.selector_css === "" ||
        this.surveyDistribution.selector_css === null)
    ) {
      return false;
    }

    return !["manual", "push-notification"].includes(this.editMode);
  }

  private async onSaveImpl() {
    // prune rules with undefined type (returned by expert mode)
    const rules = this.getCleanedTargetingRules();

    // Check if rules are valid
    return this.surveyDistributionDao
      .updateTargetingRules(
        this.org.id,
        this.survey.id,
        this.surveyDistribution.id,
        rules,
        this.surveyDistribution.selector_css,
      )
      .then(() => {
        // this.notificationHelper.trigger("Saved", null, 'success');
        // if assigning distrib.targeting_rules to this.targetingRules, it trigger issue whith saving multiple rule change
        //this.targetingRules = distrib.targeting_rules;
        this.targetingRulesBackup = JSON.parse(JSON.stringify(rules));
        this.selectorCSSBackup = this.surveyDistribution.selector_css;
      });
  }

  public async onSave() {
    this.saving = true;

    return this.onSaveImpl()
      .then(() => {
        this.saving = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
        this.saving = false;
      });
  }

  public async onEnable(enable: boolean) {
    this.enabling = true;

    await this.onSaveImpl();

    return this.surveyDistributionDao
      .updateStatus(
        this.org.id,
        this.survey.id,
        this.surveyDistribution.id,
        enable,
        this.surveyDistribution.selector_css,
      )
      .then((distrib: SurveyDistribution) => {
        // this.notificationHelper.trigger("Saved", null, 'success');
        this.active = distrib.enabled;
        this.enabling = false;

        if (!this.survey.stats.response_total && this.survey.sequence_id) {
          this.router.navigate(
            [
              "org",
              this.org.id,
              "funnel",
              this.survey.sequence_id,
              this.settingsService.getAdminSettings().funnel_create_survey_from,
            ],
            {},
          );
        } else if (
          this.survey.stats.response_total -
            this.survey.stats.response_completion_not_started ===
            0 &&
          this.active
        ) {
          this.router.navigate(
            [
              "org",
              this.org.id,
              this.uiService.surveyTypePath,
              this.survey.id,
              "stats",
            ],
            {},
          );
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
        this.enabling = false;
      });
  }

  /**
   * Wizard/Advanced mode
   */
  public isWizardAvailable(): boolean {
    const rulesPerType = this.targetingRules.reduce(
      (acc: object, curr: SurveyTargetingRule) => {
        if (!acc[curr.type]) acc[curr.type] = [];
        acc[curr.type].push(curr);
        return acc;
      },
      {},
    );

    // look for a rule with types not allowed in wizard mode
    const found = Object.values(targetingRulesTypesAndLabels)
      .filter((spec) => !spec.wizardModeEnabled)
      .find(
        (spec) =>
          !!rulesPerType[spec.type] && rulesPerType[spec.type].length > 0,
      );

    if (found) {
      return false;
    }

    // wizard mode allows only a single URL rules with 'contains' operator
    if ((rulesPerType["url"] || []).length > 1) return false;

    // in wizard mode delay and session duration rules are mutual exclusive
    if (
      (rulesPerType["delay"] || []).length +
        (rulesPerType["session_duration"] || []).length >
      1
    ) {
      return false;
    }

    const notCompatibleUrlRuleOperator = (rulesPerType["url"] || []).find(
      (r: SurveyTargetingRule) => r.operator !== "contains",
    );
    if (notCompatibleUrlRuleOperator) {
      return false;
    }

    const urlRule: SurveyTargetingRule = (rulesPerType["url"] || [])[0];
    if (
      urlRule !== undefined &&
      urlRule !== null &&
      urlRule.value.v_s_arr.length > 1 &&
      urlRule.value.v_s_arr[1].length !== 0
    ) {
      return false;
    }

    const notCompatibleScreenRuleOperator = (rulesPerType["screen"] || []).find(
      (r: SurveyTargetingRule) => r.operator !== "equal",
    );
    if (notCompatibleScreenRuleOperator) {
      return false;
    }

    if ((rulesPerType["start_date"] || []).length > 0) return false;
    if ((rulesPerType["end_date"] || []).length > 0) return false;

    return true;
  }

  public canDeactivate(component: ShareSurveyPageComponent): boolean {
    if (component.hasUnsavedChanges())
      return confirm(
        "You have unsaved changes! If you leave, your changes will be lost.",
      );
    return true;
  }

  /**
   * Rules
   */
  private findRuleByType(type: SurveyTargetingRuleType): SurveyTargetingRule {
    const rule = this.targetingRules.find(
      (r: SurveyTargetingRule) => r.type === type,
    );
    return rule ? rule : null;
  }

  // duplicated code
  private addRule(
    type: SurveyTargetingRuleType | undefined,
    operator?: SurveyTargetingRuleOperator,
  ) {
    const r = new SurveyTargetingRule();
    r.survey_distribution_id = this.surveyDistribution.id;
    r.org_id = null;
    r.type = type || "url";
    r.operator = operator || r.getAvailableOperators()?.[0]?.type;
    r.value = r.getDefaultValue(this.channel.type);
    this.targetingRules.push(r);

    this.targetingRules = Array.from(this.targetingRules);
  }

  public isTestModeEnabled(): boolean {
    if (this.channel.type === "hosted-page") return false;

    return this.testModeRule?.value?.v_b && !this.recurrenceRule;
  }

  public isRecurrenceRuleExisting(): boolean {
    this.recurrenceRule = this.findRuleByType("recurrence");

    return !!this.recurrenceRule;
  }

  // public isRecurrenceIfNotAnsweredRuleExisting(): boolean {
  //   return !!this.findRuleByType("recurrence_not_answered");
  // }

  /**
   * Test mode changes
   */
  public onTestModeChange(checked: boolean) {
    this.createTestModeRuleIfNotExist();
    this.testModeRule.value.v_b = checked;

    this.targetingRules = Array.from(this.targetingRules);
  }

  private createTestModeRuleIfNotExist(): boolean {
    this.testModeRule = this.targetingRules.find(
      (r: SurveyTargetingRule) => r.type === "multiple",
    );
    if (!this.testModeRule) {
      this.addRule("multiple");
      this.createTestModeRuleIfNotExist();
      return true;
    }
    return false;
  }

  /**
   * Capping rule changes
   */
  public onCappingTargetingChange(checked: boolean) {
    this.overrideSurveyCappingSwitch = checked;
    if (!checked) {
      this.targetingRules = this.targetingRules.filter(
        (r: SurveyTargetingRule) =>
          !["capping_survey_display", "capping_survey_response"].includes(
            r.type,
          ),
      );
    } else {
      if (!this.findRuleByType("capping_survey_display")) {
        this.addRule("capping_survey_display");
      }
      if (!this.findRuleByType("capping_survey_response")) {
        this.addRule("capping_survey_response");
      }

      this.surveyCappingDisplay = this.findRuleByType("capping_survey_display");
      this.surveyCappingResponse = this.findRuleByType(
        "capping_survey_response",
      );
    }

    this.targetingRules = Array.from(this.targetingRules);
  }

  /**
   * Targeting rule: 'multiple'
   */
  public isMultipleEnabled(): boolean {
    return this.targetingRules.find(
      (r: SurveyTargetingRule) => r.type === "multiple",
    )?.value.v_b;
  }
  public onTargetingRuleMultipleChange(val: boolean) {
    const rule = this.targetingRules.find(
      (r: SurveyTargetingRule) => r.type === "multiple",
    );

    if (!rule) {
      this.targetingRules.push(
        new SurveyTargetingRule().fromJson({
          type: "multiple",
          value: { v_b: false },
        }),
      );
      return this.onTargetingRuleMultipleChange(val);
    }

    rule.value.v_b = val;
  }

  public isInStartEndDates(): boolean {
    const start_date = this.findRuleByType("start_date");
    const end_date = this.findRuleByType("end_date");

    return isTodayBetweenDates(start_date?.value?.v_t, end_date?.value?.v_t);
  }

  public setLinkMode(mode: linkMode) {
    this.linkMode = mode;
  }
}
