<div *ngIf="rule">
  <div class="rule-type-delay">
    <nz-input-group
      [nzPrefix]="'After:'"
      [nzSuffix]="'seconds spent on the page'"
      [ngClass]="{ invalid: !checkValidValue(rule) }"
    >
      <rule-input-number
        [(value)]="rule.value.v_n"
        [step]="1"
        [minValue]="1"
        [maxValue]="3600"
        [inputGroupMode]="true"
        [placeholder]="rule.getValuePlaceholder()"
      ></rule-input-number>
    </nz-input-group>
  </div>
</div>
