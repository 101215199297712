import { Injectable, OnDestroy } from "@angular/core";
import { ConfigService } from "./config.service";
import { BehaviorSubject } from "rxjs";

const TAG_STORAGE_VERSION = "v0.2.56"; // @TODO: take a look about how to make this dynamic

@Injectable()
class TagStorageService implements OnDestroy {
  private iframeElement: HTMLIFrameElement | null = null;
  private isReady = new BehaviorSubject<boolean>(false);

  constructor(private configService: ConfigService) {
    this.createIframe();
  }

  public get isReady$() {
    return this.isReady.asObservable(); // returns BehaviourSubject as an Obervable
  }

  ngOnDestroy() {
    this.isReady.complete(); // Good practice to complete subject on lifecycle hook ngOnDestroy
  }

  public set(
    storageKey: string,
    storageType: "cookie" | "localStorage" | "sessionStorage",
    key: string,
    value: unknown,
  ) {
    this.iframeElement.contentWindow.postMessage(
      {
        screeb: {
          from: "core",
          to: "storage",
          event: {
            type: "storage.set",
            storageType,
            storageKey,
            key,
            value,
          },
        },
      },
      "*",
    );
  }

  private createIframe() {
    const iframeId = "tag-storage";
    const alreadyExistingIframe: HTMLIFrameElement | null =
      window.document.querySelector(`#screeb-${iframeId}`);

    if (alreadyExistingIframe) {
      return;
    }

    const iframe: HTMLIFrameElement = window.document.createElement("iframe");

    iframe.id = `screeb-${iframeId}`;
    iframe.title = "Screeb";
    iframe.allowFullscreen = true;
    iframe.scrolling = "no";
    iframe.frameBorder = "0";
    iframe.setAttribute("allowtransparency", "true");

    iframe.style.display = "block";
    iframe.style.border = "none";
    iframe.style.background = "none transparent";
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.style.visibility = "hidden";

    // @ts-ignore
    iframe.sandbox =
      "allow-storage-access-by-user-activation allow-scripts allow-same-origin";

    if (this.configService.config.env === "dev") {
      iframe.src = `${this.configService.config.tagEndpoint}/storage.html?origin=${window.location.origin}`;
    } else {
      iframe.src = `${this.configService.config.tagEndpoint}/${TAG_STORAGE_VERSION}/storage.html?origin=${window.location.origin}`;
    }

    window.document.body.appendChild(iframe);

    this.iframeElement = iframe;

    window.addEventListener("message", (event) => {
      if (event.origin !== this.configService.config.tagEndpoint) {
        return;
      }

      if (event.data.screeb.from === "storage") {
        if (event.data.screeb.event.type === "booted") {
          this.isReady.next(true);
        }
      }
    });
  }
}

export { TagStorageService };
