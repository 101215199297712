<ng-template #FlowTagContainer>
  <div class="FlowTagContainer is-{{ builderStore.survey?.type ?? 'survey' }}">
    <flow-tag
      [isFlowStarting]="isStarting"
      [surveyType]="builderStore.survey?.type ?? 'survey'"
      [messageURL]="messageURL"
      (messageURLChange)="messageURLChange.emit()"
    ></flow-tag>
    <div class="lang-select" *ngIf="!builderStore.readOnly">
      <language-select
        nzSize="large"
        name="defaultLanguage"
        ngDefaultControl
        [defaultLanguageWarning]="defaultLanguageWarning"
        [(ngModel)]="builderStore.currentLanguage"
        [languages]="builderStore.availableLanguages"
        [defaultLanguage]="builderStore.survey.scenario.default_language"
        [canSetAsDefault]="true"
        (defaultLanguageChange)="defaultLanguageChange.emit($event)"
      ></language-select>

      <span
        nz-popover
        nzPopoverPlacement="right"
        [nzPopoverContent]="contentTemplate"
      >
        <i class="lang-select-info" nz-icon nzType="info-circle"></i>
        <ng-template #contentTemplate>
          <div [style]="{ maxWidth: '250px' }">
            This list can be configured in your organisation
            <a routerLink="/org/{{ builderStore.org.id }}/settings/language">
              Language preferences
            </a>
            <br />
            <br />
            The default language will be used when the respondent's language has
            no translation.
          </div>
        </ng-template>
      </span>
    </div>
  </div>
</ng-template>

<div class="Checkpoint" [ngClass]="isStarting ? 'IsStarting' : 'IsEnding'">
  <ng-template
    *ngIf="isStarting"
    [ngTemplateOutlet]="FlowTagContainer"
  ></ng-template>
  <div class="LineAndButtonContainer">
    <add-button
      [surveyType]="builderStore.survey?.type ?? 'survey'"
      (onClick)="internalOnClick(trigger)"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      *ngIf="!builderStore.readOnly"
    ></add-button>
    <div class="LineContainer">
      <svg
        width="15"
        height="54"
        viewBox="0 0 15 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 27H14"
          stroke="#C7C7CF"
          stroke-linecap="round"
          stroke-linejoin="round"
          *ngIf="!builderStore.readOnly"
        />
        <path
          d="M1 53.5V1"
          stroke="#C7C7CF"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
  <ng-template
    *ngIf="!isStarting"
    [ngTemplateOutlet]="FlowTagContainer"
  ></ng-template>
</div>
