import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";

@Injectable()
export class SuperOrgResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private superOrgDao: SuperOrgDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<SuperOrg> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        if (route.params["super_org_id"]) {
          return this.superOrgDao.getById(route.params["super_org_id"]);
        } else if (route.parent.data?.org?.super_org_id) {
          return this.superOrgDao.getById(route.parent.data?.org?.super_org_id);
        } else {
          throw new Error("No super org id found");
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
