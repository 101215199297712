import { Component, Input, OnInit } from "@angular/core";
import { AddonBillingSettings } from "models/org_billing.model";
import { SettingsService } from "services/settings.service";
import {
  AddonInfo,
  addonInfos,
  displayedAddonKeys,
} from "./billing-plan/billing-plan.data";

@Component({
  selector: "billing-addon-upgrade",
  template: `
    <div class="addon">
      <div
        class="illustration"
        style="
        background-image: linear-gradient(180deg, rgba(48, 49, 64, 0.03) 0%, rgba(48, 49, 64, 0.5) 100%), url('assets/preview/{{
          addonInfo.img
        }}')
      "
      ></div>
      <div class="info">
        <span class="title">{{ addonInfo.name }}</span>
        <button
          class="get-btn"
          title="Upgrade now"
          nz-button
          nzType="primary"
          nzSize="large"
        >
          <span>
            🎟️ Activate
            <span *ngIf="price !== null">
              &nbsp;({{ price | price }}/month)
            </span>
          </span>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
        /*min-width: 400px;*/
        cursor: pointer;
      }

      .addon {
        width: 100%;
      }

      .addon .illustration {
        width: 100%;
        height: 135px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 16px;
      }

      .addon .info {
        display: flex;
        align-items: flex-start;
        margin-top: 12px;
      }

      .addon .info .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #303140;
      }

      .addon .info .get-btn {
        height: 40px;
        background-color: #ffffff;
        padding: 6px 16px;
        margin-left: auto;
        border: 1px solid #e5e5ed;
        box-shadow: none;
      }

      .addon .info .get-btn span {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #727387;
      }
    `,
  ],
})
export class SettingsBillingAddonComponent implements OnInit {
  @Input() addonKey: displayedAddonKeys = null;
  @Input() planMTU: number = null;

  public addonInfo: AddonInfo;
  public price: number = null;

  constructor(private settingsService: SettingsService) {}

  ngOnInit(): void {
    this.addonInfo = addonInfos[this.addonKey];

    const addon: AddonBillingSettings =
      this.settingsService.billing.addons[this.addonKey];

    this.price =
      addon?.per_mtu[this.planMTU]?.monthly_billing_mrr ??
      addon?.monthly_billing_mrr ??
      null;
  }
}
