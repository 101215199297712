<ng-template #upgradeAdminNotificationTemplate let-notification>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon" style="margin-left: 0">
        <img alt="Upgrade" src="/assets/illustrations/upgrade-rocket.svg" />
      </span>
      <div class="ant-notification-notice-message">
        An upgrade is available!
      </div>
      <div class="ant-notification-notice-description">
        We’ve just pushed some changes to our app, reload it to enjoy them!
      </div>
      <span class="ant-notification-notice-btn">
        <a
          nz-button
          nzType="link"
          nzSize="small"
          href="https://changelog.screeb.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>View changelog</span>
        </a>
        <button
          nz-button
          nzType="primary"
          nzSize="medium"
          (click)="upgradeAdminService.upgradeApp()"
        >
          <i nz-icon nzType="reload"></i>
          <span>Reload Screeb</span>
        </button>
      </span>
    </div>
  </div>
</ng-template>

<upgrade-banner
  *ngIf="shouldShowMTUExceededBanner()"
  [positionFixed]="true"
  [orgId]="uiService.currentOrgId"
  [danger]="true"
  title="You've reached your limit of Monthly Tracked Users!"
  text="Don't worry you can still collect insights for your audience and still launch surveys, but you won't be able to collect data on <strong>new</strong> users. You can increase your MTUs by upgrading your subscription."
  (closeClick)="closeIsMTUExceeded()"
>
</upgrade-banner>

<upgrade-banner
  *ngIf="shouldShowMTUAlmostReachedBanner()"
  [positionFixed]="true"
  [orgId]="uiService.currentOrgId"
  [small]="true"
  title="You've almost reached your monthly users limit!"
  [text]="null"
  [progressText]="
    (uiService.currentSuperOrg?.stats?.current_month_respondents ?? 0) +
    '/' +
    (uiService.currentSuperOrg?.entitlements?.max_mtu ?? 0)
  "
  [progress]="entitlementService.getMTUUsagePercent()"
  (closeClick)="closeIsMTUAlmostReachActive()"
>
</upgrade-banner>

<nz-layout
  id="layout-zone-lvl1"
  [ngClass]="{
    is_connected_as: sessionService.isConnectedAs(),
    'is-sider-forced-open': isSiderForcedOpen && !isMediumScreen,
    'is-small-screen': isSmallScreen,
    'has-no-sider': hasNoSider
  }"
>
  <upgrade-modal
    *ngIf="shouldShowFreeTrialEndedModal()"
    [centeredModal]="true"
    [withOverlay]="true"
    [orgId]="uiService.currentOrgId"
    title="Your trial is over!"
    text="Your surveys are not displayed to your users. Get in touch with our team to fully activate your account and continue getting actionable feedback for your users!"
    ctaText="Activate my account"
    (closeClick)="closeIsFreeTrialEndedModal()"
  ></upgrade-modal>

  <layout-back-keyboard-shortcuts
    *ngIf="!uiService.isAuthPage"
  ></layout-back-keyboard-shortcuts>

  <nz-sider
    *ngIf="isGlobalMenuVisible"
    id="sidebar-lvl1"
    nzCollapsible
    nzTheme="light"
    [nzWidth]="menuCollapsed ? (isSmallScreen ? '0px' : '100px') : '300px'"
    [nzCollapsedWidth]="menuCollapsed ? (isSmallScreen ? 0 : 100) : 240"
    nzBreakpoint="xxl"
    [nzCollapsed]="menuCollapsed"
    [nzTrigger]="null"
  >
    <layout-back-sidebar
      [isMenuCollapsed]="menuCollapsed"
      [isSuperOrgSidebar]="isSuperOrgSidebarVisible"
      (collapseMenu)="onClickMenuCollapse()"
    ></layout-back-sidebar>
  </nz-sider>
  <button
    *ngIf="isGlobalMenuVisible"
    nz-button
    nzType="primary"
    class="mobile-trigger"
    title="Open Sidebar"
    (click)="onClickMenuCollapse()"
  >
    <img
      alt="Secondary Menu"
      class="trigger"
      width="14"
      height="14"
      src="/assets/icons/layout/collapse.svg"
    />
  </button>
  <button
    *ngIf="hasSecondaryMobileTrigger"
    nz-button
    nzType="primary"
    class="mobile-second-trigger"
    [class]="secondaryMenuCollapsed ? '' : 'is-open'"
    title="Open Sidebar"
    (click)="onClickSecondMenuCollapse()"
  >
    <img
      alt="Menu"
      class="trigger"
      src="/assets/icons/sm/ellipsis-vertical.svg"
    />
  </button>

  <nz-layout id="layout-zone-lvl2">
    <nz-header *ngIf="isHeaderVisible" id="header-lvl1">
      <layout-back-header
        [isMenuCollapsed]="menuCollapsed"
        (collapseMenu)="onClickMenuCollapse()"
      ></layout-back-header>
    </nz-header>

    <nz-layout id="layout-zone-lvl3" (click)="onLayoutZone3Click()">
      <nz-sider
        *ngIf="isStatsColumnVisible"
        id="sidebar-lvl2"
        [nzWidth]="secondaryMenuCollapsed ? 0 : 240"
      >
        <layout-back-sidebar-stats></layout-back-sidebar-stats>
      </nz-sider>
      <nz-sider
        *ngIf="isSharingColumnVisible"
        id="sidebar-lvl2"
        [nzWidth]="secondaryMenuCollapsed ? 0 : 240"
      >
        <layout-back-sidebar-sharing></layout-back-sidebar-sharing>
      </nz-sider>
      <nz-sider
        *ngIf="isWorkspaceSettingsColumnVisible"
        id="sidebar-lvl2"
        [nzWidth]="secondaryMenuCollapsed ? 0 : 240"
      >
        <layout-back-sidebar-settings></layout-back-sidebar-settings>
      </nz-sider>
      <nz-sider
        *ngIf="isIntegrationColumnVisible"
        id="sidebar-lvl2"
        [nzWidth]="secondaryMenuCollapsed ? 0 : 210"
      >
        <layout-back-sidebar-integration></layout-back-sidebar-integration>
      </nz-sider>

      <!-- id is necessary to create affix or anchor relative to this scrolling area -->
      <nz-content
        id="affix-anchor-target"
        [ngClass]="{
          'no-margin':
            uiService.isLayoutEmbbeded ||
            uiService.isLayoutBuilder ||
            uiService.isAuthPage
        }"
      >
        <!-- body -->
        <router-outlet></router-outlet>

        <!-- loader -->
        <div *ngIf="pageLoading" id="page-loader">
          <img
            src="/assets/loader-screeb-small.gif"
            title="Loader"
            alt="Loader"
          />
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>
</nz-layout>
<ai-helper
  class="ai-helper"
  mode="default"
  [orgId]="uiService.currentOrgId"
></ai-helper>
