/* eslint-disable @angular-eslint/no-output-on-prefix, @angular-eslint/component-selector */
import { Component, EventEmitter, Input, Output } from "@angular/core";

import QRCode from "qrcode";
import { ClipboardService } from "ngx-clipboard";
import { NzImageService } from "ng-zorro-antd/image";

import { NotificationHelper } from "helpers/notification.helper";

@Component({
  selector: "text-snippet-copy",
  templateUrl: "./text-snippet-copy.component.html",
  styleUrls: ["./text-snippet-copy.component.scss"],
})
export class TextSnippetCopyComponent {
  @Input() public reset?: boolean = false;
  @Input() public copyText: string = null;
  @Input() public url: string = null;
  @Input() public qrCode: string = null;
  @Input() public canOpen: boolean = false;
  @Input() public withPadding: boolean = true;

  @Output() public onCopy = new EventEmitter();
  @Output() public onReset = new EventEmitter();
  @Output() public onOpen = new EventEmitter();
  @Output() public onQRCode = new EventEmitter();

  constructor(
    private notificationHelper: NotificationHelper,
    private clipboardService: ClipboardService,
    private nzImageService: NzImageService,
  ) {}

  /**
   * Clipboard
   */
  public copyContent(ev: Event) {
    this.clipboardService.copy(this.copyText);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );
    ev.preventDefault();
    ev.stopPropagation();

    this.onCopy.emit();
  }

  /**
   * QR code
   */
  public displayQRCode() {
    // doc: https://github.com/soldair/node-qrcode/blob/f08fd572d7/lib/renderer/utils.js#L45
    QRCode.toDataURL(this.url, { scale: 8 })
      .then((qrcode: string) => {
        const image = {
          src: qrcode,
          alt: "qr-code",
        };
        this.nzImageService.preview([image], { nzZoom: 1 });
      })
      .catch((err) => {
        console.error(err);
        return null;
      });

    this.onQRCode.emit();
  }
}
