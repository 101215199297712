import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "funnel-help",
  template: `
    <div class="lateral-panel" cdkScrollable>
      <div class="lateral-panel-inner" cdkScrollable>
        <header class="lateral-header">
          <div class="left">
            <button (click)="closePanel.emit($event)" class="close">
              <i nz-icon nzType="close" nzTheme="outline"></i>
            </button>
            <h2 class="title">I need help on funnels</h2>
          </div>
          <div class="right"></div>
        </header>

        <div class="lateral-body">
          <div class="lateral-body-content">
            <h3>Watch this quick video about Funnels</h3>

            <iframe
              width="461"
              height="259"
              src="https://www.youtube.com/embed/BMIsPf88ofE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>

            <h3>Read our complete documentation</h3>

            <card-101-link
              title="How to Set Up a Funnel Report"
              description="A step by step guide to analyse and increase your funnel's performances"
              minuteRead="3"
              url="https://help.screeb.app/en/articles/6980118-how-to-set-up-a-funnel-report"
              color="rgb(255, 240, 218)"
            ></card-101-link>

            <h3>Any other question?</h3>

            <intercom-button
              class="ask-us-a-question"
              title="Ask us a question"
              backgroundColor="var(--screeb-color-primary)"
              borderColor="var(--screeb-color-primary)"
              textColor="var(--screeb-color-background)"
            />

            <footer class="panel-button-container">
              <div class="left group"></div>
              <div class="right group">
                <button
                  nz-button
                  nzType="default"
                  nzSize="large"
                  (click)="closePanel.emit($event)"
                >
                  Close
                </button>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>

    <!-- transparent overlay -->
    <div class="lateral-panel-backdrop" (click)="closePanel.emit($event)"></div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      :host h2 {
        margin-bottom: 0 !important;
      }

      :host h3 {
        margin-top: 32px;
      }

      :host .lateral-panel {
        position: fixed;
        padding-bottom: 32px;
        top: 0;
        right: 0;
        width: 525px;
        max-width: 100%;
        height: 100%;
        z-index: 501;
        background: var(--screeb-color-background);
        animation: lateral-entrance 300ms ease-in;
      }

      @keyframes lateral-entrance {
        from {
          transform: translateX(100%);
        }

        to {
          transform: translateX(0);
        }
      }

      :host .lateral-panel-inner {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
        padding: 32px;
      }

      :host .lateral-header .left > * {
        margin: 0;
        margin-right: 16px;
      }

      :host .lateral-header .left > * h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: var(--screeb-color-body-text);
      }

      :host .lateral-header .left,
      :host .lateral-header .right {
        display: flex;
        align-items: center;
      }

      :host .lateral-header button {
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        border: 1px solid var(--screeb-color-border-secondary);
        border-radius: 8px;
        background: var(--screeb-color-background);
      }

      :host .lateral-header button[disabled] {
        border-color: transparent;
        background: transparent;
        cursor: default;
      }

      :host .lateral-header {
        display: flex;
        justify-content: space-between;
      }

      :host .lateral-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      :host .lateral-body-content {
        min-height: 100%;
        display: flex;
        flex-direction: column;
      }

      :host .lateral-body footer {
        margin-top: 24px;
      }

      :host .lateral-panel-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--screeb-color-grey-500);
        opacity: 0.2;
        z-index: 500;
      }

      :host footer.panel-button-container .group {
        display: flex;
      }

      :host footer.panel-button-container .left button,
      :host footer.panel-button-container .left simple-button {
        margin-right: 8px;
      }

      :host footer.panel-button-container .right button,
      :host footer.panel-button-container .right simple-button {
        margin-left: 8px;
      }

      :host footer.panel-button-container {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      :host iframe {
        border-radius: 16px;
      }

      :host .ask-us-a-question {
        border-radius: 16px;
        height: 48px;
        justify-content: center;
        margin: 0 auto;
      }
    `,
  ],
})
export class FunnelHelpComponent {
  @Output() closePanel = new EventEmitter<Event>();

  constructor() {}
}
