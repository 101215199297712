import { Serializable } from "models/serializable.model";
import { Release } from "./release.model";
import { AnalyticsResponseItemResponse } from "./analytics.model";

export class AskResult extends Serializable {
  constructor(
    public id?: string,
    public title?: string,
    public description?: string,
    public url?: string,
    public type?: string,
    public icon?: string,
    public obj?: Release | AnalyticsResponseItemResponse,
  ) {
    super();
  }

  static New(
    id: string,
    title: string,
    description: string,
    url: string,
    type: string,
    icon?: string,
    obj?: Release | AnalyticsResponseItemResponse,
  ): AskResult {
    return {
      id,
      title,
      description,
      url,
      type,
      icon,
      obj,
    } as AskResult;
  }
}
