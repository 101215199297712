import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { IntegrationDao } from "models/integration.dao";
import { IntegrationType } from "models/integrations.model";
import { SettingsService } from "./settings.service";

export type OAuth2State = {
  org_id: string;
  integration_type: IntegrationType;
  scopes: string[];
};

export const decodeState = (state?: string): OAuth2State =>
  state ? JSON.parse(window.atob(decodeURIComponent(state))) : {};

@Injectable()
class OAuth2Service {
  constructor(
    private screebApiHelper: ScreebApiHelper,
    private router: Router,
    private settingsService: SettingsService,
    private integrationDao: IntegrationDao,
  ) {}

  getAuthorizeURL(
    orgId: string,
    integrationSlug: IntegrationType,
  ): Promise<string> {
    return this.screebApiHelper
      .get(`/org/${orgId}/integration/${integrationSlug}/auth/oauth2/authorize`)
      .toPromise()
      .then((data: object) => {
        return data["authorize_url"];
      });
  }

  grantAuthorization(
    orgId: string,
    integrationType: IntegrationType,
    code: string,
    scopes: string[],
  ) {
    return this.screebApiHelper
      .post<object>(
        `/org/${orgId}/integration/${integrationType}/auth/oauth2/grant`,
        {
          code,
          scopes,
        },
      )
      .toPromise();
  }

  revoke(orgId: string, integrationType: IntegrationType) {
    return this.screebApiHelper
      .post<object>(
        `/org/${orgId}/integration/${integrationType}/auth/oauth2/revoke`,
        {},
      )
      .toPromise();
  }
}

export { OAuth2Service };
