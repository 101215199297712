import { format, formatDistance } from "date-fns";
import { enUS } from "date-fns/locale";

export type DateFormatType = "distance-from-now" | "date-only";

export function formatDateDistanceFromNow(date: Date): string {
  return formatDistance(date, new Date(), { addSuffix: true, locale: enUS });
}

export function formatDateOnly(date: Date): string {
  return format(date, "yyyy-LL-dd", { locale: enUS });
}

export function formatDate(date: Date, format: DateFormatType) {
  switch (format) {
    case "date-only":
      return formatDateOnly(date);
    case "distance-from-now":
      return formatDateDistanceFromNow(date);
    default:
      return date.toDateString();
  }
}

export function isTodayBetweenDates(
  start_date: Date | null,
  end_date: Date | null,
) {
  if (!start_date && !end_date) return true;

  const today = new Date();

  if (!end_date && start_date < today) {
    return true;
  } else if (!end_date && start_date > today) {
    return false;
  } else if (!start_date && end_date < today) {
    return false;
  } else if (!start_date && end_date > today) {
    return true;
  }

  if (start_date < today && end_date > today) {
    return true;
  }

  return false;
}
