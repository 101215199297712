import { Component, Input, OnInit } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";

import { RegistryEntry } from "models/registry.model";

@Component({
  selector: "rule-user-event-time",
  templateUrl: "./rule-user-event-time.component.html",
  styleUrls: ["./rule-user-event-time.component.scss"],
})
export class TargetingRuleUserEventTimeComponent implements OnInit {
  @Input() public rule: SurveyTargetingRule = null;
  @Input() public registryEntriesEvent: RegistryEntry[] = [];

  public nbr: number;
  public unit: "second" | "minute" | "hour" | "day" | "week" | "month";

  public baseUnitOptions = [
    { label: "Seconds", value: "second" },
    { label: "Minutes", value: "minute" },
    { label: "Hours", value: "hour" },
    { label: "Days", value: "day" },
    { label: "Weeks", value: "week" },
    { label: "Months", value: "month" },
  ];
  public unitOptions = this.baseUnitOptions;

  constructor() {}

  ngOnInit() {
    if (this.rule.value.v_n_p % (30 * 24 * 60 * 60) === 0) {
      this.nbr = this.rule.value.v_n_p / 30 / 24 / 60 / 60;
      this.unit = "month";
    } else if (this.rule.value.v_n_p % (7 * 24 * 60 * 60) === 0) {
      this.nbr = this.rule.value.v_n_p / 7 / 24 / 60 / 60;
      this.unit = "week";
    } else if (this.rule.value.v_n_p % (24 * 60 * 60) === 0) {
      this.nbr = this.rule.value.v_n_p / 24 / 60 / 60;
      this.unit = "day";
    } else if (this.rule.value.v_n_p % (60 * 60) === 0) {
      this.nbr = this.rule.value.v_n_p / 60 / 60;
      this.unit = "hour";
    } else if (this.rule.value.v_n_p % 60 === 0) {
      this.nbr = this.rule.value.v_n_p / 60;
      this.unit = "minute";
    } else {
      this.nbr = this.rule.value.v_n_p;
      this.unit = "second";
    }

    if (this.rule.type !== "device_visitor_event_time") {
      this.unitOptions = this.baseUnitOptions.filter(
        ({ value }) => value !== "second",
      );
    }
  }

  public checkValidTime(r: SurveyTargetingRule): boolean {
    if (r.value.v_n_p === null || r.value.v_n_p === undefined) return false;
    return true;
  }

  public onValueChange() {
    if (this.unit === "second") this.rule.value.v_n_p = this.nbr;
    else if (this.unit === "minute") this.rule.value.v_n_p = this.nbr * 60;
    else if (this.unit === "hour") this.rule.value.v_n_p = this.nbr * 60 * 60;
    else if (this.unit === "day")
      this.rule.value.v_n_p = this.nbr * 60 * 60 * 24;
    else if (this.unit === "week")
      this.rule.value.v_n_p = this.nbr * 60 * 60 * 24 * 7;
    else if (this.unit === "month")
      this.rule.value.v_n_p = this.nbr * 60 * 60 * 24 * 30;
  }
}
