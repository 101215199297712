<ul nz-menu nzMode="inline" class="screeb-menu">
  <ng-container *ngFor="let channelGroup of channelGroups">
    <li
      nz-submenu
      [nzOpen]="
        router.isActive(
          '/org/' +
            uiService.currentOrgId +
            '/' +
            uiService.surveyTypePath +
            '/' +
            uiService.currentSurveyId +
            '/share/' +
            channel.id +
            '/' +
            channelGroup.channelType,
          false
        )
      "
      *ngFor="let channel of getChannelsOfType(channelGroup.channelType)"
      [nzTitle]="getTitle(channelGroup, channel.name)"
    >
      <ul>
        <li nz-menu-item nzMatchRouter>
          <a
            routerLink="/org/{{ uiService.currentOrgId }}/{{
              uiService.surveyTypePath
            }}/{{ uiService.currentSurveyId }}/share/{{ channel.id }}/{{
              channelGroup.channelType
            }}/diffusion"
          >
            Diffusion
          </a>
        </li>
        <li nz-menu-item nzMatchRouter>
          <a
            routerLink="/org/{{ uiService.currentOrgId }}/{{
              uiService.surveyTypePath
            }}/{{ uiService.currentSurveyId }}/share/{{ channel.id }}/{{
              channelGroup.channelType
            }}/design"
          >
            Design
          </a>
        </li>
      </ul>
    </li>
  </ng-container>
</ul>
