<section class="billing-infos">
  <h2>Billing information</h2>

  <ul>
    <li *ngIf="!!orgBilling.name">
      <label>{{ orgBilling.is_company ? "Company Name" : "Name" }}</label>
      <p>{{ orgBilling.name }}</p>
    </li>
    <li *ngIf="!!orgBilling.email">
      <label>Email</label>
      <p>{{ orgBilling.email }}</p>
    </li>
    <li
      *ngIf="
        !!orgBilling.address_line_1 ||
        !!orgBilling.address_line_2 ||
        !!orgBilling.postal_code ||
        !!orgBilling.city ||
        !!orgBilling.country
      "
    >
      <label>Address</label>
      <p>
        <span *ngIf="orgBilling.address_line_1">{{
          orgBilling.address_line_1
        }}</span>
        <span *ngIf="orgBilling.address_line_2">{{
          orgBilling.address_line_2
        }}</span>
        <span *ngIf="orgBilling.postal_code || orgBilling.city"
          >{{ orgBilling.postal_code }} {{ orgBilling.city }}</span
        >
        <span *ngIf="orgBilling.country">{{
          isoCountry2ToLabel(orgBilling.country)
        }}</span>
      </p>
    </li>
    <li *ngIf="!!orgBilling.vat">
      <label>VAT</label>
      <p>{{ orgBilling.vat }}</p>
    </li>
  </ul>

  <button nz-button nzType="primary" (click)="onUpdate.emit('address')">
    Update
  </button>
</section>

<section class="payment-method">
  <h2>Payment methods</h2>

  <nz-list nzGrid>
    <div nz-row [nzGutter]="16">
      <div
        nz-col
        [nzXXl]="6"
        [nzXl]="8"
        [nzLg]="12"
        [nzMd]="24"
        [nzSm]="24"
        [nzXs]="24"
        *ngFor="let pm of orgBillingPaymentMethods.payment_method"
      >
        <nz-list-item>
          <nz-card>
            <h3>
              {{ pm.type === "card" ? "Card" : "SEPA direct debit" }}
              <div class="tags">
                <nz-tag
                  *ngIf="
                    orgBillingPaymentMethods.default_payment_method?.id ===
                    pm.id
                  "
                  [nzColor]="'var(--screeb-color-purple-200)'"
                >
                  Default
                </nz-tag>
                <nz-tag
                  *ngIf="pm.type === 'card' && isCardExpired(pm)"
                  [nzColor]="'var(--screeb-color-purple-200)'"
                >
                  Expired
                </nz-tag>
              </div>
            </h3>

            <p *ngIf="pm.type === 'card'">
              Type: {{ pm.card.brand }}
              <br />
              Card number: **** **** **** {{ pm.card.last4 }}
              <br />
              Expiration: {{ pm.card.exp_month }}/{{ pm.card.exp_year }}
            </p>

            <p *ngIf="pm.type === 'sepa_debit'">
              Bank code: {{ pm.sepa_debit.bank_code }}
              <br />
              Branch code: {{ pm.sepa_debit.branch_code }}
              <br />
              Country: {{ isoCountry2ToLabel(pm.sepa_debit.country) }}
              <br />
              Account number: ************{{ pm.sepa_debit.last4 }}
            </p>

            <footer>
              <button
                *ngIf="
                  orgBillingPaymentMethods.default_payment_method?.id !== pm.id
                "
                nz-button
                nzType="link"
                [nzLoading]="loadingPaymentMethodChange === pm.id"
                (click)="changeDefaultPaymentMethod(pm.id)"
              >
                Set as default
              </button>
              <button
                *ngIf="
                  orgBillingPaymentMethods.default_payment_method?.id !== pm.id
                "
                nz-button
                nzType="text"
                nzDanger
                [nzLoading]="loadingPaymentMethodRemove === pm.id"
                (click)="removePaymentMethod(pm.id)"
              >
                Remove
              </button>
            </footer>
          </nz-card>
        </nz-list-item>
      </div>
    </div>
  </nz-list>

  <button nz-button nzType="primary" (click)="onUpdate.emit('payment_method')">
    Add payment method
  </button>
</section>
