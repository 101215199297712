import { NzSelectOptionInterface } from "ng-zorro-antd/select";

import { endOfDay, isDate, startOfDay } from "date-fns";

import { GraphNode } from "components/builder/flow";
import {
  AnalyticsFilter,
  AnalyticsFilterOperator,
  AnalyticsFilterResponseAnswer,
  AnalyticsFilterResponseHiddenField,
  AnalyticsFilterUser,
  AnalyticsFilterUserEvent,
  AnalyticsFilters,
  AnalyticsFiltersOperator,
  AnalyticsQuery,
} from "models/analytics.filters.type";
import { ChannelDao } from "models/channel.dao";
import { Channel } from "models/channel.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { Survey } from "models/survey.model";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import {
  AnalyticsFilterService,
  analyticsQueryOperatorSettings,
} from "services/analytics-filters.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";

export type valueComponent =
  | "none"
  | "text"
  | "number"
  | "boolean"
  | "time"
  | "tag_input"
  | "preset_compare"
  | "preset_select"
  | "channel_select";

// const operatorsSettings = Object.values(analyticsQueryOperatorSettings);
const settingToOption = (filterType: AnalyticsFilter["type"]) => (s: any) => {
  const label = typeof s.label === "string" ? s.label : s.label(filterType);
  return { value: s.value, label };
};

const selectOperators = (
  filterType: AnalyticsFilter["type"],
  operators: AnalyticsFilterOperator["operator"][],
) =>
  operators
    .map((o) => analyticsQueryOperatorSettings[o])
    .map(settingToOption(filterType));

// response / respondent_aliases

export const operators = {
  response: {
    respondent_aliases: selectOperators("response", ["eq", "contains"]),
    respondent_segments: selectOperators("respondent", ["in", "not_in"]),
    raw: selectOperators("response", ["contains"]),
    channel_id: selectOperators("respondent", ["in"]),
    multiple_choice: selectOperators("respondent", ["in", "not_null"]),
    language: selectOperators("response", ["in"]),
    emotions: selectOperators("response", ["in", "not_in"]),
    answer: {
      tags: selectOperators("response", ["in", "not_in"]),
      types: selectOperators("response.answer", ["in", "not_in"]),
      string: selectOperators("response.answer", [
        "eq",
        "not_null",
        "contains",
      ]),
      numeric: selectOperators("response.answer", [
        "eq",
        "not_null",
        "gt",
        "lt",
      ]),
      // @TODO: other types
    },
    answerPreset: selectOperators("response.answer", [
      "in",
      "not_null",
      "preset.gt",
      "preset.lt",
    ]),
    hiddenField: selectOperators("response.hidden_field", [
      "eq",
      "not_null",
      "contains",
      "gt",
      "lt",
      "before",
      "after",
    ]),
  },

  respondent: {
    created_at: selectOperators("respondent", ["before", "after"]),
    last_activity_at: selectOperators("respondent", ["before", "after"]),
    aliases: selectOperators("respondent", ["in", "not_in", "contains"]),
    segment: selectOperators("respondent", ["in", "not_in"]),
    event: selectOperators("respondent.event", [
      "eq",
      "not_null",
      "null",
      "gt",
      "lt",
    ]), // @TODO: add first_after, first_before, last_after, last_before
    property: {
      string: selectOperators("respondent.property", [
        "eq",
        "not_null",
        "contains",
      ]),
      numeric: selectOperators("respondent.property", [
        "eq",
        "not_null",
        "gt",
        "lt",
      ]),
      boolean: selectOperators("respondent.property", ["eq", "not_null"]),
      time: selectOperators("respondent.property", ["before", "after"]),
    },
  },
};

export const valueComponents = {
  response: {
    respondent_aliases: "text" as valueComponent,
    respondent_segments: "segment_select" as valueComponent,
    raw: "text" as valueComponent,
    channel_id: "channel_select" as valueComponent,
    language: "language_select" as valueComponent,
    emotions: "emotions_select" as valueComponent,
    answer: {
      tags: "tag_select" as valueComponent,
      types: "types_select" as valueComponent,
      string: "text" as valueComponent,
      number: "number" as valueComponent,
      boolean: "boolean" as valueComponent,
      time: "time" as valueComponent,
      // @TODO: other types
    },
    multipleChoice: (filter: AnalyticsFilterResponseAnswer): valueComponent => {
      switch (filter.operator) {
        case "not_null":
          return "none";
        case "in":
          return "preset_select";
      }
    },
    answerPreset: (filter: AnalyticsFilterResponseAnswer): valueComponent => {
      switch (filter.operator) {
        case "not_null":
          return "none";
        case "in":
          return "preset_select";
        case "preset.lt":
        case "preset.gt":
          return "preset_compare";
      }
    },
    hiddenField: (
      filter: AnalyticsFilterResponseHiddenField,
    ): valueComponent => {
      switch (filter.operator) {
        case "not_null":
          return "none";
        case "eq":
        case "contains":
          return "text";
        case "gt":
        case "lt":
          return "number";
        case "before":
        case "after":
          return "time";
      }
    },
  },

  respondent: {
    aliases: (filter: AnalyticsFilterUser): valueComponent => {
      if (filter.operator === "in" || filter.operator === "not_in") {
        return "tag_input";
      }
      return "text";
    },
    created_at: "time" as valueComponent,
    last_activity_at: "time" as valueComponent,
    segment: "segment_select" as valueComponent,
    event: (filter: AnalyticsFilterUserEvent): valueComponent => {
      // @TODO: add first_after, first_before, last_after, last_before
      switch (filter.operator) {
        case "not_null":
          return "none";
        case "eq":
        case "gt":
        case "lt":
          return "number";
      }
    },
    property: {
      string: "text" as valueComponent,
      numeric: "number" as valueComponent,
      boolean: "boolean" as valueComponent,
      time: "time" as valueComponent,
    },
  },
};

export enum FilterGroup {
  Question = "Question",
  HiddenField = "Hidden field",
  UserProperty = "User property",
  UserEvent = "User event",
}

const filterGroupOrder = [
  FilterGroup.Question,
  FilterGroup.HiddenField,
  FilterGroup.UserProperty,
  FilterGroup.UserEvent,
];

export type AvailableField = {
  value: {
    type: AnalyticsFilter["type"];
    key: AnalyticsFilter["key"];
  };
  label: string;
  groupLabel?: FilterGroup;
};

export class CriteriaFilterStatsSurveyComponent {
  public channels: Channel[] = null;

  public nodes: GraphNode[] = [];
  public nodesByKey: object = {};

  public loadingDropdown = false;
  public visibleDropdown = false;

  public lastFilters: AnalyticsQuery;
  public filters: AnalyticsFilters = null;
  public filtersValidation: object[] = null;
  public filtersOperator: AnalyticsFiltersOperator = null;

  public keysOptions: NzSelectOptionInterface[] = [];

  public registryEntriesGroup: RegistryEntry[] = [];

  public onlyDropdown = false;

  public availableLanguages: LanguageWithEmoji[];
  public availableTags: NzSelectOptionInterface[] = [];

  public filterGroupOrder = filterGroupOrder;
  protected operators = operators;
  protected valueComponents = valueComponents;

  public title = "Segment data";

  constructor(
    protected analyticsFilterService: AnalyticsFilterService,
    protected channelDao: ChannelDao,
    protected trackersService: TrackersService,
    protected uiService: UIService,
  ) {}

  public async switchPanelVisibility(visible: boolean) {
    if (visible) {
      this.loadingDropdown = true;
      this.visibleDropdown = true;
    } else {
      this.closePanel();
      this.visibleDropdown = false;
    }
  }

  protected getOrg(): Org {
    return null;
  }

  protected getSurvey(): Survey {
    return null;
  }

  public fetchChannels(): Promise<Channel[]> {
    return this.channelDao.getAllByOrgId(this.uiService.currentOrgId);
  }

  public onSave() {
    this.filters.forEach((f) => {
      if (f.value && isDate(f.value)) {
        f.value = f.operator
          ? f.operator === "before"
            ? startOfDay(f.value)
            : endOfDay(f.value)
          : f.value;
      }
    });

    this.analyticsFilterService.setFilters(this.filtersOperator, this.filters);

    const trackingEvent = this.trackersService
      .newEventTrackingBuilder("Reporting Segmented")
      .withOrg(this.getOrg())
      .withAnalyticsFilters(this.filters, this.lastFilters.type);

    if (this.lastFilters.type === "response") {
      trackingEvent.withSurvey(this.getSurvey());
    }

    trackingEvent.build();

    this.switchPanelVisibility(false);

    this.visibleDropdown = false;
  }

  protected closePanel() {
    this.lastFilters = null;
    this.filters = null;
    this.filtersValidation = null;
    this.filtersOperator = null;
  }

  protected getAvailableFields() {
    this.keysOptions = [];
  }

  protected getDefaultFilter(_channels): AnalyticsFilter {
    return null;
  }

  /**
   * Filters CRUD / validation
   */
  public onFilterAdded(channels: Channel[]) {
    this.filters.push(
      this.getDefaultFilter(channels) as never, // wtf
    );
  }

  /**
   * Filter keys
   */
  public compareFilterKeys(a: AnalyticsFilter, b: AnalyticsFilter): boolean {
    return (
      !!a &&
      !!b &&
      !!a.type && // && !!b.type
      !!a.key && // && !!b.key
      a.type === b.type &&
      a.key === b.key
    );
  }

  public onFilterKeyChange(f: AnalyticsFilter, { type, key }) {
    f.type = type;
    f.key = key;

    const availbleOperators = this.getOperatorOptions(f).map(
      (opt) => opt.value,
    );
    if (!availbleOperators.includes(f.operator)) {
      f.operator = availbleOperators[0];
    }
    this.onOperatorChange(f);
  }

  /**
   * Filter operators
   */
  public onOperatorChange(f: AnalyticsFilter) {
    this.setDefaultValue(f);
  }

  // inherited
  public getOperatorOptions(_: AnalyticsFilter): NzSelectOptionInterface[] {
    return [];
  }

  /**
   * Filter value
   */
  public setDefaultValue(f: AnalyticsFilter) {
    (f as any).value = null;
    (f as any).values = null;
    (f as any).action_correlation_ids = null;

    const valueComponent = this.getValueComponent(f);

    if (valueComponent === "boolean") {
      (f as any).value = true;
    }
  }

  // inherited
  public getValueComponent(_: AnalyticsFilter): valueComponent {
    return "none";
  }

  public getValueSuffix(filter: AnalyticsFilter): string | null {
    if (filter.type === "respondent.event" && filter.operator !== "not_null") {
      return "times";
    }
    return null;
  }

  public getValueNumberMin(filter: AnalyticsFilter): number | null {
    if (
      filter.type === "respondent.event" &&
      ["gt", "lt"].includes(filter.operator)
    ) {
      return 0;
    } else if (
      filter.type === "respondent.event" &&
      ["eq"].includes(filter.operator)
    ) {
      return 1;
    }
    return null;
  }

  public getValuePresetOptions(_: AnalyticsFilter): NzSelectOptionInterface[] {
    return [];
  }

  public getLabel(_: AnalyticsFilter, __: string): string {
    return "";
  }

  public getValue(_: AnalyticsFilter, __: string): string | number {
    return "";
  }

  public onPresetCompareChange(_: AnalyticsFilter) {}

  public onDateChange(f: AnalyticsFilter) {
    if (["after", "before"].includes(f.operator)) {
      (f as any).value = startOfDay((f as any).value);
    }
  }
}
