import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";

import { ApplicationLoadService } from "services/app.service";
import { SettingsService } from "services/settings.service";
import { UIService } from "services/ui.service";
import { UpgradeAdminService } from "services/upgrade.service";

@Injectable()
class AppResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private settingsService: SettingsService,
    private uiService: UIService,
    private upgradeAdminService: UpgradeAdminService,
  ) {}

  resolve(): Promise<boolean> {
    return this.settingsService
      .load()
      .then(() => this.applicationLoadService.loader())
      .then(() => this.upgradeAdminService.initialize())
      .then(() => this.uiService.fetchEverything())
      .then(() => true)
      .catch((err) => {
        console.error(err);
        return false;
      });
  }
}

export { AppResolver };
