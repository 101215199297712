import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";
import { hookIntegrationsSpec } from "models/hook.model";
import {
  Integration,
  IntegrationSettings,
  IntegrationSettingsZapier,
  IntegrationSettingsZapierItem,
} from "models/integrations.model";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "integration-settings-zapier",
  templateUrl: "./zapier-settings.component.html",
  styleUrls: ["./zapier-settings.component.scss"],
})
export class IntegrationSettingsZapierComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public survey: Survey = null;
  @Input() public integration: Integration = null;
  @Input() public createIfEmpty: boolean = true;
  @Input() public hideListOnEmpty: boolean = false;
  @Input() public scope: "org" | "survey" = "org";

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix,@angular-eslint/no-output-rename
  @Output("onSettingsChange") public settings =
    new EventEmitter<integrationUpdate>();

  private currentlyExpandedItem: number = -1;
  private newItemId: string = null;

  constructor(private trackersService: TrackersService) {}

  ngOnInit() {
    if (
      this.createIfEmpty &&
      this.integration.settings.zapier.items.length === 0
    )
      this.onItemAdd();
  }

  public pruneNewItems() {
    this.integration.settings.zapier.items =
      this.integration.settings.zapier.items.filter(
        (item) => item.id !== this.newItemId,
      );
  }

  public onItemAdd() {
    if (!this.integration.settings)
      this.integration.settings = new IntegrationSettings();
    if (!this.integration.settings.zapier)
      this.integration.settings.zapier = IntegrationSettingsZapier.New();

    this.pruneNewItems();

    const newItem = IntegrationSettingsZapierItem.New(
      hookIntegrationsSpec.zapier.currentVersion,
    );
    this.integration.settings.zapier.items.push(newItem);
    this.integration.settings.zapier.items = [].concat(
      ...this.integration.settings.zapier.items,
    ); // triggers @Input() refresh

    this.expandItem(this.integration.settings.zapier.items.length - 1);

    this.newItemId = newItem.id; // mark item as new, in order to remove it if we cancel edition
  }

  public onItemSaved(index: number, item: IntegrationSettingsZapierItem) {
    const createMode =
      this.integration.settings.zapier.items[index].id === this.newItemId;
    if (createMode) {
      this.newItemId = null;
    }

    // We don't override 'enabled' param here
    this.integration.settings.zapier.items[index].id = item.id;
    this.integration.settings.zapier.items[index].version = item.version;
    this.integration.settings.zapier.items[index].name = item.name;
    this.integration.settings.zapier.items[index].path = item.path;
    this.integration.settings.zapier.items[index].hook = item.hook;

    this.propagateItemChange();
    this.noExpand();

    this.trackChange(
      createMode
        ? "Integration Zapier hook added"
        : "Integration Zapier hook updated",
      createMode
        ? "Integration Zapier survey hook added"
        : "Integration Zapier survey hook updated",
      item,
    );
  }

  public onItemStatusChange(index: number) {
    this.trackChange(
      this.integration.settings.zapier.items[index].enabled
        ? "Integration Zapier hook disabled"
        : "Integration Zapier hook enabled",
      this.integration.settings.zapier.items[index].enabled
        ? "Integration Zapier survey hook disabled"
        : "Integration Zapier survey hook enabled",
      this.integration.settings.zapier.items[index],
    );

    this.integration.settings.zapier.items[index].enabled =
      !this.integration.settings.zapier.items[index].enabled;
    this.propagateItemChange();
  }

  public onItemCanceled() {
    this.noExpand();
  }

  public onItemRemoved(index: number) {
    this.noExpand();

    this.integration.settings.zapier.items.splice(index, 1);
    this.integration.settings.zapier.items = [].concat(
      ...this.integration.settings.zapier.items,
    ); // triggers @Input() refresh

    this.propagateItemChange();

    this.trackChange(
      "Integration Zapier hook removed",
      "Integration Zapier survey hook removed",
      null,
    );
  }

  private propagateItemChange() {
    this.pruneNewItems();
    this.settings.emit({ settings: this.integration.settings });
  }

  private trackChange(
    eventNameOrg: TrackingEventName,
    eventNameSurvey: TrackingEventName,
    item?: IntegrationSettingsZapierItem,
  ) {
    const eventName = this.scope === "org" ? eventNameOrg : eventNameSurvey;

    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withIntegration(this.integration) // if `integration` is null, no property will be added
      .withSurvey(this.survey) // if `survey` is null, no property will be added
      .withProps({
        hook_item_id: item?.id,
        hook_item_version: item?.version,
        hook_item_enabled: item?.enabled,
        hook_item_name: item?.name,
        hook_item_path: item?.path,
        hook_item_hook: item?.hook,
      })
      .build();
  }

  /**
   * Collapsible
   */
  public isItemExpanded(i: number): boolean {
    return this.currentlyExpandedItem === i;
  }

  public expandItem(i: number) {
    if (this.currentlyExpandedItem !== -1) this.pruneNewItems();

    this.currentlyExpandedItem = this.isItemExpanded(i) ? -1 : i;
  }

  public noExpand() {
    this.currentlyExpandedItem = -1;
    this.pruneNewItems();
  }
}
