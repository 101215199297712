import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChartData, ChartDataset } from "chart.js";

import { PageComponentInterface } from "components/PageComponentInterface";
import { RadarIndicatorLegend } from "components/surveys/pages/stats/indicators/components/radar-indicator/radar-indicator.component";
import {
  bucketToDatedAggregation,
  computeVariation,
  getDateAnswerValueBuckets,
  getIndustryAverageScore,
  SimpleBucket,
} from "components/surveys/pages/stats/indicators/indicator.utils";
import {
  bucketToNpsAggregation,
  computeNpsPeriod,
  NpsAggregation,
} from "components/surveys/pages/stats/indicators/nps/nps.aggregation";
import {
  differenceInDays,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMinutes,
  subMonths,
  subWeeks,
} from "date-fns";
import { Account } from "models/account.model";
import { AnalyticsDao } from "models/analytics.dao";
import {
  AnalyticsQueryResponse,
  AnalyticsQueryUsers,
} from "models/analytics.filters.type";
import {
  AnalyticsResponseItemResponseEmotions,
  AnalyticsResponseItemUser,
} from "models/analytics.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { IndustriesScores } from "resolvers/asset-industries-scores";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { SessionService } from "services/auth.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { formatNumber } from "utils/number";
import { deepEqual } from "utils/object";
import {
  fakeActivity,
  fakeNpsAggregation,
  fakePlatformRepatition,
} from "./overview.component.fixtures";
import { RANDOM_MSGS } from "components/utils/card-quote-of-the-day/card-quote-of-the-day.component";

const MINI_TREND_GRAPH_MAX_BUCKETS = 30;

@Component({
  selector: "page-org-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"],
})
export class OverviewOrgPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Workspace overview";
  public name = "Workspace overview";

  private obs: any = null;

  public error: Error;
  public initialFetch = true;
  public loadings = {
    firstLoading: true,
    getNps: true,
    getNewRespondents: true,
    getCurrentActiveRespondents: true,
    getDailyActiveRespondents: true,
    getWeeklyActiveRespondents: true,
    getMonthlyActiveRespondents: true,
    getActivityTrendEvents: true,
    getSpecificRespondentsMostActive: true,
    getEmotions: true,
    getAnswersPlatformRepartition: true,
    getRespondentIdentificationRepartition: true,
  };

  public org: Org = null;
  public orgAccounts: Account[] = [];
  public surveys: Survey[] = [];
  public registryEntriesGroup: RegistryEntry[] = [];

  public orgHasRespondents = true;
  public orgHasResponses = false;
  public lastInAppSurveys: Survey[] = [];
  public industryAverageNps = 50;
  public industryAverageResponseRate = 0;
  public npsAggregation: NpsAggregation = null;
  public npsAggregationPerDate: NpsAggregation[] = null;
  public npsAggregationDiff = 0;
  public currentActiveRespondents = 0;
  public newRespondents = 0;
  public newRespondentsDiff = 0;
  public dailyActiveRespondents = 0;
  public dailyActiveRespondentsDiff = 0;
  public weeklyActiveRespondents = 0;
  public weeklyActiveRespondentsDiff = 0;
  public monthlyActiveRespondents = 0;
  public monthlyActiveRespondentsDiff = 0;
  public mostActiveRespondents: AnalyticsResponseItemUser[] = [];
  public respondentDeviceData: ChartData<"doughnut"> = { datasets: [] };
  public respondentIdentificationData: ChartData<"doughnut"> = { datasets: [] };

  private orgEmotions: AnalyticsResponseItemResponseEmotions = null;
  public emotionsChartDatasets: ChartDataset[] = [];
  public emotionsChartLegend: RadarIndicatorLegend = [
    {
      label: "Organization",
      value: "Organization",
      checked: true,
      disabled: false,
      color: "#1ED5A4",
      hoverColor: "#1ED5A4",
    },
    {
      label: "Industry",
      value: "Industry",
      checked: true,
      disabled: false,
      color: "#0054B6",
      hoverColor: "#0054B6",
      format: "number",
    },
  ];

  public formatNumber = formatNumber;

  public npsDatasets: ChartDataset[] = [];
  public activityDatasets: ChartDataset[] = [];

  public helloMessage: string = "";
  public formattedDiffIndustryAverageNps: string = "";
  public mostActiveRespondentsIds: string[] = [];

  public quoteOfTheDay =
    RANDOM_MSGS[new Date().getDate() % RANDOM_MSGS.length].split(" — ");

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private analyticsDao: AnalyticsDao,
    private analyticsFilterService: AnalyticsFilterService,
    public sessionService: SessionService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.helloMessage = this.getHelloMessage();

    this.obs = this.route.data.subscribe(async (data) => {
      this.org = data.org;
      this.orgAccounts = data.orgAccounts;
      this.surveys = data.surveys;
      this.registryEntriesGroup = data.registryEntriesGroup.groups;

      this.emotionsChartLegend.find(
        (legend) => legend.value === "Organization",
      ).label = this.org.name;

      const hasAtLeastOneSurveyLaunched =
        this.surveys.length &&
        this.surveys.some(({ survey_distributions }) =>
          survey_distributions?.some(({ enabled }) => enabled),
        );

      if (
        !this.org.stats?.total_respondents ||
        (!hasAtLeastOneSurveyLaunched &&
          !this.org.stats?.total_survey_responses)
      ) {
        this.router.navigate(["org", this.org.id, "quickstart"]);
        return;
      }

      this.industryAverageNps = getIndustryAverageScore(
        data.industriesScores as IndustriesScores,
        "nps",
        data.org.industry,
      );
      this.industryAverageResponseRate = getIndustryAverageScore(
        data.industriesScores as IndustriesScores,
        "completionRate",
        data.org.industry,
      );
      this.lastInAppSurveys = this.surveys
        .sort((a, b) => +b.updated_at - +a.updated_at)
        .slice(0, 5);

      this.getStats().then(() => {
        this.formattedDiffIndustryAverageNps =
          this.getFormattedDiffIndustryAverageNps();
        this.mostActiveRespondentsIds = this.getMostActiveRespondentsIds();
      });

      this.getOrgEmotions().then(() => {
        this.updateEmotionsDatasets(data.industriesScores as IndustriesScores);
      });
    });
  }

  private getMostActiveRespondentsIds() {
    return this.mostActiveRespondents.map(({ id }) => id);
  }

  private async getStats() {
    this.error = null;

    try {
      this.orgHasRespondents =
        this.org.stats.total_respondents > 0 ||
        (await this.analyticsFilterService.workspaceHasUsers(this.org.id));
      this.loadFakeData();

      await Promise.all([
        this.getNps(),
        this.getNewRespondents(),
        this.getCurrentActiveRespondents(),
        this.getDailyActiveRespondents(),
        this.getWeeklyActiveRespondents(),
        this.getMonthlyActiveRespondents(),
        this.getActivityTrendEvents(),
        this.getAnswersPlatformRepartition(),
        this.getSpecificRespondentsMostActive(),
      ]);

      await this.getRespondentIdentificationRepartition(); // need to wait for getMonthlyActiveRespondents
    } catch (err) {
      this.error = err;
      console.error(err);
    }

    this.initialFetch = false;
  }

  loadFakeData() {
    this.loadings.firstLoading = false;
    this.npsAggregation = fakeNpsAggregation[0];
    this.npsAggregationDiff = 9;
    this.currentActiveRespondents = 768;
    this.newRespondents = 432;
    this.newRespondentsDiff = -15;
    this.dailyActiveRespondents = 2749;
    this.dailyActiveRespondentsDiff = 12;
    this.weeklyActiveRespondents = 7564;
    this.weeklyActiveRespondentsDiff = -6;
    this.monthlyActiveRespondents = 12453;
    this.monthlyActiveRespondentsDiff = 7;
    this.respondentDeviceData = this.getAnswersPlatformRepartitionData(
      fakePlatformRepatition,
    );
    this.respondentIdentificationData =
      this.getRespondentIdentificationRepartitionData(
        this.monthlyActiveRespondents / 1.5,
      );
    this.npsDatasets = this.getNpsDatasets(fakeNpsAggregation);
    this.activityDatasets = this.getActivityDatasets(fakeActivity);
  }

  protected getNbrDateBucket(): number {
    const nbrBuckets = differenceInDays(new Date(), this.org.created_at) + 1;

    if (nbrBuckets > MINI_TREND_GRAPH_MAX_BUCKETS) {
      return MINI_TREND_GRAPH_MAX_BUCKETS;
    }

    return nbrBuckets;
  }

  private async getNpsForRange(start: Date, end: Date) {
    const query: AnalyticsQueryResponse = {
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      // survey_ids: this.surveys.map(({ id }) => UUID(id)),
      filters_bool: "AND",
      type: "response",
      filters: [],
      range: {
        start,
        end,
        field: "created_at",
      },
      having_answer_only: true,
      aggregation: [
        {
          by: "by_date",
          params: {
            date_histogram_min_interval: "day",
            date_histogram_buckets: this.getNbrDateBucket(),
            // we have to change the sign of timezone, because Javascript sucks
            // this is pure shit
            // https://stackoverflow.com/questions/55564508/pie-chart-js-display-a-no-data-held-message
            date_histogram_timezone_offset: -new Date().getTimezoneOffset(),
          },
        },
        {
          by: "by_answer.value",
          params: {
            cta_type: "nps",
          },
        },
      ],
      size: 10,
    };

    const response = await this.analyticsDao.search(query);

    const npsAggregationPerDate = bucketToDatedAggregation(
      response,
      (buckets) =>
        bucketToNpsAggregation(
          buckets,
          getDateAnswerValueBuckets,
          this.surveys.map(({ scenario }) => scenario),
        ),
    );

    const npsAggregation = computeNpsPeriod(npsAggregationPerDate);

    return {
      npsAggregation,
      npsAggregationPerDate,
    };
  }

  private getNpsDatasets(npsAggregationPerDate: NpsAggregation[]) {
    return [
      {
        yAxisID: "y",
        data: npsAggregationPerDate.map(({ score, date }) => ({
          x: +date,
          y: score,
        })),
      },
      {
        yAxisID: "y",
        data: [
          {
            x: +npsAggregationPerDate[0]?.date || +new Date(),
            y: this.industryAverageNps,
          },
          {
            x:
              +npsAggregationPerDate[npsAggregationPerDate.length - 1]?.date ||
              +new Date(),
            y: this.industryAverageNps,
          },
        ],
        borderColor: "rgba(94, 33, 241, 0.5)",
        borderWidth: 1,
        borderDash: [4, 8],
        borderDashOffset: 0,
        fill: false,
      },
    ];
  }

  private async getNps() {
    if (!this.surveys.length) {
      this.loadings.getNps = false;
      return;
    }

    this.loadings.getNps = true;

    const { npsAggregation, npsAggregationPerDate } = await this.getNpsForRange(
      this.getStartDate(),
      new Date(),
    );
    const { npsAggregation: npsAggregationDiff } = await this.getNpsForRange(
      subMonths(this.getStartDate(), 1),
      this.getStartDate(),
    );

    this.npsAggregation = npsAggregation;
    this.npsAggregationDiff = computeVariation(
      npsAggregationDiff.score,
      npsAggregation.score,
    );
    this.npsDatasets = this.getNpsDatasets(npsAggregationPerDate);

    this.loadings.getNps = false;
  }

  private getRespondentsCountQuery(
    start: Date,
    field: "last_activity_at" | "created_at",
    end = new Date(),
  ): AnalyticsQueryUsers {
    return {
      type: "respondent",
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      identified_only: false,
      filters_bool: "AND",
      filters: [],
      range: {
        start,
        end,
        field,
      },
      size: 0,
    };
  }

  private async getNewRespondents() {
    if (!this.orgHasRespondents) {
      this.loadings.getNewRespondents = false;
      return;
    }

    this.loadings.getNewRespondents = true;

    const startDate = this.getStartDate();
    const response = await this.analyticsDao.search(
      this.getRespondentsCountQuery(startDate, "created_at"),
    );
    const responseDiff = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        subMonths(startDate, 1),
        "created_at",
        startDate,
      ),
    );

    this.newRespondents = response.hits.total;
    this.newRespondentsDiff = Math.round(
      computeVariation(responseDiff.hits.total, response.hits.total),
    );
    this.loadings.getNewRespondents = false;
  }

  private async getCurrentActiveRespondents() {
    if (!this.orgHasRespondents) {
      this.loadings.getCurrentActiveRespondents = false;
      return;
    }

    this.loadings.getCurrentActiveRespondents = true;

    const response = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        subMinutes(new Date(), 5),
        "last_activity_at",
      ),
    );

    this.currentActiveRespondents = response.hits.total;
    this.loadings.getCurrentActiveRespondents = false;
  }

  private async getDailyActiveRespondents() {
    if (!this.orgHasRespondents) {
      this.loadings.getDailyActiveRespondents = false;
      return;
    }

    this.loadings.getDailyActiveRespondents = true;

    const startDate = startOfDay(new Date());
    const response = await this.analyticsDao.search(
      this.getRespondentsCountQuery(startDate, "last_activity_at"),
    );
    const responseDiff = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        subDays(startDate, 1),
        "last_activity_at",
        startDate,
      ),
    );

    this.dailyActiveRespondents = response.hits.total;
    this.dailyActiveRespondentsDiff = Math.round(
      computeVariation(responseDiff.hits.total, response.hits.total),
    );
    this.loadings.getDailyActiveRespondents = false;
  }

  private async getWeeklyActiveRespondents() {
    if (!this.orgHasRespondents) {
      this.loadings.getWeeklyActiveRespondents = false;
      return;
    }

    this.loadings.getWeeklyActiveRespondents = true;

    const startDate = startOfWeek(new Date());
    const response = await this.analyticsDao.search(
      this.getRespondentsCountQuery(startDate, "last_activity_at"),
    );
    const responseDiff = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        subWeeks(startDate, 1),
        "last_activity_at",
        startDate,
      ),
    );

    this.weeklyActiveRespondents = response.hits.total;
    this.weeklyActiveRespondentsDiff = Math.round(
      computeVariation(responseDiff.hits.total, response.hits.total),
    );
    this.loadings.getWeeklyActiveRespondents = false;
  }

  private async getMonthlyActiveRespondents() {
    if (!this.orgHasRespondents) {
      this.loadings.getMonthlyActiveRespondents = false;
      return;
    }

    this.loadings.getMonthlyActiveRespondents = true;

    const startDate = this.getStartDate();
    const response = await this.analyticsDao.search(
      this.getRespondentsCountQuery(startDate, "last_activity_at"),
    );
    const responseDiff = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        subMonths(startDate, 1),
        "last_activity_at",
        startDate,
      ),
    );

    this.monthlyActiveRespondents = response.hits.total;
    this.monthlyActiveRespondentsDiff = Math.round(
      computeVariation(responseDiff.hits.total, response.hits.total),
    );
    this.loadings.getMonthlyActiveRespondents = false;
  }

  getStartDate() {
    return startOfMonth(new Date());
  }

  getActivityDatasets(buckets: SimpleBucket[]) {
    return [
      {
        yAxisID: "y",
        data: buckets.map(({ key_as_string, doc_count }) => ({
          x: +new Date(key_as_string),
          y: doc_count,
        })),
      },
    ];
  }

  private async getActivityTrendEvents() {
    if (!this.orgHasRespondents) {
      this.loadings.getActivityTrendEvents = false;
      return;
    }

    this.loadings.getActivityTrendEvents = true;

    const query: AnalyticsQueryUsers = {
      type: "respondent",
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      // survey_ids: this.surveys.map(({ id }) => UUID(id)),
      filters_bool: "AND",
      filters: [],
      identified_only: false,
      size: 0,
      range: {
        start: this.getStartDate(),
        end: new Date(),
        field: "last_activity_at",
      },
      aggregation: [
        {
          by: "by_event.date",
          params: {
            date_histogram_min_interval: "day",
            date_histogram_buckets: this.getNbrDateBucket(),
            // we have to change the sign of timezone, because Javascript sucks
            // this is pure shit
            // https://stackoverflow.com/questions/55564508/pie-chart-js-display-a-no-data-held-message
            date_histogram_timezone_offset: -new Date().getTimezoneOffset(),
          },
        },
      ],
    };

    const response = await this.analyticsDao.search(query);

    this.activityDatasets = this.getActivityDatasets(
      response.aggregations?.event?.date?.buckets ?? [],
    );

    this.loadings.getActivityTrendEvents = false;
  }

  public async getSpecificRespondentsMostActive() {
    if (!this.orgHasRespondents) {
      this.loadings.getSpecificRespondentsMostActive = false;
      return;
    }

    this.loadings.getSpecificRespondentsMostActive = true;

    const query: AnalyticsQueryUsers = {
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      // survey_ids: this.surveys.map(({ id }) => UUID(id)),
      filters_bool: "AND",
      type: "respondent",
      filters: [],
      identified_only: false,
      range: {
        start: this.getStartDate(),
        end: new Date(),
        field: "last_activity_at",
      },
      sort: { field: "respondent.events_count", order: "desc" },
      size: 5,
    };
    const response = await this.analyticsDao.search(query);
    this.mostActiveRespondents = response.hits.respondents ?? [];

    this.loadings.getSpecificRespondentsMostActive = false;
  }

  getAnswersPlatformRepartitionData(buckets: SimpleBucket[]) {
    const style = window.getComputedStyle(document.body);
    return {
      labels: ["Desktop", "Mobile"],
      datasets: [
        {
          data: [
            buckets.find(({ key }) => key === "desktop")?.doc_count ?? 0,
            buckets.find(({ key }) => key === "mobile")?.doc_count ?? 0,
          ],
          backgroundColor: [
            style.getPropertyValue("--screeb-color-purple-500"),
            style.getPropertyValue("--screeb-color-green-500"),
          ],
          spacing: 0,
          borderColor: "transparent",
          hoverBorderColor: "transparent",
        },
      ],
    };
  }

  public async getAnswersPlatformRepartition() {
    if (!this.surveys.length || !this.orgHasRespondents) {
      this.loadings.getAnswersPlatformRepartition = false;
      return;
    }

    this.loadings.getAnswersPlatformRepartition = true;

    const query: AnalyticsQueryResponse = {
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      // survey_ids: this.surveys.map(({ id }) => UUID(id)),
      filters_bool: "AND",
      type: "response",
      filters: [],
      range: {
        start: this.getStartDate(),
        end: new Date(),
        field: "created_at",
      },
      aggregation: [
        {
          by: "by_hidden_field.value",
          params: {
            hidden_field_key: "support",
          },
        },
      ],
      size: 0,
    };
    const response = await this.analyticsDao.search(query);

    this.respondentDeviceData = this.getAnswersPlatformRepartitionData(
      response.aggregations.hidden_field.hidden_field.hidden_field.buckets,
    );
    this.orgHasResponses = true;

    if (deepEqual(this.respondentDeviceData.datasets[0].data, [0, 0])) {
      this.respondentDeviceData = this.getAnswersPlatformRepartitionData(
        fakePlatformRepatition,
      );
      this.orgHasResponses = false;
    }

    this.loadings.getAnswersPlatformRepartition = false;
  }

  // Get average org emotions
  private async getOrgEmotions() {
    this.loadings.getEmotions = true;
    const date = new Date();
    return this.analyticsDao
      .search({
        org_id: this.org.id as UUID,
        survey_ids: ["*"],
        filters_bool: "AND",
        type: "response",
        filters: [],
        size: 0,
        offset: 0,
        range: {
          start: subMonths(date, 1),
          end: date,
          field: "created_at",
        },
        aggregation: [{ by: "by_emotions" }],
      })
      .then((response) => {
        if (!response.aggregations) {
          return;
        }
        const { emotions } = response.aggregations;
        this.orgEmotions = {
          sadness: Math.round((emotions.sadness?.avg?.value ?? 0) * 5),
          joy: Math.round((emotions.joy?.avg?.value ?? 0) * 5),
          anger: Math.round((emotions.anger?.avg?.value ?? 0) * 5),
          fear: Math.round((emotions.fear?.avg?.value ?? 0) * 5),
        };
      })
      .finally(() => {
        this.loadings.getEmotions = false;
      });
  }

  private updateEmotionsDatasets(scores: IndustriesScores) {
    const datasets = [];
    if (this.orgEmotions) {
      datasets.push({
        label: "Organization",
        data: [
          // Let's have a minimum of 0.25 to fix not chart when we only have one positive emotion
          Math.max(this.orgEmotions?.anger, 0.25),
          Math.max(this.orgEmotions?.joy, 0.25),
          Math.max(this.orgEmotions?.fear, 0.25),
          Math.max(this.orgEmotions?.sadness, 0.25),
        ],
        fill: true,
        borderJoinStyle: "round",
        backgroundColor: "rgba(30, 213, 164, .5)",
        pointRadius: 0,
      });
    } else {
      this.emotionsChartLegend.find(
        (legend) => legend.value === "Organization",
      ).disabled = true;
    }

    if (this.org.industry && scores[this.org.industry]) {
      datasets.push({
        label: "Industry",
        data: scores[this.org.industry].scores.emotions.map((e) => e * 5),
        borderDash: [2, 2],
        fill: false,
        borderJoinStyle: "round",
        borderColor: "rgb(94, 33, 241)",
        borderWidth: 1.5,
        pointRadius: 0,
      });
    } else {
      this.emotionsChartLegend.find(
        (legend) => legend.value === "Industry",
      ).disabled = true;
    }
    this.emotionsChartDatasets = datasets;
  }

  getRespondentIdentificationRepartitionData(
    identifiedRespondentsTotal: number,
  ) {
    const style = window.getComputedStyle(document.body);
    return {
      labels: ["Logged in", "Anonymous"],
      datasets: [
        {
          data: [
            identifiedRespondentsTotal,
            this.monthlyActiveRespondents - identifiedRespondentsTotal,
          ],
          backgroundColor: [
            style.getPropertyValue("--screeb-color-green-500"),
            style.getPropertyValue("--screeb-color-grey-300"),
          ],
          spacing: 0,
          borderColor: "transparent",
          hoverBorderColor: "transparent",
        },
      ],
    };
  }

  async getRespondentIdentificationRepartition() {
    if (!this.orgHasRespondents) {
      this.loadings.getRespondentIdentificationRepartition = false;
      return;
    }

    this.loadings.getRespondentIdentificationRepartition = true;

    const query: AnalyticsQueryUsers = {
      type: "respondent",
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      // survey_ids: this.surveys.map(({ id }) => UUID(id)),
      identified_only: true,
      filters_bool: "AND",
      filters: [],
      range: {
        start: this.getStartDate(),
        end: new Date(),
        field: "last_activity_at",
      },
      size: 0,
    };
    const response = await this.analyticsDao.search(query);

    this.respondentIdentificationData =
      this.getRespondentIdentificationRepartitionData(response.hits.total);

    this.loadings.getRespondentIdentificationRepartition = false;
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  private getHelloMessage = () => {
    const hours = new Date().getHours();

    if (hours >= 18 || hours < 5) {
      return "Good evening";
    } else if (hours >= 5 && hours < 12) {
      return "Good morning";
    } else {
      return "Good afternoon";
    }
  };

  private getFormattedDiffIndustryAverageNps() {
    return formatNumber(
      (this.npsAggregation?.score ?? 0) - this.industryAverageNps,
      "points",
      0,
      "force",
    );
  }

  public getAverageDiffColorClass(): string {
    const diff = (this.npsAggregation?.score ?? 0) - this.industryAverageNps;
    if (Math.round(diff) > 0) {
      return "green";
    } else if (Math.round(diff) < 0) {
      return "red";
    }
    return "grey";
  }

  reload() {}
}
