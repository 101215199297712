<label class="lateral-panel-subtitle">Slider emoji</label>

<div class="panel-button-line">
  <button
    #btnElement
    title="Open emoji picker"
    class="emoji-button"
    [disabled]="node.isPreset"
    (click)="openEmojiPicker(btnElement)"
  >
    <sanitized-message-component
      [content]="cta.range.payload.emoji"
      [options]="{
        styling: true,
        emojiTranscoder: true
      }"
    ></sanitized-message-component>
  </button>
</div>
<lateral-panel-emoji-picker-popin
  [topPosition]="emojiPickerPositionTop"
  [rightPosition]="emojiPickerPositionRight"
  [emoji]="cta.range.payload.emoji"
  (onClickOutside)="emojiPickerVisible = false"
  (onEmojiSelected)="
    cta.range.payload.emoji = $event; emojiPickerVisible = false
  "
  *ngIf="emojiPickerVisible"
></lateral-panel-emoji-picker-popin>

<label class="lateral-panel-subtitle">Next step</label>

<lateral-panel-step-picker
  [routing]="node.node.routing"
  [chainableNodeIds]="node.chainableNodeIds"
  [actions]="[cta.range]"
></lateral-panel-step-picker>

<ng-container>
  <label class="lateral-panel-subtitle skip-to-step-title">Skip to step</label>
  <p class="lateral-panel-tip">Allowing users to skip to a next step</p>

  <lateral-panel-step-picker
    [routing]="node.node.routing"
    [chainableNodeIds]="node.chainableNodeIds"
    [actions]="[skipAction]"
    [isSkipAction]="true"
  ></lateral-panel-step-picker>
</ng-container>
