<div>
  <header class="iAppear">
    <h1>Customer Satisfaction</h1>

    <div class="analytics-filters" *ngIf="!!nodes.length">
      <survey-stats-filters
        reportType="csat"
        [canExport]="false"
        [org]="org"
        [survey]="survey"
        [reporting]="'Customer Satisfaction'"
        [languages]="languages"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesGroup]="registryEntriesGroup"
        [registryEntriesEvent]="registryEntriesEvent"
        type="response"
      >
      </survey-stats-filters>
    </div>
  </header>

  <survey-stats-empty-dashboard
    class="iAppear d100"
    *ngIf="nodes.length === 0"
    imgPath="/assets/survey-templates/customer-satisfaction.png"
    title="You don’t collect CSAT in this {{
      uiService.isMessagePage ? 'message' : 'survey'
    }} yet"
    description1="Customer Satisfaction is a great and simple way to measure level of satisfaction of your users. Use it to identify improvements to make to your experience!"
    primaryButtonTxt="Add CSAT to this {{
      uiService.isMessagePage ? 'message' : 'survey'
    }}"
    (primaryButtonCTA)="redirectBuilder()"
    secondaryButtonTxt="Preview this {{
      uiService.isMessagePage ? 'message' : 'survey'
    }}"
    (secondaryButtonCTA)="redirectBuilder()"
  >
  </survey-stats-empty-dashboard>

  <ng-container *ngIf="!!nodes.length">
    <div class="loader" *ngIf="loading && initialFetch">
      <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
    </div>

    <error-message
      *ngIf="error"
      message="An error occured when fetching data."
      [details]="error.message"
      (reload)="analyticsFilterService.triggerChange()"
    >
    </error-message>

    <div class="gridAppear" *ngIf="(!loading || !initialFetch) && !error">
      <nz-empty
        *ngIf="!csatCurrentPeriod || csatCurrentPeriod.total === 0"
        [nzNotFoundContent]="'No answer during this interval'"
      ></nz-empty>

      <ng-container *ngIf="csatCurrentPeriod && csatCurrentPeriod.total > 0">
        <section
          nz-row
          [nzGutter]="[32, 16]"
          class="top-indicators"
          *ngIf="!!csatCurrentPeriod"
        >
          <div nz-col nzXs="24" nzXXl="8">
            <survey-stats-indicators-single-indicator
              class="indicator-with-border"
              title="Average CSAT"
              [value]="csatCurrentPeriod.scoreOnFive"
              [diffPercent]="csatVariation?.scoreOnFive"
              format="number"
              [maximumFractionDigits]="1"
              [scoreMax]="5"
              diffFormat="points"
              imageSrc="/assets/indicators/CSAT-indicator-score.png"
            >
            </survey-stats-indicators-single-indicator>
          </div>
          <div nz-col nzXs="24" nzXl="12" nzXXl="8">
            <survey-stats-indicators-single-indicator
              class="indicator-with-border"
              title="Number of responses"
              [value]="csatCurrentPeriod.total"
              [diffPercent]="csatVariation?.total"
              imageSrc="/assets/indicators/CSAT-indicator-responses.png"
            ></survey-stats-indicators-single-indicator>
          </div>
          <div nz-col nzXs="24" nzXl="12" nzXXl="8">
            <survey-stats-indicators-single-indicator
              class="indicator-with-border"
              title="Percent of low scores (1 to 2)"
              [value]="csatCurrentPeriod.lowScore"
              [diffPercent]="csatVariation?.lowScore"
              format="percent"
              diffFormat="points"
              imageSrc="/assets/indicators/CSAT-indicator-detractors.png"
            >
            </survey-stats-indicators-single-indicator>
          </div>
        </section>

        <section
          nz-row
          [nzGutter]="[32, 16]"
          class="middle-indicators"
          *ngIf="!!csatCurrentPeriod"
        >
          <div nz-col class="fullsize-indicator" nzSpan="24" nzXl="10">
            <h2>You’re right on track!</h2>
            <survey-stats-indicators-vu-meter-indicator
              [value]="csatCurrentPeriod.score"
              [ideal]="industryAverageCsat"
              [min]="0"
              [max]="100"
              [mediumRangeStart]="33.33333"
              [highRangeStart]="66.6666"
              format="percent"
              class="indicator-with-border industry-diff-score"
            >
            </survey-stats-indicators-vu-meter-indicator>
          </div>
          <div nz-col nzFlex="1" class="fullsize-indicator">
            <h2>Results repartition</h2>
            <div class="indicator-with-border">
              <div class="repartition-container">
                <div class="repartition-chart">
                  <survey-stats-indicators-repartition-indicator
                    [values]="csatRepartition"
                    (barClick)="repartitionChartBarClick($event)"
                    emojiSet="csat"
                    chartWidth="300"
                  >
                  </survey-stats-indicators-repartition-indicator>
                </div>
                <div class="repartition-details">
                  <div class="repartition-details-value">
                    {{ computeCsatHappyUsers(csatCurrentPeriod) }}
                  </div>
                  <div class="repartition-details-label">
                    of your users are happy !
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          nz-row
          [nzGutter]="[32, 16]"
          *ngIf="!!csatPerDateCurrentPeriod"
        >
          <div nz-col nzSpan="24">
            <h2>Trend</h2>
            <div
              class="indicator-with-border"
              *ngIf="
                !csatPerDateCurrentPeriod ||
                csatPerDateCurrentPeriod.length <= 1
              "
            >
              <nz-empty
                [nzNotFoundContent]="
                  'Please select a longer period to display trend'
                "
              ></nz-empty>
            </div>
            <div
              class="indicator-with-border trend-indicator"
              *ngIf="
                csatPerDateCurrentPeriod && csatPerDateCurrentPeriod.length > 1
              "
            >
              <survey-stats-indicators-trend-indicator
                theme="csat"
                [datasets]="trendChartDataset"
                [legend]="trendChartLegend"
                [releases]="releases"
                [orgAccounts]="orgAccounts"
                [orgId]="org.id"
              >
              </survey-stats-indicators-trend-indicator>
            </div>
          </div>
        </section>
      </ng-container>
    </div>
  </ng-container>
</div>
