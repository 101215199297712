import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { differenceInHours } from "date-fns";
import { SingleValue } from "ng-zorro-antd/core/time";

@Component({
  selector: "screeb-date-picker",
  template: `
    <div class="date-range" [ngClass]="theme">
      <i class="date-range-icon" nz-icon nzType="calendar"></i>

      <span class="date-range-value"> {{ getFormattedDate() }}</span>

      <i class="date-range-icon-carret" nz-icon nzType="caret-down"></i>
    </div>
    <nz-date-picker
      #DatePicker
      [nzOpen]="calendarOpened"
      [(ngModel)]="value"
      [nzDefaultPickerValue]="value"
      [nzAllowClear]="false"
      [nzShowNow]="true"
      (ngModelChange)="valueChange.emit($event)"
      name="screeb-date-picker"
      [nzShowTime]="showTime"
    />
  `,
  styleUrls: ["./screeb-date-picker.component.scss"],
})
export class ScreebDatePickerComponent {
  public theme: "dark" | "light" = "light";
  @Input() value: Date = new Date();
  @Output() valueChange: EventEmitter<Date> = new EventEmitter();

  @Input() showTime: boolean = false;

  @ViewChild("DatePicker") datePicker;

  calendarOpened: boolean = false;

  constructor(private host: ElementRef<HTMLDivElement>) {}

  public getFormattedDate() {
    if (this.showTime) return this.value.toLocaleString();

    if (Math.abs(differenceInHours(this.value, Date.now())) < 1) return "Now";

    return this.value.toDateString();
  }

  @HostListener("document:mousedown", ["$event"])
  onClick(event) {
    // Fix for https://github.com/NG-ZORRO/ng-zorro-antd/issues/7994
    // https://github.com/NG-ZORRO/ng-zorro-antd/issues/7968
    setTimeout(() => {
      this.value = (
        this.datePicker.datePickerService.value as SingleValue
      )?.nativeDate;
      this.valueChange.emit(this.value);
    }, 300);

    for (const className of event.target.classList) {
      if (className.startsWith("ant-picker")) return;
    }

    this.calendarOpened = this.host.nativeElement.contains(event.target);
  }
}
