import { Component, Input, OnInit } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";
import {
  getNumericValueWithLimits,
  onOrRuleChange,
} from "components/surveys/pages/share/targeting/advanced/components/utils/rule-change";
import { RegistryEntry } from "models/registry.model";

@Component({
  selector: "rule-user-event-count",
  templateUrl: "./rule-user-event-count.component.html",
  styleUrls: ["./rule-user-event-count.component.scss"],
})
export class TargetingRuleUserEventCountComponent implements OnInit {
  @Input() public rule: SurveyTargetingRule = null;
  @Input() public registryEntriesEvent: RegistryEntry[] = [];

  public getNumericValueWithLimits = getNumericValueWithLimits;
  public onOrRuleChange = onOrRuleChange;

  constructor() {}

  ngOnInit() {
    if (
      this.rule.value.v_n_arr !== undefined &&
      this.rule.value.v_n_arr !== null
    ) {
      const last = this.rule.value.v_n_arr.length - 1;
      if (
        this.rule.value.v_n_arr[last] !== null &&
        this.rule.value.v_n_arr[last] !== undefined
      ) {
        this.rule.value.v_n_arr.push(null);
      }
    }
  }

  public trackByFn(index: any) {
    return index;
  }

  public checkValidCountMultiple(
    r: SurveyTargetingRule,
    index: number,
  ): boolean {
    if (
      index === 0 &&
      (r.value.v_n_arr[index] === null || r.value.v_n_arr[index] === undefined)
    )
      return false;
    return true;
  }
  public checkValidCountSingle(r: SurveyTargetingRule): boolean {
    if (r.value.v_n === null || r.value.v_n === undefined) return false;
    return true;
  }
}
