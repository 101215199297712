<div>
  <div class="billing-header">
    <img
      *ngIf="!!superOrg.logo"
      class="logo-img"
      [src]="superOrg.logo"
      alt="{{ superOrg.name }}"
    />
    <h1>{{ superOrg.name }}</h1>

    <intercom-button
      title="Any Question about your Subscription? Chat with us!"
    />

    <a
      *ngIf="'displayStripeShortcut' | hasFeatureFlag"
      nz-button
      nzType="primary"
      nzSize="large"
      class="stripe-customer-link"
      [href]="orgBilling.getStripeAdminURLForCustomer()"
      target="_blank"
    >
      Stripe
    </a>
  </div>

  <nz-tabset
    [(nzSelectedIndex)]="tabIndex"
    [nzAnimated]="false"
    class="billing-navbar"
  >
    <nz-tab nzTitle="Your Subscription">
      <settings-billing-tab-subscription
        [superOrg]="superOrg"
        [orgBilling]="orgBilling"
        [orgBillingSubscription]="orgBillingSubscription"
        [(orgBillingPaymentMethods)]="orgBillingPaymentMethods"
        [orgBillingInvoices]="orgBillingInvoices"
        (onSubscribe)="
          openPanel(
            $event.mode,
            $event.currentSubscription,
            $event.targetedSubscription
          )
        "
        (goToTab)="goToTab($event)"
      >
      </settings-billing-tab-subscription>
    </nz-tab>

    <!-- ************************************ -->

    <nz-tab nzTitle="Payment Method">
      <billing-subscription-paused-subscription-alert
        [orgBilling]="orgBilling"
        (onTabChange)="goToTab($event)"
      >
      </billing-subscription-paused-subscription-alert>

      <settings-billing-tab-payment
        [superOrg]="superOrg"
        [(orgBilling)]="orgBilling"
        [(orgBillingPaymentMethods)]="orgBillingPaymentMethods"
        [countries]="countries"
        (onUpdate)="openPanel($event, null, null)"
      >
      </settings-billing-tab-payment>
    </nz-tab>

    <!-- ************************************ -->

    <nz-tab *ngIf="tabInvoicesIsVisible()" nzTitle="Invoices">
      <billing-subscription-paused-subscription-alert
        [orgBilling]="orgBilling"
        (onTabChange)="goToTab($event)"
      >
      </billing-subscription-paused-subscription-alert>

      <settings-billing-tab-invoices
        [superOrg]="superOrg"
        [orgBillingInvoices]="orgBillingInvoices"
      >
      </settings-billing-tab-invoices>
    </nz-tab>
  </nz-tabset>

  <billing-lateral-panel
    *ngIf="lateralPanelOpened"
    [mode]="[lateralPanelMode]"
    [currentSubscription]="lateralPanelCurrentSubscription"
    [targetedSubscription]="lateralPanelTargetedSubscription"
    [(superOrg)]="superOrg"
    [(orgBilling)]="orgBilling"
    [(orgBillingPaymentMethods)]="orgBillingPaymentMethods"
    [(orgBillingSubscription)]="orgBillingSubscription"
    [countries]="countries"
    (refreshInvoice)="refreshInvoice()"
    (onClose)="closePanel()"
  >
  </billing-lateral-panel>
</div>
