import { Component, Input } from "@angular/core";
import { ChannelType } from "models/channel.model";
import { DateFormatType, formatDate } from "utils/date";
import { formatNumber, NumberFormatType } from "utils/number";

export type IndicatorSurveyStatus = {
  type: ChannelType;
  running: boolean;
};

@Component({
  selector: "survey-stats-indicators-single-indicator",
  templateUrl: "./single-indicator.component.html",
  styleUrls: ["./single-indicator.component.scss"],
})
export class SingleIndicatorStatsSurveyComponent {
  @Input()
  public title: string;
  @Input()
  public boldTitle: boolean = false;
  @Input()
  public value: number | Date | IndicatorSurveyStatus[];
  @Input()
  public diffPercent: number;
  @Input()
  public imageSrc: string;
  @Input()
  public format: NumberFormatType = "number";
  @Input()
  public dateFormat: DateFormatType = "date-only";
  @Input()
  public diffFormat: NumberFormatType = "percent";
  @Input()
  public maximumFractionDigits = 0;
  @Input()
  public thousandsSuffixes = false;
  @Input()
  public scoreMax = 0;
  @Input()
  public diffIndustryAverage: number = null;
  @Input()
  public size: "large" | "medium" | "small" = "large";
  @Input()
  public theme: "dark" | "light" = "light";
  @Input()
  public loading = false;
  @Input()
  public color: string = null;
  @Input()
  public titleLink: string = null;
  @Input()
  public link: string = null;
  @Input()
  public boldLink: boolean = false;

  constructor() {}

  public getFormattedValueNumber(rawValue: number) {
    const value = formatNumber(
      rawValue,
      this.format,
      this.maximumFractionDigits,
      "auto",
      this.thousandsSuffixes,
    );

    return this.scoreMax ? `${value}/${this.scoreMax}` : value;
  }

  public isStatus() {
    return Array.isArray(this.value);
  }

  public getStatusLabel(running: boolean) {
    if (Array.isArray(this.value)) {
      return this.value
        .filter((status) => status.running === running)
        .map(({ type }) => type);
    }
  }

  public getFormattedValue() {
    if (typeof this.value === "number") {
      return this.getFormattedValueNumber(this.value);
    } else if (this.value instanceof Date) {
      return formatDate(this.value, this.dateFormat);
    }
  }

  public getFormattedDiff() {
    return formatNumber(this.diffPercent, this.diffFormat, 0, "hide");
  }

  public getFormattedDiffIndustryAverage() {
    return formatNumber(this.diffIndustryAverage, "points", 0, "force");
  }

  public abs(nbr: number): number {
    return Math.abs(nbr);
  }
  public round(nbr: number): number {
    return Math.round(nbr);
  }
  public isFinite(nbr: number): boolean {
    return isFinite(nbr);
  }

  public getDiffColorClass(): string {
    if (this.round(this.diffPercent) > 0) {
      return "green";
    } else if (this.round(this.diffPercent) < 0) {
      return "red";
    }
    return "grey";
  }

  public getAverageDiffColorClass(): string {
    if (this.round(this.diffIndustryAverage) > 0) {
      return "green";
    } else if (this.round(this.diffIndustryAverage) < 0) {
      return "red";
    }
    return "grey";
  }
}
