import { NotificationHelper } from "helpers/notification.helper";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PageComponentInterface } from "components/PageComponentInterface";
import { AnalyticsDao } from "models/analytics.dao";
import { OrgDao } from "models/org.dao";
import { Org, OrgAnswerTags } from "models/org.model";
import { UUID } from "models/survey.dao.types";
import { SessionService } from "services/auth.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";

@Component({
  selector: "page-settings-tagging",
  templateUrl: "./tagging.component.html",
  styleUrls: ["./tagging.component.scss"],
})
export class SettingsTaggingPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Tagging Preferences";
  public name = "Settings tagging preferences";

  private obs: any = null;

  public org: Org = null;

  public orgAnswerTags: OrgAnswerTags[] = [];

  public allOrgTags: string[] = [];

  public allOrgTagsOptions: NzSelectOptionInterface[] = [];

  public isSave: boolean = true;

  public orgSmartTags: string[] = [];

  public isSmartSave: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public sessionService: SessionService,
    private orgDao: OrgDao,
    public entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
    private analyticsDao: AnalyticsDao,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.getOrgGroupTags();
      this.getAllOrgTags();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  add() {
    this.orgAnswerTags.push(new OrgAnswerTags());
    this.isSave = false;
  }

  save() {
    this.orgDao
      .updateOrgAnswerTags(this.org.id, this.orgAnswerTags)
      .then(() => {
        this.notificationHelper.trigger("Saved", null, "success");
        this.isSave = true;
      })
      .catch((err) => {
        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to save",
          null,
          "error",
        );
      });
  }

  remove(groupTag: any) {
    this.orgAnswerTags.splice(this.orgAnswerTags.indexOf(groupTag), 1);
    this.isSave = false;
  }

  getOrgGroupTags() {
    this.orgAnswerTags = this.org.answer_tags;
    this.orgSmartTags = this.org.smart_answer_tags;
  }

  public onChange($event) {
    this.isSave = $event;
  }

  public getAllOrgTags() {
    this.analyticsDao
      .search({
        org_id: this.org.id as UUID,
        survey_ids: ["*"],
        filters_bool: "AND",
        type: "response",
        filters: [],
        size: 0,
        offset: 0,
        range: {
          field: "created_at",
          start: this.org.created_at,
          end: new Date(),
        },
        aggregation: [{ by: "by_tags" }],
      })
      .then((response) => {
        const tags =
          response.aggregations?.answer?.tags.buckets.map((tag) => tag.key) ??
          [];

        // remove duplicate
        this.allOrgTags = tags.filter(
          (item, index) => tags.indexOf(item) === index,
        );
        this.allOrgTagsOptions = this.stringsToNzSelectOptionInterface(
          this.allOrgTags,
        );
      });
  }

  public saveSmartTagging() {
    this.orgDao
      .updateOrgSmartAnswerTags(this.org.id, this.orgSmartTags)
      .then(() => {
        this.notificationHelper.trigger("Saved", null, "success");
        this.isSmartSave = true;
      })
      .catch((err) => {
        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to save",
          null,
          "error",
        );
      });
  }

  public stringsToNzSelectOptionInterface(tab: string[]) {
    return tab.map((tag) => {
      return {
        label: tag,
        value: tag,
      };
    });
  }
}
