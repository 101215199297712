<div class="lateral-panel" cdkScrollable>
  <div class="lateral-panel-inner" cdkScrollable>
    <!-- header -->
    <header class="lateral-header">
      <div class="left">
        <button (click)="onClose.emit()" class="close">
          <i nz-icon nzType="close" nzTheme="outline"></i>
        </button>
        <h2 class="title" *ngIf="currentMode === 'subscription'">
          🔥 Upgrade to {{ planTypeInfo.name }}
        </h2>
        <h2 class="title" *ngIf="currentMode === 'change_billing_cycle'">
          🔥 Upgrade to Annual Billing
        </h2>
        <h2 class="title" *ngIf="currentMode === 'downgrade'">
          😿 Downgrade to a Free Account
        </h2>
        <h2 class="title" *ngIf="currentMode === 'activate_addon'">
          🔥 Activate Add Ons
        </h2>
        <h2 class="title" *ngIf="currentMode === 'payment_method'">
          Payment Information
        </h2>
        <h2 class="title" *ngIf="currentMode === 'address'">
          Billing information
        </h2>
        <h2 class="title" *ngIf="currentMode === 'confirm_subscription'">
          Confirm Your Subscription
        </h2>
      </div>
      <div class="right"></div>
    </header>

    <div class="lateral-body">
      <div class="lateral-body-content">
        <div
          class="upgrade-payment"
          *ngIf="currentMode === 'change_billing_cycle'"
        >
          <p class="subtitle">
            Opt for an annual billing cycle and save 20% on your subscription!
            Choosing this option will renew your subscription for 12 new months.
          </p>
          <ng-container *ngTemplateOutlet="BillingCycleFormTpl"></ng-container>
          <ng-container *ngTemplateOutlet="BillingSubscriptionSummaryTpl">
          </ng-container>
        </div>

        <div
          class="subscription"
          *ngIf="
            currentMode === 'subscription' || currentMode === 'activate_addon'
          "
        >
          <ng-container *ngIf="targetedSubscription.planType === 'scale'">
            <h3>Monthly Tracked Users</h3>
            <p>
              How many Monthly Active Users or Monthly Unique Visitors do you
              have?
            </p>
            <billing-subscription-mtu-slider
              [minMtu]="getMinimalMTU()"
              [maxMtu]="1000000"
              [(mtu)]="targetedSubscription.mtu"
              (mtuChange)="refreshSubscriptionOptions()"
              (onClickUp)="trackEventMTU($event)"
            >
            </billing-subscription-mtu-slider>
          </ng-container>

          <ng-container *ngIf="targetedSubscription.planType === 'scale'">
            <h3 class="addons-title">🎟️ Add Ons</h3>
            <div
              class="addon"
              [class]="{ active: targetedSubscription.addons.whiteLabel }"
            >
              <label
                nz-checkbox
                [(nzChecked)]="targetedSubscription.addons.whiteLabel"
                (nzCheckedChange)="refreshSubscriptionOptions()"
                >Remove 'Powered by Screeb'</label
              >
              <span>
                {{ getPriceByAddonKey("white-label") | price }}/month
              </span>
            </div>
            <div
              class="addon"
              [class]="{
                active: targetedSubscription.addons.responseTranslation
              }"
            >
              <label
                nz-checkbox
                [(nzChecked)]="targetedSubscription.addons.responseTranslation"
                (nzCheckedChange)="refreshSubscriptionOptions()"
                >Activate Response Translation</label
              >
              <span>
                {{ getPriceByAddonKey("response-translation") | price }}/month
              </span>
            </div>
            <div
              class="addon"
              [class]="{
                active: targetedSubscription.addons.audioVideoSurvey
              }"
            >
              <label
                nz-checkbox
                [(nzChecked)]="targetedSubscription.addons.audioVideoSurvey"
                (nzCheckedChange)="refreshSubscriptionOptions()"
                >Activate Audio/Video Survey</label
              >
              <span>
                {{ getPriceByAddonKey("audio-video-survey") | price }}/month
              </span>
            </div>

            <intercom>
              <div
                class="addon beta"
                [class]="{
                  active: targetedSubscription.addons.additionalWorkspaces > 0
                }"
              >
                <!--
                The select or disabled until we develop the feature.
                Please contact us until then.
                -->
                <label
                  nz-checkbox
                  [nzChecked]="
                    targetedSubscription.addons.additionalWorkspaces > 0
                  "
                  (click)="addWorkspaceClick($event)"
                >
                  <!-- (click)="
                targetedSubscription.addons.additionalWorkspaces =
                  targetedSubscription.addons.additionalWorkspaces || 1;
                refreshSubscriptionOptions()
              " -->
                  Add
                  <nz-input-number
                    [nzMin]="0"
                    [nzDisabled]="
                      targetedSubscription.addons.additionalWorkspaces === 0
                    "
                    [(ngModel)]="
                      targetedSubscription.addons.additionalWorkspaces
                    "
                    (click)="addWorkspaceInputClick($event)"
                    (ngModelChange)="refreshSubscriptionOptions()"
                  />
                  workspaces
                  <utils-badge text="Talk to us"></utils-badge>
                </label>
                <span>
                  {{
                    getPriceByAddonKey("additional-workspaces") | price
                  }}/month
                </span>
              </div>
            </intercom>

            <ng-container *ngTemplateOutlet="BillingCycleFormTpl">
            </ng-container>

            <h3>Coupon</h3>
            <billing-subscription-coupon-input
              [superOrgId]="superOrg.id"
              (coupon)="
                targetedSubscription.coupon = $event;
                refreshSubscriptionOptions()
              "
            >
            </billing-subscription-coupon-input>

            <ng-container *ngTemplateOutlet="BillingSubscriptionSummaryTpl">
            </ng-container>
          </ng-container>
        </div>

        <billing-lateral-panel-downgrade
          *ngIf="currentMode === 'downgrade'"
          [superOrg]="superOrg"
          [currentPlanType]="currentSubscription.planType"
          (onSubscriptionUpdate)="waitForSubscription()"
          (onClose)="onClose.emit()"
        >
        </billing-lateral-panel-downgrade>

        <billing-lateral-panel-payment-method
          *ngIf="currentMode === 'payment_method'"
          [superOrg]="superOrg"
          [orgBilling]="orgBilling"
          [orgBillingPaymentMethods]="orgBillingPaymentMethods"
          [hasNextStep]="mode.length > 1"
          (orgBillingPaymentMethodsChange)="onPaymentMethodUpdate($event)"
          (onClose)="onClose.emit()"
        ></billing-lateral-panel-payment-method>

        <billing-lateral-panel-billing-info
          *ngIf="currentMode === 'address'"
          [superOrg]="superOrg"
          [orgBilling]="orgBilling"
          [hasNextStep]="mode.length > 1"
          [countries]="countries"
          (orgBillingChange)="onBillingInfoUpdate($event)"
          (onClose)="onClose.emit()"
        ></billing-lateral-panel-billing-info>

        <div
          class="confirm-subscription"
          *ngIf="currentMode === 'confirm_subscription'"
        >
          <p class="confirm-message">
            You’re one step away from subscribing to Screeb! Let’s confirm your
            choice.
          </p>

          <h3>Your Screeb Subscription:</h3>

          <ng-container
            *ngTemplateOutlet="BillingSubscriptionSummaryTpl"
          ></ng-container>
        </div>
      </div>

      <!-- bottom buttons -->
      <footer
        class="panel-button-container"
        *ngIf="
          currentMode !== 'downgrade' &&
          currentMode !== 'address' &&
          currentMode !== 'payment_method'
        "
      >
        <intercom-nz-button
          *ngIf="priceError"
          class="contact-us"
          title="An error occurred, please contact us"
          nzType="primary"
          nzSize="large"
        ></intercom-nz-button>
        <ng-container *ngIf="!priceError">
          <button
            class="cancel-btn"
            nz-button
            nzType="default"
            nzSize="large"
            (click)="onClose.emit()"
          >
            Cancel
          </button>
          <button
            *ngIf="
              currentMode === 'subscription' ||
              currentMode === 'change_billing_cycle'
            "
            nz-button
            nzType="primary"
            nzSize="large"
            [nzLoading]="validationLoading"
            (click)="subscribe()"
          >
            Upgrade Now
          </button>
          <button
            *ngIf="currentMode === 'activate_addon'"
            nz-button
            nzType="primary"
            nzSize="large"
            [nzLoading]="validationLoading"
            (click)="subscribe()"
          >
            Activate Add Ons Now
          </button>
          <button
            *ngIf="currentMode === 'confirm_subscription'"
            nz-button
            nzType="primary"
            nzSize="large"
            [nzLoading]="validationLoading"
            (click)="subscribe()"
          >
            Confirm your subscription
          </button>
        </ng-container>
      </footer>
    </div>
  </div>
</div>

<!-- transparent overlay -->
<div class="lateral-panel-backdrop" (click)="onClose.emit()"></div>

<ng-template #BillingSubscriptionSummaryTpl>
  <billing-subscription-invoice-summary
    *ngIf="!priceError"
    [subscriptionOptions]="targetedSubscription"
    (onPriceUpdate)="price = $event; priceError = false"
    (onPriceError)="priceError = true"
  ></billing-subscription-invoice-summary>
</ng-template>

<ng-template #BillingCycleFormTpl>
  <ng-container
    *ngIf="
      targetedSubscription.planType !== 'free' &&
      (currentSubscription.planType === 'free' ||
        currentSubscription.billingCycle === 'month')
    "
  >
    <h3>Billing cycle</h3>
    <nz-radio-group
      [(ngModel)]="targetedSubscription.billingCycle"
      (ngModelChange)="
        refreshSubscriptionOptions(); trackEventBillingCycle($event)
      "
      nzButtonStyle="solid"
      nzSize="large"
    >
      <big-radio [nzValue]="'month'" label="Monthly"></big-radio>
      <big-radio
        [nzValue]="'year'"
        [label]="'Yearly (-' + YearlyDiscount * 100 + '% 🥳)'"
      ></big-radio>
    </nz-radio-group>

    <div class="billing-cycle-banner {{ targetedSubscription.billingCycle }}">
      <ng-container *ngIf="targetedSubscription.billingCycle === 'year'">
        Congrats! You’re saving €{{ getDiscount() }}/month for your company! 🥳
      </ng-container>
      <ng-container *ngIf="targetedSubscription.billingCycle !== 'year'">
        You’re missing out a {{ YearlyDiscount * 100 }}% discount opportunity!
        Save money by selecting the Annual Billing Cycle now!
      </ng-container>
    </div>
  </ng-container>
</ng-template>
