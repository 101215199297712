<div class="emoji-picker-popin-backdrop" (click)="onClickOutside.emit()"></div>
<div
  class="emoji-picker-popin-container"
  [style.top.px]="topPosition"
  [style.right.px]="rightPosition"
>
  <div class="emoji-picker-popin">
    <emoji-mart
      (emojiSelect)="onSelected($event)"
      [showPreview]="false"
      [isNative]="false"
      [enableSearch]="true"
      [darkMode]="false"
      [autoFocus]="true"
      [virtualize]="false"
      set="twitter"
      title="Pick your emoji"
      [emoji]="emoji"
      [sheetSize]="64"
      [imageUrlFn]="imageUrlFn"
      [virtualizeOffset]="150"
    >
    </emoji-mart>
    <button
      *ngIf="canBeNull"
      class="no-emoji-button"
      nz-button
      nzType="default"
      nzSize="large"
      (click)="onReset()"
    >
      <i nz-icon nzType="delete"></i>
      <span>No emoji</span>
    </button>
  </div>
</div>
