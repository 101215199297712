<div *ngIf="rule">
  <div class="rule-type-recurrence">
    <div class="rule-type-recurrence-content">
      <p class="rule-type-recurrence-title">Ask again</p>
      <nz-input-group
        [nzSuffix]="'days'"
        [nzPrefix]="'Every:'"
        [ngClass]="{ invalid: !checkValidValue(rule) }"
      >
        <rule-input-number
          [(value)]="rule.value.v_n"
          [step]="1"
          [minValue]="1"
          [maxValue]="365"
          [inputGroupMode]="true"
          [placeholder]="rule.getValuePlaceholder()"
        ></rule-input-number>
      </nz-input-group>
      <p class="rule-type-recurrence-title">if responded</p>
    </div>
    <div class="rule-type-recurrence-content">
      <p class="rule-type-recurrence-title">Ask again</p>
      <nz-input-group [nzSuffix]="'days'" [nzPrefix]="'Every:'">
        <rule-input-number
          [value]="ruleIfAnswered?.value.v_n"
          (valueChange)="onRuleIfAnsweredChange($event)"
          [step]="1"
          [minValue]="1"
          [maxValue]="365"
          [inputGroupMode]="true"
          [placeholder]="' '"
        ></rule-input-number>
      </nz-input-group>
      <p class="rule-type-recurrence-title">if not responded</p>
    </div>
  </div>
</div>
