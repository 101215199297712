<div *ngIf="rule">
  <nz-select
    nzMode="multiple"
    class="rule-type-segment-values"
    [ngClass]="{ invalid: rule.value.v_s_arr.length === 0 }"
    [(ngModel)]="rule.value.v_s_arr"
    [nzDropdownMatchSelectWidth]="false"
    nzPlaceHolder="Select segment"
    nzShowSearch
  >
    <nz-option
      *ngFor="let group of sortedRegistryEntries"
      [nzValue]="group.id"
      [nzLabel]="
        (group.favorite ? '⭐️ ' : '') + getFormattedUserGroupNameOrID(group)
      "
    ></nz-option>
  </nz-select>
</div>
