<div *ngIf="loading && initialFetch" class="loader">
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>

<ng-container *ngIf="!loading || !initialFetch">
  <section
    nz-row
    [nzGutter]="[32, 16]"
    class="top-indicators"
    *ngIf="count > 0"
  >
    <div nz-col class="fullsize-indicator" nzXs="24" nzXl="12">
      <h2>
        <span>Average&nbsp;</span>
        <span
          nz-popover
          nzPopoverPlacement="right"
          [nzPopoverContent]="averageHintTemplate"
        >
          <screeb-icon size="sm" icon="question-mark"></screeb-icon>
          <ng-template #averageHintTemplate>
            <div [style]="{ maxWidth: '250px' }">
              Sum of every scores divided by the number of responses
            </div>
          </ng-template>
        </span>
      </h2>
      <survey-stats-indicators-slider-indicator
        [value]="average"
        [min]="getMin()"
        [max]="getMax()"
        [emoji]="getEmoji()"
        class="indicator-with-border"
      >
      </survey-stats-indicators-slider-indicator>
    </div>
    <div nz-col class="fullsize-indicator" nzXs="24" nzXl="12">
      <h2>
        <span>Median&nbsp;</span>
        <span
          nz-popover
          nzPopoverPlacement="right"
          [nzPopoverContent]="medianHintTemplate"
        >
          <screeb-icon size="sm" icon="question-mark"></screeb-icon>
          <ng-template #medianHintTemplate>
            <div [style]="{ maxWidth: '250px' }">
              Score where half the scores are above and the other half below
            </div>
          </ng-template>
        </span>
      </h2>
      <survey-stats-indicators-slider-indicator
        [value]="median"
        [min]="getMin()"
        [max]="getMax()"
        [emoji]="getEmoji()"
        class="indicator-with-border"
      >
      </survey-stats-indicators-slider-indicator>
    </div>
  </section>
</ng-container>
