import { ActivatedRoute, Router } from "@angular/router";
import { PageComponentInterface } from "components/PageComponentInterface";
import { differenceInDays, intervalToDuration, sub } from "date-fns";
import { AnalyticsDao } from "models/analytics.dao";
import {
  AnalyticsFilterForResponse,
  AnalyticsFilters,
  AnalyticsFiltersOperator,
  AnalyticsQueryResponse,
} from "models/analytics.filters.type";
import { AnalyticsResponse } from "models/analytics.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { CTAType, ScenarioNode, UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { arrayToSet } from "utils/array";
import { deepCopy } from "utils/object";
import { TREND_GRAPH_MAX_BUCKETS } from "./components/trend-indicator/trend-indicator.config";
import { UIService } from "services/ui.service";

export const ONE_DAY_MS = 24 * 3600 * 1000;

export class IndicatorStatsSurveyPageComponent
  implements PageComponentInterface
{
  public title = "";
  public name = "";

  protected TREND_GRAPH_MAX_BUCKETS = TREND_GRAPH_MAX_BUCKETS;

  protected obs: any = null;

  public org: Org = null;
  public survey: Survey = null;
  public nodes: ScenarioNode[] = [];
  public languages: LanguageWithEmoji[] = [];
  public registryEntriesIdentityProperty: RegistryEntry[] = [];
  public registryEntriesGroup: RegistryEntry[] = [];
  public registryEntriesEvent: RegistryEntry[] = [];

  protected filtersObs$: any = null;
  public lastFilters: AnalyticsQueryResponse;

  public error: Error;
  public loading = true;
  public initialFetch = true;

  public recentResults = [];

  public noRefresh = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected routingService: RoutingService,
    protected analyticsDao: AnalyticsDao,
    public analyticsFilterService: AnalyticsFilterService,
    protected trackersService: TrackersService,
    public uiService: UIService,
  ) {}

  init() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.survey = data.survey;
      this.nodes = data.nodes || [];
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.languages = data.languages_and_countries.surveyLanguagesWithEmojis;
      this.registryEntriesIdentityProperty =
        data.registryEntriesIdentityProperty.filter(
          (entry: RegistryEntry) => entry.type !== "object",
        );
      this.registryEntriesEvent = data.registryEntriesEvent;
    });
  }

  destroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }

    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }

    this.analyticsFilterService.stopScheduling();
  }

  public soonCTA(primary: boolean) {
    if (primary) {
      this.router.navigate([
        `/org/${this.org.id}/${this.uiService.isMessagePage ? "message" : "survey"}/${this.survey.id}/edit`,
      ]);
    } else {
      this.router.navigate([
        `/org/${this.org.id}/${this.uiService.isMessagePage ? "message" : "survey"}/create`,
      ]);
    }
  }

  public redirectBuilder() {
    this.router.navigate([
      `/org/${this.org.id}/${this.uiService.isMessagePage ? "message" : "survey"}/${this.survey.id}/edit`,
    ]);
  }

  protected getNbrDateBucket(): number {
    const nbrBuckets =
      differenceInDays(
        this.lastFilters.range.end,
        this.lastFilters.range.start,
      ) + 1;

    if (nbrBuckets > this.TREND_GRAPH_MAX_BUCKETS) {
      return this.TREND_GRAPH_MAX_BUCKETS;
    }

    return nbrBuckets;
  }

  protected async getResponseCallback(
    _: AnalyticsResponse,
    __: AnalyticsResponse,
    ___: AnalyticsResponse,
  ) {
    return;
  }

  protected getNodesIds() {
    return arrayToSet(this.nodes.map(({ correlation_id }) => correlation_id));
  }

  protected buildCurrentPeriodQuery(): AnalyticsQueryResponse {
    return {
      ...this.lastFilters,
      aggregation: [
        {
          by: "by_date",
          params: {
            date_histogram_min_interval: "day",
            date_histogram_buckets: this.getNbrDateBucket(),
            // we have to change the sign of timezone, because Javascript sucks
            // this is pure shit
            // https://stackoverflow.com/questions/55564508/pie-chart-js-display-a-no-data-held-message
            date_histogram_timezone_offset: -new Date().getTimezoneOffset(),
          },
        },
        {
          by: "by_answer.value",
          params: {
            answer_ids: this.getNodesIds(),
          },
        },
      ],
      size: 0,
    };
  }

  protected buildPreviousPeriodQuery(
    currentPeriodQuery: AnalyticsQueryResponse,
  ): AnalyticsQueryResponse {
    const start = sub(
      currentPeriodQuery.range.start,
      intervalToDuration({
        start: currentPeriodQuery.range.start,
        end: currentPeriodQuery.range.end,
      }),
    );
    return {
      ...currentPeriodQuery,
      range: {
        ...currentPeriodQuery.range,
        end: currentPeriodQuery.range.start,
        start,
      },
    };
  }

  protected buildAllTimeQuery(
    currentPeriodQuery: AnalyticsQueryResponse,
  ): AnalyticsQueryResponse {
    return {
      ...currentPeriodQuery,
      filters: [],
      range: {
        ...currentPeriodQuery.range,
        end: new Date(),
        start: this.survey.created_at,
      },
    };
  }

  private async getResponses() {
    if (!this.nodes.length && this.isBasedOnNodes()) {
      this.initialFetch = false;
      this.loading = false;
      return;
    }

    this.loading = true;
    this.error = null;

    const queryCurrentPeriod = this.buildCurrentPeriodQuery();
    const queryPreviousPeriod =
      this.buildPreviousPeriodQuery(queryCurrentPeriod);
    const queryAllTimePeriod = this.buildAllTimeQuery(queryCurrentPeriod);

    let previousPeriod = null;
    let currentPeriod = null;
    let allTimePeriod = null;

    try {
      previousPeriod = await this.analyticsDao.search(queryPreviousPeriod);
    } catch (_) {
      // Nothing to see here
    }

    try {
      allTimePeriod = await this.analyticsDao.search(queryAllTimePeriod);
    } catch (_) {
      // Nothing to see here
    }

    try {
      currentPeriod = await this.analyticsDao.search(queryCurrentPeriod);

      await this.getResponseCallback(
        previousPeriod,
        currentPeriod,
        allTimePeriod,
      );
    } catch (err) {
      this.error = err;
      console.error(err);
    }

    this.initialFetch = false;
    this.loading = false;
  }

  protected resetFilters(defaultStartDate?: Date) {
    this.analyticsFilterService.reset(
      "response",
      this.org.id,
      [this.survey.id],
      this.survey.created_at,
      defaultStartDate,
    );
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQueryResponse) => {
        this.lastFilters = deepCopy(filters);
        this.getResponses();
      });

    if (!this.noRefresh) {
      this.analyticsFilterService.scheduleAutoRefresh(30);
    }
  }

  protected setFilters(
    filtersOperator: AnalyticsFiltersOperator,
    filters: AnalyticsFilters,
  ) {
    // @ts-ignore
    this.analyticsFilterService.setFilters(filtersOperator, filters);

    this.trackersService
      .newEventTrackingBuilder("Reporting Segmented")
      .withOrg(this.org)
      .withSurvey(this.survey)
      .withAnalyticsFilters(filters, "response")
      .build();
  }

  protected setFiltersToSpecificScores(scores: number[], ctaType: CTAType) {
    this.setFilters(
      "OR",
      arrayToSet(
        this.nodes
          .filter(
            ({ question, scenario_id }) =>
              question?.call_to_action.type === ctaType &&
              scenario_id === this.survey.scenario.id,
          )
          .map(({ correlation_id, question }) => ({
            correlation_id,
            action_correlation_ids: scores
              .map((score) => {
                switch (question.call_to_action?.type) {
                  case "csat":
                  case "ces":
                  case "nps":
                  case "scoring":
                    return question.call_to_action.scores.find(
                      ({ payload }) => payload.value === score,
                    )?.correlation_id;
                  default:
                    return;
                }
              })
              .filter(Boolean),
          })),
      ).map(
        ({
          correlation_id,
          action_correlation_ids,
        }): AnalyticsFilterForResponse => ({
          type: "response.answer",
          key: UUID(correlation_id),
          operator: "in",
          action_correlation_ids,
        }),
      ),
    );
  }

  protected isBasedOnNodes() {
    return true;
  }
}
