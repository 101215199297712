<div class="translation-switch">
  <span>Activate Automatic Responses Translation</span>
  <nz-switch
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="translationEnabled"
    (ngModelChange)="onTranslationActivationChange($event)"
    nz-tooltip
    [nzTooltipTrigger]="billingPopupVisible ? 'hover' : 'none'"
    [nzTooltipVisible]="billingPopupVisible"
    (nzTooltipVisibleChange)="onBillingPopupVisibleChange($event)"
    [nzTooltipTitle]="confirmTemplate"
  >
  </nz-switch>

  <ng-template #confirmTemplate>
    <span>This option is only available with the Translation Addon.</span>
    <a (click)="navigateToBilling()" target="_blank" rel="noreferrer"
      >Get it Now</a
    >
  </ng-template>
</div>
