<nz-modal
  [(nzVisible)]="isOpen"
  [nzTitle]="null"
  [nzContent]="modalContent"
  [nzFooter]="null"
  [nzClosable]="false"
  (nzOnCancel)="handleClose()"
  nzWidth="757px"
  nzHeight="333px"
  nzClassName="preview-audio-modal"
>
  <ng-template #modalContent>
    <div class="modal-content">
      <div class="audio-container">
        <div class="audio-visualizer">
          <div class="audio-track">
            <div class="track-line"></div>
            <div
              class="track-thumb"
              [style]="{
                left: playbackTime
              }"
            ></div>
          </div>
        </div>
        <audio
          #audioPlayer
          loop
          preload="metadata"
          (loadedmetadata)="playerReady($event)"
        >
          <source [src]="audioUrl" />
        </audio>
      </div>
      <div>
        <span
          >{{ formatDuration(currentTime) }} /
          {{ formatDuration(duration) }}</span
        >
      </div>
      <div class="actions-container">
        <div *ngIf="!isLoaded">
          <nz-spin></nz-spin>
        </div>
        <div
          *ngIf="isLoaded"
          class="action-btn"
          (click)="setPlaying(!isPlaying)"
        >
          <screeb-icon *ngIf="!isPlaying" size="md" icon="play" />
          <screeb-icon *ngIf="isPlaying" size="md" icon="pause" />
        </div>
      </div>
    </div>
  </ng-template>
</nz-modal>
