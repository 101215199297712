import { Component, Input } from "@angular/core";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "intercom-nz-button",
  template: `<a
    class="ask-question-btn"
    title="Any Question ?"
    nz-button
    [nzType]="nzType"
    [nzSize]="nzSize"
    href="mailto:michael@screeb.app"
    (click)="askUsAQuestion($event)"
    [hidden]="false"
  >
    <screeb-icon size="sm" icon="survey" />
    <span>{{ title }}</span>
  </a> `,
  styles: [
    `
      :host {
        display: inline-block;
      }

      .ask-question-btn {
        min-height: 40px;
        height: fit-content;
        width: fit-content;
        max-width: 100%;
        display: flex;
        align-items: center;
        box-shadow: none;
        white-space: normal;
        padding: 8px 16px !important;
      }

      .ask-question-btn screeb-icon {
        color: inherit;
      }

      .ask-question-btn span {
        font-weight: 400;
        text-align: left;
        line-height: 150%;
      }
    `,
  ],
})
export class IntercomNzButtonComponent {
  @Input() public nzType: string = "primary";
  @Input() public nzSize: string = "large";
  @Input() public title: string = "Chat with us!";

  constructor(private trackersService: TrackersService) {}

  public askUsAQuestion(event: Event) {
    if (this.trackersService.intercomIsBooted()) {
      this.trackersService.intercomOpenConversation();
      event.preventDefault();
    }
  }
}

@Component({
  selector: "intercom-button",
  template: `<a
    class="ask-question-btn"
    [style]="{
      color: textColor,
      'background-color': backgroundColor,
      'border-color': borderColor
    }"
    title="Any Question ?"
    nz-button
    nzType="primary"
    nzSize="large"
    href="mailto:michael@screeb.app"
    (click)="askUsAQuestion($event)"
    [hidden]="false"
  >
    <screeb-icon size="sm" icon="survey" />
    <span [style]="{ 'font-size': textSize }">{{ title }}</span>
  </a> `,
  styles: [
    `
      :host {
        display: inline-block;
      }

      .ask-question-btn {
        min-height: 40px;
        height: fit-content;
        width: fit-content;
        max-width: 100%;
        border-radius: 8px;
        border: 1px solid #e5e5ed;
        display: flex;
        align-items: center;
        box-shadow: none;
        white-space: normal;
        padding: 8px 16px !important;
      }

      .ask-question-btn screeb-icon {
        color: inherit;
      }

      .ask-question-btn span {
        font-weight: 400;
        text-align: left;
        line-height: 150%;
      }
    `,
  ],
})
export class IntercomButtonComponent {
  @Input() public title: string = "Chat with us!";
  @Input() public textSize: string = "16px";
  @Input() public borderColor: string = "#e5e5ed";
  @Input() public backgroundColor: string = "#ffffff";
  @Input() public textColor: string = "#727387";

  constructor(private trackersService: TrackersService) {}

  public askUsAQuestion(event: Event) {
    if (this.trackersService.intercomIsBooted()) {
      this.trackersService.intercomOpenConversation();
      event.preventDefault();
    }
  }
}
