<div *ngIf="rule">
  <nz-select
    nzMode="tags"
    class="rule-type-language-values"
    [ngClass]="{ invalid: rule.value.v_s_arr.length === 0 }"
    [(ngModel)]="rule.value.v_s_arr"
    [nzDropdownMatchSelectWidth]="false"
    nzPlaceHolder="Select language"
    nzShowSearch
  >
    <nz-option-group nzLabel="Main">
      <nz-option
        *ngFor="let opt of mainLanguages"
        [nzValue]="opt[0]"
        [nzLabel]="opt[1]"
      ></nz-option>
    </nz-option-group>
    <nz-option-group nzLabel="All">
      <nz-option
        *ngFor="let opt of languages"
        [nzValue]="opt[0]"
        [nzLabel]="opt[1]"
      ></nz-option>
    </nz-option-group>
  </nz-select>
</div>
