import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";

@Injectable()
export class OrgResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private orgDao: OrgDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Org> {
    return this.applicationLoadService
      .loader()
      .then(() => this.orgDao.getById(route.params["org_id"]))
      .catch((err: HttpErrorResponse) => {
        // this.notificationHelper.trigger("Failed to fetch org", null, 'error');
        console.error(err.error);
        throw err;
      });
  }
}
