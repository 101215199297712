<div *ngIf="ready" nz-row [nzGutter]="[0, 0]">
  <div nz-col [nzSpan]="24" class="row targeting-devices">
    <h3>
      Show the {{ uiService.isMessagePage ? "message" : "survey" }} on the
      following devices
    </h3>

    <ul>
      <li *ngIf="channel.type === 'widget'">
        <big-checkbox
          label="Desktop"
          [checked]="deviceRule.value.v_s_arr.indexOf('desktop') !== -1"
          (checkedChange)="onDeviceChange('desktop', $event)"
        ></big-checkbox>
      </li>
      <li>
        <big-checkbox
          label="Mobile"
          [checked]="deviceRule.value.v_s_arr.indexOf('mobile') !== -1"
          (checkedChange)="onDeviceChange('mobile', $event)"
        ></big-checkbox>
      </li>
      <li>
        <big-checkbox
          label="Tablet"
          [checked]="deviceRule.value.v_s_arr.indexOf('tablet') !== -1"
          (checkedChange)="onDeviceChange('tablet', $event)"
        ></big-checkbox>
      </li>
    </ul>
    <utils-form-error
      *ngIf="deviceRule.value.v_s_arr?.length === 0"
      [errors]="['Please select at least 1 support']"
    >
    </utils-form-error>
  </div>

  <div
    *ngIf="channel.type === 'widget'"
    nz-col
    [nzSpan]="24"
    class="row targeting-url"
  >
    <h3>
      Show the {{ uiService.isMessagePage ? "message" : "survey" }} on the
      following pages
    </h3>

    <nz-radio-group
      [ngModel]="urlRules.length > 0"
      (ngModelChange)="onUrlTargetingChange($event)"
    >
      <big-radio
        *ngIf="interaction !== 'in_page'"
        [nzValue]="false"
        label="On all pages where the Screeb tag is installed"
      >
      </big-radio>
      <big-radio
        *ngIf="interaction === 'in_page'"
        [nzValue]="false"
        label="On all pages where element is present"
      >
      </big-radio>
      <big-radio [nzValue]="true" label="On a specific page"></big-radio>
    </nz-radio-group>

    <div class="targeting-url-contains" *ngIf="urlRules.length > 0">
      <h4>URL must contain:</h4>
      <ul>
        <li *ngFor="let r of urlRules; let i = index">
          <input
            type="text"
            nz-input
            nzSize="large"
            [ngClass]="{ invalid: r.value.v_s_arr[0]?.trim().length === 0 }"
            [(ngModel)]="r.value.v_s_arr[0]"
            [placeholder]="r.getValuePlaceholder()"
            required
          />
          <button
            nz-button
            nzType="text"
            nzShape="circle"
            *ngIf="urlRules.length > 1"
            (click)="onUrlRuleRemoved(i)"
          >
            <i nz-icon nzType="close"></i>
          </button>
        </li>
      </ul>
      <!-- <button class="ant-btn-secondary" nz-button nzType="default" (click)="onUrlRuleAdded()">Add an URL</button> -->
    </div>
  </div>

  <div
    *ngIf="channel.type !== 'widget'"
    nz-col
    [nzSpan]="24"
    class="row targeting-screen"
  >
    <h3>
      Show the {{ uiService.isMessagePage ? "message" : "survey" }} on the
      following screens
    </h3>

    <nz-radio-group
      [ngModel]="!!screenRule"
      (ngModelChange)="onScreenTargetingChange($event)"
    >
      <big-radio
        [nzValue]="false"
        label="On all screens where the Screeb tag is installed"
      >
      </big-radio>
      <big-radio [nzValue]="true" label="On a specific Screen"></big-radio>
    </nz-radio-group>

    <div class="targeting-screen-is" *ngIf="!!screenRule">
      <h4>Screen is:</h4>
      <nz-select
        nzMode="multiple"
        nzBorderless
        [(ngModel)]="screenRule.value.v_s_arr"
        nzSize="large"
        [nzPlaceHolder]="screenRule.getValuePlaceholder()"
        [nzDropdownMatchSelectWidth]="false"
        nzDropdownClassName="filters-select-value"
        [nzNotFoundContent]=""
        [ngClass]="{ invalid: !screenRule.value.v_s_arr?.length }"
      >
        <nz-option
          *ngFor="let clientScreen of channelClientScreens"
          [nzLabel]="clientScreen.name"
          [nzValue]="clientScreen.name"
        ></nz-option>
      </nz-select>
    </div>
  </div>

  <div
    nz-col
    [nzSpan]="24"
    class="row targeting-delay"
    *ngIf="interaction !== 'in_page'"
  >
    <h3>Show the {{ uiService.isMessagePage ? "message" : "survey" }}</h3>

    <nz-radio-group
      [ngModel]="!!delayRule || !!sessionDurationRule"
      (ngModelChange)="onDelayOrSessionDurationTargetingChange($event)"
    >
      <big-radio
        [nzValue]="false"
        [label]="
          'Immediately after the ' +
          (channel.type === 'widget' ? 'page' : 'screen') +
          ' loads'
        "
      >
      </big-radio>
      <big-radio [nzValue]="true" [label]="editTimeDelay"></big-radio>
    </nz-radio-group>

    <ng-template #editTimeDelay>
      After a delay of
      <rule-input-number
        nzSize="small"
        [value]="(!!delayRule ? delayRule : sessionDurationRule)?.value.v_n"
        (valueChange)="
          (!!delayRule ? delayRule : sessionDurationRule).value.v_n = $event
        "
        [step]="1"
        [minValue]="1"
        [maxValue]="!!delayRule ? 3600 : 86400"
        [disabled]="isDelayOrSessionDurationDisabled()"
        [placeholder]="getPlaceholderDelayOrSessionDuration()"
        [invalid]="isDelayOrSessionDurationInvalid()"
      >
      </rule-input-number>
      seconds spent on
      <nz-select
        nzSize="small"
        [ngModel]="getDelayOrSessionDurationMode()"
        (ngModelChange)="switchDelayOrSessionDurationMode($event)"
        [disabled]="isDelayOrSessionDurationDisabled()"
      >
        <nz-option
          *ngIf="channel.type === 'widget'"
          nzLabel="the page"
          nzValue="delay"
        ></nz-option>
        <nz-option
          *ngIf="channel.type !== 'widget'"
          nzLabel="the screen"
          nzValue="delay"
        ></nz-option>
        <nz-option nzLabel="the app" nzValue="session_duration"></nz-option>
      </nz-select>
    </ng-template>
  </div>

  <css-selector
    class="row"
    [interaction]="interaction"
    [selectorCSS]="selectorCSS"
    (selectorCSSChange)="selectorCSSChange.emit($event)"
  />

  <div nz-col [nzSpan]="24" class="row targeting-recurrence">
    <h3>This {{ uiService.isMessagePage ? "message" : "survey" }} is</h3>

    <nz-radio-group
      [ngModel]="isRecurrenceRuleExisting()"
      (ngModelChange)="onRecurrenceTargetingChange($event)"
    >
      <big-radio
        [nzValue]="false"
        label="A one-off {{ uiService.isMessagePage ? 'message' : 'survey' }}"
      >
      </big-radio>
      <big-radio [nzValue]="true" [label]="editTimeRecurring"></big-radio>
    </nz-radio-group>

    <ng-template #editTimeRecurring>
      A recurring {{ uiService.isMessagePage ? "message" : "survey" }} and will
      be displayed every
      <rule-input-number
        nzSize="small"
        [(value)]="recurrenceRule?.value.v_n"
        [step]="1"
        [minValue]="1"
        [maxValue]="365"
        [disabled]="!isRecurrenceRuleExisting()"
        [placeholder]="
          !!recurrenceRule ? recurrenceRule.getValuePlaceholder() : '30'
        "
        [invalid]="
          !!recurrenceRule &&
          (recurrenceRule.value.v_n === null ||
            recurrenceRule.value.v_n === undefined)
        "
      ></rule-input-number>
      days
    </ng-template>
  </div>
</div>
