<header class="iAppear">
  <img
    loading="lazy"
    alt="User's icon"
    nz-icon
    [src]="getUserIcon(user.id, getAvatarURL())"
  />
  <div class="identity">
    <h1>
      {{
        getFormattedUserNameOrIDByNormalizedProperty(
          user.id,
          user.aliases,
          userProperties
        )
      }}
      <ng-container *ngIf="user.is_anonymous">
        <nz-tag
          nz-popover
          nzPopoverTitle="Anonymous users"
          [nzPopoverContent]="anonymousHelp"
        >
          Anonymous
        </nz-tag>

        <ng-template #anonymousHelp>
          This user has not been identified in Javascript tag and mobile SDKs.
          <br />
          <a rel="noopener" href="https://help.screeb.app" target="_blank">
            <i nz-icon nzType="link"></i>
            See our help section.
          </a>
        </ng-template>
      </ng-container>
    </h1>
    <div class="groups" *ngIf="userGroups.length">
      <span>Part of these segments: </span>
      <nz-tag
        *ngFor="let userGroup of userGroups"
        [nzMode]="('segment.update' | hasPermission) ? 'closeable' : 'default'"
        (nzOnClose)="removeUserFromGroup(userGroup); $event.preventDefault()"
      >
        {{ getFormattedUserGroupNameOrID(userGroup) }}
      </nz-tag>
    </div>
  </div>
  <div class="right-actions">
    <user-add-to-segment
      *ngIf="'segment.update' | hasPermission"
      [org]="org"
      [user]="user"
      [orgUserGroups]="orgUserGroups"
      [userAssignedGroups]="userGroups"
      (addedToSegments)="onAddedToSegments($event)"
    >
    </user-add-to-segment>
    <a
      routerLink="/org/{{ org.id }}/survey/create"
      [queryParams]="{ respondentIds: user.id }"
    >
      <button
        *ngIf="'survey.create' | hasPermission"
        nz-button
        nzType="primary"
        nzSize="large"
        class="ask-a-question-button"
      >
        <img
          alt="Ask a question"
          class="icon"
          src="assets/icons/common/ask-a-question.svg"
        />
        Ask a question
      </button>
    </a>
  </div>
</header>
<ng-container>
  <error-message
    *ngIf="errorResponsesES"
    message="An error occured when fetching data."
    [details]="errorResponsesES.message"
    (reload)="refreshData()"
  ></error-message>

  <div class="gridAppear" *ngIf="!errorResponsesES">
    <section nz-row [nzGutter]="[32, 16]" class="indicators">
      <div nz-col nzSpan="24" nzXl="16">
        <div *ngFor="let response of userResponsesES">
          <h2 class="response-header">
            <div class="left">
              <img
                alt="{{ response.survey.title }}"
                class="icon"
                src="assets/icons/users/response.svg"
              />
              <span>{{ response.survey.title }}</span>
            </div>
            <div class="right">
              <button
                *ngIf="'analytics.response.delete' | hasPermission"
                nz-button
                nzType="default"
                nzSize="large"
                nzDanger
                [nzLoading]="
                  deletingByResponseId[response.response.id] === true
                "
                (click)="
                  deleteResponse(
                    response.response.respondent_id,
                    response.response.id
                  )
                "
              >
                Delete
              </button>
            </div>
          </h2>

          <survey-stats-individual-responses-questions-table
            [nodes]="response.questions"
            [displaySurveyTitle]="false"
            [questionBodyView]="questionBodyView"
          >
          </survey-stats-individual-responses-questions-table>

          <ng-template #questionBodyView let-item="item">
            <survey-stats-individual-responses-answers-table-single-question
              [org]="org"
              [survey]="item.survey"
              [responses]="[response.response]"
              [nodeCorrelationId]="item.node.correlationId"
              [integrations]="[]"
              [removable]="false"
            >
            </survey-stats-individual-responses-answers-table-single-question>
          </ng-template>
        </div>
      </div>
      <div nz-col nzXs="24" nzLg="24" nzXl="8">
        <section nz-row [nzGutter]="[32, 16]" class="indicators">
          <div nz-col nzSpan="24" class="fullsize-indicator">
            <h2>
              <img
                alt="Activity"
                class="icon"
                src="assets/icons/users/analyze.svg"
              />
              <span>Activity</span>
            </h2>
            <div class="indicator-with-border">
              <div
                class="loader"
                *ngIf="loadingResponsesES && initialFetchResponsesES"
              >
                <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
              </div>
              <ng-container
                *ngIf="!initialFetchResponsesES || !loadingResponsesES"
              >
                <ul class="stats-list iAppear">
                  <ng-container *ngFor="let alias of user.aliases">
                    <li class="stat-item" *ngIf="alias !== user.id">
                      <div class="stat-item-header">
                        <square-icon icon="star"></square-icon>
                        <div class="stat-item-data">
                          <div class="stat-item-title">Identity</div>
                          <div class="stat-item-value" [title]="alias">
                            {{ alias }}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                  <li class="stat-item">
                    <div class="stat-item-header">
                      <square-icon icon="star"></square-icon>
                      <div class="stat-item-data">
                        <div class="stat-item-title">First seen</div>
                        <div
                          class="stat-item-value"
                          [title]="user.created_at | toLocaleString"
                        >
                          {{
                            user.created_at
                              | dfnsFormatDistanceToNow: { addSuffix: true }
                          }}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="stat-item">
                    <div class="stat-item-header">
                      <square-icon icon="bye"></square-icon>
                      <div class="stat-item-data">
                        <div class="stat-item-title">Last seen</div>
                        <div
                          class="stat-item-value"
                          [title]="user.last_activity_at | toLocaleString"
                        >
                          {{
                            user.last_activity_at
                              | dfnsFormatDistanceToNow: { addSuffix: true }
                          }}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="stat-item">
                    <div class="stat-item-header">
                      <square-icon icon="response"></square-icon>
                      <div class="stat-item-data">
                        <div class="stat-item-title">Number of responses</div>
                        <div class="stat-item-value">
                          {{ responsesCount }}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="stat-item">
                    <div class="stat-item-header">
                      <square-icon icon="response"></square-icon>
                      <div class="stat-item-data">
                        <div class="stat-item-title">Last response</div>
                        <div
                          class="stat-item-value"
                          *ngIf="!lastResponseDate || +lastResponseDate === 0"
                        >
                          N/A
                        </div>
                        <div
                          class="stat-item-value"
                          [title]="lastResponseDate | toLocaleString"
                          *ngIf="lastResponseDate && +lastResponseDate !== 0"
                        >
                          {{
                            lastResponseDate
                              | dfnsFormatDistanceToNow: { addSuffix: true }
                          }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </ng-container>
            </div>
          </div>
          <div
            nz-col
            nzSpan="24"
            class="fullsize-indicator"
            *ngIf="
              ('session_recording' | hasEntitlement) &&
              ('isSessionRecordingEnabled' | hasFeatureFlag)
            "
          >
            <h2>
              <img
                alt="Session recording"
                class="icon"
                src="assets/icons/users/live.svg"
              />
              <span>Session recording</span>
            </h2>
            <div class="indicator-with-border">
              <div class="loader" *ngIf="loadingRecords && initialFetchRecords">
                <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
              </div>
              <ng-container *ngIf="!initialFetchRecords || !loadingRecords">
                <user-records
                  [user]="user"
                  [userRecords]="userRecords"
                  (recordClicked)="onRecordClicked()"
                >
                </user-records>
              </ng-container>
            </div>
          </div>
          <div nz-col nzSpan="24" class="fullsize-indicator">
            <h2>
              <i nz-icon nzType="field-time" nzTheme="outline"></i>
              <span>Timeline</span>
            </h2>
            <div class="indicator-with-border">
              <div
                class="loader"
                *ngIf="loadingResponsesPG && initialFetchResponsesPG"
              >
                <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
              </div>
              <ng-container
                *ngIf="!initialFetchResponsesPG || !loadingResponsesPG"
              >
                <nz-timeline class="timeline" nzMode="right">
                  <ng-container *ngFor="let response of userResponsesPG">
                    <ng-template #itemRight>
                      <div>
                        {{
                          response.created_at
                            | dfnsFormatDistanceToNow: { addSuffix: true }
                        }}
                      </div>
                      <tag-registry-entry-source
                        [sources]="[response.source]"
                        size="small"
                      >
                      </tag-registry-entry-source>
                    </ng-template>

                    <nz-timeline-item [nzLabel]="itemRight">
                      <div
                        class="timeline-label"
                        *ngIf="response.survey?.deleted_at"
                      >
                        {{ response.survey.title }}
                        <span>(Deleted)</span>
                      </div>
                      <a
                        class="timeline-label"
                        *ngIf="!response.survey?.deleted_at"
                        routerLink="/org/{{ org.id }}/survey/{{
                          response.survey_id
                        }}/stats"
                      >
                        {{ response.survey?.title }}
                      </a>
                      <nz-tag
                        nzColor="var(--screeb-color-green-500)"
                        nz-tooltip
                        nzTooltipTitle="Survey version created at: {{
                          response.scenario?.created_at | toLocaleString
                        }}"
                      >
                        <span>v.{{ response.scenario?.version }}</span>
                      </nz-tag>
                      <nz-tag
                        [nzColor]="
                          response.completion | responseCompletionColor
                        "
                      >
                        <span>{{
                          response.completion | responseCompletion
                        }}</span>
                      </nz-tag>
                      <nz-tag
                        *ngIf="response.end_reason === 'closed'"
                        nzColor="#292929"
                      >
                        <span>Closed</span>
                      </nz-tag>
                      <nz-tag
                        *ngIf="response.end_reason === 'interrupted'"
                        nzColor="#292929"
                      >
                        <span>Interrupted</span>
                      </nz-tag>
                      <nz-tag
                        *ngIf="response.end_reason === 'expired'"
                        nzColor="#292929"
                      >
                        <span>Expired</span>
                      </nz-tag>
                    </nz-timeline-item>
                  </ng-container>
                </nz-timeline>
                <nz-empty
                  *ngIf="!userResponsesPG.length"
                  [nzNotFoundContent]="contentTpl"
                >
                  <ng-template #contentTpl>
                    <div>No displays</div>
                  </ng-template>
                </nz-empty>
              </ng-container>
            </div>
          </div>
          <div nz-col nzSpan="24" class="fullsize-indicator">
            <h2>
              <span>Responses Emotions Analysis</span>
              <utils-badge text="New"></utils-badge>
            </h2>
            <div class="indicator-with-border full-height">
              <survey-stats-indicators-radar-indicator
                [labels]="[
                  '😡 Anger',
                  '&nbsp;😄 Joy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
                  '😱 Fear',
                  '😢 Sadness'
                ]"
                [legend]="emotionsChartLegend"
                [datasets]="emotionsChartDatasets"
                [hideTitle]="true"
                [loading]="loadingEmotions"
              />
            </div>
          </div>
          <div nz-col nzSpan="24" class="fullsize-indicator">
            <h2>
              <img
                alt="Live events"
                class="icon"
                src="assets/icons/users/live.svg"
              />
              <span>Live events</span>
            </h2>
            <div class="indicator-with-border">
              <div class="loader" *ngIf="loadingEvents && initialFetchEvents">
                <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
              </div>
              <ng-container *ngIf="!initialFetchEvents || !loadingEvents">
                <user-events
                  [userEvents]="userEvents"
                  (eventClicked)="onEventClicked()"
                >
                </user-events>
              </ng-container>
            </div>
          </div>
          <div nz-col nzSpan="24" class="fullsize-indicator">
            <h2>
              <img
                alt="Properties"
                class="icon"
                src="assets/icons/users/respondent.svg"
              />
              <span>Properties</span>
            </h2>
            <div class="indicator-with-border">
              <div
                class="loader"
                *ngIf="loadingProperties && initialFetchProperties"
              >
                <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
              </div>
              <ng-container
                *ngIf="!initialFetchProperties || !loadingProperties"
              >
                <user-properties
                  [userProperties]="userProperties"
                ></user-properties>
              </ng-container>
            </div>
            <button
              *ngIf="'user.delete' | hasPermission"
              nz-button
              nzType="link"
              class="gdpr-request"
              [nzLoading]="loadingRequestDataDeletion"
              (click)="requestDataDeletion($event)"
            >
              <span>Request data deletion</span>
            </button>
          </div>

          <ng-container
            *ngIf="
              (!initialFetchEventsTypeform || !loadingEventsTypeform) &&
              userEventsTypeform.length
            "
          >
            <div nz-col nzSpan="24" class="fullsize-indicator">
              <h2>
                <img
                  alt="Typeform"
                  class="icon"
                  src="assets/icons/users/typeform.svg"
                />
                <span>Typeform responses</span>
              </h2>
              <div class="indicator-with-border">
                <ul class="stats-list iAppear">
                  <li
                    *ngFor="let event of userEventsTypeform"
                    class="stat-item clickable"
                  >
                    <a
                      class="stat-item-header typeform"
                      [href]="getTypeformEventURL(event)"
                      (click)="event.opened = !event.opened; onEventClicked()"
                      target="_blank"
                    >
                      <square-icon icon="typeform"></square-icon>
                      <div class="stat-item-data">
                        <div class="stat-item-title">
                          <span>{{ event.slug }}</span>
                          <tag-registry-entry-source
                            [sources]="[event.source]"
                          ></tag-registry-entry-source>
                        </div>
                        <div
                          class="stat-item-value"
                          [title]="event.triggered_at | toLocaleString"
                        >
                          {{
                            event.triggered_at
                              | dfnsFormatDistanceToNow: { addSuffix: true }
                          }}
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>
        </section>
      </div>
    </section>
  </div>
</ng-container>
