import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "uppercaseFirst" })
export class UppercaseFirstPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string): string {
    if (!text) return text;

    const [first, ...rest] = text;
    return first.toLocaleUpperCase() + rest.join("");
  }
}
