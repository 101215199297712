import { ErrorHandler, Injectable } from "@angular/core";
import {
  ActivationEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { Location } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { TrackersService } from "services/trackers.service";
import { SeoService } from "services/seo.service";
import { AnalyticsFilterService } from "./analytics-filters.service";
import { handleError as sentryErrorHandler } from "./logging.service";
import { ConfigService } from "./config.service";

@Injectable()
class RoutingService implements ErrorHandler {
  constructor(
    private router: Router,
    private location: Location,
    private seoService: SeoService,
    private trackersService: TrackersService,
    private analyticsFilterService: AnalyticsFilterService,
    private configService: ConfigService,
  ) {
    this.router.events.subscribe((e) => this.onRouterEvent(e));
  }

  // https://medium.com/@PhilippKief/global-error-handling-in-angular-ea395ce174b1
  public handleError(error: Error) {
    console.error(error);
    // console.error("Error from global error handler", error);

    sentryErrorHandler(this.configService.config, error);
  }

  private onRouterEvent(event) {
    switch (true) {
      case event instanceof ActivationEnd:
        break;
      case event instanceof NavigationStart:
        break;
      case event instanceof NavigationEnd:
        break;
      case event instanceof NavigationCancel:
        break;
      case event instanceof NavigationError:
        // change url to the routed URL (for url bar and history)
        // and display the 4xx or 5xx error
        if (event.error instanceof HttpErrorResponse) {
          this.handleHttpError(event.error);
        }
        this.location.go(event.url);
        break;
      default: {
        break;
      }
    }
  }

  public onPageChange(
    name: string,
    title: string,
    data: object,
    triggerPageViewEvent: boolean,
  ) {
    this.analyticsFilterService.stopScheduling();

    this.seoService.set({
      title,
    });

    if (!triggerPageViewEvent) {
      return;
    }

    // 'pageview' event
    const pageViewEvent = this.trackersService
      .newEventPageViewBuilder(name)
      .withProps({
        name,
        title,
        url: this.router.url,
      });

    if (data["org"]) {
      pageViewEvent.withOrg(data["org"]);
    }
    if (data["channel"]) {
      pageViewEvent.withChannel(data["channel"]);
    }
    if (data["survey"]) {
      pageViewEvent.withSurvey(data["survey"]);
    }
    if (data["integration"]) {
      pageViewEvent.withIntegration(data["integration"]);
    }

    pageViewEvent.build();
  }

  private notAuthorized() {
    throw new Error("Not supported"); // we should use front layout here
    this.router.navigate(["/401"], { skipLocationChange: true });
  }
  private permissionDenied() {
    throw new Error("Not supported"); // we should use front layout here
    this.router.navigate(["/403"], { skipLocationChange: true });
  }
  private notFound() {
    this.router.navigate(["404"], { skipLocationChange: true });
  }
  private internalError() {
    this.router.navigate(["500"], { skipLocationChange: true });
  }

  private handleHttpError(err: HttpErrorResponse) {
    switch (err.status) {
      case 403:
        console.log(err);
        break;
      case 404:
        this.notFound();
        break;
      case 500:
        this.internalError();
        break;
      default:
        throw err;
    }
  }
}

export { RoutingService };
