import { Component, Input } from "@angular/core";
import { GoalType } from "components/onboarding/onboarding-goal/onboarding-goal.data";
import { SessionService } from "services/auth.service";

@Component({
  selector: "onboarding-hold-on",
  template: `
    <h1 class="main-title">
      <span>Hold tight 🚀</span>
    </h1>
    <div class="main-description no-margin">
      <span>We're setting everything up for</span>
      <span class="emphasis"
        >&nbsp;Screeb to help you {{ goal?.description }}</span
      >
    </div>
    <p class="alt-description">We should be ready in a few seconds...</p>
    <span
      nz-icon
      nzType="loading"
      class="circle-loader"
      nzTheme="outline"
    ></span>
  `,
  styleUrls: ["../../layout/onboarding-layout.component.scss"],
  styles: [
    `
      :host {
        max-width: 550px;
      }

      .alt-description {
        font-family: Rubik;
        font-style: normal;
        font-weight: lighter;
        font-size: 24px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        margin-top: 50px;
      }

      .circle-loader {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 150px;
      }

      .circle-loader ::ng-deep svg {
        width: 32px;
        height: 32px;

        color: var(--screeb-color-purple-500);
      }
    `,
  ],
})
export class OnBoardingHoldOnComponent {
  @Input() goal: GoalType = null;
  constructor(public sessionService: SessionService) {}
}
