import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { Org } from "models/org.model";
import { SurveyDao } from "models/survey.dao";
import { Survey } from "models/survey.model";
import { combineLatest } from "rxjs";
import { SessionService } from "services/auth.service";
import { RoutingService } from "services/routing.service";
import { SettingsService } from "services/settings.service";

import { HttpErrorResponse } from "@angular/common/http";
import { NotificationHelper } from "helpers/notification.helper";
import { Account } from "models/account.model";
import { Channel, ChannelTypeFormatted } from "models/channel.model";
import { Sequence } from "models/sequence.types";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import { SurveyDistribution } from "models/survey-distribution.model";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { UIService } from "services/ui.service";
import { simpleFTS } from "utils/search";
import { isTodayBetweenDates } from "utils/date";

interface ColumnItem {
  name: string;
  width: number;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null;
  listOfFilter: NzTableFilterList | null;
  filterFn: NzTableFilterFn | undefined;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "page-survey-list",
  templateUrl: "./survey-list.component.html",
  styleUrls: ["./survey-list.component.scss"],
})
export class SurveyListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "List all surveys";
  public name = "List surveys";

  public ready = false;
  public pathPrefix = "survey";

  private obs: any = null;
  public org: Org = null;
  public surveys: Survey[] = [];
  public channels: Channel[] = [];
  public surveyTags: string[] = [];
  public orgAccounts: Account[] = [];
  public sequences: Sequence[] = [];

  public ChannelTypeFormatted = ChannelTypeFormatted;

  public filteredTags: string[] = null;
  public filteredStatus: boolean = null;
  public filteredSequences: string[] = null;
  public filteredSurveys: Survey[] = [];
  public keywords = "";

  public importModalOpened = false;
  public upgradePopupVisible = false;

  public currentSurveyActionDropDown: string = null;
  public currentSurveyNameEditing: string = null;
  private loaderStatus: object = {};

  public distributionStatusLoading: object = {};

  public workspaces: Org[] = [];
  public duplicateModalOpened = false;
  public duplicateTargetWorkspaceId: string = null;
  private surveyToDuplicate: Survey = null;

  @Output()
  public collapseMenu: EventEmitter<any> = new EventEmitter();

  @ViewChild("renameInput") renameInput: ElementRef;

  listOfColumns: ColumnItem[] = [
    {
      name: "Survey",
      width: 170,
      align: "left",
      sortOrder: null,
      sortFn: (a: Survey, b: Survey) => a.title.localeCompare(b.title),
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Status",
      width: 170,
      align: "left",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Displays",
      width: 100,
      align: "center",
      sortOrder: null,
      sortFn: (a: Survey, b: Survey) =>
        a.stats.response_total - b.stats.response_total,
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Response Rate",
      width: 150,
      align: "center",
      sortOrder: null,
      sortFn: (a: Survey, b: Survey) =>
        a.stats.response_rate - b.stats.response_rate,
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Last Edit",
      width: 130,
      align: "left",
      sortOrder: "descend",
      sortFn: (a: Survey, b: Survey) =>
        a.updated_at.getTime() - b.updated_at.getTime(),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private routingService: RoutingService,
    private modalService: NzModalService,
    public settingsService: SettingsService,
    public sessionService: SessionService,
    private surveyDistributionDao: SurveyDistributionDao,
    private notificationHelper: NotificationHelper,
    public uiService: UIService,
    public surveyDao: SurveyDao,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public entitlementService: EntitlementService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = combineLatest(this.route.data, this.route.queryParams).subscribe(
      (d) => {
        const data = d[0];

        this.org = data.org;
        this.uiService.isMessagePage = this.router.isActive(
          `/org/${this.org.id}/message`,
          false,
        );
        if (this.uiService.isMessagePage) {
          this.title = "List all messages";
          this.name = "List messages";
          this.pathPrefix = "message";
        }

        this.surveys = this.alterSurveyDistributions(data.surveys);
        this.workspaces = data.orgs;
        this.surveyTags = data.surveyTags;
        this.channels = data.channels;
        this.orgAccounts = data.orgAccounts;
        this.sequences = data.sequences;
        this.filteredSurveys = [].concat(this.surveys);
        this.ready = true;
        this.distributionStatusLoading = {};

        this.duplicateTargetWorkspaceId = this.org.id;

        this.surveys.map(
          (survey) =>
            (survey.updated_by_account = this.orgAccounts.find(
              ({ id }) => id === survey.updated_by,
            )),
        );

        // Remove all distrib except widget for in_page surveys
        this.surveys = this.surveys.map((survey) => {
          if (survey.interaction === "in_page") {
            survey.survey_distributions = survey.survey_distributions.filter(
              (distrib) => distrib.type === "widget",
            );
            return survey;
          }
          return survey;
        });
      },
    );
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  // Let's order the distributions by type and filter distributions for messages
  private alterSurveyDistributions(surveys: Survey[]): Survey[] {
    const order = {
      widget: 1,
      android: 2,
      ios: 3,
      "hosted-page": 4,
    };

    return surveys.map((survey: Survey) => {
      // For a message, only show edited distributions
      if (survey.type === "message") {
        survey.survey_distributions = survey.survey_distributions.filter(
          (d) => Number(d.updated_at) !== Number(d.created_at),
        );
      } else {
        survey.survey_distributions.sort(
          (a: SurveyDistribution, b: SurveyDistribution) => {
            return order[a.type] - order[b.type];
          },
        );
      }
      return survey;
    });
  }

  public getChannelById(id: string): Channel | null {
    return this.channels.find((channel) => id === channel.id);
  }

  public onTagsFilterChange(tags: string[]) {
    this.filteredTags = tags;

    this.filterSurveys();
  }

  public onStatusFilterChange(value: boolean) {
    this.filteredStatus = value;

    this.filterSurveys();
  }

  public onSequencesFilterChange(value: string[]) {
    this.filteredSequences = value;

    this.filterSurveys();
  }

  public onSurveyStatusChange(
    event: Event,
    survey: Survey,
    distrib: SurveyDistribution,
    enable: boolean,
  ) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.distributionStatusLoading[distrib.id] = false;

    this.surveyDistributionDao
      .updateStatus(
        this.org.id,
        survey.id,
        distrib.id,
        enable,
        distrib.selector_css,
      )
      .then((res: SurveyDistribution) => {
        distrib.enabled = res.enabled;
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      })
      .finally(() => {
        this.distributionStatusLoading[distrib.id] = true;
      });
  }

  public onDupicateSelectWorkspace(survey: Survey) {
    this.surveyToDuplicate = survey;
    this.duplicateModalOpened = true;
  }

  public onDuplicateCancel() {
    this.duplicateModalOpened = false;
    this.surveyToDuplicate = null;
  }

  public onDuplicateSurvey() {
    const survey = this.surveyToDuplicate;

    this.setSurveyActionLoaderStatus(survey.id, "duplicating", true);

    const title = survey.title;

    this.surveyDao
      .duplicate(
        survey.org_id,
        this.duplicateTargetWorkspaceId,
        this.uiService.surveyType,
        title,
        survey.id,
      )
      .then((newSurvey: Survey) => {
        this.setSurveyActionLoaderStatus(survey.id, "duplicating", false);
        return newSurvey;
      })
      .then((newSurvey: Survey) => {
        this.uiService.fetchEverything();
        return newSurvey;
      })
      .then((newSurvey: Survey) => {
        this.router.navigate(
          [
            "org",
            newSurvey.org_id,
            this.uiService.surveyTypePath,
            newSurvey.id,
          ],
          {},
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
        this.setSurveyActionLoaderStatus(survey.id, "duplicating", false);
      })
      .finally(() => {
        this.duplicateModalOpened = false;
        this.surveyToDuplicate = null;
      });
  }

  public onDelete(survey: Survey) {
    this.setSurveyActionLoaderStatus(survey.id, "deleting", true);

    new Promise((resolve) => {
      let msgPrefix = "";
      if (survey.stats.response_total === 1) {
        msgPrefix = `1 response will be removed.<br>`;
      } else if (survey.stats.response_total > 1) {
        msgPrefix = `${survey.stats.response_total} responses will be removed.<br>`;
      }

      this.modalService.warning({
        nzTitle: "Do you really want to hurt me? 🎶",
        nzContent: msgPrefix + "This operation cannot be undone.",
        nzStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
        },
        nzMaskClosable: true,
        nzCloseOnNavigation: false,
        nzOkType: "default",
        nzOkDanger: true,
        nzOkText: "Confirm",
        nzCancelText: "Cancel",
        nzOnOk: () => resolve(true),
        nzOnCancel: () => resolve(false),
      });
    })
      .then((remove: boolean) => {
        if (!remove) {
          return;
        }

        return this.surveyDao.delete(survey.org_id, survey.id).then(() => {
          this.surveys = this.surveys.filter((s: Survey) => s.id !== survey.id);
          if (
            this.surveys.length === 0 &&
            this.permissionsService.isAllowed("survey.create")
          ) {
            this.router.navigate(
              ["org", survey.org_id, this.uiService.surveyTypePath, "create"],
              {},
            );
          } else {
            this.filterSurveys();
            this.uiService.fetchEverything();
          }
        });
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      })
      .then(() => {
        this.setSurveyActionLoaderStatus(survey.id, "deleting", false);
      });
  }

  public onRename(survey: Survey) {
    this.currentSurveyNameEditing = survey.id;
    this.currentSurveyActionDropDown = null;
    setTimeout(() => {
      this.renameInput.nativeElement.focus();
      this.renameInput.nativeElement.select();
    }, 100);
  }

  private setSurveyActionLoaderStatus(
    surveyId: string,
    statusId: string,
    status: boolean,
  ) {
    if (!this.loaderStatus[surveyId]) {
      this.loaderStatus[surveyId] = {};
    }

    this.loaderStatus[surveyId][statusId] = status;
  }

  public getSurveyActionLoaderStatus(
    surveyId: string,
    statusId: string,
  ): boolean {
    return this.loaderStatus?.[surveyId]?.[statusId] === true;
  }

  public isSurveyTitleValid(name: string) {
    return name.trim().length > 2;
  }

  public stopRenameSurvey() {
    this.currentSurveyNameEditing = null;
  }

  public onRenameInputValidate(event: KeyboardEvent, survey: Survey) {
    const newTitle = (event.target as HTMLInputElement).value;

    if (!this.isSurveyTitleValid(newTitle)) {
      return;
    }

    return this.surveyDao
      .updateTitle(this.org.id, survey.id, newTitle)
      .then(async () => {
        survey.title = newTitle;
        await this.uiService.fetchEverything();
        this.stopRenameSurvey();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        throw err;
      });
  }

  public setCurrentSurveyActionDropDown(surveyId: string, opened: boolean) {
    if (!opened) {
      surveyId = null;
    }
    this.currentSurveyActionDropDown = surveyId;
  }

  getSurveySequenceTags(survey: Survey) {
    if (!survey.sequence_id) {
      return "<No sequence>";
    }

    return (
      this.sequences.find(({ id }) => id === survey.sequence_id)?.title ??
      "<Deleted sequence>"
    );
  }

  /**
   * Search
   */
  public onSearchChange() {
    this.filterSurveys();
  }

  private filterSurveys(): void {
    this.filteredSurveys = this.surveys.filter((survey: Survey) => {
      const hasActiveDistrib = !!survey.survey_distributions.find(
        (distrib) => distrib.enabled,
      );
      const statusOk =
        this.filteredStatus !== null && this.filteredStatus !== undefined
          ? hasActiveDistrib === this.filteredStatus
          : true;
      const tagOk = this.filteredTags?.length
        ? this.filteredTags.every((tag) => survey.tags.includes(tag))
        : true;
      const sequenceOk = this.filteredSequences?.length
        ? this.filteredSequences.every(
            (sequenceId) => survey.sequence_id === sequenceId,
          )
        : true;

      return (
        simpleFTS(survey.title, this.keywords) &&
        statusOk &&
        tagOk &&
        sequenceOk
      );
    });
  }

  onSurveyCreateTemplate() {
    if (
      this.entitlementService.isQuotaExceeded("max_survey", this.surveys.length)
    ) {
      this.upgradePopupVisible = true;
      return;
    }

    this.router.navigate([
      "org",
      this.org.id,
      this.uiService.surveyTypePath,
      "create",
    ]);
  }

  onSurveyCreate(withAI: boolean = false) {
    if (
      this.entitlementService.isQuotaExceeded("max_survey", this.surveys.length)
    ) {
      this.upgradePopupVisible = true;
      return;
    }

    this.surveyDao
      .create(
        this.org.id,
        this.uiService.surveyType,
        this.uiService.isMessagePage ? "New message" : "New survey",
        this.org.survey_languages[0] ?? null,
      )
      .then(async (survey: Survey) => {
        await this.uiService.fetchEverything();
        return survey;
      })
      .then((survey: Survey) => {
        if (withAI) {
          this.router.navigate(
            [`/org/${survey.org_id}/${this.pathPrefix}/${survey.id}/edit`],
            { queryParams: { ai: true } },
          );
        } else {
          this.router.navigate([
            `/org/${survey.org_id}/${this.pathPrefix}/${survey.id}/settings`,
          ]);
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.trigger(
          err?.error?.message ??
            `Failed to create ${this.uiService.isMessagePage ? "message" : "survey"}`,
          null,
          "error",
        );
        console.error(err.error);
      });
  }

  public onSurveysImport() {
    if (
      this.entitlementService.isQuotaExceeded("max_survey", this.surveys.length)
    ) {
      this.upgradePopupVisible = true;
      return;
    }

    this.importModalOpened = true;
  }

  public isInStartEndDates(distrib: SurveyDistribution): boolean {
    const start_date = distrib.targeting_rules?.filter(
      (rule) => rule.type === "start_date",
    )[0]?.value.v_t;

    const end_date = distrib.targeting_rules?.filter(
      (rule) => rule.type === "end_date",
    )[0]?.value.v_t;

    return isTodayBetweenDates(start_date, end_date);
  }
}
