<h1>Survey Capping</h1>

<header>
  <p>
    Cap the displays and responses of your surveys over periods of time. If you
    activate more than one capping type, they will add up.
  </p>
</header>

<section class="capping-selection-group">
  <ul>
    <li class="capping-selection">
      <div class="capping-selection-title">
        <h2>Conditions Before Displays</h2>
        <nz-switch
          [(ngModel)]="surveyEventsAndPropertiesSwitch"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </div>
      <div
        class="capping-config-container"
        *ngIf="surveyEventsAndPropertiesSwitch"
      >
        <!--Config Survey Events and Properties -->
        <p>We won’t display your surveys until these conditions are met.</p>
        <survey-share-targeting-advanced
          [isOrgTargeting]="true"
          [org]="org"
          [(rules)]="otherTargetingRules"
          [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
          [registryEntriesGroup]="registryEntriesGroup"
          [registryEntriesEvent]="registryEntriesEvent"
          [surveys]="surveys"
        >
        </survey-share-targeting-advanced>
      </div>
    </li>
    <li class="capping-selection">
      <div class="capping-selection-title">
        <h2>Global Capping</h2>
        <nz-switch
          [(ngModel)]="perOrgCappingSwitch"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </div>
      <div class="capping-config-container" *ngIf="perOrgCappingSwitch">
        <!--Config Global Capping-->
        <p>
          Cap the displays and responses of all your surveys over a specific
          period of time. This will be applied globally, for all your surveys.
          Once those limits are reached, we won't display your surveys until the
          end of the period.
        </p>
        <survey-capping-config
          [(rules)]="cappingTargetingRules"
          [orgId]="org.id"
          surveyCappingType="per-org"
        ></survey-capping-config>
      </div>
    </li>
    <li class="capping-selection">
      <div class="capping-selection-title">
        <h2>Per Survey Capping</h2>
        <nz-switch
          [(ngModel)]="perSurveyCappingSwitch"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </div>
      <div class="capping-config-container" *ngIf="perSurveyCappingSwitch">
        <!--Config Per Survey Capping-->
        <p>
          Cap the displays and responses of all your surveys individually, over
          a period of time. This will be applied to each of your surveys and
          when one of them reach these limits, the others will continue to be
          displayed.
        </p>
        <survey-capping-config
          [(rules)]="cappingTargetingRules"
          [orgId]="org.id"
          surveyCappingType="per-survey"
        ></survey-capping-config>
      </div>
    </li>
    <li class="capping-selection">
      <div class="capping-selection-title">
        <h2>Per User Capping</h2>
        <nz-switch
          [(ngModel)]="perRespondentCappingSwitch"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
      </div>
      <div class="capping-config-container" *ngIf="perRespondentCappingSwitch">
        <!--Config Per User Capping-->
        <p>
          Cap how many surveys a user will see over a period of time and how
          long we'll wait before displaying a new survey after the user answered
          or closed one.
        </p>
        <survey-capping-config
          [(rules)]="cappingTargetingRules"
          [orgId]="org.id"
          surveyCappingType="per-respondent"
        ></survey-capping-config>
      </div>
    </li>
    <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
    <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
  </ul>
</section>

<nz-button-group class="actions-save">
  <button
    title="Save"
    nz-button
    class="ant-btn-secondary"
    nzSize="large"
    nzShape="round"
    [nzLoading]="saving"
    (click)="onSave()"
    [disabled]="!hasUnsavedChanges()"
  >
    <i nz-icon nzType="check"></i>Save
  </button>
</nz-button-group>

<section class="tuto">
  <h2>Learn how to use survey capping in less than 5 minutes</h2>

  <div style="position: relative; height: 100%">
    <iframe
      src="https://www.loom.com/embed/75d465b9a0da476298e7c67e11c98897"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
    ></iframe>
  </div>
</section>

<upgrade-modal
  [centeredModal]="true"
  [withOverlay]="true"
  [canCancel]="false"
  upgradeType="survey_capping"
  ctaText="Upgrade now!"
  position="absolute"
  class="upgrade-modal"
  *ngIf="!('survey_capping' | hasEntitlement)"
  [orgId]="org.id"
></upgrade-modal>
