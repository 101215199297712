import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { Survey } from "models/survey.model";
import { Org } from "models/org.model";
import { Channel } from "models/channel.model";
import { ENV } from "environment";
import { UIService } from "services/ui.service";
import { TrackersService } from "services/trackers.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { EntitlementService } from "services/entitlement.service";
import { TrackingEventName } from "services/trackers.events";
import { ConfigService } from "services/config.service";

type Parameter = {
  key: string;
  value: string;
};

@Component({
  selector: "page-survey-share-link",
  templateUrl: "./link.component.html",
  styleUrls: ["./link.component.scss"],
})
export class LinkShareSurveyPageComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public channel: Channel = null;
  @Input() public survey: Survey = null;
  @Input() public multiple: boolean = false;

  @Output() public multipleChange = new EventEmitter<boolean>();

  public url: string;
  public parameters: Parameter[] = [
    {
      key: "respondent_id",
      value: "",
    },
  ];

  constructor(
    public uiService: UIService,
    public trackersService: TrackersService,
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
    private configService: ConfigService,
  ) {}

  ngOnInit() {
    this.onUpdateParameter();
  }

  private getURL(): string {
    let url = `${this.configService.config.hostedEndpoint}/1/${this.channel.id}/${this.survey.id}`;

    const parameters = Array.from(this.parameters); // copy

    // on development environment, we need to indicate the path of the javascript tag
    if (ENV["ENV"] !== "prod") {
      parameters.push({
        key: "hostname",
        value: this.configService.config.tagEndpoint,
      });
    }

    const qs = parameters
      .filter((parameter) => Boolean(parameter.value))
      .map(
        (parameter) =>
          `${parameter.key}=${encodeURIComponent(parameter.value)}`,
      );
    if (qs.length > 0) url += `?${qs.join("&")}`;

    return url;
  }

  public async onUpdateParameter() {
    this.url = this.getURL();
  }

  public onAddParameter() {
    const parameter: Parameter = {
      key: "",
      value: "",
    };
    this.parameters.push(parameter);
    this.parameters = Array.from(this.parameters);
    this.onUpdateParameter();
  }

  public removeParameter(i: number) {
    this.parameters.splice(i, 1);
    this.parameters = Array.from(this.parameters);
    this.onUpdateParameter();
  }

  public ensureValidParameterKey(parameter: Parameter) {
    if (!parameter.key) return;

    // remove non-alphanumeric chars (permit - and _)
    setTimeout(() => {
      parameter.key = parameter.key.replace(/[^A-Za-z0-9_-]/g, "");
    }, 50);
  }

  public onLinkCopy() {
    this.eventTracking("Survey URL copied");
  }

  public onLinkOpened() {
    this.eventTracking("Survey URL opened");
  }

  public onQRCodeOpened() {
    this.eventTracking("Survey URL QRCode opened");
  }

  private eventTracking(eventName: TrackingEventName) {
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withChannel(this.channel)
      .withSurvey(this.survey)
      .withProps({
        arguments_count: this.parameters.length,
      })
      .build();
  }
}
