import { Injectable } from "@angular/core";
import { EmailDao } from "models/email.dao";

@Injectable()
class MJMLService {
  constructor(private emailDao: EmailDao) {}

  public compileMJML(orgId: string, surveyId: string): Promise<string> {
    return this.emailDao.getTemplate(orgId, surveyId).then((template) => {
      return template.html;
    });
  }
}

export { MJMLService };
