<css-selector
  class="row"
  [interaction]="interaction"
  [selectorCSS]="selectorCSS"
  (selectorCSSChange)="selectorCSSChange.emit($event)"
/>

<div class="separator" *ngIf="interaction === 'in_page'"></div>

<nz-table
  #ruleTable
  [nzData]="rules"
  [nzShowPagination]="false"
  [nzPageSize]="1000"
  *ngIf="ready"
>
  <thead>
    <tr>
      <th>Trigger</th>
      <th *ngIf="canDisplayOperatorColumn">Operator</th>
      <th>Value</th>
      <th></th>
    </tr>
  </thead>

  <tbody>
    <ng-container
      *ngFor="let rule of ruleTable.data; let i = index"
      class="type-{{ rule.type }}"
    >
      <tr
        *ngIf="displayRule(rule)"
        [ngClass]="{ 'rule-disabled': !!rule.sequence_id }"
      >
        <td class="column-type">
          <div class="column-type-inner">
            <i
              *ngIf="rule.sequence_id"
              nz-icon
              nzType="question-circle"
              nzTheme="outline"
              [nzTooltipTitle]="titleTemplate"
              nzTooltipPlacement="right"
              nz-tooltip
            ></i>
            <ng-template #titleTemplate let-thing>
              These conditions are predefined based on your funnel settings.<br />
              You can add more if you want to further refine the audience that
              will see this survey.
            </ng-template>
            <div *ngIf="notRemovableRulesTypes.includes(rule.type)">
              {{ rule.getTypeLabel() }}
            </div>

            <div *ngIf="!notRemovableRulesTypes.includes(rule.type)">
              <nz-select
                class="rule-type-selector"
                [disabled]="!!rule.sequence_id"
                nzShowSearch
                [ngModel]="rule.type"
                (ngModelChange)="onRuleTypeChange(rule, $event)"
                [nzOptions]="ruleTypeSelectorsOptions[i]"
                [nzDropdownMatchSelectWidth]="false"
                nzPlaceHolder="Select rule type"
              >
              </nz-select>
            </div>
          </div>
        </td>

        <td class="column-operator" *ngIf="canDisplayOperatorColumn">
          <div
            *ngIf="
              [
                'visitor_property',
                'visitor_event_count',
                'visitor_event_time',
                'device_visitor_property',
                'device_visitor_event_count',
                'device_visitor_event_time'
              ].includes(rule.type)
            "
            class="column-operator-content"
          >
            <div
              *ngIf="
                rule.type === 'visitor_property' &&
                registryEntriesIdentityPropertyScreeb.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-properties
                [disabled]="!!rule.sequence_id"
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyScreeb
                "
                [registryEntriesIdentityPropertyPaths]="
                  registryEntriesIdentityPropertyPathsById
                "
                [propertyKeyId]="rule.value.key_id"
                (propertyKeyIdChange)="
                  rule.serializeUserPropertyRuleKey(
                    registryEntriesIdentityPropertyById.get($event)
                  )
                "
                ngDefaultControl
                [invalid]="
                  rule.getRegistryEntrySlug(
                    registryEntriesIdentityPropertyById.get(rule.value.key_id)
                  ) === ''
                "
              ></selector-user-properties>
              <rule-operator
                *ngIf="
                  !!rule.value.key_id && visibleRuleOperator.includes(rule.type)
                "
                [disabled]="!!rule.sequence_id"
                [rule]="rule"
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
                (ruleChange)="rules[i] = $event"
              >
              </rule-operator>
            </div>
            <div
              *ngIf="
                rule.type === 'device_visitor_property' &&
                registryEntriesIdentityPropertyDevice.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-properties
                [disabled]="!!rule.sequence_id"
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyDevice
                "
                [registryEntriesIdentityPropertyPaths]="
                  registryEntriesIdentityPropertyPathsById
                "
                [propertyKeyId]="rule.value.key_id"
                (propertyKeyIdChange)="
                  rule.serializeUserPropertyRuleKey(
                    registryEntriesIdentityPropertyById.get($event)
                  )
                "
                ngDefaultControl
                [invalid]="
                  rule.getRegistryEntrySlug(
                    registryEntriesIdentityPropertyById.get(rule.value.key_id)
                  ) === ''
                "
              ></selector-user-properties>
              <rule-operator
                *ngIf="
                  !!rule.value.key_id && visibleRuleOperator.includes(rule.type)
                "
                [disabled]="!!rule.sequence_id"
                [rule]="rule"
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
                (ruleChange)="rules[i] = $event"
              >
              </rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'visitor_event_time' &&
                registryEntriesEventScreeb.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [channelType]="channel?.type"
                [disabled]="!!rule.sequence_id"
                [registryEntriesEvent]="registryEntriesEventScreeb"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              <nz-select
                [disabled]="!!rule.sequence_id"
                *ngIf="!!rule.value.name_ids"
                class="rule-event-first-last"
                [(ngModel)]="rule.value.v_s"
              >
                <nz-option nzValue="latest" nzLabel="Last trigger"></nz-option>
                <nz-option nzValue="oldest" nzLabel="First trigger"></nz-option>
              </nz-select>
              <rule-operator
                [disabled]="!!rule.sequence_id"
                *ngIf="
                  !!rule.value.name_ids &&
                  visibleRuleOperator.includes(rule.type)
                "
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'device_visitor_event_time' &&
                registryEntriesEventDevice.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [channelType]="channel?.type"
                [disabled]="!!rule.sequence_id"
                [registryEntriesEvent]="registryEntriesEventDevice"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              <nz-select
                [disabled]="!!rule.sequence_id"
                *ngIf="!!rule.value.name_ids"
                class="rule-event-first-last"
                [(ngModel)]="rule.value.v_s"
              >
                <nz-option nzValue="latest" nzLabel="Last trigger"></nz-option>
                <nz-option nzValue="oldest" nzLabel="First trigger"></nz-option>
              </nz-select>
              <rule-operator
                [disabled]="!!rule.sequence_id"
                *ngIf="
                  !!rule.value.name_ids &&
                  visibleRuleOperator.includes(rule.type)
                "
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'visitor_event_count' &&
                registryEntriesEventScreeb.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [channelType]="channel?.type"
                [disabled]="!!rule.sequence_id"
                [registryEntriesEvent]="registryEntriesEventScreeb"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              <rule-operator
                [disabled]="!!rule.sequence_id"
                *ngIf="
                  !!rule.value.name_ids &&
                  visibleRuleOperator.includes(rule.type)
                "
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'device_visitor_event_count' &&
                registryEntriesEventDevice.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [channelType]="channel?.type"
                [disabled]="!!rule.sequence_id"
                [registryEntriesEvent]="registryEntriesEventDevice"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              <rule-operator
                [disabled]="!!rule.sequence_id"
                *ngIf="
                  !!rule.value.name_ids &&
                  visibleRuleOperator.includes(rule.type)
                "
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>
          </div>

          <ng-container
            *ngIf="
              ![
                'visitor_property',
                'visitor_event_count',
                'visitor_event_time',
                'device_visitor_property',
                'device_visitor_event_count',
                'device_visitor_event_time'
              ].includes(rule.type)
            "
          >
            <rule-operator
              [disabled]="!!rule.sequence_id"
              *ngIf="visibleRuleOperator.includes(rule.type)"
              [rule]="rule"
              (ruleChange)="rules[i] = $event"
            ></rule-operator>
          </ng-container>
        </td>

        <td class="column-value">
          <ng-container [ngSwitch]="rule.type">
            <div *ngSwitchCase="'language'">
              <rule-language
                [rule]="rule"
                [languages]="languages"
              ></rule-language>
            </div>

            <div *ngSwitchCase="'country'">
              <rule-country
                [rule]="rule"
                [countries]="countries"
              ></rule-country>
            </div>

            <div *ngSwitchCase="'device'">
              <rule-device [rule]="rule" [channel]="channel"></rule-device>
            </div>

            <div *ngSwitchCase="'url'">
              <rule-url [rule]="rule"></rule-url>
            </div>

            <div *ngSwitchCase="'delay'">
              <rule-delay [rule]="rule"></rule-delay>
            </div>

            <div *ngSwitchCase="'session_duration'">
              <rule-session-duration [rule]="rule"></rule-session-duration>
            </div>

            <div *ngSwitchCase="'scroll'">
              <rule-scroll [rule]="rule"></rule-scroll>
            </div>

            <div *ngSwitchCase="'scroll_reach_element'">
              <rule-scroll-reach-element
                [rule]="rule"
              ></rule-scroll-reach-element>
            </div>

            <div *ngSwitchCase="'dom_element'">
              <rule-dom-element [rule]="rule"></rule-dom-element>
            </div>

            <div *ngSwitchCase="'recurrence'">
              <rule-recurrence
                [rule]="rule"
                [ruleIfAnswered]="getRuleRecurrenceNotAnswered()"
                (ruleIfAnsweredCreation)="
                  onAddRule($event?.type, $event?.operator, $event?.value)
                "
                (ruleIfAnsweredDeletion)="removeSpecificRule($event)"
              ></rule-recurrence>
            </div>

            <div *ngSwitchCase="'start_date'">
              <rule-date [rule]="rule"></rule-date>
            </div>
            <div *ngSwitchCase="'end_date'">
              <rule-date [rule]="rule"></rule-date>
            </div>

            <!-- <div *ngSwitchDefault>
              <input type="text" nz-input placeholder="{{ rule.getValuePlaceholder() }}" [(ngModel)]="rule.value">
            </div> -->

            <div *ngSwitchCase="'visitor_event_count'">
              <rule-user-event-count
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventScreeb"
              >
              </rule-user-event-count>
            </div>

            <div *ngSwitchCase="'visitor_event_time'">
              <rule-user-event-time
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventScreeb"
              >
              </rule-user-event-time>
            </div>

            <div *ngSwitchCase="'visitor_event_sequence'">
              <rule-user-event-sequence
                [channelType]="channel?.type"
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventScreeb"
              >
              </rule-user-event-sequence>
            </div>

            <div *ngSwitchCase="'screen'">
              <rule-screen
                [rule]="rule"
                [channelClientScreens]="channelClientScreens"
              >
              </rule-screen>
            </div>

            <div *ngSwitchCase="'app_version'">
              <rule-app-version
                [rule]="rule"
                [channelClientVersions]="channelClientVersions"
              >
              </rule-app-version>
            </div>

            <div *ngSwitchCase="'visitor_property'">
              <rule-user-property
                [rule]="rule"
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyScreeb
                "
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
              ></rule-user-property>
            </div>

            <div *ngSwitchCase="'visitor_group'">
              <rule-user-segment
                [rule]="rule"
                [registryEntriesGroup]="registryEntriesGroup"
              ></rule-user-segment>
            </div>

            <div *ngSwitchCase="'visitor_answered_survey'">
              <rule-user-answered-survey
                [rule]="rule"
                [surveys]="surveys"
              ></rule-user-answered-survey>
            </div>

            <div *ngSwitchCase="'visitor_alias'">
              <rule-user-alias [org]="org" [rule]="rule"></rule-user-alias>
            </div>

            <div *ngSwitchCase="'device_visitor_property'">
              <rule-user-property
                [rule]="rule"
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyDevice
                "
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
              ></rule-user-property>
            </div>

            <div *ngSwitchCase="'device_visitor_event_count'">
              <rule-user-event-count
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventDevice"
              >
              </rule-user-event-count>
            </div>

            <div *ngSwitchCase="'device_visitor_event_time'">
              <rule-user-event-time
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventDevice"
              >
              </rule-user-event-time>
            </div>

            <div *ngSwitchCase="'device_visitor_event_sequence'">
              <rule-user-event-sequence
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventDevice"
              >
              </rule-user-event-sequence>
            </div>
          </ng-container>
        </td>

        <td class="column-actions">
          <a
            *ngIf="
              !notRemovableRulesTypes.includes(rule.type) && !rule.sequence_id
            "
            (click)="removeRule(i)"
          >
            <i nz-icon nzType="close"></i>
          </a>
        </td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>

<div class="right">
  <button
    class="survey-targeting-rule-new ant-btn-secondary"
    nz-button
    nzType="default"
    (click)="onAddRule()"
  >
    Add a trigger
  </button>
</div>

<div class="clearfix"></div>
