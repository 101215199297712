import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";

import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import { AnalyticsResponse } from "models/analytics.model";
import { CTAType, UUID } from "models/survey.dao.types";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { UIService } from "services/ui.service";
import { deepCopy } from "utils/object";

@Component({
  selector: "survey-stats-content-analysis-question-analysis",
  templateUrl: "./question-analysis.component.html",
  styleUrls: ["./question-analysis.component.scss"],
})
export class QuestionContentAnalysisComponent implements OnInit, OnDestroy {
  @Input()
  public questionType: CTAType;
  @Input()
  public letter: string;
  @Input()
  public description: string;
  @Input()
  public nodeCorrelationId: string;
  @Input()
  public answersCount: number;
  @Input()
  public maxAnswersCount: number;

  private filtersObs$: any = null;
  public lastFilters: AnalyticsQueryResponse;

  public summaryLoading: boolean = false;
  public summary: string = null;
  public categoryLoading: boolean = false;
  public category: string = null;
  public improvementLoading: boolean = false;
  public improvement: string = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private analyticsFilterService: AnalyticsFilterService,
    private analyticsDao: AnalyticsDao,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQueryResponse) => {
        this.lastFilters = deepCopy(filters);

        this.summary = null;
        this.category = null;
        this.improvement = null;
      });
  }
  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  public onGPT3SummaryRequest() {
    this.summaryLoading = true;

    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;
    query.question_ids = [this.nodeCorrelationId as UUID];
    query.aggregation = [
      {
        by: "gpt3.summary",
        params: {
          node_correlation_id: this.nodeCorrelationId,
        },
      },
    ];
    query.size = 0;

    return this.analyticsDao
      .search(query)
      .then((res: AnalyticsResponse) => {
        const summary = res.aggregations?.summary;
        this.summary = summary?.trim().replaceAll("\n", "<br>");
        this.summaryLoading = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.summary = null;
        this.summaryLoading = false;
        console.error(err);
      });
  }

  public onGPT3CategoryRequest() {
    this.categoryLoading = true;

    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;
    query.question_ids = [this.nodeCorrelationId as UUID];
    query.aggregation = [
      {
        by: "gpt3.category",
        params: {
          node_correlation_id: this.nodeCorrelationId,
        },
      },
    ];
    query.size = 0;

    return this.analyticsDao
      .search(query)
      .then((res: AnalyticsResponse) => {
        const category = res.aggregations?.["category"];
        this.category = category?.trim().replaceAll("\n", "<br>");
        this.categoryLoading = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.category = null;
        this.categoryLoading = false;
        console.error(err);
      });
  }

  public onGPT3ImprovementRequest() {
    this.improvementLoading = true;

    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;
    query.question_ids = [this.nodeCorrelationId as UUID];
    query.aggregation = [
      {
        by: "gpt3.improvement",
        params: {
          node_correlation_id: this.nodeCorrelationId,
        },
      },
    ];
    query.size = 0;

    return this.analyticsDao
      .search(query)
      .then((res: AnalyticsResponse) => {
        const improvement = res.aggregations?.["improvement"];
        this.improvement = improvement?.trim().replaceAll("\n", "<br>");
        this.improvementLoading = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.improvement = null;
        this.improvementLoading = false;
        console.error(err);
      });
  }
}
