<div>
  <tag-settings-with-preview
    class="distribution-settings"
    [(tagSettings)]="tagSettings"
    [defaultTagSettings]="getDefaultDistributionSettings()"
    [org]="org"
    [survey]="survey"
    [surveyDistribution]="surveyDistribution"
    scope="distribution"
    (specificDesignSwitchChange)="specificDesignSwitchChange($event)"
    [interactionType]="survey.interaction"
  >
  </tag-settings-with-preview>

  <ng-template #pageActions>
    <div class="toolbar" *ngIf="'survey.distribution.update' | hasPermission">
      <nz-button-group nzSize="large" class="actions-save">
        <button
          title="Reset survey"
          nz-button
          nzType="default"
          nzSize="large"
          nzShape="round"
          [disabled]="saveButtonDisabled()"
          [nzLoading]="saving"
          (click)="actionOnSave()"
        >
          <i nz-icon nzType="save"></i>Save
        </button>
      </nz-button-group>
    </div>
  </ng-template>
</div>
