<div class="header-group">
  <h1>Session details</h1>
</div>
<div class="loader" *ngIf="!user">
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>
<ng-container *ngIf="user">
  <ng-container>
    <error-message
      *ngIf="errorResponsesES"
      message="An error occured when fetching data."
      [details]="errorResponsesES.message"
      (reload)="refreshData()"
    ></error-message>

    <div class="gridAppear" *ngIf="!errorResponsesES">
      <section nz-row [nzGutter]="[32, 16]" class="player-container">
        <div nz-col nzSpan="24" nzXl="16">
          <div
            class="rrweb-player-wrapper"
            (mouseenter)="onPlayerHover(true)"
            (mouseleave)="onPlayerHover(false)"
            (mousemove)="onPlayerHover(true)"
          >
            <div id="rrweb-player-target" #rrwebPlayerWrapper></div>
            <div
              class="player-controllers"
              [class.is-hidden]="isControllerHidden"
            >
              <div>
                <div
                  class="player-controller-progress"
                  (click)="onPlayerProgressClick($event)"
                >
                  <div
                    class="player-controller-progress-bar"
                    style="width: {{ recordProgress }}%;"
                  ></div>
                </div>
                <div class="player-controllers-inner">
                  <screeb-icon
                    *ngIf="!isRecordPlaying"
                    size="md"
                    icon="play"
                    (click)="onPlayerToggle()"
                  />
                  <screeb-icon
                    *ngIf="isRecordPlaying"
                    size="md"
                    icon="pause"
                    (click)="onPlayerToggle()"
                  />
                  <screeb-icon
                    size="md"
                    icon="seek-prev"
                    [class.icon-paused]="!isRecordPlaying || !canSeekPrev"
                    (click)="onPlayerSeekPrev()"
                  />
                  <screeb-icon
                    size="md"
                    icon="seek-next"
                    style="position: relative; top: 1px"
                    [class.icon-paused]="!isRecordPlaying || !canSeekNext"
                    (click)="onPlayerSeekNext()"
                  />
                  <div class="is-spacer"></div>
                  <p>{{ playerFormattedTime }} - {{ playerTotalTime }}</p>
                  <div class="is-spacer"></div>
                  <screeb-icon
                    size="md"
                    icon="fullscreen"
                    (click)="onPlayerFullScreen()"
                  />
                  <screeb-icon
                    *ngIf="playerSpeed === 1"
                    size="md"
                    icon="speed"
                    (click)="onPlayerSpeedChange(2)"
                  />
                  <screeb-icon
                    *ngIf="playerSpeed === 2"
                    size="md"
                    icon="speed-2x"
                    (click)="onPlayerSpeedChange(3)"
                  />
                  <screeb-icon
                    *ngIf="playerSpeed === 3"
                    size="md"
                    icon="speed-3x"
                    (click)="onPlayerSpeedChange(4)"
                  />
                  <screeb-icon
                    *ngIf="playerSpeed === 4"
                    size="md"
                    icon="speed-4x"
                    (click)="onPlayerSpeedChange(5)"
                  />
                  <screeb-icon
                    *ngIf="playerSpeed === 5"
                    size="md"
                    icon="speed-5x"
                    (click)="onPlayerSpeedChange(1)"
                  />
                  <!-- <screeb-icon size="md" icon="settings" /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div nz-col nzXs="24" nzLg="24" nzXl="8">
          <section nz-row [nzGutter]="[32, 16]" class="indicators">
            <div nz-col nzSpan="24" class="fullsize-indicator">
              <div class="user-header iAppear">
                <img
                  loading="lazy"
                  alt="User's icon"
                  nz-icon
                  [src]="getUserIcon(user.id, getAvatarURL())"
                />
                <div class="identity">
                  <p>
                    {{
                      getFormattedUserNameOrIDByNormalizedProperty(
                        user.id,
                        user.aliases,
                        userProperties
                      )
                    }}
                    <ng-container *ngIf="user.is_anonymous">
                      <nz-tag
                        nz-popover
                        nzPopoverTitle="Anonymous users"
                        [nzPopoverContent]="anonymousHelp"
                      >
                        Anonymous
                      </nz-tag>

                      <ng-template #anonymousHelp>
                        This user has not been identified in Javascript tag and
                        mobile SDKs.
                        <br />
                        <a
                          rel="noopener"
                          href="https://help.screeb.app"
                          target="_blank"
                        >
                          <i nz-icon nzType="link"></i>
                          See our help section.
                        </a>
                      </ng-template>
                    </ng-container>
                    <a
                      class="open-link"
                      routerLink="/org/{{ org.id }}/people/respondent/{{
                        user.id
                      }}"
                    >
                      Open
                    </a>
                  </p>
                  <div class="groups">
                    <nz-tag
                      *ngFor="let userGroup of userGroups"
                      [nzMode]="
                        ('segment.update' | hasPermission)
                          ? 'closeable'
                          : 'default'
                      "
                      (nzOnClose)="
                        removeUserFromGroup(userGroup); $event.preventDefault()
                      "
                    >
                      {{ getFormattedUserGroupNameOrID(userGroup) }}
                    </nz-tag>
                    <user-add-to-segment
                      *ngIf="'segment.update' | hasPermission"
                      [org]="org"
                      [user]="user"
                      [isSmall]="true"
                      [orgUserGroups]="orgUserGroups"
                      [userAssignedGroups]="userGroups"
                      (addedToSegments)="onAddedToSegments($event)"
                    >
                    </user-add-to-segment>
                  </div>
                </div>
              </div>
              <h2 class="indicator-title">Activity</h2>
              <div class="indicator-with-border">
                <div
                  class="loader"
                  *ngIf="loadingResponsesES && initialFetchResponsesES"
                >
                  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
                </div>
                <ng-container
                  *ngIf="!initialFetchResponsesES || !loadingResponsesES"
                >
                  <ul class="stats-list iAppear">
                    <li class="stat-item">
                      <div class="stat-item-header">
                        <square-icon icon="star"></square-icon>
                        <div class="stat-item-data">
                          <div class="stat-item-title">First seen</div>
                          <div
                            class="stat-item-value"
                            [title]="user.created_at | toLocaleString"
                          >
                            {{
                              user.created_at
                                | dfnsFormatDistanceToNow: { addSuffix: true }
                            }}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="stat-item">
                      <div class="stat-item-header">
                        <square-icon icon="bye"></square-icon>
                        <div class="stat-item-data">
                          <div class="stat-item-title">Last seen</div>
                          <div
                            class="stat-item-value"
                            [title]="user.last_activity_at | toLocaleString"
                          >
                            {{
                              user.last_activity_at
                                | dfnsFormatDistanceToNow: { addSuffix: true }
                            }}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="stat-item">
                      <div class="stat-item-header">
                        <square-icon icon="response"></square-icon>
                        <div class="stat-item-data">
                          <div class="stat-item-title">Number of responses</div>
                          <div class="stat-item-value">
                            {{ responsesCount }}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="stat-item">
                      <div class="stat-item-header">
                        <square-icon icon="response"></square-icon>
                        <div class="stat-item-data">
                          <div class="stat-item-title">Last response</div>
                          <div
                            class="stat-item-value"
                            *ngIf="!lastResponseDate || +lastResponseDate === 0"
                          >
                            N/A
                          </div>
                          <div
                            class="stat-item-value"
                            [title]="lastResponseDate | toLocaleString"
                            *ngIf="lastResponseDate && +lastResponseDate !== 0"
                          >
                            {{
                              lastResponseDate
                                | dfnsFormatDistanceToNow: { addSuffix: true }
                            }}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </ng-container>
              </div>
            </div>
            <div nz-col nzSpan="24" class="fullsize-indicator">
              <h2 class="indicator-title">Timeline</h2>
              <div class="indicator-with-border">
                <div
                  class="loader"
                  *ngIf="loadingResponsesPG && initialFetchResponsesPG"
                >
                  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
                </div>
                <ng-container
                  *ngIf="!initialFetchResponsesPG || !loadingResponsesPG"
                >
                  <nz-timeline class="timeline" nzMode="right">
                    <ng-container *ngFor="let response of userResponsesPG">
                      <ng-template #itemRight>
                        <div>
                          {{
                            response.created_at
                              | dfnsFormatDistanceToNow: { addSuffix: true }
                          }}
                        </div>
                        <tag-registry-entry-source
                          [sources]="[response.source]"
                          size="small"
                        >
                        </tag-registry-entry-source>
                      </ng-template>

                      <nz-timeline-item [nzLabel]="itemRight">
                        <div
                          class="timeline-label"
                          *ngIf="response.survey?.deleted_at"
                        >
                          {{ response.survey.title }}
                          <span>(Deleted)</span>
                        </div>
                        <a
                          class="timeline-label"
                          *ngIf="!response.survey?.deleted_at"
                          routerLink="/org/{{ org.id }}/survey/{{
                            response.survey_id
                          }}/stats"
                        >
                          {{ response.survey?.title }}
                        </a>
                        <nz-tag
                          nzColor="var(--screeb-color-green-500)"
                          nz-tooltip
                          nzTooltipTitle="Survey version created at: {{
                            response.scenario?.created_at | toLocaleString
                          }}"
                        >
                          <span>v.{{ response.scenario?.version }}</span>
                        </nz-tag>
                        <nz-tag
                          [nzColor]="
                            response.completion | responseCompletionColor
                          "
                        >
                          <span>{{
                            response.completion | responseCompletion
                          }}</span>
                        </nz-tag>
                        <nz-tag
                          *ngIf="response.end_reason === 'closed'"
                          nzColor="#292929"
                        >
                          <span>Closed</span>
                        </nz-tag>
                        <nz-tag
                          *ngIf="response.end_reason === 'interrupted'"
                          nzColor="#292929"
                        >
                          <span>Interrupted</span>
                        </nz-tag>
                        <nz-tag
                          *ngIf="response.end_reason === 'expired'"
                          nzColor="#292929"
                        >
                          <span>Expired</span>
                        </nz-tag>
                      </nz-timeline-item>
                    </ng-container>
                  </nz-timeline>
                  <nz-empty
                    *ngIf="!userResponsesPG.length"
                    [nzNotFoundContent]="contentTpl"
                  >
                    <ng-template #contentTpl>
                      <div>No displays</div>
                    </ng-template>
                  </nz-empty>
                </ng-container>
              </div>
            </div>
            <div nz-col nzSpan="24" class="fullsize-indicator">
              <h2 class="indicator-title">
                <span>Responses Emotions Analysis</span>
                <utils-badge text="New"></utils-badge>
              </h2>
              <div class="indicator-with-border full-height">
                <survey-stats-indicators-radar-indicator
                  [labels]="[
                    '😡 Anger',
                    '&nbsp;😄 Joy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
                    '😱 Fear',
                    '😢 Sadness'
                  ]"
                  [legend]="emotionsChartLegend"
                  [datasets]="emotionsChartDatasets"
                  [hideTitle]="true"
                  [loading]="loadingEmotions"
                />
              </div>
            </div>
            <div nz-col nzSpan="24" class="fullsize-indicator">
              <h2 class="indicator-title">Live events</h2>
              <div class="indicator-with-border">
                <div class="loader" *ngIf="loadingEvents && initialFetchEvents">
                  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
                </div>
                <ng-container *ngIf="!initialFetchEvents || !loadingEvents">
                  <user-events
                    [userEvents]="userEvents"
                    (eventClicked)="onEventClicked()"
                  >
                  </user-events>
                </ng-container>
              </div>
            </div>
            <div nz-col nzSpan="24" class="fullsize-indicator">
              <h2 class="indicator-title">Properties</h2>
              <div class="indicator-with-border">
                <div
                  class="loader"
                  *ngIf="loadingProperties && initialFetchProperties"
                >
                  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
                </div>
                <ng-container
                  *ngIf="!initialFetchProperties || !loadingProperties"
                >
                  <user-properties
                    [userProperties]="userProperties"
                  ></user-properties>
                </ng-container>
              </div>
            </div>

            <ng-container
              *ngIf="
                (!initialFetchEventsTypeform || !loadingEventsTypeform) &&
                userEventsTypeform.length
              "
            >
              <div nz-col nzSpan="24" class="fullsize-indicator">
                <h2 class="indicator-title">Typeform responses</h2>
                <div class="indicator-with-border">
                  <ul class="stats-list iAppear">
                    <li
                      *ngFor="let event of userEventsTypeform"
                      class="stat-item clickable"
                    >
                      <a
                        class="stat-item-header typeform"
                        [href]="getTypeformEventURL(event)"
                        (click)="event.opened = !event.opened; onEventClicked()"
                        target="_blank"
                      >
                        <square-icon icon="typeform"></square-icon>
                        <div class="stat-item-data">
                          <div class="stat-item-title">
                            <span>{{ event.slug }}</span>
                            <tag-registry-entry-source
                              [sources]="[event.source]"
                            ></tag-registry-entry-source>
                          </div>
                          <div
                            class="stat-item-value"
                            [title]="event.triggered_at | toLocaleString"
                          >
                            {{
                              event.triggered_at
                                | dfnsFormatDistanceToNow: { addSuffix: true }
                            }}
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </section>
        </div>
      </section>
    </div>
  </ng-container>
</ng-container>
