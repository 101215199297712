import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { EntitlementService } from "services/entitlement.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "tag-settings-translation",
  templateUrl: "./tag-settings-translation.component.html",
  styleUrls: ["./tag-settings-translation.component.scss"],
})
export class TagSettingsTranslationComponent implements OnInit {
  @Input() public translationEnabled: boolean = true;

  @Output() public specificTranslationEnabledSwitchChange =
    new EventEmitter<boolean>();

  public billingPopupVisible = false;
  public translationAvailable = false;

  constructor(
    public entitlementService: EntitlementService,
    private router: Router,
    private uiService: UIService,
  ) {}

  public ngOnInit() {
    this.translationAvailable = this.entitlementService.isAvailable(
      "response_translation",
    );
  }

  public onBillingPopupVisibleChange(visible: boolean) {
    this.billingPopupVisible = visible;
  }

  public onTranslationActivationChange(active: boolean) {
    this.translationEnabled = active;
    if (!this.translationAvailable) {
      setTimeout(() => {
        this.translationEnabled = !active;
      }, 0);

      this.billingPopupVisible = true;
      return;
    }
    this.specificTranslationEnabledSwitchChange.emit(active);
  }

  public navigateToBilling() {
    this.billingPopupVisible = false;

    this.router.navigate([
      "org",
      this.uiService.currentOrgId,
      "settings",
      "billing",
    ]);
  }
}
