import { Serializable } from "models/serializable.model";
import {
  WorkspaceAccountPermission,
  WorkspaceAccountRole,
} from "./account-permissions";
import { SurveyLanguages, TranslationLanguage } from "./survey.dao.types";
import { TagSettings } from "./tag-settings.types";

export type WorkspaceRegion = "eu" | "us";

class Org extends Serializable {
  constructor(
    public id?: string,
    public super_org_id?: string,
    public tenant_region?: WorkspaceRegion,
    public name?: string,
    public industry?: string,
    public tags?: string[],
    public flags?: OrgFlags,
    public entitlements?: OrgEntitlements,
    public data_governance?: OrgDataGovernance,
    public stats?: OrgStats,
    public logo?: string,
    public last_seen_at?: Date,
    public created_at?: Date,
    public updated_at?: Date,
    // public deleted_at?: Date,
    public settings?: TagSettings,
    public api_token?: string,
    public survey_languages?: SurveyLanguages[],
    public translation_language?: TranslationLanguage,
    public translation_enabled?: boolean,
    public computed_translations?: TranslationLanguage[],
    public role?: WorkspaceAccountRole,
    public permissions?: WorkspaceAccountPermission[],
    public answer_tags?: OrgAnswerTags[],
    public smart_answer_tags?: string[],
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    this.tenant_region = json["tenant_region"] as WorkspaceRegion;

    if (json["flags"]) this.flags = new OrgFlags().fromJson(json["flags"]);
    if (json["entitlements"])
      this.entitlements = new OrgEntitlements().fromJson(json["entitlements"]);
    if (json["data_governance"])
      this.data_governance = new OrgDataGovernance().fromJson(
        json["data_governance"],
      );
    if (json["stats"]) this.stats = new OrgStats().fromJson(json["stats"]);

    return this;
  }
}

class OrgFlags extends Serializable {
  constructor(
    public debug_log_level?: string, // optional
    public sentry_log_enabled?: boolean, // optional
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

class OrgEntitlements extends Serializable {
  constructor(
    public mtu_scheme?: "limited" | "unlimited",
    public max_mtu?: number, // -1 if unlimited
    public max_mtu_window_start?: number, // optional

    public survey_scheme?: "limited" | "unlimited",
    public max_surveys?: number, // -1 if unlimited

    public funnel_scheme?: "limited" | "unlimited",
    public max_funnels?: number, // -1 if unlimited

    public max_workspaces?: number,
    // features
    public device_tracking_enabled?: boolean,
    public team_membership_enabled?: boolean,
    public capping_enabled?: boolean,
    public content_analysis_enabled?: boolean,
    public segment_analysis_enabled?: boolean,
    public multilingual_survey_enabled?: boolean,
    public advanced_survey_customization_enabled?: boolean,
    public session_recording_enabled?: boolean,
    public video_question_enabled?: boolean,
    public audio_answer_enabled?: boolean,
    public video_answer_enabled?: boolean,
    public response_translation_enabled?: boolean,
    // integrations
    // forward
    public integration_productboard_enabled?: boolean,
    public integration_harvestr_enabled?: boolean,
    public integration_zendesk_enabled?: boolean,
    public integration_intercom_enabled?: boolean,
    public integration_trello_enabled?: boolean,
    public integration_cycle_enabled?: boolean,
    public integration_monday_enabled?: boolean,
    public integration_asana_enabled?: boolean,
    // cdp
    public integration_amplitude_source_enabled?: boolean,
    public integration_segment_source_enabled?: boolean,
    public integration_rudderstack_source_enabled?: boolean,
    public integration_typeform_enabled?: boolean,
    // hooks
    public integration_stitch_enabled?: boolean,
    public integration_webhook_enabled?: boolean,
    public integration_zapier_enabled?: boolean,
    public integration_cobbai_enabled?: boolean,
    public integration_email_enabled?: boolean,
    public integration_notion_enabled?: boolean,
    public integration_atlassian_enabled?: boolean,
    public integration_amplitude_destination_enabled?: boolean,
    public integration_segment_destination_enabled?: boolean,
    public integration_rudderstack_destination_enabled?: boolean,
    // reporting
    public integration_slack_enabled?: boolean,
    // addons
    public white_label_enabled?: boolean,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

class OrgDataGovernance extends Serializable {
  constructor(
    public retention_policy_identity_days?: number,
    public retention_policy_group_days?: number,
    public retention_policy_event_days?: number,
    public retention_policy_response_days?: number,
    public registry_limit_context_property?: number,
    public registry_limit_identity_property?: number,
    public registry_limit_group_type?: number,
    public registry_limit_group_type_property?: number,
    public registry_limit_group?: number,
    public registry_limit_track?: number,
    public registry_limit_track_property?: number,
    public registry_limit_screen?: number,
    public registry_limit_screen_property?: number,
    public tracking_capability_cohort_enabled?: boolean,
    public tracking_capability_page_enabled?: boolean,
    public tracking_capability_screen_enabled?: boolean,
    public tracking_capability_device_enabled?: boolean,
    public tracking_capability_ip_enabled?: boolean,
    public tracking_capability_geoip_enabled?: boolean,
    public tracking_capability_click_enabled?: boolean,
    public tracking_capability_tap_enabled?: boolean,
    public tracking_capability_copy_enabled?: boolean,
    public tracking_capability_paste_enabled?: boolean,
    public tracking_capability_highlight_enabled?: boolean,
    public tracking_capability_zoom_enabled?: boolean,
    public tracking_capability_rage_click_enabled?: boolean,
    public tracking_capability_dead_click_enabled?: boolean,
    public tracking_capability_thrashing_click_enabled?: boolean,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

class OrgStats extends Serializable {
  constructor(
    // forever stats
    public total_respondents?: number, // Overall respondents (count)
    public total_events_track?: number, // Overall stored track events (count)
    public total_events_screen?: number, // Overall stored screen events (count)
    public total_identity_properties?: number, // Overall stored identity properties updates for every respondents (count)
    public total_group_assignations?: number, // Overall stored group assignations for every respondents (count)
    public total_survey_question_video_uploaded?: number, // Overall uploaded survey question video (count)
    public total_survey_question_video_size_uploaded?: number, // Overall uploaded survey question video size (in bytes)
    public total_response_answer_media_uploaded?: number, // Overall uploaded response answer media (count)
    public total_response_answer_media_size_uploaded?: number, // Overall uploaded response answer media size (in bytes)
    public total_survey_responses?: number, // Total survey responses, whatever the completion
    public total_survey_responses_not_started?: number, // Total survey responses, when completion is not_started
    public total_survey_responses_partially_completed?: number, // Total survey responses, when completion is partially_completed
    public total_survey_responses_fully_completed?: number, // Total survey responses, when completion is fully_completed
    public total_survey_responses_started?: number, // Total survey responses, when completion is partially_completed or fully_completed
    public total_message_responses?: number, // Total message responses, whatever the completion
    public total_message_responses_not_started?: number, // Total message responses, when completion is not_started
    public total_message_responses_partially_completed?: number, // Total message responses, when completion is partially_completed
    public total_message_responses_fully_completed?: number, // Total message responses, when completion is fully_completed
    public total_message_responses_started?: number, // Total message responses, when completion is partially_completed or fully_completed
    // 30 rolling days stats
    public rolling_month_respondents?: number, // number of respondents in the last 30 days (count)
    public rolling_month_events_track?: number, // number of stored track events in the last 30 days (count)
    public rolling_month_events_screen?: number, // number of stored screen events in the last 30 days (count)
    public rolling_month_identity_properties?: number, // number of stored identity properties updates for every respondents in the last 30 days (count)
    public rolling_month_group_assignations?: number, // number of stored group assignations for every respondents in the last 30 days (count)
    public rolling_month_survey_question_video_uploaded?: number, // number of uploaded survey question video in the last 30 days (count)
    public rolling_month_survey_question_video_size_uploaded?: number, // number of uploaded survey question video size in the last 30 days (in bytes)
    public rolling_month_survey_response_media_uploaded?: number, // number of uploaded response answer media in the last 30 days (count)
    public rolling_month_survey_response_media_size_uploaded?: number, // number of uploaded response answer media size in the last 30 days (in bytes)
    public rolling_month_survey_responses?: number, // Monthly survey responses, whatever the completion
    public rolling_month_survey_responses_not_started?: number, // Monthly survey responses, when completion is not_started
    public rolling_month_survey_responses_partially_completed?: number, // Monthly survey responses, when completion is partially_completed
    public rolling_month_survey_responses_fully_completed?: number, // Monthly survey responses, when completion is fully_completed
    public rolling_month_survey_responses_started?: number, // Monthly survey responses, when completion is partially_completed or fully_completed
    public rolling_month_message_responses?: number, // Monthly message responses, whatever the completion
    public rolling_month_message_responses_not_started?: number, // Monthly message responses, when completion is not_started
    public rolling_month_message_responses_partially_completed?: number, // Monthly message responses, when completion is partially_completed
    public rolling_month_message_responses_fully_completed?: number, // Monthly message responses, when completion is fully_completed
    public rolling_month_message_responses_started?: number, // Monthly message responses, when completion is partially_completed or fully_completed
    // 7 rolling days stats
    public rolling_week_respondents?: number, // number of respondents in the last 7 days (count)
    public rolling_week_events_track?: number, // number of stored track events in the last 7 days (count)
    public rolling_week_events_screen?: number, // number of stored screen events in the last 7 days (count)
    public rolling_week_identity_properties?: number, // number of stored identity properties updates for every respondents in the last 7 days (count)
    public rolling_week_group_assignations?: number, // number of stored group assignations for every respondents in the last 7 days (count)
    public rolling_week_survey_responses?: number, // weekly survey responses, whatever the completion
    public rolling_week_survey_responses_not_started?: number, // weekly survey responses, when completion is not_started
    public rolling_week_survey_responses_partially_completed?: number, // weekly survey responses, when completion is partially_completed
    public rolling_week_survey_responses_fully_completed?: number, // weekly survey responses, when completion is fully_completed
    public rolling_week_survey_responses_started?: number, // weekly survey responses, when completion is partially_completed or fully_completed
    public rolling_week_message_responses?: number, // weekly message responses, whatever the completion
    public rolling_week_message_responses_not_started?: number, // weekly message responses, when completion is not_started
    public rolling_week_message_responses_partially_completed?: number, // weekly message responses, when completion is partially_completed
    public rolling_week_message_responses_fully_completed?: number, // weekly message responses, when completion is fully_completed
    public rolling_week_message_responses_started?: number, // weekly message responses, when completion is partially_completed or fully_completed
    // 1 rolling day stats
    public rolling_day_respondents?: number, // number of respondents in the last 1 day (count)
    public rolling_day_events_track?: number, // number of stored track events in the last 1 day (count)
    public rolling_day_events_screen?: number, // number of stored screen events in the last 1 day (count)
    public rolling_day_identity_properties?: number, // number of stored identity properties updates for every respondents in the last 1 day (count)
    public rolling_day_group_assignations?: number, // number of stored group assignations for every respondents in the last 1 day (count)
    public rolling_day_survey_responses?: number, // daily survey responses, whatever the completion
    public rolling_day_survey_responses_not_started?: number, // daily survey responses, when completion is not_started
    public rolling_day_survey_responses_partially_completed?: number, // daily survey responses, when completion is partially_completed
    public rolling_day_survey_responses_fully_completed?: number, // daily survey responses, when completion is fully_completed
    public rolling_day_survey_responses_started?: number, // daily survey responses, when completion is partially_completed or fully_completed
    public rolling_day_message_responses?: number, // daily message responses, whatever the completion
    public rolling_day_message_responses_not_started?: number, // daily message responses, when completion is not_started
    public rolling_day_message_responses_partially_completed?: number, // daily message responses, when completion is partially_completed
    public rolling_day_message_responses_fully_completed?: number, // daily message responses, when completion is fully_completed
    public rolling_day_message_responses_started?: number, // daily message responses, when completion is partially_completed or fully_completed
    // Stats since 1st day of the month
    public current_month_respondents?: number, // number of respondents since 1st day of the month (count)
    public current_month_events_track?: number, // number of stored track events since 1st day of the month (count)
    public current_month_events_screen?: number, // number of stored screen events since 1st day of the month (count)
    public current_month_identity_properties?: number, // number of stored identity properties updates for every respondents since 1st day of the month (count)
    public current_month_group_assignations?: number, // number of stored group assignations for every respondents since 1st day of the month (count)
    public current_month_survey_question_video_uploaded?: number, // number of uploaded survey question video since 1st day of the month (count)
    public current_month_survey_question_video_size_uploaded?: number, // number of uploaded survey question video size since 1st day of the month (in bytes)
    public current_month_response_answer_media_uploaded?: number, // number of uploaded response answer media since 1st day of the month (count)
    public current_month_response_answer_media_size_uploaded?: number, // number of uploaded response answer media size since 1st day of the month (in bytes)
    public current_month_survey_responses?: number, // Monthly survey responses, whatever the completion
    public current_month_survey_responses_not_started?: number, // Monthly survey responses, when completion is not_started
    public current_month_survey_responses_partially_completed?: number, // Monthly survey responses, when completion is partially_completed
    public current_month_survey_responses_fully_completed?: number, // Monthly survey responses, when completion is fully_completed
    public current_month_survey_responses_started?: number, // Monthly survey responses, when completion is partially_completed or fully_completed
    public current_month_message_responses?: number, // Monthly message responses, whatever the completion
    public current_month_message_responses_not_started?: number, // Monthly message responses, when completion is not_started
    public current_month_message_responses_partially_completed?: number, // Monthly message responses, when completion is partially_completed
    public current_month_message_responses_fully_completed?: number, // Monthly message responses, when completion is fully_completed
    public current_month_message_responses_started?: number, // Monthly message responses, when completion is partially_completed or fully_completed

    public total_nps_score?: number, // Overall NPS score (count)
    public total_average_nps_promoters?: number, // Overall average of NPS scores between 9 and 10 (percent)
    public total_average_nps_passives?: number, // Overall average of NPS scores between 7 and 8 (percent)
    public total_average_nps_detractors?: number, // Overall average of NPS scores between 0 and 6 (percent)
    public total_nps_score_repartition?: Record<number, number>, // Overall NPS score repartition per score (count)

    public total_ces_score?: number, // Overall CES score (count)
    public total_average_ces_high_effort?: number, // Overall average of CES scores between 7 and 10 (percent)
    public total_average_ces_medium_effort?: number, // Overall average of CES scores between 4 and 6 (percent)
    public total_average_ces_low_effort?: number, // Overall average of CES scores between 0 and 3 (percent)
    public total_ces_score_repartition?: Record<number, number>, // Overall CES score repartition per score (count)

    public total_csat_score?: number, // Overall CSAT score (count)
    public total_average_csat_low_score?: number, // Overall average of CSAT scores between 0 and 2 (percent)
    public total_csat_score_repartition?: Record<number, number>, // Overall CSAT score repartition per score (count)

    public total_response_not_started?: number, // Overall response not started (count)
    public total_response_partially_completed?: number, // Overall response partially completed (count)
    public total_response_fully_completed?: number, // Overall response fully completed (count)

    public total_survey_response_started_rate?: number, // Overall response started rate (partially + fully completed) (percent)
    public total_survey_response_not_started_rate?: number, // Overall response not started rate (percent)
    public total_survey_response_partially_completed_rate?: number, // Overall response partially completed rate (percent)
    public total_survey_response_fully_completed_rate?: number, // Overall response fully completed rate (percent)
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

class OrgAnswerTags extends Serializable {
  constructor(
    public tags?: string[],
    public keywords?: string[],
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

export {
  Org,
  OrgAnswerTags,
  OrgDataGovernance,
  OrgEntitlements,
  OrgFlags,
  OrgStats,
};
