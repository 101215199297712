import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";
import { AccountDao } from "models/account.dao";
import {
  AccountFlagsOnboardingStatus,
  AccountGoalsToLabel,
  AccountJobTitlesToLabel,
} from "models/account.model";
import { ScreebApiHelper, SessionService } from "services/auth.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { SettingsService } from "services/settings.service";
import { TrackersService } from "services/trackers.service";
import { goalKeys, goals } from "./onboarding-goal.data";

@Component({
  selector: "page-onboarding-goal",
  templateUrl: "./onboarding-goal.component.html",
  styleUrls: [
    "./onboarding-goal.component.scss",
    "../layout/onboarding-layout.component.scss",
  ],
})
export class OnBoardingGoalPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Welcome to Screeb!";
  public name = "Onboarding goal";

  private obs: any = null;
  public org: Org = null;

  public accountJobTitles = AccountJobTitlesToLabel;

  public goalSubmitted = false;
  public selectedGoal: string = "collect-product-feedback";
  public goals = goals;

  public goalKeys = goalKeys;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private entitlementService: EntitlementService,
    public sessionService: SessionService,
    public settingsService: SettingsService,
    public featureFlaggingService: FeatureFlaggingService,
    public screebApiHelper: ScreebApiHelper,
    public trackersService: TrackersService,
    public orgDao: OrgDao,
    public accountDao: AccountDao,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );
    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public onGoalSelected(goalKey: string) {
    this.selectedGoal = goalKey;
  }

  public onContinue() {
    this.goalSubmitted = true;

    // Attente volontaire pour simuler une tache de fond pour l'utilisateur
    // l'interface affiche un chargement au moment de démarrer le timeout.
    setTimeout(() => {
      this.accountDao
        .setFlags({ goal: this.selectedGoal })
        .then(() => {
          this.trackersService
            .newEventTrackingBuilder(
              "Screeb Onboarding Goal Completion Waiting",
            )
            .withOrg(this.org)
            .withAccount(this.sessionService.session)
            .build();

          this.trackersService.setVisitorProperties({
            goal: this.selectedGoal,
            goal_label: AccountGoalsToLabel[this.selectedGoal],
          });
          this.onNext();
        })
        .catch(() => {});
    }, 6000); // 6 seconds
  }

  onNext() {
    const hasScenarioToLoad = Boolean(
      localStorage.getItem("screeb-restore-scenario"),
    );
    const skipOnboarding =
      !this.sessionService.session.flags?.onboarding_status.includes("goal");

    const isInvited = this.sessionService.isOnboardingInvited();

    const nextStep = "setup";
    const nextOnboardingStatus = `${nextStep}${
      isInvited ? "-invited" : ""
    }` as AccountFlagsOnboardingStatus;

    if (skipOnboarding) {
      this.accountDao.updateOnboardingStatus("done").then(() => {
        if (hasScenarioToLoad) {
          this.router.navigate(["org", this.org.id, "survey", "create"], {
            queryParams: {
              fromScenario: true,
            },
          });
        } else {
          this.router.navigate(["org", this.org.id]);
        }
      });
    } else {
      this.accountDao.updateOnboardingStatus(nextOnboardingStatus).then(() => {
        this.trackersService
          .newEventTrackingBuilder("Screeb Onboarding Goal Done")
          .withOrg(this.org)
          .withAccount(this.sessionService.session)
          .build();

        if (hasScenarioToLoad) {
          this.router.navigate(["org", this.org.id, "survey", "create"], {
            queryParams: {
              fromScenario: true,
            },
          });
        } else {
          this.router.navigate(["org", this.org.id, "onboarding", nextStep]);
        }
      });
    }
  }
}
