import { Component, Input, OnInit } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";
import {
  getNumericValueWithLimits,
  onOrRuleChange,
  onOrRuleChangeEnsureEmptyEndingField,
} from "components/surveys/pages/share/targeting/advanced/components/utils/rule-change";
import { RegistryEntry } from "models/registry.model";

@Component({
  selector: "rule-user-property",
  templateUrl: "./rule-user-property.component.html",
  styleUrls: ["./rule-user-property.component.scss"],
})
export class TargetingRuleUserPropertyComponent implements OnInit {
  @Input() public rule: SurveyTargetingRule = null;
  @Input() public registryEntriesIdentityProperty: RegistryEntry[] = [];
  @Input() public registryEntriesIdentityPropertyById: Map<
    string,
    RegistryEntry
  > = new Map();

  public getNumericValueWithLimits = getNumericValueWithLimits;
  public onOrRuleChange = onOrRuleChange;
  public onOrRuleChangeEnsureEmptyEndingField =
    onOrRuleChangeEnsureEmptyEndingField;

  constructor() {}

  ngOnInit() {
    if (this.rule.value.v_s_arr) {
      onOrRuleChangeEnsureEmptyEndingField(this.rule, false);
    } else if (this.rule.value.v_n_arr) {
      onOrRuleChangeEnsureEmptyEndingField(this.rule, true);
    } else {
      // in case there "OR" condition is not available (ex: wrong operator)
    }
  }

  public trackByFn(index: any) {
    return index;
  }

  public checkValidValueTypeNumberMultiple(
    r: SurveyTargetingRule,
    index: number,
  ): boolean {
    if (
      index === 0 &&
      (r.value.v_n_arr[index] === null || r.value.v_n_arr[index] === undefined)
    )
      return false;
    return true;
  }

  public checkValidValueTypeNumberSingle(r: SurveyTargetingRule): boolean {
    if (r.value.v_n === null || r.value.v_n === undefined) return false;
    return true;
  }

  public checkValidValueTypeString(
    r: SurveyTargetingRule,
    index: number,
  ): boolean {
    if (index === 0 && r.value.v_s_arr[index].trim().length === 0) return false;
    return true;
  }

  canSplitPastedText(pastedText: string) {
    return (
      pastedText &&
      (pastedText.includes(";") ||
        pastedText.includes(",") ||
        pastedText.includes("\n")) &&
      ["equal", "not equal", "contains", "not contains"].includes(
        this.rule.operator,
      )
    );
  }

  onPasteString($event: ClipboardEvent) {
    const pastedText = $event.clipboardData.getData("text");
    if (this.canSplitPastedText(pastedText)) {
      if (
        confirm(
          "We detected tabular data in your pasted text, would you like to split that data into OR/NEITHER condition?",
        )
      ) {
        const values = (this.rule.value.v_s_arr = pastedText
          .split(/\,|\;|\n/gi)
          .map((a) => a.trim())
          .filter(Boolean)
          .slice(0, 100));

        this.rule.value.v_s_arr = values.length ? values : [undefined];

        $event.stopPropagation();
        $event.preventDefault();
        return false;
      }
    }
  }

  onPasteNumber($event: ClipboardEvent) {
    const pastedText = $event.clipboardData.getData("text");
    if (this.canSplitPastedText(pastedText)) {
      if (
        confirm(
          "We detected tabular data in your pasted text, would you like to split that data into OR/NEITHER condition?",
        )
      ) {
        const values = pastedText
          .split(/\,|\;|\n/gi)
          .map((a) => a.trim())
          .filter(Boolean)
          .map(Number)
          .filter((a) => !Number.isNaN(a))
          .slice(0, 100);

        this.rule.value.v_n_arr = values.length ? values : [undefined];

        $event.stopPropagation();
        $event.preventDefault();
        return false;
      }
    }
  }
}
