/* eslint-disable @angular-eslint/no-output-on-prefix */

import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NzTableQueryParams, NzTableSortOrder } from "ng-zorro-antd/table";

import { ActivatedRoute, Router } from "@angular/router";
import { NotificationHelper } from "helpers/notification.helper";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import {
  AnalyticsResponse,
  AnalyticsResponseItemResponse,
  AnalyticsResponseItemResponseHiddenField,
  AnalyticsResponseItemResponseSubFieldType,
  ResponseAnswerValueFileType,
} from "models/analytics.model";
import {
  IntegrationAtlassianProject,
  IntegrationDao,
} from "models/integration.dao";
import { Integration, integrationSpecs } from "models/integrations.model";
import { Org } from "models/org.model";
import { ResponseDao } from "models/response.dao";
import { UUID, getScenarioNodeByCorrelationId } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { getUserIcon } from "models/user.model";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";
import { ClipboardService } from "ngx-clipboard";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { SessionService } from "services/auth.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { deepCopy } from "utils/object";

@Component({
  selector: "survey-stats-individual-responses-answers-table-single-question",
  templateUrl: "./answers-table-single-question.component.html",
  styleUrls: ["./answers-table-single-question.component.scss"],
})
export class AnswersTableSingleQuestionComponent implements OnInit, OnDestroy {
  private filtersObs$: any = null;
  private lastFilters: AnalyticsQueryResponse;

  public total: number;
  public pageSize = 10;
  public pageIndex = 1;
  public responseOrder: NzTableSortOrder = "descend";
  public responses: AnalyticsResponseItemResponse[];

  public error: Error;
  public loading = true;
  public initialFetch = true;
  public deletingByResponseId = {};

  public expandedResponsesById: object = {};
  public expandedOriginalResponsesById: object = {};

  public sendToIntegrations: Integration[];
  public sendToIntegrationsAtlassian: Integration | null;
  public integrationSpecs = integrationSpecs;

  public atlassianProjectContext: {
    integration: Integration;
    nodeCorrelationId: string;
    response: AnalyticsResponseItemResponse;
  } = null;
  public atlassianSendToOpened: boolean = false;
  public integrationAtlassianProjectId: string;
  public integrationsAtlassianProjects: IntegrationAtlassianProject[] = [];

  public videoPreviewOpened = false;
  public currentVideoURL: string = null;

  public audioPreviewOpened = false;
  public currentAudioURL: string = null;

  public responseLikes: Map<string, boolean> = new Map();

  private skipNextParamChange = true;

  public today = new Date();

  public isAddingTag: object = {};

  public selectedAddedTag: object = {};

  public allOrgTags: NzSelectOptionInterface[] = [];

  @Input()
  public org: Org;
  @Input()
  public survey: Survey;
  /* eslint-disable-next-line @angular-eslint/no-input-rename */
  @Input("responses")
  public prefetchedResponses: AnalyticsResponseItemResponse[];
  @Input()
  public nodeCorrelationId?: string;
  @Input()
  public integrations: Integration[];
  @Input()
  public noPagination = false;
  @Input()
  public getQuery: (
    pageSize: number,
    pageIndex: number,
    responseOrder: NzTableSortOrder,
    nodeCorrelationId: string,
  ) => AnalyticsQueryResponse;
  @Input()
  public getAnswers = (response: AnalyticsResponseItemResponse) =>
    response.answers;
  @Input()
  public removable = false;

  @Output()
  onDelete = new EventEmitter();

  public getUserIcon = getUserIcon;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private notificationHelper: NotificationHelper,
    private analyticsFilterService: AnalyticsFilterService,
    private trackersService: TrackersService,
    private responseDao: ResponseDao,
    private analyticsDao: AnalyticsDao,
    private integrationDao: IntegrationDao,
    private entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    private clipboardService: ClipboardService,
    private sessionService: SessionService,
    private uiService: UIService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.integrationsAtlassianProjects = data.integrationAtlassianProjects;
    });

    if (!this.prefetchedResponses) {
      // initial fetch is done automatically with onQueryParamsChange
      this.filtersObs$ = this.analyticsFilterService
        .subscribe()
        .subscribe((filters: AnalyticsQueryResponse) => {
          this.lastFilters = deepCopy(filters);
          this.getResponses();
        });

      this.sendToIntegrations = this.integrations.filter(
        (i: Integration) =>
          integrationSpecs[i.type].features.forwarding &&
          this.entitlementService.isIntegrationAvailable(i.type),
      );
      this.sendToIntegrationsAtlassian = this.integrations.find(
        (i: Integration) => i.type === "atlassian",
      );
    } else {
      this.getResponses();
    }
    this.getAllOrgTags();
  }

  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  public getAnswersText(
    item: AnalyticsResponseItemResponse,
    nodeCorrelationId: string,
    translated: boolean = false,
  ) {
    const node = getScenarioNodeByCorrelationId(
      this.survey.scenario,
      nodeCorrelationId,
    );
    const answers = this.nodeCorrelationId
      ? item.getAnswers(this.nodeCorrelationId)
      : this.getAnswers(item);

    const translation = translated ? this.getTranslationLanguage() : null;

    if (!node) {
      return answers?.map((a) => a?.toText(translation)).join(", ");
    }

    switch (node?.question?.call_to_action.type) {
      case "scoring":
      case "ces":
      case "csat":
      case "nps":
        return node.question?.call_to_action.scores.find((score) =>
          answers.find((a) => score.payload.value === Number(a.toText())),
        )?.payload.emoji;
      case "range":
        return answers.map((a) => `${a?.toText()}%`).join(", ");
      case "multiple_choice":
      case "pmf":
      case "input":
      case "link":
      case "appstore_rating":
        return answers?.map((a) => a?.toText(translation)).join(", ");
    }
  }

  public getAnswersNodeCorrelationId(
    item: AnalyticsResponseItemResponse,
    nodeCorrelationId?: string,
  ) {
    return nodeCorrelationId ?? this.getAnswers(item)[0]?.key;
  }

  private getResponses() {
    if (this.prefetchedResponses) {
      this.total = this.prefetchedResponses.length;
      this.responses = this.prefetchedResponses;
      this.initialFetch = false;
      this.loading = false;
      return;
    }

    this.loading = true;
    this.error = null;

    this.analyticsDao
      .search(
        this.getQuery(
          this.pageSize,
          this.pageIndex,
          this.responseOrder,
          this.nodeCorrelationId,
        ),
      )
      .then((res: AnalyticsResponse) => {
        this.total = res?.hits?.total;
        this.responses = res?.hits?.responses;
      })
      .catch((err: HttpErrorResponse) => {
        this.error = err;
        console.error(err);
      })
      .finally(() => {
        this.initialFetch = false;
        this.loading = false;
      });
  }

  public onQueryParamsChange(params: NzTableQueryParams) {
    const pageSize = params.pageSize;
    let pageIndex = params.pageIndex;
    if (pageSize !== this.pageSize) {
      pageIndex = 1;
    }
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;

    if (this.skipNextParamChange) {
      this.skipNextParamChange = false;
    } else {
      this.getResponses();
    }
  }
  public onHiddenFieldCopy(event: Event, text: string) {
    const target = (event.target as HTMLElement).parentElement;
    target.classList.add("copy");
    setTimeout(() => {
      target.classList.remove("copy");
    }, 200);

    this.clipboardService.copy(text);
    event.preventDefault();
    event.stopPropagation();
  }

  public onHiddenFieldClick(hfKey: string, hfValue: string) {
    this.analyticsFilterService.switchFilter({
      type: "response.hidden_field",
      key: hfKey,
      operator: "eq",
      value: hfValue,
    });

    const props = {
      filter_type: "response.hidden_field",
      filter_key: hfKey,
      filter_operator: "eq",
    };

    this.trackersService
      .newEventTrackingBuilder("Switched filter")
      .withOrg(this.org)
      .withSurvey(this.survey)
      .withProps(props)
      .build();
  }

  public onUserIdentifierClick(visitorUid: string) {
    this.analyticsFilterService.switchFilter({
      type: "response",
      key: "respondent_aliases",
      operator: "eq",
      value: visitorUid,
    });

    const props = {
      filter_type: "response",
      filter_key: "respondent_aliases",
      filter_operator: "eq",
    };

    this.trackersService
      .newEventTrackingBuilder("Switched filter")
      .withOrg(this.org)
      .withSurvey(this.survey)
      .withProps(props)
      .build();
  }

  public getUserInfoFromResponseHiddenFields(
    response: AnalyticsResponseItemResponse,
  ): AnalyticsResponseItemResponseHiddenField[] {
    return response.hidden_fields.filter(
      (hf: AnalyticsResponseItemResponseHiddenField): boolean => {
        return ["locale", "timezone"].includes(hf.key);
      },
    );
  }
  public getNavigationInfoFromResponseHiddenFields(
    response: AnalyticsResponseItemResponse,
  ): AnalyticsResponseItemResponseHiddenField[] {
    return response.hidden_fields.filter(
      (hf: AnalyticsResponseItemResponseHiddenField): boolean => {
        return [
          "support",
          "user_agent",
          "os",
          "platform",
          "api_version",
          "sdk_version",
        ].includes(hf.key);
      },
    );
  }
  public getOtherInfoFromResponseHiddenFields(
    response: AnalyticsResponseItemResponse,
  ): AnalyticsResponseItemResponseHiddenField[] {
    return response.hidden_fields.filter(
      (hf: AnalyticsResponseItemResponseHiddenField): boolean => {
        return ![
          "locale",
          "timezone",
          "support",
          "user_agent",
          "api_version",
          "sdk_version",
        ].includes(hf.key);
      },
    );
  }

  private sendTo(
    integration: Integration,
    nodeCorrelationId: string,
    response: AnalyticsResponseItemResponse,
  ) {
    const body = {
      response_id: response.id,
      survey_id: response.survey_id,
      question_correlation_id: nodeCorrelationId,
    };

    if (integration.type === "atlassian") {
      body["atlassian_project_id"] = this.integrationAtlassianProjectId;
    }

    this.integrationDao
      .forwardResponse(this.org.id, body, integration.type)
      .then(() =>
        this.notificationHelper.trigger("Feedback Sent", null, "success"),
      )
      .catch(() =>
        this.notificationHelper.trigger(
          "Could not send Feedback",
          null,
          "error",
        ),
      );

    this.trackersService
      .newEventTrackingBuilder("Forward response")
      .withOrg(this.org)
      .withSurvey(this.survey)
      .withIntegration(integration)
      .build();
  }

  public onSendTo(
    integration: Integration,
    nodeCorrelationId: string,
    response: AnalyticsResponseItemResponse,
  ) {
    if (integration.type === "atlassian") {
      this.atlassianSendToOpened = true;
      return;
    }

    this.sendTo(integration, nodeCorrelationId, response);
  }

  public isMediaResponseAnswer(
    response: AnalyticsResponseItemResponse,
  ): boolean {
    const answers =
      response.getAnswers(this.nodeCorrelationId) ?? this.getAnswers(response);
    return answers.some(
      (answer) =>
        answer.type === AnalyticsResponseItemResponseSubFieldType.File,
    );
  }

  public onAtlassianProjectChange(projectId: string) {
    this.integrationAtlassianProjectId = projectId;
  }

  public onAtlassianSendTo() {
    this.sendTo(
      this.atlassianProjectContext.integration,
      this.atlassianProjectContext.nodeCorrelationId,
      this.atlassianProjectContext.response,
    );

    this.atlassianSendToOpened = false;
  }

  public onAtlassianProjectOpen(
    integration: Integration,
    nodeCorrelationId: string,
    response: AnalyticsResponseItemResponse,
  ) {
    this.atlassianSendToOpened = true;

    this.atlassianProjectContext = {
      integration,
      nodeCorrelationId,
      response,
    };
  }

  public onAtlassianProjectClose() {
    this.atlassianSendToOpened = false;

    this.atlassianProjectContext = null;
  }

  // return a language only if org and survey translation aree enabled
  // return user language on priority when available
  private getTranslationLanguage() {
    if (!this.uiService.currentOrg?.translation_enabled) return null;
    if (!this.survey.translation_enabled) return null;

    if (this.sessionService.session.flags.translation_language) {
      return this.sessionService.session.flags.translation_language;
    }

    return this.uiService.currentOrg.translation_language;
  }

  public getTranslationTag(response: AnalyticsResponseItemResponse) {
    const translationAvailable = this.isTranslationAvailable(response);

    if (!translationAvailable) {
      return "Missing Translation";
    } else {
      return "Automatic Translation";
    }
  }

  public isTranslationEnabled(
    response: AnalyticsResponseItemResponse,
  ): boolean {
    const answers =
      response.getAnswers(this.nodeCorrelationId) ?? this.getAnswers(response);
    const answer = answers.some((answer) => answer.cta_type === "input");

    return answer && this.getTranslationLanguage() !== null;
  }

  // return true if the response language is the same as the translation language
  public isTranslationOriginal(
    response: AnalyticsResponseItemResponse,
  ): boolean {
    const translation = this.getTranslationLanguage();

    if (!translation) return true;

    const answers =
      response.getAnswers(this.nodeCorrelationId) ?? this.getAnswers(response);

    if (!answers.length) return true;

    return answers.some((answer) => answer.lang === translation);
  }

  // check if translation is enabled and if the response has a translation
  public isTranslationAvailable(
    response: AnalyticsResponseItemResponse,
  ): boolean {
    const translation = this.getTranslationLanguage();

    if (!translation) return false;

    const answers =
      response.getAnswers(this.nodeCorrelationId) ?? this.getAnswers(response);
    return answers.some(
      (answer) =>
        answer.cta_type === "input" &&
        answer.translations.some((tr) => tr.lang === translation),
    );
  }

  public isMediaTranscriptReady(
    response: AnalyticsResponseItemResponse,
  ): boolean {
    const answers =
      response.getAnswers(this.nodeCorrelationId) ?? this.getAnswers(response);
    const answer = answers.find(
      (answer) =>
        answer.type === AnalyticsResponseItemResponseSubFieldType.File,
    );

    return !!answer?.v_s;
  }

  public getMediaResponseAnswerFileType(
    response: AnalyticsResponseItemResponse,
  ): string {
    const answers =
      response.getAnswers(this.nodeCorrelationId) ?? this.getAnswers(response);
    const answer = answers.find(
      (answer) =>
        answer.type === AnalyticsResponseItemResponseSubFieldType.File,
    );

    switch (answer?.v_f.type) {
      case "video":
        return "🎥 Watch Original Feedback";
      case "audio":
        return "🎙 Listen to Original Feedback";
      default:
        return "🎥 Show Original Feedback";
    }
  }

  private assignMediaUrl(url: string, type: ResponseAnswerValueFileType) {
    if (type === "video") {
      this.currentVideoURL = url;
      this.videoPreviewOpened = true;
    } else if (type === "audio") {
      this.currentAudioURL = url;
      this.audioPreviewOpened = true;
    }
  }

  public onVideoModalOpenChange(open: boolean) {
    this.videoPreviewOpened = open;
    if (!open) {
      this.currentVideoURL = null;
    }
  }

  public onAudioModalOpenChange(open: boolean) {
    this.audioPreviewOpened = open;
    if (!open) {
      this.currentAudioURL = null;
    }
  }

  public onOpenMediaPreview(response: AnalyticsResponseItemResponse) {
    const answers =
      response.getAnswers(this.nodeCorrelationId) ?? this.getAnswers(response);

    const answer = answers.find(
      (answer) =>
        answer.type === AnalyticsResponseItemResponseSubFieldType.File,
    );

    if (!answer) {
      return;
    }

    this.responseDao
      .getMediaUrl(
        this.org.id,
        response.respondent_id,
        response.id,
        answer.v_f.id,
      )
      .then((res: { url: string }) => {
        this.assignMediaUrl(res.url, answer.v_f.type);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.trigger(
          "Could not get video preview",
          err.message,
          "error",
        );
      });
  }

  public onVideoResponseVote(
    response: AnalyticsResponseItemResponse,
    liked: boolean,
  ) {
    const vote = liked
      ? "Survey response video transcript liked"
      : "Survey response video transcript disliked";
    const answer =
      response.getAnswers(this.nodeCorrelationId) ?? this.getAnswers(response);
    this.trackersService
      .newEventTrackingBuilder(vote)
      .withOrg(this.org)
      .withSurvey(this.survey)
      .withAnswer(answer[0])
      .build();

    this.responseLikes.set(response.id, liked);
  }

  public deleteResponse(userId: string, responseId: string) {
    this.deletingByResponseId[responseId] = true;

    this.responseDao
      .delete(this.org.id, userId, responseId)
      .then(() => {
        this.notificationHelper.trigger("Response removed", null, "success");
        this.onDelete.emit();

        // remove from current view
        const index = this.responses.findIndex((r) => r.id === responseId);
        if (index >= 0) {
          this.responses.splice(index, 1);
          this.responses = [].concat(...this.responses);
        }
      })
      .catch(() =>
        this.notificationHelper.trigger(
          "Could not remove response",
          null,
          "error",
        ),
      )
      .finally(() => {
        this.deletingByResponseId[responseId] = false;
      });
  }

  public getAnswerTags(item: AnalyticsResponseItemResponse) {
    const answers = this.nodeCorrelationId
      ? item.getAnswers(this.nodeCorrelationId)
      : this.getAnswers(item);

    return answers[0]?.tags ?? [];
  }

  public getAllOrgTags() {
    this.analyticsDao
      .search({
        org_id: this.org.id as UUID,
        survey_ids: ["*"],
        filters_bool: "AND",
        type: "response",
        filters: [],
        size: 0,
        offset: 0,
        range: {
          field: "created_at",
          start: this.org.created_at,
          end: new Date(),
        },
        aggregation: [{ by: "by_tags" }],
      })
      .then((response) => {
        const tags = response.aggregations.answer.tags.buckets.map(
          (tag) => tag.key,
        );

        // remove duplicate
        this.allOrgTags = tags
          .filter((item, index) => tags.indexOf(item) === index)
          .map((tag) => ({ label: tag, value: tag }));
      });
  }

  public handleDeleteTag(
    userID: string,
    responseID: string,
    answerID: string,
    tag: string,
  ) {
    this.responseDao
      .removeTagsFromAnswer(this.org.id, userID, responseID, answerID, tag)
      .then(() => {
        this.notificationHelper.trigger("Tag removed", null, "success");

        this.allOrgTags.splice(
          this.allOrgTags.indexOf({ label: tag, value: tag }),
          1,
        );

        const answer = this.getAnswerFromIDs(responseID, answerID);

        answer.tags.splice(answer.tags.indexOf(tag), 1);
      })
      .catch(() => {
        this.notificationHelper.trigger("Could not remove tag", null, "error");
      });
  }

  public onAddTag(responseId: string) {
    this.isAddingTag[responseId] = true;
  }

  public onConfirmTag(userId: string, responseId: string, answerId: string) {
    this.isAddingTag[responseId] = false;

    Promise.all(
      this.responseDao.addTagsToAnswer(
        this.org.id,
        userId,
        responseId,
        answerId,
        this.selectedAddedTag[responseId],
      ),
    )
      .then(() => {
        this.notificationHelper.trigger("Tag added", null, "success");
        const answer = this.getAnswerFromIDs(responseId, answerId);
        answer.tags.push(...this.selectedAddedTag[responseId]);

        this.selectedAddedTag = [];
      })
      .catch(() => {
        this.notificationHelper.trigger("Could not add tag", null, "error");
      });
  }

  public getAnswer(item: AnalyticsResponseItemResponse) {
    return (
      this.nodeCorrelationId
        ? item.getAnswers(this.nodeCorrelationId)
        : this.getAnswers(item)
    )?.[0];
  }

  public getAnswerFromIDs(responseID: string, answerID: string) {
    const response = this.responses.filter(
      (response) => response.id === responseID,
    )[0];

    return response.answers.filter(
      (answer) => answer.answer_id === answerID,
    )[0];
  }
}
