<ul class="stats-list iAppear" *ngIf="userEvents.length">
  <li
    *ngFor="let event of userEvents"
    class="stat-item"
    [ngClass]="{
      opened: opened[event.id],
      clickable: shouldDisplayProperties(event.raw_properties)
    }"
  >
    <div
      class="stat-item-header"
      (click)="opened[event.id] = !opened[event.id]; onEventClicked()"
    >
      <square-icon icon="cursor-click"></square-icon>
      <div class="stat-item-data">
        <div class="stat-item-title">
          <span>{{ event.name }}</span>
          <tag-registry-entry-source
            [sources]="event.sources"
            size="small"
          ></tag-registry-entry-source>
        </div>
        <div
          class="stat-item-value"
          *ngIf="event.triggered_at"
          [title]="event.triggered_at | toLocaleString"
        >
          {{
            event.triggered_at | dfnsFormatDistanceToNow: { addSuffix: true }
          }}
        </div>
      </div>
      <div class="stat-item-title-count" *ngIf="event.count">
        {{ formatNumber(event.count, "number") }}
      </div>
    </div>
    <div
      class="stat-item-content"
      *ngIf="shouldDisplayProperties(event.raw_properties)"
    >
      <ngx-json-viewer [json]="event.raw_properties" [depth]="0">
      </ngx-json-viewer>
    </div>
  </li>
</ul>
<nz-empty *ngIf="!userEvents.length" [nzNotFoundContent]="contentTpl">
  <ng-template #contentTpl>
    <div>No events</div>
    <br />
    <a
      target="_blank"
      href="https://help.screeb.app/en/articles/5227983-how-to-track-user-events-in-screeb-to-display-your-surveys"
    >
      <i nz-icon nzType="info-circle"></i>
      <span> How to add user events in Screeb</span>
    </a>
  </ng-template>
</nz-empty>
