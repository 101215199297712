import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import { UserDao } from "models/user.dao";
import { User } from "models/user.model";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";
import { EntitlementService } from "services/entitlement.service";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "user-add-to-segment",
  templateUrl: "./add-to-segment.component.html",
  styleUrls: ["./add-to-segment.component.scss"],
})
export class UserAddToSegmentComponent implements OnInit {
  public isModalVisible = false;
  public isUpgradeModalVisible = false;
  public loading = false;
  public error: string = null;

  public userGroups: NzSelectOptionInterface[] = [];
  public selectedUserGroups: string[] = [];

  @Input() public user: User;
  @Input() public orgUserGroups: RegistryEntry[];
  @Input() public userAssignedGroups: RegistryEntry[];
  @Input() public org: Org;
  @Input() public isSmall: boolean = false;

  @Output() public addedToSegments = new EventEmitter<string[]>();

  @ViewChild("segmentNameElement") segmentNameElement: ElementRef;

  public getFormattedUserGroupNameOrID = getFormattedUserGroupNameOrID;

  constructor(
    private userDao: UserDao,
    private notificationHelper: NotificationHelper,
    private trackersService: TrackersService,
    private entitlementService: EntitlementService,
  ) {}

  initModal() {
    this.error = null;
    this.loading = false;
    this.userGroups = this.orgUserGroups
      .filter(
        ({ id }) =>
          !this.userAssignedGroups.some(
            (userAssignedGroup) => userAssignedGroup.id === id,
          ),
      )
      .map((userGroup) => ({
        value: userGroup.id,
        label: getFormattedUserGroupNameOrID(userGroup),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    this.selectedUserGroups = [];
  }

  ngOnInit() {
    this.initModal();
  }

  isValid() {
    return Boolean(this.selectedUserGroups.length);
  }

  showModal(): void {
    this.initModal();
    this.isModalVisible = true;
    setTimeout(() => {
      (this.segmentNameElement as any)?.focus();
    }, 500);
  }

  onModelChange($event: string[]) {
    this.selectedUserGroups = $event;
  }

  handleOk(): void {
    this.loading = true;

    const existingSegmentNames = this.selectedUserGroups
      .map(
        (segmentId) =>
          this.orgUserGroups.find(({ id }) => id === segmentId)?.title,
      )
      .filter(Boolean);

    const existingSegmentIds = this.selectedUserGroups.filter(
      (segmentId) => !!this.orgUserGroups.find(({ id }) => id === segmentId),
    );

    const newSegmentNames = this.selectedUserGroups.filter((name) => {
      return !this.orgUserGroups.find(({ id }) => id === name);
    });

    const selectedSegmentsNames = []
      .concat(...existingSegmentNames)
      .concat(...newSegmentNames);

    // @TODO: BUG !!! we must send group type id -> by default we will recreate groups with the "default" type
    this.userDao
      .addUserToGroups(
        this.org.id,
        this.user.id,
        null,
        null,
        selectedSegmentsNames,
      )
      .then(() => {
        // @TODO: here we don't emit ids of new segments
        this.addedToSegments.emit(existingSegmentIds);
        this.closeModal();
        this.notificationHelper.trigger(
          `Successfully added user to segment(s) '${selectedSegmentsNames.join(
            ", ",
          )}'!`,
          null,
          "success",
        );

        this.trackersService
          .newEventTrackingBuilder("Respondent added to segment")
          .withOrg(this.org)
          .withUser(this.user)
          .withProps({ segments: selectedSegmentsNames })
          .build();
      })
      .catch(() => (this.error = "Something went wrong. Please try again."))
      .finally(() => (this.loading = false));
  }

  closeModal() {
    this.isModalVisible = false;
  }

  handleCancel(): void {
    this.closeModal();
  }
}
