import { Injectable, Type } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ActivationEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Params,
  Router,
} from "@angular/router";

import { BuilderPageComponent } from "components/builder/page-builder.component";
import { FunnelListPageComponent } from "components/funnels/pages/list/funnel-list.component";
import { IntegrationListPageComponent } from "components/integration/list/integration-list.component";
import { EmbeddedExternalPageComponent } from "components/layouts/back/embedded-external-page/embedded-external-page.component";
import { OnBoardingBookDemoPageComponent } from "components/onboarding/onboarding-book-demo/onboarding-book-demo.component";
import { OnBoardingDiscoveryComponent } from "components/onboarding/onboarding-discovery/onboarding-discovery.component";
import { OnBoardingGoalPageComponent } from "components/onboarding/onboarding-goal/onboarding-goal.component";
import { OnBoardingInstallationPageComponent } from "components/onboarding/onboarding-installation/onboarding-installation.component";
import { OnBoardingQualificationPageComponent } from "components/onboarding/onboarding-qualification/onboarding-qualification.component";
import { OnBoardingSetupPageComponent } from "components/onboarding/onboarding-setup/onboarding-setup.component";
import { OverviewOrgPageComponent } from "components/org/overview/overview.component";
import { QuickstartOrgPageComponent } from "components/org/quickstart/quickstart.component";
import { SegmentListPageComponent } from "components/segment/list/segment-list.component";
import { SettingsChannelInstallPageComponent } from "components/settings/channel-install/channel-install.component";
import { ClientScreensListPageComponent } from "components/settings/client-screens/client-screens-list.component";
import { SettingsDataGovernancePageComponent } from "components/settings/data-governance/data-governance.component";
import { SettingsLanguagePageComponent } from "components/settings/language/language.component";
import { RegistryEventsListPageComponent } from "components/settings/registry-events/registry-events-list.component";
import { RegistryIdentityPropertiesListPageComponent } from "components/settings/registry-identity-properties/registry-identity-properties-list.component";
import { SettingsSurveyCappingPageComponent } from "components/settings/survey-capping/survey-capping.component";
import { SettingsSurveyCustomizationPageComponent } from "components/settings/survey-customization/survey-customization.component";
import { SettingsTaggingPageComponent } from "components/settings/tagging/tagging.component";
import { SettingsTeamMembersPageComponent } from "components/settings/team-members/team-members.component";
import { SettingsBillingPageComponent } from "components/super-org/billing/billing.component";
import { OverviewSuperOrgPageComponent } from "components/super-org/overview/overview.component";
import { UsersSuperOrgPageComponent } from "components/super-org/users/users.component";
import { WorkspacesSuperOrgPageComponent } from "components/super-org/workspaces/workspaces.component";
import { CreateSurveyPageComponent } from "components/surveys/pages/create/create.component";
import { SurveyListPageComponent } from "components/surveys/pages/list/survey-list.component";
import { SurveySettingsPageComponent } from "components/surveys/pages/settings/settings.component";
import { DistributionSettingsPageComponent } from "components/surveys/pages/share/distribution-settings/distribution-settings.component";
import { LinkShareSurveyPageComponent } from "components/surveys/pages/share/link/link.component";
import { ShareSurveyPageComponent } from "components/surveys/pages/share/share-page/share.component";
import { ContentAnalysisStatsSurveyPageComponent } from "components/surveys/pages/stats/content-analysis/content-analysis.component";
import { FlowAnalysisStatsSurveyPageComponent } from "components/surveys/pages/stats/flow-analysis/flow-analysis.component";
import { CESIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/ces/ces.component";
import { CSATIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/csat/csat.component";
import { NPSIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/nps/nps.component";
import { PMFIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/pmf/pmf.component";
import { IndividualResponsesStatsSurveyPageComponent } from "components/surveys/pages/stats/individual-responses/individual-responses.component";
import { OverallPerformancesStatsSurveyPageComponent } from "components/surveys/pages/stats/overall-performances/overall-performances.component";
import { StatsSurveyPageComponent } from "components/surveys/pages/stats/stats.component";
import { SurveyPageComponent } from "components/surveys/pages/survey.component";
import { UserListPageComponent } from "components/user/list/user-list.component";
import { ENV } from "environment";
import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import { SurveyDao } from "models/survey.dao";
import { Survey, SurveyType } from "models/survey.model";
import { SessionService } from "services/auth.service";
import { SettingsService } from "services/settings.service";

export enum AppTheme {
  AUTO = "auto",
  LIGHT = "light",
  DARK = "dark",
}

@Injectable()
class UIService {
  // All user super orgs
  public superOrgs: SuperOrg[] = null;

  // All user workspaces not filtered by super org
  public orgs: Org[] = null;

  // All user org workspaces
  public orgWorkspaces: Org[] = null;

  // Most users will only have one super org
  public currentSuperOrg: SuperOrg = null;
  public currentSuperOrgId: string = null;

  public currentOrg: Org = null;
  public currentOrgId: string = null;

  public currentSurvey: Survey = null;
  public currentSurveyId: string = null;

  public theme: AppTheme = AppTheme.LIGHT;

  public surveyType: SurveyType = "survey";
  public surveyTypePath: string = "survey";

  public isLayoutEmbbeded = false;
  public isLayoutBuilder = false;
  public isSuperOrgPage = false;
  public isAuthPage = false;
  public isOverviewPage = false;
  public isSurveyPage = false;
  public isMessagePage = false;
  public isStatsPage = false;
  public isSharingPage = false;
  public isWorkspaceSettingsPage = false;
  public isIntegrationListPage = false;
  public isOnBoardingPage = false;
  public isFullSiderPage = false;
  public isBillingPage = false;
  public isPeoplePage = false;

  public currentPageRouteUrl: string = null;
  public currentPageRouteComponent: Type<any> | null = null;
  public currentPageRouteParams: Params | null = null;
  public currentPageRouteQueryParams: Params | null = null;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private orgDao: OrgDao,
    private superOrgDao: SuperOrgDao,
    private surveyDao: SurveyDao,
    private settingsService: SettingsService,
  ) {
    this.router.events.subscribe((e) => this.onRouterEvent(e));

    // Handle theme
    this.refreshTheme();
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", () => {
        this.refreshTheme();
      });
  }

  private onRouterEvent(event) {
    switch (true) {
      case event instanceof ActivatedRoute:
        break;
      case event instanceof ActivationEnd: {
        if (event.snapshot.params.org_id) {
          this.settingsService.setAdminSettingsKey(
            "last_organization_id",
            event.snapshot.params.org_id,
          );
        }

        if (event.snapshot.params.super_org_id) {
          this.settingsService.setAdminSettingsKey(
            "last_super_organization_id",
            event.snapshot.params.super_org_id,
          );
        }

        break;
      }
      case event instanceof NavigationStart:
        break;
      case event instanceof NavigationEnd:
        this.fetchEverything();
        break;
      case event instanceof NavigationCancel:
        this.fetchEverything();
        break;
      case event instanceof NavigationError:
        this.fetchEverything();
        break;
      default: {
        break;
      }
    }
  }

  private checkAuth(): boolean {
    if (!this.sessionService.isAuth()) {
      this.currentSuperOrg = null;
      this.currentSuperOrgId = null;
      this.orgWorkspaces = null;
      this.currentOrg = null;
      this.currentOrgId = null;
      this.currentSurvey = null;
      this.currentSurveyId = null;
      this.currentPageRouteUrl = null;
      this.currentPageRouteComponent = null;
      this.currentPageRouteParams = null;
      this.currentPageRouteQueryParams = null;
      this.isLayoutEmbbeded = false;
      this.isLayoutBuilder = false;
      this.isSuperOrgPage = false;
      this.isOverviewPage = false;
      this.isSurveyPage = false;
      this.isMessagePage = false;
      this.surveyType = "survey";
      this.surveyTypePath = "survey";
      this.isStatsPage = false;
      this.isSharingPage = false;
      this.isWorkspaceSettingsPage = false;
      this.isIntegrationListPage = false;
      this.isOnBoardingPage = false;
      return false;
    }
    return true;
  }

  public async fetchEverything() {
    // Refresh layout as soon as possible
    this.refreshLayout();

    if (this.checkAuth()) {
      await Promise.all([
        this.fetchSuperOrgs(),
        this.fetchOrgs(),
        this.fetchSurvey(),
      ]);

      this.refreshCurrentDatas();
    }
  }

  private async fetchSuperOrgs(): Promise<boolean> {
    try {
      const superOrgs = await this.superOrgDao.getAll();

      // order by name
      superOrgs.sort((a: SuperOrg, b: SuperOrg) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });

      this.superOrgs = superOrgs;
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  }

  private async fetchOrgs(): Promise<boolean> {
    try {
      const orgs = await this.orgDao.getAll();

      // order by name
      orgs.sort((a: Org, b: Org) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });

      this.orgs = orgs;
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  }
  private async fetchSurvey(): Promise<boolean> {
    try {
      const currentOrgId = this.getOrgIdFromURL();
      const currentSurveyId = this.getSurveyIdFromURL();
      if (!!currentOrgId && !!currentSurveyId) {
        this.currentSurvey = await this.surveyDao.getById(
          currentOrgId,
          currentSurveyId,
        );
      } else {
        this.currentSurvey = null;
      }
    } catch (err) {
      console.error(err);
      return false;
    }

    return true;
  }

  private refreshLayout() {
    this.refreshCurrentPageComponent();
    this.refreshIsLayoutPageEmbbeded();
    this.refreshIsLayoutPageBuilder();
    this.refreshIsAuthPage();
    this.refreshIsSuperOrgPage();
    this.refreshIsOverviewPage();
    this.refreshIsMessagePage();
    this.refreshIsSurveyPage();
    this.refreshIsStatsPage();
    this.refreshIsSharingPage();
    this.refreshIsSettingsPage();
    this.refreshIsIntegrationListPage();
    this.refreshIsOnBoardingPage();
    this.refreshIsBillingPage();
    this.refreshIsPeoplePage();
    this.refreshFullSiderPage();
  }

  private refreshCurrentDatas() {
    this.refreshCurrentOrg();
    if (this.currentOrgId) {
      this.settingsService.setAdminSettingsKey(
        "last_organization_id",
        this.currentOrgId,
      );
    }

    this.refreshCurrentSurvey();
    this.refreshCurrentSuperOrg();
    if (this.currentSuperOrgId) {
      this.settingsService.setAdminSettingsKey(
        "last_super_organization_id",
        this.currentSuperOrgId,
      );
    }

    this.refreshCurrentOrgWorkspaces();
  }

  private refreshCurrentPageComponent() {
    const snapshot = this.getRouteSnapshot();
    this.currentPageRouteUrl = this.router.url;
    this.currentPageRouteComponent = snapshot.component as Type<any>; // @Warning if i get a string here, i'm completly fucked up
    this.currentPageRouteParams = snapshot.params;
    this.currentPageRouteQueryParams = snapshot.queryParams;
  }

  private refreshCurrentOrg() {
    this.currentOrgId = this.getOrgIdFromURL();
    this.currentOrg = this.orgs?.find((o: Org) => o.id === this.currentOrgId);

    if (!this.currentOrg && this.orgs?.length === 1) {
      this.currentOrg = this.orgs[0];
      this.currentOrgId = this.orgs[0].id;
    }
  }

  private refreshCurrentSuperOrg() {
    if (!this.currentOrg) {
      this.currentSuperOrgId = this.getSuperOrgIdFromURL();
      this.currentSuperOrg = this.superOrgs?.find(
        (o: SuperOrg) => o.id === this.currentSuperOrgId,
      );
    } else {
      this.currentSuperOrg = this.superOrgs?.find(
        (o: SuperOrg) => o.id === this.currentOrg.super_org_id,
      );
      this.currentSuperOrgId = this.currentSuperOrg?.id;
    }

    if (!this.currentSuperOrg && this.superOrgs?.length === 1) {
      this.currentSuperOrg = this.superOrgs[0];
      this.currentSuperOrgId = this.superOrgs[0].id;
    }
  }

  private refreshCurrentOrgWorkspaces() {
    // When we know the current super org, we can filter workspaces
    // Why doing it in that way? Cause i want to alter previous workspaces logic a minimum
    if (this.currentSuperOrgId) {
      this.orgWorkspaces =
        this.orgs?.filter(
          (o: Org) => o.super_org_id === this.currentSuperOrgId,
        ) || [];
    } else {
      // Assuming that this should never happen, unless we mannually remove an account from super org
      this.orgWorkspaces = this.orgs;
    }

    // Be sure that current workspace is in org
    if (
      this.currentSuperOrgId &&
      this.currentOrg?.super_org_id !== this.currentSuperOrgId
    ) {
      this.currentOrg = this.orgWorkspaces?.[0] ?? null;
      this.currentOrgId = this.orgWorkspaces?.[0]?.id ?? null;
    }
  }

  private async refreshCurrentSurvey() {
    this.currentSurveyId = this.getSurveyIdFromURL();
    if (!this.currentSurveyId) {
      this.currentSurvey = null;
    }
  }

  public setTheme(theme: AppTheme): void {
    window.localStorage?.setItem(`x-screeb-theme-${ENV.ENV}`, theme);
    this.refreshTheme();
  }

  private async refreshTheme() {
    // Get theme from localstorage
    const data = window.localStorage?.getItem(`x-screeb-theme-${ENV.ENV}`);
    const theme = (data || AppTheme.LIGHT) as AppTheme;

    if (theme === AppTheme.AUTO) {
      this.theme =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
          ? AppTheme.DARK
          : AppTheme.LIGHT;
    } else {
      this.theme = theme;
    }

    document.documentElement.setAttribute("data-theme", this.theme);
  }

  private refreshIsLayoutPageEmbbeded(): boolean {
    return (this.isLayoutEmbbeded = [
      EmbeddedExternalPageComponent,
      OverviewOrgPageComponent,
    ].includes(this.currentPageRouteComponent));
  }
  private refreshIsLayoutPageBuilder(): boolean {
    return (this.isLayoutBuilder =
      this.currentPageRouteComponent instanceof BuilderPageComponent);
  }
  private refreshIsAuthPage(): boolean {
    return (this.isAuthPage = this.currentPageRouteUrl.indexOf("/auth/") === 0);
  }
  private refreshIsSuperOrgPage(): boolean {
    return (this.isSuperOrgPage =
      this.currentPageRouteUrl.indexOf("/overview/") === 0);
  }
  private refreshIsOverviewPage(): boolean {
    return (this.isOverviewPage = [OverviewOrgPageComponent].includes(
      this.currentPageRouteComponent,
    ));
  }
  private refreshIsSurveyPage(): boolean {
    return (this.isSurveyPage =
      (this.refreshIsStatsPage() ||
        this.refreshIsSharingPage() ||
        [
          SurveyPageComponent,
          BuilderPageComponent,
          SurveySettingsPageComponent,
          CreateSurveyPageComponent,
        ].includes(this.currentPageRouteComponent)) &&
      !this.isMessagePage);
  }
  private refreshIsMessagePage(): boolean {
    this.isMessagePage =
      this.currentPageRouteUrl.indexOf(`/org/${this.currentOrgId}/message/`) ===
      0;

    if (this.isMessagePage) {
      this.surveyType = "message";
      this.surveyTypePath = "message";
    } else {
      this.surveyType = "survey";
      this.surveyTypePath = "survey";
    }
    return this.isMessagePage;
  }
  private refreshIsStatsPage(): boolean {
    return (this.isStatsPage = [
      StatsSurveyPageComponent,
      OverallPerformancesStatsSurveyPageComponent,
      ContentAnalysisStatsSurveyPageComponent,
      FlowAnalysisStatsSurveyPageComponent,
      IndividualResponsesStatsSurveyPageComponent,
      NPSIndicatorStatsSurveyPageComponent,
      CESIndicatorStatsSurveyPageComponent,
      CSATIndicatorStatsSurveyPageComponent,
      PMFIndicatorStatsSurveyPageComponent,
    ].includes(this.currentPageRouteComponent));
  }
  private refreshIsSharingPage(): boolean {
    return (this.isSharingPage = [
      ShareSurveyPageComponent,
      LinkShareSurveyPageComponent,
      DistributionSettingsPageComponent,
    ].includes(this.currentPageRouteComponent));
  }
  private refreshIsSettingsPage(): boolean {
    if (
      this.currentPageRouteComponent === SettingsBillingPageComponent &&
      this.currentPageRouteUrl.indexOf("/overview/") === 0
    ) {
      return (this.isWorkspaceSettingsPage = false);
    }
    return (this.isWorkspaceSettingsPage =
      [
        SettingsChannelInstallPageComponent,
        SettingsSurveyCappingPageComponent,
        SettingsSurveyCustomizationPageComponent,
        SettingsDataGovernancePageComponent,
        RegistryIdentityPropertiesListPageComponent,
        RegistryEventsListPageComponent,
        ClientScreensListPageComponent,
        SettingsTeamMembersPageComponent,
        SettingsBillingPageComponent,
        SettingsLanguagePageComponent,
        SettingsTaggingPageComponent,
      ].includes(this.currentPageRouteComponent) ||
      window.location.pathname.endsWith("/settings/sdk-changelogs"));
  }
  private refreshIsIntegrationListPage(): boolean {
    return (this.isIntegrationListPage = [
      IntegrationListPageComponent,
    ].includes(this.currentPageRouteComponent));
  }
  private refreshIsOnBoardingPage(): boolean {
    return (this.isOnBoardingPage = [
      OnBoardingQualificationPageComponent,
      OnBoardingGoalPageComponent,
      OnBoardingSetupPageComponent,
      OnBoardingInstallationPageComponent,
      OnBoardingBookDemoPageComponent,
      OnBoardingDiscoveryComponent,
    ].includes(this.currentPageRouteComponent));
  }
  private refreshFullSiderPage(): boolean {
    // Avoid sidebar junking on redirect to home
    if (this.currentPageRouteUrl === "/redirect-home") {
      return (this.isFullSiderPage = true);
    }

    if (
      this.currentPageRouteComponent === SettingsBillingPageComponent &&
      this.currentPageRouteUrl.indexOf("/overview/") === 0
    ) {
      return (this.isFullSiderPage = true);
    }
    return (this.isFullSiderPage = [
      CreateSurveyPageComponent,
      SurveyListPageComponent,
      SegmentListPageComponent,
      UserListPageComponent,
      OverviewOrgPageComponent,
      QuickstartOrgPageComponent,
      FunnelListPageComponent,
      OverviewSuperOrgPageComponent,
      WorkspacesSuperOrgPageComponent,
      UsersSuperOrgPageComponent,
    ].includes(this.currentPageRouteComponent));
  }

  private refreshIsBillingPage(): boolean {
    return (this.isBillingPage = [SettingsBillingPageComponent].includes(
      this.currentPageRouteComponent,
    ));
  }

  private refreshIsPeoplePage(): boolean {
    return (this.isPeoplePage = [
      UserListPageComponent,
      SegmentListPageComponent,
    ].includes(this.currentPageRouteComponent));
  }

  private getRouteSnapshot(): ActivatedRouteSnapshot {
    let route = this.router?.routerState?.root;
    while (route?.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot;
  }

  private getSuperOrgIdFromURL(): string {
    return (
      this.currentPageRouteParams?.super_org_id ??
      this.getSuperOrgIdFromURLFallback()
    );
  }

  private getSuperOrgIdFromURLFallback(): string {
    if (window.location.pathname.startsWith("/overview/"))
      return window.location.pathname.split("/")?.[2];
    return null;
  }

  private getOrgIdFromURL(): string {
    return (
      this.currentPageRouteParams?.org_id ??
      this.currentPageRouteQueryParams?.workspace ??
      this.getOrgIdFromURLFallback()
    );
  }

  private getOrgIdFromURLFallback(): string {
    if (window.location.pathname.startsWith("/org/"))
      return window.location.pathname.split("/")?.[2];
    return null;
  }

  private getSurveyIdFromURL(): string {
    return (
      this.currentPageRouteParams?.survey_id ??
      this.getSurveyIdFromURLFallback()
    );
  }

  private getSurveyIdFromURLFallback(): string {
    const orgId = this.getOrgIdFromURL();
    if (
      orgId &&
      (window.location.pathname.startsWith(`/org/${orgId}/survey/`) ||
        window.location.pathname.startsWith(`/org/${orgId}/message/`))
    ) {
      const id = window.location.pathname.split("/")?.[4];
      return id === "list" || id === "create" || id.length !== 36 ? null : id;
    }
    return null;
  }
}

export { UIService };
