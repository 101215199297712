import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SurveyInteraction } from "models/survey.model";

@Component({
  selector: "css-selector",
  templateUrl: "./css-selector.component.html",
  styleUrls: ["./css-selector.component.scss"],
})
export class CSSSelectorComponent {
  @Input() public interaction: SurveyInteraction;
  @Input() public selectorCSS: string = null;
  @Output() public selectorCSSChange = new EventEmitter<string>();
}
