<upgrade-modal
  [centeredModal]="true"
  [orgId]="org.id"
  [withOverlay]="true"
  [canCancel]="false"
  position="absolute"
  class="upgrade-modal"
  *ngIf="!('analytics_content_analysis' | hasEntitlement)"
></upgrade-modal>

<div
  [ngClass]="{
    'is-locked': !('analytics_content_analysis' | hasEntitlement)
  }"
>
  <header>
    <h1>Content Analysis</h1>

    <div class="analytics-filters">
      <survey-stats-filters
        reportType="content-analysis"
        [org]="org"
        [survey]="survey"
        [languages]="languages"
        [reporting]="'Content Analysis'"
        [registryEntriesGroup]="registryEntriesGroup"
        type="response"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesEvent]="registryEntriesEvent"
      >
      </survey-stats-filters>
    </div>
  </header>

  <ng-container *ngIf="loading">
    <div class="loader">
      <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
    </div>
  </ng-container>

  <ng-container
    *ngIf="!loading && !('hideOpenAIContentAnalysis' | hasFeatureFlag)"
  >
    <header class="summary">
      <p>
        <strong>
          In this survey, you have {{ nodes.length }} open questions and
          {{ totalResponsesCount }}
          responses in total, {{ filteredResponseCount }} corresponding to your
          filters. Let’s analyse them!
        </strong>
      </p>
      <br />
      <p>
        This report is based on
        <a
          title="OpenAI"
          rel="noopener"
          href="https://openai.com/"
          target="_blank"
          ><img alt="OpenAI" src="/assets/icons/common/openai.svg"
        /></a>
        and is still in beta. We analyse the last
        {{ maxAnswersCount }} responses corresponding to your filters.
        <a (click)="requestFeedback()">Let us know if you have any feedback!</a>
      </p>
    </header>

    <ng-container *ngIf="nodes.length > 0">
      <ng-container *ngFor="let node of nodes">
        <survey-stats-content-analysis-question-analysis
          [questionType]="node.node.type"
          [letter]="node.node.letter"
          [description]="node.node.text"
          [nodeCorrelationId]="node.node.correlationId"
          [answersCount]="answersCount[node.node.correlationId] ?? 0"
          [maxAnswersCount]="maxAnswersCount"
        >
        </survey-stats-content-analysis-question-analysis>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="nodes.length === 0">
      <nz-empty
        [nzNotFoundContent]="'No open question in this survey'"
      ></nz-empty>
    </ng-container>
  </ng-container>

  <h2>Longest Feedback</h2>

  <survey-stats-individual-responses-answers-table-single-question
    [org]="org"
    [survey]="survey"
    [noPagination]="true"
    [getQuery]="getQuery"
    [getAnswers]="getAnswers"
    [integrations]="integrations"
    [removable]="false"
  >
  </survey-stats-individual-responses-answers-table-single-question>
</div>
