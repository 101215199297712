<div *ngIf="rule">
  <div
    class="rule-type-property-values"
    *ngIf="rule.operator && registryEntriesIdentityProperty.length > 0"
  >
    <div
      *ngIf="
        ['equal', 'not equal', 'contains', 'not contains'].includes(
          rule.operator
        ) &&
        ['string'].includes(
          rule.getRegistryEntryType(
            registryEntriesIdentityPropertyById.get(rule.value.key_id)
          )
        )
      "
    >
      <div
        *ngFor="
          let line of rule.value.v_s_arr;
          let i = index;
          trackBy: trackByFn
        "
        class="or-nor-container"
      >
        <rule-or-neither [rule]="rule" [index]="i"></rule-or-neither>

        <input
          type="text"
          nz-input
          placeholder="{{
            rule.getUserPropertyValuePlaceHolder(
              registryEntriesIdentityPropertyById.get(rule.value.key_id)
            )
          }}"
          [ngModel]="line"
          (ngModelChange)="onOrRuleChange(rule, $event, i, false)"
          [ngClass]="{ invalid: !checkValidValueTypeString(rule, i) }"
          (paste)="onPasteString($event)"
        />
      </div>
    </div>

    <div
      *ngIf="
        ['equal', 'not equal'].includes(rule.operator) &&
        ['number'].includes(
          rule.getRegistryEntryType(
            registryEntriesIdentityPropertyById.get(rule.value.key_id)
          )
        )
      "
    >
      <div
        *ngFor="
          let line of rule.value.v_n_arr;
          let i = index;
          trackBy: trackByFn
        "
        class="or-nor-container"
      >
        <rule-or-neither [rule]="rule" [index]="i"></rule-or-neither>

        <rule-input-number
          [value]="rule.value.v_n_arr[i]"
          (valueChange)="onOrRuleChange(rule, $event, i, true)"
          [placeholder]="rule.getValuePlaceholder()"
          [invalid]="!checkValidValueTypeNumberMultiple(rule, i)"
          (paste)="onPasteNumber($event)"
        ></rule-input-number>
      </div>
    </div>

    <div
      *ngIf="
        ['lt', 'gt'].includes(rule.operator) &&
        ['number'].includes(
          rule.getRegistryEntryType(
            registryEntriesIdentityPropertyById.get(rule.value.key_id)
          )
        )
      "
    >
      <rule-input-number
        [(value)]="rule.value.v_n"
        [placeholder]="
          rule.getUserPropertyValuePlaceHolder(
            registryEntriesIdentityPropertyById.get(rule.value.key_id)
          )
        "
        [invalid]="!checkValidValueTypeNumberSingle(rule)"
        (paste)="onPasteNumber($event)"
      ></rule-input-number>
    </div>

    <nz-select
      *ngIf="
        ['bool'].includes(
          rule.getRegistryEntryType(
            registryEntriesIdentityPropertyById.get(rule.value.key_id)
          )
        )
      "
      [(ngModel)]="rule.value.v_b"
    >
      <nz-option [nzLabel]="'True'" [nzValue]="true"></nz-option>
      <nz-option [nzLabel]="'False'" [nzValue]="false"></nz-option>
    </nz-select>

    <nz-input-group
      *ngIf="
        ['time'].includes(
          rule.getRegistryEntryType(
            registryEntriesIdentityPropertyById.get(rule.value.key_id)
          )
        ) &&
        ['date_relative_before', 'date_relative_after'].includes(rule.operator)
      "
      [nzSuffix]="'days ago'"
      [ngClass]="{ invalid: !checkValidValueTypeNumberSingle(rule) }"
    >
      <rule-input-number
        [(value)]="rule.value.v_n"
        [step]="1"
        [minValue]="1"
        [maxValue]="365"
        [inputGroupMode]="true"
        [placeholder]="
          rule.getUserPropertyValuePlaceHolder(
            registryEntriesIdentityPropertyById.get(rule.value.key_id)
          )
        "
      ></rule-input-number>
    </nz-input-group>
  </div>

  <div *ngIf="registryEntriesIdentityProperty.length === 0">
    <span>
      You don’t collect visitor properties yet. Learn how to do it to use this
      trigger
      <a
        href="https://help.screeb.app/en/articles/4992511-how-to-track-user-properties-in-screeb"
        target="_blank"
        >here</a
      >.
    </span>
  </div>
</div>
