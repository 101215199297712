<div
  class="preview-container"
  [style]="getCssVars()"
  [ngClass]="mode + ' ' + scope"
>
  <div class="mode" *ngIf="!forceMobile">
    <nz-radio-group [(ngModel)]="mode">
      <label class="mode-button" nz-radio nzValue="desktop">
        <i nz-icon nzType="desktop" nzTheme="outline"></i>
      </label>
      <label
        *ngIf="showMobileOption"
        class="mode-button"
        nz-radio
        nzValue="mobile"
      >
        <i nz-icon nzType="mobile" nzTheme="outline"></i>
      </label>
    </nz-radio-group>
  </div>

  <div
    class="messages-wrapper"
    [ngClass]="{
      linkSharing: format === 'link',
      inPageBackground: format === 'in_page'
    }"
    [ngStyle]="{
      'background-image': 'url(\'' + getBackgroundImage() + '\')',
      'background-color': getBackgroundColor()
    }"
    [style.align-items]="getVerticalAlign()"
    [style.justify-content]="getHorizontalAlign()"
  >
    <div
      class="background"
      [ngStyle]="{
        'background-image': 'url(\'' + getInnerBackgroundImage() + '\')',
        'background-color': getInnerBackgroundColor()
      }"
    >
      <div
        class="screeb-overlay"
        *ngIf="
          getFormat() === 'conversational' &&
          tagSettings.conversational_overlay_visibility !== 'hidden'
        "
      ></div>
    </div>

    <div *ngIf="getFormat() === 'in_page'" class="in-page-image-container">
      <img
        class="in-page-image"
        src="/assets/preview/preview-background-in-page-up.png"
        alt="preview-background-in-page-up"
      />
    </div>

    <div *ngIf="getFormat() === 'in_page'" class="in-page">
      <div class="in-page-body">
        <div>
          How would you rate your
          <strong>overall satisfaction with our product</strong>?
        </div>
        <div class="in-page-rating">
          <div class="in-page-rating-button">
            <sanitized-message-component
              content="🤩"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.in_page_color_primary_text
              }"
            >
            </sanitized-message-component>
          </div>
          <div class="in-page-rating-button">
            <sanitized-message-component
              content="🙂"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.in_page_color_primary_text
              }"
            >
            </sanitized-message-component>
          </div>
          <div class="in-page-rating-button">
            <sanitized-message-component
              content="😐"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.in_page_color_primary_text
              }"
            >
            </sanitized-message-component>
          </div>
          <div class="in-page-rating-button">
            <sanitized-message-component
              content="🙁"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.in_page_color_primary_text
              }"
            >
            </sanitized-message-component>
          </div>
          <div class="in-page-rating-button">
            <sanitized-message-component
              content="😡"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.in_page_color_primary_text
              }"
            >
            </sanitized-message-component>
          </div>
        </div>
        <div class="in-page-footer">
          <div class="in-page-powered-by">
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 0C2.68629 0 0 2.68629 0 6V14H12C15.3137 14 18 11.3137 18 8V6C18 2.68629 15.3137 0 12 0H6ZM5 8C5.55229 8 6 7.55229 6 7C6 6.44771 5.55229 6 5 6C4.44772 6 4 6.44771 4 7C4 7.55229 4.44772 8 5 8ZM9 8C9.55229 8 10 7.55229 10 7C10 6.44771 9.55229 6 9 6C8.44771 6 8 6.44771 8 7C8 7.55229 8.44771 8 9 8ZM14 7C14 7.55229 13.5523 8 13 8C12.4477 8 12 7.55229 12 7C12 6.44771 12.4477 6 13 6C13.5523 6 14 6.44771 14 7Z"
                [ngStyle]="{
                  fill: '#727387'
                }"
              />
            </svg>
            <span>Powered by Screeb</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="getFormat() === 'cards'" class="cards">
      <div class="cards-buttons">
        <screeb-icon
          size="sm"
          icon="bottom-arrow"
          *ngIf="tagSettings.cards_display_reduce_button"
        ></screeb-icon>
        <screeb-icon size="sm" icon="cross"></screeb-icon>
      </div>
      <div
        class="cards-identity"
        *ngIf="tagSettings.cards_identity_type === 'fixed'"
      >
        <div class="cards-identity-avatar"></div>
        <div class="cards-identity-details">
          <div class="cards-identity-name">
            {{ tagSettings.cards_identity_fixed_name }}
          </div>
          <div class="cards-identity-title">
            {{ tagSettings.cards_identity_fixed_title }}
          </div>
        </div>
      </div>
      <div class="cards-body">
        <div>
          How would you rate your
          <strong>overall satisfaction with our product</strong>?
        </div>
        <div class="cards-rating">
          <div class="cards-rating-button">
            <sanitized-message-component
              content="🤩"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.cards_color_primary_text
              }"
            >
            </sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="🙂"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.cards_color_primary_text
              }"
            >
            </sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="😐"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.cards_color_primary_text
              }"
            >
            </sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="🙁"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.cards_color_primary_text
              }"
            >
            </sanitized-message-component>
          </div>
          <div class="cards-rating-button">
            <sanitized-message-component
              content="😡"
              [options]="{
                links: true,
                styling: true,
                emojiTranscoder: true,
                useDarkAndLightMode: true,
                primaryTextColor: tagSettings.cards_color_primary_text
              }"
            >
            </sanitized-message-component>
          </div>
        </div>
        <div class="cards-footer">
          <div
            class="cards-powered-by"
            [ngClass]="shouldInvertWhiteLabel() ? 'inverted' : ''"
          >
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 0C2.68629 0 0 2.68629 0 6V14H12C15.3137 14 18 11.3137 18 8V6C18 2.68629 15.3137 0 12 0H6ZM5 8C5.55229 8 6 7.55229 6 7C6 6.44771 5.55229 6 5 6C4.44772 6 4 6.44771 4 7C4 7.55229 4.44772 8 5 8ZM9 8C9.55229 8 10 7.55229 10 7C10 6.44771 9.55229 6 9 6C8.44771 6 8 6.44771 8 7C8 7.55229 8.44771 8 9 8ZM14 7C14 7.55229 13.5523 8 13 8C12.4477 8 12 7.55229 12 7C12 6.44771 12.4477 6 13 6C13.5523 6 14 6.44771 14 7Z"
                [ngStyle]="{
                  fill: shouldInvertWhiteLabel()
                    ? 'var(--screeb-color-white)'
                    : '#727387'
                }"
              />
            </svg>
            <span>Powered by Screeb</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="getFormat() === 'in_page'" class="in-page-image-container">
      <img
        class="in-page-image"
        src="/assets/preview/preview-background-in-page-down.png"
        alt="preview-background-in-page-down"
      />
    </div>

    <ul *ngIf="getFormat() === 'conversational'" class="messages">
      <div
        class="messages-buttons"
        [style.justify-content]="getHorizontalAlign(true)"
      >
        <button
          class="messages-button"
          *ngIf="!tagSettings.conversational_display_close_reduce"
        >
          <screeb-icon size="sm" icon="ellipsis-horizontal"></screeb-icon>
        </button>
        <button
          class="messages-button"
          *ngIf="tagSettings.conversational_display_close_reduce"
        >
          <screeb-icon size="sm" icon="cross"></screeb-icon>
        </button>
        <button
          class="messages-button"
          *ngIf="tagSettings.conversational_display_close_reduce"
        >
          <screeb-icon size="sm" icon="neutral"></screeb-icon>
        </button>
      </div>
      <li class="message incoming">
        <div class="message-inner">
          <div
            class="intro"
            *ngIf="tagSettings.conversational_identity_type === 'fixed'"
          >
            <div class="channel-picture"></div>
            <div class="display-name">
              <strong>{{
                tagSettings.conversational_identity_fixed_name
              }}</strong>
              <br />
              <span>{{ tagSettings.conversational_identity_fixed_title }}</span>
            </div>
          </div>
          <div class="text">
            <p class="content">
              How likely are you to recommend our app to a friend or colleague?
            </p>
          </div>
        </div>
      </li>

      <li class="message outgoing">
        <div class="message-inner is-score-response">
          <div class="text">
            <p class="content" style="font-size: 32px">
              <sanitized-message-component
                content="9️⃣"
                [options]="{
                  links: true,
                  styling: true,
                  emojiTranscoder: true,
                  useDarkAndLightMode: true,
                  primaryTextColor:
                    tagSettings.conversational_color_primary_text
                }"
              >
              </sanitized-message-component>
            </p>
          </div>
        </div>
      </li>

      <li class="message incoming">
        <div class="message-inner">
          <div class="text">
            <div class="channel-picture"></div>
            <p class="content">How can we improve your experience?</p>
          </div>
        </div>
      </li>

      <li class="message outgoing">
        <div class="message-inner">
          <div class="text">
            <p class="content">
              I use the app on a daily basis, sometimes with my phone. A mobile
              app would be awesome!
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
