<div hidden>
  <label class="lateral-panel-section-title">Options</label>

  <ng-container *ngIf="['beacon', 'popover'].includes(node.node.question.type)">
    <label class="lateral-panel-subtitle">Placement</label>

    <div
      class="selector-input-wrapper{{ hasSelectorError ? ' is-error' : '' }}"
    >
      <div class="selector-prefix">Element</div>
      <input
        type="text"
        class="panel-input"
        placeholder=".my-selector"
        [ngClass]="{
          invalid: false
        }"
        [ngModel]="question.selector"
        (ngModelChange)="question.selector = $event; validateData()"
      />
    </div>

    <p class="error-message" *ngIf="hasSelectorError">
      <i nz-icon nzType="warning"></i>
      <span>Invalid selector</span>
    </p>
  </ng-container>

  <label class="lateral-panel-subtitle">Style</label>
  <nz-select ngModel="loud">
    <nz-option nzValue="light" nzLabel="Light"></nz-option>
    <nz-option nzValue="loud" nzLabel="Loud"></nz-option>
  </nz-select>

  <ng-container *ngIf="node.node.question.type === 'banner'">
    <label class="lateral-panel-subtitle">Position</label>
    <nz-select
      *ngIf="node.node.question.type === 'banner'"
      [ngModel]="question.position"
      (ngModelChange)="question.position = $event; validateData()"
    >
      <nz-option nzValue="fixed" nzLabel="Fixed"></nz-option>
      <nz-option nzValue="floating" nzLabel="Floating"></nz-option>
    </nz-select>
  </ng-container>

  <div
    class="is-flex"
    *ngIf="['banner', 'sidebar', 'modal'].includes(node.node.question.type)"
  >
    <div>
      <label class="lateral-panel-subtitle">Placement</label>
      <nz-select
        *ngIf="node.node.question.type === 'banner'"
        [ngModel]="question.placement"
      >
        <nz-option nzValue="top" nzLabel="Top"></nz-option>
        <nz-option nzValue="bottom" nzLabel="Bottom"></nz-option>
      </nz-select>
      <nz-select
        *ngIf="node.node.question.type === 'sidebar'"
        (ngModelChange)="question.placement = $event; validateData()"
      >
        <nz-option nzValue="right" nzLabel="Right"></nz-option>
        <nz-option nzValue="left" nzLabel="Left"></nz-option>
      </nz-select>
      <nz-select
        *ngIf="
          node.node.question.type !== 'banner' &&
          node.node.question.type !== 'sidebar'
        "
        [ngModel]="question.placement"
        (ngModelChange)="question.placement = $event; validateData()"
      >
        <nz-option
          *ngFor="let placement of availableQuestionTypeMessagePlacement"
          [nzValue]="placement"
          [nzLabel]="questionTypeMessagePlacementLabels[placement]"
        ></nz-option>
      </nz-select>
    </div>
    <div *ngIf="node.node.question.type === 'banner'">
      <label class="lateral-panel-subtitle">Size</label>
      <nz-select
        [ngModel]="question.size"
        (ngModelChange)="question.size = $event; validateData()"
      >
        <nz-option
          nzValue="full"
          nzLabel="Full"
          [nzDisabled]="question.position !== 'fixed'"
        ></nz-option>
        <nz-option
          nzValue="small"
          nzLabel="Small"
          [nzDisabled]="question.position === 'fixed'"
        ></nz-option>
        <nz-option
          nzValue="large"
          nzLabel="Large"
          [nzDisabled]="question.position === 'fixed'"
        ></nz-option>
      </nz-select>
    </div>
  </div>

  <div
    class="switch-wrapper"
    *ngIf="['banner', 'popover', 'modal'].includes(node.node.question.type)"
  >
    <nz-switch
      ngDefaultControl
      [ngModelOptions]="{ standalone: true }"
      ngModel="true"
    >
    </nz-switch>
    <label
      >Personify with an avatar
      <span class="recommended">Recommended</span></label
    >
  </div>
  <div
    class="switch-wrapper"
    *ngIf="['banner', 'modal'].includes(node.node.question.type)"
  >
    <nz-switch
      ngDefaultControl
      [ngModelOptions]="{ standalone: true }"
      ngModel="true"
    >
    </nz-switch>
    <label>Allow closing <span class="recommended">Recommended</span></label>
  </div>

  <hr />
</div>

<label class="lateral-panel-section-title">Content and actions</label>
<p class="preview-box">
  <span class="preview-box-message">
    Manage and write your content directly on the preview!
  </span>
  <button nz-button nzType="primary" (click)="onOpenPreviewClick()">
    <span>Open builder </span>
    &nbsp;
    <screeb-icon size="sm" icon="righ-arrow"></screeb-icon>
  </button>
</p>
