<div *ngIf="rule" class="rule-type-event-count-values">
  <div
    class="rule-type-event-count-values"
    *ngIf="rule.operator && registryEntriesEvent.length > 0"
  >
    <div *ngIf="['equal', 'not equal'].includes(rule.operator)">
      <div
        *ngFor="
          let line of rule.value.v_n_arr;
          let i = index;
          trackBy: trackByFn
        "
        class="or-nor-container"
      >
        <rule-or-neither [rule]="rule" [index]="i"></rule-or-neither>

        <rule-input-number
          [value]="rule.value.v_n_arr[i]"
          (valueChange)="onOrRuleChange(rule, $event, i, true)"
          [step]="1"
          [minValue]="0"
          [maxValue]="999999"
          [placeholder]="rule.getValuePlaceholder()"
          [invalid]="!checkValidCountMultiple(rule, i)"
        ></rule-input-number>
      </div>
    </div>

    <div *ngIf="['gt', 'lt'].includes(rule.operator)">
      <rule-input-number
        [(value)]="rule.value.v_n"
        [step]="1"
        [minValue]="0"
        [maxValue]="999999"
        [placeholder]="rule.getValuePlaceholder()"
        [invalid]="!checkValidCountSingle(rule)"
      ></rule-input-number>
    </div>
  </div>
</div>

<div *ngIf="registryEntriesEvent.length === 0">
  <span>
    You don’t collect visitor events yet. Learn how to do it to use this trigger
    <a
      href="https://help.screeb.app/en/articles/5227983-how-to-track-user-events-in-screeb"
      target="_blank"
      >here</a
    >.
  </span>
</div>
