<nz-modal
  [(nzVisible)]="isOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzClosable]="false"
  (nzOnCancel)="handleCancel()"
  nzWidth="957px"
  nzClassName="workspace-modal"
>
  <ng-template #modalTitle>
    <h3 *ngIf="!assignUsersStep">
      {{ workspace === null ? "Create a new Workspace" : "Edit a Workspace" }}
    </h3>
    <h3 *ngIf="assignUsersStep">Assign Users to {{ workspace?.name }}</h3>
  </ng-template>

  <ng-template #modalContent>
    <div class="tabs" *ngIf="workspace === null && !assignUsersStep">
      <div class="tab">Individual Creation</div>
      <div class="tab">
        Bulk Creation
        <utils-badge text="Soon"></utils-badge>
      </div>
    </div>
    <form
      *ngIf="!assignUsersStep"
      nz-form
      [formGroup]="validateForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="is-flex">
        <nz-form-item>
          <nz-form-label>Name</nz-form-label>
          <nz-form-control
            class="form-input"
            nzErrorTip="Please fill this field"
            required
          >
            <input
              type="text"
              nz-input
              nzHasFeedback
              formControlName="name"
              placeholder="My Awesome Workspace"
              required
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <div class="item-label-wrap">
            <nz-form-label>Tags</nz-form-label>
            <span>Optional</span>
          </div>
          <nz-form-control class="form-input">
            <nz-select
              nzMode="tags"
              nzPlaceHolder="Choose or add tags"
              name="tags"
              [nzMaxMultipleCount]="100"
              [nzShowArrow]="true"
              nzSuffixIcon="caret-down"
              formControlName="tags"
            >
              <nz-option
                *ngFor="let tag of tagOptions"
                [nzLabel]="tag"
                [nzValue]="tag"
              ></nz-option>
            </nz-select>
            <p class="tooltip m0">
              You can use tags to easily organize your workspaces.
            </p>
          </nz-form-control>
        </nz-form-item>
      </div>
      <nz-form-item
        *ngIf="superOrg?.entitlements.mtu_mode === 'manual'"
        class="mtu-allocation"
      >
        <nz-form-label>MTUs Allocation</nz-form-label>
        <nz-form-control class="form-input">
          <div class="is-flex is-mtu">
            <div class="mtus">
              <p>Total MTUs in your Plan</p>
              <p>
                {{
                  formatNumber(
                    superOrg?.entitlements.max_mtu,
                    "number",
                    1,
                    "auto",
                    false
                  )
                }}
              </p>
            </div>
            <div class="mtus">
              <p>Remaining MTUs</p>
              <p>
                {{ formatNumber(remainingMTU, "number", 1, "auto", false) }}
              </p>
            </div>
            <div>
              <p>MTUs Allocation for this Workspace</p>
              <nz-input-number
                nzMin="100"
                nzMax="maxMTU"
                nzStep="500"
                formControlName="max_mtu"
                placeholder="1.000.000"
                (ngModelChange)="onInputChange($event)"
                required
              ></nz-input-number>
            </div>
          </div>
          <p class="tooltip">
            Note: if you allocate more MTU to this new workspace than what is
            remaining in your plan, we will evenly decrease the MTU allocation
            of your other workspaces to free up the necessary units. Please
            manage your MTU allocation accordingly.
          </p>
        </nz-form-control>
      </nz-form-item>
    </form>
    <ng-container *ngIf="assignUsersStep">
      <div class="info-banner">
        🎉 &nbsp; Your new workspace has been created! Now, you can add users
        and manage their role:
      </div>
      <div *ngIf="isLoading" class="is-loading">
        <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
      </div>
      <form
        *ngIf="!isLoading"
        nz-form
        class="access-form"
        [formGroup]="validateForm"
        (ngSubmit)="onSubmit()"
      >
        <div *ngFor="let entry of accessFormArray.controls; let i = index">
          <div [formGroup]="entry" class="is-flex">
            <nz-form-item>
              <nz-form-label>Users</nz-form-label>
              <nz-form-control class="form-input">
                <nz-select
                  nzMode="multiple"
                  nzPlaceHolder="Choose one or more users"
                  name="users"
                  [nzMaxMultipleCount]="100"
                  [nzShowArrow]="true"
                  nzSuffixIcon="caret-down"
                  (ngModelChange)="onUserChange(i, $event)"
                  formControlName="users"
                >
                  <nz-option
                    *ngFor="let user of users"
                    [nzLabel]="
                      user.fullname +
                      (entry.get('is_admin').value && user.role !== 'admin'
                        ? ' (Not admin)'
                        : !entry.get('is_admin').value &&
                            user.role === 'admin' &&
                            entry.get('users').value?.length
                          ? ' (Admin)'
                          : '')
                    "
                    [nzValue]="user.id"
                    [nzDisabled]="
                      getUsedUsersWithoutIndex(i).includes(user.id) ||
                      (entry.get('is_admin').value && user.role !== 'admin') ||
                      (!entry.get('is_admin').value &&
                        user.role === 'admin' &&
                        entry.get('role').value !== 'admin' &&
                        entry.get('users').value?.length !== null &&
                        entry.get('users').value?.length > 0)
                    "
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item class="role-select">
              <nz-form-label>Role</nz-form-label>
              <nz-form-control>
                <nz-select
                  formControlName="role"
                  nzPlaceHolder="Assign a role for those users"
                  [nzOptions]="
                    entry.get('is_admin').value ? adminRole : workspacesRoles
                  "
                  nzSuffixIcon="caret-down"
                  [nzDisabled]="entry.get('is_admin').value"
                >
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <div class="flex-action">
              <button
                *ngIf="i === accessFormArray.controls.length - 1"
                nz-button
                nzType="default"
                nzShape="circle"
                nzSize="small"
                (click)="addNewAccess()"
              >
                <screeb-icon size="sm" icon="plus"></screeb-icon>
              </button>
              <button
                *ngIf="i !== accessFormArray.controls.length - 1"
                nz-button
                nzType="default"
                nzShape="circle"
                nzSize="small"
                (click)="removeAccess(i)"
              >
                <screeb-icon size="sm" icon="neutral"></screeb-icon>
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </ng-template>

  <ng-template #modalFooter>
    <button
      nz-button
      nzType="default"
      class="workspace-cancel-btn"
      (click)="handleCancel()"
    >
      Cancel
    </button>
    <button
      nz-button
      [class]="'workspace-add-btn'.concat(isFormValid() ? '' : ' disabled')"
      (click)="onSubmit()"
      [nzLoading]="loading"
      [disabled]="!isFormValid()"
    >
      {{
        assignUsersStep
          ? isAssignEmpty
            ? "Skip"
            : "Invite"
          : workspace === null
            ? "Next"
            : "Update"
      }}
    </button>
  </ng-template>
</nz-modal>
