import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { AnalyticsResponse } from "models/analytics.model";
import { AnalyticsQueryUsers } from "./analytics.filters.type";
import { RegistryEntrySource, RegistryEntrySubject } from "./registry.model";
import { Response } from "./response.model";
import { UserEvent } from "./user-event.model";
import { UserProperty } from "./user-properties.model";
import { User } from "./user.model";

@Injectable()
export class UserDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public searchUsers(
    baseQuery: AnalyticsQueryUsers,
    overview: boolean = false,
  ): Promise<AnalyticsResponse> {
    const orgId = baseQuery.org_id;

    // we won't need a deep copy here
    const query = Object.assign({}, baseQuery);
    delete query.org_id;
    delete query.survey_ids;

    if (baseQuery.aggregation) {
      query.aggregation = baseQuery.aggregation.reduce(
        (finalAggregation: any, aggregration) => ({
          ...finalAggregation,
          ...aggregration["params"],
          by: [...finalAggregation.by, aggregration.by],
        }),
        { by: [] },
      );
    }

    const start = new Date().getTime();
    return this.screebApiHelper
      .request(
        "SEARCH",
        `/org/${orgId}/user${overview ? "?overview=true" : ""}`,
        { body: query },
      )
      .toPromise()
      .then((data: object): AnalyticsResponse => {
        const res = AnalyticsResponse.fromJson(data);

        const end = new Date().getTime();
        console.log(`Took ${res.took} ms (${end - start} ms e2e)`);

        return res;
      });
  }

  public exportUsers(query: AnalyticsQueryUsers): string {
    const orgId = query.org_id;

    // we won't need a deep copy here
    query = Object.assign({}, query);
    delete query.org_id;
    delete query.aggregation;

    const q = encodeURIComponent(JSON.stringify(query));
    // const q = encodeURIComponent(window.btoa(JSON.stringify(query)));

    const basePath = this.screebApiHelper.getApiEndpoint();
    return `${basePath}/org/${orgId}/user/csv?query=${q}`;
  }

  public getUser(orgId: string, userId: string): Promise<User> {
    return this.screebApiHelper
      .get<object>(`/org/${orgId}/user/${userId}`)
      .toPromise()
      .then((data: Record<string, any>): User => {
        return new User().fromJson(data);
      });
  }

  public getUserProperties(
    orgId: string,
    userId: string,
    includeDataSource?: RegistryEntrySource[] | null,
    excludeDataSource?: RegistryEntrySource[] | null,
  ): Promise<UserProperty[]> {
    includeDataSource = includeDataSource ?? [];
    excludeDataSource = excludeDataSource ?? [];
    return this.screebApiHelper
      .get<object>(
        `/org/${orgId}/user/${userId}/properties?include_data_source=${includeDataSource.join(
          ",",
        )}&exclude_data_source=${excludeDataSource.join(",")}`,
      )
      .toPromise()
      .then((data: Record<string, any>): UserProperty[] => {
        return data.properties.map((p: object) =>
          new UserProperty().fromJson(p),
        );
      });
  }

  public getUserEvents(
    orgId: string,
    userId: string,
    count: number = 10,
    until?: Date | null,
    includeSubject?: RegistryEntrySubject[] | null,
    excludeSubject?: RegistryEntrySubject[] | null,
    includeDataSource?: RegistryEntrySource[] | null,
    excludeDataSource?: RegistryEntrySource[] | null,
  ): Promise<UserEvent[]> {
    includeSubject = includeSubject ?? [];
    excludeSubject = excludeSubject ?? [];
    includeDataSource = includeDataSource ?? [];
    excludeDataSource = excludeDataSource ?? [];

    const _includeSubject = includeSubject.join(",");
    const _excludeSubject = excludeSubject.join(",");
    const _includeDataSource = includeDataSource.join(",");
    const _excludeDataSource = excludeDataSource.join(",");

    return this.screebApiHelper
      .get<object>(
        `/org/${orgId}/user/${userId}/events?until=${until?.toISOString()}&count=${count}&include_subject=${_includeSubject}&exclude_subject=${_excludeSubject}&include_data_source=${_includeDataSource}&exclude_data_source=${_excludeDataSource}`,
      )
      .toPromise()
      .then((data: Record<string, any>): UserEvent[] => {
        return data.events.map((e: object) => new UserEvent().fromJson(e));
      });
  }

  public getUserResponses(
    orgId: string,
    userId: string,
    count: number = 1000,
    until: Date = new Date(),
  ): Promise<Response[]> {
    return this.screebApiHelper
      .get<{ responses: Response[] }>(
        `/org/${orgId}/user/${userId}/response?until=${until?.toISOString()}&count=${count}`,
      )
      .toPromise()
      .then(({ responses }) =>
        responses.map((r) => new Response().fromJson(r)),
      );
  }

  public saveSegment(
    orgId: string,
    groups: { type: string; name: string }[],
    filters: AnalyticsQueryUsers,
  ) {
    return Promise.all(
      groups.map((group) =>
        this.screebApiHelper
          .post(`/org/${orgId}/user_groups`, {
            type: group.type,
            name: group.name,
            filters,
          })
          .toPromise(),
      ),
    );
  }

  public addUserToGroups(
    orgId: string,
    userId: string,
    groupType: string | null,
    groupTypeId: string | null,
    groupNames: string[],
  ): Promise<object> {
    return Promise.all(
      groupNames.map((groupName) =>
        this.screebApiHelper
          .patch(`/org/${orgId}/user/${userId}/group`, {
            assign: true,
            type: groupType,
            type_id: groupTypeId,
            name: groupName,
            properties: {},
          })
          .toPromise(),
      ),
    );
  }

  public removeUserFromGroup(
    orgId: string,
    userId: string,
    groupType: string | null,
    groupTypeId: string | null,
    groupName: string,
  ): Promise<object> {
    return this.screebApiHelper
      .patch<object>(`/org/${orgId}/user/${userId}/group`, {
        assign: false,
        type: groupType,
        type_id: groupTypeId,
        name: groupName,
        properties: {},
      })
      .toPromise();
  }

  public remove(orgId: string, userId: string): Promise<object> {
    return this.screebApiHelper
      .delete(`/org/${orgId}/user/${userId}`)
      .toPromise();
  }
}
