/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TagSettingsThemeFontFamily } from "models/tag-settings.types";
import { EntitlementService } from "services/entitlement.service";
import { UIService } from "services/ui.service";

export const fonts: TagSettingsThemeFontFamily[] = [
  "Inter",
  "Lato",
  "Liberation Sans",
  "Montserrat",
  "Nunito",
  "Open Sans",
  "Raleway",
  "Roboto",
  "Rubik",
  "Source Sans Pro",
  "Ubuntu",
];

export type TagFonts = typeof fonts;

@Component({
  selector: "font-select",
  template: `
    <nz-select
      class="font-select"
      [nzPlaceHolder]="placeholder"
      [nzShowSearch]="true"
      nzSize="large"
      [nzDropdownMatchSelectWidth]="false"
      [nzCustomTemplate]="defaultTemplate"
      [(ngModel)]="ngModel"
      (ngModelChange)="ngModelChange.emit($event)"
    >
      <nz-option
        class="font-option"
        *ngFor="let font of fonts"
        nzCustomContent
        [nzLabel]="font"
        [nzValue]="font"
      >
        <div [style.font-family]="font">
          {{ font }}
        </div>
      </nz-option>
    </nz-select>
    <ng-template #defaultTemplate let-selected>
      <div [style.font-family]="ngModel">
        {{ ngModel }}
      </div>
    </ng-template>
  `,
  styles: [
    `
      @import "../../../../themes/fonts.scss";

      @include loadAvailableFonts();

      .font-select {
        min-width: 150px;
        width: 100%;
      }

      .font-option {
        display: flex;
        align-items: center;
        width: 100%;
      }

      ::ng-deep .ant-select-item-option-content {
        display: flex;
        align-items: center;
        width: 100%;
      }

      ::ng-deep .ant-select-selection-item {
        display: flex;
        align-items: center;
      }

      ::ng-deep .ant-select-selection-item-remove {
        margin-left: 8px;
        font-size: 12px !important;
      }
    `,
  ],
})
export class FontSelect {
  @Input() public placeholder: string = "Select font";
  @Input() public ngModel = "Rubik";

  @Output() public ngModelChange = new EventEmitter<string>();

  fonts = fonts;

  constructor(
    public entitlementService: EntitlementService,
    public uiService: UIService,
  ) {}
}
