<header>
  <h1>All responses</h1>
  <div class="analytics-filters">
    <survey-stats-filters
      reportType="all-responses"
      [canShare]="true"
      [org]="org"
      [survey]="survey"
      [reporting]="'Individual Responses'"
      [languages]="languages"
      [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
      [registryEntriesGroup]="registryEntriesGroup"
      [registryEntriesEvent]="registryEntriesEvent"
      type="response"
    >
    </survey-stats-filters>
  </div>
</header>
<section class="tuto">
  <card-101-link
    *ngFor="let post of posts101"
    class="treemap-col"
    [page]="name"
    [title]="post.title"
    [color]="post.color"
    [url]="post.url"
  ></card-101-link>
</section>

<survey-stats-individual-responses-questions-table
  [nodes]="nodes"
  [responsesCount]="responsesCount"
  [displaySurveyTitle]="false"
  [questionBodyView]="questionBodyView"
>
</survey-stats-individual-responses-questions-table>

<ng-template #questionBodyView let-item="item">
  <ng-container *ngIf="!item._node.isOpenEndedQuestion">
    <!-- questions ouvertes -->
    <survey-stats-individual-responses-bar-chart-single-question
      #barChart
      [org]="org"
      [survey]="survey"
      [node]="item._node"
    ></survey-stats-individual-responses-bar-chart-single-question>
    <ng-container *ngIf="barChart.buckets.length > 0">
      <div
        class="switch"
        (click)="
          expandedResponses[item.node.correlationId] =
            !expandedResponses[item.node.correlationId]
        "
      >
        <button
          *ngIf="expandedResponses[item.node.correlationId]"
          nz-button
          nzType="link"
        >
          Hide all results <i nz-icon nzType="up"></i>
        </button>
        <button
          *ngIf="!expandedResponses[item.node.correlationId]"
          nz-button
          nzType="link"
        >
          Show all results <i nz-icon nzType="down"></i>
        </button>
      </div>
      <survey-stats-individual-responses-answers-table-single-question
        *ngIf="expandedResponses[item.node.correlationId]"
        [org]="org"
        [survey]="survey"
        [getQuery]="getQuery"
        [nodeCorrelationId]="item.node.correlationId"
        [integrations]="integrations"
        [removable]="true"
      >
      </survey-stats-individual-responses-answers-table-single-question>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="item._node.isOpenEndedQuestion">
    <survey-stats-individual-responses-range-single-question
      *ngIf="item._node.questionType === 'range'"
      [org]="org"
      [survey]="survey"
      [node]="item._node"
    ></survey-stats-individual-responses-range-single-question>

    <survey-stats-individual-responses-answers-table-single-question
      [org]="org"
      [survey]="survey"
      [getQuery]="getQuery"
      [nodeCorrelationId]="item.node.correlationId"
      [integrations]="integrations"
      [removable]="true"
    >
    </survey-stats-individual-responses-answers-table-single-question>
  </ng-container>
</ng-template>
