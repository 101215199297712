/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, Output } from "@angular/core";

import { NotificationHelper } from "helpers/notification.helper";
import {
  OrgBilling,
  OrgBillingPaymentMethods,
  OrgBillingPaymentMethodsSource,
} from "models/org_billing.model";
import { lateralPanelMode } from "../products";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";

@Component({
  selector: "settings-billing-tab-payment",
  templateUrl: "./tab-payment.component.html",
  styleUrls: ["./tab-payment.component.scss"],
})
export class SettingsBillingTabPaymentComponent {
  @Input() superOrg: SuperOrg = null;
  @Input() orgBilling: OrgBilling = null;
  @Input() orgBillingPaymentMethods: OrgBillingPaymentMethods = null;
  @Input() countries: string[][] = null;

  @Output() orgBillingPaymentMethodsChange =
    new EventEmitter<OrgBillingPaymentMethods>();
  @Output() onUpdate = new EventEmitter<lateralPanelMode>();

  public loadingPaymentMethodChange: string | null = null;
  public loadingPaymentMethodRemove: string | null = null;

  constructor(
    private notificationHelper: NotificationHelper,
    private superOrgDao: SuperOrgDao,
  ) {}

  public changeDefaultPaymentMethod(paymentMethodId: string) {
    this.loadingPaymentMethodChange = paymentMethodId;

    this.superOrgDao
      .updateDefaultPaymentMethod(this.superOrg.id, paymentMethodId)
      .then((orgBillingPaymentMethods: OrgBillingPaymentMethods) => {
        this.orgBillingPaymentMethods = orgBillingPaymentMethods;
        this.orgBillingPaymentMethodsChange.emit(orgBillingPaymentMethods);
        this.loadingPaymentMethodChange = null;
      })
      .catch((err) => {
        this.notificationHelper.trigger(
          "Could not save default payment method.",
          null,
          "error",
        );
        console.error(err);
        this.loadingPaymentMethodChange = null;
      });
  }

  public removePaymentMethod(paymentMethodId: string) {
    this.loadingPaymentMethodRemove = paymentMethodId;

    this.superOrgDao
      .removePaymentMethod(this.superOrg.id, paymentMethodId)
      .then((orgBillingPaymentMethods: OrgBillingPaymentMethods) => {
        this.orgBillingPaymentMethods = orgBillingPaymentMethods;
        this.orgBillingPaymentMethodsChange.emit(orgBillingPaymentMethods);
        this.loadingPaymentMethodRemove = null;
      })
      .catch((err) => {
        this.notificationHelper.trigger(
          "Could not remove payment method.",
          null,
          "error",
        );
        console.error(err);
        this.loadingPaymentMethodRemove = null;
      });
  }

  public isoCountry2ToLabel(iso: string): string {
    return this.countries.find((c) => c[0] === iso)?.[1];
  }

  public isCardExpired(paymentMethod: OrgBillingPaymentMethodsSource): boolean {
    const now = new Date();

    if (paymentMethod.card.exp_year < now.getFullYear()) {
      return true;
    } else if (
      paymentMethod.card.exp_year === now.getFullYear() &&
      paymentMethod.card.exp_month < now.getMonth() + 1
    ) {
      return true;
    }

    return false;
  }
}
