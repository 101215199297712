import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";

import { AnalyticsDao } from "models/analytics.dao";
import {
  AnalyticsResponse,
  AnalyticsResponseItemUser,
  getFormattedUserNameOrID,
} from "models/analytics.model";
import { Org } from "models/org.model";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";
import { UUID } from "models/survey.dao.types";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { NotificationHelper } from "helpers/notification.helper";
import { Debounce } from "utils/debounce";
import { AnalyticsQuery } from "models/analytics.filters.type";

@Component({
  selector: "rule-user-alias",
  templateUrl: "./rule-user-alias.component.html",
  styleUrls: ["./rule-user-alias.component.scss"],
})
export class TargetingRuleUserAliasComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public rule: SurveyTargetingRule = null;

  public options: AnalyticsResponseItemUser[] = [];
  public initial: AnalyticsResponseItemUser[] = [];
  public isLoading: boolean = false;

  public getFormattedUserNameOrID = getFormattedUserNameOrID;

  constructor(
    private analyticsDao: AnalyticsDao,
    private analyticsFilterService: AnalyticsFilterService,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit(): void {
    this.searchByUserIds(this.rule.value.v_s_arr).then((users) => {
      this.initial = users;
    });
  }

  @Debounce(300)
  public async onSearch(keyword: string) {
    this.options = await this.searchByKeyword(keyword);
  }

  private searchByKeyword(
    keyword: string,
  ): Promise<AnalyticsResponseItemUser[]> {
    const query = this.analyticsFilterService.getInitialFilters(
      "respondent",
      UUID(this.org.id),
      "AND",
      null,
      this.org.created_at,
      new Date(),
    );

    if (query.type !== "respondent") {
      throw new Error("should be a respondent query");
    }

    query.range.start = this.org.created_at;
    query.range.end = new Date();

    query.size = 50;
    query.offset = 0;
    query.sort = {
      field: "respondent.last_activity_at",
      order: "desc",
    };

    query.identified_only = false;
    query.keyword = keyword.trim();

    return this.search(query);
  }

  private searchByUserIds(
    userIds: string[],
  ): Promise<AnalyticsResponseItemUser[]> {
    if (userIds.length === 0) {
      return Promise.resolve([]);
    }

    const query = this.analyticsFilterService.getInitialFilters(
      "respondent",
      UUID(this.org.id),
      "OR",
      null,
      this.org.created_at,
      new Date(),
    );

    if (query.type !== "respondent") {
      throw new Error("should be a respondent query");
    }

    query.range.start = this.org.created_at;
    query.range.end = new Date();

    query.size = 50;
    query.offset = 0;
    query.sort = {
      field: "respondent.last_activity_at",
      order: "desc",
    };

    query.filters = userIds.map((userId) => {
      return {
        type: "respondent",
        key: "aliases",
        operator: "eq",
        value: userId,
      };
    });

    query.identified_only = false;
    query.keyword = null;

    return this.search(query);
  }

  private search(query: AnalyticsQuery): Promise<AnalyticsResponseItemUser[]> {
    this.options = [];
    this.isLoading = true;

    return this.analyticsDao
      .search(query)
      .then((res: AnalyticsResponse) => {
        return res?.hits?.respondents || [];
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.notificationHelper.trigger("Failed to search user", null, "error");
        return [];
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
