<button
  nz-button
  nzType="default"
  nzSize="large"
  nzTrigger="click"
  nz-dropdown
  [nzDropdownMenu]="dropdown"
  nzPlacement="bottomRight"
>
  <i nz-icon nzType="export" nzTheme="outline"></i>
  Export
</button>
<nz-dropdown-menu
  nzOverlayClassName="export-button-more"
  #dropdown="nzDropdownMenu"
>
  <ul class="export-button-more" nz-menu>
    <li nz-menu-item>
      <a
        [href]="getCsvExportURL(false)"
        download="export.csv"
        (click)="onExportAllClick()"
        >Export all</a
      >
    </li>
    <li nz-menu-item>
      <a
        [href]="getCsvExportURL(true)"
        download="export.csv"
        (click)="onExportWithFiltersClick()"
        >Export with filters</a
      >
    </li>
  </ul>
</nz-dropdown-menu>
