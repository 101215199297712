import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { NotificationHelper } from "helpers/notification.helper";
import { Integration } from "models/integrations.model";
import { IntegrationDao } from "models/integration.dao";

@Injectable()
export class OrgIntegrationsResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private integrationDao: IntegrationDao,
    private notificationHelper: NotificationHelper,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Integration[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.integrationDao.getIntegrations(route.params["org_id"]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}

@Injectable()
export class OrgIntegrationResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private integrationDao: IntegrationDao,
    private notificationHelper: NotificationHelper,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Integration> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.integrationDao.getIntegration(
          route.params["org_id"],
          route.params["integration_slug"],
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}

@Injectable()
export class OrgIntegrationOrNullResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private integrationDao: IntegrationDao,
    private notificationHelper: NotificationHelper,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Integration> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.integrationDao.getIntegration(
          route.params["org_id"],
          route.params["integration_slug"],
        );
      })
      .catch((err: HttpErrorResponse) => {
        if (err.status === 404) {
          return null;
        }
        console.error(err.error);
        throw err;
      });
  }
}
