<div nz-row [nzGutter]="[0, 0]">
  <div nz-col [nzSpan]="24">
    <p>
      Display the {{ uiService.isMessagePage ? "message" : "survey" }} by
      executing a command from Screeb SDK (ideal if you want to display the
      {{ uiService.isMessagePage ? "message" : "survey" }} after a click on a
      button, for example)
    </p>
  </div>

  <div nz-col [nzSpan]="24">
    <h3>
      Show the {{ uiService.isMessagePage ? "message" : "survey" }} on the
      following pages
    </h3>

    <nz-radio-group [ngModel]="true" (ngModelChange)="recurrencyChange($event)">
      <big-radio [nzValue]="true" label="Every time"> </big-radio>
      <big-radio [nzValue]="false" label="Only once"></big-radio>
    </nz-radio-group>
  </div>

  <div nz-col [nzSpan]="24">
    <p>
      Use this code below on any element of your app to trigger the display of
      the {{ uiService.isMessagePage ? "message" : "survey" }}:
    </p>

    <div class="installation-code-container">
      <code class="installation-code" [highlight]="code"> </code>
      <button
        class="copy-button"
        nz-button
        nzSize="medium"
        nzType="primary"
        (click)="clipboardCopy(code)"
      >
        Copy
      </button>
    </div>

    <p>
      You want to link data to this
      {{ uiService.isMessagePage ? "message" : "survey" }} responses?
      <a [href]="getDocURL()" target="_blank"> Read our doc here </a>
    </p>
  </div>
</div>
