import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { stringToHash } from "utils/string";
import {
  IntegrationSettingsCobbai,
  IntegrationSettingsWebhook,
  IntegrationSettingsZapier,
} from "./integrations.model";
import { APISurvey, SurveyLanguages, SurveyScenario } from "./survey.dao.types";
import {
  IntegrationSettingsReports,
  ShareReportType,
  Survey,
  SurveyType,
} from "./survey.model";

type ScenarioQuestionSuggestion = {
  question: string;
  explanation: string;
};

@Injectable()
class SurveyDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getAllByOrgId(
    type: SurveyType,
    orgId: string,
    withScenario?: boolean,
    withTargetingRules?: boolean,
    fromOverview: boolean = false,
  ): Promise<Survey[]> {
    const params = new URLSearchParams({
      type,
      with_scenario: String(withScenario ?? false),
      with_targeting_rules: String(withTargetingRules ?? false),
    });

    if (fromOverview) {
      params.append("overview", "true");
    }

    return this.screebApiHelper
      .get<Survey[]>(`/org/${orgId}/survey?${params.toString()}`)
      .toPromise()
      .then((data: Survey[]) => {
        return data.map((survey: object) => new Survey().fromJson(survey));
      });
  }
  public getById(
    orgId: string,
    id: string,
    scenarioId?: string,
  ): Promise<Survey> {
    const params = new URLSearchParams({
      scenario_id: scenarioId ?? "",
    });

    return this.screebApiHelper
      .get<APISurvey>(`/org/${orgId}/survey/${id}?${params.toString()}`)
      .toPromise()
      .then((data: object) => {
        return new Survey().fromJson(data);
      });
  }

  private _create(
    orgId: string,
    type: SurveyType,
    title: string,
    duplicate_survey_id: string | null,
    duplicate_workspace_target_id: string | null,
    language: string | null,
    sequence_id?: string,
  ): Promise<Survey> {
    const body = {
      type,
      title,
      duplicate_survey_id,
      duplicate_workspace_target_id,
      language,
      sequence_id,
    };
    return this.screebApiHelper
      .post<Survey>(`/org/${orgId}/survey`, body)
      .toPromise()
      .then((data: Survey) => {
        return new Survey().fromJson(data);
      });
  }
  public create(
    orgId: string,
    type: SurveyType,
    title: string,
    language: SurveyLanguages,
    sequenceId?: string,
  ) {
    return this._create(orgId, type, title, null, null, language, sequenceId);
  }
  public duplicate(
    orgId: string,
    targetWorkspaceId: string,
    type: SurveyType,
    title: string,
    surveyId: string,
  ) {
    return this._create(orgId, type, title, surveyId, targetWorkspaceId, null);
  }

  public updateTitle(orgId: string, surveyId: string, title: string) {
    return this.screebApiHelper
      .patch<Survey>(`/org/${orgId}/survey/${surveyId}`, { title })
      .toPromise()
      .then((data: Survey) => {
        return new Survey().fromJson(data);
      });
  }
  public updateFlow(
    orgId: string,
    surveyId: string,
    scenario: SurveyScenario,
    isImported: boolean = false,
  ): Promise<Survey> {
    const body = { scenario, is_imported: isImported };

    return this.screebApiHelper
      .patch<Survey>(`/org/${orgId}/survey/${surveyId}`, body)
      .toPromise()
      .then((data: Survey) => {
        return new Survey().fromJson(data);
      });
  }

  public async updateIntegrations(
    orgId: string,
    surveyId: string,
    reportType: ShareReportType,
    integrations: {
      webhook?: IntegrationSettingsWebhook;
      zapier?: IntegrationSettingsZapier;
      cobbai?: IntegrationSettingsCobbai;
      reports?: IntegrationSettingsReports;
    },
  ) {
    const body = {
      integrations,
      triggerReporting: reportType,
    };

    return await this.screebApiHelper
      .patch<object>(`/org/${orgId}/survey/${surveyId}`, body)
      .toPromise();
  }

  public delete(orgId: string, surveyId: string) {
    return this.screebApiHelper
      .delete<object>(`/org/${orgId}/survey/${surveyId}`)
      .toPromise()
      .then((data: object) => {
        return data;
      });
  }

  public generateScenarioQuestionSuggestions(
    orgId: string,
    surveyId: string,
    query: string,
  ) {
    return this.screebApiHelper
      .post<ScenarioQuestionSuggestion[]>(
        `/org/${orgId}/survey/${surveyId}/scenario/question-suggestion`,
        { query },
      )
      .toPromise()
      .then((data: object) => {
        return data["suggestions"].map(
          (suggestion: object) => suggestion as ScenarioQuestionSuggestion,
        );
      });
  }

  public generateScenario(orgId: string, surveyId: string, query: string) {
    return this.screebApiHelper
      .post<
        ScenarioQuestionSuggestion[]
      >(`/org/${orgId}/survey/${surveyId}/scenario/generate`, { query })
      .toPromise();
  }

  public generateScenarioPublic(query: string) {
    const result = localStorage.getItem(
      "screeb-generate-" + stringToHash(query),
    );

    if (result) {
      return Promise.resolve(JSON.parse(result));
    }

    return this.screebApiHelper
      .post<ScenarioQuestionSuggestion[]>("/generate", { query })
      .toPromise();
  }
}

export { ScenarioQuestionSuggestion, SurveyDao };
