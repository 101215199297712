import { ChartDataset } from "chart.js";
import { AnalyticsResponse } from "models/analytics.model";
import { SurveyScenario } from "models/survey.dao.types";
import { formatNumber } from "utils/number";
import { RepartitionIndicatorValues } from "../components/repartition-indicator/repartition-indicator.component";
import {
  bucketToCountPerKey,
  computeIndicatorVariation,
  computeRepartition,
  computeVariation,
  getDateAnswerValueBuckets,
  replaceBucketsKeys,
} from "../indicator.utils";

export type CsatAggregation = {
  score: number;
  scoreOnFive: number;
  lowScore: number;
  promoters: number;
  passives: number;
  detractors: number;
  total: number;
  date?: Date;
};

export const csatEmojis = ["😡", "😒", "😐", "😄", "😍"];

export function computeCsatRepartition(
  periodBucket: AnalyticsResponse,
  scenario: SurveyScenario,
): RepartitionIndicatorValues {
  const getColor = (index: number) => {
    const style = window.getComputedStyle(document.body);
    if (index <= 1) {
      return style.getPropertyValue("--screeb-color-red-500");
    } else if (index <= 2) {
      return style.getPropertyValue("--screeb-color-yellow-500");
    }
    return style.getPropertyValue("--screeb-color-green-500");
  };

  return computeRepartition(periodBucket, csatEmojis, getColor, scenario);
}

export function computeCsatPeriod(
  csatPerDate: CsatAggregation[],
): CsatAggregation {
  const total = csatPerDate.reduce(
    (acc: number, csat: CsatAggregation): number => acc + csat.total,
    0,
  );
  const detractors = csatPerDate.reduce(
    (acc: number, csat: CsatAggregation): number => acc + csat.detractors,
    0,
  );
  const passives = csatPerDate.reduce(
    (acc: number, csat: CsatAggregation): number => acc + csat.passives,
    0,
  );
  const promoters = csatPerDate.reduce(
    (acc: number, csat: CsatAggregation): number => acc + csat.promoters,
    0,
  );
  const score = computeCsatScore(promoters, total);
  const scoreOnFive = computeCsatScoreOnFive(score);
  const lowScore = computeCsatScore(detractors, total);

  return {
    total,
    detractors,
    passives,
    promoters,
    score,
    scoreOnFive,
    lowScore,
  };
}

export function computeCsatVariationBewteenPeriod(
  previousPeriod: CsatAggregation,
  currentPeriod: CsatAggregation,
): CsatAggregation {
  if (!previousPeriod.total) {
    return null;
  }

  return {
    score: currentPeriod.score - previousPeriod.score,
    scoreOnFive: currentPeriod.scoreOnFive - previousPeriod.scoreOnFive,
    lowScore: currentPeriod.lowScore - previousPeriod.lowScore,
    detractors: computeIndicatorVariation(
      previousPeriod.detractors,
      previousPeriod.total,
      currentPeriod.detractors,
      currentPeriod.total,
    ),
    passives: computeIndicatorVariation(
      previousPeriod.passives,
      previousPeriod.total,
      currentPeriod.passives,
      currentPeriod.total,
    ),
    promoters: computeIndicatorVariation(
      previousPeriod.promoters,
      previousPeriod.total,
      currentPeriod.promoters,
      currentPeriod.total,
    ),
    total: computeVariation(previousPeriod.total, currentPeriod.total),
  };
}

export function bucketToCsatAggregation(
  oneDayCsat: Record<string, any>,
  getBuckets = getDateAnswerValueBuckets,
  scenario: SurveyScenario,
): CsatAggregation {
  const { countPerKey, total } = bucketToCountPerKey(
    replaceBucketsKeys(getBuckets(oneDayCsat), [scenario]),
    csatEmojis,
  );

  const date = oneDayCsat.date;

  const promoters = countPerKey["😍"] + countPerKey["😄"];
  const passives = countPerKey["😐"];
  const detractors = countPerKey["😡"] + countPerKey["😒"];

  const score = computeCsatScore(promoters, total);

  return {
    lowScore: computeCsatScore(detractors, total),
    score,
    scoreOnFive: computeCsatScoreOnFive(score),

    promoters,
    passives,
    detractors,
    total,
    date,
  };
}

export function csatPerDateToChartDataset(
  csatPerDate: CsatAggregation[],
): ChartDataset[] {
  const csatToData = (field: "score" | "lowScore") =>
    csatPerDate.map((csat) => {
      return { x: +csat.date, y: csat[field] };
    });

  const style: ChartDataset = {
    yAxisID: "main",
    fill: "transparent",
    data: [],
  };

  return [
    {
      ...style,
      data: csatToData("lowScore"),
      label: "Average low scores (1 to 2)",
      borderColor: "#F11672",
      pointBackgroundColor: "#F11672",
      pointHoverBorderColor: "#FFFFFF",
      pointHoverBackgroundColor: "#F11672",
    },
    {
      ...style,
      data: csatToData("score"),
      label: "Average satisfaction",
      borderColor: "#5E21F1",
      pointBackgroundColor: "#5E21F1",
      pointHoverBorderColor: "#FFFFFF",
      pointHoverBackgroundColor: "#5E21F1",
    },
  ];
}

export function computeCsatScoreOnFive(score: number) {
  return (score * 5) / 100;
}

export function computeCsatScore(
  responsesCount: number,
  totalResponsesCount: number,
) {
  if (!totalResponsesCount) {
    return 0;
  }
  return (responsesCount / totalResponsesCount) * 100;
}

export function computeHappyUsersPercent(csatCurrentPeriod: CsatAggregation) {
  if (!csatCurrentPeriod.total) {
    return 0;
  }
  return (csatCurrentPeriod.promoters * 100) / csatCurrentPeriod.total;
}

export function computeCsatHappyUsers(csatCurrentPeriod: CsatAggregation) {
  return formatNumber(computeHappyUsersPercent(csatCurrentPeriod), "percent");
}
