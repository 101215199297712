<nz-table
  #nzTable
  [nzData]="nodes"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
  nzTableLayout="fixed"
>
  <thead>
    <tr>
      <th nzWidth="110px"></th>
      <th
        [nzSortDirections]="['ascend', 'descend']"
        [nzSortOrder]="questionOrder"
        (nzSortOrderChange)="onSortChange('index', $event)"
      >
        Questions
      </th>
      <!-- <th>Views</th> -->
      <!-- <th>Responses</th> -->
      <th nzWidth="100px"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let node of nzTable.data; let i = index">
      <!-- this works as a border // visible only when current question or q-1 is opened -->
      <tr
        class="question-opened-separator"
        [ngClass]="{
          visible:
            !!expandedNodes[node.node.id] ||
            (i > 0 && !!nzTable.data[i - 1]?.expanded)
        }"
      >
        <td></td>
        <!-- <td></td>
                <td></td> -->
        <td></td>
        <td></td>
      </tr>
      <tr
        class="question-header"
        [ngClass]="{ opened: !!expandedNodes[node.node.id] }"
        (click)="expandedNodes[node.node.id] = !expandedNodes[node.node.id]"
      >
        <td nzAlign="center">
          <card-type
            [questionType]="node.node.type"
            [letter]="node.node.letter"
            [vertical]="false"
          ></card-type>
        </td>
        <td>
          <div class="question-title">
            <span class="question-type">{{ getCardType(node._node) }}</span>
            <sanitized-message-component
              class="question-title-text"
              [content]="node.node.text"
              [options]="{ links: true, styling: true, emojiTranscoder: true }"
            ></sanitized-message-component>
            <div
              class="answers-count"
              *ngIf="responsesCount?.[node.node.correlationId]"
            >
              {{ responsesCount[node.node.correlationId] }} responses
            </div>
          </div>
          <div class="survey-name" *ngIf="displaySurveyTitle">
            {{ node.survey.title }}
          </div>
        </td>
        <!-- <td>42</td> -->
        <!-- <td>42</td> -->
        <td nzAlign="center" class="actions">
          <button
            *ngIf="!expandedNodes[node.node.id]"
            nz-button
            nzType="default"
          >
            <i nz-icon nzType="down"></i>
          </button>
          <button
            *ngIf="!!expandedNodes[node.node.id]"
            class="ant-btn-secondary"
            nz-button
            nzType="default"
          >
            <i nz-icon nzType="up"></i>
          </button>
        </td>
      </tr>
      <tr class="question-body" *ngIf="!!expandedNodes[node.node.id]">
        <td colspan="3">
          <ng-container
            *ngTemplateOutlet="questionBodyView; context: { item: node }"
          ></ng-container>
        </td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>
