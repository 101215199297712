<div class="FlowTag{{ messageURL?.length ? ' is-message' : '' }}">
  <ng-container *ngIf="!messageURL?.length">
    {{ label }}
  </ng-container>
  <div class="message-preview" *ngIf="messageURL?.length">
    <screeb-icon size="sm" icon="link"></screeb-icon>
    <p>{{ messageURL }}</p>
    <div class="is-spacer"></div>
    <button
      nz-button
      nzType="primary"
      nzSize="large"
      (click)="messageURLChange.emit()"
    >
      Edit
    </button>
  </div>
</div>
