<div class="stats-header">
  <free-trial-banner />
  <header class="iAppear">
    <h1>{{ uiService.isMessagePage ? "Message" : "Survey" }} analytics</h1>

    <div class="analytics-filters">
      <survey-stats-filters
        reportType="survey-analytics"
        [canExport]="false"
        [org]="org"
        [survey]="survey"
        [reporting]="
          (uiService.isMessagePage ? 'Message' : 'Survey') + ' analytics'
        "
        [languages]="languages"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesGroup]="registryEntriesGroup"
        [registryEntriesEvent]="registryEntriesEvent"
        type="response"
      >
      </survey-stats-filters>
    </div>
  </header>

  <div class="loader" *ngIf="loading && initialFetch">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>

  <error-message
    *ngIf="error"
    message="An error occured when fetching data."
    [details]="error.message"
    (reload)="analyticsFilterService.triggerChange()"
  ></error-message>

  <nz-empty
    *ngIf="
      (!loading || !initialFetch) &&
      !error &&
      !overallPerformancesCurrentPeriod?.totalDisplays
    "
    [nzNotFoundContent]="'No data during this interval'"
  ></nz-empty>

  <section
    *ngIf="
      (!loading || !initialFetch) &&
      !error &&
      !!overallPerformancesCurrentPeriod?.totalDisplays
    "
    class="top-indicators gridAppear"
  >
    <div class="top-indicators__left">
      <div class="card-background">
        <survey-stats-indicators-single-indicator
          title="Total number of displays"
          [value]="overallPerformancesCurrentPeriod.totalDisplays"
          [diffPercent]="overallPerformancesVariation?.totalDisplays"
          format="number"
          diffFormat="percent"
          imageSrc="/assets/illustrations/displays.png"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div class="card-background">
        <survey-stats-indicators-single-indicator
          title="Total number of responses"
          [value]="overallPerformancesCurrentPeriod.totalResponses"
          [diffPercent]="overallPerformancesVariation?.totalResponses"
          format="number"
          diffFormat="percent"
          imageSrc="/assets/illustrations/responses.png"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div class="card-background">
        <survey-stats-indicators-single-indicator
          title="Response rate"
          [value]="overallPerformancesCurrentPeriod.responseRate"
          [diffPercent]="overallPerformancesVariation?.responseRate"
          format="percent"
          diffFormat="percent"
          [diffIndustryAverage]="
            overallPerformancesCurrentPeriod.responseRate -
            industryAverageResponseRate
          "
          imageSrc="/assets/illustrations/response-rate.png"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div class="card-background">
        <survey-stats-indicators-single-indicator
          title="Completion rate"
          [value]="overallPerformancesCurrentPeriod.completionRate"
          [diffPercent]="overallPerformancesVariation?.completionRate"
          format="percent"
          diffFormat="percent"
          imageSrc="/assets/illustrations/completion.png"
        >
        </survey-stats-indicators-single-indicator>
      </div>
    </div>
    <div class="top-indicators__right">
      <div class="card-background">
        <survey-stats-indicators-radar-indicator
          [labels]="[
            '😡 Anger',
            '&nbsp;😄 Joy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;',
            '😱 Fear',
            '😢 Sadness'
          ]"
          [legend]="emotionsChartLegend"
          [datasets]="emotionsChartDatasets"
          [loading]="loadingEmotions"
        />
      </div>
    </div>
  </section>
</div>
<section
  *ngIf="
    (!loading || !initialFetch) &&
    !error &&
    !!overallPerformancesCurrentPeriod?.totalDisplays
  "
  class="gridAppear"
  nz-row
  [nzGutter]="[32, 16]"
>
  <div nz-col nzSpan="24">
    <div
      class="indicator-with-border iAppear d100"
      *ngIf="
        !overallPerformancesPerDateCurrentPeriod ||
        overallPerformancesPerDateCurrentPeriod.length <= 1
      "
    >
      <nz-empty
        [nzNotFoundContent]="'Please select a longer period to display trend'"
      ></nz-empty>
    </div>
    <div
      class="indicator-with-border trend-indicator iAppear d200"
      *ngIf="
        overallPerformancesPerDateCurrentPeriod &&
        overallPerformancesPerDateCurrentPeriod.length > 1
      "
    >
      <survey-stats-indicators-trend-indicator
        theme="overall"
        [datasets]="trendChartDataset"
        [legend]="trendChartLegend"
        [releases]="releases"
        [orgAccounts]="orgAccounts"
        [orgId]="org.id"
      >
      </survey-stats-indicators-trend-indicator>
    </div>
  </div>
</section>

<section
  *ngIf="
    (!loading || !initialFetch) &&
    !error &&
    !!overallPerformancesCurrentPeriod?.totalDisplays
  "
  class="gridAppear"
  nz-row
  [nzGutter]="[32, 16]"
>
  <div nz-col nzOrder="1" nzXs="24" [nzXXl]="{ span: 14, order: 0 }">
    <section class="bottom-indicators" nz-row [nzGutter]="[32, 16]">
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="Current status of the {{
            uiService.isMessagePage ? 'message' : 'survey'
          }}"
          [value]="surveyStats.currentStatus"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="Last edit"
          [value]="surveyStats.lastEdit"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8"></div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="💻 Displays on Desktop"
          [value]="overallPerformancesCurrentPeriod.desktop.displays"
          [diffPercent]="overallPerformancesVariation?.desktop.displays"
          format="number"
          diffFormat="percent"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="🍫 Displays on Tablet"
          [value]="overallPerformancesCurrentPeriod.tablet.displays"
          [diffPercent]="overallPerformancesVariation?.tablet.displays"
          format="number"
          diffFormat="percent"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="📱 Displays on Mobile"
          [value]="overallPerformancesCurrentPeriod.mobile.displays"
          [diffPercent]="overallPerformancesVariation?.mobile.displays"
          format="number"
          diffFormat="percent"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="💻 Responses on Desktop"
          [value]="overallPerformancesCurrentPeriod.desktop.responses"
          [diffPercent]="overallPerformancesVariation?.desktop.responses"
          format="number"
          diffFormat="percent"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="🍫 Responses on Tablet"
          [value]="overallPerformancesCurrentPeriod.tablet.responses"
          [diffPercent]="overallPerformancesVariation?.tablet.responses"
          format="number"
          diffFormat="percent"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="📱 Responses on Mobile"
          [value]="overallPerformancesCurrentPeriod.mobile.responses"
          [diffPercent]="overallPerformancesVariation?.mobile.responses"
          format="number"
          diffFormat="percent"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="💻 Response rate on Desktop"
          [value]="overallPerformancesCurrentPeriod.desktop.responseRate"
          [diffPercent]="overallPerformancesVariation?.desktop.responseRate"
          format="percent"
          diffFormat="percent"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="🍫 Response rate on Tablet"
          [value]="overallPerformancesCurrentPeriod.tablet.responseRate"
          [diffPercent]="overallPerformancesVariation?.tablet.responseRate"
          format="percent"
          diffFormat="percent"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="📱 Response rate on Mobile"
          [value]="overallPerformancesCurrentPeriod.mobile.responseRate"
          [diffPercent]="overallPerformancesVariation?.mobile.responseRate"
          format="percent"
          diffFormat="percent"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="Date of first response"
          [value]="overallPerformancesCurrentPeriod.firstResponseDate"
        >
        </survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzXs="24" nzLg="8">
        <survey-stats-indicators-single-indicator
          title="Date of last response"
          [value]="overallPerformancesCurrentPeriod.lastResponseDate"
          dateFormat="distance-from-now"
        >
        </survey-stats-indicators-single-indicator>
      </div>
    </section>
  </div>
  <div nz-col nzXs="24" nzXXl="10">
    <section nz-row [nzGutter]="[32, 16]">
      <div nz-col nzXs="24" nzLg="12" nzXXl="24">
        <card-101-link
          title="How To Get A Great Response Rate For Your In-App Surveys - The Complete Guide"
          minuteRead="6"
          url="https://screeb.app/how-to-get-a-great-response-rate-for-your-in-app-surveys-the-complete-guide/"
          color="var(--screeb-color-success-outline)"
        ></card-101-link>
      </div>
      <div nz-col nzXs="24" nzLg="12" nzXXl="24">
        <card-101-link
          title="How to use segments to identify insightful feedback? (Video)"
          minuteRead="3"
          url="https://help.screeb.app/en/articles/5385254-how-to-use-segments-to-identify-insightful-feedback"
          color="var(--screeb-color-primary-outline)"
        ></card-101-link>
      </div>
    </section>
  </div>
</section>
