<div>
  <header class="iAppear">
    <h1>Customer Effort Score</h1>

    <div class="analytics-filters" *ngIf="!!nodes.length">
      <survey-stats-filters
        reportType="ces"
        [canExport]="false"
        [org]="org"
        [survey]="survey"
        [reporting]="'Customer Effort Score'"
        [languages]="languages"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesGroup]="registryEntriesGroup"
        [registryEntriesEvent]="registryEntriesEvent"
        type="response"
      >
      </survey-stats-filters>
    </div>
  </header>

  <survey-stats-empty-dashboard
    class="iAppear d100"
    *ngIf="nodes.length === 0"
    imgPath="/assets/survey-templates/CES.png"
    title="You don’t collect CES in this {{
      uiService.isMessagePage ? 'message' : 'survey'
    }} yet"
    description1="Customer Effort Score is a great and simple way to measure how much effort your customers gave to make to do an action. Use it to identify improvements to make to your experience!"
    primaryButtonTxt="Add CES to this {{
      uiService.isMessagePage ? 'message' : 'survey'
    }}"
    (primaryButtonCTA)="redirectBuilder()"
    secondaryButtonTxt="Preview this {{
      uiService.isMessagePage ? 'message' : 'survey'
    }}"
    (secondaryButtonCTA)="redirectBuilder()"
  >
  </survey-stats-empty-dashboard>

  <ng-container *ngIf="!!nodes.length">
    <div class="loader" *ngIf="loading && initialFetch">
      <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
    </div>

    <error-message
      *ngIf="error"
      message="An error occured when fetching data."
      [details]="error.message"
      (reload)="analyticsFilterService.triggerChange()"
    >
    </error-message>

    <div class="gridAppear" *ngIf="(!loading || !initialFetch) && !error">
      <nz-empty
        *ngIf="
          !cesCurrentPeriod ||
          cesCurrentPeriod.total === 0 ||
          !cesCurrentPeriod.score
        "
        [nzNotFoundContent]="'No answer during this interval'"
      ></nz-empty>

      <ng-container
        *ngIf="
          cesCurrentPeriod &&
          cesCurrentPeriod.total > 0 &&
          cesCurrentPeriod.score
        "
      >
        <section
          nz-row
          [nzGutter]="[32, 16]"
          class="top-indicators"
          *ngIf="!!cesCurrentPeriod"
        >
          <div nz-col nzXs="24" nzXl="12" nzXXl="6">
            <survey-stats-indicators-single-indicator
              class="indicator-with-border"
              title="Score"
              [value]="cesCurrentPeriod.score"
              [diffPercent]="cesVariation?.score"
              format="number"
              diffFormat="percent"
              [maximumFractionDigits]="1"
              [scoreMax]="7"
              imageSrc="/assets/indicators/CES-indicator-score.png"
            >
            </survey-stats-indicators-single-indicator>
          </div>
          <div nz-col nzXs="24" nzXl="12" nzXXl="6">
            <survey-stats-indicators-single-indicator
              class="indicator-with-border"
              title="Small effort"
              [value]="cesCurrentPeriod.smallEffortPercent"
              [diffPercent]="cesVariation?.smallEffortPercent"
              format="percent"
              diffFormat="percent"
              imageSrc="/assets/indicators/CES-indicator-small-effort.png"
            >
            </survey-stats-indicators-single-indicator>
          </div>
          <div nz-col nzXs="24" nzXl="12" nzXXl="6">
            <survey-stats-indicators-single-indicator
              class="indicator-with-border"
              title="Medium effort"
              [value]="cesCurrentPeriod.mediumEffortPercent"
              [diffPercent]="cesVariation?.mediumEffortPercent"
              format="percent"
              diffFormat="percent"
              imageSrc="/assets/indicators/CES-indicator-medium-effort.png"
            >
            </survey-stats-indicators-single-indicator>
          </div>
          <div nz-col nzXs="24" nzXl="12" nzXXl="6">
            <survey-stats-indicators-single-indicator
              class="indicator-with-border"
              title="High effort"
              [value]="cesCurrentPeriod.highEffortPercent"
              [diffPercent]="cesVariation?.highEffortPercent"
              format="percent"
              diffFormat="percent"
              imageSrc="/assets/indicators/CES-indicator-high-effort.png"
            >
            </survey-stats-indicators-single-indicator>
          </div>
        </section>

        <section
          nz-row
          [nzGutter]="[32, 16]"
          class="middle-indicators"
          *ngIf="!!cesCurrentPeriod"
        >
          <div nz-col class="fullsize-indicator" nzSpan="24" nzXl="10">
            <h2>You’re right on track!</h2>
            <survey-stats-indicators-vu-meter-indicator
              [value]="cesCurrentPeriod.score"
              [ideal]="industryAverageCes"
              [min]="1"
              [max]="7"
              [mediumRangeStart]="4"
              [highRangeStart]="5.5"
              format="number"
              diffFormat="percent"
              [maximumFractionDigits]="1"
              class="indicator-with-border industry-diff-score"
            >
            </survey-stats-indicators-vu-meter-indicator>
          </div>
          <div nz-col nzFlex="1" class="fullsize-indicator">
            <h2>Results repartition</h2>
            <div class="indicator-with-border">
              <div class="repartition-container">
                <div class="repartition-chart">
                  <survey-stats-indicators-repartition-indicator
                    [values]="cesRepartition"
                    (barClick)="repartitionChartBarClick($event)"
                    emojiSet="ces"
                    chartWidth="300"
                  >
                  </survey-stats-indicators-repartition-indicator>
                </div>
                <div class="repartition-details">
                  <survey-stats-indicators-progress-indicator
                    (click)="repartitionProgressClicked('smallEffort')"
                    class="repartition-progress"
                    color="var(--screeb-color-success)"
                    label="Small effort"
                    [value]="cesCurrentPeriod.smallEffort"
                    [percentValue]="cesCurrentPeriod.smallEffortPercent"
                    [delay]="600"
                  >
                  </survey-stats-indicators-progress-indicator>
                  <survey-stats-indicators-progress-indicator
                    (click)="repartitionProgressClicked('mediumEffort')"
                    class="repartition-progress"
                    color="var(--screeb-color-warning)"
                    label="Medium effort"
                    [value]="cesCurrentPeriod.mediumEffort"
                    [percentValue]="cesCurrentPeriod.mediumEffortPercent"
                    [delay]="700"
                  >
                  </survey-stats-indicators-progress-indicator>
                  <survey-stats-indicators-progress-indicator
                    (click)="repartitionProgressClicked('highEffort')"
                    class="repartition-progress"
                    color="var(--screeb-color-error)"
                    label="High effort"
                    [value]="cesCurrentPeriod.highEffort"
                    [percentValue]="cesCurrentPeriod.highEffortPercent"
                    [delay]="800"
                  >
                  </survey-stats-indicators-progress-indicator>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section nz-row [nzGutter]="[32, 16]" *ngIf="!!cesPerDateCurrentPeriod">
          <div nz-col nzSpan="24">
            <h2>Trend</h2>
            <div
              class="indicator-with-border"
              *ngIf="
                !cesPerDateCurrentPeriod || cesPerDateCurrentPeriod.length <= 1
              "
            >
              <nz-empty
                [nzNotFoundContent]="
                  'Please select a longer period to display trend'
                "
              ></nz-empty>
            </div>
            <div
              class="indicator-with-border trend-indicator"
              *ngIf="
                cesPerDateCurrentPeriod && cesPerDateCurrentPeriod.length > 1
              "
            >
              <survey-stats-indicators-trend-indicator
                theme="ces"
                [datasets]="trendChartDataset"
                [legend]="trendChartLegend"
                [releases]="releases"
                [orgAccounts]="orgAccounts"
                [orgId]="org.id"
              >
              </survey-stats-indicators-trend-indicator>
            </div>
          </div>
        </section>
      </ng-container>
    </div>
  </ng-container>
</div>
