import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService, SessionService } from "services/auth.service";
import { NotificationHelper } from "helpers/notification.helper";
import { UIService } from "services/ui.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { Channel, ChannelType } from "models/channel.model";
import { ChannelDao } from "models/channel.dao";

type ChannelGroup = {
  name: string;
  channelType: ChannelType;
  label: string;
};

@Component({
  selector: "layout-back-sidebar-sharing",
  templateUrl: "./sidebar-sharing.component.html",
  styleUrls: ["./sidebar-sharing.component.scss"],
})
export class LayoutBackSidebarSharingComponent implements OnInit {
  public channels: Channel[] = [];

  public channelGroups: ChannelGroup[] = [
    {
      name: "website",
      channelType: "widget",
      label: "my web app",
    },
    {
      name: "android",
      channelType: "android",
      label: "my Android app",
    },
    {
      name: "ios",
      channelType: "ios",
      label: "my iOS app",
    },
    {
      name: "link",
      channelType: "hosted-page",
      label: "Share with a link",
    },
  ];

  constructor(
    public router: Router,
    private channelDao: ChannelDao,
    public authService: AuthService,
    public sessionService: SessionService,
    public notificationHelper: NotificationHelper,
    public uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  ngOnInit(): void {
    // If current survey is a message, we only want to show one channel
    if (this.uiService.currentSurvey?.type === "message") {
      const distribution =
        this.uiService.currentSurvey.survey_distributions.find(
          (d) => Number(d.updated_at) !== Number(d.created_at),
        );

      if (distribution) {
        this.channelGroups = this.channelGroups.filter(
          (channelGroup) => channelGroup.channelType === distribution.type,
        );
      }
    }
    // If current survey is in_page, we only want to show web channel
    if (this.uiService.currentSurvey?.interaction === "in_page") {
      this.channelGroups = this.channelGroups.filter(
        (channelGroup) => channelGroup.name === "website",
      );
    }

    this.channelDao
      .getAllByOrgId(this.uiService.currentOrgId)
      .then((channels) => {
        this.channels = channels;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getTitle(channelGroup: ChannelGroup, channelName: string) {
    const channels = this.getChannelsOfType(channelGroup.channelType);

    if (channelGroup.channelType === "hosted-page") {
      return channels.length ? channelGroup.label : channelName;
    }

    return `Share in ${channels.length ? channelGroup.label : channelName}`;
  }

  getChannelsOfType(type: ChannelType): Channel[] {
    return this.channels.filter((value) => value.type === type);
  }
}
