<nz-radio-group
  [ngModel]="interactionType"
  (ngModelChange)="interactionTypeChange.emit($event)"
  class="interaction-type-radio-group"
>
  <big-radio
    nzValue="in_app"
    label="Proactive In-App Survey"
    badgeText="Best response rate"
    [content]="surveyInteraction"
    [contentContext]="{
      label:
        'Get feedback at key moments of your user experience with proactive and engaging surveys',
      img:
        interactionType === 'in_app'
          ? '/assets/survey-interactions/proactive-activated.png'
          : '/assets/survey-interactions/proactive-desactivated.png',
      imgalt: 'proactive-activated'
    }"
  />
  <big-radio
    nzValue="in_page"
    label="In-Page Embedded Survey"
    [content]="surveyInteraction"
    [contentContext]="{
      label:
        'Let users provide inline and contextual feedback about specific content',
      img:
        interactionType === 'in_page'
          ? '/assets/survey-interactions/in-page-survey-activated.png'
          : '/assets/survey-interactions/in-page-survey-desactivated.png',
      imgalt: 'in-page-survey-activated'
    }"
  />
  <!-- <big-radio -->
  <!--   nzValue="feedback_button" -->
  <!--   label="Feedback Button" -->
  <!--   [content]="surveyInteraction" -->
  <!--   [contentContext]="{ -->
  <!--     label: -->
  <!--       'A persistent button on your app for people to give feedback at any time', -->
  <!--     img: -->
  <!--       interactionType === 'feedback_button' -->
  <!--         ? '/assets/survey-interactions/feedback-button-activated.png' -->
  <!--         : '/assets/survey-interactions/feedback-button-desactivated.png', -->
  <!--     imgalt: 'in-page-survey-activated' -->
  <!--   }" -->
  <!-- /> -->
  <!-- <big-radio -->
  <!--   nzValue="email" -->
  <!--   label="Email Survey" -->
  <!--   [content]="surveyInteraction" -->
  <!--   [contentContext]="{ -->
  <!--     label: -->
  <!--       'Get feedback from your users even when they’re not using your app', -->
  <!--     img: -->
  <!--       interactionType === 'email' -->
  <!--         ? '/assets/survey-interactions/email-survey-activated.png' -->
  <!--         : '/assets/survey-interactions/email-survey-desactivated.png', -->
  <!--     imgalt: 'in-page-survey-activated' -->
  <!--   }" -->
  <!-- /> -->
</nz-radio-group>

<ng-template
  #surveyInteraction
  let-label="label"
  let-img="img"
  let-imgalt="imgalt"
>
  <div class="survey-interaction-container">
    <p>
      {{ label }}
    </p>
    <img src="{{ img }}" alt="{{ imgalt }}" />
  </div>
</ng-template>
