<div class="half half-left">
  <div class="column">
    <h1>
      Welcome to the<br /><strong>Continuous<br />Product Discovery</strong>!
    </h1>

    <nz-card [nzBordered]="false" nzTitle="Login">
      <p class="center">
        "Connectivity is a human right."
        <br />
        <i>-- Mark Zuckerberg</i>
      </p>

      <div *ngIf="failed && retryEnabled" class="center">
        <button nz-button [nzType]="'primary'" (click)="retry()">
          Please try again
        </button>
      </div>

      <div *ngIf="loading" class="center">
        <utils-loader size="medium"></utils-loader>
      </div>
    </nz-card>
  </div>
</div>

<div class="half half-right">
  <img src="/assets/welcome.png" title="Welcome!" alt="Welcome" />
  <!-- <img src="/assets/logo-white.png" title="Screeb!"> -->
</div>
