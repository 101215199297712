/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  htmlSanitizer,
  stripHtml,
} from "components/builder/components/Cards/sanitized-message/sanitizer";
import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  CTACalendar,
  SurveyLanguages,
  getI18nTextLabelTranslation,
  setI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";

enum CalendarType {
  Calendly = "calendly",
  Google = "google",
  Hubspot = "hubspot",
  CalCom = "calcom",
  SavvyCal = "savvycal",
}

@Component({
  selector: "lateral-panel-edit-calendar",
  templateUrl: "./edit-calendar.component.html",
  styleUrls: ["./edit-calendar.component.scss"],
})
export class LateralPanelEditCalendarComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() public language: SurveyLanguages = "en";
  @Output() errorChange = new EventEmitter<boolean>();
  public linkErrors: string[] = [];

  constructor(
    public builderStore: BuilderStore,
    public entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    this.validateData();
  }

  public getError(): string[] {
    return [...this.linkErrors];
  }

  public get cta(): CTACalendar {
    const cta = this.node.node.question.call_to_action;
    if (cta.type !== "calendar")
      throw Error("unexpected action type for calendar");
    return cta;
  }

  /**
   * Data validation
   */
  private isValidURL(url: string): string | null {
    // Check calendly url
    // https://calendly.com/screeb/discoverycall?embed_domain=screeb.app&embed_type=Inline
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?calendly.com\\/[a-zA-Z0-9-_]+\\/?",
      "i",
    );

    if (urlPattern.test(url)) {
      return CalendarType.Calendly;
    }

    // Check google calendar url
    // https://calendar.google.com/calendar/appointments/schedules/AcZssZ2aaNm7vn5JtI3YhCNV8u34hIElxroU7kDsGPJmuLqL_nlME97eB-U9pOBvP6nPtdGStmb8ZQVR
    // https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1-85je20btsnWuDIhX9g207gfHAlW5iuiecfW3ZfP9x4Cbih1wlPAheOBQ49FMhJ776v3u80rn
    // https://calendar.google.com/calendar/u/0/appointments/AcZssZ0ZjC-s-ONvQCweNYdyc0B7m_sM6uiNcBFWssE=
    const googleUrlPattern = new RegExp(
      "^(https?:\\/\\/)?calendar.google.com\\/calendar\\/(u\\/[0-9]+\\/)?appointments\\/[a-zA-Z0-9-_]+\\/?",
      "i",
    );

    if (googleUrlPattern.test(url)) {
      return CalendarType.Google;
    }

    // Check hubspot calendar url
    // https://meetings.hubspot.com/simon-robic
    const hubspotUrlPattern = new RegExp(
      "^(https?:\\/\\/)?meetings.hubspot.com\\/[a-zA-Z0-9-_]+\\/?",
      "i",
    );

    if (hubspotUrlPattern.test(url)) {
      return CalendarType.Hubspot;
    }

    // Check cal.com calendar url
    // https://cal.com/alexis-rouillard-fzqhou
    const calcomUrlPattern = new RegExp(
      "^(https?:\\/\\/)?cal.com\\/[a-zA-Z0-9-_]+\\/?",
      "i",
    );

    if (calcomUrlPattern.test(url)) {
      return CalendarType.CalCom;
    }

    // Check savvycal calendar url
    // https://savvycal.com/alexisrouillard/b24791cb
    const savvycalUrlPattern = new RegExp(
      "^(https?:\\/\\/)?savvycal.com\\/[a-zA-Z0-9-_]+\\/[a-zA-Z0-9-_]+\\/?",
      "i",
    );

    if (savvycalUrlPattern.test(url)) {
      return CalendarType.SavvyCal;
    }

    return null;
  }

  public validateLink() {
    const url = this.getLabelLink() || "";
    const urlLength = this.getLength(url);

    this.linkErrors = [];
    if (urlLength < 1) {
      this.linkErrors = ["Required"];
    } else if (urlLength > 250) {
      this.linkErrors = ["Max 250 characters allowed."];
    } else if (!this.isValidURL(url.trim())) {
      this.linkErrors = ["Invalid URL."];
    }

    return this.linkErrors.length > 0;
  }

  public validateData() {
    const error = this.validateLink();
    if (error) this.errorChange.emit(true);
    else this.errorChange.emit(false);
  }

  public getLabelLink(): string {
    const cta = this.node.node.question.call_to_action as CTACalendar;
    return decode(
      getI18nTextLabelTranslation(
        cta.calendar.payload.url,
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLink(url: string) {
    const cta = this.node.node.question.call_to_action as CTACalendar;

    // Let alter url to add some params
    let newURL = url;
    const provider = this.isValidURL(url);
    try {
      const parsedURL = new URL(url);
      if (provider === CalendarType.Calendly) {
        if (!parsedURL.searchParams.has("embed_type")) {
          parsedURL.searchParams.append("embed_type", "Inline");
        }

        if (!parsedURL.searchParams.has("hide_gdpr_banner")) {
          parsedURL.searchParams.append("hide_gdpr_banner", "1");
        }

        if (!parsedURL.searchParams.has("hide_event_type_details")) {
          parsedURL.searchParams.append("hide_event_type_details", "1");
        }
      } else if (provider === CalendarType.Google) {
        if (!parsedURL.searchParams.has("hl")) {
          parsedURL.searchParams.append("hl", this.language);
        }

        if (!parsedURL.searchParams.has("gv")) {
          parsedURL.searchParams.append("gv", "true");
        }
      }

      newURL = parsedURL.toString();
    } catch (e) {
      console.error(e);
    }
    setI18nTextLabelTranslation(
      cta.calendar.payload.url,
      newURL,
      this.language,
    );
  }

  getLength(text: string) {
    return stripHtml(
      htmlSanitizer(text, {
        styling: true,
        CR: false,
        nativeCR: true,
        links: true,
      }),
    ).length;
  }
}
