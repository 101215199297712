/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import {
  billingCycleDurationLabels,
  planTypeInfos,
} from "components/super-org/billing/components/billing-plan/billing-plan.data";
import {
  couponToString,
  planIdToPlanType,
} from "components/super-org/billing/products";
import { differenceInDays, endOfDay, format } from "date-fns";
import {
  OrgBilling,
  OrgBillingInvoice,
  OrgBillingMetadata,
  OrgBillingPaymentMethods,
  OrgBillingSubscription,
  PlanType,
  scalePlanDefaultWorkspaces,
} from "models/org_billing.model";
import { SuperOrg } from "models/super-org.model";
import { EntitlementService } from "services/entitlement.service";
import { SettingsService } from "services/settings.service";
import { formatNumber } from "utils/number";

export type PlanDisplayMode = "normal" | "entitlement" | "service" | "trial";

const EntitlementsToLabel: {
  [key in keyof OrgBillingMetadata]?: string;
} = {
  max_workspaces: "Workspaces",
  capping: "Capping",
  advanced_survey_customization: "Advanced Survey Customization",
  white_label: "Hide 'Powered by Screeb'",
  multilingual_survey: "Multilingual Survey",
};

@Component({
  selector: "billing-plan",
  templateUrl: "billing-plan.component.html",
  styleUrls: ["billing-plan.component.scss"],
})
export class SettingsBillingPlanComponent implements OnChanges {
  @Input() superOrg: SuperOrg;
  @Input() orgBilling: OrgBilling;
  @Input() orgBillingSubscription: OrgBillingSubscription = null;
  @Input() orgBillingPaymentMethods: OrgBillingPaymentMethods = null;
  @Input() orgBillingInvoices: OrgBillingInvoice[] = [];
  @Input() planType: PlanType;

  @Input() displayMode: PlanDisplayMode = "normal";
  @Output() onUpgrade = new EventEmitter<PlanType>();
  @Output() onDowngrade = new EventEmitter<unknown>();
  @Output() onUpgradeAnnualBilling = new EventEmitter<unknown>();
  @Output() onComparePlan: EventEmitter<void> = new EventEmitter<void>();
  @Output() goToInvoices: EventEmitter<void> = new EventEmitter<void>();

  public currentPlanType: PlanType;
  public isCurrentPlanType: boolean = false;
  public freeTrial: boolean = false;

  public price: number = 0;
  public steps = [];
  public selectedTrialStep = 0;
  public remainingTrialDays = 0;
  public cancelAt: Date = null;

  public cycleDurationToLabel = billingCycleDurationLabels;
  public planTypeInfos = planTypeInfos;
  public scalePlanDefaultWorkspaces = scalePlanDefaultWorkspaces;
  public couponToString = couponToString;

  public addons: { name: string; value: string }[] = [];

  constructor(
    private settingsService: SettingsService,
    private entitlementService: EntitlementService,
  ) {}

  public ngOnChanges(): void {
    this.currentPlanType = planIdToPlanType(
      this.orgBilling.stripe_product_id,
      this.settingsService.billing,
    );
    this.isCurrentPlanType = this.planType === this.currentPlanType;
    this.freeTrial = this.superOrg.flags.billing_status === "trial";

    if (this.freeTrial) {
      this.refreshFreeTrialStep();
    }
    if (this.displayMode === "entitlement") {
      this.refreshActiveAddons();
    }

    this.cancelAt = this.orgBillingSubscription?.cancel_at
      ? new Date(this.orgBillingSubscription?.cancel_at * 1000)
      : null;
  }

  private refreshActiveAddons() {
    this.addons = [];

    const formatValue = (value: boolean | number) => {
      if (typeof value === "number") {
        return formatNumber(value, "number");
      }
      return (value as boolean) ? "Active" : "Inactive";
    };

    Object.entries(this.orgBilling.effective_metadata).forEach((entry) => {
      const addonId = entry[0];
      const value = entry[1];
      if (!!value && !!EntitlementsToLabel[addonId]) {
        this.addons.push({
          name: EntitlementsToLabel[addonId],
          value: formatValue(value as boolean | number),
        });
      }
    });
  }

  private refreshFreeTrialStep() {
    const remainingDays =
      this.entitlementService.getRemainingFreeTrialDays() ?? 0;
    this.steps = new Array(this.getFreeTrialTotalDuration())
      .fill(0)
      .map((nb, idx) => idx + 1);

    this.selectedTrialStep = this.steps.length - remainingDays;
    this.remainingTrialDays = remainingDays;
  }

  public formatHighNumber(num: number): string {
    return formatNumber(num, "number");
  }

  public getFormattedDate(timestamp: number) {
    return format(timestamp * 1000, "MMMM d, y");
  }

  public hasPaymentMethod() {
    return !!this.orgBillingPaymentMethods?.default_payment_method;
  }

  public getPaymentMethodLabel() {
    const defaultPMId =
      this.orgBillingPaymentMethods?.default_payment_method?.id;
    const method = this.orgBillingPaymentMethods?.payment_method.find(
      (pm) => pm.id === defaultPMId,
    );

    if (!method) {
      return "None";
    }

    switch (method.type) {
      case "card":
        return `${method.card.brand} ending by ${method.card.last4}`;
      case "sepa_debit":
        return `Account ending by ${method.sepa_debit.last4}`;
    }
  }

  public getFreeTrialTotalDuration(): number {
    const trialDuration = this.orgBilling.billing_trial_duration;
    return differenceInDays(endOfDay(trialDuration * 1000), 0);
  }

  public getFormattedFreeTrialDuration(): string {
    const trialDuration = this.orgBilling.billing_trial_duration;
    const days = differenceInDays(endOfDay(trialDuration * 1000), 0);
    return `${days} days`;

    // const duration = intervalToDuration({
    //   start: 0,
    //   end: endOfDay(trialDuration * 1000),
    // });
    // if (!duration.weeks) {
    //   duration.weeks = (duration.days / 7) | 0;
    // }
    // if (!!duration.weeks && duration.days % 7 === 0) {
    //   return formatDuration(duration, {
    //     format: ["weeks"],
    //   });
    // }

    // return formatDuration(duration, {
    //   format: ["days"],
    // });
  }

  public comparePlan() {
    this.onComparePlan.emit();
  }

  public upgrade() {
    this.onUpgrade.emit(this.planType);
  }

  public downgrade() {
    this.onDowngrade.emit();
  }

  public upgradeAnnualBilling() {
    this.onUpgradeAnnualBilling.emit();
  }

  public typeOf(any: any): string {
    return typeof any;
  }
}
