/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import {
  AddonKey,
  AddonKeyToName,
  BillingSettings,
  OrgBilling,
  OrgBillingInvoice,
  OrgBillingPaymentMethods,
  OrgBillingSubscription,
  PlanType,
  notFreeSubscriptionOptions,
  scalePlanDefaultMTU,
  scalePlanDefaultWorkspaces,
  subscriptionOptions,
} from "models/org_billing.model";
import { SuperOrg } from "models/super-org.model";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { SettingsService } from "services/settings.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { deepCopy } from "utils/object";
import { recommendedPlanTypeUpgrade } from "../components/billing-plan/billing-plan.data";
import {
  defaultScaleSubscriptionOptions,
  lateralPanelMode,
  planIdToPlanType,
} from "../products";

export type onSubscribePayload = {
  mode: lateralPanelMode;
  currentSubscription: subscriptionOptions;
  targetedSubscription: subscriptionOptions;
};

@Component({
  selector: "settings-billing-tab-subscription",
  templateUrl: "./tab-subscription.component.html",
  styleUrls: ["./tab-subscription.component.scss"],
})
export class SettingsBillingTabSubscriptionComponent
  implements OnInit, OnChanges
{
  @Input() superOrg: SuperOrg = null;
  @Input() orgBilling: OrgBilling = null;
  @Input() orgBillingSubscription: OrgBillingSubscription = null;
  @Input() orgBillingPaymentMethods: OrgBillingPaymentMethods = null;
  @Input() orgBillingInvoices: OrgBillingInvoice[] = [];
  @Input() billingSettings: BillingSettings;

  @Output() onSubscribe: EventEmitter<onSubscribePayload> =
    new EventEmitter<onSubscribePayload>();
  @Output() goToTab: EventEmitter<string> = new EventEmitter<string>();

  public currentPlanType: PlanType;
  public recommendedPlanType: PlanType | null;
  public currentSubscriptionOptions: subscriptionOptions;

  public data = [];

  public comparePlanOpened = false;

  constructor(
    private trackersService: TrackersService,
    private settingsService: SettingsService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(): void {
    this.refresh();
  }

  refresh(): void {
    this.currentPlanType = planIdToPlanType(
      this.orgBilling.stripe_product_id,
      this.settingsService.billing,
    );
    this.recommendedPlanType =
      recommendedPlanTypeUpgrade[
        planIdToPlanType(
          this.orgBilling.stripe_product_id,
          this.settingsService.billing,
        )
      ];

    this.currentSubscriptionOptions = this.orgBillingToSubscriptionOptions(
      this.settingsService.billing,
      this.orgBilling,
      this.orgBillingSubscription,
    );
  }

  public downgradePlan() {
    const event = this.trackersService
      .newEventTrackingBuilder("Organization billing plan downgrade requested")
      .withSuperOrg(this.superOrg)
      .withProps({
        current_subscription_options: this.currentSubscriptionOptions,
      });

    if (this.currentPlanType === "custom") {
      event.withProps({ action_result: "contact_us" }).build();
      this.askUsAQuestion();
      return;
    }

    event
      .withProps({
        action_result: "open_panel",
        downgrade_to: "free",
        targeted_subscription_options: {
          planType: "free",
        },
      })
      .build();

    this.onSubscribe.emit({
      mode: "downgrade",
      currentSubscription: this.currentSubscriptionOptions,
      targetedSubscription: {
        planType: "free",
      },
    });
  }

  public upgradePlan(planType: "scale" | "custom") {
    const event = this.trackersService
      .newEventTrackingBuilder("Organization billing plan upgrade requested")
      .withSuperOrg(this.superOrg)
      .withProps({
        current_subscription_options: this.currentSubscriptionOptions,
        targeted_subscription_options: deepCopy(
          defaultScaleSubscriptionOptions,
        ),
      });

    if (planType === "custom") {
      event.withProps({ action_result: "contact_us" }).build();
      this.askUsAQuestion();
      return;
    }

    switch (this.currentSubscriptionOptions.planType) {
      case "free":
        event
          .withProps({
            action_result: "open_panel",
            upgrade_to: planType,
          })
          .build();
        this.onSubscribe.emit({
          mode: "subscription",
          currentSubscription: this.currentSubscriptionOptions,
          targetedSubscription: deepCopy(defaultScaleSubscriptionOptions),
        });
        break;
      case "scale":
        event
          .withProps({
            action_result: "open_panel",
            upgrade_to: planType,
          })
          .build();
        this.onSubscribe.emit({
          mode: "subscription",
          currentSubscription: this.currentSubscriptionOptions,
          targetedSubscription: deepCopy(this.currentSubscriptionOptions),
        });
        break;
      case "custom":
        event.withProps({ action_result: "unexpected" }).build();
        this.askUsAQuestion();
        break;
    }
  }

  public upgradeAnnualBilling() {
    const event = this.trackersService
      .newEventTrackingBuilder(
        "Organization billing addon additional MTU requested",
      )
      .withSuperOrg(this.superOrg)
      .withProps({
        current_subscription_options: this.currentSubscriptionOptions,
      });

    if (this.currentPlanType === "custom") {
      event.withProps({ action_result: "contact_us" }).build();
      this.askUsAQuestion();
      return;
    } else if (this.currentPlanType === "free") {
      event.withProps({ action_result: "unexpected" }).build();
      return;
    }

    const targetedSubscriptionOptions = deepCopy(
      this.currentSubscriptionOptions,
    ) as notFreeSubscriptionOptions;

    targetedSubscriptionOptions.billingCycle = "year";

    event
      .withProps({
        action_result: "open_panel",
        upgrade_to: targetedSubscriptionOptions.planType,
      })
      .withProps({ targeted_subscription_options: targetedSubscriptionOptions })
      .build();

    this.onSubscribe.emit({
      mode: "change_billing_cycle",
      currentSubscription: this.currentSubscriptionOptions,
      targetedSubscription: targetedSubscriptionOptions,
    });
  }

  public activateAddon(addonKey: AddonKey) {
    const event = this.trackersService
      .newEventTrackingBuilder(
        ("Organization billing addon " +
          AddonKeyToName[addonKey] +
          " requested") as TrackingEventName,
      )
      .withSuperOrg(this.superOrg)
      .withProps({
        current_subscription_options: this.currentSubscriptionOptions,
      });

    if (this.currentPlanType === "custom") {
      event.withProps({ action_result: "contact_us" }).build();
      this.askUsAQuestion();
      return;
    }

    const targetedSubscriptionOptions = deepCopy(
      this.currentPlanType === "scale"
        ? this.currentSubscriptionOptions
        : defaultScaleSubscriptionOptions,
    ) as notFreeSubscriptionOptions;

    switch (addonKey) {
      case "white-label":
        targetedSubscriptionOptions.addons.whiteLabel = true;
        break;
      case "additional-workspaces":
        targetedSubscriptionOptions.addons.additionalWorkspaces += 1;
        break;
      case "response-translation":
        targetedSubscriptionOptions.addons.responseTranslation = true;
        break;
      case "audio-video-survey":
        targetedSubscriptionOptions.addons.audioVideoSurvey = true;
        break;
    }

    event
      .withProps({
        action_result: "open_panel",
        upgrade_to: targetedSubscriptionOptions.planType,
      })
      .withProps({ targeted_subscription_options: targetedSubscriptionOptions })
      .build();
    this.onSubscribe.emit({
      mode: "activate_addon",
      currentSubscription: this.currentSubscriptionOptions,
      targetedSubscription: targetedSubscriptionOptions,
    });
  }

  public activateAdditionalMTU() {
    const event = this.trackersService
      .newEventTrackingBuilder(
        "Organization billing addon additional MTU requested",
      )
      .withSuperOrg(this.superOrg)
      .withProps({
        current_subscription_options: this.currentSubscriptionOptions,
      });

    if (this.currentPlanType === "custom") {
      this.askUsAQuestion();
      event.withProps({ action_result: "contact_us" }).build();
      return;
    }

    this.upgradePlan("scale");
    event
      .withProps({ action_result: "open_panel", upgrade_to: "scale" })
      .build();
  }

  private orgBillingToSubscriptionOptions(
    billingSettings: BillingSettings,
    orgBilling: OrgBilling,
    /* eslint-disable */
    orgBillingSubscription: OrgBillingSubscription,
  ): subscriptionOptions {
    if (!orgBilling.stripe_product_id) {
      return { planType: "free" };
    }

    return {
      billingCycle: orgBilling.billing_cycle_duration ?? "month",
      planType: planIdToPlanType(orgBilling.stripe_product_id, billingSettings),
      addons: {
        whiteLabel: orgBilling.expected_metadata.white_label,
        additionalWorkspaces:
          orgBilling.expected_metadata.max_workspaces -
          scalePlanDefaultWorkspaces,
        responseTranslation: orgBilling.expected_metadata.response_translation,
        audioVideoSurvey: orgBilling.expected_metadata.video_question,
      },
      mtu: orgBilling.expected_metadata.max_mtu ?? scalePlanDefaultMTU,
      coupon: null, // @TODO: support the case where we would reuse the same coupon for a different subscription.
      // coupon: orgBillingSubscription.discount,
    };
  }

  private askUsAQuestion() {
    if (this.trackersService.intercomIsBooted()) {
      this.trackersService.intercomOpenConversation();
    } else {
      window.location.assign("mailto:michael@screeb.app");
    }
  }

  public onComparePlan() {
    this.comparePlanOpened = true;
  }

  public onComparePlanModalToggle(opened: boolean) {
    this.comparePlanOpened = opened;
  }
}
