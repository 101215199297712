export function arrayToSet<T>(elements: T[]): T[] {
  return elements.filter((elem, index, self) => {
    return index === self.indexOf(elem);
  });
}

export function arrayToMap(elements: any[], key: string): Map<any, any> {
  return new Map(elements.map((value) => [value[key], value]));
}

export function arrayToKV(
  elements: any[],
  key: string,
  value: string,
): Map<any, any> {
  return new Map(elements.map((item) => [item[key], item[value]]));
}

export function arrayExcludeElements(all: any[], exclude: any[]) {
  return all.filter((x) => !exclude.includes(x));
}

export function groupBy<T extends object, K extends keyof T>(
  array: T[],
  key: K,
): { [key: string]: T[] } {
  return array.reduce((rv, x) => {
    // @ts-ignore
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function groupByCb<T extends object>(
  array: T[],
  key: (T) => string,
): { [key: string]: T[] } {
  return array.reduce((rv, x) => {
    // @ts-ignore
    (rv[x[key(x)]] = rv[x[key(x)]] || []).push(x);
    return rv;
  }, {});
}

export function findWithPriority<T>(
  array: T[],
  ...predicates: ((value: T, index: number, obj: any[]) => boolean)[]
): T | undefined {
  for (const predicate of predicates) {
    const found = array.find(predicate);
    if (found) {
      return found;
    }
  }
  return undefined;
}
