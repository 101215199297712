<ng-container>
  <h2>MTU Allocation</h2>
  <div class="gridAppear">
    <section nz-col class="overview-content">
      <div nz-col nzXs="24">
        <section nz-row [nzGutter]="[0, 32]">
          <div nz-col nzXs="24" nzXl="24">
            <div class="indicator-with-border">
              <div class="user">
                <div class="iAppear">
                  <survey-stats-indicators-single-indicator
                    title="Number of workspaces"
                    boldTitle="true"
                    size="medium"
                    [value]="workspaces?.length || 0"
                    [loading]="isLoading"
                    [thousandsSuffixes]="true"
                    [maximumFractionDigits]="1"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
                <div class="iAppear">
                  <survey-stats-indicators-single-indicator
                    title="Number of MTU in your Plan"
                    boldTitle="true"
                    size="medium"
                    [value]="superOrg?.entitlements.max_mtu || 0"
                    [loading]="isLoading"
                    [thousandsSuffixes]="false"
                    [maximumFractionDigits]="1"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
                <div class="iAppear">
                  <survey-stats-indicators-single-indicator
                    title="Allocated MTU"
                    boldTitle="true"
                    size="medium"
                    [value]="
                      superOrg?.entitlements.mtu_mode === 'auto'
                        ? superOrg?.entitlements.max_mtu || 0
                        : getAllocatedMTU()
                    "
                    [loading]="isLoading"
                    [thousandsSuffixes]="false"
                    [maximumFractionDigits]="1"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
                <div class="iAppear">
                  <survey-stats-indicators-single-indicator
                    title="Remaining MTU to allocate"
                    boldTitle="true"
                    size="medium"
                    [value]="
                      superOrg?.entitlements.mtu_mode === 'auto'
                        ? 0
                        : (superOrg?.entitlements.max_mtu || 0) -
                          getAllocatedMTU()
                    "
                    [loading]="isLoading"
                    [thousandsSuffixes]="false"
                    [maximumFractionDigits]="1"
                  >
                  </survey-stats-indicators-single-indicator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section class="content iAppear d100">
      <div class="filters-bar">
        <div class="left">
          <div class="tags-filter">
            <nz-select
              nzShowSearch
              nzAllowClear
              nzSuffixIcon="caret-down"
              nzPlaceHolder="All Tags"
              nzSize="large"
              nzMode="multiple"
              [ngModel]="filteredTags"
              [nzShowArrow]="true"
              (ngModelChange)="onTagsFilterChange($event)"
            >
              <ng-container *ngFor="let tag of workspacesTags">
                <nz-option [nzLabel]="tag" [nzValue]="tag"></nz-option>
              </ng-container>
            </nz-select>
          </div>
          <div class="search-bar">
            <form nz-form (ngSubmit)="onSearchChangeImmediate(keyword)">
              <nz-input-group
                nzSize="large"
                [nzPrefix]="prefixIcon"
                [nzSuffix]="inputClearButton"
              >
                <input
                  type="text"
                  nz-input
                  placeholder="Search a workspace"
                  [ngModel]="keyword"
                  (ngModelChange)="onSearchChange($event)"
                  [ngModelOptions]="{ standalone: true }"
                />
              </nz-input-group>
              <ng-template #prefixIcon
                ><i nz-icon nzType="search"></i
              ></ng-template>
              <ng-template #inputClearButton>
                <i
                  nz-icon
                  class="ant-input-clear-icon"
                  nzTheme="fill"
                  nzType="close-circle"
                  *ngIf="keyword"
                  (click)="onSearchChangeImmediate('')"
                ></i>
              </ng-template>
            </form>
          </div>
        </div>
        <div class="right">
          <div class="label">
            <nz-switch
              [ngModel]="superOrg?.entitlements.mtu_mode === 'even'"
              [nzDisabled]="superOrg?.entitlements.mtu_mode === 'even'"
              (ngModelChange)="onMTUModeChange('even', $event)"
            ></nz-switch>
            Even Allocation
          </div>
          <div class="label">
            <nz-switch
              [ngModel]="
                superOrg?.entitlements.mtu_mode === 'manual' ||
                !superOrg?.entitlements.mtu_mode
              "
              [nzDisabled]="
                superOrg?.entitlements.mtu_mode === 'manual' ||
                !superOrg?.entitlements.mtu_mode
              "
              (ngModelChange)="onMTUModeChange('manual', $event)"
            ></nz-switch>
            Manual Allocation
          </div>
          <div class="label">
            <nz-switch
              [ngModel]="superOrg?.entitlements.mtu_mode === 'auto'"
              [nzDisabled]="superOrg?.entitlements.mtu_mode === 'auto'"
              (ngModelChange)="onMTUModeChange('auto', $event)"
            ></nz-switch>
            Dynamic Allocation
            <screeb-icon
              size="sm"
              icon="info-circle"
              nz-popover
              nzType="primary"
              nzPopoverPlacement="bottomLeft"
              [nzPopoverContent]="contentTemplate"
            ></screeb-icon>
            <ng-template #contentTemplate>
              Dynamic mode does not limit workspaces individually,<br />they can
              all use the maximum number of MTUs included in the plan.
            </ng-template>
          </div>

          <button
            title="Save"
            nz-button
            nzType="primary"
            nzSize="large"
            [nzLoading]="isSaving"
            (click)="onSave()"
            [disabled]="!hasUnsavedChanges()"
          >
            <screeb-icon size="sm" icon="save"></screeb-icon>
            <span>Save</span>
          </button>
        </div>
      </div>

      <div class="workspace-table-group">
        <nz-table
          class="workspace-table"
          #table
          [nzData]="filteredWorkspaces"
          nzTableLayout="fixed"
          [nzPageSize]="100"
          [nzShowSizeChanger]="true"
          [nzFrontPagination]="true"
          [nzHideOnSinglePage]="true"
          [nzPageSizeOptions]="[10, 25, 100]"
        >
          <thead>
            <tr>
              <th
                *ngFor="let column of listOfColumns"
                [(nzSortOrder)]="column.sortOrder"
                [nzSortFn]="column.sortFn"
                [nzSortDirections]="column.sortDirections"
                [nzFilterMultiple]="column.filterMultiple"
                [nzColumnKey]="column.sortKey"
                [nzWidth]="column.width"
                [nzAlign]="column.align"
              >
                {{ column.name }}
              </th>
              <th [nzWidth]="'155px'"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let workspace of table.data">
              <!--
                HACK: using (click), we cannot right-click on a row and select "open in a new table".
                So we added a "<a>" html tag between tbody and tr.
              -->

              <tr>
                <td nzAlign="left" class="bold-text" nzEllipsis="true">
                  {{ workspace.name }}
                  <div *ngIf="workspace.tags?.length" class="workspace-tags">
                    <nz-tag *ngFor="let tag of workspace.tags.slice(0, 3)">
                      {{ tag }}
                    </nz-tag>
                    <nz-tag *ngIf="workspace.tags.length > 3">
                      +{{ workspace.tags.length - 3 }}
                    </nz-tag>
                  </div>
                </td>
                <td nzAlign="left">
                  <nz-slider
                    [nzMin]="minimumMTU"
                    [nzMax]="
                      superOrg?.entitlements.mtu_mode === 'auto'
                        ? superOrg?.entitlements.max_mtu
                        : getMaxMTUPerWorkspace()
                    "
                    [nzStep]="500"
                    [nzDots]="false"
                    [nzDisabled]="superOrg?.entitlements.mtu_mode !== 'manual'"
                    [nzTooltipPlacement]="'top'"
                    [ngModel]="
                      superOrg?.entitlements.mtu_mode === 'auto'
                        ? superOrg?.entitlements.max_mtu
                        : workspace.entitlements.max_mtu
                    "
                    (ngModelChange)="onMTUChange(workspace, $event)"
                  >
                  </nz-slider>
                  <p class="slider-mtu">
                    Consumed MTU since 1st day of the month:
                    {{
                      formatNumber(
                        workspace.stats?.current_month_respondents || 0,
                        "number",
                        1,
                        "auto",
                        false
                      )
                    }}
                  </p>
                </td>
                <td nzAlign="right" class="slider-input">
                  <nz-input-number
                    [nzMin]="minimumMTU"
                    [nzStep]="500"
                    [nzMax]="getMaxMTUPerWorkspace()"
                    [nzDisabled]="superOrg?.entitlements.mtu_mode !== 'manual'"
                    [ngStyle]="{ marginLeft: '16px' }"
                    [ngModel]="
                      superOrg?.entitlements.mtu_mode === 'auto'
                        ? superOrg?.entitlements.max_mtu
                        : workspace.entitlements.max_mtu
                    "
                    (ngModelChange)="onMTUChange(workspace, $event)"
                  ></nz-input-number>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </section>
  </div>
</ng-container>
