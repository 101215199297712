import { Component, Input, OnChanges } from "@angular/core";
import { htmlSanitizer, SanitizeOptions } from "./sanitizer";

@Component({
  selector: "sanitized-message-component",
  templateUrl: "./sanitized-message.component.html",
  styleUrls: ["./sanitized-message.component.scss"],
})
export class SanitizedMessageComponent implements OnChanges {
  @Input()
  public content: string;
  @Input()
  public options: SanitizeOptions;

  public _content: string = "";

  constructor() {}

  ngOnChanges() {
    this._content = htmlSanitizer(this.content, this.options);
  }
}
