import "chartjs-adapter-date-fns";

/* ANGULAR MODULES */
import { OverlayModule } from "@angular/cdk/overlay";
import { registerLocaleData } from "@angular/common";
import { HttpBackend, HttpClientModule } from "@angular/common/http";
import en from "@angular/common/locales/en";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  ExtraOptions,
  PreloadAllModules,
  Router,
  RouterModule,
} from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { MarkdownModule } from "ngx-markdown";

/* ANTD MODULE */
import { NzAffixModule } from "ng-zorro-antd/affix";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzAnchorModule } from "ng-zorro-antd/anchor";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { en_US, NZ_I18N } from "ng-zorro-antd/i18n";
import { NzImageModule } from "ng-zorro-antd/image";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzListModule } from "ng-zorro-antd/list";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzNotificationModule } from "ng-zorro-antd/notification";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzSliderModule } from "ng-zorro-antd/slider";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzTimelineModule } from "ng-zorro-antd/timeline";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzTypographyModule } from "ng-zorro-antd/typography";

/* EXTERNAL MODULES */
// import { enUS } from 'date-fns/locale';
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { ColorPickerModule } from "@iplab/ngx-color-picker";
import { TranslateModule } from "@ngx-translate/core";
import { KeyboardShortcutsModule } from "ng-keyboard-shortcuts";
import { NgChartsModule } from "ng2-charts";
import { ClipboardModule } from "ngx-clipboard";
import { CookieModule } from "ngx-cookie";
import { DateFnsModule } from "ngx-date-fns";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { HIGHLIGHT_OPTIONS, HighlightModule } from "ngx-highlightjs";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { NgxStripeModule } from "ngx-stripe";

/* INTERNAL MODULES */
import { IconsProviderModule } from "./icons-provider.module";

/* LAYOUT */
import { EmbeddedExternalPageComponent } from "components/layouts/back/embedded-external-page/embedded-external-page.component";
import { LayoutBackHeaderComponent } from "components/layouts/back/header/header.component";
import { LayoutBackHeaderService } from "components/layouts/back/header/header.service";
import { LayoutBackKeyboardShortcutsComponent } from "components/layouts/back/keyboard-shortcuts/keyboard-shortcuts.component";
import { LayoutBackSidebarIntegrationComponent } from "components/layouts/back/sidebar-integration/sidebar-integration.component";
import { LayoutBackSidebarSettingsComponent } from "components/layouts/back/sidebar-settings/sidebar-settings.component";
import { LayoutBackSidebarSharingComponent } from "components/layouts/back/sidebar-sharing/sidebar-sharing.component";
import { LayoutBackSidebarStatsComponent } from "components/layouts/back/sidebar-stats/sidebar-stats.component";
import { LayoutBackSidebarComponent } from "components/layouts/back/sidebar/sidebar.component";
import { InternalErrorPageComponent } from "components/layouts/error-pages/internalerror-page.component";
import { NoOrgaPageComponent } from "components/layouts/error-pages/no-orga-page.component";
import { NotAuthorizedPageComponent } from "components/layouts/error-pages/notauthorized-page.component";
import { NotFoundPageComponent } from "components/layouts/error-pages/notfound-page.component";
import { PermissionDeniedPageComponent } from "components/layouts/error-pages/permissiondenied-page.component";
import { LayoutComponent } from "components/layouts/layout.component";
import { MaintenanceComponent } from "components/layouts/maintenance.component";
import { ParentLayoutComponent } from "components/layouts/parent-layout.component";

/* SERVICES */
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { ApplicationLoadService } from "services/app.service";
import { AuthService, SessionService } from "services/auth.service";
import { ConfigService } from "services/config.service";
import { EntitlementService } from "services/entitlement.service";
import { EventService } from "services/event.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { ImportService } from "services/import.service";
import { LoggerService, startLogging } from "services/logging.service";
import { MediaUploadService } from "services/media-upload.service";
import { MJMLService } from "services/mjml.service";
import { OAuth2Service } from "services/oauth2.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { SeoService } from "services/seo.service";
import { SettingsService } from "services/settings.service";
import { TagStorageService } from "services/tag-storage.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { UpgradeAdminService } from "services/upgrade.service";
import { TagEditorService } from "services/tag-editor.service";

/* GUARDS */
import {
  EnsureNotAuthGuard,
  IsAuthGuard,
  IsNotAuthGuard,
} from "guards/auth.guard";
import { GenericCanDeactivate } from "guards/generic-can-deactivate.guard";
import { IsIntegrationSlugValidGuard } from "guards/integration.guard";
import { OnBoardingGuard } from "guards/onboarding.guard";
import { DoOrgHasSurveysGuard } from "guards/survey.guard";

/* RESOLVERS */
import { AssetIndustriesScoresResolver } from "resolvers/asset-industries-scores";
import { AssetLanguagesAndCountriesResolver } from "resolvers/asset-languages-countries";
import { ChannelResolver } from "resolvers/channel";
import {
  ChannelClientScreensResolver,
  ChannelClientVersionsResolver,
  ChannelsClientVersionsResolver,
} from "resolvers/channel-screen";
import { ChannelsResolver } from "resolvers/channels";
import { IntegrationAtlassianProjectsResolver } from "resolvers/integration-atlassian-projects";
import {
  OrgIntegrationOrNullResolver,
  OrgIntegrationResolver,
  OrgIntegrationsResolver,
} from "resolvers/integrations";
import { OrgResolver } from "resolvers/org";
import { OrgAccountsResolver } from "resolvers/org-accounts";
import {
  OrgBillingInvoicesResolver,
  OrgBillingPaymentMethodsResolver,
  OrgBillingResolver,
  OrgBillingSubscriptionResolver,
} from "resolvers/org-billing";
import { OrgIndustryStatsResolver } from "resolvers/org-industry";
import { OrgTargetingRulesResolver } from "resolvers/org-targeting-rules";
import { WorkspacesResolver } from "resolvers/orgs";
import {
  RegistryEventsResolver,
  RegistryGroupResolver,
  RegistryIdentityPropertyResolver,
} from "resolvers/registry";
import { OrgReleasesResolver } from "resolvers/releases";
import { SequenceResolver } from "resolvers/sequence";
import { SequencesResolver } from "resolvers/sequences";
import { SuperOrgResolver } from "resolvers/super-org";
import { SuperOrgAccountsResolver } from "resolvers/super-org-accounts";
import { SuperOrgWorkspacesResolver } from "resolvers/super-org-workspaces";
import { SuperOrgsResolver } from "resolvers/super-orgs";
import { SurveyResolver } from "resolvers/survey";
import { SurveyDistributionBySurveyAndChannelResolver } from "resolvers/survey-distribution";
import { SurveyDistributionsBySurveyResolver } from "resolvers/survey-distributions";
import { SurveyScenarioNodeResolver } from "resolvers/survey-scenario-node";
import { SurveyTagsResolver } from "resolvers/survey-tags";
import { SurveysResolver } from "resolvers/surveys";
import { TagEditorTokenResolver } from "resolvers/tag-editor-token";
import { UserResolver } from "resolvers/user";
import { UserResponsesByUserIdResolver } from "resolvers/user-responses";

/* DAO */
import { GetFeedbackDao } from "components/surveys/pages/import/tools/getfeedback/getfeedback.dao";
import { AnalyticsDao } from "models/analytics.dao";
import { ChannelDao } from "models/channel.dao";
import { EmailDao } from "models/email.dao";
import { IntegrationDao } from "models/integration.dao";
import { OrgDao } from "models/org.dao";
import { RegistryDao } from "models/registry.dao";
import { ReleaseDao } from "models/release.dao";
import { ResponseDao } from "models/response.dao";
import { SequenceDao } from "models/sequence.dao";
import { SuperOrgDao } from "models/super-org.dao";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import { SurveyScenarioNodeDao } from "models/survey-scenario-node.dao";
import { SurveyDao } from "models/survey.dao";
import { TagEditorTokenDao } from "models/tag-editor-token.dao";
import { TagSettingsDao } from "models/tag-settings.dao";
import { UploadDao } from "models/upload.dao";
import { UserDao } from "models/user.dao";

/* STORES */
import { BuilderStore } from "stores/builder.store";

/* HELPERS */
import { NotificationHelper } from "helpers/notification.helper";
import { ScreebApiHelper } from "helpers/screeb-api.helper";

/* PIPES */
import { EntitlementPipe } from "pipes/entitlement.pipe";
import { PermissionPipe } from "pipes/permission.pipe";
import { PluralSingularPipe } from "pipes/pluralSingular.pipe";
import { PricePipe } from "pipes/price.pipe";
import { ResponseCompletionColorPipe } from "pipes/response-completion-color.pipe";
import { ResponseCompletionPipe } from "pipes/response-completion.pipe";
import { SafePipe } from "pipes/safe.pipe";
import { SuperPermissionPipe } from "pipes/super-permission.pipe";
import { ToLocaleStringPipe } from "pipes/to-locale-string.pipe";
import { UppercaseFirstPipe } from "pipes/uppercase-first.pipe";

/* UTILS */
import { ScreebDatePickerComponent } from "components/utils/date-picker/screeb-date-picker.component";
import { BadgeComponent } from "utils/badge/badge.component";
import { FormErrorComponent } from "utils/form-error/form-error.component";
import { HelpPopoverComponent } from "utils/help-popover/help-popover.component";
import { LoaderComponent } from "utils/loader/loader.component";
import { MediaStreamDirective } from "utils/media-stream-directive/media-stream.directive";

/* PAGES */
import { GrantCallbackComponent } from "components/auth/page/callback/grant/grant-callback.component";
import { Oauth2CallbackComponent } from "components/auth/page/callback/oauth2/oauth2-callback.component";
import { CreateWithAIPageComponent } from "components/auth/page/create-with-ai/create-with-ai.component";
import { BenchmarkPageComponent } from "components/benchmark/benchmark.component";
import { BuilderPageComponent } from "components/builder/page-builder.component";
import { CreateChannelPageComponent } from "components/channel/create/create.component";
import { TagSettingsBehaviourComponent } from "components/common/customize/tag-settings/component/tag-settings-behaviour/tag-settings-behaviour.component";
import { TagSettingsDesignComponent } from "components/common/customize/tag-settings/component/tag-settings-design/tag-settings-design.component";
import { TagSettingsInteractionsComponent } from "components/common/customize/tag-settings/component/tag-settings-interactions/tag-settings-interactions.component";
import { TagSettingsIntegrationsComponent } from "components/common/customize/tag-settings/component/tag-settings-integrations/tag-settings-integrations.component";
import { TagSettingsPreviewComponent } from "components/common/customize/tag-settings/component/tag-settings-preview/tag-settings-preview.component";
import { TagSettingsTranslationComponent } from "components/common/customize/tag-settings/component/tag-settings-translation/tag-settings-translation.component";
import { TagSettingsWithPreviewPageComponent } from "components/common/customize/tag-settings/tag-settings-with-preview.component";
import { SelectorUserEventsComponent } from "components/common/user/selector-user-events/selector-user-events.component";
import { SelectorUserPropertiesComponent } from "components/common/user/selector-user-properties/selector-user-properties.component";
import { TagRegistryEntrySourceComponent } from "components/common/user/tag-registry-entry-source/tag-registry-entry-source.component";
import { FunnelHelpComponent } from "components/funnels/components/funnel-help/funnel-help.component";
import { FunnelCreatePageComponent } from "components/funnels/pages/create/funnel-create.component";
import { FunnelEditPageComponent } from "components/funnels/pages/edit/funnel-edit.component";
import { FunnelListPageComponent } from "components/funnels/pages/list/funnel-list.component";
import { FunnelReportPageComponent } from "components/funnels/pages/report/funnel-report.component";
import { HomePageComponent } from "components/home/home.component";
import { IntegrationListPageComponent } from "components/integration/list/integration-list.component";
import { IntegrationSettingsOAuthComponent } from "components/integration/settings/components/common/oauth/integration-settings-oauth.component";
import { IntegrationSettingsPageComponent } from "components/integration/settings/integration-settings.component";
import { OnBoardingBookDemoPageComponent } from "components/onboarding/onboarding-book-demo/onboarding-book-demo.component";
import { OnBoardingDiscoveryComponent } from "components/onboarding/onboarding-discovery/onboarding-discovery.component";
import { OnBoardingHoldOnComponent } from "components/onboarding/onboarding-goal/hold-on/hold-on.component";
import { OnBoardingGoalPageComponent } from "components/onboarding/onboarding-goal/onboarding-goal.component";
import { OnBoardingInstallationGuideComponent } from "components/onboarding/onboarding-installation/onboarding-installation-guide/onboarding-installation-guide.component";
import { OnBoardingInstallationPageComponent } from "components/onboarding/onboarding-installation/onboarding-installation.component";
import { OnboardingDesktopPreviewComponent } from "components/onboarding/onboarding-preview/onboarding-desktop-preview/onboarding-desktop-preview";
import { OnboardingMobilePreviewComponent } from "components/onboarding/onboarding-preview/onboarding-mobile-preview/onboarding-mobile-preview";
import { ReleaseListPageComponent } from "components/release/list/release-list.component";
import { SegmentDetailsPageComponent } from "components/segment/details/segment-details.component";
import { SegmentListPageComponent } from "components/segment/list/segment-list.component";
import { AccountSettingsPageComponent } from "components/settings/account/account-settings.component";
import { SettingsAccountMFAComponent } from "components/settings/account/mfa/account-mfa.component";
import { SettingsAccountProviderComponent } from "components/settings/account/provider/account-provider.component";
import { SettingsChannelInstallPageComponent } from "components/settings/channel-install/channel-install.component";
import { ClientScreensListPageComponent } from "components/settings/client-screens/client-screens-list.component";
import { SettingsDataGovernancePageComponent } from "components/settings/data-governance/data-governance.component";
import { SettingsLanguagePageComponent } from "components/settings/language/language.component";
import { SettingsLanguageTranslationOptionComponent } from "components/settings/language/translation-option/language-translation-option.component";
import { RegistryEventsListPageComponent } from "components/settings/registry-events/registry-events-list.component";
import { RegistryIdentityPropertiesListPageComponent } from "components/settings/registry-identity-properties/registry-identity-properties-list.component";
import { SettingsPageComponent } from "components/settings/settings.component";
import { SettingsSurveyCappingPageComponent } from "components/settings/survey-capping/survey-capping.component";
import { SettingsSurveyCustomizationPageComponent } from "components/settings/survey-customization/survey-customization.component";
import { SettingsTaggingEditComponent } from "components/settings/tagging/edit/tagging-edit.component";
import { SettingsTaggingPageComponent } from "components/settings/tagging/tagging.component";
import { SettingsTeamMembersPageComponent } from "components/settings/team-members/team-members.component";
import { SettingsBillingPageComponent } from "components/super-org/billing/billing.component";
import { CreateSurveyPageComponent } from "components/surveys/pages/create/create.component";
import { SurveysImportModalComponent } from "components/surveys/pages/import/surveys-import.component";
import { GetFeedbackImportPageComponent } from "components/surveys/pages/import/tools/getfeedback/getfeedback-import.component";
import { DuplicateSurveyModalComponent } from "components/surveys/pages/list/components/duplicate-modal/duplicate-survey-modal.component";
import { SurveyListPageComponent } from "components/surveys/pages/list/survey-list.component";
import { SurveySettingsPageComponent } from "components/surveys/pages/settings/settings.component";
import { DistributionSettingsPageComponent } from "components/surveys/pages/share/distribution-settings/distribution-settings.component";
import { EmailShareSurveyPageComponent } from "components/surveys/pages/share/email/email.component";
import { LinkShareSurveyPageComponent } from "components/surveys/pages/share/link/link.component";
import { ShareSurveyPageComponent } from "components/surveys/pages/share/share-page/share.component";
import { ShareRedirectSurveyPageComponent } from "components/surveys/pages/share/share-redirect.component";
import { ContentAnalysisStatsSurveyPageComponent } from "components/surveys/pages/stats/content-analysis/content-analysis.component";
import { FlowAnalysisStatsSurveyPageComponent } from "components/surveys/pages/stats/flow-analysis/flow-analysis.component";
import { CESIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/ces/ces.component";
import { CSATIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/csat/csat.component";
import { NPSIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/nps/nps.component";
import { PMFIndicatorStatsSurveyPageComponent } from "components/surveys/pages/stats/indicators/pmf/pmf.component";
import { IndividualResponsesStatsSurveyPageComponent } from "components/surveys/pages/stats/individual-responses/individual-responses.component";
import { OverallPerformancesStatsSurveyPageComponent } from "components/surveys/pages/stats/overall-performances/overall-performances.component";
import { StatsSurveyPageComponent } from "components/surveys/pages/stats/stats.component";
import { SurveyPageComponent } from "components/surveys/pages/survey.component";
import { UserDetailsPageComponent } from "components/user/details/user-details.component";
import { UserListPageComponent } from "components/user/list/user-list.component";

/* COMPONENTS */
import { BenchmarkSummaryComponent } from "components/benchmark/components/benchmark-summary/benchmark-summary.component";
import { InviteTeamMembersComponent } from "components/common/invite-team-members/invite-team-members";
import { IntegrationSettingsAmplitudeDestinationComponent } from "components/integration/settings/components/amplitude-destination/config/amplitude-destination-settings.component";
import { IntegrationSettingsAmplitudeSourceComponent } from "components/integration/settings/components/amplitude-source/config/amplitude-source-settings.component";
import { IntegrationSettingsAsanaComponent } from "components/integration/settings/components/asana/config/asana-settings.component";
import { IntegrationSettingsAtlassianComponent } from "components/integration/settings/components/atlassian/config/atlassian-settings.component";
import { IntegrationSettingsCobbaiComponent } from "components/integration/settings/components/cobbai/config/cobbai-settings.component";
import { IntegrationSettingsCobbaiEditComponent } from "components/integration/settings/components/cobbai/config/edit/cobbai-settings-edit.component";
import { IntegrationInstructionsComponent } from "components/integration/settings/components/common/instructions/integration-instructions.component";
import { IntegrationSettingsCycleComponent } from "components/integration/settings/components/cycle/config/cycle-settings.component";
import { IntegrationSettingsHarvestrComponent } from "components/integration/settings/components/harvestr/config/harvestr-settings.component";
import { IntegrationSettingsIntercomComponent } from "components/integration/settings/components/intercom/config/intercom-settings.component";
import { IntegrationSettingsMondayComponent } from "components/integration/settings/components/monday/config/monday-settings.component";
import { IntegrationSettingsNotionComponent } from "components/integration/settings/components/notion/config/notion-settings.component";
import { IntegrationSettingsProductboardComponent } from "components/integration/settings/components/productboard/config/productboard-settings.component";
import { IntegrationSettingsRudderstackDestinationComponent } from "components/integration/settings/components/rudderstack-destination/config/rudderstack-destination-settings.component";
import { IntegrationSettingsRudderStackSourceComponent } from "components/integration/settings/components/rudderstack-source/config/rudderstack-source-settings.component";
import { IntegrationSettingsSegmentDestinationComponent } from "components/integration/settings/components/segment-destination/config/segment-destination-settings.component";
import { IntegrationSettingsSegmentSourceComponent } from "components/integration/settings/components/segment-source/config/segment-source-settings.component";
import { IntegrationSettingsSlackComponent } from "components/integration/settings/components/slack/config/slack-settings.component";
import { IntegrationSettingsStitchEditComponent } from "components/integration/settings/components/stitch/config/edit/stitch-settings-edit.component";
import { IntegrationSettingsStitchComponent } from "components/integration/settings/components/stitch/config/stitch-settings.component";
import { IntegrationSettingsTrelloComponent } from "components/integration/settings/components/trello/config/trello-settings.component";
import { IntegrationSettingsTypeformEditComponent } from "components/integration/settings/components/typeform/config/edit/typeform-settings-edit.component";
import { IntegrationSettingsTypeformComponent } from "components/integration/settings/components/typeform/config/typeform-settings.component";
import { IntegrationSettingsWebhookEditComponent } from "components/integration/settings/components/webhook/config/edit/webhook-settings-edit.component";
import { IntegrationSettingsWebhookComponent } from "components/integration/settings/components/webhook/config/webhook-settings.component";
import { IntegrationSettingsZapierEditComponent } from "components/integration/settings/components/zapier/config/edit/zapier-settings-edit.component";
import { IntegrationSettingsZapierComponent } from "components/integration/settings/components/zapier/config/zapier-settings.component";
import { IntegrationSettingsZendeskComponent } from "components/integration/settings/components/zendesk/config/zendesk-settings.component";
import { OnboardingHeaderComponent } from "components/onboarding/onboarding-header/onboarding-header";
import { ReleaseAPIModalComponent } from "components/release/api-modal/api-modal.component";
import { ReleaseCreateModalComponent } from "components/release/create/release-create.component";
import { ReleaseOnboardingModalComponent } from "components/release/onboarding/release-onboarding-modal.component";
import { SettingsDataGovernanceRegistryLimitsComponent } from "components/settings/data-governance/components/registry-limits.component";
import { SettingsDataGovernanceRetentionSelectorComponent } from "components/settings/data-governance/components/retention-selector.component";
import { SettingsDataGovernanceTrackingCapabilityComponent } from "components/settings/data-governance/components/tracking-capability.component";
import { SettingsSurveyCappingConfigComponent } from "components/settings/survey-capping/component/survey-capping-config/survey-capping-config.component";
import { SettingsSurveyCappingSummaryComponent } from "components/settings/survey-capping/component/survey-capping-summary/survey-capping-summary.component";
import { FreeTrialBannerComponent } from "components/super-org/billing/banners/free-trial-banner.component";
import { SettingsBillingSubscriptionPausedSubscriptionAlertComponent } from "components/super-org/billing/banners/paused-subscription-alert.component";
import { UpgradeBanner } from "components/super-org/billing/banners/upgrade-banner.component";
import { UpgradeModal } from "components/super-org/billing/banners/upgrade-modal.component";
import { SettingsBillingLateralPanelComponent } from "components/super-org/billing/billing-lateral-panel/billing-lateral-panel.component";
import { SettingsBillingLateralPanelBillingInfoComponent } from "components/super-org/billing/billing-lateral-panel/components/billing-info.component";
import { SettingsBillingLateralPanelDowngradeComponent } from "components/super-org/billing/billing-lateral-panel/components/downgrade.component";
import { SettingsBillingLateralPanelPaymentMethodComponent } from "components/super-org/billing/billing-lateral-panel/components/payment-method.component";
import { SettingsBillingAddonComponent } from "components/super-org/billing/components/addon-upgrade.component";
import { SettingsBillingPlanComponent } from "components/super-org/billing/components/billing-plan/billing-plan.component";
import { SettingComparePlanModalComponent } from "components/super-org/billing/components/compare-plan.component";
import { SettingsBillingSubscriptionCouponInputComponent } from "components/super-org/billing/components/coupon-input.component";
import { SettingsBillingSubscriptionInvoiceComponent } from "components/super-org/billing/components/invoice-summary/invoice-summary.component";
import { SettingsBillingSubscriptionMTUSliderComponent } from "components/super-org/billing/components/mtu-slider.component";
import { SettingsBillingTestingPaymentMethodHelperComponent } from "components/super-org/billing/components/testing-payment-method-helper.component";
import { SettingsBillingTabInvoicesComponent } from "components/super-org/billing/tab-invoices/tab-invoices.component";
import { SettingsBillingTabPaymentComponent } from "components/super-org/billing/tab-payment/tab-payment.component";
import { SettingsBillingTabSubscriptionComponent } from "components/super-org/billing/tab-subscription/tab-subscription.component";
import { SurveyChannelStatusComponent } from "components/surveys/pages/list/components/channel-survey-status/survey-channel-status.component";
import { TestModeBannerComponent } from "components/surveys/pages/share/share-page/banners/test-mode-banner.component";
import { AdvancedTargetingShareSurveyComponent } from "components/surveys/pages/share/targeting/advanced/advanced.component";
import { CSSSelectorComponent } from "components/surveys/pages/share/targeting/component/css-selector.component";
import { ManualTargetingShareSurveyComponent } from "components/surveys/pages/share/targeting/manual/manual.component";
import { PushNotificationTargetingShareSurveyComponent } from "components/surveys/pages/share/targeting/push-notification/push-notification.component";
import { WizardTargetingShareSurveyComponent } from "components/surveys/pages/share/targeting/wizard/wizard.component";
import { QuestionContentAnalysisComponent } from "components/surveys/pages/stats/content-analysis/question-analysis/question-analysis.component";
import { EmptyDashboardStatsSurveyComponent } from "components/surveys/pages/stats/empty-dashboard/empty-dashboard.component";
import { FiltersCSVExportComponent } from "components/surveys/pages/stats/filters/csv-export/csv-export.component";
import { CriteriaFilterStatsResponseSurveyComponent } from "components/surveys/pages/stats/filters/filter-criteria/filter-criteria-response.component";
import { CriteriaFilterStatsUserSurveyComponent } from "components/surveys/pages/stats/filters/filter-criteria/filter-criteria-user.component";
import { FilterCriteriaFiltersComponent } from "components/surveys/pages/stats/filters/filter-criteria/filters/filter-criteria-filters.component";
import { DateFilterStatsSurveyComponent } from "components/surveys/pages/stats/filters/filter-date/filter-date.component";
import { FiltersStatsSurveyComponent } from "components/surveys/pages/stats/filters/filters.component";
import { FiltersSaveSegmentComponent } from "components/surveys/pages/stats/filters/save-segment/save-segment.component";
import { ShareReportingComponent } from "components/surveys/pages/stats/filters/share/reporting/share-reporting.component";
import { ForwardAtlassianModalComponent } from "components/surveys/pages/stats/forward/forward-atlassian-modal/forward-atlassian-modal.component";
import { CorrelationIndicator } from "components/surveys/pages/stats/indicators/components/correlation-indicator/correlation-indicator.component";
import { ProgressIndicatorStatsSurveyComponent } from "components/surveys/pages/stats/indicators/components/progress-indicator/progress-indicator.component";
import { RepartitionIndicatorStatsSurveyComponent } from "components/surveys/pages/stats/indicators/components/repartition-indicator/repartition-indicator.component";
import { SingleIndicatorStatsSurveyComponent } from "components/surveys/pages/stats/indicators/components/single-indicator/single-indicator.component";
import { SliderIndicatorStatsSurveyComponent } from "components/surveys/pages/stats/indicators/components/slider-indicator/slider-indicator.component";
import { ReleaseChartOverlayComponent } from "components/surveys/pages/stats/indicators/components/trend-indicator/release-overlay/release-overlay.component";
import { TrendIndicatorStatsSurveyComponent } from "components/surveys/pages/stats/indicators/components/trend-indicator/trend-indicator.component";
import { VuMeterIndicatorStatsSurveyComponent } from "components/surveys/pages/stats/indicators/components/vu-meter-indicator/vu-meter-indicator.component";
import { AnswersTableSingleQuestionComponent } from "components/surveys/pages/stats/individual-responses/answers-table-single-question/answers-table-single-question.component";
import { BarChartSingleQuestionComponent } from "components/surveys/pages/stats/individual-responses/bar-chart-single-question/bar-chart-single-question.component";
import { QuestionsTableComponent } from "components/surveys/pages/stats/individual-responses/questions-table/questions-table.component";
import { RangeSingleQuestionComponent } from "components/surveys/pages/stats/individual-responses/range-single-question/range-single-question.component";
import { ShareLateralPanelComponent } from "components/surveys/pages/stats/share-lateral-panel/share-lateral-panel.component";
import { AtlassianShareLateralPanelComponent } from "components/surveys/pages/stats/share-lateral-panel/share-via-atlassian/share-via-atlassian.component";
import { EmailShareLateralPanelComponent } from "components/surveys/pages/stats/share-lateral-panel/share-via-email/share-via-email.component";
import { NotionShareLateralPanelComponent } from "components/surveys/pages/stats/share-lateral-panel/share-via-notion/share-via-notion.component";
import { SlackShareLateralPanelComponent } from "components/surveys/pages/stats/share-lateral-panel/share-via-slack/share-via-slack.component";
import { UserAddToSegmentComponent } from "components/user/common/add-to-segment/add-to-segment.component";
import { UserEventsComponent } from "components/user/common/user-events/user-events.component";
import { UserPropertiesComponent } from "components/user/common/user-properties/user-properties.component";
import { UserRecordsComponent } from "components/user/common/user-records/user-records.component";
import { UserSimpleListComponent } from "components/user/common/user-simple-list/user-simple-list.component";
import { AiHelperComponent } from "components/utils/ai-helper/ai-helper.component";
import { BigCheckbox } from "components/utils/big-checkbox/big-checkbox.component";
import { BigCheckbox2 } from "components/utils/big-checkbox2/big-checkbox2.component";
import { BigRadio } from "components/utils/big-radio/big-radio.component";
import { Card101LinkComponent } from "components/utils/card-101-link/card-101-link.component";
import { CardPodcastComponent } from "components/utils/card-highlight/card-highlight.component";
import { CardQuoteOfTheDayComponent } from "components/utils/card-quote-of-the-day/card-quote-of-the-day.component";
import { CardComponent } from "components/utils/card.component";
import { CheckboxSelectComponent } from "components/utils/checkbox-select/checkbox-select.component";
import { ColorPickerComponent } from "components/utils/color-picker/color-picker.component";
import { DeterminateProgress } from "components/utils/determinate-progress/determinate-progress.component";
import { DonutIndicatorComponent } from "components/utils/donut-indicator/donut-indicator";
import { EditableTextComponent } from "components/utils/editable-text/editable-text.component";
import { ErrorMessageComponent } from "components/utils/error-message/error-message.component";
import { ExpandableBoxComponent } from "components/utils/expandable-box/expandable-box.component";
import { FileImagePickerComponent } from "components/utils/file-image-picker/file-image-picker.component";
import { FontSelect } from "components/utils/font-select/font-select.component";
import { IndeterminateProgress } from "components/utils/indeterminate-progress/indeterminate-progress.component";
import { IndicatorVariationComponent } from "components/utils/indicator-variation/indicator-variation.component";
import {
  IntercomButtonComponent,
  IntercomNzButtonComponent,
} from "components/utils/intercom-button/intercom-button.component";
import { IntercomComponent } from "components/utils/intercom/intercom.component";
import { LanguageSelect } from "components/utils/language-select/language-select.component";
import { MiniTrendIndicatorComponent } from "components/utils/mini-trend-indicator/mini-trend-indicator";
import { OrgAccountAvatarListComponent } from "components/utils/org-account-avatar-list/org-account-avatar-list.component";
import { OrgAccountAvatarComponent } from "components/utils/org-account-avatar/org-account-avatar.component";
import { QuickstartTaskComponent } from "components/utils/quickstart-task/quickstart-task";
import { ScreebIconComponent } from "components/utils/screeb-icon/screeb-icon.component";
import { SquareIconComponent } from "components/utils/square-icon/square-icon.component";
import { TagInputComponent } from "components/utils/tag-input/tag-input.component";
import { TagsComponent } from "components/utils/tags/tags.component";
import { TextSnippetCopyComponent } from "components/utils/text-snippet-copy/text-snippet-copy.component";
import { TrendChartComponent } from "components/utils/trend-chart/trend-chart.component";
import { BrandCardComponent } from "utils/brand-card/brand-card";

import { DropIfSelectPopoverComponent } from "components/funnels/components/drop-if-select/drop-if-select-popover.component";
import { DropIfSelectComponent } from "components/funnels/components/drop-if-select/drop-if-select.component";
import { FunnelAddEventPopoverComponent } from "components/funnels/components/funnel-add-event/funnel-add-event-popover.component";
import { FunnelAddEventComponent } from "components/funnels/components/funnel-add-event/funnel-add-event.component";
import { FunnelGridSumCellComponent } from "components/funnels/components/funnel-grid-sum-cell/funnel-grid-sum-cell.component";
import { FunnelGridSurveyCellComponent } from "components/funnels/components/funnel-grid-survey-cell/funnel-grid-survey-cell.component";
import { FunnelGridComponent } from "components/funnels/components/funnel-grid/funnel-grid.component";
import { FunnelRepartitionComponent } from "components/funnels/components/funnel-repartition/funnel-repartition.component";
import { TargetingRuleAppVersionComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-app-version/rule-app-version.component";
import { TargetingRuleCountryComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-country/rule-country.component";
import { TargetingRuleDateComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-date/rule-date.component";
import { TargetingRuleDelayComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-delay/rule-delay.component";
import { TargetingRuleDeviceComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-device/rule-device.component";
import { TargetingRuleDOMElementComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-dom-element/rule-dom-element.component";
import { TargetingRuleInputNumberComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-input-number/rule-input-number.component";
import { TargetingRuleLanguageComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-language/rule-language.component";
import { TargetingRuleMultipleComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-multiple/rule-multiple.component";
import { TargetingRuleOperatorComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-operator/rule-operator.component";
import { TargetingRuleOrNeitherComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-or-neither/rule-or-neither.component";
import { TargetingRuleRecurrenceComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-recurrence/rule-recurrence.component";
import { TargetingRuleScreenComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-screen/rule-screen.component";
import { TargetingRuleScrollReachElementComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-scroll-reach-element/rule-scroll-reach-element.component";
import { TargetingRuleScrollComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-scroll/rule-scroll.component";
import { TargetingRuleSessionDurationComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-session-duration/rule-session-duration.component";
import { TargetingRuleUrlComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-url/rule-url.component";
import { TargetingRuleUserAliasComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-user-alias/rule-user-alias.component";
import { TargetingRuleUserAnsweredSurveyComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-user-answered-survey/rule-user-answered-survey.component";
import { TargetingRuleUserEventCountComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-user-event-count/rule-user-event-count.component";
import { TargetingRuleUserEventSequenceComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-user-event-sequence/rule-user-event-sequence.component";
import { TargetingRuleUserEventTimeComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-user-event-time/rule-user-event-time.component";
import { TargetingRuleUserPropertyComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-user-property/rule-user-property.component";
import { TargetingRuleUserSegmentComponent } from "components/surveys/pages/share/targeting/advanced/components/rule-user-segment/rule-user-segment.component";
import { UserSegmentSelectComponent } from "components/user/common/user-segment-select/user-segment-select.component";
import { StepsComponent } from "utils/steps/steps.component";

/* BOTMAMANGER BOOTSTRAP */
import { AppComponent } from "./app.component";
import { AppResolver } from "./app.resolver";
import { screebRoutes } from "./app.routes";

/* BUILDER */
import { AddButtonComponent } from "components/builder/components/AddButton/AddButton.component";
import { BuilderLayoutComponent } from "components/builder/components/BuilderLayout/BuilderLayout.component";
import { CardMenuPopinComponent } from "components/builder/components/CardMenuPopin/CardMenuPopin.component";
import { ActionDotComponent } from "components/builder/components/Cards/ActionDot/ActionDot.component";
import { BigCardComponent } from "components/builder/components/Cards/BigCard/BigCard.component";
import { CardTypeComponent } from "components/builder/components/Cards/CardType/CardType.component";
import { CardTypeIconsComponent } from "components/builder/components/Cards/CardTypeIcons/CardTypeIcons.component";
import { InvisibleCardComponent } from "components/builder/components/Cards/InvisibleCard/InvisibleCard.component";
import { SanitizedMessageComponent } from "components/builder/components/Cards/sanitized-message/sanitized-message.component";
import { SmallCardComponent } from "components/builder/components/Cards/SmallCard/SmallCard.component";
import { CheckpointComponent } from "components/builder/components/Checkpoint/Checkpoint.component";
import { ColumnComponent } from "components/builder/components/Columns/Column/Column.component";
import { FlowTagComponent } from "components/builder/components/FlowTag/FlowTag.component";
import { LateralPanelEditButtonComponent } from "components/builder/components/LateralPanel/edit-button/edit-button.component";
import { LateralPanelEditCalendarComponent } from "components/builder/components/LateralPanel/edit-calendar/edit-calendar.component";
import { LateralPanelEditInputComponent } from "components/builder/components/LateralPanel/edit-input/edit-input.component";
import { LateralPanelEditLinkComponent } from "components/builder/components/LateralPanel/edit-link/edit-link.component";
import { LateralPanelEditMessageComponent } from "components/builder/components/LateralPanel/edit-message/edit-message.component";
import { LateralPanelEditRangeComponent } from "components/builder/components/LateralPanel/edit-range/edit-range.component";
import { LateralPanelEditScoreComponent } from "components/builder/components/LateralPanel/edit-score/edit-score.component";
import { LateralPanelEditTextComponent } from "components/builder/components/LateralPanel/edit-text/edit-text.component";
import { LateralPanelEditVideoComponent } from "components/builder/components/LateralPanel/edit-video/edit-video.component";
import { SurveyPreviewVideoModalComponent } from "components/builder/components/LateralPanel/edit-video/video-preview-modal/video-preview-modal.component";
import { SurveyRecordVideoModalComponent } from "components/builder/components/LateralPanel/edit-video/video-record-modal/video-record-modal.component";
import { LateralPanelEmojiPickerPopinComponent } from "components/builder/components/LateralPanel/emoji-picker-popin/emoji-picker-popin.component";
import { LateralPanelIntegrationsComponent } from "components/builder/components/LateralPanel/integrations/integrations.component";
import { LateralPanelComponent } from "components/builder/components/LateralPanel/LateralPanel.component";
import { LateralPanelQuestionSuggestionComponent } from "components/builder/components/LateralPanel/question-suggestion/question-suggestion.component";
import { SimpleButtonComponent } from "components/builder/components/LateralPanel/SimpleButton/SimpleButton.component";
import { PreviewPanelComponent } from "components/builder/components/preview/preview.component";
import { DraggableZoneDirective } from "components/builder/draggable-zone.directive";

import { Auth0AuthorizePageComponent } from "components/auth/page/auth/authorize.component";
import { Auth0LoginPageComponent } from "components/auth/page/auth/login.component";
import { LogoutPageComponent } from "components/auth/page/auth/logout.component";
import { Auth0RegisterPageComponent } from "components/auth/page/auth/register.component";

import { SurveyPreviewAudioModalComponent } from "components/builder/components/LateralPanel/edit-video/audio-preview-modal/audio-preview-modal.component";
import { LateralPanelStepPickerComponent } from "components/builder/components/LateralPanel/step-picker/step-picker.component";
import { ProxyIframeComponent } from "components/builder/components/proxy-iframe/proxy-iframe.component";
import { OnBoardingQualificationPageComponent } from "components/onboarding/onboarding-qualification/onboarding-qualification.component";
import { OnboardingSetupDiscoveryComponent } from "components/onboarding/onboarding-setup/onboarding-setup-discovery/onboarding-setup-discovery.component";
import { OnBoardingSetupPageComponent } from "components/onboarding/onboarding-setup/onboarding-setup.component";
import { CreateOrgModalComponent } from "components/org/create/create.component";
import { OverviewOrgPageComponent } from "components/org/overview/overview.component";
import { QuickstartOrgPageComponent } from "components/org/quickstart/quickstart.component";
import { CreateSuperOrgPageComponent } from "components/super-org/create/create.component";
import { OverviewSuperOrgPageComponent } from "components/super-org/overview/overview.component";
import { AddUserModalComponent } from "components/super-org/users/add/add.component";
import { UsersSuperOrgPageComponent } from "components/super-org/users/users.component";
import { WorkspacesSuperOrgPageComponent } from "components/super-org/workspaces/workspaces.component";
import { RadarIndicatorStatsSurveyComponent } from "components/surveys/pages/stats/indicators/components/radar-indicator/radar-indicator.component";
import { UserRecordDetailsPageComponent } from "components/user-record/details/user-record-details.component";
import { UserRecordListPageComponent } from "components/user-record/list/user-record-list.component";
import { ENV } from "environment";
import { CanCreateSuperOrgGuard } from "guards/can-create-super-org.guard";
import { AccountDao } from "models/account.dao";
import { UserRecordDao } from "models/user-record.dao";
import { FeaturePipe } from "pipes/feature.pipe";
import { UserRecordResolver } from "resolvers/user-record";

registerLocaleData(en);

const routingOpts: ExtraOptions = {
  initialNavigation: "enabledNonBlocking",
  onSameUrlNavigation: "reload",
  useHash: false,
  preloadingStrategy: PreloadAllModules,
  paramsInheritanceStrategy: "always", // childRoutes from /org/:org_id can access $org_id
};

@NgModule({
  imports: [
    // angular modules
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(screebRoutes, routingOpts),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,

    // external modules
    CookieModule.forRoot(),
    DateFnsModule.forRoot(),
    ClipboardModule,
    PickerModule, // emoji picker
    ColorPickerModule,
    NgChartsModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
    }),
    KeyboardShortcutsModule.forRoot(),
    HighlightModule,
    NgxDaterangepickerMd.forRoot(),
    NgxJsonViewerModule,
    NgxStripeModule.forRoot(ENV["STRIPE_PUBLISHABLE_KEY"]),
    MarkdownModule.forRoot(),

    // ng-zorro
    NzLayoutModule,
    NzGridModule,
    NzSpaceModule,
    NzMenuModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzRadioModule,
    NzCheckboxModule,
    NzSelectModule,
    NzSwitchModule,
    NzAutocompleteModule,
    NzStepsModule,
    NzBadgeModule,
    NzDividerModule,
    NzToolTipModule,
    NzPopoverModule,
    NzDropDownModule,
    NzCardModule,
    NzProgressModule,
    NzTableModule,
    NzTagModule,
    NzNotificationModule,
    NzMessageModule,
    NzModalModule,
    NzAffixModule,
    NzEmptyModule,
    NzAvatarModule,
    NzSpinModule,
    NzDatePickerModule,
    NzAlertModule,
    NzTypographyModule,
    NzImageModule,
    NzTabsModule,
    NzTimelineModule,
    NzCollapseModule,
    NzListModule,
    NzSliderModule,
    NzAnchorModule,
    NzBreadCrumbModule,
    NzInputNumberModule,
    NzSkeletonModule,

    // internal module
    IconsProviderModule,
  ],
  declarations: [
    AppComponent,

    /* LAYOUT */
    ParentLayoutComponent,
    MaintenanceComponent,
    LayoutComponent,
    LayoutBackSidebarComponent,
    LayoutBackSidebarStatsComponent,
    LayoutBackSidebarIntegrationComponent,
    LayoutBackSidebarSharingComponent,
    LayoutBackSidebarSettingsComponent,
    LayoutBackHeaderComponent,
    LayoutBackKeyboardShortcutsComponent,
    NotAuthorizedPageComponent,
    PermissionDeniedPageComponent,
    InternalErrorPageComponent,
    NotFoundPageComponent,
    NoOrgaPageComponent,
    EmbeddedExternalPageComponent,

    /* PAGES */
    HomePageComponent,
    CreateWithAIPageComponent,
    // auth
    Auth0LoginPageComponent,
    Auth0RegisterPageComponent,
    Auth0AuthorizePageComponent,
    LogoutPageComponent,
    GrantCallbackComponent,
    Oauth2CallbackComponent,
    // super org
    CreateSuperOrgPageComponent,
    OverviewSuperOrgPageComponent,
    WorkspacesSuperOrgPageComponent,
    UsersSuperOrgPageComponent,
    // org
    CreateOrgModalComponent,
    OverviewOrgPageComponent,
    QuickstartOrgPageComponent,
    AddUserModalComponent,
    // onBoarding
    OnBoardingQualificationPageComponent,
    OnBoardingGoalPageComponent,
    OnBoardingHoldOnComponent,
    OnBoardingBookDemoPageComponent,
    OnBoardingInstallationPageComponent,
    OnBoardingSetupPageComponent,
    OnBoardingInstallationGuideComponent,
    OnboardingSetupDiscoveryComponent,
    OnBoardingDiscoveryComponent,
    OnboardingMobilePreviewComponent,
    OnboardingDesktopPreviewComponent,
    // channel
    CreateChannelPageComponent,
    SettingsChannelInstallPageComponent,
    RegistryIdentityPropertiesListPageComponent,
    RegistryEventsListPageComponent,
    ClientScreensListPageComponent,
    SettingsDataGovernancePageComponent,
    SettingsTeamMembersPageComponent,
    SettingsLanguagePageComponent,
    SettingsLanguageTranslationOptionComponent,
    // settings
    SettingsPageComponent,
    AccountSettingsPageComponent,
    SettingsAccountProviderComponent,
    SettingsAccountMFAComponent,
    SettingsBillingPageComponent,
    SettingsTaggingPageComponent,
    SettingsTaggingEditComponent,
    SettingsSurveyCappingPageComponent,
    SettingsSurveyCustomizationPageComponent,
    IntegrationListPageComponent,
    IntegrationSettingsPageComponent,
    IntegrationSettingsOAuthComponent,
    // survey
    SurveyPageComponent,
    CreateSurveyPageComponent,
    SurveyListPageComponent,
    DuplicateSurveyModalComponent,
    ShareRedirectSurveyPageComponent,
    ShareSurveyPageComponent,
    LinkShareSurveyPageComponent,
    EmailShareSurveyPageComponent,
    StatsSurveyPageComponent,
    OverallPerformancesStatsSurveyPageComponent,
    ContentAnalysisStatsSurveyPageComponent,
    FlowAnalysisStatsSurveyPageComponent,
    IndividualResponsesStatsSurveyPageComponent,
    NPSIndicatorStatsSurveyPageComponent,
    CESIndicatorStatsSurveyPageComponent,
    CSATIndicatorStatsSurveyPageComponent,
    PMFIndicatorStatsSurveyPageComponent,
    SurveySettingsPageComponent,
    DistributionSettingsPageComponent,
    SurveysImportModalComponent,
    ProxyIframeComponent,
    // surveys import
    GetFeedbackImportPageComponent,
    // people
    UserRecordListPageComponent,
    UserRecordDetailsPageComponent,
    UserListPageComponent,
    UserDetailsPageComponent,
    UserAddToSegmentComponent,
    UserRecordsComponent,
    UserEventsComponent,
    UserPropertiesComponent,
    UserSimpleListComponent,
    InviteTeamMembersComponent,
    SegmentListPageComponent,
    SegmentDetailsPageComponent,
    // builder
    BuilderPageComponent,
    // funnel
    FunnelListPageComponent,
    FunnelCreatePageComponent,
    FunnelEditPageComponent,
    FunnelReportPageComponent,
    FunnelHelpComponent,
    // releases
    ReleaseListPageComponent,
    // benchmark
    BenchmarkPageComponent,
    BenchmarkSummaryComponent,

    /* COMPONENTS */
    // analytics
    QuestionContentAnalysisComponent,
    AnswersTableSingleQuestionComponent,
    QuestionsTableComponent,
    BarChartSingleQuestionComponent,
    RangeSingleQuestionComponent,
    EmptyDashboardStatsSurveyComponent,
    SingleIndicatorStatsSurveyComponent,
    SliderIndicatorStatsSurveyComponent,
    VuMeterIndicatorStatsSurveyComponent,
    RepartitionIndicatorStatsSurveyComponent,
    TrendIndicatorStatsSurveyComponent,
    RadarIndicatorStatsSurveyComponent,
    ReleaseChartOverlayComponent,
    CorrelationIndicator,
    ProgressIndicatorStatsSurveyComponent,
    FiltersStatsSurveyComponent,
    DateFilterStatsSurveyComponent,
    CriteriaFilterStatsResponseSurveyComponent,
    CriteriaFilterStatsUserSurveyComponent,
    FilterCriteriaFiltersComponent,
    FiltersCSVExportComponent,
    FiltersSaveSegmentComponent,
    ShareLateralPanelComponent,
    EmailShareLateralPanelComponent,
    NotionShareLateralPanelComponent,
    ForwardAtlassianModalComponent,
    AtlassianShareLateralPanelComponent,
    SlackShareLateralPanelComponent,
    ShareReportingComponent,
    // settings
    TagSettingsBehaviourComponent,
    TagSettingsDesignComponent,
    TagSettingsInteractionsComponent,
    TagSettingsIntegrationsComponent,
    TagSettingsTranslationComponent,
    TagSettingsPreviewComponent,
    TagSettingsWithPreviewPageComponent,
    SelectorUserEventsComponent,
    SelectorUserPropertiesComponent,
    TagRegistryEntrySourceComponent,
    TextSnippetCopyComponent,
    // surveys
    SurveyChannelStatusComponent,
    // targeting
    PushNotificationTargetingShareSurveyComponent,
    ManualTargetingShareSurveyComponent,
    WizardTargetingShareSurveyComponent,
    AdvancedTargetingShareSurveyComponent,
    CSSSelectorComponent,
    TargetingRuleOperatorComponent,
    TargetingRuleOrNeitherComponent,
    TargetingRuleInputNumberComponent,
    TargetingRuleUrlComponent,
    TargetingRuleCountryComponent,
    TargetingRuleLanguageComponent,
    TargetingRuleDeviceComponent,
    TargetingRuleDelayComponent,
    TargetingRuleRecurrenceComponent,
    TargetingRuleScrollComponent,
    TargetingRuleScrollReachElementComponent,
    TargetingRuleDOMElementComponent,
    TargetingRuleSessionDurationComponent,
    TargetingRuleMultipleComponent,
    TargetingRuleScreenComponent,
    TargetingRuleAppVersionComponent,
    TargetingRuleUserEventCountComponent,
    TargetingRuleUserEventTimeComponent,
    TargetingRuleUserPropertyComponent,
    TargetingRuleUserEventSequenceComponent,
    TargetingRuleUserSegmentComponent,
    TargetingRuleUserAliasComponent,
    TargetingRuleUserAnsweredSurveyComponent,
    TargetingRuleDateComponent,
    // integration components
    IntegrationInstructionsComponent,
    IntegrationSettingsHarvestrComponent,
    IntegrationSettingsProductboardComponent,
    IntegrationSettingsZendeskComponent,
    IntegrationSettingsIntercomComponent,
    IntegrationSettingsTrelloComponent,
    IntegrationSettingsCycleComponent,
    IntegrationSettingsMondayComponent,
    IntegrationSettingsAsanaComponent,
    IntegrationSettingsTypeformComponent,
    IntegrationSettingsTypeformEditComponent,
    IntegrationSettingsWebhookComponent,
    IntegrationSettingsWebhookEditComponent,
    IntegrationSettingsStitchComponent,
    IntegrationSettingsStitchEditComponent,
    IntegrationSettingsZapierComponent,
    IntegrationSettingsZapierEditComponent,
    IntegrationSettingsCobbaiComponent,
    IntegrationSettingsCobbaiEditComponent,
    IntegrationSettingsSlackComponent,
    IntegrationSettingsNotionComponent,
    IntegrationSettingsAtlassianComponent,
    IntegrationSettingsSegmentSourceComponent,
    IntegrationSettingsRudderStackSourceComponent,
    IntegrationSettingsSegmentDestinationComponent,
    IntegrationSettingsRudderstackDestinationComponent,
    IntegrationSettingsAmplitudeSourceComponent,
    IntegrationSettingsAmplitudeDestinationComponent,
    // utils
    Card101LinkComponent,
    CardQuoteOfTheDayComponent,
    CardPodcastComponent,
    ErrorMessageComponent,
    ColorPickerComponent,
    FileImagePickerComponent,
    TagsComponent,
    SquareIconComponent,
    BigCheckbox,
    BigCheckbox2,
    BigRadio,
    IntercomButtonComponent,
    IntercomNzButtonComponent,
    IntercomComponent,
    LanguageSelect,
    FontSelect,
    CardComponent,
    UpgradeModal,
    UpgradeBanner,
    FreeTrialBannerComponent,
    TestModeBannerComponent,
    OrgAccountAvatarComponent,
    OrgAccountAvatarListComponent,
    IndicatorVariationComponent,
    MiniTrendIndicatorComponent,
    DonutIndicatorComponent,
    CheckboxSelectComponent,
    EditableTextComponent,
    IndeterminateProgress,
    DeterminateProgress,
    ScreebIconComponent,
    BrandCardComponent,
    ExpandableBoxComponent,
    QuickstartTaskComponent,
    BrandCardComponent,
    TagInputComponent,
    // capping components
    SettingsSurveyCappingConfigComponent,
    SettingsSurveyCappingSummaryComponent,
    // billing components
    SettingsBillingLateralPanelComponent,
    SettingsBillingLateralPanelPaymentMethodComponent,
    SettingsBillingLateralPanelBillingInfoComponent,
    SettingsBillingLateralPanelDowngradeComponent,
    SettingsBillingTabPaymentComponent,
    SettingsBillingTabSubscriptionComponent,
    SettingsBillingTabInvoicesComponent,
    SettingsBillingTestingPaymentMethodHelperComponent,
    SettingsBillingSubscriptionCouponInputComponent,
    SettingsBillingSubscriptionMTUSliderComponent,
    SettingsBillingSubscriptionPausedSubscriptionAlertComponent,
    SettingsBillingSubscriptionInvoiceComponent,
    SettingsBillingPlanComponent,
    SettingComparePlanModalComponent,
    SettingsBillingAddonComponent,
    // data governance
    SettingsDataGovernanceRetentionSelectorComponent,
    SettingsDataGovernanceRegistryLimitsComponent,
    SettingsDataGovernanceTrackingCapabilityComponent,
    // funnel
    FunnelGridComponent,
    FunnelGridSurveyCellComponent,
    FunnelGridSumCellComponent,
    FunnelRepartitionComponent,
    FunnelAddEventComponent,
    FunnelAddEventPopoverComponent,
    DropIfSelectComponent,
    DropIfSelectPopoverComponent,
    // releases
    ReleaseCreateModalComponent,
    ReleaseOnboardingModalComponent,
    ReleaseAPIModalComponent,
    TrendChartComponent,
    UserSegmentSelectComponent,

    /* PIPES */
    SafePipe,
    PricePipe,
    ResponseCompletionPipe,
    ResponseCompletionColorPipe,
    ToLocaleStringPipe,
    PluralSingularPipe,
    UppercaseFirstPipe,
    PermissionPipe,
    SuperPermissionPipe,
    EntitlementPipe,
    FeaturePipe,

    /* UTILS */
    BadgeComponent,
    FormErrorComponent,
    LoaderComponent,
    HelpPopoverComponent,
    ScreebDatePickerComponent,
    OnboardingHeaderComponent,
    StepsComponent,
    MediaStreamDirective,
    AiHelperComponent,

    /* BUILDER */
    BuilderLayoutComponent,
    DraggableZoneDirective,
    ColumnComponent,
    BigCardComponent,
    InvisibleCardComponent,
    SmallCardComponent,
    CardTypeComponent,
    CardTypeIconsComponent,
    ActionDotComponent,
    SanitizedMessageComponent,
    FlowTagComponent,
    AddButtonComponent,
    CheckpointComponent,
    CardMenuPopinComponent,
    PreviewPanelComponent,
    LateralPanelComponent,
    LateralPanelEmojiPickerPopinComponent,
    SimpleButtonComponent,
    LateralPanelEditTextComponent,
    LateralPanelEditVideoComponent,
    SurveyRecordVideoModalComponent,
    SurveyPreviewVideoModalComponent,
    SurveyPreviewAudioModalComponent,
    LateralPanelEditButtonComponent,
    LateralPanelEditInputComponent,
    LateralPanelEditLinkComponent,
    LateralPanelEditCalendarComponent,
    LateralPanelEditScoreComponent,
    LateralPanelEditRangeComponent,
    LateralPanelStepPickerComponent,
    LateralPanelIntegrationsComponent,
    LateralPanelQuestionSuggestionComponent,
    LateralPanelEditMessageComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          _: Sentry.TraceService,
          handler: HttpBackend,
          configService: ConfigService,
        ) =>
        () => {
          // Let's initialize the config service
          return configService.loadConfig(handler).then(() => {
            startLogging(configService.config);
          });
        },
      deps: [Sentry.TraceService, HttpBackend, ConfigService],
      multi: true,
    },
    AppResolver,

    /* SERVICES */
    AnalyticsFilterService,
    ApplicationLoadService,
    ConfigService,
    AuthService,
    SessionService,
    EventService,
    LoggerService,
    FeatureFlaggingService,
    RoutingService,
    SeoService,
    SettingsService,
    TrackersService,
    UIService,
    EntitlementService,
    MediaUploadService,
    PermissionsService,
    OAuth2Service,
    MJMLService,
    UpgradeAdminService,
    TagStorageService,
    TagEditorService,
    ImportService,

    /* OTHER LIBS SERVICES */
    { provide: NZ_I18N, useValue: en_US },
    // { provide: NZ_DATE_LOCALE, useValue: enUS },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        languages: {
          javascript: () => import("highlight.js/lib/languages/javascript"),
          xml: () => import("highlight.js/lib/languages/xml"),
          kotlin: () => import("highlight.js/lib/languages/kotlin"),
          swift: () => import("highlight.js/lib/languages/swift"),
        },
      },
    },

    /* OTHER SERVICES */
    LayoutBackHeaderService,

    /* GUARDS */
    IsAuthGuard,
    IsNotAuthGuard,
    EnsureNotAuthGuard,
    GenericCanDeactivate,
    IsIntegrationSlugValidGuard,
    DoOrgHasSurveysGuard,
    OnBoardingGuard,
    CanCreateSuperOrgGuard,

    /* RESOLVERS */
    AssetLanguagesAndCountriesResolver,
    AssetIndustriesScoresResolver,
    ChannelResolver,
    ChannelsResolver,
    ChannelClientScreensResolver,
    ChannelClientVersionsResolver,
    ChannelsClientVersionsResolver,
    SurveyTagsResolver,
    SurveyResolver,
    SurveysResolver,
    SurveyDistributionsBySurveyResolver,
    SurveyDistributionBySurveyAndChannelResolver,
    SurveyScenarioNodeResolver,
    OrgTargetingRulesResolver,
    OrgAccountsResolver,
    SuperOrgAccountsResolver,
    SuperOrgResolver,
    SuperOrgsResolver,
    OrgResolver,
    WorkspacesResolver,
    OrgBillingInvoicesResolver,
    OrgBillingPaymentMethodsResolver,
    OrgBillingResolver,
    OrgBillingSubscriptionResolver,
    UserResolver,
    UserRecordResolver,
    UserResponsesByUserIdResolver,
    RegistryIdentityPropertyResolver,
    RegistryGroupResolver,
    RegistryEventsResolver,
    OrgIntegrationsResolver,
    OrgIntegrationResolver,
    OrgIntegrationOrNullResolver,
    IntegrationAtlassianProjectsResolver,
    SequenceResolver,
    SequencesResolver,
    OrgReleasesResolver,
    SuperOrgWorkspacesResolver,
    OrgIndustryStatsResolver,
    TagEditorTokenResolver,

    /* DAO */
    AnalyticsDao,
    UserDao,
    UserRecordDao,
    ResponseDao,
    SurveyDao,
    SurveyDistributionDao,
    SurveyScenarioNodeDao,
    ChannelDao,
    SuperOrgDao,
    OrgDao,
    RegistryDao,
    AccountDao,
    IntegrationDao,
    TagSettingsDao,
    UploadDao,
    EmailDao,
    SequenceDao,
    ReleaseDao,
    GetFeedbackDao,
    TagEditorTokenDao,

    /* STORES */
    BuilderStore,

    /* HELPERS */
    ScreebApiHelper,
    NotificationHelper,

    /* UTILS */

    // page load error (first load)
    // commented in order to use sentry instead
    {
      provide: ErrorHandler,
      useClass: RoutingService,
    },

    // Sentry
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
