<nz-modal
  [(nzVisible)]="isOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzClosable]="false"
  (nzOnCancel)="handleCancel()"
  nzWidth="450px"
  nzClassName="surveys-import-modal"
>
  <ng-template #modalTitle>
    <h3>Import surveys</h3>
    <p>Have existing surveys?<br />Import them to Screeb and use them now.</p>
  </ng-template>

  <ng-template #modalContent>
    <a
      routerLink="/org/{{ org.id }}/survey/import/getfeedback"
      class="surveys-import-modal__tool"
    >
      <img src="/assets/external/getfeedback.png" alt="GetFeedback" />
    </a>
  </ng-template>

  <ng-template #modalFooter>
    <button
      nz-button
      nzType="default"
      class="surveys-import-cancel-btn"
      (click)="handleCancel()"
    >
      Close
    </button>
  </ng-template>
</nz-modal>
