import { Component, Input, OnInit } from "@angular/core";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";

import { TagSettings } from "models/tag-settings.types";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { Org } from "models/org.model";

@Component({
  selector: "tag-settings-behaviour",
  templateUrl: "./tag-settings-behaviour.component.html",
  styleUrls: ["./tag-settings-behaviour.component.scss"],
})
export class TagSettingsBehaviourComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public tagSettings: TagSettings = null;
  @Input() public scope: "org" = "org" as const;

  public responseExpirationOptions: NzSelectOptionInterface[] = [
    { label: "1 minute", value: 60 },
    { label: "5 minutes", value: 300 },
    { label: "15 minutes", value: 900 },
    { label: "1 hour", value: 3600 },
    { label: "1 day", value: 86400 },
    { label: "1 week", value: 604800 },
    { label: "1 month", value: 2419200 },
  ];

  public tagSettingsDiff: TagSettings = null;

  public error: string = null;

  constructor(public featureFlaggingService: FeatureFlaggingService) {}

  ngOnInit() {
    this.tagSettingsDiff = { ...this.tagSettings };
  }

  public hasNotChanged() {
    return (
      JSON.stringify(this.tagSettings) === JSON.stringify(this.tagSettingsDiff)
    );
  }
}
