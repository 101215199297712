import { Component, Input } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";

@Component({
  selector: "rule-delay",
  templateUrl: "./rule-delay.component.html",
  styleUrls: ["./rule-delay.component.scss"],
})
export class TargetingRuleDelayComponent {
  @Input()
  public rule: SurveyTargetingRule = null;

  constructor() {}

  public checkValidValue(r: SurveyTargetingRule): boolean {
    if (r.value.v_n === null || r.value.v_n === undefined) return false;
    return true;
  }
}
