import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { AuthService } from "services/auth.service";
import { RoutingService } from "services/routing.service";

@Component({
  template: ` <div class="logout-page"></div>`,
  selector: "page-logout",
})
export class LogoutPageComponent implements PageComponentInterface, OnInit {
  public title = "Logout to Screeb 🚀";
  public name = "Logout";

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.authService.authStatus = "authenticating";

    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.authService.logout();
  }
}
