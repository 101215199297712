<div class="card" [style]="{ backgroundColor: color }">
  <div class="title-group">
    <p
      id="bold-title"
      [innerHTML]="title + ' ' + sessionService.session.firstname + ' 👋'"
    ></p>
    <!-- <p class="title">QUOTE OF THE DAY</p> -->
    <p>
      <span class="quote">
        {{ quote }}
      </span>
    </p>
    <p class="author" *ngIf="author">{{ author }}</p>
  </div>
</div>
