<header>
  <h1 *ngIf="channel.type === 'widget'">Share in my web app</h1>
  <h1 *ngIf="channel.type === 'android'">Share in my Android app</h1>
  <h1 *ngIf="channel.type === 'ios'">Share in my iOS app</h1>
  <h1 *ngIf="channel.type === 'hosted-page'">
    <ng-container *ngIf="linkMode === 'link'">Share with a Link</ng-container>
    <ng-container *ngIf="linkMode === 'email'">Share by Email</ng-container>
  </h1>

  <div
    *ngIf="
      channel.type === 'hosted-page' &&
      ('isEmailSurveysEnabled' | hasFeatureFlag)
    "
    class="link-mode-switch"
  >
    <nz-radio-group
      [ngModel]="linkMode"
      (ngModelChange)="setLinkMode($event)"
      nzButtonStyle="solid"
      nzSize="large"
    >
      <label nz-radio-button nzValue="email"> Embed in Email </label>
      <label nz-radio-button nzValue="link"> Link </label>
    </nz-radio-group>
  </div>
</header>

<free-trial-banner />

<test-mode-banner *ngIf="isTestModeEnabled()"></test-mode-banner>

<div class="container">
  <section
    id="channel-targeting-widget-mobile"
    *ngIf="['widget', 'android', 'ios'].includes(channel.type)"
  >
    <section id="channel-targeting">
      <h2>User segmentation</h2>

      <div class="channel-targeting-mode-switch">
        <nz-radio-group
          [(ngModel)]="editMode"
          nzButtonStyle="solid"
          nzSize="large"
        >
          <label
            *ngIf="channel.type === 'android' || channel.type === 'ios'"
            nz-radio-button
            nzValue="push-notification"
            >Notification</label
          >
          <label
            *ngIf="survey.interaction !== 'in_page'"
            nz-radio-button
            nzValue="manual"
            >Manual</label
          >
          <label
            nz-radio-button
            nzValue="wizard"
            [nzDisabled]="!isWizardAvailable()"
            >Simple</label
          >
          <label nz-radio-button nzValue="advanced">Advanced</label>
        </nz-radio-group>
      </div>
    </section>

    <section
      id="channel-targeting-push-notification"
      *ngIf="editMode === 'push-notification'"
    >
      <survey-share-targeting-push-notification
        [surveyId]="survey.id"
        [channelType]="channel.type"
      >
      </survey-share-targeting-push-notification>
    </section>

    <section id="channel-targeting-manual" *ngIf="editMode === 'manual'">
      <survey-share-targeting-manual
        [surveyId]="survey.id"
        [channelType]="channel.type"
      >
      </survey-share-targeting-manual>
    </section>

    <section id="channel-targeting-wizard" *ngIf="editMode === 'wizard'">
      <survey-share-targeting-wizard
        [channel]="channel"
        [surveyId]="survey.id"
        [distribId]="surveyDistribution.id"
        [orgId]="org.id"
        [rules]="targetingRules"
        (rulesChange)="onTargetingRulesChange($event)"
        [channelClientScreens]="channelClientScreens"
        [languages]="languages"
        [countries]="countries"
        [interaction]="survey.interaction"
        [(selectorCSS)]="surveyDistribution.selector_css"
      ></survey-share-targeting-wizard>
    </section>

    <section id="channel-targeting-advanced" *ngIf="editMode === 'advanced'">
      <survey-share-targeting-advanced
        [isOrgTargeting]="false"
        [org]="org"
        [channel]="channel"
        [surveyId]="survey.id"
        [distribId]="surveyDistribution.id"
        [rules]="targetingRules"
        (rulesChange)="onTargetingRulesChange($event)"
        [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
        [registryEntriesEvent]="registryEntriesEvent"
        [registryEntriesGroup]="registryEntriesGroup"
        [channelClientScreens]="channelClientScreens"
        [channelClientVersions]="channelClientVersions"
        [languages]="languages"
        [countries]="countries"
        [surveys]="surveys"
        [interaction]="survey.interaction"
        [(selectorCSS)]="surveyDistribution.selector_css"
      >
      </survey-share-targeting-advanced>
    </section>

    <section class="test-mode" *ngIf="editMode !== 'manual'">
      <div class="test-mode-title">
        <h2>
          Activate test mode for this
          {{ uiService.isMessagePage ? "message" : "survey" }}
        </h2>
        <nz-switch
          [ngModel]="testModeRule?.value?.v_b && !recurrenceRule"
          (ngModelChange)="onTestModeChange($event)"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
          [nzDisabled]="!!recurrenceRule"
        ></nz-switch>
        <ng-template #checkedTemplate
          ><i nz-icon nzType="check"></i
        ></ng-template>
        <ng-template #unCheckedTemplate
          ><i nz-icon nzType="close"></i
        ></ng-template>
      </div>

      <div
        *ngIf="isTestModeEnabled()"
        class="test-mode-content"
        [style]="{
          height: 'auto'
        }"
      >
        <test-mode-banner></test-mode-banner>
      </div>
    </section>

    <section class="capping-selection" *ngIf="editMode !== 'manual'">
      <div class="capping-selection-title">
        <h2>
          Limit the number of displays and responses for this
          {{ uiService.isMessagePage ? "message" : "survey" }}
        </h2>
        <nz-switch
          [ngModel]="overrideSurveyCappingSwitch"
          (ngModelChange)="onCappingTargetingChange($event)"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
        ></nz-switch>
        <screeb-icon
          *ngIf="!('survey_capping' | hasEntitlement)"
          size="sm"
          icon="upgrade"
        />
        <ng-template #checkedTemplate
          ><i nz-icon nzType="check"></i
        ></ng-template>
        <ng-template #unCheckedTemplate
          ><i nz-icon nzType="close"></i
        ></ng-template>
      </div>

      <div
        *ngIf="overrideSurveyCappingSwitch"
        class="capping-selection-content"
        [style]="{
          height: ('survey_capping' | hasEntitlement) ? 'auto' : 450 + 'px'
        }"
      >
        <upgrade-modal
          [offsetY]="20"
          [centeredModal]="true"
          class="upgrade-modal"
          upgradeType="survey_capping"
          ctaText="Upgrade Now!"
          *ngIf="!('survey_capping' | hasEntitlement)"
          [orgId]="org.id"
        ></upgrade-modal>

        <div
          [ngClass]="{
            'is-locked': !('survey_capping' | hasEntitlement)
          }"
        >
          <!--Config Global Capping-->
          <p>
            Cap the displays and responses of this
            {{ uiService.isMessagePage ? "message" : "survey" }}, over a period
            of time.<br />
            This will be only applied to this
            {{ uiService.isMessagePage ? "message" : "survey" }} and will
            override
            <a href="/org/{{ org.id }}/settings/survey-capping"
              >General
              {{ uiService.isMessagePage ? "Messages" : "Surveys" }} Capping
              settings</a
            >.
          </p>
          <survey-capping-config
            [(rules)]="targetingRules"
            surveyCappingType="per-survey"
          ></survey-capping-config>
        </div>
      </div>
    </section>

    <div nz-row class="resources-guide">
      <h2>User Segmentation Resources</h2>

      <section class="tuto">
        <card-101-link
          *ngFor="let post of posts101"
          class="treemap-col"
          [page]="name"
          [title]="post.title"
          [color]="post.color"
          [url]="post.url"
        ></card-101-link>
      </section>
    </div>

    <div class="clearfix"></div>
  </section>

  <section
    id="channel-targeting-hosted-page"
    *ngIf="['hosted-page'].includes(channel.type)"
  >
    <page-survey-share-link
      *ngIf="linkMode === 'link'"
      [org]="org"
      [channel]="channel"
      [survey]="survey"
      [multiple]="isMultipleEnabled()"
      (multipleChange)="onTargetingRuleMultipleChange($event)"
    ></page-survey-share-link>
    <page-survey-share-email
      *ngIf="linkMode === 'email'"
      [org]="org"
      [channel]="channel"
      [survey]="survey"
    ></page-survey-share-email>
  </section>
</div>

<ng-template #pageActions>
  <div class="toolbar" *ngIf="'survey.distribution.update' | hasPermission">
    <nz-button-group>
      <button
        title="Save"
        nz-button
        nzSize="large"
        nzShape="round"
        [nzLoading]="saving"
        (click)="onSave()"
        [disabled]="!hasUnsavedChanges()"
      >
        <screeb-icon size="sm" icon="save"></screeb-icon>
        <span>Save</span>
      </button>
    </nz-button-group>
    <nz-button-group nzSize="large" class="actions-save" *ngIf="!active">
      <button
        id="status-launch"
        class="ant-btn-secondary"
        title="Set live"
        nz-button
        nzSize="large"
        nzShape="round"
        [nzLoading]="enabling"
        (click)="onEnable(true)"
        [disabled]="!canLaunch()"
      >
        <screeb-icon size="sm" icon="launch"></screeb-icon>
        <span>Set live</span>
      </button>
    </nz-button-group>

    <nz-button-group>
      <button
        id="status-pause"
        title="pause"
        class="ant-btn-secondary"
        nzGhost
        *ngIf="active"
        nz-button
        nzSize="large"
        nzShape="round"
        [nzLoading]="enabling"
        (click)="onEnable(false)"
        [disabled]="!canLaunch()"
      >
        <screeb-icon size="sm" icon="pause"></screeb-icon>
        <span>Pause</span>
      </button>
    </nz-button-group>

    <div class="pending-mode-popover" *ngIf="active && !isInStartEndDates()">
      This survey is currently active but not displayed, as it is outside the
      selected date and time range.
    </div>
  </div>
</ng-template>
