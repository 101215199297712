import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ChannelType } from "models/channel.model";
import {
  ChannelTypeToRegistryEntrySource,
  RegistryEntry,
  RegistryEntrySource,
  RegistryEntrySourceFormatted,
  RegistryEntrySourceIsIntegration,
  sortRegistryEntries,
  sortedRegistryEntrySources,
} from "models/registry.model";

@Component({
  selector: "selector-user-events",
  templateUrl: "./selector-user-events.component.html",
  styleUrls: ["./selector-user-events.component.scss"],
})
export class SelectorUserEventsComponent {
  @Input() public channelType: ChannelType;
  @Input() public registryEntriesEvent: RegistryEntry[];
  @Input() public invalid: boolean = false;
  @Input() public disabled: boolean = false;

  @Input() public eventNameIds: string[];
  @Output() public eventNameIdsChange = new EventEmitter<string[]>();

  public sortedRegistryEntrySources = sortedRegistryEntrySources;
  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;

  constructor() {}

  public getEventNamesBySource(source: RegistryEntrySource) {
    const sourceIsCurrentChannel =
      source === ChannelTypeToRegistryEntrySource[this.channelType];
    const isIntegrationSource = RegistryEntrySourceIsIntegration[source];

    if (!sourceIsCurrentChannel && !isIntegrationSource) {
      return [];
    }

    return (
      this.registryEntriesEvent
        // .filter((prop) => !prop.hidden)
        .filter((prop) => {
          return prop.sources.includes(source);
        })
        .sort(sortRegistryEntries)
    );
  }
}
