import { Injectable } from "@angular/core";

import {
  BigCardContainer,
  CardContainer,
  CardContainerType,
  InvisibleCard,
  SmallCardContainer,
} from "components/builder/components/BuilderLayout/Models";
import {
  GraphNode,
  ScenarioGraphBuilder,
  graphNodeCompare,
} from "components/builder/flow";
import { Column } from "components/builder/page-builder.component";
import {
  newRoutingEffectToEnd,
  newRoutingEffectToNode,
  routingGetNextNodeId,
  routingSetCase,
} from "components/builder/routing";
import { SimpleBucket } from "components/surveys/pages/stats/indicators/indicator.utils";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import {
  ActionButton,
  ActionScoring,
  CTAAction,
  CTAType,
  I18nTextLabel,
  ScenarioNode,
  ScenarioQuestion,
  ScenarioQuestionType,
  ScenarioQuestionTypeMessage,
  SurveyLanguages,
  SurveyScenario,
  UUID,
  getActionsFromQuestion,
  getI18nTextLabelTranslation,
  messageNodeTypesSettings,
  newMessageQuestion,
  newSurveyQuestion,
  newTextLabel,
  newVideoLabel,
} from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { TagSettings, TagSettingsFormat } from "models/tag-settings.types";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { arrayToSet } from "utils/array";
import { uuidv4 } from "utils/uuid";
import { v4 } from "uuid";

@Injectable()
class BuilderStore {
  public org: Org = null;
  public survey: Survey = null;
  public orgIntegrations: Integration[] = null;
  public currentLanguage: SurveyLanguages;
  public languagesAndCountries: LanguageWithEmoji[];
  public availableLanguages: LanguageWithEmoji[];
  public messageUrl: string;

  public nodes: GraphNode[];
  public nodesById: object;
  public nodesPerColumns: GraphNode[][]; // nodes grouped per column

  public readOnly = false;
  public showPathCount = false;

  public save: () => Promise<any>;

  constructor() {}

  public initBuilder(
    org: Org,
    survey: Survey,
    integrations: Integration[],
    languagesAndCountries: LanguageWithEmoji[],
    save: () => Promise<any>,
    readOnly = false,
    showPathCount = false,
  ) {
    this.org = org;
    this.survey = survey;
    this.orgIntegrations = integrations;
    this.currentLanguage = this.survey.scenario.default_language;
    this.languagesAndCountries = languagesAndCountries;

    const orgAndSurveyAvailableLanguages = arrayToSet(
      org.survey_languages.concat(this.getSurveyScenarioLanguages()),
    );

    this.availableLanguages = languagesAndCountries.filter((language) =>
      orgAndSurveyAvailableLanguages.some(
        (availableLanguage) => availableLanguage === language.value,
      ),
    );

    this.save = save;
    this.readOnly = readOnly;

    this.showPathCount = showPathCount;
  }

  public getSurveyFormat(): TagSettingsFormat {
    return this.getSurveySettings().format || "conversationnal";
  }

  public getSurveySettings(): TagSettings {
    return {
      ...this.org.settings,
      ...this.survey.settings,
    };
  }

  getQuestionPathCountForNodeId(nodeId: string, questionsPath: SimpleBucket[]) {
    const [shortenedId] = nodeId.split("-");

    return questionsPath
      .filter(({ key }) => key.includes(shortenedId))
      .reduce((a, b) => a + b.doc_count, 0);
  }

  getAnswerPathCountForAnswerId(answerId: string, answersPath: SimpleBucket[]) {
    const [shortenedId] = answerId.split("-");

    return answersPath
      .filter(({ key }) => key.includes(shortenedId))
      .reduce((a, b) => a + b.doc_count, 0);
  }

  private getLabelForQuestion(node: GraphNode, question: ScenarioQuestion) {
    if (question.type && question.type !== "survey") {
      return newTextLabel(
        messageNodeTypesSettings[question.type].title,
        this.currentLanguage,
      );
    }

    if (question.type === "survey") {
      if (question.messages?.[0]?.type === "video") {
        return (
          question.messages?.[0]?.video ?? newVideoLabel(this.currentLanguage)
        );
      }

      if (
        question.messages?.[0]?.type === "text" &&
        question.messages?.[0]?.text
      ) {
        return question.messages?.[0]?.text;
      }
    }

    return newTextLabel(question.description, this.currentLanguage);
  }

  private getSubLabelsForQuestion(
    node: GraphNode,
    question: ScenarioQuestion,
  ): string[] {
    const subLabels: string[] = [];
    if (question.call_to_action.type === "calendar") {
      const subLabel = getI18nTextLabelTranslation(
        question.call_to_action.calendar.payload.url as I18nTextLabel,
        this.currentLanguage,
        this.survey.scenario.default_language,
      );
      if (subLabel?.length) {
        try {
          // Get host from url and uppercase first letter
          // Yeah i know, it's a bit hacky but it's the easiest way to do it
          const host = new URL(subLabel).hostname;
          subLabels.push(host.charAt(0).toUpperCase() + host.slice(1));
        } catch (_) {
          subLabels.push("Invalid calendar link");
        }
      } else {
        subLabels.push("No calendar link");
      }
    } else if (
      question.call_to_action.type === "multiple_choice" ||
      question.call_to_action.type === "link"
    ) {
      if (question.call_to_action.randomize) {
        subLabels.push("Randomized");
      }
      if (question.call_to_action.responsive) {
        subLabels.push("Responsive");
      }
      if (
        question.call_to_action.type === "multiple_choice" &&
        question.call_to_action.multiple
      ) {
        subLabels.push("Multiple");
      }
    } else if (
      question.call_to_action.type === "nps" ||
      question.call_to_action.type === "ces" ||
      question.call_to_action.type === "scoring" ||
      question.call_to_action.type === "csat"
    ) {
      if (question.call_to_action.responsive) {
        subLabels.push("Responsive");
      }
    }

    if (node.node.integrations.webhook?.items?.length) {
      subLabels.push("Webhook");
    }

    if (node.node.integrations.zapier?.items?.length) {
      subLabels.push("Zapier");
    }

    return subLabels;
  }

  public refreshCards(
    answersPath: SimpleBucket[] = [],
    questionsPath: SimpleBucket[] = [],
  ) {
    const createEmptyColumn = (): Column => ({
      cards: [],
      exitDotCount: 0,
    });

    try {
      this.computeNodeGraph();

      const allCards: CardContainer[] = [];
      const columnsWithCards = this.nodesPerColumns.map(
        (rawColumn, columnIndex) => {
          let cardIndex = 0;
          const column = createEmptyColumn();
          const cards = rawColumn.reduce<CardContainer[]>(
            (cardsAccumulator, node: GraphNode) => {
              const question = node.node.question;
              if (!question) throw Error("unexpected null question");

              const label = this.getLabelForQuestion(node, question);
              const subLabels = this.getSubLabelsForQuestion(node, question);
              let targetNodeId: string | null = null;
              let skipTargetNodeId: string | null = null;
              let action: CTAAction | null = null;

              // actions will be used to link all the multiple choice answers to the same target node
              // in case of multiples values context
              let actions: CTAAction[] | null = null;
              let multiple: boolean = false;
              {
                switch (question.call_to_action.type) {
                  case "input":
                    targetNodeId = node.nextNodeIds[0];
                    skipTargetNodeId = node.skipToNodeId;
                    action = question.call_to_action.input;
                    break;
                  case "range":
                    targetNodeId = node.nextNodeIds[0];
                    skipTargetNodeId = node.skipToNodeId;
                    action = question.call_to_action.range;
                    break;
                  case "none": // no cta -> we probably have some text message
                    targetNodeId = node.nextNodeIds[0];
                    skipTargetNodeId = node.skipToNodeId;
                    break;
                  case "multiple_choice":
                  case "pmf":
                    multiple = question.call_to_action.multiple;
                    skipTargetNodeId = node.skipToNodeId;

                    if (multiple) {
                      // pray for having the same id for all the choices
                      action = question.call_to_action.choices[0];
                      targetNodeId = routingGetNextNodeId(
                        node.node.routing,
                        action.id,
                      );
                      actions = question.call_to_action.choices;
                    }
                    break;
                  case "calendar":
                    targetNodeId = node.nextNodeIds[0];
                    skipTargetNodeId = node.skipToNodeId;
                    break;
                  default:
                    // likely a skippable question with small cards
                    skipTargetNodeId = node.skipToNodeId;
                    break;
                }
              }

              const nodePathCount = this.getQuestionPathCountForNodeId(
                node.correlationId,
                questionsPath,
              );

              let pathAnswerCount = 0;
              let skipPathAnswerCount = 0;

              if (
                node.node.question?.call_to_action.type === "input" ||
                node.node.question?.call_to_action.type === "range"
              ) {
                pathAnswerCount = this.getAnswerPathCountForAnswerId(
                  action.correlation_id,
                  answersPath,
                );
              }

              if (question.skip_action) {
                skipPathAnswerCount = this.getAnswerPathCountForAnswerId(
                  question.skip_action.correlation_id,
                  answersPath,
                );
              }

              const bigCard: BigCardContainer = {
                columnIndex,
                cardIndex: cardIndex++,
                type:
                  !question.type || question.type === "survey"
                    ? question.call_to_action.type
                    : question.type,
                letter: node.name,
                label,
                subLabel: subLabels.length ? subLabels.join(", ") : undefined,
                node,
                targetNodeId,
                skipTargetNodeId,
                id: node.id,
                multiple: multiple,
                action,
                actions,
                component: CardContainerType.BigCard,
                pathCount: nodePathCount,
                pathCountPercent: nodePathCount
                  ? (pathAnswerCount * 100) / nodePathCount
                  : 0,
                skipPathCountPercent: nodePathCount
                  ? (skipPathAnswerCount * 100) / nodePathCount
                  : 0,
              };
              cardsAccumulator.push(bigCard);
              allCards.push(bigCard);

              const createBaseSmallCard = (
                action: ActionButton | ActionScoring,
              ): SmallCardContainer => {
                const pathAnswerCount = this.getAnswerPathCountForAnswerId(
                  action.correlation_id,
                  answersPath,
                );

                return {
                  columnIndex,
                  cardIndex: cardIndex++,
                  originCards: [bigCard],
                  component: CardContainerType.SmallCard,
                  emoji: action.payload.emoji,
                  node,
                  // Don't show target line for small cards with multiple answers
                  targetNodeId: multiple
                    ? null
                    : routingGetNextNodeId(node.node.routing, action.id),
                  multiple: multiple,
                  // Small card can't be skipped independently
                  skipTargetNodeId: null,
                  action,
                  pathCount: pathAnswerCount,
                  pathCountPercent: nodePathCount
                    ? (pathAnswerCount * 100) / nodePathCount
                    : 0,
                };
              };

              switch (question.call_to_action.type) {
                case "multiple_choice":
                case "pmf":
                case "link":
                  question.call_to_action.choices.forEach((choice) => {
                    const card = {
                      ...createBaseSmallCard(choice),
                      label: choice.payload.label,
                    };
                    cardsAccumulator.push(card);
                    allCards.push(card);
                  });
                  break;
                case "scoring":
                case "nps":
                case "ces":
                case "csat":
                  question.call_to_action.scores.forEach((score) => {
                    const card = createBaseSmallCard(score);
                    cardsAccumulator.push(card);
                    allCards.push(card);
                  });
                  break;
              }

              return cardsAccumulator;
            },
            [],
          );

          const exitDotCount = cards.reduce((acc, card) => {
            acc += Number(!!card.targetNodeId);
            acc += Number(!!card.skipTargetNodeId);
            return acc;
          }, 0);

          return {
            ...column,
            exitDotCount,
            cards,
          };
        },
      );

      const allInvisibleCards: InvisibleCard[] = [];

      const getOriginId = (
        nodeId: string,
        type: "skip_to_node" | "next_node",
      ) => {
        const card = allInvisibleCards.find((card) => card.id === nodeId);
        if (card) {
          if (type === "skip_to_node") {
            return getOriginId(card.skipTargetNodeId, type);
          }
          return getOriginId(card.targetNodeId, type);
        }
        return nodeId;
      };

      const createInvisibleCard = (
        targetNodeId: string | null,
        skipTargetNodeId: string | null,
        columnIndex: number,
        currentCard: CardContainer,
      ) => {
        const targetCard = allCards.find(
          (card) =>
            card.component === CardContainerType.BigCard &&
            card.id === (targetNodeId || skipTargetNodeId),
        ) as BigCardContainer | undefined;

        // We want to have one invisible card at each column between current card and target card
        // Where each intermediate invisible card will have targetNodeId/skipTargetNodeId set to next invisible card
        let lastInvisibleCard: InvisibleCard | null = null;
        for (
          let newColumnIndex = columnIndex + 1;
          newColumnIndex < targetCard.columnIndex;
          newColumnIndex++
        ) {
          const existingInvisibleCard = allInvisibleCards.find(
            (card) =>
              ((targetNodeId &&
                getOriginId(card.targetNodeId, "next_node") === targetNodeId) ||
                (skipTargetNodeId &&
                  getOriginId(card.skipTargetNodeId, "skip_to_node") ===
                    skipTargetNodeId)) &&
              card.columnIndex === newColumnIndex,
          );

          if (!existingInvisibleCard) {
            const invisibleCard: InvisibleCard = {
              id: v4(),
              columnIndex: newColumnIndex,
              cardIndex: columnsWithCards[newColumnIndex].cards.length,
              originCards: [currentCard],
              targetNodeId: targetNodeId,
              skipTargetNodeId: skipTargetNodeId,
              component: CardContainerType.InvisibleCard,
            };

            // Only bind current card to the first invisible card
            if (!lastInvisibleCard) {
              if (targetNodeId) {
                currentCard.targetNodeId = invisibleCard.id;
              } else if (skipTargetNodeId) {
                currentCard.skipTargetNodeId = invisibleCard.id;
              }
            } else {
              // If we have last invisible card, set its targetNodeId/skipTargetNodeId to this invisible card
              if (invisibleCard.targetNodeId) {
                lastInvisibleCard.targetNodeId = invisibleCard.id;
              }

              if (invisibleCard.skipTargetNodeId) {
                lastInvisibleCard.skipTargetNodeId = invisibleCard.id;
              }
            }

            columnsWithCards[newColumnIndex].exitDotCount++;
            columnsWithCards[newColumnIndex].cards.push(invisibleCard);
            allInvisibleCards.push(invisibleCard);
            lastInvisibleCard = invisibleCard;
          } else {
            existingInvisibleCard.originCards.push(currentCard);

            // Only bind current card to the first invisible card
            if (!lastInvisibleCard) {
              if (targetNodeId) {
                currentCard.targetNodeId = existingInvisibleCard.id;
              } else if (skipTargetNodeId) {
                currentCard.skipTargetNodeId = existingInvisibleCard.id;
              }
            } else {
              // If we have last invisible card, set its targetNodeId/skipTargetNodeId to this invisible card
              if (existingInvisibleCard.targetNodeId) {
                lastInvisibleCard.targetNodeId = existingInvisibleCard.id;
              }

              if (existingInvisibleCard.skipTargetNodeId) {
                lastInvisibleCard.skipTargetNodeId = existingInvisibleCard.id;
              }
            }

            lastInvisibleCard = existingInvisibleCard;
          }
        }
      };

      // Now let's create invisible cards for each node line
      // We need to do it after all cards are created and in reverse order
      // to make sure invisible cards from previous columns are under
      for (
        let columnIndex = columnsWithCards.length - 1;
        columnIndex >= 0;
        columnIndex--
      ) {
        columnsWithCards[columnIndex].cards.forEach((currentCard) => {
          const { columnIndex, targetNodeId, skipTargetNodeId } = currentCard;

          // Create invisible card for the next node line
          if (targetNodeId) {
            createInvisibleCard(targetNodeId, null, columnIndex, currentCard);
          }

          // Create another invisible card for the skip to node line
          // As we don't want to have both lines at the same place
          if (skipTargetNodeId) {
            createInvisibleCard(
              null,
              skipTargetNodeId,
              columnIndex,
              currentCard,
            );
          }
        });
      }
      return {
        flattenCards: [...allInvisibleCards, ...allCards],
        columnsWithCards: columnsWithCards,
      };
    } catch (err) {
      console.error(err);
    }
  }

  getSurveyScenarioLanguages() {
    return arrayToSet(
      this.survey.scenario?.nodes
        .flatMap((node) => {
          // Message is read only in the builder
          if (node.question.type === "survey") {
            const messagesLang = node.question?.messages.flatMap((message) =>
              Object.entries(
                message.type === "text" ? message.text : message.video,
              )
                .filter(([, text]) => Boolean(text))
                .map(([lang]) => lang),
            );
            let actionsLang: string[] = [];

            if (node.question?.call_to_action?.type === "multiple_choice") {
              actionsLang = actionsLang.concat(
                node.question?.call_to_action?.choices.flatMap((choice) =>
                  choice.type === "button"
                    ? Object.entries(choice.payload.label)
                        .filter(([, text]) => Boolean(text))
                        .map(([lang]) => lang)
                    : [],
                ),
              );
            }

            return [...messagesLang, ...actionsLang];
          }

          return [];
        })
        .concat(this.survey.scenario?.default_language),
    );
  }

  public resetBuilder() {
    this.org = null;
    this.survey = null;
    this.currentLanguage = null;
  }

  public setSurvey(survey: Survey) {
    if (!this.org) throw Error("Empty org was not expected");
    this.survey = survey;
    this.currentLanguage = this.survey.scenario.default_language;
  }

  public setSurveyScenario(scenario: SurveyScenario) {
    this.survey.scenario.node_entrypoint_id = scenario.node_entrypoint_id;
    this.survey.scenario.nodes = scenario.nodes;
  }

  public setMessageUrl(messageUrl: string) {
    this.messageUrl = messageUrl;

    const firstNode = this.survey.scenario?.nodes?.[0];

    if (
      firstNode.question &&
      (firstNode.question.type === "popover" ||
        firstNode.question.type === "beacon")
    ) {
      firstNode.question.url = messageUrl;
    }
  }

  public computeNodeGraph() {
    // group nodes by column
    const flow = new ScenarioGraphBuilder(this.survey.scenario);
    const nodesPerColumns = flow.getNodeGraph();
    nodesPerColumns.forEach((nodes: GraphNode[]) => {
      nodes.sort(graphNodeCompare);
    });

    // flatten nodes
    const nodes = nodesPerColumns.reduce(
      (acc: GraphNode[], curr: GraphNode[]): GraphNode[] => {
        return acc.concat(...curr);
      },
      [],
    );
    nodes.sort(graphNodeCompare);

    // map<id => node>
    const nodesById = nodes.reduce((acc: object, curr: GraphNode): object => {
      acc[curr.id] = curr;
      return acc;
    }, {});

    // persist to store
    this.nodes = nodes;
    this.nodesById = nodesById;
    this.nodesPerColumns = nodesPerColumns;
  }

  // create a question and link its inner actions to routing rules
  // returns node id
  public addNode(
    type: CTAType | ScenarioQuestionTypeMessage["type"],
    nextNodeId: UUID | null,
  ): UUID {
    let defaultEffect = newRoutingEffectToEnd();
    if (type === "none" && !!nextNodeId)
      defaultEffect = newRoutingEffectToNode(nextNodeId);

    const node: ScenarioNode = {
      id: uuidv4() as UUID,
      correlation_id: uuidv4() as UUID,
      question:
        this.survey.type === "survey"
          ? newSurveyQuestion(
              type as CTAType,
              this.survey.scenario.default_language,
            )
          : newMessageQuestion(
              type as ScenarioQuestionTypeMessage["type"],
              this.survey.scenario.default_language,
              this.messageUrl,
            ),
      routing: {
        type: "switch",
        cases: [],
        default_effect: defaultEffect,
      },
      integrations: {},
    };

    getActionsFromQuestion(node.question).forEach((action: CTAAction) => {
      routingSetCase(node.routing, action.id, nextNodeId, "next_node");
    });

    this.survey.scenario.nodes.push(node);
    return node.id;
  }

  public removeNode(node: GraphNode) {
    // First, we try to find a cool node that will replace the deleted one in the DAG.
    // Priority order:
    // 1. next node
    // 2. skip
    // 3. default_effect
    // @TODO: For (1. next node), when many nodes match, we should select the one that removes the less nodes instead
    // (probably in the next column of the DAG). Another option would be to select the one that is the most linked in `node.node.routing.cases`.
    const currentNodeCases = node.node.routing.cases;
    const firstEffectHavingNextNode =
      // 1. next node
      currentNodeCases.find((c) => c.effect.type === "next_node")?.effect ??
      // 2. skip
      currentNodeCases.find((c) => c.effect.type === "skip_to_node")?.effect ??
      // 3. default_effect
      node.node.routing.default_effect;

    let nextNodeIdOrEnd: UUID | null = null;
    if (firstEffectHavingNextNode?.type === "next_node") {
      nextNodeIdOrEnd = firstEffectHavingNextNode.next_node_id;
    } else if (firstEffectHavingNextNode?.type === "skip_to_node") {
      nextNodeIdOrEnd = firstEffectHavingNextNode.skip_to_node_id;
    } else {
      // end of survey: no next node
    }

    // Now, we have either a node id or end of survey.

    // Special case: the deleted node were the first node of the survey.
    if (this.survey.scenario.node_entrypoint_id === node.id) {
      this.survey.scenario.node_entrypoint_id = nextNodeIdOrEnd;
      return;
    }

    // All other cases: node were in the middle of the survey.
    const previousNodes: GraphNode[] = arrayToSet(node.previousNodeIds).map(
      (nid: string) => this.nodesById[nid],
    );

    for (const previousNode of previousNodes) {
      const routing = previousNode.node.routing;

      // re-route default_effect
      if (nextNodeIdOrEnd) {
        if (
          routing.default_effect.type === "next_node" &&
          routing.default_effect.next_node_id === node.id
        ) {
          routing.default_effect.next_node_id = nextNodeIdOrEnd;
        } else if (
          routing.default_effect.type === "skip_to_node" &&
          routing.default_effect.skip_to_node_id === node.id
        ) {
          routing.default_effect.skip_to_node_id = nextNodeIdOrEnd;
        }
      } else {
        routing.default_effect = newRoutingEffectToEnd();
      }

      // Make a shallow copy to be sure to remove all matching cases
      const cases = [...routing.cases];

      // re-route all other effects
      for (const c of cases) {
        if (nextNodeIdOrEnd) {
          if (
            c.effect.type === "next_node" &&
            c.effect.next_node_id === node.id
          ) {
            c.effect.next_node_id = nextNodeIdOrEnd;
          } else if (
            c.effect.type === "skip_to_node" &&
            c.effect.skip_to_node_id === node.id
          ) {
            c.effect.skip_to_node_id = nextNodeIdOrEnd;
          }
        } else if (
          c.effect.type === "next_node" &&
          c.effect.next_node_id === node.id
        ) {
          c.effect = newRoutingEffectToEnd();
        } else if (
          c.effect.type === "skip_to_node" &&
          c.effect.skip_to_node_id === node.id
        ) {
          c.effect = newRoutingEffectToEnd();
        }
      }
    }
  }

  public setNodeQuestionType(node: GraphNode, type: ScenarioQuestionType) {
    // Message is read only in the builder
    if (node.node.question.type === "survey") {
      node.node.question.messages.forEach((message) => {
        message.type = type;

        if (message.type === "text" && !message.text) {
          message.text = newTextLabel(
            node.node.question.description,
            this.currentLanguage,
          );
        } else if (message.type === "video" && !message.video) {
          message.video = newVideoLabel(this.currentLanguage);

          // hack to force video to be empty
          // I don't know why, but when we create a new video label, it is set to the previous node video data
          setTimeout(() => {
            if (message.video[this.currentLanguage].url?.length > 0) {
              message.video[this.currentLanguage] = {};
            }
          }, 1);
        }
      });
    }
  }

  public hasMissingTranslation() {
    return this.nodes.some((node) => {
      // Message is read only in the builder
      if (node.node.question.type === "survey") {
        const missingMessage = node.node.question.messages.some((message) => {
          if (message.type === "text") {
            return !message.text[this.survey.scenario.default_language];
          }
          return false;
        });

        if (missingMessage) {
          return true;
        }

        switch (node.node.question.call_to_action.type) {
          case "multiple_choice":
            return node.node.question.call_to_action.choices.some(
              (choice) =>
                !choice.payload.label[this.survey.scenario.default_language],
            );
          default:
            return false;
        }
      }

      return false;
    });
  }

  public hasIncorrectSettings() {
    return this.nodes.some((node) => {
      if (["beacon", "popover"].includes(node.node.question.type)) {
        const selector = (node.node.question as any).selector;
        return !selector?.length;
      }
    });
  }

  updateDescriptions() {
    this.nodes.forEach((node) => {
      // Message is read only in the builder
      if (node.node.question.type === "survey") {
        const message = node.node.question.messages?.[0];
        const nodeText = message?.type === "text" ? message?.text : null;

        if (nodeText) {
          node.node.question.description =
            nodeText[this.survey.scenario.default_language];
        }
      }
    });
  }

  public pruneNotLinkedNodes() {
    const flow = new ScenarioGraphBuilder(this.survey.scenario);
    this.survey.scenario.nodes = flow
      .getNodeGraph()
      .flat()
      .map((n: GraphNode) => n.node);
  }

  public pruneUnselectedNodesMode() {
    this.nodes.forEach((node) => {
      // Message is read only in the builder
      if (node.node.question.type === "survey") {
        node.node.question.messages.forEach((message) => {
          if (message.type === "text") {
            message["video"] = undefined;
          } else if (message.type === "video") {
            message["text"] = undefined;
          }
        });
      }
    });
  }

  public pruneNonDefaultEmptyQuestionDescription() {
    this.nodes.forEach((node) => {
      // Message is read only in the builder
      if (node.node.question.type === "survey") {
        node.node.question.messages.forEach((message) => {
          if (message.type === "video") {
            return;
          }

          Object.keys(message.text).forEach((lang) => {
            if (lang !== this.survey.scenario?.default_language) {
              if (message.text[lang]?.length === 0) {
                message.text[lang] = undefined;
              }
            }
          });
        });
      }
    });
  }

  public pruneVideoNodeUrls() {
    this.nodes.forEach((node) => {
      // Message is read only in the builder
      if (node.node.question.type === "survey") {
        node.node.question.messages.forEach((message) => {
          if (message.type === "video") {
            Object.keys(message.video).forEach((lang) => {
              message.video[lang].url = undefined;
              message.video[lang].thumbnail_urls = undefined;
            });
          }
        });
      }
    });
  }

  public replaceNode(node: GraphNode) {
    const index = this.survey.scenario.nodes.findIndex((n) => n.id === node.id);
    this.survey.scenario.nodes[index] = node.node;
  }

  changeDefaultLanguage() {
    this.nodes.forEach((node) => {
      // Message is read only in the builder
      if (node.node.question.type === "survey") {
        node.node.question?.messages?.forEach((message) => {
          switch (message.type) {
            case "text":
              message.text[this.survey.scenario?.default_language] =
                message.text[this.currentLanguage];

              delete message.text[this.currentLanguage];
              break;
            case "video":
              message.video[this.survey.scenario?.default_language] =
                message.video[this.currentLanguage];

              delete message.video[this.currentLanguage];
              break;
          }
        });
      }

      if (node.node.question?.call_to_action.type === "multiple_choice") {
        node.node.question?.call_to_action.choices?.forEach((choice) => {
          choice.payload.label[this.survey.scenario?.default_language] =
            choice.payload.label[this.currentLanguage];

          delete choice.payload.label[this.currentLanguage];
        });
      }
    });

    this.updateDescriptions();
  }
}

export { BuilderStore };
