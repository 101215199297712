<nz-tabset [nzAnimated]="false">
  <nz-tab nzTitle="Webhook">
    <section nz-row [nzGutter]="[32, 16]">
      <div nz-col nzXs="24" class="description">
        Push your Screeb data to third party solutions via Webhooks. This will
        <b>automatically</b> push data from this
        {{ uiService.isMessagePage ? "message" : "survey" }} only. Click here if
        you want to share data from all your
        {{ uiService.isMessagePage ? "messages" : "surveys" }} via Webhooks.
      </div>
      <div nz-col nzSpan="24">
        <upgrade-banner
          class="upgrade-banner"
          title=""
          text="You need to <strong>upgrade</strong> your account to unlock integrations!"
          *ngIf="!('integration_webhook' | hasEntitlement)"
          [orgId]="org.id"
          [small]="true"
        ></upgrade-banner>

        <integration-settings-webhook
          *ngIf="'integration_webhook' | hasEntitlement"
          [org]="org"
          [survey]="survey"
          [integration]="{ settings: surveyIntegrations }"
          [createIfEmpty]="false"
          [hideListOnEmpty]="true"
          scope="survey"
          (valid)="webhookValid = $event"
          (onSettingsChange)="onWebhookChange($event)"
        >
        </integration-settings-webhook>
      </div>
    </section>
  </nz-tab>

  <nz-tab nzTitle="Cobbai" *ngIf="integrationIsCreatedInOrganization('cobbai')">
    <section nz-row [nzGutter]="[32, 16]">
      <div nz-col nzXs="24" class="description">
        Push your Screeb data to the Cobbai feedback analaysis tool. This will
        <b>automatically</b> push data from this
        {{ uiService.isMessagePage ? "message" : "survey" }} only. Click here if
        you want to share data from all your
        {{ uiService.isMessagePage ? "messages" : "surveys" }} to Cobbai.
      </div>
      <div nz-col nzSpan="24">
        <upgrade-banner
          class="upgrade-banner"
          title=""
          text="You need to <strong>upgrade</strong> your account to unlock integrations!"
          *ngIf="!('integration_cobbai' | hasEntitlement)"
          [orgId]="org.id"
          [small]="true"
        ></upgrade-banner>

        <integration-settings-cobbai
          *ngIf="'integration_cobbai' | hasEntitlement"
          [org]="org"
          [survey]="survey"
          [integration]="{ settings: surveyIntegrations }"
          [createIfEmpty]="false"
          [hideListOnEmpty]="true"
          scope="survey"
          (valid)="cobbaiValid = $event"
          (onSettingsChange)="onCobbaiChange($event)"
        >
        </integration-settings-cobbai>
      </div>
    </section>
  </nz-tab>

  <nz-tab
    nzTitle="Zapier"
    *ngIf="
      ('isIntegrationZapierAvailable' | hasFeatureFlag) &&
      integrationIsCreatedInOrganization('zapier')
    "
  >
    <section nz-row [nzGutter]="[32, 16]">
      <div nz-col nzXs="24" class="description">
        Push your Screeb data to third party solutions via Zapier. This will
        <b>automatically</b> push data from this
        {{ uiService.isMessagePage ? "message" : "survey" }} only. Click here if
        you want to share data from all your
        {{ uiService.isMessagePage ? "messages" : "surveys" }} via Webhooks.
      </div>
      <div nz-col nzSpan="24">
        <upgrade-banner
          class="upgrade-banner"
          title=""
          text="You need to <strong>upgrade</strong> your account to unlock integrations!"
          *ngIf="!('integration_zapier' | hasEntitlement)"
          [orgId]="org.id"
          [small]="true"
        ></upgrade-banner>

        <integration-settings-zapier
          *ngIf="'integration_zapier' | hasEntitlement"
          [org]="org"
          [survey]="survey"
          [integration]="{ settings: surveyIntegrations }"
          [createIfEmpty]="false"
          [hideListOnEmpty]="true"
          scope="survey"
          (valid)="zapierValid = $event"
          (onSettingsChange)="onZapierChange($event)"
        >
        </integration-settings-zapier>
      </div>
    </section>
  </nz-tab>
</nz-tabset>
