import { GraphNode } from "components/builder/flow";
import {
  CTAAction,
  CTAType,
  I18nLabel,
  ScenarioQuestionTypeMessage,
} from "models/survey.dao.types";

export enum CardContainerType {
  BigCard = "big-card",
  SmallCard = "small-card",
  InvisibleCard = "invisible-card",
}

export type BigCardContainer = {
  originCards?: CardContainer[];
  columnIndex: number;
  cardIndex: number;
  component: CardContainerType.BigCard;
  type: CTAType | ScenarioQuestionTypeMessage["type"];
  letter: string;
  label: I18nLabel;
  subLabel?: string;
  node: GraphNode;
  id: string;
  multiple: boolean;
  targetNodeId: string | null;
  skipTargetNodeId: string | null;
  action?: CTAAction | null;
  actions?: CTAAction[] | null;
  pathCount?: number;
  pathCountPercent?: number;
  skipPathCountPercent?: number;
};
export type SmallCardContainer = {
  originCards: CardContainer[];
  columnIndex: number;
  cardIndex: number;
  component: CardContainerType.SmallCard;
  label?: I18nLabel;
  emoji: string;
  node: GraphNode;
  multiple: boolean;
  targetNodeId: string | null;
  skipTargetNodeId: string | null;
  action: CTAAction;
  pathCount?: number;
  pathCountPercent?: number;
  skipPathCountPercent?: number;
};
export type InvisibleCard = {
  component: CardContainerType.InvisibleCard;
  id: string;
  targetNodeId: string | null;
  skipTargetNodeId: string | null;
  originCards: CardContainer[];
  columnIndex: number;
  cardIndex: number;
};

export type CardContainer =
  | BigCardContainer
  | SmallCardContainer
  | InvisibleCard;

export type CardDataAndElement = {
  cardData: CardContainer;
  element: HTMLElement;
};
