import { Serializable } from "models/serializable.model";
import { OrgAccountPermission, OrgAccountRole } from "./account-permissions";
import { OrgStats } from "./org.model";
import { billingStatus } from "./org_billing.model";

export const defaultSuperOrgName = "My organization";

class SuperOrg extends Serializable {
  constructor(
    public id?: string,
    public name?: string,
    public logo?: string,
    public last_seen_at?: Date,
    public created_at?: Date,
    public updated_at?: Date,

    public stats?: SuperOrgStats,
    public flags?: SuperOrgFlags,
    public entitlements?: SuperOrgEntitlements,

    // Computed
    public role?: OrgAccountRole,
    public permissions?: OrgAccountPermission[],
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  public mtuExceeded(): boolean {
    if (
      this.stats?.current_month_respondents === null ||
      this.stats?.current_month_respondents === undefined ||
      this.entitlements?.max_mtu === null ||
      this.entitlements?.max_mtu === undefined
    )
      throw Error("missing mtu or max_mtu counters");
    if (this.entitlements.max_mtu === -1) return false;
    return this.stats.current_month_respondents >= this.entitlements.max_mtu;
  }

  public get usedMTUPercent(): number | null {
    if (
      this.stats?.current_month_respondents === null ||
      this.stats?.current_month_respondents === undefined ||
      this.entitlements?.max_mtu === null ||
      this.entitlements?.max_mtu === undefined
    )
      return null;
    if (this.entitlements.max_mtu === -1) return 0;
    return Math.ceil(
      Math.min(
        (this.stats.current_month_respondents * 100) /
          this.entitlements.max_mtu,
        100,
      ),
    );
  }
}

class SuperOrgFlags extends Serializable {
  constructor(
    public debug_log_level?: string, // optional

    public billing_status?: billingStatus,
    public last_trial_end_date?: Date, // optional

    public mfa_enforced?: boolean,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

class SuperOrgEntitlements extends Serializable {
  constructor(
    public mtu_scheme?: "limited" | "unlimited",
    public max_mtu?: number, // -1 if unlimited
    public mtu_mode?: "manual" | "auto" | "even",
    public max_mtu_window_start?: number, // optional

    public survey_scheme?: "limited" | "unlimited",
    public max_surveys?: number, // -1 if unlimited

    public funnel_scheme?: "limited" | "unlimited",
    public max_funnels?: number, // -1 if unlimited

    public max_workspaces?: number,
    // features
    public device_tracking_enabled?: boolean,
    public team_membership_enabled?: boolean,
    public capping_enabled?: boolean,
    public content_analysis_enabled?: boolean,
    public segment_analysis_enabled?: boolean,
    public multilingual_survey_enabled?: boolean,
    public advanced_survey_customization_enabled?: boolean,
    public session_recording_enabled?: boolean,
    public video_question_enabled?: boolean,
    public audio_answer_enabled?: boolean,
    public video_answer_enabled?: boolean,
    public response_translation_enabled?: boolean,
    // integrations
    // forward
    public integration_productboard_enabled?: boolean,
    public integration_harvestr_enabled?: boolean,
    public integration_zendesk_enabled?: boolean,
    public integration_intercom_enabled?: boolean,
    public integration_trello_enabled?: boolean,
    public integration_cycle_enabled?: boolean,
    public integration_monday_enabled?: boolean,
    public integration_asana_enabled?: boolean,
    // cdp
    public integration_amplitude_source_enabled?: boolean,
    public integration_segment_source_enabled?: boolean,
    public integration_rudderstack_source_enabled?: boolean,
    public integration_typeform_enabled?: boolean,
    // hooks
    public integration_stitch_enabled?: boolean,
    public integration_webhook_enabled?: boolean,
    public integration_zapier_enabled?: boolean,
    public integration_cobbai_enabled?: boolean,
    public integration_email_enabled?: boolean,
    public integration_notion_enabled?: boolean,
    public integration_atlassian_enabled?: boolean,
    public integration_amplitude_destination_enabled?: boolean,
    public integration_segment_destination_enabled?: boolean,
    public integration_rudderstack_destination_enabled?: boolean,
    // reporting
    public integration_slack_enabled?: boolean,
    // addons
    public white_label_enabled?: boolean,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

class SuperOrgStats extends OrgStats {}

export { SuperOrg, SuperOrgEntitlements, SuperOrgFlags };
