<nz-select
  *ngIf="!isPreview"
  nzShowSearch
  nzServerSearch
  nzAllowClear
  [nzLoading]="isLoading"
  nzPlaceHolder="All Users or Segments"
  [nzSize]="showList ? '' : 'large'"
  nzMode="multiple"
  (nzOnSearch)="onSearchUsers($event)"
  [nzStatus]="hasError ? 'error' : ''"
  [nzSuffixIcon]="showList ? 'caret-down' : ''"
  [ngModel]="userIds"
  nzSuffixIcon="caret-down"
  [nzShowArrow]="true"
  (ngModelChange)="userIdsChange.emit($event)"
>
  <nz-option
    *ngFor="let group of filteredUserGroupsOptions"
    nzCustomContent
    [nzLabel]="getGroupNameById(group.id)"
    [nzValue]="group.id"
  >
    <nz-tag nzColor="var(--screeb-color-info-outline)">Segment</nz-tag>
    {{ getGroupNameById(group.id) }}
  </nz-option>
  <nz-option
    *ngFor="let user of filteredUsersOptions"
    nzCustomContent
    [nzLabel]="getFormattedUserNameOrID(user)"
    [nzValue]="user.id"
  >
    <nz-tag nzColor="#E4F8F2">User</nz-tag>
    {{ getFormattedUserNameOrID(user) }}
  </nz-option>
  <nz-option
    *ngIf="!currentKeyword?.length || filteredUsersOptions?.length === 25"
    nzDisabled
    nzValue=""
    nzLabel="Please type for more users..."
  >
  </nz-option>
</nz-select>
<ng-container *ngIf="showList">
  <ng-container *ngIf="isUsersLoading">
    <nz-skeleton
      *ngFor="let user of userIds"
      [nzAvatar]="{ shape: 'circle' }"
      nzActive
      [nzParagraph]="false"
    ></nz-skeleton>
  </ng-container>
  <div class="users-list" *ngIf="!isUsersLoading && computedUsers?.length">
    <div class="user-item" *ngFor="let user of computedUsers">
      <div class="user-icon">
        <img
          nz-icon
          loading="lazy"
          alt="User avatar"
          [src]="getUserIcon(user.id, user.getAvatarURL())"
        />
        <div class="user-presence"></div>
      </div>
      <div class="user-infos">
        <div class="bold-text">
          {{ getFormattedUserNameOrID(user) }}
          <nz-tag class="user-type" nzColor="#E4F8F2">User</nz-tag>
          <ng-container *ngIf="user.is_anonymous">
            <br />
            <nz-tag
              nz-popover
              nzPopoverTitle="Anonymous users"
              [nzPopoverContent]="anonymousHelp"
              >Anonymous
            </nz-tag>
            <ng-template #anonymousHelp>
              This user has not been identified in Javascript tag or mobile
              SDKs.
              <br />
              <a rel="noopener" href="https://help.screeb.app" target="_blank">
                <i nz-icon nzType="link"></i>
                See our help section.
              </a>
            </ng-template>
          </ng-container>
        </div>
        <nz-tag *ngFor="let userGroupId of user.groups_ids">
          {{ getGroupNameById(userGroupId) }}
        </nz-tag>
      </div>
      <div class="user-actions">
        <a
          *ngIf="isPreview"
          nz-button
          nzType="text"
          routerLink="/org/{{ user.org_id }}/people/respondent/{{ user.id }}"
        >
          <screeb-icon size="sm" icon="righ-arrow"></screeb-icon>
        </a>
        <button
          nz-button
          nzType="text"
          title="Remove respondent"
          *ngIf="!isPreview"
          (click)="onRemove(user.id)"
        >
          <screeb-icon size="md" icon="delete"></screeb-icon>
        </button>
      </div>
    </div>
    <div class="user-item" *ngFor="let group of computedUserGroups">
      <div class="user-icon">
        <div class="user-segment-icon">👥</div>
      </div>
      <div class="user-infos">
        <div class="bold-text">
          {{ getGroupNameById(group.id) }}
          <nz-tag class="user-type" nzColor="var(--screeb-color-info-outline)"
            >Segment</nz-tag
          >
        </div>
        <nz-tag
          nzSize="small"
          *ngFor="let source of group.sources"
          [nzColor]="registryEntrySourceFormatted[source].color"
        >
          <span class="tag">
            {{ registryEntrySourceFormatted[source].title }}
          </span>
        </nz-tag>
      </div>
      <div class="user-actions">
        <a
          *ngIf="isPreview"
          nz-button
          nzType="text"
          routerLink="/org/{{ group.tenant_id }}/people/segment/{{ group.id }}"
        >
          <screeb-icon size="sm" icon="righ-arrow"></screeb-icon>
        </a>
        <button
          nz-button
          nzType="text"
          title="Remove respondent"
          *ngIf="!isPreview"
          (click)="onRemove(group.id)"
        >
          <screeb-icon size="md" icon="delete"></screeb-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
