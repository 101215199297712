import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsIndex, AnalyticsQuery } from "models/analytics.filters.type";
import { Org } from "models/org.model";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { deepCopy } from "utils/object";

@Component({
  selector: "filters-csv-export",
  templateUrl: "./csv-export.component.html",
  styleUrls: ["./csv-export.component.scss"],
})
export class FiltersCSVExportComponent implements OnInit, OnDestroy {
  @Input()
  public org: Org = null;
  @Input()
  public survey: Survey = null;
  @Input()
  public type: AnalyticsIndex;

  private filtersObs$: any = null;
  public lastFilters: AnalyticsQuery;
  public defaultFilters: AnalyticsQuery;

  constructor(
    private featureFlaggingService: FeatureFlaggingService,
    private analyticsFilterService: AnalyticsFilterService,
    private analyticsDao: AnalyticsDao,
    private trackersService: TrackersService,
  ) {}

  ngOnInit() {
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQuery) => {
        this.lastFilters = this.addCustomParamsForCSVExport(deepCopy(filters));
      });

    this.defaultFilters = this.addCustomParamsForCSVExport(
      this.getInitialFilters(),
    );
  }

  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  private addCustomParamsForCSVExport(filters: AnalyticsQuery): AnalyticsQuery {
    filters = deepCopy(filters); // probably not needed, but just in case...

    if (filters.type === "response") {
      filters.having_answer_only = true;
    }

    return filters;
  }

  public onExportWithFiltersClick() {
    // export event is sent into Segment from server side
    this.track("Export with filters", this.lastFilters);
  }
  public onExportAllClick() {
    // export event is sent into Segment from server side
    this.track("Export all", this.defaultFilters);
  }
  private track(eventName: TrackingEventName, filters: AnalyticsQuery) {
    // export event is sent into Segment from server side
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withAnalyticsFilters(filters.filters, filters.type)
      .build();
  }

  private getInitialFilters() {
    return this.analyticsFilterService.getInitialFilters(
      this.type,
      UUID(this.org.id),
      "AND",
      this.survey ? [UUID(this.survey.id)] : [],
      this.survey?.created_at || this.org?.created_at,
    );
  }

  public getCsvExportURL(withFilters: boolean): string {
    withFilters = withFilters && !!this.lastFilters;

    return this.analyticsDao.export(
      withFilters ? this.lastFilters : this.defaultFilters,
    );
  }
}
