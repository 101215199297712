import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UUID } from "models/survey.dao.types";
import { PermissionsService } from "services/permissions.service";
import { formatNumber } from "utils/number";

@Component({
  selector: "funnel-grid-survey-cell",
  template: `
    <div
      class="funnel-grid-survey-cell"
      [ngClass]="{
        'edit-mode': editMode,
        success: sequenceStepType === 'success',
        drop: sequenceStepType === 'drop'
      }"
    >
      <div *ngIf="!editMode" class="survey-cell-header">
        <div class="survey-cell-title">
          <span *ngIf="sequenceStepType === 'success'">Converted users</span>
          <span *ngIf="sequenceStepType === 'drop'">Dropped users</span>
        </div>
        <div class="survey-cell-stats">
          <div
            class="survey-cell-header-percent"
            *ngIf="userPercent !== undefined && !isNaN(userPercent)"
          >
            {{ formatNumber(userPercent, "percent") }}
            <span *ngIf="!firstStep">of previous step</span>
          </div>
          <div class="survey-cell-header-count">
            <span>
              {{
                userCount === userNextCount
                  ? "(" + formatNumber(userCount, "number") + ")"
                  : "(" +
                    formatNumber(userCount, "number") +
                    " of " +
                    formatNumber(userNextCount, "number") +
                    ")"
              }}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="!editMode" class="survey-cell-separator"></div>
      <div *ngIf="!editMode && !surveyId" class="no-survey-spacer"></div>
      <div *ngIf="surveyId" class="survey-cell-survey-indicator">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 5.5C5.68629 5.5 3 8.18629 3 11.5V19.5H15C18.3137 19.5 21 16.8137 21 13.5V11.5C21 8.18629 18.3137 5.5 15 5.5H9ZM8 13.5C8.55229 13.5 9 13.0523 9 12.5C9 11.9477 8.55229 11.5 8 11.5C7.44772 11.5 7 11.9477 7 12.5C7 13.0523 7.44772 13.5 8 13.5ZM12 13.5C12.5523 13.5 13 13.0523 13 12.5C13 11.9477 12.5523 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5C11 13.0523 11.4477 13.5 12 13.5ZM17 12.5C17 13.0523 16.5523 13.5 16 13.5C15.4477 13.5 15 13.0523 15 12.5C15 11.9477 15.4477 11.5 16 11.5C16.5523 11.5 17 11.9477 17 12.5Z"
            [attr.fill]="sequenceStepType === 'success' ? '#1ED5A4' : '#F11672'"
          />
        </svg>
        <div class="survey-cell-survey-title" [title]="surveyTitle">
          {{ surveyTitle }}
        </div>
        <div class="survey-cell-survey-response-count" *ngIf="!editMode">
          {{ surveyResponseCount }}
        </div>
        <a
          class="square-button"
          *ngIf="editMode"
          routerLink="/org/{{ orgId }}/survey/{{ surveyId }}/edit"
          nz-button
          nzType="default"
          nzSize="large"
        >
          <img nz-icon src="/assets/icons/homepage/pen-dark.svg" alt="Edit" />
        </a>
      </div>
      <div class="survey-cell-survey-actions" *ngIf="!editMode || !surveyId">
        <button
          *ngIf="!surveyId && ('survey.create' | hasPermission)"
          [ngClass]="getButtonClass()"
          (click)="onDisplaySurveyClick()"
          nz-button
          nzType="primary"
          nzSize="large"
          [nzLoading]="displaySurveyLoading"
        >
          Ask a Question
        </button>

        <a
          class="square-button"
          *ngIf="surveyId"
          routerLink="/org/{{ orgId }}/survey/{{ surveyId }}/edit"
          nz-button
          nzType="default"
          nzSize="large"
        >
          <img nz-icon src="/assets/icons/homepage/pen-dark.svg" alt="Edit" />
        </a>
        <a
          class="square-button"
          *ngIf="surveyId"
          [ngClass]="getButtonClass()"
          routerLink="/org/{{ orgId }}/survey/{{
            surveyId
          }}/stats/overall-performances"
          nz-button
          nzType="primary"
          nzSize="large"
        >
          <img
            nz-icon
            src="/assets/icons/homepage/graph-white.svg"
            alt="Edit"
          />
        </a>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        max-width: 500px;
      }

      :host .no-survey-spacer {
        height: 27px;
      }

      :host .funnel-grid-survey-cell {
        width: 100%;
        height: 176px;
        border: 3px solid var(--screeb-color-border);
        border-radius: 8px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      :host .funnel-grid-survey-cell.edit-mode {
        height: 124px;
      }

      :host .survey-cell-header {
        display: flex;
        align: center;
        justify-content: space-between;
      }

      :host .survey-cell-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
      }

      :host .survey-cell-stats {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      :host .survey-cell-header-percent,
      :host .survey-cell-header-count {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-texts);
      }

      :host .survey-cell-header-percent {
        font-weight: 500;
      }

      :host .survey-cell-separator {
        width: 100%;
        height: 1px;
        background: var(--screeb-color-grey-300);
      }

      :host .survey-cell-survey-indicator {
        display: flex;
        align-items: center;
      }

      :host .edit-mode .survey-cell-survey-indicator {
        height: 100%;
      }

      :host .survey-cell-survey-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        flex: 1;
        margin: 0 8px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      :host .survey-cell-survey-response-count {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
      }

      :host .survey-cell-survey-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      :host .edit-mode .survey-cell-survey-actions {
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }

      :host .square-button {
        max-width: 40px;
        padding-left: 11px;
        padding-right: 13px;
      }

      :host .drop {
        border-color: var(--screeb-color-error-outline);
      }

      :host .success {
        border-color: var(--screeb-color-success-outline);
      }

      :host .success-button {
        border-color: var(--screeb-color-green-500);
        background: var(--screeb-color-green-500);
        color: var(--screeb-color-white);
        text-shadow: none;
        box-shadow: none;
      }

      :host .success-button:hover,
      :host .success-button:active,
      :host .success-button:focus {
        color: var(--screeb-color-white);
        background: var(--screeb-color-green-400);
        border-color: var(--screeb-color-green-400);
      }

      :host .drop-button {
        border-color: var(--screeb-color-error);
        background: var(--screeb-color-error);
        color: var(--screeb-color-white);
        text-shadow: none;
        box-shadow: none;
      }

      :host .drop-button:hover,
      :host .drop-button:active,
      :host .drop-button:focus {
        color: var(--screeb-color-white);
        background: var(--screeb-color-red-400);
        border-color: var(--screeb-color-red-400);
      }
    `,
  ],
})
export class FunnelGridSurveyCellComponent {
  @Input() public editMode = false;
  @Input() public orgId: UUID;
  @Input() public surveyId: UUID;
  @Input() public surveyTitle = "";
  @Input() public surveyResponseCount = 0;
  @Input() public sequenceId: UUID;
  @Input() public sequenceStepType: "drop" | "success";
  @Input() public sequenceStepIndex: number;
  @Input() public userPercent: number;
  @Input() public userCount = 0;
  @Input() public userNextCount = 0;
  @Input() public firstStep = false;

  @Output() public displaySurveyClick = new EventEmitter<string>();

  public isNaN = Number.isNaN;
  public displaySurveyLoading = false;

  public formatNumber = formatNumber;

  constructor(public permissionsService: PermissionsService) {}

  getButtonClass() {
    return {
      "success-button": this.sequenceStepType === "success",
      "drop-button": this.sequenceStepType === "drop",
    };
  }

  onDisplaySurveyClick() {
    const url = `/org/${this.orgId}/survey/create?sequenceId=${this.sequenceId}&sequenceStepType=${this.sequenceStepType}&sequenceStepIndex=${this.sequenceStepIndex}`;

    this.displaySurveyLoading = true;
    this.displaySurveyClick.emit(url);
  }
}
