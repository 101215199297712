import { Component, Input } from "@angular/core";
import { ChannelType, ChannelTypeFormatted } from "models/channel.model";

const orderedChannelTypes = Object.keys(ChannelTypeFormatted);

@Component({
  selector: "survey-channel-status",
  templateUrl: "./survey-channel-status.component.html",
  styleUrls: ["./survey-channel-status.component.scss"],
})
export class SurveyChannelStatusComponent {
  @Input()
  public channelsType: ChannelType[] = [];
  @Input()
  public running: boolean = null;
  @Input()
  public testMode: boolean = null;
  @Input()
  public loading: boolean = null;
  @Input()
  public isInStartEndDates: boolean = null;

  constructor() {}

  getFormattedChannelList(): string {
    return this.channelsType
      .map((channelType) => ChannelTypeFormatted[channelType]?.title)
      .filter((channelType) => channelType)
      .sort((a: string, b: string): number => {
        return orderedChannelTypes.indexOf(a) - orderedChannelTypes.indexOf(b);
      })
      .join(", ");
  }
}
