<div *ngIf="loading && initialFetch" class="loader">
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>

<ng-container *ngIf="!loading || !initialFetch">
  <section
    nz-row
    [nzGutter]="[32, 16]"
    class="top-indicators"
    *ngIf="indicators.nps && buckets.length"
  >
    <div nz-col nzXs="24" nzXl="12" nzXXl="6">
      <survey-stats-indicators-single-indicator
        class="indicator-with-border"
        title="NPS Score"
        [value]="indicators.nps.score"
        imageSrc="/assets/indicators/NPS-indicator-score.png"
        size="medium"
      >
      </survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzXs="24" nzXl="12" nzXXl="6">
      <survey-stats-indicators-single-indicator
        class="indicator-with-border"
        title="Promoters"
        [value]="indicators.nps.promoters"
        imageSrc="/assets/indicators/NPS-indicator-promoters.png"
        size="medium"
      >
      </survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzXs="24" nzXl="12" nzXXl="6">
      <survey-stats-indicators-single-indicator
        class="indicator-with-border"
        title="Passives"
        [value]="indicators.nps.passives"
        imageSrc="/assets/indicators/NPS-indicator-passive.png"
        size="medium"
      >
      </survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzXs="24" nzXl="12" nzXXl="6">
      <survey-stats-indicators-single-indicator
        class="indicator-with-border"
        title="Detractors"
        [value]="indicators.nps.detractors"
        imageSrc="/assets/indicators/NPS-indicator-detractors.png"
        size="medium"
      >
      </survey-stats-indicators-single-indicator>
    </div>
  </section>

  <section
    nz-row
    [nzGutter]="[32, 16]"
    class="top-indicators"
    *ngIf="indicators.csat && buckets.length"
  >
    <div nz-col nzXs="24" nzXXl="8">
      <survey-stats-indicators-single-indicator
        class="indicator-with-border"
        title="Average CSAT"
        [value]="indicators.csat.scoreOnFive"
        format="number"
        [maximumFractionDigits]="1"
        [scoreMax]="5"
        diffFormat="points"
        imageSrc="/assets/indicators/CSAT-indicator-score.png"
        size="medium"
      >
      </survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzXs="24" nzXl="12" nzXXl="8">
      <survey-stats-indicators-single-indicator
        class="indicator-with-border"
        title="Number of responses"
        [value]="indicators.csat.total"
        imageSrc="/assets/indicators/CSAT-indicator-responses.png"
        size="medium"
      >
      </survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzXs="24" nzXl="12" nzXXl="8">
      <survey-stats-indicators-single-indicator
        class="indicator-with-border"
        title="Percent of low scores (1 to 2)"
        [value]="indicators.csat.lowScore"
        format="percent"
        diffFormat="points"
        imageSrc="/assets/indicators/CSAT-indicator-detractors.png"
        size="medium"
      >
      </survey-stats-indicators-single-indicator>
    </div>
  </section>

  <section
    nz-row
    [nzGutter]="[32, 16]"
    class="top-indicators"
    *ngIf="indicators.ces && buckets.length"
  >
    <div nz-col nzXs="24" nzXl="12" nzXXl="6">
      <survey-stats-indicators-single-indicator
        class="indicator-with-border"
        title="Score"
        [value]="indicators.ces.score"
        format="number"
        diffFormat="percent"
        [maximumFractionDigits]="1"
        [scoreMax]="7"
        imageSrc="/assets/indicators/CES-indicator-score.png"
        size="medium"
      >
      </survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzXs="24" nzXl="12" nzXXl="6">
      <survey-stats-indicators-single-indicator
        class="indicator-with-border"
        title="Small effort"
        [value]="indicators.ces.smallEffortPercent"
        format="percent"
        diffFormat="percent"
        imageSrc="/assets/indicators/CES-indicator-small-effort.png"
        size="medium"
      >
      </survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzXs="24" nzXl="12" nzXXl="6">
      <survey-stats-indicators-single-indicator
        class="indicator-with-border"
        title="Medium effort"
        [value]="indicators.ces.mediumEffortPercent"
        format="percent"
        diffFormat="percent"
        imageSrc="/assets/indicators/CES-indicator-medium-effort.png"
        size="medium"
      >
      </survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzXs="24" nzXl="12" nzXXl="6">
      <survey-stats-indicators-single-indicator
        class="indicator-with-border"
        title="High effort"
        [value]="indicators.ces.highEffortPercent"
        format="percent"
        diffFormat="percent"
        imageSrc="/assets/indicators/CES-indicator-high-effort.png"
        size="medium"
      >
      </survey-stats-indicators-single-indicator>
    </div>
  </section>

  <nz-empty *ngIf="buckets.length === 0" nzNotFoundImage="simple"></nz-empty>

  <div class="answers-order-switch" *ngIf="total">
    <nz-switch [(ngModel)]="orderAnswerByMostResponded"> </nz-switch>
    <span>Order by most responded</span>
  </div>

  <ul *ngIf="buckets.length > 0">
    <li
      *ngFor="
        let bucket of orderAnswerByMostResponded ? sortedBuckets : buckets
      "
    >
      <div class="inner" (click)="onBucketClick(bucket['correlation_id'])">
        <div
          class="bar"
          [style]="{ width: getResponsePercentage(bucket['doc_count']) }"
        ></div>
        <span class="text">
          <sanitized-message-component
            [content]="bucket['key']"
            [options]="{
              styling: true,
              emojiTranscoder: true
            }"
          ></sanitized-message-component>
        </span>
        <span class="percent">{{
          getResponsePercentage(bucket["doc_count"])
        }}</span>
      </div>
      <span class="count">{{ bucket["doc_count"] | number }}</span>
    </li>
  </ul>
</ng-container>
