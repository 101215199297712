import { Injectable } from "@angular/core";

import { integrationSpecs, IntegrationType } from "models/integrations.model";
import { SessionService } from "services/auth.service";
import { UIService } from "services/ui.service";
import { ConfigService } from "./config.service";

@Injectable()
class FeatureFlaggingService {
  constructor(
    private sessionService: SessionService,
    private uiService: UIService,
    private configService: ConfigService,
  ) {}

  private accountIsConnectAs(): boolean {
    return this.sessionService.isConnectedAs();
  }

  public accountIsScreebTeam(): boolean {
    if (!this.sessionService.session?.email) {
      return false;
    }
    // @TODO: this is not safe, since we do not check address ownership on signup
    if (this.sessionService.session?.email?.indexOf("@screeb.app") !== -1) {
      return true;
    }
    const whiltelist = [
      "michael.hirbec@gmail.com",
      "mikadublin@hotmail.com",
      "simon.robic@gmail.com",
    ];
    return whiltelist.includes(this.sessionService.session?.email);
  }

  private accountIsScreebTechTeam(): boolean {
    if (!this.accountIsScreebTeam()) {
      return false;
    }

    const techteam = [
      "martin",
      "clement",
      "samuel",
      "simon",
      "clement.chaban",
      "romain",
      "alexis",
    ].map((a) => `${a}@screeb.app`);
    return techteam.includes(
      this.sessionService.session?.email.replace(/\+[a-zA-Z0-9]+/gi, ""),
    );
  }

  public canCreateSuperOrg(): boolean {
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  // email surveys
  public isEmailSurveysEnabled(): boolean {
    return this.accountIsScreebTeam();
  }

  // contextual messages
  public isContextualMessagesEnabled(): boolean {
    return (
      this.accountIsScreebTeam() ||
      this.accountIsConnectAs() ||
      [
        "0b6fcd6a-3a3e-4239-ad71-cf8e6a5d23bd", // Doctolib
        "90ecf7b7-e7d9-4071-bbbd-f738445b1fbc", // Cegedim
        "bf998347-a1e2-45d6-9f8b-0c750c827211", // Hellowork
        "eabf04a8-45ad-42d1-bc80-e28045fdc749", // Napta
        "88600514-b51d-4692-bfe4-eafe41719b02", // Gaming1 - 1
        "a8ae88ed-8d85-4250-9f1f-5dfbc45e4fac", // Gaming1 - 2
        "0b8d41f1-b6f5-4aba-a5f8-8a0f405347bb", // Nextlane
        "a4b8f363-cc65-4663-88a6-4ea9cd76fc6e", // Nextlane
        "b5899b1b-713c-4b68-9f57-b201d4fd21eb", // Nextlane
        "dc794f85-90eb-402b-8ef8-da6d740773f7", // Nextlane
        "b0458378-10e5-4647-93a7-0a909e480e38", // PassInterim (sam poto)
      ].includes(this.uiService.currentSuperOrgId)
    );
  }

  public isContextualMessagesMobileEnabled(): boolean {
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  // import surveys
  public isImportSurveysEnabled(): boolean {
    if (
      this.uiService.currentOrgId === "819ef379-edc6-420d-b59f-eac6b129baf9" // Accor
    ) {
      return true;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  // theme
  public isThemeSwitchEnabled(): boolean {
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  // stats
  public isProductMarketFitEnabled(): boolean {
    return false;
    // return this.accountIsScreebTechTeam();
  }

  // user
  public isSessionRecordingEnabled(): boolean {
    return false;
    // return this.accountIsScreebTechTeam();
  }

  // channels
  public isChannelCreationEnabled(): boolean {
    return this.sessionService.isConnectedAs() || this.accountIsScreebTeam();
  }

  public displayRuleTypeLeaveIntent(): boolean {
    return (
      this.sessionService.isConnectedAs() ||
      this.accountIsScreebTeam() ||
      [
        "ddb61c82-c118-402e-9020-d0bc573dda8b", // gov plus
      ].includes(this.uiService.currentOrgId)
    );
  }

  // settings
  public isDataGovernancePageEnabled(): boolean {
    return this.accountIsScreebTechTeam();
  }

  // integrations
  public displayIntegrationCard(type: IntegrationType): boolean {
    if (
      type === "typeform" &&
      [
        "5a295134-24db-437a-bfbc-41683eadb845", // miimosa
        "4510d8fb-4bbf-4da2-ab3e-c5041b0a08e6", // javelo
      ].includes(this.uiService.currentOrgId)
    ) {
      return true;
    }

    return (
      integrationSpecs[type].visibleInMarketplace ||
      this.sessionService.isConnectedAs() ||
      this.accountIsScreebTeam()
    );
  }

  public isIntegrationZapierAvailable(): boolean {
    return this.displayIntegrationCard("zapier");
  }

  public isNotionReplayButtonVisible(): boolean {
    return this.sessionService.isConnectedAs() || this.accountIsScreebTeam();
  }

  public isAtlassianReplayButtonVisible(): boolean {
    return this.sessionService.isConnectedAs() || this.accountIsScreebTeam();
  }

  // billing
  public displayStripeShortcut(): boolean {
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  // content analysis
  public hideOpenAIContentAnalysis(): boolean {
    return (
      // opendatasoft - https://screeb.slack.com/archives/DMCJJBWUB/p1677773532209509
      this.uiService.currentOrgId === "a65a7002-1b81-4281-935f-b1086f2af0ff"
    );
  }

  public isInteractionEnabled(): boolean {
    return this.accountIsScreebTechTeam();
  }
}

export { FeatureFlaggingService };
