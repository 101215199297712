<div>
  <section class="content">
    <free-trial-banner />
    <div class="header-group">
      <h1>{{ uiService.isMessagePage ? "Messages" : "Surveys" }}</h1>
      <p *ngIf="uiService.isMessagePage">
        Display messages directly to your users
      </p>
      <div class="spacer"></div>
      <button
        *ngIf="
          !uiService.isMessagePage &&
          ('survey.create' | hasPermission) &&
          ('isImportSurveysEnabled' | hasFeatureFlag)
        "
        class="add"
        nz-button
        nzSize="large"
        nzType="default"
        (click)="onSurveysImport()"
      >
        <i
          *ngIf="
            !entitlementService.isQuotaExceeded('max_survey', surveys.length)
          "
          nz-icon
          nzType="import"
        ></i>
        <screeb-icon
          *ngIf="
            entitlementService.isQuotaExceeded('max_survey', surveys.length)
          "
          size="sm"
          icon="upgrade"
        />
        Import Surveys
      </button>
    </div>

    <div class="header-actions" *ngIf="'survey.create' | hasPermission">
      <div class="header-action" (click)="onSurveyCreateTemplate()">
        <div class="header-action__img header-action__img--blue">
          <img
            alt="Create from template"
            src="assets/icons/surveys/template.svg"
          />
        </div>
        <div class="header-action__info">
          <p class="header-action__title">Create from template</p>
          <p class="header-action__desc">
            {{
              uiService.isMessagePage
                ? "Start from one of our multiple messages templates scenario."
                : "Start from one of our multiple surveys template."
            }}
          </p>
        </div>
        <div class="header-action__btn">
          <screeb-icon
            *ngIf="
              entitlementService.isQuotaExceeded('max_survey', surveys.length)
            "
            size="sm"
            icon="upgrade"
          />
          <screeb-icon
            *ngIf="
              !entitlementService.isQuotaExceeded('max_survey', surveys.length)
            "
            size="sm"
            icon="plus"
          />
        </div>
      </div>
      <div class="header-action" (click)="onSurveyCreate()">
        <div class="header-action__img header-action__img--yellow">
          <img
            alt="Create from scratch"
            src="assets/icons/surveys/scratch.svg"
          />
        </div>
        <div class="header-action__info">
          <p class="header-action__title">Create from scratch</p>
          <p class="header-action__desc">
            {{
              uiService.isMessagePage
                ? "Create your own messages and chain them for your needs."
                : "
            Create your own survey from scratch and ask tailored questions to
            your users."
            }}
          </p>
        </div>
        <div class="header-action__btn">
          <screeb-icon
            *ngIf="
              entitlementService.isQuotaExceeded('max_survey', surveys.length)
            "
            size="sm"
            icon="upgrade"
          />
          <screeb-icon
            *ngIf="
              !entitlementService.isQuotaExceeded('max_survey', surveys.length)
            "
            size="sm"
            icon="plus"
          />
        </div>
      </div>
      <div
        *ngIf="!uiService.isMessagePage"
        class="header-action"
        (click)="onSurveyCreate(true)"
      >
        <div class="header-action__img header-action__img--purple">
          <img alt="Create from scratch" src="assets/icons/surveys/ai.svg" />
        </div>
        <div class="header-action__info">
          <p class="header-action__title">Create with AI</p>
          <p class="header-action__desc">
            {{
              uiService.isMessagePage
                ? "Use AI to generate a flow of messages. Edit as you like."
                : "Use AI to generate a survey scenario. Edit as you like."
            }}
          </p>
        </div>
        <div class="header-action__btn">
          <screeb-icon
            *ngIf="
              entitlementService.isQuotaExceeded('max_survey', surveys.length)
            "
            size="sm"
            icon="upgrade"
          />
          <screeb-icon
            *ngIf="
              !entitlementService.isQuotaExceeded('max_survey', surveys.length)
            "
            size="sm"
            icon="plus"
          />
        </div>
      </div>
    </div>

    <upgrade-modal
      *ngIf="upgradePopupVisible"
      [canCancel]="true"
      [withOverlay]="true"
      (closeClick)="upgradePopupVisible = false"
      [centeredModal]="true"
      [orgId]="org.id"
      position="absolute"
      upgradeType="max_survey"
      ctaText="Upgrade Now!"
    />

    <div class="filters-bar">
      <div class="tags-filter">
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="All Tags"
          nzSize="large"
          nzMode="multiple"
          [ngModel]="filteredTags"
          [nzShowArrow]="true"
          nzSuffixIcon="caret-down"
          (ngModelChange)="onTagsFilterChange($event)"
        >
          <ng-container *ngFor="let tag of surveyTags">
            <nz-option [nzLabel]="tag" [nzValue]="tag"></nz-option>
          </ng-container>
        </nz-select>
      </div>
      <div class="sequences-filter">
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="All Sequences"
          nzSize="large"
          nzMode="multiple"
          [ngModel]="filteredSequences"
          [nzShowArrow]="true"
          nzSuffixIcon="caret-down"
          (ngModelChange)="onSequencesFilterChange($event)"
        >
          <ng-container *ngFor="let sequence of sequences">
            <nz-option
              [nzLabel]="sequence.title"
              [nzValue]="sequence.id"
            ></nz-option>
          </ng-container>
        </nz-select>
      </div>
      <div class="status-filter">
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="All Status"
          nzSize="large"
          [ngModel]="filteredStatus"
          (ngModelChange)="onStatusFilterChange($event)"
        >
          <nz-option nzLabel="Running" [nzValue]="true"></nz-option>
          <nz-option nzLabel="Paused" [nzValue]="false"></nz-option>
        </nz-select>
      </div>
      <div class="spacer"></div>
      <div class="search-bar">
        <nz-input-group
          nzSize="large"
          [nzPrefix]="prefixIcon"
          [nzSuffix]="inputClearButton"
        >
          <input
            type="text"
            nz-input
            placeholder="Search a {{
              uiService.isMessagePage ? 'message' : 'survey'
            }}"
            [(ngModel)]="keywords"
            (ngModelChange)="onSearchChange()"
          />
        </nz-input-group>
        <ng-template #prefixIcon><i nz-icon nzType="search"></i></ng-template>
        <ng-template #inputClearButton>
          <i
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="keywords"
            (click)="keywords = ''; onSearchChange()"
          ></i>
        </ng-template>
      </div>
    </div>

    <div class="survey-table-group" *ngIf="ready">
      <nz-table
        class="surveys-table"
        #table
        [nzData]="filteredSurveys"
        [nzHideOnSinglePage]="true"
        [nzPageSize]="100"
        [nzShowPagination]="filteredSurveys.length > 100"
        nzTableLayout="fixed"
        [nzScroll]="{ x: '1100px' }"
      >
        <thead>
          <tr>
            <th
              *ngFor="let column of listOfColumns"
              [(nzSortOrder)]="column.sortOrder"
              [nzSortFn]="column.sortFn"
              [nzSortDirections]="column.sortDirections"
              [nzFilterMultiple]="column.filterMultiple"
              [nzFilters]="column.listOfFilter"
              [nzFilterFn]="column.filterFn"
              [nzWidth]="column.width + 'px'"
              [nzAlign]="column.align"
            >
              {{ column.name }}
            </th>
            <th [nzWidth]="'140px'" nzRight></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let survey of table.data">
            <!--
                HACK: using (click), we cannot right-click on a row and select "open in a new table".
                So we added a "<a>" html tag between tbody and tr.
              -->
            <a
              class="hack"
              routerLink="/org/{{ org.id }}/{{ pathPrefix }}/{{
                survey.id
              }}/stats"
              routerLinkActive="active"
            >
              <tr>
                <td class="bold-text">
                  <ng-template
                    [ngIf]="currentSurveyNameEditing === survey.id"
                    [ngIfElse]="elseTemplate"
                  >
                    <input
                      type="text"
                      value="{{ survey.title }}"
                      placeholder="Survey title"
                      #renameInput
                      (keydown.enter)="onRenameInputValidate($event, survey)"
                      (keydown.escape)="stopRenameSurvey()"
                      (blur)="stopRenameSurvey()"
                    />
                  </ng-template>
                  <ng-template #elseTemplate>
                    <a
                      class="survey-title"
                      routerLink="/org/{{ org.id }}/{{ pathPrefix }}/{{
                        survey.id
                      }}"
                      routerLinkActive="active"
                      (click)="
                        $event.preventDefault(); $event.stopPropagation()
                      "
                    >
                      {{ survey.title }}
                    </a>
                  </ng-template>
                  <tags [tags]="survey.tags"></tags>
                  <a
                    *ngIf="survey.sequence_id"
                    routerLink="/org/{{ org.id }}/funnel/{{
                      survey.sequence_id
                    }}/report"
                    title="Go to sequence"
                  >
                    <nz-tag
                      [style.color]="'var(--screeb-color-white)'"
                      nzColor="var(--screeb-color-blue-500)"
                    >
                      {{ getSurveySequenceTags(survey) }}
                    </nz-tag>
                  </a>
                </td>
                <td>
                  <ng-container
                    *ngFor="let distrib of survey.survey_distributions"
                  >
                    <survey-channel-status
                      *ngIf="
                        permissionsService.isAllowed(
                          'survey.distribution.update'
                        )
                      "
                      [running]="distrib.enabled"
                      [testMode]="
                        getChannelById(distrib.channel_id)?.type !==
                          'hosted-page' && distrib.test_mode
                      "
                      [isInStartEndDates]="isInStartEndDates(distrib)"
                      [loading]=""
                      [channelsType]="[
                        getChannelById(distrib.channel_id)?.type
                      ]"
                      (click)="
                        onSurveyStatusChange(
                          $event,
                          survey,
                          distrib,
                          !distrib.enabled
                        )
                      "
                    ></survey-channel-status>
                    <survey-channel-status
                      *ngIf="
                        !permissionsService.isAllowed(
                          'survey.distribution.update'
                        )
                      "
                      [running]="distrib.enabled"
                      [testMode]="
                        getChannelById(distrib.channel_id)?.type !==
                          'hosted-page' && distrib.test_mode
                      "
                      [loading]=""
                      [channelsType]="[
                        getChannelById(distrib.channel_id)?.type
                      ]"
                    ></survey-channel-status>
                  </ng-container>
                </td>
                <td nzAlign="center">{{ survey.stats.response_total }}</td>
                <td nzAlign="center">
                  {{ (survey.stats.response_rate * 100).toFixed() }}%
                </td>
                <td>
                  <div class="updated_by">
                    <org-account-avatar
                      *ngIf="survey.updated_by_account"
                      [account]="survey.updated_by_account"
                      size="small"
                    ></org-account-avatar>
                    <div class="updated_by_time">
                      {{
                        survey.updated_at
                          | dfnsFormatDistanceToNow: { addSuffix: true }
                      }}
                    </div>
                  </div>
                </td>
                <td nzAlign="right" class="actions" nzRight>
                  <a
                    routerLink="/org/{{ org.id }}/{{ pathPrefix }}/{{
                      survey.id
                    }}/stats"
                    title="Stats"
                    nz-button
                    class="button-create"
                    nzSize="large"
                    nzType="default"
                    nzShape="round"
                    (click)="$event.preventDefault(); $event.stopPropagation()"
                  >
                    <img
                      alt="Stats"
                      nz-icon
                      src="/assets/icons/homepage/graph.svg"
                    />
                  </a>
                  <a
                    *ngIf="'survey.update' | hasPermission"
                    routerLink="/org/{{ org.id }}/{{ pathPrefix }}/{{
                      survey.id
                    }}/edit"
                    title="Edit"
                    nz-button
                    class="button-create"
                    nzSize="large"
                    nzType="primary"
                    nzShape="round"
                    (click)="$event.preventDefault(); $event.stopPropagation()"
                  >
                    <img
                      alt="Edit"
                      nz-icon
                      src="/assets/icons/homepage/pen.svg"
                    />
                  </a>
                  <!-- survey action dropdown-->
                  <button
                    *ngIf="'survey.update' | hasPermission"
                    title="More"
                    nz-button
                    class="button-create"
                    nzSize="large"
                    nzType="text"
                    nzShape="round"
                    (click)="$event.preventDefault(); $event.stopPropagation()"
                  >
                    <i
                      nz-icon
                      [nzType]="'more'"
                      class="survey-actions"
                      [ngClass]="{
                        opened: currentSurveyActionDropDown === survey.id
                      }"
                      nz-dropdown
                      nzTrigger="click"
                      nzPlacement="bottomRight"
                      [nzDropdownMenu]="surveyActionsDropdown"
                      (nzVisibleChange)="
                        setCurrentSurveyActionDropDown(survey.id, $event)
                      "
                    ></i>
                  </button>

                  <nz-dropdown-menu #surveyActionsDropdown="nzDropdownMenu">
                    <ul nz-menu class="sidebar-surveys-dropdown-actions-list">
                      <style>
                        /* because overlay is displayed out of component */
                        .sidebar-surveys-dropdown-actions-list li.disabled {
                          background: transparent;
                          cursor: default;
                        }

                        .sidebar-surveys-dropdown-actions-list a {
                          padding: 0;
                          padding-left: 10px;
                          text-align: left;
                        }

                        .sidebar-surveys-dropdown-actions-list button {
                          margin-top: -5px;
                          margin-bottom: -5px;
                          padding: 0;
                        }

                        .sidebar-surveys-dropdown-actions-list button i.green {
                          color: green;
                        }

                        .sidebar-surveys-dropdown-actions-list button i.yellow {
                          color: #ffc046;
                        }
                      </style>
                      <li nz-menu-item (click)="onRename(survey)">
                        <button nz-button nzType="text">Rename</button>
                      </li>
                      <li
                        nz-menu-item
                        (click)="onDupicateSelectWorkspace(survey)"
                      >
                        <button
                          nz-button
                          nzType="text"
                          [nzLoading]="
                            getSurveyActionLoaderStatus(
                              survey.id,
                              'duplicating'
                            )
                          "
                        >
                          Duplicate
                        </button>
                      </li>
                      <li nz-menu-item (click)="onDelete(survey)">
                        <button
                          nz-button
                          nzType="text"
                          [nzLoading]="
                            getSurveyActionLoaderStatus(survey.id, 'deleting')
                          "
                        >
                          Delete
                        </button>
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                </td>
              </tr>
            </a>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
    <upgrade-banner
      *ngIf="entitlementService.isQuotaExceeded('max_survey', surveys.length)"
      class="upgrade-banner"
      [orgId]="org.id"
      upgradeType="max_survey"
      CTAText="Upgrade Now!"
    />
  </section>
  <surveys-import-modal
    [isOpen]="importModalOpened"
    (modalOpenChange)="importModalOpened = $event"
    [org]="org"
  />
  <duplicate-survey-modal
    [isOpen]="duplicateModalOpened"
    [orgs]="workspaces"
    [workspaceId]="duplicateTargetWorkspaceId"
    (workspaceChange)="duplicateTargetWorkspaceId = $event"
    (duplicate)="onDuplicateSurvey()"
    (modalClose)="onDuplicateCancel()"
  ></duplicate-survey-modal>
</div>
