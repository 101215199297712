import { TagSettings } from "./tag-settings.types";

// To avoid "TS2590: Expression produces a union type that is too complex to represent." error
// We must use an helper to merge instead of using the spread operator
export function mergeTagSettings(
  ...settings: Partial<TagSettings>[]
): TagSettings | null {
  const mergedSettings: Partial<TagSettings> = {};
  settings.forEach((setting) => {
    if (setting) {
      Object.assign(mergedSettings, setting);
    }
  });
  const validatedSettings = validateTagSettings(mergedSettings);
  return validatedSettings;
}

function validateTagSettings(
  settings: Partial<TagSettings>,
): TagSettings | null {
  return settings as TagSettings;
}

const adaptTagSettingsBackgroundSettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  switch (tagSettingsToAdapt.page_background_type) {
    case "image": {
      const { page_background_type, page_background_image_url } =
        tagSettingsToAdapt;
      return { page_background_type, page_background_image_url };
    }
    case "color": {
      const { page_background_type, page_background_color } =
        tagSettingsToAdapt;
      return { page_background_type, page_background_color };
    }
    default:
      return {};
  }
};

const adaptTagSettingsCardIdentitySettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  switch (tagSettingsToAdapt.cards_identity_type) {
    case "fixed": {
      const {
        cards_identity_type,
        cards_identity_fixed_name,
        cards_identity_fixed_title,
        cards_identity_fixed_avatar,
      } = tagSettingsToAdapt;
      return {
        cards_identity_type,
        cards_identity_fixed_name,
        cards_identity_fixed_title,
        cards_identity_fixed_avatar,
      };
    }
    default:
      return {};
  }
};

const adaptTagSettingsConversationalIdentitySettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  switch (tagSettingsToAdapt.conversational_identity_type) {
    case "fixed": {
      const {
        conversational_identity_type,
        conversational_identity_fixed_name,
        conversational_identity_fixed_title,
        conversational_identity_fixed_avatar,
      } = tagSettingsToAdapt;
      return {
        conversational_identity_type,
        conversational_identity_fixed_name,
        conversational_identity_fixed_title,
        conversational_identity_fixed_avatar,
      };
    }
    default:
      return {};
  }
};

const adaptTagSettingsInPageIdentitySettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  switch (tagSettingsToAdapt.in_page_identity_type) {
    case "fixed": {
      const {
        in_page_identity_type,
        in_page_identity_fixed_name,
        in_page_identity_fixed_title,
        in_page_identity_fixed_avatar,
      } = tagSettingsToAdapt;
      return {
        in_page_identity_type,
        in_page_identity_fixed_name,
        in_page_identity_fixed_title,
        in_page_identity_fixed_avatar,
      };
    }
    default:
      return {};
  }
};

const adaptTagSettingsMessagesIdentitySettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  switch (tagSettingsToAdapt.messages_identity_type) {
    case "fixed": {
      const {
        messages_identity_type,
        messages_identity_fixed_name,
        messages_identity_fixed_title,
        messages_identity_fixed_avatar,
      } = tagSettingsToAdapt;
      return {
        messages_identity_type,
        messages_identity_fixed_name,
        messages_identity_fixed_title,
        messages_identity_fixed_avatar,
      };
    }
    default:
      return {};
  }
};

const adaptTagSettingsColorSettingsToApi = ({
  advanced,
  cards_color_primary,
  cards_color_primary_text,
  conversational_color_primary,
  conversational_color_primary_text,
  in_page_color_primary,
  in_page_color_primary_text,
  messages_color_primary,
  messages_color_primary_text,
}: TagSettings) => {
  if (advanced) {
    return {};
  }

  return {
    cards_color_primary,
    cards_color_primary_text,
    conversational_color_primary,
    conversational_color_primary_text,
    in_page_color_primary,
    in_page_color_primary_text,
    messages_color_primary,
    messages_color_primary_text,
  };
};

export function resetDefaultsValuesIfNecessary(
  tagSettings: TagSettings,
): TagSettings {
  if (tagSettings.advanced) {
    return tagSettings;
  }

  return {
    ...tagSettings,

    // cards
    cards_border_radius: 16,
    cards_border_line: false,
    cards_border_line_width: 2,
    cards_border_line_color: "#303140FF",
    cards_border_shadow: true,
    cards_border_shadow_color: "#3031401A",
    cards_color_secondary: "#F3F4F9",
    cards_color_secondary_text: "#303140",
    cards_display_reduce_button: true,
    cards_font_family: "Rubik",
    cards_font_line_height: 24,
    cards_font_size: 16,
    cards_identity_avatar_format: "rounded_square",
    cards_overlay_color: "#313140A6",
    cards_overlay_visibility: "hidden",
    cards_size: 100,

    // conversational
    conversational_border_radius: 16,
    conversational_border_line: false,
    conversational_border_line_width: 2,
    conversational_border_line_color: "#303140FF",
    conversational_border_shadow: true,
    conversational_border_shadow_color: "#30314040",
    conversational_color_secondary: "#FFFFFF",
    conversational_color_secondary_text: "#303140",
    conversational_display_close_reduce: false,
    conversational_font_family: "Rubik",
    conversational_font_line_height: 24,
    conversational_font_size: 16,
    conversational_identity_avatar_format: "rounded_square",
    conversational_overlay_color: "#313140A6",
    conversational_overlay_visibility: "response",
    conversational_size: 100,

    // in_page
    in_page_border_radius: 16,
    in_page_border_line: false,
    in_page_border_line_width: 2,
    in_page_border_line_color: "#303140FF",
    in_page_border_shadow: true,
    in_page_border_shadow_color: "#3031401A",
    in_page_color_secondary: "#F3F4F9",
    in_page_color_secondary_text: "#303140",
    in_page_display_reduce_button: false,
    in_page_font_family: "Rubik",
    in_page_font_line_height: 24,
    in_page_font_size: 16,
    in_page_identity_avatar_format: "rounded_square",
    in_page_overlay_color: "#313140A6",
    in_page_overlay_visibility: "hidden",
    in_page_size: 100,

    // messages
    messages_border_radius: 16,
    messages_border_line: false,
    messages_border_line_width: 2,
    messages_border_line_color: "#303140FF",
    messages_border_shadow: true,
    messages_border_shadow_color: "#3031401A",
    messages_color_secondary: "#F3F4F9",
    messages_color_secondary_text: "#303140",
    messages_color_tertiary: "#FFFFFF",
    messages_color_tertiary_text: "#303140",
    messages_font_family: "Rubik",
    messages_font_line_height: 24,
    messages_font_size: 16,
    messages_title_font_family: "Rubik",
    messages_title_font_weight: 500,
    messages_title_font_size: 16,
    messages_identity_avatar_format: "rounded_square",
    messages_overlay_color: "#313140A6",
    messages_overlay_visibility: "always",
    messages_size: 100,
  };
}

export function adaptTagSettingsToApi(
  tagSettingsToAdapt: TagSettings,
  scope: "org" | "survey" | "distribution",
): Partial<TagSettings> | Record<string, never> {
  const tagSettings: TagSettings = { ...tagSettingsToAdapt };

  if (!tagSettings) {
    return {};
  }

  // awful, yuck!
  switch (tagSettings.page_background_type) {
    case "color":
      delete tagSettings["page_background_image_url"];
      break;
    case "image":
      delete tagSettings["page_background_color"];
      break;
  }

  switch (scope) {
    case "org":
      return resetDefaultsValuesIfNecessary(tagSettings);
    default:
      const {
        format,
        cards_position,
        conversational_position,
        in_page_position,
        cards_pop_sound,
        conversational_pop_sound,
        in_page_pop_sound,
        messages_pop_sound,
        cards_display_reduce_button,
        in_page_display_reduce_button,
      } = tagSettings;

      return {
        format,
        cards_position,
        conversational_position,
        in_page_position,
        cards_pop_sound,
        conversational_pop_sound,
        in_page_pop_sound,
        messages_pop_sound,
        cards_display_reduce_button,
        in_page_display_reduce_button,

        ...adaptTagSettingsColorSettingsToApi(tagSettings),
        ...adaptTagSettingsBackgroundSettingsToApi(tagSettings),
        ...adaptTagSettingsCardIdentitySettingsToApi(tagSettings),
        ...adaptTagSettingsConversationalIdentitySettingsToApi(tagSettings),
        ...adaptTagSettingsInPageIdentitySettingsToApi(tagSettings),
        ...adaptTagSettingsMessagesIdentitySettingsToApi(tagSettings),
      };
  }
}
