import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "settings-data-governance-retention-selector",
  template: `
    <div class="label">
      {{ what }} retention
      <nz-switch
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
        [ngModelOptions]="{ standalone: true }"
        [ngModel]="value"
        (ngModelChange)="valueChange.emit(value ? null : 365)"
      ></nz-switch>
      <ng-template #checkedTemplate
        ><span nz-icon nzType="check"></span
      ></ng-template>
      <ng-template #unCheckedTemplate
        ><span nz-icon nzType="close"></span
      ></ng-template>
    </div>

    <nz-input-number
      ngDefaultControl
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="value"
      (ngModelChange)="valueChange.emit($event)"
      [nzMin]="30"
      [nzMax]="3650"
      [nzStep]="1"
      [disabled]="!value"
    ></nz-input-number>

    days
  `,
  styles: [
    `
      .label {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        margin: 4px 0;
      }
    `,
  ],
})
export class SettingsDataGovernanceRetentionSelectorComponent {
  @Input() what: string;
  @Input() value: number;
  @Output() valueChange = new EventEmitter<number>();

  constructor() {}
}
