import { CTAType, UUID } from "./survey.dao.types";

// Operators

export type AnalyticsFiltersOperator = "AND" | "OR";

export type AnalyticsFilterOperatorEqualOrNotEqual = {
  operator: "eq"; // | 'neq';
  value: string | number | boolean;
};

type AnalyticsFilterOperatorExistsOrNotExists = {
  operator: "not_null" | "null";
};

export type AnalyticsFilterOperatorIn = {
  operator: "in" | "not_in";
  values?: string[];
  action_correlation_ids?: string[];
};

export type AnalyticsFilterOperatorContainsOrNotContains = {
  operator: "contains"; // | 'not_contains';
  value: string;
};

export type AnalyticsFilterOperatorGreaterOrLessThan = {
  operator: "gt" | "lt";
  value: number;
};

type AnalyticsFilterOperatorBeforeOrAfter = {
  operator: "before" | "after";
  value: Date;
};

// not a big fan of this, see how its possible to harmonize this
export type AnalyticsFilterOperatorGreaterOrLessThanPreset = {
  operator: "preset.gt" | "preset.lt";
  value: number;
};

export type AnalyticsFilterOperator =
  | AnalyticsFilterOperatorEqualOrNotEqual
  | AnalyticsFilterOperatorExistsOrNotExists
  | AnalyticsFilterOperatorIn
  | AnalyticsFilterOperatorContainsOrNotContains
  | AnalyticsFilterOperatorGreaterOrLessThan
  | AnalyticsFilterOperatorBeforeOrAfter
  | AnalyticsFilterOperatorGreaterOrLessThanPreset;

// Filters

type GenericAnalyticsFilter<Type, Key> = {
  type: Type;
  key: Key;
};

export type AnalyticsFilterResponse = GenericAnalyticsFilter<
  "response",
  | "respondent_id"
  | "respondent_aliases"
  | "respondent_segments"
  | "channel_id"
  | "raw"
  | "language"
  | "emotions"
  | "types"
  | "tags"
> &
  (
    | AnalyticsFilterOperatorEqualOrNotEqual
    | AnalyticsFilterOperatorContainsOrNotContains
    | AnalyticsFilterOperatorIn
  );
export type AnalyticsFilterResponseAnswer = GenericAnalyticsFilter<
  "response.answer",
  UUID
> &
  (
    | AnalyticsFilterOperatorEqualOrNotEqual
    | AnalyticsFilterOperatorExistsOrNotExists
    | AnalyticsFilterOperatorContainsOrNotContains
    | AnalyticsFilterOperatorGreaterOrLessThan
    | AnalyticsFilterOperatorGreaterOrLessThanPreset // !
    | AnalyticsFilterOperatorIn
  ); // ?
export type AnalyticsFilterResponseHiddenField = GenericAnalyticsFilter<
  "response.hidden_field",
  string
> &
  (
    | AnalyticsFilterOperatorEqualOrNotEqual
    | AnalyticsFilterOperatorExistsOrNotExists
    | AnalyticsFilterOperatorContainsOrNotContains
    | AnalyticsFilterOperatorGreaterOrLessThan
    | AnalyticsFilterOperatorBeforeOrAfter
  );

export type AnalyticsFilterUser = GenericAnalyticsFilter<
  "respondent",
  "aliases" | "raw" | "created_at" | "last_activity_at" | "segment"
> &
  (
    | AnalyticsFilterOperatorEqualOrNotEqual
    | AnalyticsFilterOperatorExistsOrNotExists
    | AnalyticsFilterOperatorContainsOrNotContains
    | AnalyticsFilterOperatorGreaterOrLessThan
    | AnalyticsFilterOperatorBeforeOrAfter
    | AnalyticsFilterOperatorIn
  );
export type AnalyticsFilterUserProperty = GenericAnalyticsFilter<
  "respondent.property",
  string
> &
  (
    | AnalyticsFilterOperatorEqualOrNotEqual
    | AnalyticsFilterOperatorExistsOrNotExists
    | AnalyticsFilterOperatorContainsOrNotContains
    | AnalyticsFilterOperatorGreaterOrLessThan
    | AnalyticsFilterOperatorBeforeOrAfter
  );
export type AnalyticsFilterUserEvent = GenericAnalyticsFilter<
  "respondent.event",
  string
> &
  (
    | AnalyticsFilterOperatorEqualOrNotEqual
    | AnalyticsFilterOperatorExistsOrNotExists
    | AnalyticsFilterOperatorGreaterOrLessThan
    | AnalyticsFilterOperatorBeforeOrAfter
  );

export type AnalyticsFilterForResponse =
  | AnalyticsFilterResponse
  | AnalyticsFilterResponseAnswer
  | AnalyticsFilterResponseHiddenField;

export type AnalyticsFilterForUser =
  | AnalyticsFilterUser
  | AnalyticsFilterUserProperty
  | AnalyticsFilterUserEvent;

export type AnalyticsFilter =
  | AnalyticsFilterForResponse
  | AnalyticsFilterForUser;

// Sort

export type SortOrder = "asc" | "desc";

type GenericSort = {
  order: SortOrder;
};

type SortForResponse = GenericSort &
  (
    | { field: "response.last_answer_at" }
    | { field: "response.longest_answers" }
  );

export type SortForUser = GenericSort & {
  field:
    | "respondent.last_activity_at"
    | "respondent.created_at"
    | "respondent.events_count";
};

// Aggregations

type AnalyticsAggregationByDate = {
  by: "by_date";
  params: {
    date_histogram_min_interval: "month" | "day" | "hour";
    date_histogram_buckets: number;
    date_histogram_timezone_offset: number;
  };
};

type AnalyticsAggregationGPT3Summary = {
  by: "gpt3.summary";
  params: {
    node_correlation_id: string;
  };
};

type AnalyticsAggregationGPT3Category = {
  by: "gpt3.category";
  params: {
    node_correlation_id: string;
  };
};

type AnalyticsAggregationGPT3Improvement = {
  by: "gpt3.improvement";
  params: {
    node_correlation_id: string;
  };
};

type AnalyticsAggregationByCompletion = {
  by: "by_completion";
};

type AnalyticsAggregationByQuestionCorrelationId = {
  by: "by_question_correlation_id";
  params: object;
};

type AnalyticsAggregationByAnswerValue = {
  by: "by_answer.value";
  params: {
    answer_ids?: UUID[];
    cta_type?: CTAType;
  };
};

type AnalyticsAggregationByAnswerAverage = {
  by: "by_answer.average";
  params: {
    answer_ids?: UUID[];
  };
};

type AnalyticsAggregationByAnswerMedian = {
  by: "by_answer.median";
  params: {
    answer_ids?: UUID[];
  };
};

type AnalyticsAggregationByHiddenFieldKey = {
  by: "by_hidden_field.key";
};

type AnalyticsAggregationByHiddenFieldValue = {
  by: "by_hidden_field.value";
  params: {
    hidden_field_key: string;
  };
};

type AnalyticsAggregationBySignificantText = {
  by: "by_significant_text.raw";
  params: {
    significant_text_count: number;
  };
};

type AnalyticsAggregationByLanguage = {
  by: "by_language";
};

type AnalyticsAggregationByEmotions = {
  by: "by_emotions";
};

type AnalyticsAggregationByTags = {
  by: "by_tags";
};

type AnalyticsAggregationByPath = {
  by: "by_path";
};

type AnalyticsAggregationByEventMostTriggered = {
  by: "by_event.most_triggered";
};

type AnalyticsAggregationByEventDate = {
  by: "by_event.date";
  params: {
    date_histogram_min_interval: "month" | "day" | "hour";
    date_histogram_buckets: number;
    date_histogram_timezone_offset: number;
  };
};

type AnalyticsAggregationByEventCount = {
  by: "by_event.count";
  params: {
    event_id: string;
  };
};

type AnalyticsAggregationByEventSequence = {
  by: "by_event.sequence";
  params: {
    event_sequence_date_from: Date;
    event_sequence_date_to: Date;
    event_sequence_ids: UUID[];
  };
};

type AnalyticsAggregationByPropertyMostTracked = {
  by: "by_property.most_tracked";
};

type AnalyticsAggregationByPowerUserMostActive = {
  by: "by_power_users.most_active";
};

type AnalyticsAggregationByPowerUserLeastActive = {
  by: "by_power_users.least_active";
};

type AnalyticsAggregationByPowerUserTopMrr = {
  by: "by_power_users.top_mrr";
};

export type AnalyticsAggregationForResponse =
  | AnalyticsAggregationByDate
  | AnalyticsAggregationGPT3Summary
  | AnalyticsAggregationGPT3Category
  | AnalyticsAggregationGPT3Improvement
  | AnalyticsAggregationByCompletion
  | AnalyticsAggregationByQuestionCorrelationId
  | AnalyticsAggregationByAnswerValue
  | AnalyticsAggregationByAnswerAverage
  | AnalyticsAggregationByAnswerMedian
  | AnalyticsAggregationByHiddenFieldKey
  | AnalyticsAggregationByHiddenFieldValue
  | AnalyticsAggregationByLanguage
  | AnalyticsAggregationByEmotions
  | AnalyticsAggregationByTags
  | AnalyticsAggregationByPath
  | AnalyticsAggregationBySignificantText;

export type AnalyticsAggregationForUser =
  | AnalyticsAggregationByEventMostTriggered
  | AnalyticsAggregationByEventDate
  | AnalyticsAggregationByEventCount
  | AnalyticsAggregationByEventSequence
  | AnalyticsAggregationByPropertyMostTracked
  | AnalyticsAggregationByPowerUserMostActive
  | AnalyticsAggregationByPowerUserLeastActive
  | AnalyticsAggregationByPowerUserTopMrr;

// Range

export type DateRange<T extends string> = {
  field: T;
  start: Date;
  end: Date;
};

// Queries

export type GenericAnalyticsQuery = {
  org_id: UUID;
  survey_ids: UUID[] | ["*"];
  offset?: number;
  size?: number;
  filters_bool: AnalyticsFiltersOperator;
};

export type AnalyticsQueryResponse = GenericAnalyticsQuery & {
  type: "response";
  filters: AnalyticsFilterForResponse[];
  range: DateRange<"created_at">;
  aggregation?: AnalyticsAggregationForResponse[];
  sort?: SortForResponse;
  question_ids?: UUID[];
  having_answer_only?: boolean;
};

export type AnalyticsQueryUsers = GenericAnalyticsQuery & {
  type: "respondent";
  filters: AnalyticsFilterForUser[];
  range: DateRange<"last_activity_at" | "created_at">;
  aggregation?: AnalyticsAggregationForUser[];
  sort?: SortForUser;
  keyword?: string;
  identified_only?: boolean;
};

export type AnalyticsQuery = AnalyticsQueryResponse | AnalyticsQueryUsers;

export type AnalyticsIndex = AnalyticsQuery["type"];
export type AnalyticsFilters = AnalyticsQuery["filters"];

export function isAnalyticsFilterFor<T extends AnalyticsFilter>(
  type: AnalyticsFilter,
): type is T {
  return Boolean(type);
}
