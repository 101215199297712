/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  htmlSanitizer,
  stripHtml,
} from "components/builder/components/Cards/sanitized-message/sanitizer";
import { LateralPanelEmojiPickerConfig } from "components/builder/components/LateralPanel/LateralPanel.component";
import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  ActionButton,
  ActionSkip,
  CTALink,
  I18nTextLabel,
  SurveyLanguages,
  UUID,
  getI18nTextLabelTranslation,
  newCTASkip,
  setI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";
import { randomString } from "utils/random";
import { uuidv4 } from "utils/uuid";

const RANDOM_EMOJI = ["💪", "🥰", "😎", "🥳", "🤯", "😝", "👏", "🤘", "👌"];

@Component({
  selector: "lateral-panel-edit-link",
  templateUrl: "./edit-link.component.html",
  styleUrls: ["./edit-link.component.scss"],
})
export class LateralPanelEditLinkComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() public language: SurveyLanguages = "en";
  @Output() onOptionAdded = new EventEmitter();
  @Output() errorChange = new EventEmitter<boolean>();
  @Output() onEmojiPickerOpen =
    new EventEmitter<LateralPanelEmojiPickerConfig>();

  public labelErrors: string[][] = [];
  public linkErrors: string[][] = [];

  public uniqHash: string;

  constructor(
    public builderStore: BuilderStore,
    public entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    this.uniqHash = randomString(5);
    this.validateData();
  }

  public getError(ctaIndex: number): string[] {
    return [
      ...(this.labelErrors[ctaIndex] ?? []),
      ...(this.linkErrors[ctaIndex] ?? []),
    ];
  }

  public get cta(): CTALink {
    const cta = this.node.node.question.call_to_action;
    if (cta.type !== "link") throw Error("unexpected action type for link");
    return cta;
  }

  public get skipAction(): ActionSkip {
    return (this.node.node.question.skip_action ??= newCTASkip());
  }

  /**
   * Data validation
   */

  private isValidURL(url: string) {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    ); // validate fragment locator
    return !!urlPattern.test(url);
  }

  public validateLabel() {
    this.labelErrors = [];

    let error = false;
    this.labelErrors = this.cta.choices.map((link, idx) => {
      const text = this.getLabelText(idx)?.trim() || "";
      const textLength = this.getLength(text);

      if (textLength < 1) {
        error = true;
        return ["Label is required."];
      }
      if (textLength > 70) {
        error = true;
        return ["Max 70 characters allowed."];
      }

      return [];
    });

    return error;
  }

  public validateLink() {
    this.linkErrors = [];

    let error = false;
    this.linkErrors = this.cta.choices.map((link, idx) => {
      const url = this.getLabelLink(idx) || "";
      const urlLength = this.getLength(url);

      if (urlLength < 1) {
        return [];
      }
      if (urlLength > 250) {
        error = true;
        return ["Max 250 characters allowed."];
      }
      if (!this.isValidURL(url.trim())) {
        error = true;
        return ["Invalid URL."];
      }

      return [];
    });

    return error;
  }

  public validateData() {
    const error = this.validateLabel() || this.validateLink();
    if (error) this.errorChange.emit(true);
    else this.errorChange.emit(false);
  }

  public addOption() {
    if (this.node.isPreset) {
      throw Error("cannot add multiple choices in preset");
    }

    // default values
    const emoji = this.getRandomEmoji();
    const label = {} as I18nTextLabel;
    const link = {} as I18nTextLabel;
    setI18nTextLabelTranslation(label, "Your answer", this.language);
    setI18nTextLabelTranslation(link, "", this.language);

    this.cta.choices.push({
      id: uuidv4() as UUID,
      correlation_id: uuidv4() as UUID,
      type: "button",
      payload: {
        emoji,
        label,
        url: link,
      },
    });

    this.onOptionAdded.emit();
    this.validateData();
  }

  public removeOption(index: number) {
    if (this.node.isPreset) {
      throw Error("cannot add multiple choices in preset");
    }

    if (this.cta.choices.length <= 1) {
      return;
    }
    this.cta.choices.splice(index, 1);

    this.validateData();
  }

  private getRandomEmoji(): string {
    return RANDOM_EMOJI[Math.floor(Math.random() * RANDOM_EMOJI.length)];
  }

  /**
   * Emoji picker
   */
  public openEmojiPicker(
    buttonWithEmoji: ActionButton,
    btnElement: HTMLElement,
  ) {
    const buttonRect = btnElement.getBoundingClientRect();
    this.onEmojiPickerOpen.emit({
      action: buttonWithEmoji,
      positionTop: buttonRect.top + window.pageYOffset - 20,
      positionRight:
        window.innerWidth - buttonRect.left + window.pageXOffset + 10,
      canBeNull: false,
    });
  }

  public getLabelText(ctaIdx: number): string {
    const cta = this.node.node.question.call_to_action as CTALink;
    return decode(
      getI18nTextLabelTranslation(
        cta.choices[ctaIdx].payload.label,
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelText(ctaIdx: number, text: string) {
    const cta = this.node.node.question.call_to_action as CTALink;
    setI18nTextLabelTranslation(
      cta.choices[ctaIdx].payload.label,
      text,
      this.language,
    );
  }

  public getLabelLink(ctaIdx: number): string {
    const cta = this.node.node.question.call_to_action as CTALink;
    return decode(
      getI18nTextLabelTranslation(
        cta.choices[ctaIdx].payload.url,
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLink(ctaIdx: number, text: string) {
    const cta = this.node.node.question.call_to_action as CTALink;
    setI18nTextLabelTranslation(
      cta.choices[ctaIdx].payload.url,
      text,
      this.language,
    );
  }

  public setIsRandomize(randomize: boolean) {
    this.cta.randomize = randomize;
    this.cta.responsive = false;
    this.validateData();
  }

  public setIsResponsive(responsive: boolean) {
    this.cta.responsive = responsive;
    this.cta.randomize = false;
    this.validateData();
  }

  getLength(text: string) {
    return stripHtml(
      htmlSanitizer(text, {
        styling: true,
        CR: false,
        nativeCR: true,
        links: true,
      }),
    ).length;
  }
}
