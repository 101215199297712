import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { SeoService } from "services/seo.service";
import { Channel } from "models/channel.model";
import { PageComponentInterface } from "components/PageComponentInterface";
import { RoutingService } from "services/routing.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";

@Component({
  selector: "page-settings-install",
  templateUrl: "./channel-install.component.html",
  styleUrls: ["./channel-install.component.scss"],
})
export class SettingsChannelInstallPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Install Screeb";
  public name = "Settings install";

  private obs: any = null;
  public org: Org = null;
  public channels: Channel[] = null;
  public widgetChannel: Channel = null;
  public androidChannel: Channel = null;
  public iosChannel: Channel = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private notificationHelper: NotificationHelper,
    private seoService: SeoService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
      this.channels = data["channels"];
      this.widgetChannel = data["channels"].find(
        (channel) => channel.type === "widget",
      );
      this.androidChannel = data["channels"].find(
        (channel) => channel.type === "android",
      );
      this.iosChannel = data["channels"].find(
        (channel) => channel.type === "ios",
      );
    });
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }
}
