import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { fileToBase64, resizeImageWithRatio } from "models/upload.dao";

export const ONE_MEGA_OCTET = 1048576;

@Component({
  selector: "file-image-picker",
  templateUrl: "./file-image-picker.component.html",
  styleUrls: ["./file-image-picker.component.scss"],
})
export class FileImagePickerComponent {
  @Input()
  public imageFile: File = null;
  @Input()
  public imageUrl: string = null;
  @Input()
  public loading = false;
  @Input()
  public canDelete = true;
  @Input()
  public allowedExtensions: string[] = ["jpeg", "jpg", "png", "gif"];
  @Input()
  public maxSize = ONE_MEGA_OCTET; // 1Mo
  @Input()
  public resize: { minWidth: number; minHeight: number } = null;

  @Output()
  public imageFileChange: EventEmitter<File> = new EventEmitter();

  @ViewChild("upload")
  private uploadBtn: ElementRef;

  public error: string = null;

  public upload() {
    this.uploadBtn.nativeElement.click();
  }

  async onChange(event: any) {
    this.error = "";

    const file: File = event.target.files[0];
    if (!file) {
      return;
    }

    if (file.size > this.maxSize) {
      this.error = `Max file size: ${Math.round(this.maxSize / 1000000)} Mo`;
      return;
    }

    if (this.resize) {
      this.imageFile = await resizeImageWithRatio(
        file,
        this.resize.minWidth,
        this.resize.minHeight,
      );
    } else {
      this.imageFile = file;
    }

    this.imageUrl = await fileToBase64(this.imageFile);

    this.imageFileChange.emit(this.imageFile);
  }

  deleteClick() {
    this.imageFile = null;
    this.imageUrl = null;
    this.imageFileChange.emit(this.imageFile);
  }

  getAllowedExtensions() {
    return this.allowedExtensions.map((ext) => `.${ext}`).join(",");
  }
}
