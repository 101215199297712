import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";

import {
  IntegrationAtlassianProject,
  IntegrationDao,
} from "models/integration.dao";
import { ApplicationLoadService } from "services/app.service";
import { EntitlementService } from "services/entitlement.service";

@Injectable()
export class IntegrationAtlassianProjectsResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private integrationDao: IntegrationDao,
    public entitlementServiec: EntitlementService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Promise<IntegrationAtlassianProject[]> {
    return this.applicationLoadService
      .loader()
      .then(async () => {
        if (!this.entitlementServiec.isIntegrationAvailable("atlassian")) {
          return [];
        }

        const integrations = await this.integrationDao.getIntegrations(
          route.params["org_id"],
        );
        const integration = integrations.find(
          (int) => int.type === "atlassian",
        );
        if (!integration || !integration.auth_ok) {
          return [];
        }

        return this.integrationDao
          .atlassianListProjects(route.params["org_id"])
          .then((projects) => projects.projects);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
