import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Router } from "@angular/router";
import { NzModalComponent } from "ng-zorro-antd/modal";
import { AllowIn } from "ng-keyboard-shortcuts";
import { AskResult } from "models/ask_result.model";
import { OrgDao } from "models/org.dao";
import { Debounce } from "utils/debounce";

@Component({
  selector: "ai-helper",
  templateUrl: "./ai-helper.component.html",
  styleUrls: ["./ai-helper.component.scss"],
})
export class AiHelperComponent implements OnDestroy {
  @Input() public orgId: string = null;
  @Input() mode: "default" | "home" = "default";

  @ViewChild("modalComponent") modalComponent: NzModalComponent;

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler() {
    this.handleCancel();
  }

  public shortcuts = [
    {
      key: "cmd + k",
      label: "Search",
      description: "Search",
      preventDefault: true,
      allowIn: [
        AllowIn.Textarea,
        AllowIn.Input,
        AllowIn.Select,
        AllowIn.ContentEditable,
      ],
      command: () => {
        if (this.isOpen) {
          this.handleCancel();
        } else {
          this.onOpen();
        }
      },
    },
  ];

  public allCommands = [
    // Releases
    {
      keywords: ["release", "update"],
      label: "Releases",
      description: "View all your releases.",
      command: () => {
        this.router.navigate(["org", this.orgId, "releases"]);
      },
    },
    // Integrations
    {
      keywords: ["integration", "plugin", "addon"],
      label: "Integrations",
      description:
        "Connect Screeb to your daily tools and unleash the power of your users feedback!",
      command: () => {
        this.router.navigate(["org", this.orgId, "integrations"]);
      },
    },
    // Surveys
    {
      keywords: ["survey", "poll"],
      label: "Create a new Survey",
      description: "Create a new survey.",
      command: () => {
        this.router.navigate(["org", this.orgId, "survey", "create"]);
      },
    },
    {
      keywords: ["survey", "poll"],
      label: "Surveys",
      description: "View all your surveys.",
      command: () => {
        this.router.navigate(["org", this.orgId, "survey", "list"]);
      },
    },
    // Funnels
    {
      keywords: ["funnel", "conversion"],
      label: "Funnels",
      description: "View all your funnels.",
      command: () => {
        this.router.navigate(["org", this.orgId, "funnel", "list"]);
      },
    },
    // Users
    {
      keywords: ["user", "people", "respondent"],
      label: "Users",
      description: "View all your users.",
      command: () => {
        this.router.navigate(["org", this.orgId, "people", "respondent"]);
      },
    },
    // Segments
    {
      keywords: ["segment", "filter"],
      label: "Segments",
      description: "View all your segments.",
      command: () => {
        this.router.navigate(["org", this.orgId, "people", "segment"]);
      },
    },
    // Help
    {
      keywords: ["help", "support"],
      label: "Help",
      description: "View the help center.",
      icon: "question-mark",
      iconSize: "sm",
      command: () => {
        window.open("https://help.screeb.app", "_blank");
      },
    },
    {
      keywords: ["help", "support"],
      label: "Talk to a human",
      description: "We won't eat, I promise",
      icon: "talk-to-a-human",
      iconSize: "sm",
      command: () => {
        window.open("mailto:michael@screeb.app", "_blank");
      },
    },
    {
      keywords: ["share", "feedback", "suggestion", "idea", "bug"],
      label: "Share a feedback",
      description: "Share a feedback, a suggestion, an idea or a bug.",
      icon: "share",
      iconSize: "sm",
      command: () => {
        window.open("mailto:simon@screeb.app", "_blank");
      },
    },
    // Settings
    {
      keywords: ["settings", "preferences"],
      label: "Settings",
      description: "View your organization settings.",
      command: () => {
        this.router.navigate(["org", this.orgId, "settings", "customize"]);
      },
    },
    {
      keywords: ["settings", "preferences", "account"],
      label: "Account",
      description: "View your account settings.",
      command: () => {
        this.router.navigate(["org", this.orgId, "settings", "account"]);
      },
    },
    {
      keywords: ["capping", "limits"],
      label: "Survey Capping",
      description: "View your organization capping settings.",
      command: () => {
        this.router.navigate(["org", this.orgId, "settings", "survey-capping"]);
      },
    },
    {
      keywords: ["settings", "preferences", "billing"],
      label: "Billing",
      description: "View your billing settings.",
      command: () => {
        this.router.navigate(["org", this.orgId, "settings", "billing"]);
      },
    },
    {
      keywords: ["settings", "preferences", "team"],
      label: "Team members",
      description: "View your team members settings.",
      command: () => {
        this.router.navigate(["org", this.orgId, "settings", "team-members"]);
      },
    },
  ];

  public isOpen = false;
  public isAnswerOpen = false;
  public hasBeenOpened = false;
  public isLoading = false;
  public hasError = false;

  public query: string = "";
  public queryQuestion: string = "";

  public answer: any = null;

  public commands: any[] = null;
  public results: AskResult[] | null = null;

  public focusedIndex = 0; // 0 is the input, 1 is the first result

  public currentStream: any = null;

  constructor(
    private orgDao: OrgDao,
    private router: Router,
  ) {}

  @ViewChild("aiSearchInput", { static: false }) aiSearchInput: ElementRef;
  @ViewChildren("commands") commandsElements: QueryList<ElementRef>;
  @ViewChildren("results") resultsElements: QueryList<ElementRef>;

  ngOnDestroy(): void {
    this.currentStream?.close();
    this.currentStream = null;
  }

  onOpen(): void {
    if (!this.isOpen) {
      this.isOpen = true;
      if (this.hasBeenOpened && this.query.length > 0) {
        this.isAnswerOpen = true;
      }
      setTimeout(() => this.aiSearchInput.nativeElement.focus(), 0);
    }
  }

  @Debounce(150)
  onQueryChange(event): void {
    if (!this.isOpen && this.mode !== "default" && this.mode !== "home") return;
    this.query = event;
    if (this.query.length === 0) {
      this.queryQuestion = "";
      this.isAnswerOpen = false;
      setTimeout(() => {
        this.results = null;
        this.answer = null;
        this.commands = null;
      }, 150);
    } else if (this.mode === "default" || this.mode === "home") {
      this.onOpen();
      this.onEnter();
    }
  }

  onEnter(): void {
    if (this.query.length === 0) return;
    this.isLoading = true;
    this.hasError = false;
    this.queryQuestion = this.query;

    if (this.mode === "default" || this.mode === "home") {
      this._searchCommands();
      this._search();
    }
  }

  onArrowUp(): boolean {
    if (this.mode !== "default" && this.mode !== "home") {
      return true;
    }

    if (this.focusedIndex > 0) {
      this.focusedIndex--;
    }

    this.focusElement();
    return false;
  }

  onArrowDown(): boolean {
    if (this.mode !== "default" && this.mode !== "home") {
      return true;
    }

    if (
      this.focusedIndex <
      (this.commands?.length ?? 0) + (this.results?.length ?? 0)
    ) {
      this.focusedIndex++;
    } else {
      this.focusedIndex = 0;
    }

    this.focusElement();
    return false;
  }

  focusElement(): void {
    if (this.focusedIndex === 0) {
      this.aiSearchInput.nativeElement.focus();
    } else if (this.focusedIndex <= (this.commands?.length ?? 0)) {
      this.commandsElements
        .toArray()
        [this.focusedIndex - 1].nativeElement.focus();
    } else if (
      this.focusedIndex <=
      (this.commands?.length ?? 0) + (this.results?.length ?? 0)
    ) {
      this.resultsElements
        .toArray()
        [this.focusedIndex - this.commands.length - 1].nativeElement.focus();
    }
  }

  /**
   * This function filters a list of commands based on whether their keywords are
   * included in a given query question.
   */
  private _searchCommands(): void {
    this.commands = this.allCommands.filter((c) => {
      return c.keywords.some((k) => {
        return (
          this.queryQuestion.toLowerCase().includes(k) ||
          (k.includes(this.queryQuestion.toLowerCase()) &&
            this.queryQuestion.length > 2)
        );
      });
    });
  }

  /**
   * This function searches for results based on a query question using an orgDao
   * and displays the results or an error message.
   */
  private _search(): void {
    this.orgDao
      .ask(this.orgId, this.queryQuestion)
      .then((res) => {
        this.isAnswerOpen = true;
        this.hasBeenOpened = true; // for the animation and avoid style binding issues
        this.results = res;
      })
      .catch((err) => {
        console.error(err);
        this.hasError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public onCommandClick(command: any): void {
    command.command();
    this.handleCancel();
  }

  handleOpen(): void {
    (
      this.modalComponent.getModalRef().containerInstance as any
    ).focusTrap.destroy();
    this.aiSearchInput.nativeElement.focus();
  }

  handleCancel(): void {
    this.isOpen = false;
    this.isAnswerOpen = false;
    this.aiSearchInput.nativeElement.blur();
  }

  onResultClick(result: any): void {
    this.handleCancel();
    if (result.url) this.router.navigate(result.url);
  }
}
