/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { SessionService } from "services/auth.service";

import { SurveyLanguages } from "models/survey.dao.types";
import { TrackersService } from "services/trackers.service";
import { ConfigService } from "services/config.service";

@Component({
  selector: "survey-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
})
export class PreviewPanelComponent implements OnInit {
  @Input()
  channelId: string = null;
  @Input()
  surveyId: string = null;
  @Input()
  lang: SurveyLanguages = "en";

  @Output()
  onClose = new EventEmitter<Event>();

  @ViewChild("iframePreview")
  iframe: ElementRef;

  public iframePath: string = null;

  public mode: "desktop" | "mobile" = "desktop";

  constructor(
    public sessionService: SessionService,
    private configService: ConfigService,
    private trackersService: TrackersService,
  ) {}

  ngOnInit() {
    const params = [
      `channel_id=${this.channelId}`,
      `survey_id=${this.surveyId}`,
      `lang=${this.lang}`,
      // `visitor_id=${this.sessionService.session.id}`,
      // `visitor_firstname=${this.sessionService.session.firstname}`,
      // `visitor_lastname=${this.sessionService.session.lastname}`,
      `visitor_id=screeb-preview`,
      `visitor_firstname=Screeb`,
      `visitor_lastname=Preview`,
      // `visitor_email=${this.sessionService.session.email}`,
      `host=${this.configService.config.tagBypassEndpoint}`,
      `platform=${this.configService.config.platform}`,
    ];
    this.iframePath = `/preview.html?${params.join("&")}`;
  }

  public onReload() {
    const msg = {
      "screeb-preview": { type: "preview-reload" },
    };
    this.iframe?.nativeElement?.contentWindow.postMessage(
      JSON.parse(JSON.stringify(msg)),
      window.location.href,
    );

    this.trackersService
      .newEventTrackingBuilder("Survey preview reloaded")
      .withProps({
        channel_id: this.channelId,
        survey_id: this.surveyId,
        survey_type: "survey",
      })
      .build();
  }
}
