<ng-container>
  <div class="gridAppear" *ngIf="!error">
    <section
      nz-row
      [nzGutter]="[
        { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64 },
        { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64 }
      ]"
      class="quickstart-content"
    >
      <div nz-col nzXs="24" [nzXl]="tasks.installDone ? 24 : 10">
        <div class="iAppear bloc-hello">
          <span class="hello-message">
            Hi
            <span class="hello-message-name">
              {{ sessionService.session?.firstname }} </span
            >! 👋 <br />
            Welcome to Screeb! <br />This page will help you discover how our
            platform can help
            {{
              accountJobTitlesToLabel[
                sessionService.session?.flags?.job_title
              ] ?? accountJobTitlesToLabel["other"]
            }}
            like you!
          </span>
        </div>
      </div>
      <div nz-col nzXs="24" nzXl="14" *ngIf="!tasks.installDone">
        <div class="iAppear bloc-install-screeb">
          <img
            alt="Install Screeb"
            src="/assets/illustrations/quickstart-install-screeb.png"
          />
          <div class="install-screeb-title">
            👉 Install Screeb in your app now!
          </div>
          <div class="install-screeb-description">
            Install Screeb in less than 2 minutes.<br />
            That's
            <span
              >needed to analyse account's behaviour and display surveys!</span
            >
          </div>
          <button
            routerLink="/org/{{ org?.id }}/settings/install"
            title="Set up now"
            nz-button
            nzType="default"
            nzSize="large"
          >
            <screeb-icon size="sm" icon="dev-documentation"></screeb-icon>
            <span>Set up now</span>
          </button>
        </div>
      </div>
      <div nz-col nzXs="24">
        <expandable-box
          [(opened)]="tasks.presentationOpened"
          (openedChange)="presentationVideoOpenedChange($event)"
        >
          <h3 title>
            <quickstart-task
              indice="1"
              [completed]="tasks.presentationDone"
            ></quickstart-task>
            <span>
              See what you'll able to do in Screeb to
              <span class="bold">{{ accountGoalsToLabel[goal] }}</span>
            </span>
          </h3>
          <div
            nz-row
            [nzGutter]="[
              { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64 },
              16
            ]"
          >
            <div nz-col nzXs="24" nzXl="12">
              Screeb has been designed to help you with your Continuous Product
              Discovery, and especially when you want to
              <span class="bold">{{ accountGoalsToLabel[goal] }}</span
              >. <br /><br />
              Discover in 2 minutes how our platform will help you save time,
              decrease the number of support tickets, increase your conversion
              rate in key user journeys, your user satisfaction and your product
              adoption.
            </div>
            <div nz-col nzXs="24" nzXl="12">
              <div
                id="presentationVideo"
                #presentationVideo
                class="presentation-video"
              ></div>
            </div>
          </div>
        </expandable-box>
      </div>
    </section>

    <section
      nz-row
      [nzGutter]="[
        { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64 },
        { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64 }
      ]"
      class="quickstart-content"
    >
      <div nz-col nzXs="24" nzXl="14">
        <section
          nz-row
          [nzGutter]="[
            { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64 },
            { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64 }
          ]"
        >
          <div nz-col nzXs="24">
            <expandable-box [(opened)]="tasks.launchFirstSurveysOpened">
              <h3 title>
                <quickstart-task
                  indice="2"
                  [completed]="tasks.launchFirstSurveysDone"
                ></quickstart-task>
                <span class="title-flex">Launch your first in-app surveys</span>
                <a
                  *ngIf="'survey.create' | hasPermission"
                  class="add"
                  routerLink="/org/{{ org?.id }}/survey/create"
                  routerLinkActive="active"
                  nz-button
                  nzSize="large"
                  nzType="primary"
                >
                  <i nz-icon nzType="plus"></i>
                  Create new survey
                </a>
              </h3>

              <table class="launch-first-surveys-table">
                <thead>
                  <tr>
                    <th></th>
                    <th style="text-align: left">Goal</th>
                    <th style="min-width: 120px; width: 120px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let survey of surveyTemplates[goal]">
                    <td>
                      {{ survey.title }}
                    </td>
                    <td>
                      {{ accountGoalsToLabel[goal] }}
                    </td>
                    <td>
                      <a
                        *ngIf="'survey.create' | hasPermission"
                        nz-tooltip
                        routerLink="/org/{{ org?.id }}/survey/create"
                        [queryParams]="{
                          duplicateById: survey.id
                        }"
                      >
                        Edit & launch
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </expandable-box>
          </div>

          <div *ngIf="'org.member.create' | hasPermission" nz-col nzXs="24">
            <expandable-box [(opened)]="tasks.inviteTeamMembersOpened">
              <h3 title>
                <quickstart-task
                  indice="3"
                  [completed]="tasks.inviteTeamMembersDone"
                ></quickstart-task>
                <span class="title-flex">Invite your team mates to Screeb</span>
              </h3>

              <div *ngIf="tasks.inviteTeamMembersOpened">
                The more people in your team will use Screeb, the more efficient
                you will be! Invite your team mates, collaborate on surveys and
                reports, share your learnings and become better at Product
                Discovery together!
                <br /><br />
                <span class="bold">
                  In average, companies save 10,04 days of Product Discovery per
                  month when their whole product team is using Screeb!
                </span>

                <invite-team-members
                  scope="quickstart"
                  [loading]="invitationLoading"
                  (submitInvitations)="onSubmitInvitations($event)"
                ></invite-team-members>
              </div>
            </expandable-box>
          </div>

          <div nz-col nzXs="24" *ngIf="'integration.create' | hasPermission">
            <expandable-box [(opened)]="tasks.installSlackIntegrationOpened">
              <h3 title>
                <quickstart-task
                  [indice]="('org.member.create' | hasPermission) ? 4 : 3"
                  [completed]="tasks.installSlackIntegrationDone"
                ></quickstart-task>
                <span class="title-flex">Install our Slack Integration</span>
              </h3>

              <div class="bloc-install-slack">
                <img
                  alt="Install Slack"
                  src="/assets/illustrations/quickstart-install-slack.png"
                />
                <p>
                  Install our Slack integration and receive your responses and
                  charts right in your preferred channels.
                </p>
                <button
                  title="Install integration"
                  nz-button
                  nzType="default"
                  nzSize="large"
                  (click)="onInstallSlackIntegrationClick()"
                  [nzLoading]="installSlackIntegrationLoading"
                >
                  <screeb-icon size="sm" icon="live"></screeb-icon>
                  <span>Install integration</span>
                </button>
              </div>
            </expandable-box>
          </div>
        </section>
      </div>
      <div nz-col nzXs="24" nzXl="10">
        <section
          nz-row
          [nzGutter]="[
            { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64 },
            { xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64 }
          ]"
        >
          <div nz-col nzXs="24">
            <h3>Recommended readings</h3>

            <card-101-link
              title="How to get more responses to your in-app surveys"
              minuteRead="5"
              url="https://screeb.app/how-to-get-more-responses-to-your-in-app-surveys-the-4-levers-you-can-use-today/"
              color="var(--screeb-color-info-outline)"
            ></card-101-link>
            <card-101-link
              title="How to use events to display surveys "
              minuteRead="4"
              url="https://help.screeb.app/en/articles/5252769-how-to-use-your-users-events-to-choose-when-to-display-your-surveys"
              color="#E4F8F2"
            ></card-101-link>
            <card-101-link
              title="How to launch many surveys without spamming your users"
              minuteRead="5"
              url="https://help.screeb.app/en/articles/5543922-how-to-limit-the-number-of-displays-and-responses-of-your-surveys"
              color="var(--screeb-color-white)"
            ></card-101-link>
          </div>

          <div nz-col nzXs="24" class="bloc-not-ready">
            <h3>Not ready to start?</h3>

            <section nz-row [nzGutter]="[16, 16]">
              <div nz-col nzXs="12">
                <button
                  routerLink="/org/{{ org?.id }}/onboarding/book-demo"
                  title="Book a call with us"
                  nz-button
                  nzType="default"
                  nzSize="large"
                >
                  <screeb-icon size="sm" icon="talk-to-a-human"></screeb-icon>
                  <span>Book a call with us</span>
                </button>
              </div>
              <div nz-col nzXs="12">
                <a
                  href="https://developers.screeb.app"
                  target="_blank"
                  rel="noopener"
                  title="Read developer doc."
                  nz-button
                  nzType="default"
                  nzSize="large"
                >
                  <screeb-icon size="sm" icon="dev-documentation"></screeb-icon>
                  <span>Read developer doc.</span>
                </a>
              </div>
              <div nz-col nzXs="12">
                <intercom>
                  <button
                    title="ask-us-a-question"
                    nz-button
                    nzType="default"
                    nzSize="large"
                  >
                    <screeb-icon size="sm" icon="survey"></screeb-icon>
                    <span>Chat with our team</span>
                  </button>
                </intercom>
              </div>
              <div nz-col nzXs="12">
                <button
                  title="Work with partner"
                  nz-button
                  nzType="default"
                  nzSize="large"
                  [disabled]="true"
                >
                  <screeb-icon size="sm" icon="newest-user"></screeb-icon>
                  <span>Work with partner</span>
                </button>
              </div>
            </section>
          </div>
        </section>
      </div>
    </section>
  </div>
</ng-container>
