import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";

import { Org } from "models/org.model";
import { NotificationHelper } from "helpers/notification.helper";
import { SuperOrg } from "models/super-org.model";
import { formatNumber } from "utils/number";
import { SuperOrgDao } from "models/super-org.dao";
import { Account } from "models/account.model";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";
import { SessionService } from "services/auth.service";

@Component({
  selector: "workspace-create-modal",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class CreateOrgModalComponent implements OnInit, OnChanges, OnDestroy {
  private obs: any = null;

  public loading: boolean = false;
  public errors: any = null;

  public validateForm: FormGroup = null;
  public assignForm: FormGroup = null;

  @Input() superOrg: SuperOrg = null;
  @Input() workspaces: Org[] = [];
  @Input() workspace: Org = null;
  @Input() isOpen: boolean = false;
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter();

  public formatNumber = formatNumber;
  public tagOptions: string[] = [];
  public remainingMTU = 0;

  public users: Account[] = [];
  public usedUsers: string[][] = [];

  public minMtu = 100;
  public maxMTU = 500;

  public assignUsersStep: boolean = false;
  public isAssignEmpty: boolean = true;
  public isLoading: boolean = false;

  public adminRole: NzSelectOptionInterface[] = [
    { label: "Admin", value: "admin" },
  ];

  public workspacesRoles: NzSelectOptionInterface[] = [
    { label: "Editor", value: "editor" },
    { label: "Analyst", value: "analyst" },
  ];

  constructor(
    private superOrgDao: SuperOrgDao,
    private notificationHelper: NotificationHelper,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges() {
    this.initForm();
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }

  private initForm() {
    this.assignUsersStep = false;
    this.isAssignEmpty = true;
    this.loading = false;
    this.maxMTU =
      this.superOrg?.entitlements?.max_mtu -
      this.minMtu * this.workspaces.length;
    if (this.workspace !== null) {
      this.maxMTU += this.minMtu;
    }
    this.validateForm = this.formBuilder.group({
      name: [
        this.workspace?.name,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(128),
        ],
      ],
      tags: [this.workspace?.tags, []],
      max_mtu: [
        this.workspace?.entitlements?.max_mtu,
        [
          Validators.required,
          Validators.min(100),
          Validators.max(
            this.superOrg?.entitlements?.max_mtu -
              this.minMtu * (this.workspaces.length - (this.workspace ? 1 : 0)),
          ),
        ],
      ],
    });

    this.assignForm = this.formBuilder.group({
      access: this.formBuilder.array([
        this.formBuilder.group({
          users: [null, [Validators.required]],
          role: ["analyst", [Validators.required]],
          is_admin: [false, [Validators.required]],
        }),
      ]),
    });

    this.tagOptions = [
      ...new Set(
        this.workspaces
          .map((e) => e.tags)
          .flat()
          .filter((f) => f?.length),
      ),
    ];
    this.onInputChange(this.workspace?.entitlements?.max_mtu ?? 0);

    if (this.assignUsersStep) {
      this.initAssignUsers();
    }
  }

  handleCancel(): void {
    this.isOpen = false;
    this.modalOpenChange.emit(this.assignUsersStep);
    this.loading = false;
  }

  public onInputChange(mtu: number) {
    let newMtu = mtu;

    // Ensure to do not exceed the max super orgMTU
    if (mtu > this.maxMTU) {
      newMtu = this.maxMTU;
      this.validateForm.patchValue(
        { max_mtu: this.maxMTU },
        { emitEvent: false, onlySelf: true },
      );
    } else if (mtu < this.minMtu) {
      newMtu = this.minMtu;
      this.validateForm.patchValue(
        { max_mtu: this.minMtu },
        { emitEvent: false, onlySelf: true },
      );
    }

    this.remainingMTU =
      this.superOrg?.entitlements?.max_mtu -
      this.workspaces.reduce(
        (acc, org) => acc + org?.entitlements?.max_mtu,
        0,
      ) -
      newMtu;
    if (this.workspace !== null) {
      this.remainingMTU += this.workspace?.entitlements?.max_mtu;
    }
  }

  public getUsedUsersWithoutIndex(idx: number): string[] {
    return this.usedUsers.filter((_, i) => i !== idx).flat();
  }

  public get accessFormArray(): FormArray {
    return this.assignForm.get("access") as FormArray;
  }

  public onUserChange(idx: number, ids: string[]): void {
    const formGroup = this.accessFormArray.controls[idx] as FormGroup;
    this.usedUsers[idx] = ids;
    // Check if the last added user is admin
    if (!ids?.length) {
      const roleValue = formGroup.controls["role"].value;
      if (roleValue === "admin") {
        formGroup.controls["role"].patchValue("analyst", {
          emitEvent: false,
          onlySelf: true,
        });
      }
      formGroup.controls["is_admin"].patchValue(false, {
        emitEvent: false,
        onlySelf: true,
      });
      return;
    }
    const id = ids[ids.length - 1];
    const user = this.users.find((u) => u.id === id);
    if (!user) return;

    if (user.role === "admin") {
      const haveOnlyAdmin = ids
        .map((id) => {
          const user = this.users.find((u) => u.id === id);
          return user.role === "admin";
        })
        .every((v) => v);

      // Check if first entry have already some value
      if (haveOnlyAdmin) {
        formGroup.controls["role"].patchValue("admin", {
          emitEvent: false,
          onlySelf: true,
        });
        formGroup.controls["is_admin"].patchValue(true, {
          emitEvent: false,
          onlySelf: true,
        });
      }
    } else {
      const roleValue = formGroup.controls["role"].value;
      if (roleValue === "admin") {
        formGroup.controls["role"].patchValue("analyst", {
          emitEvent: false,
          onlySelf: true,
        });
      }
      formGroup.controls["is_admin"].patchValue(false, {
        emitEvent: false,
        onlySelf: true,
      });
    }
  }

  public addNewAccess() {
    this.accessFormArray.push(
      this.formBuilder.group({
        users: [null, [Validators.required]],
        role: ["analyst", [Validators.required]],
        is_admin: [false, [Validators.required]],
      }),
    );
  }

  public removeAccess(index: number) {
    this.accessFormArray.removeAt(index);
  }

  public isFormValid(): boolean {
    if (this.assignUsersStep) {
      if (this.assignForm.valid) {
        this.isAssignEmpty = false;
        return true;
      }
      const value = this.assignForm.value;
      value.access = value.access.filter((v) => v.users?.length);
      this.isAssignEmpty = value.access.length === 0;
      return true;
    }
    return this.validateForm.valid;
  }

  public initAssignUsers() {
    // Fetch users
    this.isLoading = true;
    this.superOrgDao
      .getAllSuperOrgAccounts(this.superOrg.id)
      .then((accounts) => {
        this.users = accounts.filter(
          (a) => a.id !== this.sessionService.session.id,
        );
        this.usedUsers = [];
        this.isLoading = false;
      });
  }

  public onSubmit() {
    if (this.workspace !== null && this.assignUsersStep) {
      for (const i in this.assignForm.controls) {
        this.assignForm.controls[i].markAsDirty();
        this.assignForm.controls[i].updateValueAndValidity();

        for (const j in this.accessFormArray.controls) {
          const formGroup = this.accessFormArray.controls[j] as FormGroup;
          for (const k in formGroup.controls) {
            formGroup.controls[k].markAsDirty();
            formGroup.controls[k].updateValueAndValidity();
          }
        }
      }

      if (!this.isFormValid()) return;
    } else {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }

      if (!this.validateForm.valid) return;
    }

    this.loading = true;
    this.errors = null;

    const value = this.validateForm.value;

    if (this.workspace !== null && this.assignUsersStep) {
      if (this.isAssignEmpty) {
        this.modalOpenChange.emit(true);
        return;
      }
      this.superOrgDao
        .assignUsersToWorkspace(
          this.superOrg.id,
          this.workspace.id,
          this.assignForm.value.access,
        )
        .then((_: Org) => {
          this.notificationHelper.trigger("Users assigned", null, "success");
          this.errors = null;
          this.isOpen = false;
          this.assignUsersStep = false;
          this.workspace = null;
          this.modalOpenChange.emit(true);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationHelper.trigger(
            err?.error?.message ?? "Failed to assign users",
            null,
            "error",
          );
          this.errors = err.error;
          console.error(err.error);
        })
        .finally(() => {
          this.loading = false;
        });
      return;
    }

    if (this.workspace !== null) {
      this.superOrgDao
        .updateWorkspace(
          this.superOrg.id,
          this.workspace.id,
          value.name,
          value.max_mtu,
          value.tags,
        )
        .then((_: Org) => {
          this.notificationHelper.trigger("Workspace Updated", null, "success");
          this.errors = null;
          this.isOpen = false;
          this.workspace = null;
          this.modalOpenChange.emit(true);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationHelper.trigger(
            err?.error?.message ?? "Failed to update workspace",
            null,
            "error",
          );
          this.errors = err.error;
          console.error(err.error);
        })
        .then(() => {
          this.loading = false;
        });
      return;
    }

    this.superOrgDao
      .createWorkspace(this.superOrg.id, value.name, value.max_mtu, value.tags)
      .then((newWorkspace: Org) => {
        this.notificationHelper.trigger("Workspace Created", null, "success");
        this.errors = null;
        this.assignUsersStep = true;
        this.workspace = newWorkspace;
        this.initAssignUsers();
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to create workspace",
          null,
          "error",
        );
        this.errors = err.error;
        console.error(err.error);
      })
      .then(() => {
        this.loading = false;
      });
  }
}
