<div id="release-infobox" style="visibility: hidden">
  <ul class="release-list">
    <li *ngFor="let release of infoboxContent">
      <div class="infobox-title">{{ release.name }}</div>
      <div class="infobox-description">
        <sanitized-message-component
          [content]="release.description"
          [options]="{
            links: true,
            styling: true,
            emojiTranscoder: true,
            CR: true
          }"
        >
        </sanitized-message-component>
      </div>
      <div class="infobox-tags">
        <nz-tag nzColor="#E4F8F2">{{ release.version }}</nz-tag>
        <org-account-avatar
          *ngIf="!!release.author_id"
          class="profile-picture"
          [account]="getAuthorProfilePicture(release.author_id)"
          [noTooltip]="true"
          [size]="'small'"
        />
        <nz-tag
          nzColor="var(--screeb-color-info-outline)"
          [class]="!!release.author_id ? 'with-profile-picture' : ''"
          >{{ getReleasedBy(release.author_id) }}</nz-tag
        >
      </div>
      <button
        nz-button
        nzType="default"
        class="infobox-cta"
        (click)="onReleaseReadMore(release.id)"
      >
        Read More
      </button>
    </li>
  </ul>
</div>
<div class="overlay" id="release-overlay">
  <ng-container *ngFor="let point of groupedReleases; let idx = index">
    <div
      class="release-marker-container"
      [ngStyle]="{ left: point.left + 'px' }"
    >
      <div class="release-tooltip-container">
        <ng-container *ngIf="groupedReleases[idx].releases.length === 1">
          <org-account-avatar
            *ngIf="!!groupedReleases[idx].releases[0].author_id"
            class="profile-picture"
            [account]="
              getAuthorProfilePicture(
                groupedReleases[idx].releases[0].author_id
              )
            "
            [noTooltip]="true"
            [size]="'small'"
          />
          <div
            class="release-tooltip"
            [class]="{
              'with-profile-picture':
                !!groupedReleases[idx].releases[0].author_id
            }"
          >
            {{ groupedReleases[idx].releases[0].name }}
          </div>
        </ng-container>
        <ng-container *ngIf="groupedReleases[idx].releases.length > 1">
          <div class="release-tooltip">
            {{ groupedReleases[idx].releases.length }} Releases
          </div>
        </ng-container>
      </div>
      <div
        class="release-marker"
        (click)="onMarkerMouseEnter(point.left, idx)"
      ></div>
    </div>
  </ng-container>
</div>
