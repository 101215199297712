import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import { SurveyDistribution } from "models/survey-distribution.model";
import {
  Survey,
  SurveyIntegrations,
  SurveyInteraction,
  SurveyType,
} from "models/survey.model";

import { TagSettings } from "models/tag-settings.types";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "tag-settings-with-preview",
  templateUrl: "./tag-settings-with-preview.component.html",
  styleUrls: ["./tag-settings-with-preview.component.scss"],
})
export class TagSettingsWithPreviewPageComponent implements OnInit {
  @Input() public tabSelectedIndex = 0;
  public typeSelected?: SurveyType;
  public selectedTypeIndex = 0;
  public typeOrders: SurveyType[] = ["survey", "message"];
  public typesNames: Record<SurveyType, string> = {
    survey: "Survey",
    message: "Message",
  };

  @Input() public org: Org = null;
  @Input() public survey: Survey = null;
  @Input() public surveyDistribution: SurveyDistribution = null;
  @Input() public defaultTagSettings: TagSettings = null;
  @Input() public tagSettings: TagSettings = null;
  @Input() public orgIntegrations: Integration[] = null;
  @Input() public surveyIntegrations: SurveyIntegrations = null;
  @Input() public scope: "org" | "survey" | "distribution" = "org";
  @Input() public additionalSettingsRef: TemplateRef<any> | null = null;
  @Input() public translationEnabled: boolean = true;
  @Input() public interactionType: SurveyInteraction;

  @Output() public specificDesignSwitchChange = new EventEmitter<boolean>();
  @Output() public specificBehaviourSwitchChange = new EventEmitter<boolean>();
  @Output() public specificTranslationEnabledSwitchChange =
    new EventEmitter<boolean>();

  @Output() public interactionTypeChange =
    new EventEmitter<SurveyInteraction>();

  public mode: "mobile" | "desktop" = "desktop";

  constructor(
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    // Switch to "link sharing" tab by default for "hosted-page" channel type
    if (
      this.scope === "distribution" &&
      this.surveyDistribution.type === "hosted-page"
    ) {
      this.tabSelectedIndex = 2;
    }

    if (this.survey) {
      this.typeSelected = this.survey.type;
      this.selectedTypeIndex = this.typeOrders.indexOf(this.survey.type);
    } else {
      this.typeSelected = "survey"; // Default to survey
    }
  }

  shouldForceMobile() {
    return (
      this.scope === "distribution" &&
      (this.surveyDistribution.type === "android" ||
        this.surveyDistribution.type === "ios")
    );
  }

  public onTypeTabChange(index: number) {
    this.selectedTypeIndex = index;
    this.typeSelected = this.typeOrders[index];
  }

  getFormat() {
    if (this.interactionType === "in_page") {
      return "in_page";
    }

    if (this.tagSettings) {
      switch (this.tabSelectedIndex) {
        case 0:
          return "conversational";
        case 1:
          return "cards";
        case 2:
          return "link";
        case 3:
          return "in_page";
      }
    } else {
      switch (this.defaultTagSettings.format) {
        case "cards":
          return "cards";
        case "conversationnal":
          return "conversational";
      }
    }
    return "conversational";
  }
}
