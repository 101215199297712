import {
  AddonKey,
  billingCycleDuration,
  OrgBilling,
  PlanType,
} from "models/org_billing.model";
import { formatNumber } from "utils/number";

export const billingCycleDurationLabels: {
  [key in billingCycleDuration]: string;
} = {
  // day: "Daily",
  // week: "Weekly",
  month: "Monthly",
  year: "Yearly",
  // once: "Once",
};

export type PlanTypeInfo = {
  type: PlanType;
  name: string;
  startPrice?: number;
  upgradeCTA?: string;
  subtitle: string;
  features: string[];
  currentFeatures: (string | ((orgBilling: OrgBilling) => string))[];
};

export const planTypeInfos: { [key in PlanType]: PlanTypeInfo } = {
  free: {
    type: "free",
    name: "Free Plan",
    startPrice: 0,
    upgradeCTA: null,
    subtitle:
      "Ideal for Startups to measure NPS on auto-pilot every month and kickstart your user research.",
    features: [],
    currentFeatures: [
      (orgBilling: OrgBilling) => {
        if (orgBilling.expected_metadata.mtu_scheme === "unlimited") {
          return "Unlimited MTU per month";
        }
        return `Up to ${formatNumber(
          orgBilling.expected_metadata.max_mtu,
          "number",
        )} MTU per month`;
      },
      "1 Team Member Access",
      "2 Surveys & Unlimited Responses",
      // "1 User Segment",
      "1 Funnel Report",
      "Limited Access to our Integrations",
    ],
  },

  scale: {
    type: "scale",
    name: "Scale Plan",
    startPrice: 74,
    upgradeCTA: "🔥 Upgrade Now and Unlock These Features",
    subtitle:
      "Ideal for product teams who want to to understand how to make a product that customers want and to ensure that they are improving it over time.",
    features: [
      "Up to 1M MTU",
      "Unlimited Team Member Access",
      "Unlimited Surveys & Responses",
      // "Unlimited User Segments & Segment Analytics",
      "Unlimited Funnel Reports",
      "Full Access to Integrations",
      "Multi-Lingual Surveys",
      "Survey Capping",
      "Dedicated Support",
    ],
    currentFeatures: [
      (orgBilling: OrgBilling) => {
        if (orgBilling.expected_metadata.mtu_scheme === "unlimited") {
          return "Unlimited MTU";
        }
        return `${formatNumber(
          orgBilling.expected_metadata.max_mtu,
          "number",
        )} MTU`;
      },
      (orgBilling: OrgBilling) => {
        if (orgBilling.expected_metadata.max_workspaces === -1) {
          return "Unlimited Workspaces";
        }
        return `Up to ${orgBilling.expected_metadata.max_workspaces} Workspaces`;
      },
      "Unlimited Team Member Access",
      "Unlimited Surveys & Responses",
      // "Unlimited User Segments & Segment Analytics",
      "Unlimited Funnel Reports",
      "Full Access to Integrations",
      "Livechat Support",
      "Up to 1 year data retention",
    ],
  },

  custom: {
    type: "custom",
    name: "Custom Plan",
    startPrice: null,
    upgradeCTA: "🔥 Talk to our Team to Upgrade to Custom",
    subtitle:
      "Ideal for product teams who want to to understand how to make a product that customers want and to ensure that they are improving it over time.",
    features: [
      "Custom number of Workspaces",
      "Advanced Survey Graphical Customisation",
      "Custom data retention duration",
      "Dedicated training for your team members",
      "Dedicated Customer Success Manager",
      "Ability to pay via wire transfer with custom contract",
    ],
    currentFeatures: [
      (orgBilling: OrgBilling) => {
        if (orgBilling.expected_metadata.mtu_scheme === "unlimited") {
          return "Unlimited MTU";
        }
        return `${formatNumber(
          orgBilling.expected_metadata.max_mtu,
          "number",
        )} MTU`;
      },
      (orgBilling: OrgBilling) => {
        if (orgBilling.expected_metadata.max_workspaces === -1) {
          return "Unlimited Workspaces";
        }
        return `Up to ${orgBilling.expected_metadata.max_workspaces} Workspaces`;
      },
      "Unlimited Team Member Access",
      "Unlimited Surveys & Responses",
      // "Unlimited User Segments & Segment Analytics",
      "Unlimited Funnel Reports",
      "Full Access to Integrations",
      "Advanced Survey Customisation",
      "Custom data retention duration",
      "Dedicated Customer Success Manager",
    ],
  },
};

export const recommendedPlanTypeUpgrade: {
  [key in PlanType]: PlanType | null;
} = {
  free: "scale",
  scale: "custom",
  custom: null,
};

export type AddonInfo = { name: string; img: string };

export type displayedAddonKeys = AddonKey | "additional-mtu";

export const addonInfos: { [key in displayedAddonKeys]: AddonInfo } = {
  "additional-workspaces": {
    name: "Additional Workspaces",
    img: "workspace.png",
  },
  "white-label": {
    name: "Remove 'Powered by Screeb'",
    img: "powered-by-screeb.png",
  },
  "additional-mtu": {
    name: "Add more MTUs",
    img: "additional-mtu.png",
  },
  // "additional-events": {
  //   name: "Collect more Events and Properties",
  //   img: "powered-by-screeb.png",
  // },
  // "session-recording": {
  //   name: "Activate Session Recording",
  //   img: "workspace.png",
  // },
  "response-translation": {
    name: "Response Translation",
    img: "response-translation.png",
  },
  "audio-video-survey": {
    name: "Audio / Video Format",
    img: "audio-video.png",
  },
};
