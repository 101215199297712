<div class="menu-left campaign-edit">
  <div
    class="survey-title"
    nz-tooltip
    [nzTooltipTitle]="uiService.currentSurvey.title"
  >
    <span>{{ uiService.currentSurvey.title }}</span>
  </div>
</div>
<div class="spacer"></div>
<steps-component
  [steps]="steps"
  [currentStep]="currentStepIdx"
  (stepClicked)="onStepClicked($event)"
/>
<div class="spacer"></div>
<div class="menu-right" id="bot-builder-actions-location">
  <!-- this is a fucking anti-pattern, but osef ;) -->
  <ng-container *ngIf="headerService.surveyPageActionsRef">
    <ng-container
      [ngTemplateOutlet]="headerService.surveyPageActionsRef"
    ></ng-container>
  </ng-container>
</div>
