import { Component, Input } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";
import { ChannelClientVersion } from "models/channel-screen.model";

@Component({
  selector: "rule-app-version",
  templateUrl: "./rule-app-version.component.html",
  styleUrls: ["./rule-app-version.component.scss"],
})
export class TargetingRuleAppVersionComponent {
  @Input() public rule: SurveyTargetingRule = null;
  @Input() public channelClientVersions: ChannelClientVersion[] = [];

  constructor() {}
}
