import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { SurveyTargetingRule } from "./survey-targeting-rule.model";
import { SurveyDistribution } from "./survey-distribution.model";

@Injectable()
export class SurveyDistributionDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getAllBySurveyId(
    orgId: string,
    surveyId: string,
  ): Promise<SurveyDistribution[]> {
    return this.screebApiHelper
      .get<SurveyDistribution[]>(
        `/org/${orgId}/survey/${surveyId}/distribution`,
      )
      .toPromise()
      .then((data: object[]) => {
        return data.map((c: object) => new SurveyDistribution().fromJson(c));
      });
  }
  public getAllBySurveyIdAndChannelId(
    orgId: string,
    surveyId: string,
    channelId: string,
  ): Promise<SurveyDistribution[]> {
    return this.screebApiHelper
      .get<SurveyDistribution[]>(
        `/org/${orgId}/survey/${surveyId}/distribution?channel_id=${channelId}`,
      )
      .toPromise()
      .then((data: object[]) => {
        return data.map((c: object) => new SurveyDistribution().fromJson(c));
      });
  }

  public _update(
    orgId: string,
    surveyId: string,
    distributionId: string,
    enabled: boolean | null,
    targeting_rules: SurveyTargetingRule[] | null,
    selector_css: string | null,
  ) {
    return this.screebApiHelper
      .patch<SurveyDistribution>(
        `/org/${orgId}/survey/${surveyId}/distribution/${distributionId}`,
        { enabled, targeting_rules, selectors_css: new Array(selector_css) },
      )
      .toPromise()
      .then((data: SurveyDistribution) => {
        return new SurveyDistribution().fromJson(data);
      });
  }
  public updateStatus(
    orgId: string,
    surveyId: string,
    distributionId: string,
    enabled: boolean,
    selector_css: string,
  ) {
    return this._update(
      orgId,
      surveyId,
      distributionId,
      enabled,
      null,
      selector_css,
    );
  }
  public updateTargetingRules(
    orgId: string,
    surveyId: string,
    distributionId: string,
    targeting_rules: SurveyTargetingRule[],
    selector_css: string,
  ) {
    return this._update(
      orgId,
      surveyId,
      distributionId,
      null,
      targeting_rules,
      selector_css,
    );
  }
}
