<div>
  <h1>Automatic Tagging</h1>

  <section>
    <h3>Configure tags to automatically add them to answers of your surveys</h3>
  </section>
  <section>
    <div>
      <h3>Automatic tagging</h3>
      <div class="tags-section">
        <div class="tags" *ngFor="let groupTag of orgAnswerTags">
          <page-settings-tagging-edit
            [groupTag]="groupTag"
            [allOrgTags]="allOrgTags"
            (removeGroupTag)="remove(groupTag)"
            (changeTag)="onChange($event)"
          />
        </div>
        <div *ngIf="orgAnswerTags.length === 0">
          <p>
            No automatic tagging configured yet. Add a new one by clicking on
            the "Add" button below.
          </p>
        </div>
        <div
          class="save"
          [ngClass]="{ invisible: !('tagging.update' | hasPermission) }"
        >
          <button nz-button nzType="primary" nzSize="large" (click)="add()">
            Add
          </button>
          <button
            [disabled]="isSave"
            nz-button
            nzType="primary"
            nzSize="large"
            (click)="save()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <div>
      <h3>Smart tagging</h3>
      <div class="smart-tag-container">
        <p>
          Our AI will analyse the actual content of feedback and apply tags
          based on meaning and not only keywords
        </p>
        <tag-input
          placeHolder="smart-tags"
          size="large"
          [options]="allOrgTagsOptions"
          [ngModel]="orgSmartTags"
          (ngModelChange)="orgSmartTags = $event; isSmartSave = false"
        >
        </tag-input>
        <div
          class="save"
          [ngClass]="{ invisible: !('tagging.update' | hasPermission) }"
        >
          <button
            [disabled]="isSmartSave"
            nz-button
            nzType="primary"
            nzSize="large"
            (click)="saveSmartTagging()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
