<div nz-row [nzGutter]="[0, 0]">
  <div nz-col [nzSpan]="24">
    <h3>How to set up a push notification</h3>
    <p>
      Create a new campaign in your notification service admin (ex: Firebase)
      and prepare the content of the notification : title, body, target and
      scheduling.
    </p>
    <p>
      To configure the payload, add a custom data (in the "Additional options"
      section for Firebase). For example, you can specify a key value like this:
    </p>
    <div class="installation-code-container">
      <code class="installation-code" [highlight]="code"> </code>
      <button
        class="copy-button"
        nz-button
        nzSize="medium"
        nzType="primary"
        (click)="clipboardCopy(code)"
      >
        Copy
      </button>
    </div>
    <p>Then, launch the campaign.</p>

    <p>
      For complete documentation,
      <a [href]="getDocURL()" target="_blank"> Read our doc here </a>
    </p>
  </div>
</div>
