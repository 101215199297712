/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component } from "@angular/core";

import { ENV } from "environment";

@Component({
  selector: "billing-testing-payment-method-helper",
  template: `
    <h4>Fake credit cards:</h4>
    <ul>
      <li>- ✅ Succeeds + no 3DS required: 4242 4242 4242 4242</li>
      <li>- ✅ Requires 3DS first time: 4000 0025 0000 3155</li>
      <li>- ☢️ Requires 3DS every time: 4000 0027 6000 3184</li>
      <li>- 🚨 Succeeds but insufficient_funds: 4000 0082 6000 3178</li>
      <li>- 🚨 Declines during setup: 4000 0000 0000 9995</li>
      <li>
        <a href="https://stripe.com/docs/testing" target="_blank"
          >More cards
        </a>
      </li>
    </ul>

    <br /><br />

    <h4>Fake SEPA accounts:</h4>
    <ul>
      <li>
        - ✅ "processing" then "succeeded" (instant):
        FR1420041010050500013M02606
      </li>
      <li>
        - ✅ "processing" then "succeeded" (3min): FR3020041010050500013M02609
      </li>
      <li>
        - ☢️ "processing" then "requires_payment_method" (instant):
        FR8420041010050500013M02607
      </li>
      <li>
        - ☢️ "processing" then "requires_payment_method" (3min):
        FR7920041010050500013M02600
      </li>
      <li>
        - 🚨 "processing" then "succeeded" then dispute:
        FR5720041010050500013M02608
      </li>
    </ul>
  `,
  styles: [],
})
export class SettingsBillingTestingPaymentMethodHelperComponent {
  constructor() {}

  public get isDevMode(): boolean {
    return ENV["ENV"] === "dev";
  }
}
