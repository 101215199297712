<big-checkbox
  class="big-checkbox"
  label="Via Slack"
  [content]="slackBigCheckbox"
  [(checked)]="enabled"
  (checkedChange)="onEnabledChange($event)"
  [disabled]="!quotaOk"
  iconUrl="/assets/icons/integrations/slack-icon.svg"
  name="slackEnabled"
  ngDefaultControl
  collapseWhenNotChecked
>
  <ng-template #slackBigCheckbox>
    <div class="slack-tip" *ngIf="integrationSlackStatus === 'not_installed'">
      <span>No Slack integration installed!<br />Go to </span>
      <a routerLink="/org/{{ org.id }}/integrations"> integrations </a>
      <span>to install one.</span>
    </div>

    <div
      class="slack-tip"
      *ngIf="
        integrationSlackStatus === 'error' ||
        integrationSlackStatus === 'not_authed'
      "
    >
      <p class="error">Something went wrong with Slack integration!</p>
      <p>
        Go to your
        <a routerLink="/org/{{ org.id }}/integrations/slack">
          Slack integration
        </a>
        to check your connection.
      </p>
    </div>

    <div
      *ngIf="
        integrationSlackStatus === 'loading' || integrationSlackStatus === 'ok'
      "
    >
      <label>Share with</label>
      <div>
        <nz-select
          nzShowSearch
          nzPlaceHolder="Select a channel"
          [nzLoading]="integrationSlackStatus === 'loading'"
          [nzOptions]="channels"
          [(ngModel)]="slackChannel"
          (ngModelChange)="slackChannelChange.emit($event)"
          nzSize="large"
          class="slack-channels-select"
        >
        </nz-select>
      </div>
    </div>

    <div
      class="error"
      *ngIf="
        integrationSlackStatus === 'ok' &&
        !isSlackValid() &&
        slackChannel?.length > 0
      "
    >
      This channel is not valid.
    </div>
  </ng-template>
</big-checkbox>
