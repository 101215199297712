import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { SuperOrg } from "models/super-org.model";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import { SurveyDao } from "models/survey.dao";
import { AuthService, SessionService } from "services/auth.service";
import { ConfigService } from "services/config.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { TrackersService } from "services/trackers.service";
import { AppTheme, UIService } from "services/ui.service";

@Component({
  selector: "layout-back-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class LayoutBackSidebarComponent implements OnInit, OnDestroy {
  @Input()
  public canCollapse: boolean;
  @Input()
  public isMenuCollapsed: boolean;
  @Input()
  public isSuperOrgSidebar: boolean = false;

  @Output()
  public collapseMenu: EventEmitter<any> = new EventEmitter();

  @ViewChild("renameInput") renameInput: ElementRef;

  public superOrgPickerOpened: boolean = false;
  public orgPickerOpened: boolean = false;
  public currentSurveyNameEditing: string = null;

  public superOrgPickerSearch: string = "";
  public orgPickerSearch: string = "";

  private obsEvents: any = null;

  private lastProfilePicture = "";

  public bottomMenuPopoverVisible = false;

  public filteredOrgs: SuperOrg[] = [];
  public filteredWorkspaces: Org[] = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    public sessionService: SessionService,
    public notificationHelper: NotificationHelper,
    public uiService: UIService,
    public surveyDao: SurveyDao,
    public surveyDistributionDao: SurveyDistributionDao,
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
    public permissionsService: PermissionsService,
    public configService: ConfigService,
    private trackersService: TrackersService,
  ) {}

  ngOnInit() {
    this.filteredOrgs = this.uiService.superOrgs;
    this.filteredWorkspaces = this.uiService.orgs;
    this.obsEvents = this.router.events.subscribe(() => {
      if (
        this.sessionService.session?.profile_picture !== this.lastProfilePicture
      ) {
        this.lastProfilePicture = this.sessionService.session?.profile_picture;
      }
    });
  }

  ngOnDestroy() {
    if (this.obsEvents) this.obsEvents.unsubscribe();
  }

  public logout() {
    this.authService.logout();
  }

  public onClickMenuCollapse() {
    this.collapseMenu.emit();
  }

  public getFirstWorkspaceURL(superOrgID: string) {
    const firstWorkspace = this.uiService.orgs.find(
      (org) => org.super_org_id === superOrgID,
    );

    if (!firstWorkspace) {
      return `/overview/${superOrgID}`;
    }
    return `/org/${firstWorkspace?.id}`;
  }

  public onFeedback() {
    this.trackersService.screebSurveyStart(
      "8fc985c8-da03-4bae-8fab-35903edcf843",
    );
    event.preventDefault();
  }

  public scheduleDemo() {
    window["Calendly"].initPopupWidget({
      url: "https://meetings.hubspot.com/simon-robic/en-inapp-meeting",
    });
    return false;
  }

  public closeBottomMenu() {
    this.bottomMenuPopoverVisible = false;
  }

  public toggleTheme() {
    this.uiService.setTheme(
      this.uiService.theme === AppTheme.LIGHT ? AppTheme.DARK : AppTheme.LIGHT,
    );
  }

  public onSuperOrgSearchChange(value: string) {
    this.superOrgPickerSearch = value;
    this.filteredOrgs = this.uiService.superOrgs.filter((org) => {
      return (
        org.name
          .toLowerCase()
          .indexOf(this.superOrgPickerSearch.toLowerCase()) !== -1
      );
    });
  }

  public onOrgSearchChange(value: string) {
    this.orgPickerSearch = value;
    this.filteredWorkspaces = this.uiService.orgs.filter((org) => {
      return (
        org.name.toLowerCase().indexOf(this.orgPickerSearch.toLowerCase()) !==
        -1
      );
    });
  }

  public onOrgSelected(org: Org) {
    this.orgPickerOpened = false;

    const scopedRedirect = {
      survey: "/org/" + org.id + "/survey/list",
      message: "/org/" + org.id + "/message/list",
      "people/segment": "/org/" + org.id + "/people/segment",
      "people/respondent": "/org/" + org.id + "/people/respondent",
      funnel: "/org/" + org.id + "/funnel/list",
      integrations: "/org/" + org.id + "/integrations",
    };

    const hasRedirected = Object.entries(scopedRedirect).some(
      ([key, value]) => {
        if (this.uiService.currentPageRouteUrl.includes(key)) {
          this.router.navigateByUrl(value);
          return true;
        }

        return false;
      },
    );

    if (hasRedirected) return;

    const newUrl = this.uiService.currentPageRouteUrl.includes(
      "/org/" + this.uiService.currentOrgId,
    )
      ? this.uiService.currentPageRouteUrl.replace(
          this.uiService.currentOrgId,
          org.id,
        )
      : "/org/" + org.id;

    this.router.navigateByUrl(newUrl);
  }
}
