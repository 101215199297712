import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { AuthService } from "services/auth.service";
import { NotificationHelper } from "helpers/notification.helper";

import { PageComponentInterface } from "components/PageComponentInterface";
import { RoutingService } from "services/routing.service";

@Component({
  selector: "page-auth-callback-grant",
  templateUrl: "./grant-callback.component.html",
  styleUrls: ["../../common.scss", "./grant-callback.component.scss"],
})
export class GrantCallbackComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Login to Screeb 🚀";
  public name = "Grant Callback Login";

  private sub: any = null;
  public loading: boolean = false;
  public failed: boolean = false;
  public retryEnabled: boolean = false;

  private token: string = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private authService: AuthService,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      false,
    );

    this.failed = false;
    this.loading = false;

    this.sub = this.route.queryParams.subscribe((queryParams) => {
      this.fetchDataFromQueryParams(queryParams);
      this.validateAndLogin();
    });
  }

  private fetchDataFromQueryParams(queryParams: object) {
    this.token = queryParams["token"];
  }

  private validateAndLogin() {
    if (!this.token) {
      console.error("Missing token");
      this.failed = true;
    } else {
      this.loading = true;
      this.authService
        .loginGrantCallback(this.token)
        .then(() => {
          try {
            // this.notificationHelper.trigger("Welcome!", null, 'success');
            // this.destination is not safe !!
            // this.router.navigate(this.destination, { queryParams: this.destinationQueryParams });
            this.router.navigate(["/"]);
          } catch (err) {
            this.router.navigate(["/404"]);
          }
        })
        .catch((err: HttpErrorResponse) => {
          console.error(err);
          this.notificationHelper.trigger(
            err?.error?.message ?? "Error",
            null,
            "error",
          );
          this.failed = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  public retry(): void {
    throw "Not supported";
  }
}
