/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { autoTips } from "helpers/form-errors.helper";
import { NotificationHelper } from "helpers/notification.helper";
import { OrgBilling } from "models/org_billing.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";

@Component({
  selector: "billing-lateral-panel-billing-info",
  template: `
    <form
      nz-form
      [nzAutoTips]="autoTips"
      [formGroup]="billingInfoForm"
      [nzLayout]="'vertical'"
      (submit)="saveBillingInfo()"
      (ngSubmit)="saveBillingInfo()"
    >
      <nz-form-item>
        <nz-form-control>
          <nz-radio-group formControlName="is_company" nzSize="large">
            <big-radio [nzValue]="false" label="Personnal"></big-radio>
            <big-radio [nzValue]="true" label="Company"></big-radio>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired nzFor="name">{{
          billingInfoForm.value.is_company ? "Company Name" : "Name"
        }}</nz-form-label>
        <nz-form-control>
          <nz-input-group>
            <input type="text" nz-input formControlName="name" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="billingInfoForm.value.is_company">
        <nz-form-label nzFor="vat">VAT number (optional)</nz-form-label>
        <nz-form-control>
          <nz-input-group>
            <input type="text" nz-input formControlName="vat" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired nzFor="email">Billing email</nz-form-label>
        <nz-form-control>
          <nz-input-group>
            <input type="email" nz-input formControlName="email" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired nzFor="address_line_1"
          >Address line 1</nz-form-label
        >
        <nz-form-control>
          <nz-input-group>
            <input type="text" nz-input formControlName="address_line_1" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="address_line_2">Address line 2</nz-form-label>
        <nz-form-control>
          <nz-input-group>
            <input type="text" nz-input formControlName="address_line_2" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired nzFor="city">City</nz-form-label>
        <nz-form-control>
          <nz-input-group>
            <input type="text" nz-input formControlName="city" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired nzFor="postal_code">ZIP code</nz-form-label>
        <nz-form-control [nzErrorTip]="errorTpl">
          <nz-input-group>
            <input
              type="text"
              minLength="5"
              maxLength="7"
              nz-input
              formControlName="postal_code"
            />
            <ng-template #errorTpl let-control>
              <span class="field-error" *ngIf="control.hasError('pattern')">
                Malformed ZIP code
              </span>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired nzFor="country">Country</nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="country"
            nzPlaceHolder="Select country"
            nzShowSearch
          >
            <nz-option
              *ngFor="let opt of countries"
              [nzValue]="opt[0]"
              [nzLabel]="opt[1]"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>

    <footer class="panel-button-container">
      <button
        class="cancel-btn"
        nz-button
        nzType="default"
        nzSize="large"
        (click)="onClose.emit($event)"
      >
        Cancel
      </button>

      <button
        nz-button
        nzType="primary"
        nzSize="large"
        [class]="{
          disabled: !billingInfoForm.valid
        }"
        [nzLoading]="validationLoading"
        [disabled]="!billingInfoForm.valid"
        (click)="saveBillingInfo()"
      >
        {{ hasNextStep ? "Next" : "Save billing info" }}
      </button>
    </footer>
  `,
  styles: [
    `
      form nz-form-item {
        margin-bottom: 15px;
      }

      form nz-form-item nz-form-label {
        padding-bottom: 3px;
        font-weight: bold;
      }

      form nz-form-item nz-radio-group {
        display: flex;
      }

      form nz-form-item nz-radio-group big-radio {
        display: block;
        flex: auto;
        padding: 8px;
      }
    `,
  ],
  styleUrls: ["../footer.component.scss"],
})
export class SettingsBillingLateralPanelBillingInfoComponent implements OnInit {
  @Input() superOrg: SuperOrg = null;
  @Input() orgBilling: OrgBilling = null;
  @Input() hasNextStep: boolean = false;
  @Input() countries: string[][] = null;

  @Output() orgBillingChange = new EventEmitter<OrgBilling>();
  @Output() onClose = new EventEmitter<boolean>();

  public billingInfoForm: FormGroup;
  public autoTips = autoTips;

  public validationLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private notificationHelper: NotificationHelper,
    private superOrgDao: SuperOrgDao,
  ) {}

  ngOnInit(): void {
    this.billingInfoForm = null;

    this.initModeBillingInfo();
  }

  private initModeBillingInfo() {
    this.billingInfoForm = this.fb.group({
      is_company: [this.orgBilling.is_company, [Validators.required]],
      name: [this.orgBilling.name, [Validators.required]],
      email: [this.orgBilling.email, [Validators.required, Validators.email]],
      address_line_1: [this.orgBilling.address_line_1, [Validators.required]],
      address_line_2: [this.orgBilling.address_line_2, []],
      city: [this.orgBilling.city, [Validators.required]],
      postal_code: [
        this.orgBilling.postal_code,
        [Validators.required, Validators.pattern(/^[a-zA-Z0-9]{4,7}$/)],
      ],
      country: [this.orgBilling.country, [Validators.required]],
      vat: [this.orgBilling.vat, []],
    });

    this.billingInfoForm.valueChanges.subscribe(() => {
      this.billingInfoCleanVAT();
    });
  }

  public billingInfoCleanVAT(): boolean {
    if (!this.billingInfoForm.value.vat) {
      return;
    }

    // remove forbidden char
    const newVal = this.billingInfoForm.value.vat.replace(
      /([^a-zA-Z0-9]+)/gi,
      "",
    );
    this.billingInfoForm.get("vat").setValue(newVal, { emitEvent: false });
  }

  public billingInfoAreValid(): boolean {
    if (this.billingInfoForm.valid) return true;

    Object.values(this.billingInfoForm.controls).forEach((control) => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });

    return false;
  }

  public saveBillingInfo() {
    if (!this.billingInfoAreValid) {
      return;
    }

    this.validationLoading = true;

    const values = this.billingInfoForm.value;
    const billingInfoValues = {
      is_company: values.is_company,
      name: values.name,
      email: values.email,
      address_line_1: values.address_line_1,
      address_line_2:
        values.address_line_2?.length > 0 ? values.address_line_2 : null,
      city: values.city,
      postal_code: values.postal_code,
      country: values.country,
      vat: values.is_company && values.vat?.length > 0 ? values.vat : null,
    } as OrgBilling;

    this.superOrgDao
      .updateBillingInfo(this.superOrg.id, billingInfoValues)
      .then((orgBilling: OrgBilling) => {
        this.orgBilling = orgBilling;
        this.orgBillingChange.emit(orgBilling);
        this.validationLoading = false;
      })
      .catch((err) => {
        this.notificationHelper.trigger(
          "Could not save billing information.",
          null,
          "error",
        );
        console.error(err);
        this.validationLoading = false;
      });
  }
}
