<div
  [ngClass]="{
    'is-locked': !('team_membership' | hasEntitlement)
  }"
>
  <h1>Team members</h1>

  <header>
    <p>
      Screeb is better with friends!<br />
      Invite your colleagues and collaborate on surveys, research and insights
      analysis.
    </p>
  </header>
  <h2>Your team</h2>
  <section id="results">
    <nz-table
      #table
      [nzData]="orgAccounts"
      [nzHideOnSinglePage]="true"
      [nzPageSize]="100"
      [nzScroll]="{ x: '1100px' }"
    >
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Email</th>
          <th>Title</th>
          <th>Role</th>
          <th>Last seen at</th>
          <th nzWidth="80" nzRight></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let account of table.data">
          <td style="max-width: 50px">
            <org-account-avatar [account]="account"></org-account-avatar>
          </td>
          <td>{{ account.fullname }}</td>
          <td>{{ account.email }}</td>
          <td>{{ account.job_title }}</td>
          <td>
            <nz-select
              required
              nzPlaceHolder="Role"
              [disabled]="
                sessionService.session.id === account.id ||
                !('org.member.update' | hasPermission)
              "
              [ngModel]="account.role"
              (ngModelChange)="onRoleChanged(account, $event)"
              [nzDropdownMatchSelectWidth]="false"
            >
              <nz-option nzLabel="Admin" nzValue="admin"></nz-option>
              <nz-option nzLabel="Editor" nzValue="editor" nzCustomContent>
                <span>Editor</span>&nbsp;
                <utils-badge top="-2px" text="New"></utils-badge>
              </nz-option>
              <nz-option nzLabel="Analyst" nzValue="analyst" nzCustomContent>
                <span>Analyst</span>&nbsp;
                <utils-badge top="-2px" text="New"></utils-badge>
              </nz-option>
            </nz-select>
          </td>
          <td>
            {{
              account.last_seen_at
                | dfnsFormatDistanceToNow: { addSuffix: true }
            }}
          </td>
          <td nzAlign="center" class="actions" nzRight>
            <button
              *ngIf="
                sessionService.session.id !== account.id &&
                ('org.member.delete' | hasPermission)
              "
              title="Remove member"
              nz-button
              nzType="default"
              nzSize="large"
              nzDanger
              (click)="deleteMember(account.id)"
            >
              <i nz-icon nzType="delete"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </section>

  <section id="invitation" *ngIf="'org.member.create' | hasPermission">
    <h2>Invite your colleagues</h2>

    <invite-team-members
      #invitationsForm
      (submitInvitations)="onSubmitInvitations($event)"
    ></invite-team-members>
  </section>
</div>

<upgrade-modal
  [centeredModal]="true"
  [withOverlay]="true"
  [canCancel]="false"
  upgradeType="team_membership"
  ctaText="Upgrade now!"
  position="absolute"
  class="upgrade-modal"
  *ngIf="!('team_membership' | hasEntitlement)"
  [orgId]="org.id"
></upgrade-modal>
