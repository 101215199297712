<ul nz-menu>
  <li id="menu-stats-survey-analytics" nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/{{
        uiService.surveyTypePath
      }}/{{ uiService.currentSurveyId }}/stats/overall-performances"
      [queryParams]="searchParams"
      routerLinkActive="active"
    >
      {{ uiService.isMessagePage ? "Message" : "Survey" }} analytics
    </a>
  </li>
  <ng-container *ngIf="!uiService.isMessagePage">
    <li id="menu-stats-survey-breakdown" nz-menu-item nzMatchRouter>
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/flow-analysis"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        {{ uiService.isMessagePage ? "Message" : "Survey" }} breakdown
      </a>
    </li>
    <li id="menu-stats-all-responses" nz-menu-item nzMatchRouter>
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/all-responses"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        All responses
      </a>
    </li>
    <li id="menu-stats-content-analysis" nz-menu-item nzMatchRouter>
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/content-analysis"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        Content analysis
      </a>
    </li>
    <li id="menu-stats-nps" nz-menu-item nzMatchRouter>
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/nps"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        Net Promoter Score
      </a>
    </li>
    <li id="menu-stats-csat" nz-menu-item nzMatchRouter>
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/csat"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        Customer Satisfaction
      </a>
    </li>
    <li id="menu-stats-ces" nz-menu-item nzMatchRouter>
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/ces"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        Customer Effort Score
      </a>
    </li>

    <li
      id="menu-stats-pmf"
      nz-menu-item
      nzMatchRouter
      *ngIf="'isProductMarketFitEnabled' | hasFeatureFlag"
    >
      <a
        routerLink="/org/{{ uiService.currentOrgId }}/{{
          uiService.surveyTypePath
        }}/{{ uiService.currentSurveyId }}/stats/product-market-fit"
        [queryParams]="searchParams"
        routerLinkActive="active"
      >
        Product Market Fit
      </a>
    </li>
  </ng-container>
</ul>
