import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, CanDeactivate, Router } from "@angular/router";
import { isInvalid } from "components/common/customize/tag-settings/component/tag-settings-design/tag-settings-design.component";
import { LayoutBackHeaderService } from "components/layouts/back/header/header.service";
import { PageComponentInterface } from "components/PageComponentInterface";
import { NotificationHelper } from "helpers/notification.helper";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import { Survey, SurveyInteraction } from "models/survey.model";
import { adaptTagSettingsToApi } from "models/tag-settings.api";
import { TagSettingsDao } from "models/tag-settings.dao";
import { TagSettings } from "models/tag-settings.types";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { UIService } from "services/ui.service";
import { deepCopy, deepEqual } from "utils/object";

@Component({
  selector: "page-survey-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SurveySettingsPageComponent
  implements
    PageComponentInterface,
    OnInit,
    AfterViewInit,
    OnDestroy,
    CanDeactivate<SurveySettingsPageComponent>
{
  public title = "Survey Settings & Design";
  public name = "Survey Settings & Design";

  @ViewChild("pageActions")
  private pageActionsRef: TemplateRef<any>;

  private obs: any = null;

  public org: Org = null;
  public integrations: Integration[] = null;
  public survey: Survey = null;
  public surveyDiff: Survey = null;

  public saving = false;
  public error: string = null;

  public tagSettings: TagSettings = null;
  public tagSettingsDiff: TagSettings = null;

  public surveyTags: string[] = [];

  public tabSelectedIndex = 0;

  public translationEnabled: boolean = true;
  public translationEnabledDiff: boolean = true;

  public interaction: SurveyInteraction = null;

  public isInvalid = isInvalid;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private layoutBackHeaderService: LayoutBackHeaderService,
    private tagSettingsDao: TagSettingsDao,
    private notificationHelper: NotificationHelper,
    public permissionsService: PermissionsService,
    public uiService: UIService,
  ) {}

  ngOnInit(): void {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
      this.integrations = data["integrations"];
      this.survey = data["survey"];
      this.surveyTags = data["surveyTags"];
      this.tagSettings = this.survey.settings
        ? { ...this.org.settings, ...this.survey.settings }
        : null;
      this.surveyDiff = new Survey().fromJson(deepCopy(this.survey));
      this.tagSettingsDiff = deepCopy(this.tagSettings);
      this.translationEnabled = this.survey.translation_enabled ?? true;
      this.translationEnabledDiff = this.translationEnabled;
      this.interaction = this.survey.interaction;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutBackHeaderService.surveyPageActionsRef = this.pageActionsRef;
    });
  }

  ngOnDestroy(): void {
    if (this.obs) {
      this.obs.unsubscribe();
    }
    this.layoutBackHeaderService.surveyPageActionsRef = null;
  }

  public getNbrResponse() {
    return this.survey.stats.response_total;
  }

  specificDesignSwitchChange(activated: boolean) {
    this.tagSettings = activated ? this.org.settings : null;
  }

  specifictranslationEnabledSwitchChange(activated: boolean) {
    this.translationEnabled = activated;
  }

  interactionTypeChange(interaction: SurveyInteraction) {
    this.interaction = interaction;
    this.survey.interaction = interaction;

    if (interaction === "in_page") {
      this.survey.settings.format = "in_page";
      this.tagSettings.format = "in_page";
    } else {
      this.survey.settings.format = "conversationnal";
      this.tagSettings.format = "conversationnal";
      this.tabSelectedIndex = -1;
    }
  }

  public async actionOnSave() {
    this.saving = true;
    this.error = null;

    this.tagSettingsDao
      .updateSurveyTagSettings(
        this.org.id,
        this.survey.id,
        adaptTagSettingsToApi(this.tagSettings, "survey"),
        {
          title: this.survey.title,
          tags: this.survey.tags,
        },
        this.survey.integrations,
        this.translationEnabled,
        this.interaction,
      )
      .then(() => {
        this.surveyDiff = new Survey().fromJson(deepCopy(this.survey));
        this.tagSettingsDiff = deepCopy(this.tagSettings);
        this.translationEnabledDiff = this.translationEnabled;

        if (!this.getNbrResponse()) {
          this.router.navigate([
            `/org/${this.org.id}/${this.uiService.isMessagePage ? "message" : "survey"}/${this.survey.id}/edit`,
          ]);
        }
      })
      .catch(() => {
        this.error = "Oops! Something went wrong. Please retry.";
        this.notificationHelper.trigger(
          "Something went wrong when saving your survey settings. Please try again later!",
          null,
          "error",
        );
      })
      .finally(() => {
        setTimeout(() => {
          this.saving = false;
        }, 300);
      });
  }

  public hasNotChanged(): boolean {
    return (
      deepEqual(this.tagSettings, this.tagSettingsDiff) &&
      deepEqual(this.survey, this.surveyDiff) &&
      this.translationEnabled === this.translationEnabledDiff
    );
  }

  saveButtonDisabled() {
    return this.hasNotChanged() || isInvalid(this.tagSettings);
  }

  isValidSurveyTags() {
    return (
      this.survey.tags.length <= 10 &&
      this.survey.tags.every((tag) => tag.length >= 1 && tag.length <= 255)
    );
  }

  isValidSurveyName() {
    return this.survey.title.length >= 3 && this.survey.title.length <= 255;
  }

  /**
   * Prevent user from navigating away or F5, when something is not saved
   */
  @HostListener("window:beforeunload", ["$event"])
  preventNavigatingAway($event: any) {
    if (this.hasUnsavedData) $event.returnValue = true;
  }

  private get hasUnsavedData(): boolean {
    return !this.saveButtonDisabled();
  }

  canDeactivate(): boolean {
    if (this.hasUnsavedData) {
      return confirm(
        "You have unsaved changes! If you leave, your changes will be lost.",
      );
    }
    return true;
  }
}
