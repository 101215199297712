<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<builder-layout
  #builderLayout
  [draggableZone]
  [columnsWithCards]="columnsWithCards"
  [channelIdPreview]="channelIdPreview"
  [defaultLanguageWarning]="hasMissingTranslation()"
  [forceHelpBox]="forceHelpBox"
  (onLateralPanelSave)="onNodeSavedFromLateralPanel($event)"
  (onNodeAdded)="onNodeAdded($event)"
  (onNodeRemoved)="onNodeDeletedFromLateralPanel($event)"
  (onOpenAiBuilder)="onOpenAiBuilder($event)"
  (onDisableHelpBox)="this.forceHelpBox = false"
  (defaultLanguageChange)="onDefaultLanguageChanged($event)"
  (onOpenTagEditor)="onOpenTagEditor($event)"
></builder-layout>

<ng-template #pageActions>
  <div class="toolbar" *ngIf="'survey.update' | hasPermission">
    <ng-container>
      <nz-button-group nzSize="large" class="actions-history">
        <button
          title="Undo"
          nz-button
          nzType="text"
          nzSize="large"
          nzShape="round"
          (click)="onUndo()"
          [disabled]="surveyHistoryIndex <= 0"
        >
          <screeb-icon size="sm" icon="undo"></screeb-icon>
        </button>
        <button
          title="Redo"
          nz-button
          nzType="text"
          nzSize="large"
          nzShape="round"
          (click)="onRedo()"
          [disabled]="surveyHistoryIndex >= surveyHistory.length - 1"
        >
          <screeb-icon size="sm" icon="redo"></screeb-icon>
        </button>
      </nz-button-group>

      <nz-button-group nzSize="large" class="actions-save">
        <button
          title="Reset survey"
          nz-button
          nzType="default"
          nzSize="large"
          nzShape="round"
          [disabled]="saveButtonDisabled()"
          (click)="onReset()"
        >
          <screeb-icon size="sm" icon="reset"></screeb-icon>
          &nbsp;
          <span>Reset</span>
        </button>
        <button
          *ngIf="builderStore?.survey?.stats.response_rate === 0"
          title="Save and next"
          nz-button
          class="ant-btn-secondary"
          nzSize="large"
          nzShape="round"
          [disabled]="saveButtonDisabled(true)"
          [nzLoading]="saving"
          (click)="actionOnSave()"
        >
          <screeb-icon size="sm" icon="arrow-right"></screeb-icon>
          <span>Save and next</span>
        </button>
        <button
          *ngIf="builderStore?.survey?.stats.response_rate > 0"
          title="Save"
          nz-button
          class="ant-btn-secondary"
          nzSize="large"
          nzShape="round"
          [disabled]="saveButtonDisabled()"
          [nzLoading]="saving"
          (click)="actionOnSave()"
        >
          <screeb-icon size="sm" icon="save"></screeb-icon>
          <span>Save</span>
        </button>
      </nz-button-group>
      <div class="in-page-popover" *ngIf="showInPagePopover">
        This survey can't be saved because interaction <br />
        in-page requires the last question to be Text
      </div>
    </ng-container>
  </div>
</ng-template>

<nz-modal
  [(nzVisible)]="isAIBuilderOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzClosable]="false"
  nzCentered
  (nzOnCancel)="isAIBuilderOpen = false"
  nzWidth="650px"
  nzClassName="survey-ai-builder-modal"
>
  <ng-template #modalTitle>
    <h3>
      <screeb-icon class="icon" size="sm" icon="lightning"></screeb-icon>
      Create with AI
    </h3>
    <p>
      Explain what you want to ask and our AI will create a survey. You'll be
      able to edit the survey before setting it live.
    </p>
    <p *ngIf="!emptyScenario" class="warn">
      Warning: your existing survey will be replaced.
    </p>
  </ng-template>

  <ng-template #modalContent>
    <input
      nz-input
      [disabled]="isGeneratingIdx !== null"
      [(ngModel)]="aiQuery"
      placeholder="e.g. I want to know what my users think about the buying process"
      (keyup.enter)="onGenerateScenario()"
    />
    <p class="tip-prompt">Or choose one of our scenarios below.</p>
    <button
      nz-button
      nzType="default"
      [nzLoading]="isGeneratingIdx === 1"
      [disabled]="isGeneratingIdx !== null && isGeneratingIdx !== 1"
      class="survey-ai-prompt-builder-btn"
      (click)="onGenerateScenario(1)"
    >
      Are my users satisfied about their experience with
      {{ builderStore?.org?.name || "my company" }}?
    </button>
    <button
      nz-button
      nzType="default"
      [nzLoading]="isGeneratingIdx === 2"
      [disabled]="isGeneratingIdx !== null && isGeneratingIdx !== 2"
      class="survey-ai-prompt-builder-btn"
      (click)="onGenerateScenario(2)"
    >
      Was it easy to use my last feature?
    </button>
    <button
      nz-button
      nzType="default"
      [nzLoading]="isGeneratingIdx === 3"
      [disabled]="isGeneratingIdx !== null && isGeneratingIdx !== 3"
      class="survey-ai-prompt-builder-btn"
      (click)="onGenerateScenario(3)"
    >
      What should I build next for my users to be happy?
    </button>
  </ng-template>

  <ng-template #modalFooter>
    <button
      nz-button
      nzType="default"
      class="survey-ai-builder-btn"
      (click)="isAIBuilderOpen = false"
    >
      Close
    </button>
    <div class="survey-ai-builder-create-wrapper">
      <ng-container *ngIf="isGeneratingIdx !== null">
        <span class="loading-tip">Generation can take up to 30 seconds.</span>
      </ng-container>
      <button
        nz-button
        nzType="primary"
        [nzLoading]="isGeneratingIdx !== null"
        class="survey-ai-builder-btn"
        (click)="onGenerateScenario(0)"
      >
        Create
      </button>
    </div>
  </ng-template>
</nz-modal>
