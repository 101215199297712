<div *ngIf="rule" class="line">
  <input
    [type]="['equal', 'not equal'].includes(rule.operator) ? 'url' : ''"
    nz-input
    placeholder="{{ rule.getValuePlaceholder() }}"
    [(ngModel)]="rule.value.v_s"
    [ngClass]="{ invalid: !checkValidQuerySelector(rule) }"
  />

  <span class="is">is</span>

  <rule-operator [rule]="rule" (ruleChange)="rule.operator = $event.operator">
  </rule-operator>
</div>
