<ng-container *ngIf="integration">
  <ng-container *ngIf="!integration.auth_ok">
    <p>
      No {{ integrationSpec.name }} account connected.<br />
      Please click the button below to connect one.
    </p>

    <a
      class="connect"
      [href]="authorizeURL"
      *ngIf="!!authorizeURL"
      nz-button
      nzType="default"
      nzSize="large"
    >
      <img
        src="/assets/icons/integrations/{{ integrationSlug }}-icon.svg"
        style="height: 20px; width: 20px; margin-right: 12px"
        alt="Connect {{ integrationSpec.name }} account"
      />
      <span> Connect {{ integrationSpec.name }} account </span>
    </a>
  </ng-container>

  <ng-container *ngIf="integration.auth_ok">
    <p class="integrationOk">
      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
      <span>
        All good! A {{ integrationSpec.name }} account is connected.<br />
        {{ confirmMessage }}
      </span>
    </p>

    <ul class="connected-cta">
      <li *ngIf="!!authorizeURL">
        <a [href]="authorizeURL" title="Reconnect" nz-button nzType="default">
          Reconnect
        </a>
      </li>
      <li>
        <button
          title="Disconnect"
          nz-button
          nzType="default"
          nzDanger
          (click)="disconnect()"
        >
          Revoke access
        </button>
      </li>
    </ul>
  </ng-container>
</ng-container>
