import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ScenarioGraphBuilder } from "components/builder/flow";

import { PageComponentInterface } from "components/PageComponentInterface";
import { QuestionDetails } from "components/surveys/pages/stats/individual-responses/questions-table/questions-table.component";
import { NotificationHelper } from "helpers/notification.helper";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import {
  AnalyticsResponse,
  AnalyticsResponseItemResponse,
  AnalyticsResponseItemResponseEmotions,
} from "models/analytics.model";
import { Org } from "models/org.model";
import {
  RegistryEntry,
  RegistryEntrySourceFormatted,
} from "models/registry.model";
import { UserEvent } from "models/user-event.model";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import { UserRecord } from "models/user-record.model";
import { UserDao } from "models/user.dao";
import {
  aggregateUserProperties,
  getFormattedUserNameOrIDByNormalizedProperty,
  getUserIcon,
  removePropertiesHavingNullParent,
  User,
  UserNormalizedProperty,
} from "models/user.model";
import { Response } from "models/response.model";
import { SurveyDao } from "models/survey.dao";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { NzTableSortOrder } from "ng-zorro-antd/table";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { EntitlementService } from "services/entitlement.service";
import { RoutingService } from "services/routing.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { arrayToMap, groupBy } from "utils/array";
import { mapObject } from "utils/object";
import { UserNormalizedEvent } from "../common/user-events/user-events.component";
import { PermissionsService } from "services/permissions.service";
import { ResponseDao } from "models/response.dao";
import { HttpErrorResponse } from "@angular/common/http";
import { NzModalService } from "ng-zorro-antd/modal";
import { delayPromise } from "utils/promises";
import { UIService } from "services/ui.service";
import { ChartDataset } from "chart.js";
import { RadarIndicatorLegend } from "components/surveys/pages/stats/indicators/components/radar-indicator/radar-indicator.component";
import { IndustriesScores } from "resolvers/asset-industries-scores";
import { UserRecordDao } from "models/user-record.dao";

const RESPONSE_POLLING_INTERVAL = 30000;

type ResponseDetails = {
  survey: Survey;
  questions: QuestionDetails[];
  response: AnalyticsResponseItemResponse;
};

@Component({
  selector: "user-details-page",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"],
})
export class UserDetailsPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "User profile";
  public name = "User profile";

  private obs: any = null;

  public org: Org;
  public orgUserProperties: RegistryEntry[]; // exclude type=object
  public orgUserPropertiesById: Map<string, RegistryEntry>;
  public orgUserEvents: RegistryEntry[];
  public orgUserGroups: RegistryEntry[];
  public orgUserGroupTypes: RegistryEntry[];

  public loadingEmotions = true;
  private orgEmotions: AnalyticsResponseItemResponseEmotions = null;
  private userEmotions: AnalyticsResponseItemResponseEmotions = null;
  public emotionsChartDatasets: ChartDataset[] = [];
  public emotionsChartLegend: RadarIndicatorLegend = [
    {
      label: "User",
      value: "User",
      checked: true,
      disabled: false,
      color: "#0094FF",
      hoverColor: "#0094FF",
    },
    {
      label: "Organization",
      value: "Organization",
      checked: true,
      disabled: false,
      color: "#1ED5A4",
      hoverColor: "#1ED5A4",
    },
    {
      label: "Industry",
      value: "Industry",
      checked: true,
      disabled: false,
      color: "#0054B6",
      hoverColor: "#0054B6",
      format: "number",
    },
  ];

  public user: User;
  public userProperties: UserNormalizedProperty[] = [];
  public userEvents: UserNormalizedEvent[] = [];
  public userEventsTypeform: UserNormalizedEvent[] = [];
  public userRecords: UserRecord[] = [];
  public userGroups: RegistryEntry[] = [];
  public userResponsesPG: Response[] = [];
  public userResponsesES: ResponseDetails[];
  // public userResponsesES: QuestionDetails[];
  public deletingByResponseId = {};

  public responsesCount = 0;
  public lastResponseDate = new Date(0);
  public expandedResponses: { [key: string]: boolean } = {};

  public loadingResponsesES = true;
  public initialFetchResponsesES = true;
  public errorResponsesES: Error;

  public loadingResponsesPG = true;
  public initialFetchResponsesPG = true;
  public errorResponsesPG: Error;

  public loadingProperties = true;
  public initialFetchProperties = true;
  public errorProperties: Error;

  public loadingEvents = true;
  public initialFetchEvents = true;
  public errorEvents: Error;

  public loadingEventsTypeform = true;
  public initialFetchEventsTypeform = true;
  public errorEventsTypeform: Error;

  public loadingRecords = true;
  public initialFetchRecords = true;
  public errorRecords: Error;

  public loadingRequestDataDeletion = false;

  public questionOrder: NzTableSortOrder = "descend";

  public surveyIds: UUID[] = [];

  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;
  public getUserIcon = getUserIcon;
  public getFormattedUserGroupNameOrID = getFormattedUserGroupNameOrID;
  public getFormattedUserNameOrIDByNormalizedProperty =
    getFormattedUserNameOrIDByNormalizedProperty;

  private getResponseInterval: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private analyticsDao: AnalyticsDao,
    private surveyDao: SurveyDao,
    private responseDao: ResponseDao,
    private userDao: UserDao,
    private userRecordDao: UserRecordDao,
    private trackersService: TrackersService,
    private notificationHelper: NotificationHelper,
    private modalService: NzModalService,
    public uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public entitlementService: EntitlementService,
  ) {}

  private mergeEvents(
    registryEntriesEvent: RegistryEntry[],
    events: UserEvent[],
  ): UserNormalizedEvent[] {
    return events
      .sort(
        (event1, event2) =>
          Number(event2.triggered_at) - Number(event1.triggered_at),
      )
      .map((event) => {
        const name = registryEntriesEvent.find(
          (registryEntry) => event.name_id === registryEntry.id,
        )?.slug;

        return {
          name,
          ...event,
        };
      });
  }

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );
    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.user = data.user;
      this.orgUserProperties = data.orgProperties;
      this.orgUserPropertiesById = arrayToMap(this.orgUserProperties, "id");
      this.orgUserProperties = this.orgUserProperties.filter(
        (entry: RegistryEntry) => entry.type !== "object",
      );
      this.orgUserEvents = data.orgEvents;
      this.orgUserGroups = data.orgGroups.groups;
      this.orgUserGroupTypes = data.orgGroups.group_types;

      this.userGroups = this.user.assigned_group_ids
        .map((assigned_group_id) =>
          this.orgUserGroups.find(({ id }) => assigned_group_id === id),
        )
        .filter(Boolean);

      // Get emotions based on filters
      this.loadingEmotions = true;
      Promise.all([this.getUserEmotions(), this.getOrgEmotions()])
        .then(() => {
          this.updateEmotionsDatasets(
            data.industriesScores as IndustriesScores,
          );
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingEmotions = false;
        });

      this.refreshData();

      this.getResponseInterval = setInterval(
        this.refreshData.bind(this),
        RESPONSE_POLLING_INTERVAL,
      );
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
    if (this.getResponseInterval) {
      clearInterval(this.getResponseInterval);
    }
  }

  public async refreshData() {
    this.getResponsesES();
    this.getResponsesPG();
    this.getProperties();
    this.getEvents();
    this.getRecords();
  }

  // Get average org emotions
  private async getOrgEmotions() {
    return this.analyticsDao
      .search({
        org_id: this.org.id as UUID,
        survey_ids: ["*"],
        type: "response",
        filters: [],
        filters_bool: "AND",
        range: {
          field: "created_at",
          start: new Date(this.org.created_at),
          end: new Date(),
        },
        size: 0,
        offset: 0,
        aggregation: [{ by: "by_emotions" }],
      })
      .then((response) => {
        const { emotions } = response.aggregations;
        this.orgEmotions = {
          sadness: Math.round((emotions.sadness?.avg?.value ?? 0) * 5),
          joy: Math.round((emotions.joy?.avg?.value ?? 0) * 5),
          anger: Math.round((emotions.anger?.avg?.value ?? 0) * 5),
          fear: Math.round((emotions.fear?.avg?.value ?? 0) * 5),
        };
      });
  }

  private async getUserEmotions() {
    return this.analyticsDao
      .search({
        org_id: this.org.id as UUID,
        survey_ids: ["*"],
        type: "response",
        filters: [
          {
            type: "response",
            key: "respondent_aliases",
            operator: "eq",
            value: this.user.id,
          },
        ],
        filters_bool: "AND",
        range: {
          field: "created_at",
          start: new Date(this.org.created_at),
          end: new Date(),
        },
        size: 0,
        offset: 0,
        aggregation: [{ by: "by_emotions" }],
      })
      .then((response) => {
        const { emotions } = response.aggregations;
        this.userEmotions = {
          sadness: Math.round((emotions.sadness?.avg?.value ?? 0) * 5),
          joy: Math.round((emotions.joy?.avg?.value ?? 0) * 5),
          anger: Math.round((emotions.anger?.avg?.value ?? 0) * 5),
          fear: Math.round((emotions.fear?.avg?.value ?? 0) * 5),
        };
      });
  }

  private async getResponsesES() {
    // if (this.surveyIds.length === 0) {
    //   // Need this condition because survey ids are inserted into URL.
    //   // Empty survey list implies a broken URL...
    //   this.userResponsesES = [];
    //   this.loadingResponsesES = false;
    //   this.initialFetchResponsesES = false;
    //   this.errorResponsesES = null;
    //   return;
    // }

    this.loadingResponsesES = true;
    this.errorResponsesES = null;

    const query: AnalyticsQueryResponse = {
      type: "response",
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      filters: [
        {
          type: "response",
          key: "respondent_aliases",
          operator: "eq",
          value: this.user.id,
        },
      ],
      filters_bool: "AND",
      range: {
        field: "created_at",
        start: new Date(this.org.created_at),
        end: new Date(),
      },
      sort: {
        field: "response.last_answer_at",
        order: "desc",
      },
      size: 50,
    };

    return this.analyticsDao
      .search(query)
      .then(async (analyticsResponse) => {
        this.userResponsesES = await this.formatResponses(analyticsResponse);
      })
      .catch((error) => {
        this.errorResponsesES = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingResponsesES = false;
        this.initialFetchResponsesES = false;
      });
  }

  private updateEmotionsDatasets(scores: IndustriesScores) {
    const datasets = [];
    if (this.userEmotions) {
      datasets.push({
        label: "User",
        data: [
          // Let's have a minimum of 0.25 to fix not chart when we only have one positive emotion
          Math.max(this.userEmotions?.anger, 0.25),
          Math.max(this.userEmotions?.joy, 0.25),
          Math.max(this.userEmotions?.fear, 0.25),
          Math.max(this.userEmotions?.sadness, 0.25),
        ],
        fill: true,
        borderJoinStyle: "round",
        backgroundColor: "rgba(94, 33, 241, 0.2)",
        pointRadius: 0,
      });
    } else {
      this.emotionsChartLegend.find(
        (legend) => legend.value === "User",
      ).disabled = true;
    }

    if (this.orgEmotions) {
      datasets.push({
        label: "Organization",
        data: [
          // Let's have a minimum of 0.25 to fix not chart when we only have one positive emotion
          Math.max(this.orgEmotions?.anger, 0.25),
          Math.max(this.orgEmotions?.joy, 0.25),
          Math.max(this.orgEmotions?.fear, 0.25),
          Math.max(this.orgEmotions?.sadness, 0.25),
        ],
        fill: true,
        borderJoinStyle: "round",
        backgroundColor: "rgba(30, 213, 164, .5)",
        pointRadius: 0,
      });
    } else {
      this.emotionsChartLegend.find(
        (legend) => legend.value === "Organization",
      ).disabled = true;
    }

    if (this.org.industry && scores[this.org.industry]) {
      datasets.push({
        label: "Industry",
        data: scores[this.org.industry].scores.emotions.map((e) => e * 5),
        borderDash: [2, 2],
        fill: false,
        borderJoinStyle: "round",
        borderColor: "rgb(94, 33, 241)",
        borderWidth: 1.5,
        pointRadius: 0,
      });
    } else {
      this.emotionsChartLegend.find(
        (legend) => legend.value === "Industry",
      ).disabled = true;
    }
    this.emotionsChartDatasets = datasets;
  }

  private async getResponsesPG() {
    this.loadingResponsesPG = true;
    this.errorResponsesPG = null;

    return this.userDao
      .getUserResponses(this.org.id, this.user.id, 100) // cursor ?
      .then((responses) => {
        this.userResponsesPG = responses;
      })
      .catch((error) => {
        this.errorResponsesPG = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingResponsesPG = false;
        this.initialFetchResponsesPG = false;
      });
  }

  private async getProperties() {
    this.loadingProperties = true;
    this.errorProperties = null;

    return this.userDao
      .getUserProperties(this.org.id, this.user.id)
      .then((properties) => {
        properties = removePropertiesHavingNullParent(
          this.orgUserPropertiesById,
          properties,
        );
        this.userProperties = aggregateUserProperties(
          this.orgUserPropertiesById,
          properties,
        );
      })
      .catch((error) => {
        this.errorProperties = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingProperties = false;
        this.initialFetchProperties = false;
      });
  }

  private async getEvents() {
    this.loadingEvents = true;
    this.errorEvents = null;

    // all event types except typeform
    this.userDao
      .getUserEvents(
        this.org.id,
        this.user.id,
        50,
        new Date(),
        // ["track", "screen"],
        ["track"],
        [],
        [],
        ["typeform"],
      )
      .then((data: UserEvent[]) => {
        this.userEvents = this.mergeEvents(this.orgUserEvents, data);
      })
      .catch((error) => {
        this.errorEvents = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingEvents = false;
        this.initialFetchEvents = false;
      });

    // typeform
    this.userDao
      .getUserEvents(
        this.org.id,
        this.user.id,
        50,
        new Date(),
        ["track"],
        [],
        ["typeform"],
        [],
      )
      .then((events) => {
        this.userEventsTypeform = this.mergeEvents(this.orgUserEvents, events);
      })
      .catch((error) => {
        this.errorEventsTypeform = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingEventsTypeform = false;
        this.initialFetchEventsTypeform = false;
      });
  }

  private async getRecords() {
    this.loadingRecords = true;
    this.errorRecords = null;

    return this.userRecordDao
      .getByUserID(this.org.id, this.user.id)
      .then((records) => {
        records.sort(
          (a, b) => Number(b.session_start) - Number(a.session_start),
        );

        // hide records shorter than 5 seconds
        records = records.filter((r) => r.snapshot_duration_seconds > 5);

        this.userRecords = records;
      })
      .catch((error) => {
        this.errorRecords = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingRecords = false;
        this.initialFetchRecords = false;
      });
  }

  private async formatResponses({
    hits: { responses },
  }: AnalyticsResponse): Promise<ResponseDetails[]> {
    if (!responses) {
      return [];
    }

    const responsesWithAnswers = responses?.filter(({ answers }) =>
      Boolean(answers.length),
    );

    if (!responsesWithAnswers) {
      return [];
    }

    this.responsesCount = responsesWithAnswers.length;
    this.lastResponseDate = responsesWithAnswers.reduce(
      (mostRecentDate, { last_answer_at }) =>
        mostRecentDate > last_answer_at ? mostRecentDate : last_answer_at,
      new Date(0),
    );

    const responsesByScenarioIdsBySurveyIds = mapObject(
      groupBy(responsesWithAnswers, "survey_id"),
      (value) => Object.keys(groupBy(value, "scenario_id")),
    );

    const scenarioToFetch = Object.entries(
      responsesByScenarioIdsBySurveyIds,
    ).flatMap(([surveyId, scenarioIds]) =>
      scenarioIds.map((scenarioId) => ({ surveyId, scenarioId })),
    );

    const surveys = await Promise.all(
      scenarioToFetch.map(({ surveyId, scenarioId }) =>
        this.surveyDao.getById(this.org.id, surveyId, scenarioId),
      ),
    );

    const findSurveyByScenarioId = (scenarioId: string) =>
      surveys.find(({ scenario: { id } }) => id === scenarioId);

    return responsesWithAnswers
      .map((response) => {
        const survey = findSurveyByScenarioId(response.scenario_id);
        const nodes = new ScenarioGraphBuilder(survey.scenario)
          .getNodeGraph()
          .flat()
          .filter(
            ({ correlationId }) =>
              !!response.answers.find(({ key }) => correlationId === key),
          );

        const questions = nodes
          .filter(
            (node) =>
              !!response.answers.find(
                ({ key }) => node.node.correlation_id === key,
              ),
          )
          .map((node) => {
            const answer = response.answers.find(
              ({ key }) => node.node.correlation_id === key,
            );

            return {
              survey,
              _node: node,
              node: {
                id: node.node.id,
                correlationId: node.node.correlation_id,
                letter: node.name,
                type: node.questionType,
                text: node.description,
              },
              index: answer.time.getTime(), // revert order
            } as QuestionDetails;
          });

        return {
          survey,
          questions,
          response,
        } as ResponseDetails;
      })
      .sort(
        (a, b) =>
          b.response.created_at.getTime() - a.response.created_at.getTime(),
      );
  }

  onResponseClicked() {
    clearInterval(this.getResponseInterval);
    if (Object.values(this.expandedResponses)?.length > 0) {
      this.getResponseInterval = setInterval(
        this.getResponsesES.bind(this),
        RESPONSE_POLLING_INTERVAL,
      );
    }

    this.trackEvent("Respondent response clicked");
  }

  public deleteResponse(userId: string, responseId: string) {
    this.deletingByResponseId[responseId] = true;

    this.responseDao
      .delete(this.org.id, userId, responseId)
      .then(() => {
        this.notificationHelper.trigger("Response removed", null, "success");

        // remove from current view
        this.hideJustDeletedResponse(responseId);
      })
      .catch(() =>
        this.notificationHelper.trigger(
          "Failed to remove response",
          null,
          "error",
        ),
      )
      .finally(() => {
        this.deletingByResponseId[responseId] = false;
      });
  }
  private hideJustDeletedResponse(responseId: string) {
    // remove from current view
    const indexES = this.userResponsesES.findIndex(
      (r) => r.response.id === responseId,
    );
    if (indexES >= 0) {
      this.userResponsesES.splice(indexES, 1);
    }

    // remove from current view
    const indexPG = this.userResponsesPG.findIndex((r) => r.id === responseId);
    if (indexPG >= 0) {
      this.userResponsesPG.splice(indexPG, 1);
    }
  }

  getTypeformEventURL(event: UserEvent) {
    const formId = event.raw_properties?.["form_response"]?.["form_id"];
    if (!formId) return "https://admin.typeform.com";

    return `https://admin.typeform.com/form/${formId}/results#responses`;
  }

  onEventClicked() {
    this.trackEvent("Respondent event clicked");
  }

  onRecordClicked() {
    this.trackEvent("Respondent record opened");
  }

  onTypeformClicked() {
    this.trackEvent("Respondent typeform clicked");
  }

  removeUserFromGroup(userGroup: RegistryEntry) {
    this.userDao
      .removeUserFromGroup(
        this.org.id,
        this.user.id,
        null,
        userGroup.parent_id,
        userGroup.title,
      )
      .then(() => {
        this.userGroups = this.userGroups.filter(
          ({ id }) => id !== userGroup.id,
        );
        this.notificationHelper.trigger(
          `Successfully removed user from segment '${userGroup.title}'!`,
          null,
          "success",
        );

        this.trackersService
          .newEventTrackingBuilder("Respondent removed from segment")
          .withOrg(this.org)
          .withUser(this.user)
          .withProps({
            segments: userGroup.slug,
            segmentType: userGroup.parent_id,
          })
          .build();
      })
      .catch(() => {
        this.notificationHelper.trigger(
          `Failed to remove user from segment '${userGroup.title}', please retry.`,
          null,
          "error",
        );
      });
  }

  onAddedToSegments(groupIds: string[]) {
    this.userGroups.push(
      ...groupIds
        .map((groupId) => this.orgUserGroups.find(({ id }) => id === groupId))
        .filter(Boolean),
    );
  }

  public requestDataDeletion(event: Event) {
    event.preventDefault();

    if (!this.permissionsService.isAllowed("user.delete")) {
      return;
    }

    new Promise((resolve) => {
      this.modalService.warning({
        nzTitle: "Do you really want to hurt me? 🎶",
        nzContent:
          "Tracked data and survey responses will be removed. This operation cannot be undone.",
        nzStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
        },
        nzMaskClosable: true,
        nzCloseOnNavigation: false,
        nzOkType: "default",
        nzOkDanger: true,
        nzOkText: "Confirm",
        nzCancelText: "Cancel",
        nzOnOk: () => resolve(true),
        nzOnCancel: () => resolve(false),
      });
    })
      .then((remove: boolean) => {
        if (!remove) {
          return;
        }

        this.loadingRequestDataDeletion = true;
        return this.userDao
          .remove(this.org.id, this.user.id)
          .then(delayPromise(5000));
      })
      .then(() => {
        this.router.navigate(["org", this.org.id, "people", "respondent"]);
      })
      .catch((err: HttpErrorResponse) => {
        this.loadingRequestDataDeletion = false;
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Could not delete user",
          null,
          "error",
        );
      });
  }

  public getAvatarURL(): string | null {
    return this.userProperties.find(
      (property: UserNormalizedProperty) => property.key === "avatar",
    )?.value as string; // this safe, we don't expect other data type for avatars
  }

  private trackEvent(eventName: TrackingEventName) {
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withUser(this.user)
      .build();
  }

  // public formatRespondentDisplayData(
  //   respondentDisplay: RespondentDisplay
  // ): string {
  //   // @TODO [@samber 2022-10-12]: i had to run this formatter on TS side, because created_at is not a Date object
  //   // When i gonna remove this respondentDisplay useless entity, i will replace it by a template formatter: `created_at | dfnsFormatDistanceToNow: { addSuffix: true }`
  //   return formatDistanceToNow(new Date(respondentDisplay.created_at), {
  //     addSuffix: true,
  //   });
  // }
}
