<ng-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="mode !== 'default' && mode !== 'home'"
></ng-keyboard-shortcuts>
<div
  class="ai-search"
  [class]="{
    open: isOpen,
    isFixed: mode === 'default',
    'align-left': mode === 'home'
  }"
  #aiSearch
  (click)="onOpen()"
>
  <screeb-icon
    *ngIf="mode === 'default' || mode === 'home'"
    class="icon"
    size="sm"
    icon="search"
  ></screeb-icon>
  <input
    type="text"
    nzBorderless
    nz-input
    #aiSearchInput
    nzSize="large"
    [ngModel]="isOpen ? query : null"
    (ngModelChange)="onQueryChange($event)"
    (keyup.enter)="onEnter()"
    (keydown.Tab)="onArrowDown()"
    (keyup.arrowup)="onArrowUp()"
    (keyup.arrowdown)="onArrowDown()"
    placeholder="Search anything..."
    focus
  />
  <span *ngIf="isLoading" nz-icon nzType="loading" class="loading-icon"></span>
  <screeb-icon
    *ngIf="hasError && !isLoading"
    size="sm"
    icon="warning"
    class="error-icon"
    nz-tooltip
    nzTooltipTitle="An error occured, please try again later"
  ></screeb-icon>
  <span *ngIf="!isLoading && !hasError" class="tip enter" (click)="onEnter()">
    Enter ⏎
  </span>
  <span
    *ngIf="
      (mode === 'default' || mode === 'home') &&
      !isOpen &&
      !isLoading &&
      !hasError
    "
    class="tip shortcut"
    (click)="onEnter()"
  >
    ⌘K
  </span>
</div>
<div
  class="ai-helper-modal"
  [class]="{
    open: isAnswerOpen && (!hasError || answer?.message?.length),
    isFixed: mode === 'default'
  }"
  [style]="
    mode === 'default'
      ? null
      : {
          width: aiSearch?.offsetWidth + 'px',
          left: aiSearch?.offsetLeft + 'px'
        }
  "
>
  <div *ngIf="answer?.answers !== null && query.length" class="ai-answer">
    <h3>{{ queryQuestion }}</h3>
    <ng-container *ngIf="answer?.answers?.length">
      <div class="message" [innerHtml]="answer.message"></div>
    </ng-container>
    <div class="message" *ngIf="!answer?.answers?.length">
      <p>
        Sorry, I don't have an answer for this question yet. <br />
        <span class="tip">You can ask another question</span>
      </p>
    </div>
  </div>
  <div class="ai-answer" *ngIf="mode === 'default' || mode === 'home'">
    <ng-container *ngIf="commands?.length">
      <h4>Commands</h4>
      <a
        href="javascript:void(0)"
        *ngFor="let command of commands; let i = index"
        class="ai-answer-result ai-answer-command"
        (click)="onCommandClick(command)"
        (keydown.Tab)="onArrowDown()"
        (keyup.enter)="onCommandClick(command)"
        (keyup.arrowup)="onArrowUp()"
        (keyup.arrowdown)="onArrowDown()"
        #commands
      >
        <div class="ai-answer-result__icon">
          <screeb-icon
            [size]="command.iconSize || 'md'"
            [icon]="command.icon || 'arrow-right'"
          ></screeb-icon>
        </div>
        <div>
          <p class="ai-answer-result__title">{{ command.label }}</p>
          <p class="ai-answer-result__description">
            {{ command.description }}
          </p>
        </div>
      </a>
      <hr *ngIf="results?.length" />
    </ng-container>
    <ng-container *ngIf="results?.length">
      <h4>Search results</h4>
      <a
        [routerLink]="result.url"
        *ngFor="let result of results"
        class="ai-answer-result"
        (keydown.Tab)="onArrowDown()"
        (keyup.enter)="onResultClick(result)"
        (keyup.arrowup)="onArrowUp()"
        (keyup.arrowdown)="onArrowDown()"
        #results
      >
        <div class="ai-answer-result__icon">
          <screeb-icon
            size="md"
            [icon]="result.icon || 'arrow-right'"
          ></screeb-icon>
        </div>
        <div>
          <p class="ai-answer-result__title">{{ result.title }}</p>
          <p class="ai-answer-result__description">
            {{ result.description }}
          </p>
        </div>
      </a>
    </ng-container>
    <div
      class="message"
      *ngIf="!commands?.length && !results?.length && query.length"
    >
      <h4>Search results</h4>
      <p>
        <screeb-icon size="sm" icon="dev-documentation"></screeb-icon>
        Sorry, no results found. <br />
        <span class="tip">You can search for something else...</span>
      </p>
    </div>
    <ul class="helper">
      <li>
        <svg viewBox="0 0 20 20">
          <path
            d="M5.41406 3.80273L1.96875 7.32324C1.83203 7.4668 1.74316 7.67188 1.74316 7.85645C1.74316 8.28027 2.03711 8.56738 2.4541 8.56738C2.65918 8.56738 2.82324 8.49902 2.95312 8.3623L4.36816 6.89941L5.28418 5.83984L5.22949 7.30273V15.8477C5.22949 16.2715 5.53027 16.5723 5.94727 16.5723C6.36426 16.5723 6.66504 16.2715 6.66504 15.8477V7.30273L6.61035 5.83984L7.5332 6.89941L8.94141 8.3623C9.07129 8.49902 9.24219 8.56738 9.44043 8.56738C9.85742 8.56738 10.1514 8.28027 10.1514 7.85645C10.1514 7.67188 10.0693 7.4668 9.92578 7.32324L6.4873 3.80273C6.18652 3.48828 5.71484 3.48145 5.41406 3.80273ZM14.3965 16.3398L17.8418 12.8057C17.9785 12.6689 18.0674 12.4639 18.0674 12.2793C18.0674 11.8555 17.7734 11.5684 17.3564 11.5684C17.1514 11.5684 16.9873 11.6367 16.8506 11.7734L15.4424 13.2295L14.5264 14.2959L14.5811 12.833V4.28809C14.5811 3.87109 14.2803 3.56348 13.8633 3.56348C13.4463 3.56348 13.1387 3.87109 13.1387 4.28809V12.833L13.1934 14.2959L12.2773 13.2295L10.8691 11.7734C10.7324 11.6367 10.5684 11.5684 10.3701 11.5684C9.95312 11.5684 9.65918 11.8555 9.65918 12.2793C9.65918 12.4639 9.74121 12.6689 9.87793 12.8057L13.3232 16.3398C13.624 16.6475 14.0957 16.6543 14.3965 16.3398Z"
          ></path>
        </svg>
        Select
      </li>
      <li>
        <svg viewBox="0 0 16 16">
          <path
            d="M5.38965 14.1667C5.81812 14.1667 6.10156 13.8767 6.10156 13.468C6.10156 13.2571 6.01587 13.0989 5.89062 12.967L4.18994 11.3125L3.02979 10.3369L4.55908 10.4028H12.7922C14.4402 10.4028 15.1389 9.65796 15.1389 8.04297V4.13403C15.1389 2.48608 14.4402 1.78735 12.7922 1.78735H9.13379C8.70532 1.78735 8.4021 2.11035 8.4021 2.50586C8.4021 2.90137 8.69873 3.22437 9.13379 3.22437H12.7593C13.4316 3.22437 13.7151 3.50781 13.7151 4.17358V7.99683C13.7151 8.67578 13.425 8.95923 12.7593 8.95923H4.55908L3.02979 9.03174L4.18994 8.04956L5.89062 6.39502C6.01587 6.26978 6.10156 6.11157 6.10156 5.89404C6.10156 5.48535 5.81812 5.19531 5.38965 5.19531C5.21167 5.19531 5.01392 5.27441 4.8689 5.41943L1.08521 9.1438C0.933594 9.28882 0.854492 9.48657 0.854492 9.68433C0.854492 9.87549 0.933594 10.0732 1.08521 10.2183L4.8689 13.9492C5.01392 14.0876 5.21167 14.1667 5.38965 14.1667Z"
          ></path>
        </svg>
        Open
      </li>
    </ul>
  </div>
</div>

<!-- transparent overlay in order to have nit modal animation on query -->
<div
  class="ai-backdrop"
  [class]="{ open: isOpen }"
  (click)="handleCancel()"
></div>
