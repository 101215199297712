<div class="org-settings">
  <header>
    <h1>Default settings & design</h1>
  </header>

  <tag-settings-with-preview
    [(tagSettings)]="org.settings"
    [org]="org"
    scope="org"
  ></tag-settings-with-preview>

  <footer>
    <button
      (click)="save()"
      [nzLoading]="loading"
      nz-button
      nzType="primary"
      nzSize="large"
      [disabled]="hasNotChanged() || isInvalid(org.settings)"
    >
      Save
    </button>
    <div class="save-error">{{ error }}</div>
  </footer>
</div>
