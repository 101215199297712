import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { Org } from "models/org.model";
import { ActivatedRoute } from "@angular/router";
import { PageComponentInterface } from "components/PageComponentInterface";
import { RoutingService } from "services/routing.service";
import { getProfilePicture, Account } from "models/account.model";
import { OrgDao } from "models/org.dao";
import { HttpErrorResponse } from "@angular/common/http";
import { SessionService } from "services/auth.service";
import { NotificationHelper } from "helpers/notification.helper";
import { EntitlementService } from "services/entitlement.service";
import { WorkspaceAccountRole } from "models/account-permissions";
import { PermissionsService } from "services/permissions.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import {
  InviteTeamMembersComponent,
  TeamMemberInvitation,
} from "components/common/invite-team-members/invite-team-members";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "page-settings-team-members",
  templateUrl: "./team-members.component.html",
  styleUrls: ["./team-members.component.scss"],
})
export class SettingsTeamMembersPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Team members";
  public name = "Settings team members";

  private obs: any = null;

  public org: Org = null;
  public orgAccounts: Account[] = [];

  public invitationLoading: boolean = false;

  public getProfilePicture = getProfilePicture;

  @ViewChild("invitationsForm") invitationsForm: InviteTeamMembersComponent;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public sessionService: SessionService,
    private orgDao: OrgDao,
    private notificationHelper: NotificationHelper,
    public entitlementService: EntitlementService,
    public permissionsService: PermissionsService,
    public featureFlaggingService: FeatureFlaggingService,
    public trackersService: TrackersService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.orgAccounts = data.orgAccounts;
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  onSubmitInvitations(invitations: TeamMemberInvitation[]) {
    if (!invitations.length) return;

    this.invitationLoading = true;

    Promise.all(
      invitations
        .filter((invitation) => Boolean(invitation.email))
        .map((invitation) =>
          this.orgDao.inviteOrgAccounts(
            this.org.id,
            invitation.email,
            invitation.role,
          ),
        ),
    )
      .then(async () => {
        this.trackersService
          .newEventTrackingBuilder("Screeb Invite Done")
          .withOrg(this.org)
          .withProps(invitations)
          .build();

        this.notificationHelper.trigger(
          "You team mates have been invited to Screeb!",
          null,
          "success",
        );

        this.invitationsForm.reset();
        this.orgAccounts = await this.orgDao.getAllOrgAccounts(this.org.id);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.trigger(
          "Something went wrong when inviting your team mates. Please try again later!",
          null,
          "error",
        );
        console.error(err.error);
      })
      .finally(() => {
        this.invitationLoading = false;
      });
  }

  public deleteMember(accountId: string) {
    this.orgDao
      .deleteOrgAccounts(this.org.id, accountId)
      .then(() => {
        this.orgAccounts = this.orgAccounts.filter(
          (assignedAccount: Account) => assignedAccount.id !== accountId,
        );
        this.notificationHelper.trigger(
          "Team member succesfully removed!",
          null,
          "success",
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      });
  }

  public onRoleChanged(account: Account, role: WorkspaceAccountRole) {
    this.orgDao
      .updateAccount(this.org.id, account.id, { role })
      .then(() => {
        this.notificationHelper.trigger(
          "Team member succesfully edited!",
          null,
          "success",
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      });
  }
}
