/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { SurveyLanguages } from "models/survey.dao.types";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";

@Component({
  selector: "checkpoint",
  templateUrl: "./Checkpoint.component.html",
  styleUrls: ["./Checkpoint.component.scss"],
})
export class CheckpointComponent implements AfterViewInit {
  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  @ViewChild(CdkOverlayOrigin) trigger!: CdkOverlayOrigin;

  @Output() onClick = new EventEmitter<any>();
  @Output() public defaultLanguageChange: EventEmitter<SurveyLanguages> =
    new EventEmitter();
  @Output() public messageURLChange: EventEmitter<void> = new EventEmitter();

  @Input() isStarting: boolean;
  @Input() defaultLanguageWarning = false;
  @Input() openOnInit = false;
  @Input() messageURL: string = "";

  ngAfterViewInit(): void {
    // Wait for page transition to be over
    setTimeout(() => {
      if (this.openOnInit) {
        this.onClick.emit(this.trigger);
      }
    }, 200);
  }

  public internalOnClick(origin: CdkOverlayOrigin) {
    this.onClick.emit(origin);
  }
}
