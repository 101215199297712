import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SurveyInteraction } from "models/survey.model";

@Component({
  selector: "tag-settings-interactions",
  templateUrl: "./tag-settings-interactions.component.html",
  styleUrls: ["./tag-settings-interactions.component.scss"],
})
export class TagSettingsInteractionsComponent {
  @Input() public interactionType: SurveyInteraction = "in_app";
  @Output() public interactionTypeChange =
    new EventEmitter<SurveyInteraction>();
}
