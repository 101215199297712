import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ChartDataset } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import {
  trendChartOptions,
  TrendIndicatorLegend,
  TrendIndicatorTheme,
  TREND_GRAPH_MAX_BUCKETS,
} from "./trend-indicator.config";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { Release } from "models/release.model";
import { Account } from "models/account.model";
import { Router } from "@angular/router";

@Component({
  selector: "survey-stats-indicators-trend-indicator",
  templateUrl: "./trend-indicator.component.html",
  styleUrls: ["./trend-indicator.component.scss"],
})
export class TrendIndicatorStatsSurveyComponent implements OnChanges {
  @ViewChild("trendChart")
  private trendChart: BaseChartDirective = null;

  // responsive: https://stackoverflow.com/questions/37621020/setting-width-and-height
  public options = trendChartOptions([], "nps", TREND_GRAPH_MAX_BUCKETS);

  @Input()
  public datasets: ChartDataset[] = [];
  @Input()
  public legend: TrendIndicatorLegend = [];
  @Input()
  public hideLegend = false;
  @Input()
  public theme: TrendIndicatorTheme;

  @Input()
  public releases: Release[] = [];

  @Input()
  public orgAccounts: Account[] = [];

  @Input()
  public orgId: string = "";

  public reversedLegend: TrendIndicatorLegend = [];

  public colors: { backgroundColor: string }[];
  constructor(
    public featureFlaggingService: FeatureFlaggingService,
    public router: Router,
  ) {}
  ngOnChanges({ datasets, legend, theme }: SimpleChanges) {
    if (
      legend &&
      (legend.previousValue !== legend.currentValue ||
        datasets.previousValue !== datasets.currentValue) &&
      legend.currentValue &&
      theme.currentValue
    ) {
      this.reversedLegend = [...legend.currentValue].reverse();
      this.colors = legend.currentValue.map(({ color }) => ({
        backgroundColor: color,
      }));

      this.options = trendChartOptions(
        legend.currentValue,
        theme.currentValue,
        datasets?.currentValue[datasets?.currentValue?.length - 1]?.data
          ?.length || TREND_GRAPH_MAX_BUCKETS,
      );
    }

    setTimeout(() => {
      [...this.reversedLegend]
        .reverse()
        .forEach(({ checked }, index) =>
          this.trendChart.hideDataset(index, !checked),
        );
    }, 1);
  }

  public onChartMouseEnter() {
    const style = getComputedStyle(document.body);
    this.colors.forEach((color, index) => {
      const computedColor = this.legend[index].hoverColor;
      if (computedColor.indexOf("--") === 0) {
        color.backgroundColor = style.getPropertyValue(computedColor);
      } else {
        color.backgroundColor = this.legend[index].hoverColor;
      }
    });
  }

  public onChartMouseLeave() {
    const style = getComputedStyle(document.body);
    this.colors.forEach((color, index) => {
      const computedColor = this.legend[index].color;
      if (computedColor.indexOf("--") === 0) {
        color.backgroundColor = style.getPropertyValue(computedColor);
      } else {
        color.backgroundColor = this.legend[index].color;
      }
    });
  }

  public onTrendLegendChange(
    legends: Array<{ label: string; value: string; checked?: boolean }>,
  ) {
    if (!this.trendChart?.hideDataset) {
      throw Error("cannot access chart visiblity options");
    }

    // refresh visibility for each dataset
    legends.forEach(
      (legend: { label: string; value: string; checked?: boolean }) => {
        // find the right dataset matching the current legend
        const index = this.datasets.findIndex(
          (dataset: ChartDataset): boolean => {
            return dataset.label === legend.value;
          },
        );

        if (index !== -1) {
          this.trendChart.hideDataset(index, !legend.checked);
        }
      },
    );
  }
}
