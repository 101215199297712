<nz-select
  nzShowSearch
  nzHasFeedback
  nzPlaceHolder="Select user property"
  [nzDisabled]="disabled"
  [ngClass]="{ invalid: invalid }"
  [ngModel]="propertyKeyId"
  (ngModelChange)="propertyKeyIdChange.emit($event)"
  [nzDropdownMatchSelectWidth]="false"
>
  <nz-option-group
    *ngFor="let source of sortedRegistryEntrySources"
    [nzLabel]="registryEntrySourceFormatted[source].title"
  >
    <nz-option
      *ngFor="let registryEntry of getPropertyKeysBySource(source)"
      [nzValue]="registryEntry.id"
      [nzLabel]="
        (registryEntry.favorite ? '⭐️ ' : '') +
        registryEntriesIdentityPropertyPaths[registryEntry.id].join('.')
      "
    >
    </nz-option>
    <nz-option
      *ngIf="
        getPropertyKeysBySource(source).length === 0 &&
        registryEntrySourceFormatted[source].targetingCategoryVisibleOnEmpty
      "
      [nzValue]="'None'"
      [nzLabel]="'None'"
      nzDisabled
    ></nz-option>
  </nz-option-group>
</nz-select>
