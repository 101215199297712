import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { deepCopy } from "utils/object";
import { TrackersService } from "services/trackers.service";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { GraphNode } from "components/builder/flow";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import { UUID } from "models/survey.dao.types";

@Component({
  selector: "survey-stats-individual-responses-range-single-question",
  templateUrl: "./range-single-question.component.html",
  styleUrls: ["./range-single-question.component.scss"],
})
export class RangeSingleQuestionComponent implements OnInit, OnDestroy {
  @Input()
  public org: Org;
  @Input()
  public survey: Survey;
  @Input()
  public node: GraphNode;

  private filtersObs$: any = null;
  private lastFilters: AnalyticsQueryResponse;

  public error: Error;
  public loading = true;
  public initialFetch = true;

  public count = 0;
  public average = 0;
  public median = 0;

  public orderAnswerByMostResponded = true;

  constructor(
    private analyticsFilterService: AnalyticsFilterService,
    private trackersService: TrackersService,
    private analyticsDao: AnalyticsDao,
  ) {}

  ngOnInit() {
    // initial fetch is done automatically
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQueryResponse) => {
        this.lastFilters = deepCopy(filters);
        this.getResponses();
      });
  }

  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  private async getResponses() {
    this.loading = true;
    this.error = null;

    try {
      const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;
      query.question_ids = [this.node.node.correlation_id];
      query.size = 0;

      // get average
      query.aggregation = [
        {
          by: "by_answer.average",
          params: {
            answer_ids: [UUID(this.node.node.correlation_id)],
          },
        },
      ];

      let result = await this.analyticsDao.search(query);
      this.average = result.aggregations?.answer?.answer?.avg?.value ?? 0;
      this.count = result.aggregations?.answer?.answer?.doc_count ?? 0;

      // get median
      // @TODO should be parallel instead of sequential
      query.aggregation = [
        {
          by: "by_answer.median",
          params: {
            answer_ids: [UUID(this.node.node.correlation_id)],
          },
        },
      ];

      result = await this.analyticsDao.search(query);
      this.median =
        result.aggregations?.answer?.answer?.median?.values["50.0"] ?? 0;
    } catch (err) {
      this.error = err;
      console.error(err);
    }

    this.initialFetch = false;
    this.loading = false;
  }

  public getMin(): number {
    const cta = this.node.node.question.call_to_action;
    if (cta.type !== "range") {
      throw new Error("unexpected cta type");
    }

    return cta.range.payload.min;
  }
  public getMax(): number {
    const cta = this.node.node.question.call_to_action;
    if (cta.type !== "range") {
      throw new Error("unexpected cta type");
    }

    return cta.range.payload.max;
  }
  public getEmoji(): string {
    const cta = this.node.node.question.call_to_action;
    if (cta.type !== "range") {
      throw new Error("unexpected cta type");
    }

    return cta.range.payload.emoji;
  }
}
