<div class="question">
  <card-type
    [questionType]="questionType"
    [letter]="letter"
    [vertical]="false"
  ></card-type>

  <sanitized-message-component
    class="question-title-text"
    [content]="description"
    [options]="{ links: true, styling: true, emojiTranscoder: true }"
  ></sanitized-message-component>

  <div class="answers-count">{{ answersCount }} responses</div>
</div>

<div nz-row [nzGutter]="24" class="gpt3" *ngIf="answersCount === 0">
  <div nz-col nzSpan="24">
    <nz-empty [nzNotFoundContent]="'No response to this question'"></nz-empty>
  </div>
</div>

<div nz-row [nzGutter]="24" class="gpt3" *ngIf="answersCount > 0">
  <div nz-col nzSpan="8">
    <div class="indicator-with-border">
      <div class="iAppear" [ngClass]="{ 'not-available': !summary }">
        <h3>Content Summary</h3>
        <p *ngIf="!!summary" [innerHTML]="summary"></p>
        <p *ngIf="!summary">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
      <div class="overlay" *ngIf="!summary">
        <button
          nz-button
          nzType="default"
          [nzLoading]="summaryLoading"
          (click)="onGPT3SummaryRequest()"
        >
          Summarise
          {{
            answersCount > maxAnswersCount
              ? "the last " + maxAnswersCount
              : answersCount
          }}
          responses
        </button>
      </div>
    </div>
  </div>
  <div nz-col nzSpan="8">
    <div class="indicator-with-border">
      <div class="iAppear" [ngClass]="{ 'not-available': !improvement }">
        <h3>Top 10 Product Improvements</h3>
        <p *ngIf="!!improvement" [innerHTML]="improvement"></p>
        <p *ngIf="!improvement">
          Lorem ipsum
          <br />
          Lorem ipsum
          <br />
          Lorem ipsum
          <br />
          Lorem ipsum
          <br />
          Lorem ipsum
          <br />
          Lorem ipsum
          <br />
          Lorem ipsum
          <br />
          Lorem ipsum
          <br />
          Lorem ipsum
          <br />
          Lorem ipsum
        </p>
      </div>
      <div class="overlay" *ngIf="!improvement">
        <button
          nz-button
          nzType="default"
          [nzLoading]="improvementLoading"
          (click)="onGPT3ImprovementRequest()"
        >
          Get improvements from
          {{
            answersCount > maxAnswersCount
              ? "the last " + maxAnswersCount
              : answersCount
          }}
          responses
        </button>
      </div>
    </div>
  </div>
  <div nz-col nzSpan="8">
    <div class="indicator-with-border">
      <div class="iAppear" [ngClass]="{ 'not-available': !category }">
        <h3>Feedback Categories</h3>
        <p *ngIf="!!category" [innerHTML]="category"></p>
        <p *ngIf="!category">
          Lorem ipsum - 27%
          <br />
          Lorem ipsum - 12%
          <br />
          Lorem ipsum - 8%
          <br />
          Lorem ipsum - 18%
          <br />
          Lorem ipsum - 6%
        </p>
      </div>
      <div class="overlay" *ngIf="!category">
        <button
          nz-button
          nzType="default"
          [nzLoading]="categoryLoading"
          (click)="onGPT3CategoryRequest()"
        >
          Categorise the
          {{
            answersCount > maxAnswersCount
              ? "the last " + maxAnswersCount
              : answersCount
          }}
          responses
        </button>
      </div>
    </div>
  </div>
</div>
