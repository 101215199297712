<div *ngIf="rule">
  <nz-tag
    *ngIf="channel.type === 'widget'"
    nzMode="checkable"
    [nzChecked]="rule.value.v_s_arr.indexOf('desktop') !== -1"
    (nzCheckedChange)="onDeviceChange(rule, 'desktop', $event)"
    >Desktop</nz-tag
  >
  <nz-tag
    nzMode="checkable"
    [nzChecked]="rule.value.v_s_arr.indexOf('mobile') !== -1"
    (nzCheckedChange)="onDeviceChange(rule, 'mobile', $event)"
    >Mobile</nz-tag
  >
  <nz-tag
    nzMode="checkable"
    [nzChecked]="rule.value.v_s_arr.indexOf('tablet') !== -1"
    (nzCheckedChange)="onDeviceChange(rule, 'tablet', $event)"
    >Tablet</nz-tag
  >
  <utils-form-error
    *ngIf="rule.value.v_s_arr.length === 0"
    [errors]="['Please select at least 1 support']"
  >
  </utils-form-error>
</div>
