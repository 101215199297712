<div class="account-provider">
  <div class="provider-title-container">
    <img
      class="provider-logo"
      src="assets/icons/providers/{{ provider.provider }}.svg"
      [alt]="provider"
    />
    <span class="provider-name">{{
      accountProviderToLabel[provider.provider]
    }}</span>
  </div>

  <span *ngIf="!isConnected" class="provider-status">Not Linked</span>
  <span *ngIf="isConnected" class="provider-status">Linked</span>

  <div class="provider-actions-container">
    <button
      *ngIf="!isConnected"
      class="link-btn"
      nz-button
      [disabled]="error || readOnly"
      [nzLoading]="loading"
      nzType="primary"
      nzSize="large"
      (click)="onConnect()"
    >
      Link
    </button>

    <button
      *ngIf="isConnected"
      class="unlink-btn"
      nz-button
      nzDanger
      [disabled]="provider.primary || error || readOnly"
      [nzLoading]="loading"
      nz-popover
      [nzPopoverTrigger]="provider.primary ? 'hover' : null"
      nzPopoverTitle="Primary account"
      [nzPopoverContent]="PrimaryAccountTemplate"
      nzPopoverPlacement="leftTop"
      nzType="default"
      nzSize="large"
      (click)="onDisconnect()"
    >
      Unlink
    </button>
  </div>

  <ng-template #PrimaryAccountTemplate>
    You can't unlink your primary account
  </ng-template>
</div>
