import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { ApplicationLoadService } from "services/app.service";
import { NotificationHelper } from "helpers/notification.helper";
import { ChannelDao } from "models/channel.dao";
import { Channel } from "models/channel.model";

@Injectable()
export class ChannelResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private applicationLoadService: ApplicationLoadService,
    private channelDao: ChannelDao,
    private notificationHelper: NotificationHelper,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Channel> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.channelDao.getById(
          route.params["org_id"],
          route.params["channel_id"],
        );
      })
      .catch((err: HttpErrorResponse) => {
        // this.notificationHelper.trigger("Failed to fetch channels", null, 'error');
        console.error(err.error);
        throw err;
      });
  }
}
