import { Component, Input, TemplateRef } from "@angular/core";

import { NzTableSortOrder } from "ng-zorro-antd/table";

import { GraphNode } from "components/builder/flow";
import { CTAType } from "models/survey.dao.types";
import { Survey } from "models/survey.model";

export type QuestionDetails = {
  _node: GraphNode;
  survey: Survey;
  node: {
    id: string;
    correlationId: string;
    letter: string;
    type: CTAType;
    text: string;
  };
  index: number;
};

@Component({
  selector: "survey-stats-individual-responses-questions-table",
  templateUrl: "./questions-table.component.html",
  styleUrls: ["./questions-table.component.scss"],
})
export class QuestionsTableComponent {
  @Input()
  nodes: QuestionDetails[] = [];
  @Input()
  responsesCount: { [key: string]: number } = {};
  @Input()
  displaySurveyTitle: boolean = false;
  @Input()
  questionBodyView: TemplateRef<any>;

  public expandedNodes: { [key: string]: boolean } = {};
  public questionOrder: NzTableSortOrder = "ascend";

  constructor() {}

  public onSortChange(colName: string, order: NzTableSortOrder) {
    const multiply = order === "ascend" ? 1 : -1;
    this.nodes.sort((a: QuestionDetails, b: QuestionDetails): number => {
      return (a.index - b.index) * multiply;
    });
  }

  public getCardType(node: GraphNode) {
    if (node.isCES) {
      return "[CES] ";
    }
    if (node.isCSAT) {
      return "[CSAT] ";
    }
    if (node.isNPS) {
      return "[NPS] ";
    }

    return "";
  }
}
