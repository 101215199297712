<nz-modal
  [(nzVisible)]="isOpen"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzClosable]="false"
  (nzOnOk)="onDuplicate()"
  (nzOnCancel)="handleClose()"
  nzAutofocus="ok"
  nzWidth="500px"
  nzClassName="duplicate-survey-modal"
>
  <ng-template #modalTitle>
    <h3>Select a Workspace</h3>
    <sanitized-message-component
      content="⚠️ Targeting rules are not duplicated for now !"
      [options]="{
        links: true,
        styling: true,
        emojiTranscoder: true,
        CR: true
      }"
    >
    </sanitized-message-component>
  </ng-template>

  <ng-template #modalContent>
    <div class="modal-content">
      <nz-select
        class="workspace-select"
        nzSize="large"
        nzShowSearch
        nzPlaceHolder="Select a workspace"
        [ngModel]="workspaceId"
        (ngModelChange)="onWorkspaceChange($event)"
        [autofocus]="false"
        (keyup.enter)="$event.preventDefault(); onDuplicate()"
      >
        <nz-option
          *ngFor="let org of orgs"
          [nzLabel]="org.name"
          [nzValue]="org.id"
        ></nz-option>
      </nz-select>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <button
      nz-button
      nzType="default"
      class="duplicate-cancel-btn"
      (click)="handleClose()"
    >
      Cancel
    </button>
    <button
      nz-button
      [class]="'duplicate-survey-btn'.concat(workspaceId ? '' : ' disabled')"
      [disabled]="!workspaceId"
      (click)="onDuplicate()"
    >
      Duplicate
    </button>
  </ng-template>
</nz-modal>
