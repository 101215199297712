import { Component, Input } from "@angular/core";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";

@Component({
  selector: "rule-date",
  templateUrl: "./rule-date.component.html",
})
export class TargetingRuleDateComponent {
  @Input()
  public rule: SurveyTargetingRule = null;

  constructor() {}
}
