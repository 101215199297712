<div>
  <nz-card class="email-template-card">
    <p class="card-title">
      <strong>Paste the code</strong> below in your email to display the first
      question of your survey to your readers
    </p>

    <p class="error" *ngIf="error">{{ error }}</p>

    <div class="installation-code-container" *ngIf="!error">
      <code
        class="installation-code"
        [highlight]="emailTemplate"
        [languages]="undefined"
      >
      </code>
      <button
        class="copy-button"
        nz-button
        nzSize="medium"
        nzType="primary"
        (click)="copy()"
      >
        Copy
      </button>
    </div>
  </nz-card>

  <iframe #preview class="email-preview"></iframe>
</div>
