<div
  class="cols indicator-container {{ imageSrc?.length ? 'have-img' : '' }}"
  [ngClass]="[size, theme].join(' ')"
>
  <div class="col col-1">
    <div *ngIf="title" class="row-1">
      <span class="title" [ngClass]="{ 'title-bold': boldTitle }">
        <sanitized-message-component
          [content]="title"
          [options]="{
            styling: true,
            emojiTranscoder: true
          }"
        ></sanitized-message-component>
      </span>
    </div>
    <nz-spin nzSimple nzSize="small" *ngIf="loading"></nz-spin>
    <ng-container *ngIf="!loading">
      <div *ngIf="value !== undefined && value !== null" class="row-2">
        <span class="value" [ngStyle]="{ color: color }">{{
          getFormattedValue()
        }}</span>
        <span
          *ngIf="diffPercent !== null && diffPercent !== undefined"
          class="diff"
          [ngClass]="getDiffColorClass()"
          [ngStyle]="{ color: color }"
        >
          <i
            [ngStyle]="{ 'background-color': color }"
            *ngIf="round(diffPercent) !== 0"
          ></i>
          <i *ngIf="round(diffPercent) === 0">=</i>

          {{ isFinite(diffPercent) ? getFormattedDiff() : "inf." }}
        </span>
        <span
          *ngIf="
            titleLink !== null &&
            titleLink !== undefined &&
            link !== null &&
            link !== undefined
          "
        >
          <a
            [ngClass]="{ 'title-link-bold': boldLink }"
            routerLink="{{ link }}"
          >
            {{ titleLink }}
          </a>
        </span>
      </div>
      <div *ngIf="value === undefined || value === null" class="row-2">
        <ng-content></ng-content>
      </div>
      <div
        *ngIf="
          diffIndustryAverage !== null && diffIndustryAverage !== undefined
        "
        class="row-2"
        class="industryAverage"
      >
        <span [ngClass]="getAverageDiffColorClass()">
          {{ getFormattedDiffIndustryAverage() }}
        </span>
        <span>compared to your industry</span>
      </div>
      <div *ngIf="isStatus()" class="row-2 channels">
        <survey-channel-status
          [channelsType]="getStatusLabel(true)"
          [running]="true"
        >
        </survey-channel-status>
        <survey-channel-status
          [channelsType]="getStatusLabel(false)"
          [running]="false"
        >
        </survey-channel-status>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!!imageSrc" class="col col-2" style="margin-left: auto">
    <img [src]="imageSrc" alt="" />
  </div>
</div>
