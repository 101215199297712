/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, Output, EventEmitter, Input, OnInit } from "@angular/core";

import { EmojiData, EmojiEvent } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import {
  formatEmojiCodePoint,
  shouldOverrideEmojiCodePoint,
} from "../../Cards/sanitized-message/emojis";

@Component({
  selector: "lateral-panel-emoji-picker-popin",
  templateUrl: "./emoji-picker-popin.component.html",
  styleUrls: ["./emoji-picker-popin.component.scss"],
})
export class LateralPanelEmojiPickerPopinComponent implements OnInit {
  constructor() {}

  @Input()
  topPosition: number;
  @Input()
  rightPosition: number;
  @Input()
  emoji?: string;
  @Input()
  canBeNull = false;

  @Output()
  onClickOutside = new EventEmitter<null>();
  @Output()
  onEmojiSelected = new EventEmitter<string>();

  public onReset() {
    this.onEmojiSelected.emit();
  }

  public ngOnInit(): void {
    this.topPosition = Math.min(
      this.topPosition,
      document.body.clientHeight - 500,
    );
  }

  public onSelected(data: EmojiEvent) {
    this.onEmojiSelected.emit(data.emoji.native);
  }

  public imageUrlFn(emoji: EmojiData | null): string {
    if (shouldOverrideEmojiCodePoint(emoji.unified)) {
      return `/assets/emojis/dark/${formatEmojiCodePoint(emoji.unified)}.svg`;
    }
  }
}
