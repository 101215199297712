import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  SurveyTargetingRule,
  SurveyTargetingRuleOperator,
  SurveyTargetingRuleType,
  SurveyTargetingRuleValue,
} from "models/survey-targeting-rule.model";

@Component({
  selector: "rule-recurrence",
  templateUrl: "./rule-recurrence.component.html",
  styleUrls: ["./rule-recurrence.component.scss"],
})
export class TargetingRuleRecurrenceComponent {
  @Input()
  public rule: SurveyTargetingRule = null;
  @Input()
  public ruleIfAnswered: SurveyTargetingRule = null;

  @Output()
  public ruleIfAnsweredCreation: EventEmitter<{
    type?: SurveyTargetingRuleType;
    operator?: SurveyTargetingRuleOperator;
    value?: SurveyTargetingRuleValue;
  }> = new EventEmitter();

  @Output()
  public ruleIfAnsweredDeletion: EventEmitter<SurveyTargetingRule> =
    new EventEmitter();

  constructor() {}

  public checkValidValue(r: SurveyTargetingRule): boolean {
    if (r.value.v_n === null || r.value.v_n === undefined) return false;
    return true;
  }

  public onRuleIfAnsweredChange(event: number): void {
    if ((event === null || event === undefined) && this.ruleIfAnswered) {
      this.ruleIfAnsweredDeletion.emit(this.ruleIfAnswered);
      return;
    }

    if (!this.ruleIfAnswered) {
      this.ruleIfAnsweredCreation.emit({
        type: "recurrence_not_answered",
        operator: null,
        value: new SurveyTargetingRuleValue().fromJson({
          v_n: event,
        }),
      });
      return;
    }
    this.ruleIfAnswered.value.v_n = event;
  }
}
