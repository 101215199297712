import { Component, OnDestroy, OnInit } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { PageComponentInterface } from "components/PageComponentInterface";
import { PermissionsService } from "services/permissions.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "page-settings",
  template: "",
})
export class SettingsPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Settings";
  public name = "settings";

  private obs: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private uiService: UIService,
    public permissionsService: PermissionsService,
  ) {}

  ngOnInit() {
    this.obs = this.route.data.subscribe((_) => {
      if (this.permissionsService.isSuperAllowed("org.update")) {
        this.router.navigate(
          ["org", this.uiService.currentOrgId, "settings", "customize"],
          {},
        );
      } else {
        this.router.navigate(
          ["org", this.uiService.currentOrgId, "settings", "install"],
          {},
        );
      }
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }
}
