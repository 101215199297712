import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { GraphNode, ScenarioGraphBuilder } from "components/builder/flow";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import { AnalyticsResponseItemResponse } from "models/analytics.model";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { LanguageWithEmoji } from "resolvers/asset-languages-countries";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { deepCopy } from "utils/object";
import { QuestionDetails } from "../individual-responses/questions-table/questions-table.component";

// const SIGNIFICANT_TEXT_COUNT = 10;

@Component({
  selector: "page-survey-stats-content-analysis",
  templateUrl: "./content-analysis.component.html",
  styleUrls: ["./content-analysis.component.scss"],
})
export class ContentAnalysisStatsSurveyPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Analyze - Content Analysis";
  public name = "Content Analysis";

  public maxAnswersCount: number = 500;

  private obs: any = null;

  public org: Org = null;
  public survey: Survey = null;
  public integrations: Integration[] = [];
  public languages: LanguageWithEmoji[] = [];
  public registryEntriesGroup: RegistryEntry[] = [];
  public nodes: QuestionDetails[] = [];
  public registryEntriesIdentityProperty: RegistryEntry[] = [];
  public registryEntriesEvent: RegistryEntry[] = [];

  public totalResponsesCount: number = 0;
  public filteredResponseCount: number = 0;
  public answersCount: object = {};

  private filtersObs$: any = null;
  public lastFilters: AnalyticsQueryResponse;

  // public error: Error;
  public loading = true;

  // public treemapData: { name: string; value: number }[] = [];

  public colorScheme = {
    domain: [
      // 10 colors
      "#5e21f1",
      /*'#6930f2',*/ "#743ff3",
      /*'#7f4ef4',*/ "#8a5df6",
      "#956cf7",
      "#a07bf8",
      "#ab8bf9",
      "#b69afa",
      "#c1a9fb",
      "#ccb8fd",
      "#d7c7fe",
      "#e2d6ff",
    ],
  };

  //   public post101Text = `Click on one of the keyword to filter your report. This ‘keyword filter’ will also be applied to other reports so you’ll be able to analyze all your data keyword by keyword.
  // <br><br>Also, use filters on top of this screen to see the most popular keywords and the longest feedback based on other responses or on visitors’ properties.`;
  //   public post101Color = "#FFF2D6";

  // public colors: ColorHelper;

  public valueFormatter = (value: number) => `${value}`;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private analyticsFilterService: AnalyticsFilterService,
    public featureFlaggingService: FeatureFlaggingService,
    private trackersService: TrackersService,
    private analyticsDao: AnalyticsDao,
    public uiService: UIService,
    public entitlementService: EntitlementService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.survey = data.survey;
      this.integrations = data.integrations;
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.languages = data.languages_and_countries.surveyLanguagesWithEmojis;
      this.registryEntriesIdentityProperty =
        data.registryEntriesIdentityProperty.filter(
          (entry: RegistryEntry) => entry.type !== "object",
        );
      this.registryEntriesEvent = data.registryEntriesEvent;

      this.resetFilters(this.survey.created_at);
      this.resetQuestionList();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }

    this.analyticsFilterService.stopScheduling();
  }

  private resetQuestionList() {
    if (!this.survey) {
      return;
    }

    // list questions with CTA -> remove simple text messages
    const flow = new ScenarioGraphBuilder(this.survey.scenario);
    const nodes = flow
      .getNodeGraph()
      .flat()
      .filter((n: GraphNode) => n.hasCTA);

    this.nodes = nodes
      .filter((q) => q.questionType === "input")
      .map((q) => this.getQuestionDetails(q))
      .filter((q) => !!q);
  }

  public getQuestionDetails(node: GraphNode): QuestionDetails {
    return {
      survey: this.survey,
      _node: node,
      node: {
        id: node.node.id,
        correlationId: node.node.correlation_id,
        type: node.questionType,
        letter: node.name,
        text: node.description,
      },
      index: node.index,
    } as QuestionDetails;
  }

  private resetFilters(defaultStartDate?: Date) {
    this.analyticsFilterService.reset(
      "response",
      this.org.id,
      [this.survey.id],
      this.survey.created_at,
      defaultStartDate,
    );
    // initial fetch is done automatically with onQueryParamsChange
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQueryResponse) => {
        this.lastFilters = deepCopy(filters);
        this.refresh();
        // setTimeout(() => this.refresh(), 0);
      });
  }

  private async refresh() {
    this.loading = true;
    // this.error = null;

    // await this.getMostSignificantWords();
    await this.getTotalResponses();
    await this.getFilteredResponses();
    await this.getAnswersCount();

    this.loading = false;
  }

  private async getTotalResponses() {
    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;
    query.filters = [];
    query.having_answer_only = true;
    query.range.start = this.org.created_at;
    query.range.end = new Date();
    query.size = 0;
    this.totalResponsesCount = (
      await this.analyticsDao.search(query)
    )?.hits.total;
  }

  private async getFilteredResponses() {
    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;
    query.having_answer_only = true;
    query.size = 0;
    this.filteredResponseCount = (
      await this.analyticsDao.search(query)
    )?.hits.total;
  }

  private async getAnswersCount() {
    if (this.nodes.length === 0) {
      return {};
    }

    const ids = this.nodes.map(
      (q: QuestionDetails) => q.node.correlationId as UUID,
    );

    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;
    query.question_ids = ids;
    query.size = 0;
    query.aggregation = [
      { by: "by_question_correlation_id", params: { answer_ids: ids } },
    ];
    query.having_answer_only = true;

    const results = await this.analyticsDao.search(query);

    this.answersCount = this.nodes.reduce((answersCount, node) => {
      return {
        ...answersCount,
        [node.node.correlationId]:
          results.aggregations?.answer.answer.answer.buckets.find(
            ({ key }) => key === node.node.correlationId,
          )?.doc_count || 0,
      };
    }, {});
  }

  // private async getMostSignificantWords() {
  //   const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;
  //   query.aggregation = [
  //     {
  //       by: "by_significant_text.raw",
  //       params: {
  //         significant_text_count: SIGNIFICANT_TEXT_COUNT,
  //       },
  //     },
  //   ];
  //   query.size = 0;

  //   try {
  //     // When we search word frequency into elasticsearch, using the "sampler" aggregator, we may get
  //     // an inaccurate doc_count (number of occurrences in the doc sample).
  //     // Instead, we shound use bg_count, an extrapolated doc count.
  //     let mostSignificantWords = AnalyticsResponse.fromJson(
  //       fakeData.mostSignificantWords
  //     );

  //     if (this.entitlementService.isAvailable("analytics_content_analysis")) {
  //       mostSignificantWords = await this.analyticsDao.search(query);

  //       if (!this.entitlementService.isAvailable("analytics_content_analysis")) {
  //         return;
  //       }
  //     }

  //     const aggregations = mostSignificantWords.aggregations;
  //     const buckets = Object.values(aggregations).reduce(
  //       (buckets: object[], agg: any): object[] => {
  //         if (!!agg.buckets) {
  //           buckets = buckets.concat(agg.buckets);
  //         }
  //         return buckets;
  //       },
  //       []
  //     );

  //     // order buckets by most significant count
  //     buckets?.sort((a: any, b: any): number => {
  //       return b.doc_count - a.doc_count;
  //     });

  //     // keep only 10 most significant words
  //     buckets.splice(SIGNIFICANT_TEXT_COUNT);

  //     this.treemapData =
  //       buckets?.map((bucket) => {
  //         return {
  //           name: bucket.key,
  //           value: bucket.doc_count,
  //         };
  //       }) || []; // prevents null value
  //   } catch (err) {
  //     this.error = err;
  //     console.error(err);
  //   }
  // }

  // public onTreeMapClick(event: { name: string; value: number }) {
  //   this.analyticsFilterService.switchFilter({
  //     type: "response",
  //     key: "raw",
  //     operator: "contains",
  //     value: event.slug,
  //   });

  //   this.trackersService
  //     .newEventTrackingBuilder("Switched filter")
  //     .withOrg(this.org)
  //     .withSurvey(this.survey)
  //     .withProps({
  //       filter_type: "repsonse",
  //       filter_key: "raw",
  //       filter_operator: "contains",
  //     })
  //     .build();
  // }

  public getQuery() {
    const query = deepCopy(this.lastFilters);

    query.sort = { field: "response.longest_answers", order: "desc" };
    query.size = 5;

    return query;
  }

  public getAnswers(response: AnalyticsResponseItemResponse) {
    return response.answers.sort(
      (a, b): number => b.toText().length - a.toText().length,
    );
  }

  public requestFeedback() {
    this.trackersService.screebSurveyStart(
      "cac7f55e-0441-42b6-96b7-26f4e99d6a4f",
    );
  }
}
