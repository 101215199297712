import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { formatNumber } from "utils/number";

@Pipe({ name: "price" })
export class PricePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(price: number | null) {
    // if (price === null) return null;
    if (price === 0) return "€0";
    return "€" + formatNumber(price, "number");
  }
}
