<div>
  <section class="link-sharing-header">
    <text-snippet-copy
      [copyText]="url"
      [url]="url"
      [qrCode]="url"
      [canOpen]="true"
      (onCopy)="onLinkCopy()"
      (onOpen)="onLinkOpened()"
      (onQRCode)="onQRCodeOpened()"
    ></text-snippet-copy>
  </section>

  <section id="parameters">
    <h2>URL Builder</h2>
    <p>
      You can add parameters to your URL to link data to the responses you’ll
      collect. They’ll be visible in our reports as properties and you’ll be
      able to use them as filters.
      <br />
      You can use variables from your emailing provider, social sharing tool or
      any other solution you may use to share this link.
    </p>
    <nz-table
      #table
      [nzData]="parameters"
      [nzHideOnSinglePage]="true"
      [nzPageSize]="100"
    >
      <thead>
        <tr>
          <th>Parameter</th>
          <th>Value</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let parameter of table.data; let i = index">
          <td nzAlign="center">
            <input
              nz-input
              type="text"
              [(ngModel)]="parameter.key"
              (ngModelChange)="
                ensureValidParameterKey(parameter); onUpdateParameter()
              "
            />
          </td>
          <td nzAlign="center">
            <input
              *ngIf="parameter.key.length > 0"
              nz-input
              type="text"
              [(ngModel)]="parameter.value"
              (ngModelChange)="onUpdateParameter()"
            />
          </td>
          <td class="column-actions">
            <a (click)="removeParameter(i)">
              <i nz-icon nzType="close"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </nz-table>

    <div class="right">
      <button
        class="survey-targeting-rule-new ant-btn-secondary"
        nz-button
        nzType="default"
        (click)="onAddParameter()"
      >
        Add a parameter
      </button>
    </div>
  </section>

  <section id="switch-multiple">
    <div class="recurring-survey-title">
      <h2>Multiple response</h2>
      <nz-switch
        [ngModel]="multiple"
        (ngModelChange)="multipleChange.emit($event)"
        [nzCheckedChildren]="checkedTemplate"
        [nzUnCheckedChildren]="unCheckedTemplate"
      ></nz-switch>
      <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
      <ng-template #unCheckedTemplate
        ><i nz-icon nzType="close"></i
      ></ng-template>
      <span
        class="help"
        nz-popover
        [nzPopoverContent]="variablesHelpTemplate"
        nzPopoverPlacement="rightTop"
      >
        <i nz-icon nzType="question-circle" nzTheme="twotone"></i>
      </span>

      <ng-template #variablesHelpTemplate>
        <div class="recurring-survey-helper">
          <p>
            By default, a user can reply to a survey only once. Activate the
            multiple responses, to allow a user to reply many times.
          </p>
        </div>
      </ng-template>
    </div>
  </section>
</div>
