/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import {
  ActionSkip,
  CTARange,
  SurveyLanguages,
  newCTASkip,
} from "models/survey.dao.types";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";

@Component({
  selector: "lateral-panel-edit-range",
  templateUrl: "./edit-range.component.html",
  styleUrls: ["./edit-range.component.scss"],
})
export class LateralPanelEditRangeComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() public language: SurveyLanguages = "en";

  @Output() errorChange = new EventEmitter<boolean>();

  public emojiPickerVisible = false;
  public emojiPickerPositionTop = 0;
  public emojiPickerPositionRight = 0;

  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    if (!["range"].includes(this.node.node.question.call_to_action.type)) {
      throw Error("unexpected action type for range");
    }

    this.validateData();
  }

  public get cta(): CTARange {
    const cta = this.node.node.question.call_to_action;
    if (!["range"].includes(cta.type)) {
      throw Error("unexpected action type for range");
    }
    return cta as CTARange;
  }

  public get skipAction(): ActionSkip {
    return (this.node.node.question.skip_action ??= newCTASkip());
  }

  /**
   * Emoji picker
   */
  public openEmojiPicker(btnElement: HTMLElement) {
    const buttonRect = btnElement.getBoundingClientRect();

    this.emojiPickerPositionTop = buttonRect.top + window.pageYOffset - 20;
    this.emojiPickerPositionRight =
      window.innerWidth - buttonRect.left + window.pageXOffset + 10;
    this.emojiPickerVisible = true;
  }

  /**
   * Data validation
   */
  public validateData() {
    const error = false;

    if (error) {
      this.errorChange.emit(true);
    } else {
      this.errorChange.emit(false);
    }
  }
}
