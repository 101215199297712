import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { autoTips } from "helpers/form-errors.helper";
import { WorkspaceAccountRole } from "models/account-permissions";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";
import { SessionService } from "services/auth.service";

export type TeamMemberInvitation = {
  email: string;
  role: WorkspaceAccountRole;
};

@Component({
  selector: "invite-team-members",
  template: `
    <form
      nz-form
      [nzAutoTips]="autoTips"
      [formGroup]="invitations"
      class="invite-form"
      (ngSubmit)="onSubmit()"
    >
      <div class="invite-lines" #inviteLines>
        <div class="invite-line" nz-row>
          <div nz-col nzSpan="12">
            <nz-form-label>Email</nz-form-label>
          </div>
          <div nz-col nzSpan="12">
            <nz-form-label>Role</nz-form-label>
          </div>
        </div>

        <ng-container>
          <ng-container
            *ngFor="let invitation of invitations.controls; let i = index"
          >
            <div
              class="invite-line"
              nz-row
              [nzGutter]="[16, 0]"
              [formGroup]="invitation"
            >
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-control>
                    <nz-input-group>
                      <input
                        id="email"
                        type="email"
                        nz-input
                        nzHasFeedback
                        formControlName="email"
                        placeholder="friend@{{ getCompanyDomain() }}"
                        (ngModelChange)="onEmailInputChange()"
                      />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="12" class="invite-role">
                <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      id="role"
                      nzHasFeedback
                      formControlName="role"
                      nzPlaceHolder="Admin, Analyst..."
                      [nzOptions]="roles"
                      [nzDropdownMatchSelectWidth]="false"
                    >
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <button
                  type="button"
                  class="delete-button"
                  nz-button
                  nzType="default"
                  nzSize="medium"
                  *ngIf="invitations.length > 1"
                  (click)="removeInvitation(i); $event.preventDefault()"
                >
                  <i nz-icon nzType="delete" nzTheme="outline"></i>
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="form-submit-error" *ngIf="!!errors?.message">
        <utils-form-error [errors]="[errors?.message]"></utils-form-error>
      </div>

      <div class="actions">
        <button
          type="submit"
          nz-button
          nzType="default"
          nzSize="large"
          [nzLoading]="loading"
        >
          <span>Send invitations</span>
          &nbsp;
          <i nz-icon nzType="mail" nzTheme="outline"></i>
        </button>
      </div>
    </form>
  `,
  styles: [
    `
      @import "../../onboarding/layout/onboarding-layout.component.scss";

      :host .invite-form {
        margin-top: 16px;
      }

      :host .invite-line {
        display: flex;
        animation: shadeAppearUp 200ms ease-in-out;
      }

      :host .invite-role {
        display: flex;
        align-items: center;
      }

      :host .delete-button {
        margin-left: 16px;
        margin-bottom: 16px;
      }

      :host .add-button {
        margin-top: 16px;
        animation: shadeAppearUp 200ms ease-in-out;
      }

      :host .actions {
        display: flex;
        justify-content: center;
        margin-top: 32px;
      }

      :host .ant-form-item {
        width: 100%;
      }

      :host ::ng-deep .ant-form-item-explain-error {
        display: none !important;
      }

      /* fuck this shit, we really have to implement our design system atomic components */
      :host ::ng-deep .ant-form-item {
        margin-bottom: 16px;
      }

      :host ::ng-deep .ant-form-item:last-child {
        margin-bottom: 0;
      }

      :host ::ng-deep .ant-input {
        padding: 17px 16px 15px 16px !important;
        border: none !important;
        box-shadow: none !important;
        background: transparent !important;
        border-radius: 24px !important;

        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);

        &::placeholder {
          color: rgba(114, 115, 135, 0.5);
        }
      }

      :host ::ng-deep .ant-select-selection-placeholder,
      :host ::ng-deep .ant-input::placeholder {
        color: rgba(114, 115, 135, 0.5);
      }

      :host ::ng-deep .ant-form-item-has-error {
        .ant-input-group,
        .ant-input-affix-wrapper,
        .ant-select {
          border-color: var(--screeb-color-red-500) !important;

          animation: shakeAnimation 500ms ease-in-out;
        }
      }

      :host ::ng-deep .form-submit-error {
        margin-bottom: 18px;
      }

      :host ::ng-deep .ant-input-group,
      :host ::ng-deep .ant-input-affix-wrapper,
      :host ::ng-deep .ant-select-single {
        background: var(--screeb-color-white) !important;
        box-shadow: 0px 2px 16px rgba(48, 49, 64, 0.1) !important;
        border-radius: 24px !important;
        border: none;
        height: 56px;
        padding-left: 0;
        padding-right: 20px;
        border: 1px solid transparent;

        transition: all 200ms;

        &:focus-within,
        &:focus,
        &:hover {
          border-color: #884dff !important;
        }
      }

      :host ::ng-deep .ant-select-focused .ant-select-selector {
        outline: none !important;
        box-shadow: none !important;
      }

      :host ::ng-deep .ant-select-selector {
        border: none !important;
        height: 100% !important;
        background: transparent !important;

        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);

        padding: 13px 16px 15px 16px !important;
      }

      :host ::ng-deep .ant-form-item {
        display: block;
      }

      :host ::ng-deep .ant-form-item-label {
        margin-bottom: 4px;

        label {
          font-family: Rubik;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          color: var(--screeb-color-body-text);
        }

        ::after {
          content: "";
        }
      }

      :host ::ng-deep .ant-form-item-explain {
        color: var(--screeb-color-red-500);
        height: 0;
        min-height: 0;
        position: absolute;
        top: -18px;
        right: 24px;

        span {
          display: none;
        }

        span:first-child {
          display: block;
        }
      }

      :host ::ng-deep .ant-select-disabled .ant-select-selector,
      :host ::ng-deep .ant-input-disabled {
        color: var(--screeb-color-body-text-secondary) !important;
      }

      :host ::ng-deep .ant-select-disabled,
      :host ::ng-deep .ant-input-group.disabled {
        opacity: 0.66;
      }

      :host ::ng-deep .ant-input-group,
      :host ::ng-deep .ant-input-affix-wrapper,
      :host ::ng-deep .ant-select-single {
        &.ant-select-disabled,
        &.disabled {
          &:focus-within,
          &:focus,
          &:hover {
            border-color: transparent !important;
          }
        }
      }
    `,
  ],
})
export class InviteTeamMembersComponent implements OnInit {
  @Input() public loading = false;
  @Input() public scope: "onboarding" | "quickstart" = "onboarding";

  @Output() public submitInvitations = new EventEmitter<
    TeamMemberInvitation[]
  >();
  @Output() public skip = new EventEmitter<never>();

  @ViewChild("inviteLines") inviteLines: ElementRef<HTMLDivElement>;

  public invitations: FormArray = null;
  public errors: any = null;

  public autoTips = autoTips;

  public roles: NzSelectOptionInterface[] = [
    { label: "Admin", value: "admin" },
    { label: "Editor", value: "editor" },
    { label: "Analyst", value: "analyst" },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
  ) {}

  ngOnInit(): void {
    this.invitations = this.formBuilder.array([
      // this.createInvitation(true),
      this.createInvitation(),
    ]);
  }

  createInvitation(required = false) {
    return this.formBuilder.group({
      email: [
        null,
        required ? [Validators.required, Validators.email] : [Validators.email],
      ],
      role: ["admin", required ? [Validators.required] : []],
    });
  }

  addInvitation() {
    this.invitations.push(this.createInvitation());
    setTimeout(() => {
      this.inviteLines.nativeElement.scrollTop =
        this.inviteLines.nativeElement.scrollHeight;
    }, 0);
  }

  removeInvitation(index: number) {
    this.invitations.removeAt(index);
  }

  onSubmit() {
    for (const control of this.invitations.controls as FormGroup[]) {
      for (const key in control.controls) {
        control.controls[key].markAsDirty();
        control.controls[key].updateValueAndValidity();
      }
    }

    if (!this.invitations.valid) return false;

    if (!this.invitations.value[0]?.email) {
      return false;
    }

    this.submitInvitations.emit(this.invitations.value);
  }

  public getCompanyDomain() {
    return this.sessionService.session.email.split("@")[1];
  }

  onEmailInputChange() {
    if (this.invitations.value.every(({ email }) => Boolean(email))) {
      this.addInvitation();
    }
  }

  public reset() {
    this.invitations.clear();
    this.addInvitation();
  }
}
