import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import {
  createSequenceFunnelFromEvents,
  Sequence,
  SequenceType,
} from "./sequence.types";
import { UUID } from "./survey.dao.types";

type SequenceDaoCreatePayload = Pick<Sequence, "type" | "funnel" | "title">;

export const adaptSequenceFromAPI = (sequence: Sequence): Sequence => ({
  ...sequence,
  created_at: new Date(sequence.created_at),
  updated_at: new Date(sequence.updated_at),
});

@Injectable()
class SequenceDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getByType(orgId: string, sequenceType?: SequenceType) {
    return this.screebApiHelper
      .get<Sequence[]>(
        `/org/${orgId}/sequence${sequenceType ? `?type=${sequenceType}` : ""}`,
      )
      .toPromise()
      .then((sequences) => sequences.map(adaptSequenceFromAPI));
  }

  public getById(orgId: string, sequenceId: UUID) {
    return this.screebApiHelper
      .get<Sequence>(`/org/${orgId}/sequence/${sequenceId}`)
      .toPromise()
      .then(adaptSequenceFromAPI);
  }

  public create(
    orgId: string,
    type: SequenceType,
    title: string,
    events: UUID[],
  ) {
    const body: SequenceDaoCreatePayload = {
      title,
      type,
      funnel: createSequenceFunnelFromEvents(events),
    };

    return this.screebApiHelper
      .post<Sequence>(`/org/${orgId}/sequence`, body)
      .toPromise();
  }

  public patch(orgId: string, sequenceId: string, sequence: Sequence) {
    return this.screebApiHelper
      .patch<Sequence>(`/org/${orgId}/sequence/${sequenceId}`, sequence)
      .toPromise();
  }

  public delete(orgId: string, sequenceId: string, remove_surveys: boolean) {
    return this.screebApiHelper
      .delete<Sequence>(`/org/${orgId}/sequence/${sequenceId}`, {
        params: { remove_surveys },
      })
      .toPromise();
  }
}

export { SequenceDao };
