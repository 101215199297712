/* eslint-disable @angular-eslint/component-class-suffix */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "editable-text",
  template: `
    <input
      *ngIf="editMode"
      type="text"
      nz-input
      #input
      [nzSize]="size"
      [placeholder]="placeholder"
      (keyup)="onInputKeyUp($event)"
      [(ngModel)]="tempValue"
    />
    <button
      *ngIf="editMode"
      title="Save"
      nz-button
      class="ant-btn-secondary"
      [nzSize]="size"
      [disabled]="!valid()"
      (click)="onSubmitClick()"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.7071 4.29289C14.0976 4.68342 14.0976 5.31658 13.7071 5.70711L7.70711 11.7071C7.51957 11.8946 7.26522 12 7 12C6.73478 12 6.48043 11.8946 6.29289 11.7071L2.29289 7.70711C1.90237 7.31658 1.90237 6.68342 2.29289 6.29289C2.68342 5.90237 3.31658 5.90237 3.70711 6.29289L7 9.58579L12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289Z"
          fill="#ffffff"
        />
      </svg>
    </button>
    <div *ngIf="!editMode">{{ value }}</div>
    <button
      *ngIf="!editMode"
      title="Edit"
      nz-button
      [nzSize]="size"
      nzType="link"
      (click)="onEditClick()"
    >
      <img nz-icon src="/assets/icons/homepage/pen-dark.svg" alt="Edit" />
    </button>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
      }

      :host .ant-btn-secondary {
        margin-left: 8px;
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class EditableTextComponent {
  @Input() public value = "";
  @Input() public placeholder = "";
  @Input() public size: "small" | "medium" | "large" = "large";

  @Output() public valueChange = new EventEmitter<string>();

  @ViewChild("input") input: ElementRef;

  public tempValue = "";
  public editMode = false;

  onEditClick() {
    this.tempValue = this.value;
    this.editMode = true;
    setTimeout(() => this.input.nativeElement.focus(), 0);
  }

  onSubmitClick() {
    if (this.valid()) {
      this.value = this.tempValue;
      this.valueChange.emit(this.tempValue);
      this.editMode = false;
    }
  }

  onInputKeyUp($event: KeyboardEvent) {
    if ($event.key === "Enter") {
      this.onSubmitClick();
    }
  }

  valid() {
    return Boolean(this.value.trim().replace(/\s/gi, "").length);
  }
}
