<div class="header-group">
  <h1>Sessions recordings</h1>
</div>

<ng-container>
  <div class="loader" *ngIf="loading && initialFetch">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>

  <error-message
    *ngIf="error"
    message="An error occured when fetching data."
    [details]="error.message"
    (reload)="analyticsFilterService.triggerChange()"
  ></error-message>

  <div class="gridAppear" *ngIf="(!loading || !initialFetch) && !error">
    <section class="content iAppear d100">
      <div class="filters-bar">
        <div class="left"></div>
        <div class="right"></div>
      </div>

      <div class="session-table-group">
        <nz-table
          class="session-table"
          #table
          [nzData]="sessions"
          nzTableLayout="fixed"
          [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex"
          [nzTotal]="maxOffset"
          [nzShowSizeChanger]="true"
          [nzFrontPagination]="false"
          [nzHideOnSinglePage]="true"
          [nzPageSizeOptions]="[10, 25, 100]"
          (nzQueryParams)="onQueryParamsChange($event)"
        >
          <thead>
            <tr>
              <th
                *ngFor="let column of listOfColumns"
                [(nzSortOrder)]="column.sortOrder"
                [nzSortFn]="column.sortFn"
                [nzSortDirections]="column.sortDirections"
                [nzFilterMultiple]="column.filterMultiple"
                [nzColumnKey]="column.sortKey"
                [nzWidth]="column.width"
                [nzAlign]="column.align"
              >
                {{ column.name }}
              </th>
            </tr>
          </thead>
          <tr *ngIf="trackingCTAVisible()" class="cta-install">
            <td colspan="5">
              <a
                nz-button
                nzSize="large"
                nzType="default"
                class="ant-btn-secondary"
                routerLink="/org/{{ org.id }}/settings/install"
                >Install Screeb SDK for feeding your sessions list.</a
              >
            </td>
          </tr>
          <tbody *ngIf="!trackingCTAVisible()">
            <ng-container *ngFor="let session of table.data">
              <!--
                HACK: using (click), we cannot right-click on a row and select "open in a new table".
                So we added a "<a>" html tag between tbody and tr.
              -->
              <a
                class="hack"
                routerLink="/org/{{ org.id }}/people/sessions/{{ session.id }}"
              >
                <tr>
                  <td nzAlign="left">
                    <div class="session-icon">
                      <screeb-icon size="md" icon="play" />
                    </div>
                  </td>
                  <td nzAlign="left" class="bold-text" nzEllipsis="true"></td>
                  <td nzAlign="center">
                    <div class="respondent">
                      <nz-spin
                        nzSimple
                        nzSize="medium"
                        *ngIf="
                          isUsersLoading &&
                          initialFetch &&
                          !users[session.respondent_id]
                        "
                      ></nz-spin>
                      <user-simple-list
                        *ngIf="
                          (!isUsersLoading && !initialFetch) ||
                          users[session.respondent_id]
                        "
                        [users]="
                          users[session.respondent_id]
                            ? [users[session.respondent_id]]
                            : []
                        "
                        userNameSize="small"
                        [shouldShowArrow]="false"
                        [registryEntriesGroup]="registryEntriesGroup"
                        [shouldShowPresence]="true"
                        [showEmptyState]="false"
                        iconSize="small"
                      >
                      </user-simple-list>
                    </div>
                  </td>
                  <td nzAlign="center">
                    {{
                      duration(session.snapshot_duration_seconds)
                        | dfnsFormatDuration: { format: ["hours", "minutes"] }
                    }}
                  </td>
                  <td nzAlign="center">
                    {{
                      session.session_start
                        | dfnsFormatDistanceToNow: { addSuffix: true }
                    }}
                  </td>
                </tr>
              </a>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
    </section>
  </div>
</ng-container>
