import { Component, OnDestroy, OnInit } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { PageComponentInterface } from "components/PageComponentInterface";
import { RoutingService } from "services/routing.service";
import {
  OrgBilling,
  OrgBillingInvoice,
  OrgBillingPaymentMethods,
  OrgBillingSubscription,
  subscriptionOptions,
} from "models/org_billing.model";
import { lateralPanelMode } from "components/super-org/billing/products";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";

@Component({
  selector: "page-settings-billing",
  templateUrl: "./billing.component.html",
  styleUrls: ["./billing.component.scss"],
})
export class SettingsBillingPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Billing";
  public name = "Settings billing";

  private obs: any = null;

  public superOrg: SuperOrg = null;
  public orgBilling: OrgBilling = null;
  public orgBillingSubscription: OrgBillingSubscription = null;
  public orgBillingPaymentMethods: OrgBillingPaymentMethods = null;
  public orgBillingInvoices: OrgBillingInvoice[] = null;
  public countries: string[][] = null;

  public tabIndex: number = 0;
  public lateralPanelOpened: boolean = false;
  public lateralPanelMode: lateralPanelMode = null;
  public lateralPanelCurrentSubscription: subscriptionOptions = null;
  public lateralPanelTargetedSubscription: subscriptionOptions = null;

  private invoiceRefreshJob: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public featureFlaggingService: FeatureFlaggingService,
    private superOrgDao: SuperOrgDao,
  ) {}

  ngOnInit() {
    if (this.route.snapshot.queryParams["tab"]) {
      this.goToTab(this.route.snapshot.queryParams["tab"]);
    }

    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.superOrg = data.superOrg;
      this.orgBilling = data.orgBilling;
      this.orgBillingSubscription = data.orgBillingSubscription;
      this.orgBillingPaymentMethods = data.orgBillingPaymentMethods;
      this.orgBillingInvoices = data.orgBillingInvoices;
      this.countries = data["languages_and_countries"]["countries"];

      this.refreshInvoicePeriodically();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }

    clearTimeout(this.invoiceRefreshJob);
  }

  public openPanel(
    mode: lateralPanelMode,
    currentSubscription: subscriptionOptions | null,
    targetedSubscription: subscriptionOptions | null,
  ) {
    this.lateralPanelMode = mode;
    this.lateralPanelCurrentSubscription = currentSubscription;
    this.lateralPanelTargetedSubscription = targetedSubscription;
    this.lateralPanelOpened = true;
  }

  public closePanel() {
    this.lateralPanelOpened = false;
  }

  public tabInvoicesIsVisible(): boolean {
    return this.orgBillingInvoices?.length > 0;
  }

  public refreshInvoice() {
    this.superOrgDao
      .getInvoicesBySuperOrgId(this.superOrg.id)
      .then((invoices: OrgBillingInvoice[]) => {
        this.orgBillingInvoices = invoices;
      })
      .catch((err) => {
        // fails silently
        console.error(err);
      });
  }

  // dirty polling
  private refreshInvoicePeriodically() {
    this.invoiceRefreshJob = setInterval(() => {
      if (this.tabIndex === 2) {
        this.refreshInvoice();
      }
    }, 2000);
  }

  public goToTab(name: "subscription" | "payment" | "invoices") {
    switch (name) {
      case "subscription":
        this.tabIndex = 0;
        break;
      case "payment":
        this.tabIndex = 1;
        break;
      case "invoices":
        this.tabIndex = 2;
        break;
    }
  }
}
