import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { PageComponentInterface } from "components/PageComponentInterface";
import {
  authors,
  Post101,
} from "components/utils/card-101-link/card-101-link.model";
import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { RegistryDao } from "models/registry.dao";
import {
  getRegistryEntriesPath,
  RegistryEntry,
  RegistryEntrySource,
  RegistryEntrySourceFormatted,
  RegistryEntryTypeFormatted,
  removeRegistryEntriesRecursivelyAndPreserveOrder,
} from "models/registry.model";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { arrayToSet } from "utils/array";
import { Debounce } from "utils/debounce";

interface ColumnItem {
  name: string;
  nzWidth: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null;
  listOfFilter: NzTableFilterList | null;
  filterFn: NzTableFilterFn | undefined;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "page-settings-registry-identity-properties-list",
  templateUrl: "./registry-identity-properties-list.component.html",
  styleUrls: ["./registry-identity-properties-list.component.scss"],
})
export class RegistryIdentityPropertiesListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "User Identity Properties";
  public name = "Settings user identity properties";

  private obs: any = null;
  public registryEntries: RegistryEntry[] = [];
  public filteredRegistryEntries: RegistryEntry[] = [];
  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;
  public registryEntryTypeFormatted = RegistryEntryTypeFormatted;
  public registryEntryPathsById: Map<string, string[]> = new Map();
  public checkedRegistryEntryIds = new Set<string>();
  public visibleRegistryEntries: RegistryEntry[] = [];

  public keyword = "";

  public listOfColumns: ColumnItem[] = [
    {
      name: "", // checkbox selection
      nzWidth: "50px",
      sortOrder: null,
      sortFn: null,
      sortDirections: null,
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Name",
      nzWidth: null,
      sortOrder: null,
      sortFn: (a: RegistryEntry, b: RegistryEntry) =>
        this.registryEntryPathsById[a.id]
          ?.join(".")
          ?.localeCompare(this.registryEntryPathsById[b.id]?.join(".")),
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Type",
      nzWidth: null,
      sortOrder: null,
      sortFn: (a: RegistryEntry, b: RegistryEntry) =>
        a.type.localeCompare(b.type),
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: (list: string[], item: RegistryEntry) =>
        list.some((name) => item.type.indexOf(name) !== -1),
    },
    {
      name: "Source",
      nzWidth: null,
      sortOrder: null,
      sortFn: (a: RegistryEntry, b: RegistryEntry) => {
        if (a.sources.length === 0 || b.sources.length === 0) {
          return a.sources.length === 0 ? -1 : 1;
        }
        const aWord = a.sources.sort((c, d) => c.localeCompare(d))[0];
        const bWord = b.sources.sort((c, d) => c.localeCompare(d))[0];
        return aWord.localeCompare(bWord);
      },
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: (list: string[], item: RegistryEntry) =>
        list.some(
          (name) => item.sources.indexOf(name as RegistryEntrySource) !== -1,
        ),
    },
    {
      name: "Created on",
      nzWidth: null,
      sortOrder: null,
      sortFn: (a: RegistryEntry, b: RegistryEntry) =>
        a.created_at.getTime() - b.created_at.getTime(),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Last update on",
      nzWidth: null,
      sortOrder: "descend",
      sortFn: (a: RegistryEntry, b: RegistryEntry) =>
        a.updated_at.getTime() - b.updated_at.getTime(),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "", // actions
      nzWidth: null,
      sortOrder: null,
      sortFn: null,
      sortDirections: null,
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
    },
  ];

  public posts101: Post101[] = [
    {
      color: "var(--screeb-color-warning-outline)",
      title: "Everything about Users Properties in Screeb",
      url: "https://help.screeb.app/en/articles/8611571-all-about-users-properties",
      author: authors["Simon Robic"],
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public featureFlagging: FeatureFlaggingService,
    private notificationHelper: NotificationHelper,
    private modalService: NzModalService,
    private registryDao: RegistryDao,
    public entitlementService: EntitlementService,
    public permissionsService: PermissionsService,
  ) {}

  @Input() public org: Org = null;

  public updateAllowed = false;
  public deleteAllowed = false;
  public updateOrDeleteAllowed = false;

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.registryEntries = data["registryEntriesIdentityProperty"];
      this.registryEntryPathsById = getRegistryEntriesPath(
        this.registryEntries,
      );

      this.initSort();
      this.initFilters();

      this.updateAllowed = this.permissionsService.isAllowed("registry.update");
      this.deleteAllowed = this.permissionsService.isAllowed("registry.delete");
      this.updateOrDeleteAllowed = this.updateAllowed || this.deleteAllowed;
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  private initSort() {
    this.registryEntries.forEach((value) => {
      if (value.sources.length > 1) {
        value.sources.sort((a, b) => a.localeCompare(b));
      }
    });
  }

  private initFilters() {
    // Type filters
    const propertyTypes = arrayToSet(this.registryEntries.map((v) => v.type));
    this.listOfColumns[1].listOfFilter = propertyTypes.map((type) => {
      const settings = RegistryEntryTypeFormatted[type];
      return { text: settings.title, value: type.toString() };
    });

    // Sources filters
    const sources = arrayToSet(
      this.registryEntries.reduce((acc: string[], p: RegistryEntry) => {
        acc = acc.concat(...p.sources);
        return acc;
      }, []),
    );
    this.listOfColumns[2].listOfFilter = sources.map((s: string) => {
      const settings = RegistryEntrySourceFormatted[s];
      return { text: settings.title, value: s };
    });

    this.filterRegistryEntries();
  }

  @Debounce(400)
  public filterRegistryEntries() {
    // @TODO: what happens to recursive properties ?
    const keywords = this.keyword.toLowerCase().split(" ");

    if (keywords.length === 0) {
      this.filteredRegistryEntries = this.registryEntries;
    } else {
      this.filteredRegistryEntries = this.registryEntries.filter((item) => {
        return !keywords.find((k) => item.slug.toLowerCase().indexOf(k) === -1);
      });
    }
  }

  public selectAll() {
    const selected = new Set<string>();
    this.visibleRegistryEntries.forEach((item: RegistryEntry) => {
      if (item.removable) {
        selected.add(item.id);
      }
    });
    this.checkedRegistryEntryIds = selected;
  }
  public unselectAll() {
    this.checkedRegistryEntryIds = new Set<string>();
  }
  public isAllSelected(): boolean {
    // find not selectd item
    return !!this.visibleRegistryEntries.find((item: RegistryEntry) => {
      return item.removable && !this.checkedRegistryEntryIds.has(item.id);
    });
  }
  public isAllUnselected(): boolean {
    // find not selectd item
    return !!this.visibleRegistryEntries.find((item: RegistryEntry) => {
      return item.removable && this.checkedRegistryEntryIds.has(item.id);
    });
  }
  public hasSelected(): boolean {
    return Array.from(this.checkedRegistryEntryIds).length > 0;
  }
  public onPageChange($event) {
    this.visibleRegistryEntries = $event;
    this.checkedRegistryEntryIds = new Set<string>();
  }

  public confirmThenRemoveSelectedEntries() {
    this.confirmThenRemoveEntries(Array.from(this.checkedRegistryEntryIds));
  }
  // @TODO: we should add a loader
  public confirmThenRemoveEntries(ids: string[]) {
    new Promise((resolve) => {
      let msgPrefix = "";
      if (ids.length === 1) {
        msgPrefix = `1 user property will be removed.<br>`;
        msgPrefix += `If one of your survey is using this property, it won't be displayed anymore. Be sure to update your rules accordingly.<br>`;
      } else if (ids.length > 1) {
        msgPrefix = `${ids.length} user properties will be removed.<br>`;
        msgPrefix += `If one of your survey is using these properties, it won't be displayed anymore. Be sure to update your rules accordingly.<br>`;
      }

      this.modalService.warning({
        nzTitle: "Do you really want to hurt me? 🎶",
        nzContent: msgPrefix + "This operation cannot be undone.",
        nzStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
        },
        nzMaskClosable: true,
        nzCloseOnNavigation: false,
        nzOkType: "default",
        nzOkDanger: true,
        nzOkText: "Confirm",
        nzCancelText: "Cancel",
        nzOnOk: () => resolve(true),
        nzOnCancel: () => resolve(false),
      });
    }).then((ok: boolean) => ok && this.removeEntries(ids));
  }
  private removeEntries(ids: string[]) {
    if (ids.length === 0) {
      return;
    }

    return this.registryDao
      .remove(this.org.id, ids)
      .then(() => {
        this.notificationHelper.trigger(
          "User properties have been removed",
          null,
          "success",
        );

        this.checkedRegistryEntryIds = new Set<string>();
        this.registryEntries = removeRegistryEntriesRecursivelyAndPreserveOrder(
          this.registryEntries,
          ids,
        );
        this.filterRegistryEntries();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Could not remove user properties",
          null,
          "error",
        );
      });
  }

  public favoriteSelectedEntries(favorite: boolean) {
    this.favorite(Array.from(this.checkedRegistryEntryIds), favorite);
  }
  public favorite(ids: string[], favorite: boolean) {
    if (ids.length === 0) {
      return;
    }

    return this.registryDao
      .updateFavorite(this.org.id, ids, favorite)
      .then(() => {
        this.notificationHelper.trigger(
          "User properties have been updated",
          null,
          "success",
        );

        this.checkedRegistryEntryIds = new Set<string>();
        this.registryEntries = this.registryEntries.map((registryEntry) => {
          if (ids.includes(registryEntry.id)) {
            registryEntry.favorite = favorite;
          }
          return registryEntry;
        });
        this.filterRegistryEntries();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Could not updated user properties",
          null,
          "error",
        );
      });
  }

  public showHideSelectedEntries(show: boolean) {
    this.showHide(Array.from(this.checkedRegistryEntryIds), show);
  }
  public showHide(ids: string[], show: boolean) {
    if (ids.length === 0) {
      return;
    }

    return this.registryDao
      .updateHidden(this.org.id, ids, !show)
      .then(() => {
        this.notificationHelper.trigger(
          "User properties have been updated",
          null,
          "success",
        );

        this.checkedRegistryEntryIds = new Set<string>();
        this.registryEntries = this.registryEntries.map((registryEntry) => {
          if (ids.includes(registryEntry.id)) {
            registryEntry.hidden = !show;
          }
          return registryEntry;
        });
        this.filterRegistryEntries();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Could not update user properties",
          null,
          "error",
        );
      });
  }
}
