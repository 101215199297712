<div
  class="InvisibleCard"
  [ngClass]="{
    ReadOnly: builderStore.readOnly,
    'is-skip-line': isSkipLine,
    'is-hovered': hovered,
    'is-even': isEven
  }"
>
  <div class="InvisibleCardLine"></div>
</div>
