<section class="content">
  <div class="header-group">
    <h1>Funnels</h1>

    <button
      *ngIf="'sequence.create' | hasPermission"
      title="Create a funnel"
      nz-button
      nzType="primary"
      nzSize="large"
      (click)="onCreateFunnelClick()"
    >
      <i
        *ngIf="
          !entitlementService.isQuotaExceeded('max_funnels', funnels.length)
        "
        nz-icon
        nzType="plus"
      ></i>
      <screeb-icon
        *ngIf="
          entitlementService.isQuotaExceeded('max_funnels', funnels.length)
        "
        size="sm"
        icon="upgrade"
      />
      <span>Create a funnel</span>
    </button>
  </div>
  <upgrade-modal
    *ngIf="upgradePopupVisible"
    [canCancel]="true"
    [withOverlay]="true"
    (closeClick)="upgradePopupVisible = false"
    [centeredModal]="true"
    [orgId]="org.id"
    upgradeType="max_funnels"
    position="absolute"
    ctaText="Upgrade Now!"
  />

  <div class="big-empty-screen" *ngIf="!funnels.length">
    <img
      class="big-screen-illustration"
      src="/assets/empty-screen/empty-screen-funnels.png"
      alt="Empty"
    />
    <div class="big-empty-screen-right">
      <div class="big-empty-screen-title">Start Analyzing Funnels Now</div>
      <div class="big-empty-screen-description">
        Analyze key funnels of your app, spot friction points and ask questions
        to your users based on their behaviour in funnels.<br />
        With Screeb's Funnels Reports, understand how you can improve your user
        experience and funnel performances.
      </div>
      <div class="big-empty-screen-actions">
        <button
          *ngIf="'sequence.create' | hasPermission"
          title="Create a funnel"
          nz-button
          nzType="primary"
          nzSize="large"
          (click)="onCreateFunnelClick()"
        >
          <i
            *ngIf="
              !entitlementService.isQuotaExceeded('max_funnels', funnels.length)
            "
            nz-icon
            nzType="plus"
          ></i>
          <screeb-icon
            *ngIf="
              entitlementService.isQuotaExceeded('max_funnels', funnels.length)
            "
            size="sm"
            icon="upgrade"
          />
          <span>Create a funnel</span>
        </button>
      </div>
    </div>
  </div>

  <div class="section-101" *ngIf="!funnels.length">
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/BMIsPf88ofE"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>

    <card-101-link
      title="How to Set Up a Funnel Report"
      description="A step by step guide to analyse and increase your funnel's performances"
      minuteRead="3"
      url="https://help.screeb.app/en/articles/6980118-how-to-set-up-a-funnel-report"
      color="rgb(255, 240, 218)"
    ></card-101-link>
  </div>

  <div class="filters-bar" *ngIf="funnels.length">
    <div class="search-bar">
      <nz-input-group
        nzSize="large"
        [nzPrefix]="prefixIcon"
        [nzSuffix]="inputClearButton"
      >
        <input
          type="text"
          nz-input
          placeholder="Search a funnel"
          [(ngModel)]="keywords"
          (ngModelChange)="onSearchChange()"
        />
      </nz-input-group>
      <ng-template #prefixIcon><i nz-icon nzType="search"></i></ng-template>
      <ng-template #inputClearButton>
        <i
          nz-icon
          class="ant-input-clear-icon"
          nzTheme="fill"
          nzType="close-circle"
          *ngIf="keywords"
          (click)="keywords = ''; onSearchChange()"
        ></i>
      </ng-template>
    </div>
  </div>

  <div class="funnel-table-group" *ngIf="funnels.length">
    <nz-table
      class="funnels-table"
      #table
      [nzData]="filteredFunnels"
      [nzHideOnSinglePage]="true"
      [nzPageSize]="100"
      [nzShowPagination]="filteredFunnels.length > 100"
      nzTableLayout="fixed"
    >
      <thead>
        <tr>
          <th
            *ngFor="let column of listOfColumns"
            [(nzSortOrder)]="column.sortOrder"
            [nzSortFn]="column.sortFn"
            [nzSortDirections]="column.sortDirections"
            [nzFilterMultiple]="column.filterMultiple"
            [nzFilters]="column.listOfFilter"
            [nzFilterFn]="column.filterFn"
            [nzWidth]="column.width + 'px'"
            [nzAlign]="column.align"
          >
            {{ column.name }}
          </th>
          <th nzWidth="150px" nzRight></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let funnel of table.data">
          <td class="bold-text">
            <a routerLink="/org/{{ org.id }}/funnel/{{ funnel.id }}/report">
              {{ funnel.title }}
            </a>
          </td>
          <td>
            <div class="updated_by">
              <org-account-avatar
                *ngIf="funnel.updated_by_account"
                [account]="funnel.updated_by_account"
                size="small"
              ></org-account-avatar>
              <div class="updated_by_time">
                {{
                  funnel.updated_at
                    | dfnsFormatDistanceToNow: { addSuffix: true }
                }}
              </div>
            </div>
          </td>
          <td>{{ funnel.funnel?.steps.length }}</td>
          <td nzAlign="right" class="actions" nzRight>
            <ng-container *ngIf="'sequence.delete' | hasPermission">
              <a
                routerLink="/org/{{ org.id }}/funnel/{{ funnel.id }}/report"
                title="Stats"
                nz-button
                class="button-create"
                nzSize="large"
                nzType="default"
                nzShape="round"
              >
                <img
                  alt="Stats"
                  nz-icon
                  src="/assets/icons/homepage/graph.svg"
                />
              </a>
              <a
                *ngIf="'sequence.update' | hasPermission"
                routerLink="/org/{{ org.id }}/funnel/{{ funnel.id }}/edit"
                title="Edit"
                nz-button
                class="button-create"
                nzSize="large"
                nzType="primary"
                nzShape="round"
              >
                <img alt="Edit" nz-icon src="/assets/icons/homepage/pen.svg" />
              </a>
              <button
                title="More"
                nz-button
                class="button-create"
                nzSize="large"
                nzType="text"
                nzShape="round"
              >
                <i
                  nz-icon
                  [nzType]="'more'"
                  class="survey-actions"
                  [ngClass]="{
                    opened: currentSequenceActionDropDown === funnel.id
                  }"
                  nz-dropdown
                  nzTrigger="click"
                  nzPlacement="bottomRight"
                  [nzDropdownMenu]="surveyActionsDropdown"
                  (nzVisibleChange)="
                    setCurrentSequenceActionDropDown(funnel.id, $event)
                  "
                ></i>
              </button>
              <nz-dropdown-menu #surveyActionsDropdown="nzDropdownMenu">
                <ul nz-menu class="sidebar-surveys-dropdown-actions-list">
                  <style>
                    .sidebar-surveys-dropdown-actions-list button {
                      margin-top: -5px;
                      margin-bottom: -5px;
                      padding: 0;
                    }
                  </style>
                  <li nz-menu-item>
                    <button
                      nz-button
                      nzType="text"
                      [nzLoading]="false"
                      (click)="onDelete(funnel)"
                    >
                      Delete
                    </button>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <upgrade-banner
    *ngIf="entitlementService.isQuotaExceeded('max_funnels', funnels.length)"
    class="upgrade-banner"
    [orgId]="org.id"
    upgradeType="max_funnels"
    CTAText="Upgrade Now!"
  />
</section>
<page-funnel-create
  *ngIf="createFunnelLateralPanelVisible"
  [org]="org"
  [registryEntriesEvent]="registryEntriesEvent"
  (closePanel)="onCreateFunnelClose()"
></page-funnel-create>
