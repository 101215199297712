import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";
import { Channel } from "models/channel.model";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { ClipboardService } from "ngx-clipboard";
import { MJMLService } from "services/mjml.service";

@Component({
  selector: "page-survey-share-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
})
export class EmailShareSurveyPageComponent implements AfterViewInit {
  @ViewChild("preview") preview: ElementRef;

  @Input() public org: Org = null;
  @Input() public channel: Channel = null;
  @Input() public survey: Survey = null;

  public emailTemplate = "";
  public error: string = null;

  constructor(
    private mjmlService: MJMLService,
    private clipboardService: ClipboardService,
    private notificationHelper: NotificationHelper,
  ) {}

  ngAfterViewInit() {
    this.mjmlService
      .compileMJML(this.org.id, this.survey.id)
      .then((template) => {
        this.emailTemplate = template;
        this.setPreview();
      })
      .catch((error) => {
        console.error(error);
        this.error = "An error occurred while generating the email template.";
      });
  }

  private setPreview() {
    const doc =
      this.preview.nativeElement.contentDocument ||
      this.preview.nativeElement.contentWindow;
    doc.open();
    doc.write(this.emailTemplate);
    doc.close();
  }

  copy() {
    this.clipboardService.copy(this.emailTemplate);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );
  }
}
