import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OrgDao } from "models/org.dao";

@Component({
  selector: "compare-plan-modal",
  template: `<nz-modal
    [(nzVisible)]="isOpen"
    [nzTitle]="null"
    [nzContent]="modalContent"
    [nzFooter]="null"
    [nzClosable]="false"
    (nzOnCancel)="handleCancel()"
    nzWidth="1280px"
    nzClassName="compare-plan-modal"
  >
    <ng-template #modalContent>
      <div class="modal-content">
        <iframe
          id="compare-plan"
          title="Compare Our Plans"
          width="100%"
          height="100%"
          src="https://screeb.app/pricing/#pricing-compare"
        ></iframe>
      </div>
    </ng-template>
  </nz-modal> `,
  styles: [
    `
      ::ng-deep #compare-plan {
        border: none;
      }

      ::ng-deep .compare-plan-modal .ant-modal-content {
        border-radius: 28px;
        width: 1280px;
        height: 720px;
      }

      ::ng-deep .compare-plan-modal .ant-modal-content .ant-modal-body,
      ::ng-deep
        .compare-plan-modal
        .ant-modal-content
        .ant-modal-body
        .modal-content {
        height: 100%;
      }
    `,
  ],
})
export class SettingComparePlanModalComponent {
  @Input() isOpen: boolean = false;
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter();

  constructor(public orgDao: OrgDao) {}

  handleCancel(): void {
    this.isOpen = false;
    this.modalOpenChange.emit(this.isOpen);
  }
}
