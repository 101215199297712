import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { FormGroup } from "@angular/forms";
import { PageComponentInterface } from "components/PageComponentInterface";
import { autoTips } from "helpers/form-errors.helper";
import { NotificationHelper } from "helpers/notification.helper";
import { AuthService } from "services/auth.service";
import { RoutingService } from "services/routing.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  template: `
    <img
      id="screeb-global-loader"
      src="/assets/loader-screeb.gif"
      title="Loader"
      alt="Loader"
    />
  `,
  selector: "page-auth0-authorize",
})
export class Auth0AuthorizePageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Login to Screeb 🚀";
  public name = "Login";

  private sub: any = null;

  public loading: boolean = false;
  public errors: any = null;

  public validateForm: FormGroup = null;
  public autoTips = autoTips;
  public passwordVisible: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private authService: AuthService,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit() {
    this.authService.authStatus = "authorizing";
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.authService
      .handleRedirectCallback()
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.trigger("Error", err.message, "error", 3600); // 1 hour
      });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}
