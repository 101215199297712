/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, Output } from "@angular/core";

import { OrgBillingCoupon } from "models/org_billing.model";
import { SuperOrgDao } from "models/super-org.dao";

@Component({
  selector: "billing-subscription-coupon-input",
  template: `
    <nz-input-group [nzSuffix]="suffixTemplate">
      <input
        type="text"
        nz-input
        [(ngModel)]="code"
        [disabled]="valid"
        (keyup.enter)="onSubmit()"
      />
    </nz-input-group>

    <ng-template #suffixTemplate>
      <button
        *ngIf="!valid"
        nz-button
        [nzLoading]="loading"
        nzType="primary"
        (click)="onSubmit()"
      >
        Add
      </button>
      <!-- <button *ngIf="!valid" nz-button nzType="link" (click)="onSubmit()">
        <i nz-icon nzType="check-circle" nzTheme="outline"></i>
      </button> -->
      <button *ngIf="valid" nz-button nzType="link" (click)="onCancel()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </button>
    </ng-template>

    <div class="form-submit-error" *ngIf="error">
      <utils-form-error [errors]="['Coupon not found.']"></utils-form-error>
    </div>
  `,
  styles: [``],
})
export class SettingsBillingSubscriptionCouponInputComponent {
  @Input() superOrgId: string;
  @Output() coupon = new EventEmitter<OrgBillingCoupon>();

  public code: string = "";
  public loading: boolean = false;
  public valid: boolean = false;
  public error: boolean = false;

  constructor(private superOrgDao: SuperOrgDao) {}

  public onSubmit() {
    this.loading = true;

    this.superOrgDao
      .getPromotionCodeByCustomerFacingCode(this.superOrgId, this.code)
      .then((coupon: OrgBillingCoupon) => {
        this.coupon.emit(coupon);
        this.valid = true;
        this.error = false;
        this.loading = false;
      })
      .catch(() => {
        this.coupon.emit(null);
        this.valid = false;
        this.error = true;
        this.loading = false;
      });
  }

  public onCancel() {
    this.coupon.emit(null);
    this.valid = false;
    this.error = false;
  }
}
