import { Component } from "@angular/core";
import { SessionService } from "services/auth.service";

export const RANDOM_MSGS = [
  "Keep calm and collect feedback.",
  "A user interface is like a joke: if you have to explain it, it's not that good.",
  "We all need people who can give us feedback. That's how we improve. — Bill Gates",
  "I just want my customers to be happy. I never really thought about business. — Martin Ohannessian, Cofounder of Le Petit Ballon",
  "The important thing is not to stop questioning. — Albert Einstein",
  'I don\'t wanna "build software" as much as build understanding and express it in software. — Jessica Joy Kerr, Software Developer & Symmathecist',
  "Problems are a feature. They’re the opportunity to see how we can productively move forward. — Seth Godin",
  "The technology that most disrupts established industries is speed. The speed of connection to peers, to suppliers and most of all, to customers. — Seth Godin",
  "It's not anyone else responsability to show us the truth. It's our responsability to find it. We do that by asking good questions. — Rob Fitzpatrick",
  "Focusing on growth can be an unhealthy obsession. Build a tight customer feedback loop instead and the growth will come. — Justin Kan, Twitch Cofounder",
  "The best product managers change their minds as they learn. — Craig Daniel, Former VP Product at Drift",
  "The thing I have noticed is when the anecdotes and the data disagree, the anecdotes are usually right. — Jeff Bezos",
  "A lot of internal debates could be quickly settled with a simple survey.",
  "Asking questions is the daily routine of great PMs. — Artabaz Shams, VP Product at Blablacar",
];

const getHelloMessage = () => {
  const hours = new Date().getHours();

  if (hours >= 18 || hours < 5) {
    return "Good evening";
  } else if (hours >= 5 && hours < 12) {
    return "Good morning";
  } else {
    return "Good afternoon";
  }
};

@Component({
  selector: "card-quote-of-the-day",
  templateUrl: "./card-quote-of-the-day.component.html",
  styleUrls: ["./card-quote-of-the-day.component.scss"],
})
export class CardQuoteOfTheDayComponent {
  private randomQuote =
    RANDOM_MSGS[new Date().getDate() % RANDOM_MSGS.length].split(" — ");

  public quote: string =
    this.randomQuote.length > 0 ? this.randomQuote[0] : null;
  public author: string =
    this.randomQuote.length > 1 ? this.randomQuote[1] : null;
  public color: string = "var(--screeb-color-white)";

  public title = getHelloMessage();

  constructor(public sessionService: SessionService) {}
}
