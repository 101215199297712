import { SimpleBucket } from "components/surveys/pages/stats/indicators/indicator.utils";
import { NpsAggregation } from "components/surveys/pages/stats/indicators/nps/nps.aggregation";
import { subDays } from "date-fns";

export const fakeNpsAggregation: NpsAggregation[] = [
  -20, 5, -2, 10, 20, 50, 30, -10, 20, 30,
]
  .reverse()
  .map((score, index) => ({
    date: subDays(new Date(), index),
    score,
    passives: 0,
    detractors: 0,
    promoters: 0,
    total: 0,
  }));

export const fakeActivity: SimpleBucket[] = [
  5, 8, 31, 22, 39, 19, 50, 27, 10, 61,
]
  .reverse()
  .map((doc_count, index) => ({
    key: "",
    key_as_string: subDays(new Date(), index).toISOString(),
    doc_count,
  }));

export const fakePlatformRepatition: SimpleBucket[] = [
  { key: "desktop", doc_count: 67 },
  { key: "mobile", doc_count: 33 },
];
