import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SurveyType } from "models/survey.model";

@Component({
  selector: "flow-tag",
  templateUrl: "./FlowTag.component.html",
  styleUrls: ["./FlowTag.component.scss"],
})
export class FlowTagComponent {
  constructor() {}

  @Input() surveyType: SurveyType = "survey";
  @Input() isFlowStarting: boolean = true;
  @Input() messageURL: string = "";
  @Output() public messageURLChange: EventEmitter<void> = new EventEmitter();

  public get label(): string {
    if (this.surveyType === "survey") {
      return this.isFlowStarting ? "Survey starts" : "Survey ends";
    }
    return this.isFlowStarting ? "Message starts" : "Message ends";
  }
}
