import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { HttpErrorResponse } from "@angular/common/http";
import { PageComponentInterface } from "components/PageComponentInterface";
import {
  Post101,
  authors,
} from "components/utils/card-101-link/card-101-link.model";
import { startOfDay, startOfMonth } from "date-fns";
import { AnalyticsDao } from "models/analytics.dao";
import {
  AnalyticsQueryUsers,
  SortForUser,
  SortOrder,
} from "models/analytics.filters.type";
import {
  AnalyticsResponse,
  AnalyticsResponseItemUser,
  getFormattedUserNameOrID,
} from "models/analytics.model";
import { Org } from "models/org.model";
import {
  RegistryEntry,
  getRegistryEntriesTopLevel,
} from "models/registry.model";
import { getUserIcon } from "models/user.model";
import {
  NzTableQueryParams,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { Debounce } from "utils/debounce";
import { deepCopy } from "utils/object";

interface ColumnItem {
  name: string;
  sortKey?: string;
  width?: string;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null | true;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "user-list-page",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
})
export class UserListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Users";
  public name = "Users";

  private obs: any = null;

  public org: Org;
  public registryEntriesIdentityProperty: RegistryEntry[] = [];
  public registryEntriesEvent: RegistryEntry[] = [];
  public registryEntriesGroup: RegistryEntry[] = [];

  protected filtersObs$: any = null;
  public lastFilters: AnalyticsQueryUsers;

  public searching = false;
  public loading = true;
  public initialFetch = true;
  public error: Error;
  public pageSize = 10;
  public pageIndex = 1;
  public total = 0;
  public maxOffset = 0;
  public dailyActive = 0;
  public monthlyActive = 0;
  public users: AnalyticsResponseItemUser[] = [];

  private sort?: SortForUser = {
    field: "respondent.last_activity_at",
    order: "desc",
  };

  listOfColumns: ColumnItem[] = [
    {
      name: "",
      width: "90px",
      align: "center",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: true,
    },
    {
      name: "Name or ID",
      width: "300px",
      align: "left",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: true,
    },
    {
      name: "First Seen",
      width: "150px",
      sortKey: "respondent.created_at",
      align: "center",
      sortOrder: "",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Last Activity",
      width: "150px",
      sortKey: "respondent.last_activity_at",
      align: "center",
      sortOrder: "ascend",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
  ];

  private skipNextParamChange = true;

  public posts101: Post101[] = [
    {
      color: "var(--screeb-color-purple-200)",
      title: "How to track user properties in Screeb with our Javascript SDK",
      url: "https://help.screeb.app/en/articles/4992511-how-to-track-user-properties-in-screeb",
      author: authors["Simon Robic"],
    },
    {
      color: "var(--screeb-color-info-outline)",
      title: "How to use our Segment integration to better identify your users",
      url: "https://help.screeb.app/en/articles/5299671-segment-integration-the-complete-guide",
      author: authors["Simon Robic"],
    },
    {
      color: "#FFF2D6",
      title:
        "How to use our Users report to better understand how your users are using your app (Video)",
      url: "https://help.screeb.app/en/articles/5658565-how-to-use-our-users-report-to-better-understand-how-your-users-are-using-your-app",
      author: authors["Simon Robic"],
    },
  ];

  public getUserIcon = getUserIcon;
  public getFormattedUserNameOrID = getFormattedUserNameOrID;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private analyticsDao: AnalyticsDao,
    public analyticsFilterService: AnalyticsFilterService,
    private trackersService: TrackersService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );
    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;

      this.registryEntriesIdentityProperty = getRegistryEntriesTopLevel(
        data.registryEntriesIdentityProperty,
      ).filter((entry: RegistryEntry) => entry.type !== "object");
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.registryEntriesEvent = data.registryEntriesEvent;
      this.resetFilters();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }

    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }

    this.analyticsFilterService.stopScheduling();
  }

  private resetFilters() {
    this.analyticsFilterService.reset(
      "respondent",
      this.org.id,
      [],
      this.org.created_at,
      this.org.created_at,
    );

    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQueryUsers) => {
        this.lastFilters = deepCopy(filters);
        this.getUsers();
      });

    this.analyticsFilterService.scheduleAutoRefresh(15);
  }

  public trackingCTAVisible(): boolean {
    if (this.org.stats.total_respondents > 0) return false;
    if (this.users.length > 0) return false;
    return true;
  }

  private getUsers() {
    this.loading = true;
    this.error = null;

    /**
     * Query 1: total number of users and list of users
     */
    const query = deepCopy(this.lastFilters) as AnalyticsQueryUsers;
    query.range.start = this.org.created_at;
    query.range.end = new Date();
    query.size = this.pageSize;
    query.offset = (this.pageIndex - 1) * this.pageSize;
    query.sort = deepCopy(this.sort);

    // reverse order when sorting by date, because we display "3 days ago" and not "2024-01-01"
    if (
      this.sort.field === "respondent.created_at" ||
      this.sort.field === "respondent.last_activity_at"
    ) {
      query.sort.order = this.sort.order === "asc" ? "desc" : "asc";
    }

    const fut1 = this.analyticsDao
      .search(query)
      .then((res: AnalyticsResponse) => {
        this.total = res?.hits?.total;
        this.maxOffset = Math.min(res?.hits?.total || 0, 10000);
        this.users = res?.hits?.respondents || [];
      })
      .catch((err: HttpErrorResponse) => {
        this.error = err;
        console.error(err);
      })
      .finally(() => {
        this.initialFetch = false;
        this.loading = false;
        this.searching = false;
      });

    /**
     * Query 2: number of users for the last 24 hours
     */
    const lastDayQuery = deepCopy(query);
    lastDayQuery.range.start = startOfDay(new Date());
    lastDayQuery.range.end = new Date();
    lastDayQuery.offset = 0;
    lastDayQuery.size = 0;

    const fut2 = this.analyticsDao
      .search(lastDayQuery)
      .then((res: AnalyticsResponse) => {
        this.dailyActive = res?.hits?.total || 0;
      });

    /**
     * Query 3: number of users for the current month
     */
    const lastMonthQuery = deepCopy(query);
    lastMonthQuery.range.start = startOfMonth(new Date());
    lastMonthQuery.range.end = new Date();
    lastMonthQuery.offset = 0;
    lastMonthQuery.size = 0;

    const fut3 = this.analyticsDao
      .search(lastMonthQuery)
      .then((res: AnalyticsResponse) => {
        this.monthlyActive = res?.hits?.total || 0;
      });

    /**
     * Wait all responses
     */
    Promise.all([fut1, fut2, fut3])
      .catch((err: HttpErrorResponse) => {
        this.error = err;
        console.error(err);
      })
      .finally(() => {
        this.initialFetch = false;
        this.loading = false;
        this.searching = false;
      });
  }

  private adaptSortDirection(value: string): SortOrder {
    switch (value) {
      case "descend":
        return "desc";
      case "ascend":
      default:
        return "asc";
    }
  }

  public onQueryParamsChange(params: NzTableQueryParams) {
    let { pageIndex } = params;
    const { pageSize } = params;
    const { key, value } = params.sort.find(({ value }) => Boolean(value));

    this.sort = {
      field: key as "respondent.last_activity_at" | "respondent.created_at",
      order: this.adaptSortDirection(value),
    };

    if (pageSize !== this.pageSize) {
      pageIndex = 1;
    }

    this.pageSize = pageSize;
    this.pageIndex = pageIndex;

    if (this.skipNextParamChange) {
      this.skipNextParamChange = false;
    } else {
      this.getUsers();
    }
  }

  public onRowClick() {
    this.trackersService
      .newEventTrackingBuilder("Respondent list row clicked")
      .withOrg(this.org)
      .withAnalyticsFilters(this.lastFilters.filters, this.lastFilters.type)
      .build();

    // this.router.navigateByUrl(`/org/${this.org.id}/people/respondent/${userId}`);
  }

  /**
   * Search
   */
  @Debounce(400)
  public onSearchChange(keyword: string) {
    // this.getUsers();
    // this.analyticsFilterService.setKeyword(keyword.trim());
    this.onSearchChangeImmediate(keyword);
  }

  // without debounce
  public onSearchChangeImmediate(keyword: string) {
    this.analyticsFilterService.setKeyword(keyword.trim());
    this.searching = true;
    this.getUsers();

    this.trackersService
      .newEventTrackingBuilder("Respondent keyword searched")
      .withOrg(this.org)
      .withProps({
        keyword: keyword.trim(),
      })
      .build();
  }
}
