<div nz-row class="page-container">
  <div nz-col [nzXs]="24" [nzMd]="12" class="column-container">
    <div class="inner-left">
      <div class="welcome-header">
        <img src="/assets/logo-purple-500.svg" alt="Screeb logo" />
        <ng-container *ngIf="org.logo">
          <span>x</span>
          <img [src]="org.logo" [alt]="org.name" />
        </ng-container>
      </div>
      <h1 class="main-title">
        <span>Well done </span>
        <span class="emphasis">{{ sessionService.session.firstname }}!</span>
        <span> You're ready to use Screeb! 🥳</span>
      </h1>
      <div class="main-description" *ngIf="!isInvited && !isFreePlan()">
        <span class="emphasis">Your 14 days free trial has just started</span>
        <span>
          on our Scale plan, the one that
          {{
            toPlural(accountRoleToLabel[sessionService.session.flags.job_title])
          }}
          choose most often.</span
        >
      </div>
      <div class="install-box" *ngIf="!isTagInstalled">
        <div class="install-logo">
          <div
            class="circle"
            style="background-color: #0c1652; transform: rotate(-10.45deg)"
          >
            <screeb-icon size="install" icon="rudderstack" />
          </div>
          <div class="circle big abs" style="background-color: #52be96">
            <screeb-icon size="install" icon="segment" />
          </div>
          <div
            class="circle right"
            style="
              background-color: var(--screeb-color-purple-500);
              transform: rotate(8.96deg);
            "
          >
            <screeb-icon size="install" icon="screeb" />
          </div>
        </div>
        <span class="install-tip dark">Install Screeb in your app</span>
        <span class="install-tip light">
          Install Screeb in your app in less than 2 minutes
        </span>
        <button
          class="install-btn"
          title="Install now"
          nz-button
          nzType="primary"
          nzSize="large"
          (click)="onInstall()"
        >
          <screeb-icon size="sm" icon="dev-documentation" />
          <span>Install now</span>
        </button>
      </div>
      <intercom-button
        class="ask-question-btn"
        title="Any question before starting? Chat with us!"
      />

      <span class="not-ready-btn"
        ><span *ngIf="!isTagInstalled">Not ready? </span>
        <a (click)="onSkip()">Skip this step</a></span
      >
    </div>
  </div>

  <div class="or-circle">OR</div>

  <div nz-col [nzXs]="24" [nzMd]="12" class="column-container column-right">
    <div class="inner-right">
      <onboarding-setup-discovery
        [org]="org"
        [jobTitle]="sessionService.session.flags.job_title"
        [goal]="sessionService.session.flags.goal"
      />
    </div>
  </div>
</div>
