import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { endOfDay, format, startOfDay, subDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import {
  AnalyticsFilter,
  AnalyticsFilterForResponse,
  AnalyticsFilterForUser,
  AnalyticsFilterOperator,
  AnalyticsFilters,
  AnalyticsFiltersOperator,
  AnalyticsIndex,
  AnalyticsQuery,
  GenericAnalyticsQuery,
  isAnalyticsFilterFor,
} from "models/analytics.filters.type";
import { UUID } from "models/survey.dao.types";
import { UserDao } from "models/user.dao";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { deepCopy, deepEqual } from "utils/object";
import { EntitlementService } from "./entitlement.service";
import { UIService } from "./ui.service";

export const analyticsQueryOperatorSettings: {
  [Key in AnalyticsFilterOperator["operator"]]: {
    label: string | ((filterType: AnalyticsFilter["type"]) => string);
    value: Key;
    filterCanHaveValue: boolean;
    filterCanHaveValues: boolean;
  };
} = {
  eq: {
    label: (filterType: AnalyticsFilter["type"]) =>
      filterType === "respondent.event" ? "Has been triggered exactly" : "is",
    value: "eq",
    filterCanHaveValue: true,
    filterCanHaveValues: false,
  },
  /*neq: {
    label: 'is not',
    value: 'neq',
    filterCanHaveValue: true,
    filterCanHaveValues: false
  },*/
  in: {
    label: "is",
    value: "in",
    filterCanHaveValue: false,
    filterCanHaveValues: true,
  },
  not_in: {
    label: "is not",
    value: "not_in",
    filterCanHaveValue: false,
    filterCanHaveValues: true,
  },

  not_null: {
    label: (filterType: AnalyticsFilter["type"]) =>
      filterType === "respondent.event"
        ? "Has already been triggered"
        : "exists",
    value: "not_null",
    filterCanHaveValue: false,
    filterCanHaveValues: false,
  },
  null: {
    label: "does not exist",
    value: "null",
    filterCanHaveValue: false,
    filterCanHaveValues: false,
  },

  contains: {
    label: "contains",
    value: "contains",
    filterCanHaveValue: true,
    filterCanHaveValues: false,
  },
  /*not_contains: {
    label: 'does not contain',
    value: 'not_contains',
    filterCanHaveValue: true,
    filterCanHaveValues: false
  },*/

  gt: {
    label: (filterType: AnalyticsFilter["type"]) =>
      filterType === "respondent.event"
        ? "Has been triggered more than"
        : "greater than",
    value: "gt",
    filterCanHaveValue: true,
    filterCanHaveValues: false,
  },
  lt: {
    label: (filterType: AnalyticsFilter["type"]) =>
      filterType === "respondent.event"
        ? "Has been triggered less than"
        : "less than",
    value: "lt",
    filterCanHaveValue: true,
    filterCanHaveValues: false,
  },

  before: {
    label: "Before",
    value: "before",
    filterCanHaveValue: true,
    filterCanHaveValues: false,
  },
  after: {
    label: "After",
    value: "after",
    filterCanHaveValue: true,
    filterCanHaveValues: false,
  },

  // never sent to server (transformed into "in" operator)
  // those operators accept both "value" and "values" fields because we select a "value", but only "values" is sent to server
  "preset.gt": {
    label: "greater than",
    value: "preset.gt",
    filterCanHaveValue: true,
    filterCanHaveValues: false,
  },
  "preset.lt": {
    label: "less than",
    value: "preset.lt",
    filterCanHaveValue: true,
    filterCanHaveValues: false,
  },
};

export type AnalyticsFiltersUrl = {
  o: AnalyticsFiltersOperator; // filters operator
  i: boolean; // identified_only
  f: {
    t?: string; // type
    k?: string; // key
    o: AnalyticsFilterOperator; // operator
    v?: string | number; // value
    w?: (string | number)[]; // values
    a?: string[]; // action_correlation_ids
  }[];
};

type AnalyticsFiltersFromUrl = {
  filters: AnalyticsFilters;
  operator: AnalyticsFiltersOperator;
  identified_only?: boolean;
};

export const filtersToUrlFilters = (
  filtersOperator: AnalyticsFiltersOperator,
  filters: AnalyticsFilters,
  identified_only: boolean,
): AnalyticsFiltersUrl => ({
  o: filtersOperator,
  i: identified_only ?? undefined,
  f: filters.map(
    ({ key: k, type, operator, value, values, action_correlation_ids }) => ({
      k,
      t: type ?? undefined,
      o: operator ?? undefined,
      v: value ?? undefined,
      w: values ?? undefined,
      a: action_correlation_ids ?? undefined,
    }),
  ),
});

// @TODO: io-ts here
export const urlFiltersToFilters = ({
  o,
  f,
  i,
}: AnalyticsFiltersUrl): AnalyticsFiltersFromUrl => ({
  operator: (o as AnalyticsFiltersOperator) || "AND",
  identified_only: i,
  filters: f
    ? f.map(
        ({
          k: key,
          t: type,
          o: operator,
          v: value,
          w: values,
          a: action_correlation_ids,
        }) =>
          ({
            type,
            key,
            operator,
            value,
            values,
            action_correlation_ids,
          }) as any,
      )
    : [],
});

@Injectable()
export class AnalyticsFilterService {
  private onChange$: BehaviorSubject<AnalyticsQuery>;
  private filters: AnalyticsQuery;
  private refreshInterval: any = null;

  public minDate: Date;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private entitlementService: EntitlementService,
    private uiService: UIService,
    private userDao: UserDao,
  ) {
    this.onChange$ = new BehaviorSubject<AnalyticsQuery>(undefined);
  }

  private updateUrlParams(
    queryParams: { from: string; to: string } | { filters: string },
  ) {
    // Replace query params without triggering page reload
    this.location.go(
      this.router
        .createUrlTree([], {
          relativeTo: this.route,
          queryParamsHandling: "merge",
          queryParams,
        })
        .toString(),
    );
  }

  private updateUrlDateRange() {
    this.updateUrlParams({
      from: format(this.filters.range.start, "yyyy-LL-dd"),
      to: format(this.filters.range.end, "yyyy-LL-dd"),
    });
  }

  private updateUrlFilters() {
    this.updateUrlParams({
      filters: JSON.stringify(
        filtersToUrlFilters(
          this.filters.filters_bool,
          this.filters.filters,
          this.filters.type === "respondent" && this.filters.identified_only,
        ),
      ),
    });
  }

  public getInitialFilters(
    type: AnalyticsIndex,
    orgId: UUID,
    operator: AnalyticsFiltersOperator,
    surveyIds: UUID[],
    from: Date,
    to?: Date,
    identifiedOnly?: boolean,
  ): AnalyticsQuery {
    const baseFilters: GenericAnalyticsQuery = {
      org_id: orgId,
      survey_ids: surveyIds,
      filters_bool: operator,
    };
    const baseRange = {
      start: startOfDay(from ? new Date(from) : subDays(new Date(), 6)),
      end: endOfDay(to ? new Date(to) : new Date()),
    };

    switch (type) {
      case "response":
        return {
          ...baseFilters,
          type,
          filters: [],
          range: {
            ...baseRange,
            field: "created_at",
          },
          having_answer_only: false,
        };

      case "respondent":
        return {
          ...baseFilters,
          type,
          filters: [],
          keyword: "",
          identified_only: identifiedOnly,
          range: {
            ...baseRange,
            field: "last_activity_at",
          },
        };
    }
  }

  public reset(
    type: AnalyticsIndex,
    orgId: string,
    surveyIds: string[],
    minDate?: Date,
    defaultStartDate?: Date,
  ) {
    const oneWeekAgo = subDays(new Date(), 6);

    const fromParam = this.route.snapshot.queryParamMap.get("from");
    const toParam = this.route.snapshot.queryParamMap.get("to");
    const filtersParam = this.route.snapshot.queryParamMap.get("filters");

    const from = fromParam
      ? utcToZonedTime(new Date(fromParam), "UTC")
      : defaultStartDate ?? oneWeekAgo;
    const to = toParam ? utcToZonedTime(new Date(toParam), "UTC") : new Date();
    const { filters, operator, identified_only }: AnalyticsFiltersFromUrl =
      filtersParam
        ? urlFiltersToFilters(JSON.parse(filtersParam))
        : { filters: [], operator: "AND" };

    this.filters = this.getInitialFilters(
      type,
      UUID(orgId),
      operator,
      surveyIds.map(UUID),
      from,
      to,
      identified_only,
    );

    this.filters.filters = filters;

    this.minDate =
      startOfDay(minDate) ||
      startOfDay(from) ||
      startOfDay(this.uiService.currentOrg?.created_at);

    this.stopScheduling();
    this.triggerChange();
  }

  public setDateRange(start: Date, end: Date) {
    this.filters.range.start = start;
    this.filters.range.end = end;
    this.updateUrlDateRange();
    this.triggerChange();
  }

  public setKeyword(keyword: string) {
    switch (this.filters.type) {
      case "response":
        throw Error("could not set keyword filter on response");
      case "respondent":
        this.filters.keyword = keyword.trim();
        break;
    }

    // @TODO
    // this.updateUrlKeyword();
    this.triggerChange();
  }

  public setIdentifiedOnly(identifiedOnly: boolean) {
    switch (this.filters.type) {
      case "response":
        throw Error("could not set identifiedOnly filter on response");
      case "respondent":
        this.filters.identified_only = identifiedOnly;
        break;
    }

    this.updateUrlFilters();
    this.triggerChange();
  }

  public setFilters(
    operator: AnalyticsFiltersOperator,
    filters: AnalyticsFilters,
  ) {
    this.filters.filters_bool = operator;
    this.filters.filters = filters;
    this.updateUrlFilters();
    this.triggerChange();
  }

  public addFilter(filter: AnalyticsFilter) {
    switch (this.filters.type) {
      case "response":
        if (isAnalyticsFilterFor<AnalyticsFilterForResponse>(filter)) {
          this.filters.filters.push(filter);
        }
        break;
      case "respondent":
        if (isAnalyticsFilterFor<AnalyticsFilterForUser>(filter)) {
          this.filters.filters.push(filter);
        }
        break;
    }

    this.updateUrlFilters();
    this.triggerChange();
  }

  public filterExists(filterToSearch: AnalyticsFilter): boolean {
    return (
      this.filters &&
      this.filters.filters.some((filter) => deepEqual(filter, filterToSearch))
    );
  }

  public findFilterIndex(filterToSearch: AnalyticsFilter): number {
    return this.filters.filters.findIndex((filter) =>
      deepEqual(filter, filterToSearch),
    );
  }

  private removeFilterByIndex(index: number) {
    if (index >= 0 && index < this.filters.filters.length) {
      this.filters.filters.splice(index, 1);
      this.updateUrlFilters();
      this.triggerChange();
    }
  }

  public removeFilter(filter: AnalyticsFilter) {
    this.removeFilterByIndex(this.findFilterIndex(filter));
  }

  public switchFilter(filter: AnalyticsFilter) {
    if (this.filterExists(filter)) {
      this.removeFilter(filter);
    } else {
      this.addFilter(filter);
    }

    this.updateUrlFilters();
  }

  public setSelectedUserGroups(segments: string[]) {
    if (this.filters.type === "respondent") {
      this.filters.filters = this.filters.filters.filter(
        (filter) =>
          !(
            filter.type === "respondent" &&
            filter.key === "segment" &&
            (filter.operator === "in" || filter.operator === "not_in")
          ),
      );

      if (segments.length) {
        this.filters.filters.push({
          type: "respondent",
          key: "segment",
          operator: "in",
          values: segments,
        });
      }

      this.updateUrlFilters();
      this.triggerChange();
    }
  }

  public scheduleAutoRefresh(seconds: number) {
    this.stopScheduling();
    this.refreshInterval = setInterval(() => {
      // disable refresh if range end is before current datetime
      if (this.filters.range.end > new Date()) {
        this.updateUrlDateRange();
        this.triggerChange();
      }
    }, seconds * 1000);
  }

  public stopScheduling() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  public triggerChange() {
    this.onChange$.next(this.filters);
  }

  public get() {
    return this.filters;
  }

  public subscribe() {
    return this.onChange$.asObservable().pipe(map((data) => deepCopy(data)));
  }

  private async fetchOrgRespondents(orgId: string, overview: boolean = false) {
    const users = await this.userDao.searchUsers(
      {
        type: "respondent",
        org_id: UUID(orgId),
        survey_ids: [],
        filters_bool: "AND",
        filters: [],
        range: {
          field: "last_activity_at",
          start: new Date("2019-01-01T00:00:00Z"),
          end: new Date(),
        },
      },
      overview,
    );

    return users;
  }

  public async workspaceHasUsers(
    orgId: string,
    overview: boolean = false,
  ): Promise<boolean> {
    for (let index = 0; index < 5; index++) {
      const users = await this.fetchOrgRespondents(orgId, overview);

      if (users.hits.total !== 0) {
        return true;
      }

      await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    return false;
  }
}
