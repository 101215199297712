/* eslint-disable @angular-eslint/no-output-on-prefix */
import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";

import { NotificationHelper } from "helpers/notification.helper";
import {
  IntegrationAtlassianProject,
  IntegrationDao,
} from "models/integration.dao";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { TrackersService } from "services/trackers.service";

@Component({
  selector: "share-lateral-panel-atlassian",
  templateUrl: "./share-via-atlassian.component.html",
  styleUrls: ["./share-via-atlassian.component.scss"],
})
export class AtlassianShareLateralPanelComponent implements OnInit, OnDestroy {
  @Input() org: Org;
  @Input() survey: Survey;
  @Input() atlassianProjectId: string;
  @Input() integrations: Integration[];
  @Input() enabled: boolean;

  @Output() atlassianProjectIdChange = new EventEmitter<string>();
  @Output() enabledChange = new EventEmitter<boolean>();
  @Output() validChange = new EventEmitter<boolean>();

  public quotaOk: boolean;

  public projectOptions: NzSelectOptionInterface[] = [];

  public projects: IntegrationAtlassianProject[] = [];
  public project: IntegrationAtlassianProject;
  public projectId: string = null;
  public projectError: boolean = false;

  public creating: boolean = false;
  public replaying: boolean = false;
  public fetchingProject: boolean = false;

  public integrationAtlassian?: Integration;
  public integrationAtlassianStatus?:
    | "not_installed"
    | "not_authed"
    | "loading"
    | "ok"
    | "error";

  private obs: any;

  constructor(
    private route: ActivatedRoute,
    private integrationDao: IntegrationDao,
    private entitlementService: EntitlementService,
    private notificationHelper: NotificationHelper,
    public featureFlaggingService: FeatureFlaggingService,
    private trackersService: TrackersService,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.projects = data.integrationAtlassianProjects;
    });

    this.quotaOk = this.entitlementService.isIntegrationAvailable("atlassian");

    this.integrationAtlassian = this.integrations.find(
      ({ type }) => type === "atlassian",
    );

    this.fetchProjects().then(() => this.isAtlassianValid());

    if (this.atlassianProjectId) {
      this.fetchProject(this.atlassianProjectId).then(() =>
        this.isAtlassianValid(),
      );
    }
  }

  ngOnDestroy(): void {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public async fetchProjects() {
    if (!this.integrationAtlassian) {
      this.integrationAtlassianStatus = "not_installed";
      return;
    }

    if (!this.integrationAtlassian.auth_ok) {
      this.integrationAtlassianStatus = "not_authed";
      return;
    }

    this.integrationAtlassianStatus = "ok";
    this.projectOptions = this.projects.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }

  public fetchProject(projectId: string) {
    this.fetchingProject = true;
    this.projectError = false;

    return this.integrationDao
      .atlassianGetProject(this.org.id, projectId)
      .then((data) => {
        this.fetchingProject = false;
        this.projectError = false;

        this.projectId = data.id;
        this.project = data;
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to synchronize survey",
          null,
          "error",
        );

        this.projectError = true;
        this.fetchingProject = false;
      });
  }

  public syncProject() {
    this.creating = true;

    return this.integrationDao
      .atlassianSyncProject(this.org.id, this.projectId, this.survey.id)
      .then(() => {
        this.creating = false;
        this.projectError = false;

        this.project = this.projects.find(({ id }) => id === this.projectId);
        this.atlassianProjectId = this.projectId;

        this.atlassianProjectIdChange.emit(this.projectId);

        this.trackersService
          .newEventTrackingBuilder("Integration Atlassian project updated")
          .withOrg(this.org)
          .withSurvey(this.survey)
          .withProps({
            atlassian_project_id: this.projectId,
          })
          .build();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.creating = false;
      })
      .then(() => this.isAtlassianValid());
  }

  public remove() {
    this.projectId = null;
    this.atlassianProjectId = null;
  }

  public isAtlassianValid() {
    let valid = true;

    if (this.enabled) {
      valid = Boolean(this.atlassianProjectId) && Boolean(this.projectId);
    }

    this.validChange.emit(valid);
    return valid;
  }

  public onEnabledChange(enabled: boolean) {
    this.enabledChange.emit(enabled);
    this.isAtlassianValid();
  }

  public triggerReplay() {
    if (this.isAtlassianValid()) {
      this.replaying = true;

      this.integrationDao
        .triggerHookReplay(
          this.org.id,
          "atlassian",
          "response.ended",
          [this.survey.id],
          null,
          {
            atlassian_cloud_id:
              this.integrationAtlassian.settings.atlassian.cloud_id,
            atlassian_project_id: this.atlassianProjectId,
          },
        )
        .then(() => {
          this.notificationHelper.trigger("That's all folks!", null, "success");
        })
        .catch((err: HttpErrorResponse) => {
          console.error(err);

          this.notificationHelper.trigger(
            err?.error?.message ?? "Failed to replay responses",
            null,
            "error",
          );
        })
        .then(() => {
          this.replaying = false;
        });
    }
  }
}
