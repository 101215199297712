<ul class="stats-list iAppear" *ngIf="userPropertiesWithoutObjects.length">
  <li class="stat-item" *ngFor="let property of userPropertiesWithoutObjects">
    <div class="stat-item-header">
      <square-icon [icon]="property.icon"></square-icon>
      <div class="stat-item-data">
        <div class="stat-item-title">
          <span>{{ property.key }}</span>
          <tag-registry-entry-source
            [sources]="property.sources"
            size="small"
          ></tag-registry-entry-source>
        </div>
        <div
          class="stat-item-value"
          *ngIf="property.type === 'time'"
          [title]="property.value | toLocaleString"
        >
          {{ property.value | dfnsFormatDistanceToNow: { addSuffix: true } }}
        </div>
        <div class="stat-item-value" *ngIf="property.type !== 'time'">
          {{
            property.value !== null && property.value !== undefined
              ? property.value
              : "N/C"
          }}
        </div>
      </div>
      <div class="stat-item-title-count" *ngIf="property.count">
        {{ formatNumber(property.count, "number", 1) }}
      </div>
    </div>
  </li>
</ul>
<nz-empty
  *ngIf="!userPropertiesWithoutObjects.length"
  [nzNotFoundContent]="contentTpl"
>
  <ng-template #contentTpl>
    <div>No properties</div>
    <br />
    <a
      target="_blank"
      href="https://help.screeb.app/en/articles/4992511-how-to-track-user-properties-in-screeb"
    >
      <i nz-icon nzType="info-circle"></i>
      <span> How to add user properties in Screeb</span>
    </a>
  </ng-template>
</nz-empty>
