import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import { ActionSkip, CTAInput, newCTASkip } from "models/survey.dao.types";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";

@Component({
  selector: "lateral-panel-edit-input",
  templateUrl: "./edit-input.component.html",
  styleUrls: ["./edit-input.component.scss"],
})
export class LateralPanelEditInputComponent implements OnInit {
  @Input() node: GraphNode = null;

  @Output() errorChange = new EventEmitter<boolean>();

  constructor(
    public builderStore: BuilderStore,
    public entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    this.validateData();
  }

  public get cta(): CTAInput {
    const cta = this.node.node.question.call_to_action;
    if (cta.type !== "input") throw Error("unexpected action type for input");
    return cta;
  }

  public get skipAction(): ActionSkip {
    return (this.node.node.question.skip_action ??= newCTASkip());
  }

  /**
   * Data validation
   */
  public validateData() {
    this.errorChange.emit(false);
  }
}
