import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { format } from "date-fns";
import { NotificationHelper } from "helpers/notification.helper";
import { Account } from "models/account.model";
import { Org } from "models/org.model";
import { ReleaseDao } from "models/release.dao";
import { getReleasedBy, Release } from "models/release.model";
import { NzModalService } from "ng-zorro-antd/modal";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { groupBy } from "utils/array";
import { uuidv4 } from "utils/uuid";

const fakeReleases: Release[] = [
  new Date(2023, 1, 15),
  new Date(2023, 1, 18),
  new Date(2023, 1, 23),
  new Date(2023, 2, 1),
  new Date(2023, 2, 10),
  new Date(2023, 2, 15),
  new Date(2023, 2, 20),
  new Date(2023, 2, 27),
  new Date(2023, 3, 7),
  new Date(2023, 3, 12),
  new Date(2023, 3, 13),
  new Date(2023, 3, 14),
].map(
  (date) =>
    new Release(
      uuidv4(),
      "org_id",
      undefined,
      "Update Number 4",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et ipsum ipsum. Quisque pulvinar rhoncus ex non venenatis. Nulla facilisi. Aenean sollicitudin odio ipsum, sed viverra mi tincidunt id. In tempus et nunc ac tristique. In turpis purus, sagittis vitae facilisis at, consequat sit amet dui.",
      "0.0.1",
      ["Admin", "Feedback", "Turbo"],
      date,
      undefined,
      undefined,
    ),
);

type ReleasePeriod = {
  month: number;
  year: number;
  releases: Release[];
};

@Component({
  selector: "page-release-list",
  templateUrl: "./release-list.component.html",
  styleUrls: ["./release-list.component.scss"],
})
export class ReleaseListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy, AfterViewInit
{
  public title = "Releases";
  public name = "Release list";

  private obs: any = null;
  public releases: Release[] = [];
  public sortedReleases: ReleasePeriod[] = [];
  public filteredReleases: ReleasePeriod[] = [];
  public tagList: string[] = [];
  public selectedTagFilters: string[] = [];
  public selectedRelease: Release = null;
  private orgAccounts: Account[] = [];

  public modalOpened = false;
  public apiModalOpened = false;
  public showOnboarding = false;

  public isFakeDataDisplayed = false;

  public isUpgradeModalVisible = false;
  public keywords = "";
  public ready = false;

  private fromCursor: Date = null;
  private size = 25;
  private isLast = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private modal: NzModalService,
    private notificationHelper: NotificationHelper,
    private releaseDao: ReleaseDao,
    public permissionsService: PermissionsService,
  ) {}

  @Input() public org: Org = null;

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
      this.releases;
      this.orgAccounts = data["orgAccounts"];

      this.fromCursor = new Date();

      const intersect = (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            if (this.releases.length > 0) {
              this.fromCursor =
                this.releases[this.releases.length - 1].released_at;
            }
            this.fetchMoreReleases();
          }
        }
      };
      const container = document.querySelector("#timeline-footer");
      const observer = new IntersectionObserver(intersect, {
        root: null, // intersect with viewport
        rootMargin: "0px", // no margin when computing intersections
        threshold: 1.0, // execute callback when every pixel is visible
      });
      observer.observe(container);
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.ready = true;
  }

  private fetchMoreReleases() {
    if (this.isLast) {
      return;
    }

    this.releaseDao
      .getPaginatedByOrgId(this.org.id, this.fromCursor, this.size)
      .then((releases) => {
        this.releases = [...this.releases, ...releases];

        if (releases.length < this.size) {
          this.isLast = true;
        }

        if (this.releases.length === 0) {
          console.log("No releases found, displaying fake data");

          this.releases = fakeReleases;
          this.showOnboarding = true;
          this.isFakeDataDisplayed = true;
        } else {
          this.showOnboarding = false;
          this.isFakeDataDisplayed = false;
        }

        this.sortReleases();
        this.refreshReleasesTagList();
        this.refreshFilteredReleases();
      });
  }

  public onOnboardingClose() {
    this.showOnboarding = false;

    // Open Create Modal with no defined release to toggle create mode
    this.selectedRelease = null;
    this.modalOpened = true;
  }

  public setAPIModalOpened(opened: boolean) {
    this.apiModalOpened = opened;
  }

  public refreshFilteredReleases() {
    if (this.selectedTagFilters.length === 0) {
      this.filteredReleases = this.sortedReleases;
      return;
    }

    this.filteredReleases = this.sortedReleases
      .map((releasePeriod) => ({
        ...releasePeriod,
        releases: releasePeriod.releases.filter((release) =>
          release.tags.some((tag) => this.selectedTagFilters.includes(tag)),
        ),
      }))
      .filter(
        (releasePeriod: ReleasePeriod) => releasePeriod.releases.length > 0,
      );
  }

  public setModalOpened(opened: boolean, releaseTarget: Release | null = null) {
    if (releaseTarget !== null) {
      this.selectedRelease = new Release().fromJson(releaseTarget);
    } else {
      this.selectedRelease = null;
      if (this.isFakeDataDisplayed || this.releases.length === 0) {
        this.showOnboarding = true;
      }
    }
    this.modalOpened = opened;
  }

  getFormattedReleaseDate(releaseDate: Date) {
    return format(releaseDate, "eeee, d MMMM y - h:m a");
  }

  getFormattedReleasePeriod(releasePeriod: ReleasePeriod) {
    const date = new Date(releasePeriod.year, releasePeriod.month);

    return date.toLocaleString("en-US", { month: "long", year: "numeric" });
  }

  getReleasedBy(authorId?: string) {
    return getReleasedBy(this.orgAccounts, authorId);
  }

  getAuthorProfilePicture(accountId: string): Account {
    return this.orgAccounts.find((u) => u.id === accountId);
  }

  private sortReleases() {
    /*
    Step 1: sort by date
    Step 2: subdivide array into batch per Month
     */

    this.releases.sort(
      (a, b) => b.released_at.getTime() - a.released_at.getTime(),
    );

    this.sortedReleases = this.releases.reduce(
      (resultArray: ReleasePeriod[], item) => {
        const releaseGroup = resultArray.findIndex(
          (r) =>
            r.month === item.released_at.getMonth() &&
            r.year === item.released_at.getFullYear(),
        );

        if (releaseGroup === -1) {
          resultArray.push({
            year: item.released_at.getFullYear(),
            month: item.released_at.getMonth(),
            releases: [item],
          });
          return resultArray;
        }

        resultArray[releaseGroup].releases.push(item);

        return resultArray;
      },
      [],
    );

    groupBy(this.releases, "released_at");
  }

  refreshReleasesTagList() {
    if (this.isFakeDataDisplayed) return;

    this.tagList = this.releases.reduce((tagList, release) => {
      return tagList.concat(
        release.tags.filter((tag) => tagList.indexOf(tag) < 0),
      );
    }, []);
  }

  public onReleaseSubmitted(release: Release) {
    if (this.isFakeDataDisplayed) {
      this.releases = [];
      this.isFakeDataDisplayed = false;
    }
    const releaseIdx = this.releases.findIndex((r) => r.id === release.id);
    if (releaseIdx < 0) {
      this.releases = [...this.releases, release];
    } else {
      this.releases[releaseIdx].fromJson(release);
    }

    this.sortReleases();
    this.refreshReleasesTagList();
    this.refreshFilteredReleases();
  }

  private deleteRelease(releaseId: string) {
    this.releaseDao
      .deleteById(this.org.id, releaseId)
      .then(() => {
        this.notificationHelper.trigger("Release Removed", null, "success");
        this.releases = this.releases.filter(
          (release) => release.id !== releaseId,
        );

        if (this.releases.length === 0) {
          this.releases = fakeReleases;
          this.showOnboarding = true;
          this.isFakeDataDisplayed = true;
        }
        this.sortReleases();
        this.refreshReleasesTagList();
        this.refreshFilteredReleases();
      })
      .catch((err) => {
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      });
  }

  showDeleteConfirm($event, releaseId: string): void {
    this.modal.warning({
      nzTitle: "Are you sure you want to delete this Release?",
      nzOkText: "Remove",
      nzCancelText: "Cancel",
      nzOkType: "default",
      nzOkDanger: true,
      nzStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      },
      nzOnOk: () => this.deleteRelease(releaseId),
    });
    $event.stopPropagation();
    $event.preventDefault();
  }

  // same function as in survey-list.component.ts
  public getCssBackground(path: string): string {
    return 'url("' + path + '")';
  }
}
