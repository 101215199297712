<div
  class="org-settings"
  [ngClass]="{
    'survey-preview': tabSelectedIndex !== 2,
    'link-sharing-preview': tabSelectedIndex === 2
  }"
>
  <div class="settings">
    <section *ngIf="scope === 'org'">
      <hr />
      <h2 class="title"><screeb-icon size="md" icon="button" /> Behaviour</h2>
      <p class="desc">
        Let's customize the behaviour of your messages and surveys
      </p>
      <tag-settings-behaviour
        [scope]="scope"
        [org]="org"
        [(tagSettings)]="tagSettings"
        (specificBehaviourSwitchChange)="
          specificBehaviourSwitchChange.emit($event)
        "
      >
      </tag-settings-behaviour>
    </section>
    <nz-tabset
      *ngIf="!survey?.type && ('isContextualMessagesEnabled' | hasFeatureFlag)"
      [nzSelectedIndex]="selectedTypeIndex"
      [nzAnimated]="false"
      (nzSelectedIndexChange)="onTypeTabChange($event)"
    >
      <nz-tab
        *ngFor="let type of typeOrders"
        [nzTitle]="typesNames[type]"
      ></nz-tab>
    </nz-tabset>
    <ng-container *ngIf="additionalSettingsRef">
      <section>
        <ng-container [ngTemplateOutlet]="additionalSettingsRef"></ng-container>
      </section>
    </ng-container>
    <section
      *ngIf="
        interactionType &&
        scope === 'survey' &&
        typeSelected === 'survey' &&
        featureFlaggingService.isInteractionEnabled()
      "
    >
      <hr />
      <h2 class="title">
        <screeb-icon size="md" icon="button" /> Type of Interaction
      </h2>
      <p class="desc">
        Define how you will invite your users to respond to your survey
      </p>
      <div class="settings-interaction-container">
        <tag-settings-interactions
          [interactionType]="interactionType"
          (interactionTypeChange)="interactionTypeChange.emit($event)"
        />
      </div>
    </section>
    <section>
      <hr *ngIf="scope === 'survey'" />
      <h2 class="title"><screeb-icon size="md" icon="desktop" /> Design</h2>
      <p class="desc" *ngIf="scope === 'survey'">
        Let's customize the look and feel of your
        {{ typeSelected === "survey" ? "survey" : "message" }}
      </p>
      <p class="desc" *ngIf="scope === 'org'">
        Let's customize the default look and feel of your
        {{ typeSelected === "survey" ? "surveys" : "messages" }}
      </p>
      <p class="desc" *ngIf="scope === 'distribution'">
        Let's customize the look and feel of your
        {{ typeSelected === "survey" ? "survey" : "message" }} distribution
      </p>
      <tag-settings-design
        [scope]="scope"
        [surveyDistribution]="surveyDistribution"
        [typeSelected]="typeSelected"
        [(tabSelectedIndex)]="tabSelectedIndex"
        [org]="org"
        [(tagSettings)]="tagSettings"
        (specificDesignSwitchChange)="specificDesignSwitchChange.emit($event)"
        [interaction]="interactionType"
      >
      </tag-settings-design>
    </section>
    <section
      *ngIf="
        scope === 'survey' &&
        ('response_translation' | hasEntitlement) &&
        org.translation_enabled
      "
    >
      <hr />
      <h2 class="title">
        <screeb-icon size="md" icon="response" /> Translation
      </h2>
      <p class="desc">
        Handle wether you want to translate the responses of your
        {{ uiService.isMessagePage ? "message" : "survey" }}
      </p>
      <tag-settings-translation
        [translationEnabled]="translationEnabled"
        (specificTranslationEnabledSwitchChange)="
          specificTranslationEnabledSwitchChange.emit($event)
        "
      ></tag-settings-translation>
    </section>
    <section
      *ngIf="scope === 'survey' && ('integration.create' | hasPermission)"
    >
      <hr />
      <h2 class="title"><screeb-icon size="md" icon="link" /> Integrations</h2>
      <p class="desc">
        Let's integrate your
        {{ uiService.isMessagePage ? "message" : "survey" }} with your favorite
        tools
      </p>
      <tag-settings-integrations
        [scope]="scope"
        [org]="org"
        [survey]="survey"
        [orgIntegrations]="orgIntegrations"
        [surveyIntegrations]="surveyIntegrations"
      >
      </tag-settings-integrations>
    </section>
  </div>
  <tag-settings-preview
    *ngIf="typeSelected === 'survey'"
    [mode]="mode"
    [scope]="scope"
    [forceMobile]="shouldForceMobile()"
    [showMobileOption]="
      interactionType === 'in_page' || getFormat() === 'in_page' ? false : true
    "
    class="preview"
    [format]="getFormat()"
    [tagSettings]="tagSettings ?? defaultTagSettings"
  >
  </tag-settings-preview>
</div>
