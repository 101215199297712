import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { Org } from "models/org.model";
import {
  integrationSpecs,
  integrationCategorySpec,
  IntegrationSpec,
  Integration,
  IntegrationType,
  IntegrationCategory,
  orderedIntegrationsTypes,
} from "models/integrations.model";
import { NotificationHelper } from "helpers/notification.helper";
import { NzModalService } from "ng-zorro-antd/modal";
import { IntegrationDao } from "models/integration.dao";
import { PageComponentInterface } from "components/PageComponentInterface";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { EntitlementService } from "services/entitlement.service";
import { combineLatest } from "rxjs";
import { simpleFTS } from "utils/search";
import { PermissionsService } from "services/permissions.service";

@Component({
  selector: "page-integration-list",
  templateUrl: "./integration-list.component.html",
  styleUrls: ["./integration-list.component.scss"],
})
export class IntegrationListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Integrations";
  public name = "Integrations list";

  private obs: any = null;
  private integrations: Integration[] = [];
  public visibleIntegrations: IntegrationSpec[] = [];
  public integrationCategorySpec = integrationCategorySpec;

  public isUpgradeModalVisible = false;
  private category: "all" | "active" | IntegrationCategory = "all";
  public keywords = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private modal: NzModalService,
    private featureFlaggingService: FeatureFlaggingService,
    private notificationHelper: NotificationHelper,
    private integrationDao: IntegrationDao,
    public permissionsService: PermissionsService,
    public entitlementService: EntitlementService,
  ) {}

  @Input() public org: Org = null;

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = combineLatest([
      this.route.data,
      this.route.queryParams,
    ]).subscribe((data) => {
      this.org = data[0]["org"];
      this.integrations = data[0]["integrations"];

      this.category = data[1]["category"] ?? "all";

      this.onSearchChange();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public onInstall($event, type: IntegrationType, createOnInstall: boolean) {
    if (!this.permissionsService.isAllowed("integration.create")) {
      return;
    }

    if (!this.entitlementService.isIntegrationAvailable(type)) {
      this.isUpgradeModalVisible = true;
      return;
    }

    if (createOnInstall) {
      const newIntegration = Integration.New(this.org.id, type);

      this.integrationDao
        .updateIntegration(
          newIntegration.org_id,
          newIntegration.type,
          null,
          newIntegration.settings,
        )
        .then(() => {
          this.router.navigate([
            "org",
            this.org.id,
            "integrations",
            type.toString(),
          ]);
          this.notificationHelper.trigger(
            "Integration installed",
            null,
            "success",
          );
        })
        .catch((err: HttpErrorResponse) => {
          console.error(err.error);
          this.notificationHelper.trigger(
            err?.error?.message ?? "Error",
            null,
            "error",
          );
        });
    } else {
      this.router.navigate([
        "org",
        this.org.id,
        "integrations",
        type.toString(),
      ]);
    }
    $event.stopPropagation();

    return false; // WTF: not returning false with an `$event.stopPropagation();` will reload the page on navigation
  }

  public onEdit(type: IntegrationType) {
    this.router.navigate(["org", this.org.id, "integrations", type.toString()]);

    return false; // WTF: not returning false with an `$event.stopPropagation();` will reload the page on navigation
  }

  private removeIntegration(type: IntegrationType) {
    this.integrationDao
      .removeIntegration(this.org.id, type)
      .then(() => {
        this.integrations = this.integrations.filter(
          (integration) => integration.type !== type,
        );
        this.notificationHelper.trigger("Integration Removed", null, "success");
      })
      .catch((err) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      });
  }

  showRemoveConfirm($event, type: IntegrationType): void {
    this.modal.warning({
      nzTitle: "Are you sure you want to remove this integration?",
      nzOkText: "Remove",
      nzCancelText: "Cancel",
      nzOkType: "default",
      nzOkDanger: true,
      nzStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      },
      nzOnOk: () => this.removeIntegration(type),
    });
    $event.stopPropagation();
    $event.preventDefault();
  }

  // same function as in survey-list.component.ts
  public getCssBackground(path: string): string {
    return 'url("' + path + '")';
  }

  public isIntegrationAuthBroken(type: IntegrationType) {
    if (integrationSpecs[type].authCanBreak === false) {
      return false;
    }

    return (
      this.integrations.find((integration: Integration) => {
        return integration.type === type;
      })?.auth_ok === false
    );
  }

  /**
   * Filtering
   */
  public isIntegrationInstalled(type: IntegrationType): boolean {
    const found = this.integrations.find((integration: Integration) => {
      return integration.type === type;
    });

    return !!found;
  }

  private getSpecs(): IntegrationSpec[] {
    return orderedIntegrationsTypes
      .map((type: IntegrationType) => integrationSpecs[type])
      .filter((integration: IntegrationSpec) => integration.installable)
      .filter((integration: IntegrationSpec) =>
        this.featureFlaggingService.displayIntegrationCard(integration.type),
      )
      .filter((integration: IntegrationSpec) =>
        simpleFTS(integration.name, this.keywords),
      );
  }

  private sortIntegrations(integrations: IntegrationSpec[]): IntegrationSpec[] {
    return integrations.sort((a, b) => {
      // active first
      if (
        Number(this.isIntegrationInstalled(b.type)) -
          Number(this.isIntegrationInstalled(a.type)) <
        0
      ) {
        return -1;
      }

      // order by type
      return (
        orderedIntegrationsTypes.indexOf(a.type) -
        orderedIntegrationsTypes.indexOf(b.type)
      );
    });
  }

  public getVisibleIntegrations(): IntegrationSpec[] {
    if (this.category === "all") {
      return this.getSpecs();
    }

    if (this.category === "active") {
      return this.getSpecs().filter((integration: IntegrationSpec) =>
        this.isIntegrationInstalled(integration.type),
      );
    }

    return this.getSpecs().filter((integration: IntegrationSpec) =>
      integration.categories.includes(this.category as IntegrationCategory),
    );
  }

  public onSearchChange() {
    this.visibleIntegrations = this.sortIntegrations(
      this.getVisibleIntegrations(),
    );
  }
}
