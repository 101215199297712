import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

import { AuthService, SessionService } from "services/auth.service";
import { NotificationHelper } from "helpers/notification.helper";
import { UIService } from "services/ui.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";

const getParams = (url: string) =>
  Object.fromEntries(
    // @ts-ignore (fucked up native types, searchParams should be typed as iterator)
    new URL(`a:/${url}`).searchParams,
  );

@Component({
  selector: "layout-back-sidebar-stats",
  templateUrl: "./sidebar-stats.component.html",
  styleUrls: ["./sidebar-stats.component.scss"],
})
export class LayoutBackSidebarStatsComponent {
  public searchParams: { [key: string]: string };

  constructor(
    public router: Router,
    public location: Location,
    public authService: AuthService,
    public sessionService: SessionService,
    public notificationHelper: NotificationHelper,
    public uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {
    // Keep query params through every reports
    this.searchParams = getParams(this.location.path());
    this.location.onUrlChange((url) => (this.searchParams = getParams(url)));
  }
}
