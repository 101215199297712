import { Component, Input, OnChanges } from "@angular/core";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import { SurveyTargetingRule } from "models/survey-targeting-rule.model";
import { RegistryEntry, sortRegistryEntries } from "models/registry.model";

@Component({
  selector: "rule-user-segment",
  templateUrl: "./rule-user-segment.component.html",
  styleUrls: ["./rule-user-segment.component.scss"],
})
export class TargetingRuleUserSegmentComponent implements OnChanges {
  @Input() public rule: SurveyTargetingRule = null;
  @Input() public registryEntriesGroup: RegistryEntry[] = [];

  public getFormattedUserGroupNameOrID = getFormattedUserGroupNameOrID;

  public sortedRegistryEntries = [];

  constructor() {}

  ngOnChanges() {
    this.sortedRegistryEntries =
      this.registryEntriesGroup.sort(sortRegistryEntries);
  }
}
