/* eslint-disable @angular-eslint/component-class-suffix */
import { Component } from "@angular/core";

@Component({
  selector: "test-mode-banner",
  template: `
    <div class="test-mode-banner" [style]="{ 'background-color': '#FFF6EA' }">
      <span class="test-mode-title"
        >⚠️ Test mode activated ! Eligible users will see it over and over until
        you desactivate the test mode or the survey.<br />Add an additional
        trigger to limit displays only to specific users.</span
      >
    </div>
  `,
  styles: [
    `
      .test-mode-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        border-radius: 16px;
        width: 100%;
        min-height: 72px;
        padding: 16px 90px;
        animation: shadeAppear 200ms;
        margin-bottom: 32px;
      }

      .test-mode-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: #303140;
      }

      .test-mode-banner .upgrade-btn {
        height: 40px;
      }

      @keyframes shadeAppear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `,
  ],
})
export class TestModeBannerComponent {
  constructor() {}
}
