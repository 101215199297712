import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { SimpleBucket } from "components/surveys/pages/stats/indicators/indicator.utils";
import {
  Account,
  AccountGoal,
  AccountGoalsToLabel,
  AccountJobTitle,
} from "models/account.model";
import { Org } from "models/org.model";
import { SessionService } from "services/auth.service";
import { RoutingService } from "services/routing.service";
import { formatNumber } from "utils/number";
import { AnalyticsQueryUsers } from "models/analytics.filters.type";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { AnalyticsDao } from "models/analytics.dao";

import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { TrackersService } from "services/trackers.service";
import { Integration, IntegrationSettings } from "models/integrations.model";
import { IntegrationDao } from "models/integration.dao";
import { NotificationHelper } from "helpers/notification.helper";
import { HttpErrorResponse } from "@angular/common/http";
import { OAuth2Service } from "services/oauth2.service";
import { AccountDao } from "models/account.dao";
import { TeamMemberInvitation } from "components/common/invite-team-members/invite-team-members";
import { OrgDao } from "models/org.dao";

declare const YT: any;

@Component({
  selector: "page-org-quickstart",
  templateUrl: "./quickstart.component.html",
  styleUrls: ["./quickstart.component.scss"],
})
export class QuickstartOrgPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Organization quickstart";
  public name = "Organization quickstart";

  private obs: any = null;

  public error: Error;
  public initialFetch = true;

  public org: Org = null;
  public orgAccounts: Account[] = [];
  public surveys: Survey[] = [];
  public integrations: Integration[] = [];

  public orgHasUsers = true;
  public orgHasResponses = false;
  public orgHasSurveyLive = false;
  public orgHasSlackIntegration = false;
  public goal: AccountGoal = "boost-user-satisfaction";
  public installSlackIntegrationLoading = false;
  public invitationLoading = false;

  public formatNumber = formatNumber;

  public tasks = {
    installDone: false,

    presentationDone: false,
    presentationOpened: true,

    launchFirstSurveysDone: false,
    launchFirstSurveysOpened: true,

    inviteTeamMembersDone: false,
    inviteTeamMembersOpened: true,

    installSlackIntegrationDone: false,
    installSlackIntegrationOpened: true,
  };

  public surveyTemplates: Record<AccountGoal, { title: string; id: string }[]> =
    {
      "collect-product-feedback": [
        {
          id: "b9af1857-f341-46a5-863a-32f67fcfd549",
          title: "Product Feedback",
        },
        {
          id: "a56ad9ef-7f45-4db8-9198-7308d255a1ea",
          title: "Feature Satisfaction",
        },
      ],
      "boost-user-satisfaction": [
        { id: "486d54b5-4675-4fcd-95ab-cacb42989cb6", title: "NPS" },
        {
          id: "a989c632-015a-4081-8932-3cfdab407a34",
          title: "Customer Satisfaction",
        },
      ],
      "improve-funnels-conversion-rate": [
        {
          id: "4f444fec-3fab-4136-92cb-be7d256c9f15",
          title: "Sign Up Experience",
        },
        {
          id: "0b578cfc-ce7a-4702-884b-87b61b1c7c21",
          title: "Customer Effort Score",
        },
      ],
      "increase-user-retention": [
        {
          id: "6c67e68b-4903-4def-8905-cf2e2a96d5fd",
          title: "Future Usage Intent",
        },
        { id: "9cd88a2e-5440-4f82-8674-1daa9d8bc399", title: "Reduce Churn" },
      ],
      "increase-product-adoption": [
        {
          id: "476ff023-e633-4984-bc5e-9ed2681cadcb",
          title: "User Experience",
        },
        {
          id: "3806be4f-2256-439a-b5cc-cb14b69cf3e7",
          title: "Prioritize Feature Development",
        },
      ],
      "discover-new-things-about-users": [
        {
          id: "/673400d1-90af-4d8a-a169-692def789090",
          title: "User Qualification",
        },
        { id: "fe499c91-447f-4928-932a-c83bec1e136c", title: "Customer Goals" },
      ],
    };

  public accountGoalsToLabel = AccountGoalsToLabel;
  public accountJobTitlesToLabel: { [key in AccountJobTitle]: string } = {
    "product-manager": "Product Managers",
    "ux-researcher": "UX Researchers",
    "product-ops": "Product Ops",
    "product-designer": "Product Designers",
    developer: "Developers",
    other: "People",
  };

  public player: any = null;

  @ViewChild("presentationVideo")
  presentationVideo: ElementRef<HTMLIFrameElement>;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private analyticsDao: AnalyticsDao,
    private accountDao: AccountDao,
    private orgDao: OrgDao,
    public sessionService: SessionService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public trackersService: TrackersService,
    public integrationDao: IntegrationDao,
    public notificationHelper: NotificationHelper,
    public oauth2Service: OAuth2Service,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );
    this.obs = this.route.data.subscribe(async (data) => {
      this.org = data.org;
      this.orgAccounts = data.orgAccounts;
      this.surveys = data.surveys;
      this.integrations = data.integrations;
      this.goal =
        this.sessionService.session?.flags?.goal ?? "boost-user-satisfaction";
      this.orgHasUsers = await this.hasUsers();
      this.orgHasSurveyLive = this.hasSurveyLive();
      this.orgHasSlackIntegration = this.hasSlackIntegration();

      this.tasks.installDone = this.orgHasUsers;
      this.tasks.presentationDone =
        this.sessionService.session?.flags?.intro_video_seen ?? false;
      this.tasks.launchFirstSurveysDone = this.orgHasSurveyLive;
      this.tasks.inviteTeamMembersDone = Boolean(this.orgAccounts.length - 1);
      this.tasks.installSlackIntegrationDone = this.orgHasSlackIntegration;

      this.tasks.presentationOpened = !this.tasks.presentationDone;
      this.tasks.launchFirstSurveysOpened = !this.tasks.launchFirstSurveysDone;
      this.tasks.inviteTeamMembersOpened = !this.tasks.inviteTeamMembersDone;
      this.tasks.installSlackIntegrationOpened =
        !this.tasks.installSlackIntegrationDone;

      if (this.tasks.presentationOpened) {
        this.loadVideoPlayer();
      }
    });
  }

  private getUsersCountQuery(
    start: Date,
    field: "last_activity_at" | "created_at",
    end = new Date(),
  ): AnalyticsQueryUsers {
    return {
      type: "respondent",
      org_id: UUID(this.org.id),
      survey_ids: this.surveys.map(({ id }) => UUID(id)),
      identified_only: false,
      filters_bool: "AND",
      filters: [],
      range: {
        start,
        end,
        field,
      },
      size: 0,
    };
  }

  private hasSlackIntegration() {
    return this.integrations.some(
      ({ type, auth_ok }) => type === "slack" && auth_ok,
    );
  }

  private hasSurveyLive() {
    return this.surveys.some(({ survey_distributions }) =>
      survey_distributions.some(({ enabled }) => enabled),
    );
  }

  private async hasUsers() {
    const response = await this.analyticsDao.search(
      this.getUsersCountQuery(this.org.created_at, "created_at"),
    );

    return response.hits.total > 0;
  }

  getActivityDatasets(buckets: SimpleBucket[]) {
    return [
      {
        yAxisID: "y",
        data: buckets.map(({ key_as_string, doc_count }) => ({
          x: +new Date(key_as_string),
          y: doc_count,
        })),
      },
    ];
  }

  ngOnDestroy() {
    this.unloadVideoPlayer();
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  getHelloMessage = () => {
    const hours = new Date().getHours();

    if (hours >= 18 || hours < 5) {
      return "Good evening";
    } else if (hours >= 5 && hours < 12) {
      return "Good morning";
    } else {
      return "Good afternoon";
    }
  };

  onClickedOnPlay() {
    this.tasks.presentationDone = true;
    this.sessionService.session.flags.intro_video_seen = true;
    this.accountDao.setFlags({ intro_video_seen: true });
  }

  getVideoId() {
    switch (this.goal) {
      case "boost-user-satisfaction":
        return "e0Q7dxeGRW0";
      case "collect-product-feedback":
        return "HzImPZ63VZI";
      case "discover-new-things-about-users":
        return "-Mx9nXaJqWQ";
      case "improve-funnels-conversion-rate":
        return "kYCkTtgs81I";
      case "increase-product-adoption":
        return "U8GdND1OSPo";
      case "increase-user-retention":
        return "5qRscVIv_7E";
    }
  }

  loadVideoPlayer() {
    this.player = new YT.Player(this.presentationVideo.nativeElement, {
      videoId: this.getVideoId(),
      events: {
        onStateChange: ({ data }) => {
          if (data === 1) {
            this.onClickedOnPlay();
          }
        },
      },
    });

    const iframe: HTMLIFrameElement = this.player.getIframe();

    iframe.width = "100% !important";
    iframe.height = "20vw !important";
    iframe.style.width = "100% !important";
    iframe.style.height = "20vw !important";
    iframe.style.minHeight = "300px";
    iframe.style.borderRadius = "8px";
    iframe.style.background = "var(--screeb-color-grey-300)";
  }

  unloadVideoPlayer() {
    if (this.player?.detroy) {
      this.player?.unloadModule();
      this.player?.detroy();
    }
  }

  presentationVideoOpenedChange(opened: boolean) {
    if (opened) {
      this.loadVideoPlayer();
    } else {
      this.unloadVideoPlayer();
    }
  }

  onInstallSlackIntegrationClick() {
    this.integrationDao
      .updateIntegration(
        this.org.id,
        "slack",
        null,
        IntegrationSettings.New("slack"),
      )
      .then(() => this.oauth2Service.getAuthorizeURL(this.org.id, "slack"))
      .then((url) => (window.location.href = url))
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      });
  }

  onSubmitInvitations(invitations: TeamMemberInvitation[]) {
    if (!invitations.length) return;

    this.invitationLoading = true;

    Promise.all(
      invitations
        .filter((invitation) => Boolean(invitation.email))
        .map((invitation) =>
          this.orgDao.inviteOrgAccounts(
            this.org.id,
            invitation.email,
            invitation.role,
          ),
        ),
    )
      .then(() => {
        this.trackersService
          .newEventTrackingBuilder("Screeb Invite Done")
          .withOrg(this.org)
          .withProps(invitations)
          .build();

        this.tasks.inviteTeamMembersDone = true;
        this.tasks.inviteTeamMembersOpened = false;

        this.notificationHelper.trigger(
          "You team mates have been invited to Screeb!",
          null,
          "success",
        );
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.trigger(
          "Something went wrong when inviting your team mates. Please try again later!",
          null,
          "error",
        );
        console.error(err.error);
      })
      .finally(() => {
        this.invitationLoading = false;
      });
  }
}
