<ul nz-menu>
  <li nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/integrations"
      [queryParams]="{ category: 'all' }"
      routerLinkActive="active"
    >
      All integrations
    </a>
  </li>
  <li nz-menu-item nzMatchRouter>
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/integrations"
      [queryParams]="{ category: 'active' }"
      routerLinkActive="active"
    >
      Active integrations
    </a>
  </li>

  <li class="separator"></li>

  <li nz-menu-item nzMatchRouter *ngFor="let category of categories">
    <a
      routerLink="/org/{{ uiService.currentOrgId }}/integrations"
      [queryParams]="{ category: category }"
      routerLinkActive="active"
    >
      {{ integrationCategorySpec[category].name }}
    </a>
  </li>
</ul>
