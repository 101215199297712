import { default as insane } from "insane";
import { emojiTranscoder } from "./emojis";

/***************************************************
 *          DUPLICATE FROM TAG SOURCE CODE
 ***************************************************/

function sanitizerFilters(token) {
  if (token.tag === "a") {
    const href = token.attrs["href"];

    if (href === null || href === undefined || href === "") return false;

    if (href[0] === "#") return false;

    // fails relative URL or invalid URL
    try {
      new URL(href);
    } catch (err) {
      return false;
    }

    // it is supported by library already,
    // but library does not break the <a> tag in that case :-(
    if (href.indexOf("javascript:") === 0) return false;
    if (href.indexOf("magnet:") === 0) return false;

    // bullshit that enforces opening in new window 🤮
    // @TODO: we should make a contrib to official library, in order to support side effect with a clean hook
    token.attrs["target"] = "_blank";
  }
  return true;
}

export class SanitizeOptions {
  links?: boolean = false;
  styling?: boolean = false;
  emojiTranscoder?: boolean = false;
  useDarkAndLightMode?: boolean = false;
  primaryTextColor?: string = "var(--screeb-color-white)";
  CR?: boolean = false;
  nativeCR?: boolean = false;
}

// text formating order does matter
// @TODO: it does not makes sense to apply this filters to buttons, scores are responses
// @TODO: responses should be flatten, even if we have tags in the button
export function htmlSanitizer(
  input: string,
  options: SanitizeOptions = {},
): string {
  const allowedTags: string[] = [];
  if (options.links) allowedTags.push("a");
  if (options.styling) allowedTags.push("u", "s", "b", "i");
  if (options.CR) allowedTags.push("br");

  const allowedAttributes = { "*": ["title", "accesskey"] };
  if (options.links)
    allowedAttributes["a"] = ["href", "name", "target", "aria-label"];

  let sanitized = insane(input, {
    allowedTags: allowedTags,
    allowedAttributes: allowedAttributes,
    allowedSchemes: ["http", "https", "mailto"],
    filter: sanitizerFilters,
    transformText: (text) => {
      if (options.CR) text = text.replace(/\n/g, "<br>");
      else if (!options.nativeCR) text = text.replace(/\n/g, " ");
      return text;
    },
  });

  // emoji transcoding is executed after sanitizer, because images may be removed by `insane`
  if (options.emojiTranscoder)
    sanitized = emojiTranscoder(
      sanitized,
      options.useDarkAndLightMode,
      options.primaryTextColor,
    );

  return sanitized;
}

export const stripHtml = (html: string): string =>
  (html ?? "").replace(/(<([^>]+)>)/gi, "");
