<nz-card style="width: 300px" nzTitle="New channel">
  <form nz-form nz-form [formGroup]="validateForm" (ngSubmit)="onSubmit()">
    <nz-form-item>
      <nz-form-control>
        <nz-select
          formControlName="type"
          nzHasFeedback
          required
          nzPlaceHolder="Type"
        >
          <nz-option nzValue="widget" nzLabel="In-app web"></nz-option>
          <nz-option nzValue="android" nzLabel="In-app Android"></nz-option>
          <nz-option nzValue="ios" nzLabel="In-app iOS"></nz-option>
          <nz-option nzValue="hosted-page" nzLabel="Shared Link"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control>
        <nz-input-group>
          <input
            type="text"
            nz-input
            nzHasFeedback
            formControlName="name"
            placeholder="Name"
            required
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <div class="center">
      <button nz-button nzType="primary" [nzLoading]="loading">Create</button>
    </div>
  </form>
</nz-card>
